import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
// buddies Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class BuddiesTab extends Component {

    linkToAnotherProfile = () => {
        this.props.data.linkToAnotherProfile()
    }

    render() {
        let infinite_value_buddies = false
        if (this.props.data.buddies.length > 3) {
            infinite_value_buddies = true
        }

        //invitation carousel settings
        const settings = {
            dots: false,
            infinite: infinite_value_buddies,
            slidesToShow: 2,
            swipeToSlide: true,
            // slidesToScroll: 1,
            // autoplay: true,
            // speed: 3000,
            // autoplaySpeed: 1000,
            // cssEase: "linear",
        };

        return (
            <Fragment>
                <div class="row mb-5">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-8 item-5 mt-4">
                        <div class="row">
                            <div class="col-xl-12 text-left ml-3 item-text-5">
                                <h3 class="blue">Buddies</h3>
                                {this.props.data.buddies.length > 0 ?
                                    <Link to={{ pathname: '/show-profile-buddies', state: { profile_username: this.props.data.profile_username } }}>
                                        <div class="profile-show-all-buddies-section">
                                            <div class="profile-show-all-buddies-button">
                                                <img
                                                    class="profile-show-all-buddies-icon"
                                                    src={require("../../../assets/profile/svg/show-buddies.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="profile-show-all-buddies">
                                                Show all
                                            </div>
                                        </div>
                                    </Link>
                                    :
                                    null
                                }
                            </div>

                        </div>

                        <div class="" >
                            <Slider {...settings} >
                                {this.props.data.buddies.map(buddy => (
                                    <div key={buddy.id}>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 text-center item-div-4" >
                                            <Link to={{ pathname: `/user-profile/${buddy.user}/profile`, state: { buddy: buddy.user } }}>
                                                <div
                                                    onClick={this.linkToAnotherProfile}
                                                    class="item-img-4">
                                                    {buddy.profile_picture ?

                                                        <img class="profile-buddy-img" src={buddy.profile_picture} alt="" />
                                                        :
                                                        <img class="profile-buddy-img" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                    }
                                                </div>
                                                <p class="item-text-4 blue profile-sliders-names-size">{buddy.username}</p>
                                            </Link>
                                        </div>

                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div class="col-xl-2"></div>
                </div>
            </Fragment >
        )
    }
}

export default (BuddiesTab);