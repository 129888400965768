import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const HubPrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            let user = JSON.parse(localStorage.getItem('ur'))
            if (user) {
                if (user.hub_id === 0) {
                    return <Redirect to="/" />
                } else {
                    return <Component {...props} />;

                }
            } else {
                return <Redirect to="/register" />;
            }
        }}
    />
);

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(HubPrivateRoute);