import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getReservationMeetings,
    getMeetingInvitations,
    cancelMeeting,
} from '../../../../store/actions/meeting'
import ShareReservationModal from '../ShareReservationModal';
import ReservationQrModal from '../ReservationQrModal';
import AreYouSure from '../../../user/layout/AreYouSure';
import Modal from 'react-awesome-modal';
import WifiModal from '../WifiModal';


export class ReservationMeetings extends Component {

    state = {
        openTab: [],
        openHubReservationTab: [],
        shareReservationModal: false,
        cancelledHubReservations: [],
        meetingAreYouSureModal: false,
        reservationAreYouSureModal: false,
        wifiModalVisible: false,
        hub_name: "",
        wifi_code: "",
        qr_code: "",
        meeting_to_cancel: {},
        qrCodeModalVisible: false,
    }

    openShareReservationModal(hub_reservation_id) {
        this.setState({
            shareReservationModal: true
        });
        this.props.getHubReservtionShares(hub_reservation_id)
    }

    closeModal() {
        this.setState({
            wifiModalVisible: false,
            shareReservationModal: false,
            reservationAreYouSureModal: false,
            meetingAreYouSureModal: false,
            qrCodeModalVisible: false,
            hub_name: "",
            wifi_code: "",
            qr_code: "",
        });
    }

    openMeetingAreYouSureModal(meeting) {
        this.setState({
            meeting_to_cancel: meeting,
            meetingAreYouSureModal: true,
        });
    }

    cancelReservationMeeting() {
        const meeting = this.state.meeting_to_cancel
        this.props.cancelReservationMeeting(meeting)
    }

    openReservationAreYouSureModal() {
        this.setState({
            reservationAreYouSureModal: true,
        });
    }

    changeModeOpenTab = (id) => (e) => {
        e.preventDefault();
        const meeting_tab = [...this.state.openTab]

        if ((meeting_tab.indexOf(id)) !== -1) {
            const new_remove = meeting_tab.filter(f => f !== id)
            this.setState({ openTab: new_remove })
        }
        else {
            if (meeting_tab.length === 0) {
                meeting_tab.push(id)
                this.setState({ openTab: meeting_tab })
            }
            else {
                meeting_tab.pop()
                meeting_tab.push(id)
                this.setState({ openTab: meeting_tab })
            }
            this.props.getInvitations(id);
        }
    }

    updateCancelledHubReservations(id) {
        const cancellations_tab = [...this.state.cancelledHubReservations]
        cancellations_tab.push(id)
        this.setState({ cancelledHubReservations: cancellations_tab })
    }

    userCancelHubReservation = (hub_reservation_id, user) => (e) => {
        const status = "Cancelled by User"
        const answer = { status, user, hub_reservation_id };
        this.props.userCancelHubReservation(answer, hub_reservation_id);
        this.updateCancelledHubReservations(hub_reservation_id)
    }

    changeModeOpenHubReservationTab = (id) => (e) => {
        e.preventDefault();
        const hub_reservation_tab = [...this.state.openHubReservationTab]

        if ((hub_reservation_tab.indexOf(id)) !== -1) {
            const new_remove = hub_reservation_tab.filter(f => f !== id)
            this.setState({ openHubReservationTab: new_remove })
        }
        else {
            if (hub_reservation_tab.length === 0) {
                hub_reservation_tab.push(id)
                this.setState({ openHubReservationTab: hub_reservation_tab })
            }
            else {
                hub_reservation_tab.pop()
                hub_reservation_tab.push(id)
                this.setState({ openHubReservationTab: hub_reservation_tab })
            }
            this.props.getHubReservtionShares(id);
        }
    }

    cancelReservation = (meeting, id) => (e) => {
        const reservation_id = id
        const status = "Cancelled by User"
        const answer = { status, meeting };
        this.props.cancelMeeting(answer, reservation_id);
    }

    openWifiModal(hub_name, wifi_code) {
        this.setState({
            wifiModalVisible: true,
            hub_name: hub_name,
            wifi_code: wifi_code
        });
    }

    openReservationQrModal(qr_code, hub_name) {
        this.setState({
            qrCodeModalVisible: true,
            hub_name: hub_name,
            qr_code: qr_code
        });
    }

    render() {
        // meeting.creator_user === JSON.parse(localStorage.getItem('ur')).id ?
        let user = []
        if (JSON.parse(localStorage.getItem('ur'))) {
            user = JSON.parse(localStorage.getItem('ur')).id
        }
        const reservation_shares = [...this.props.data.hub_reservation_shares]
        return (
            <Fragment>
                <Modal visible={this.state.qrCodeModalVisible} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                    <ReservationQrModal
                        data={{
                            hub_name: this.state.hub_name,
                            qr_code: this.state.qr_code,
                            closeModal: this.closeModal.bind(this)
                        }}
                    />
                </Modal>
                {this.props.data.meetings.length + this.props.data.hub_reservations.length === 0 ?
                    <div class="text-center empty-meetings-image">
                        <div class="empty-meetings-image">
                            <img
                                class="empty-meetings-created"
                                src={require("../../../../assets/layout/mika/stars.png")}
                                alt=""
                            />
                        </div>
                        <br />
                        <div>
                            <p class="text-blue-empty-meetings">
                                You don't have <br /> any <b>Reservations</b> currently, <br />seize the <b>night</b><br /> by
                                <Link to="/">
                                    <a class="link2-meetings" href="#">Requesting one!</a>
                                </Link>
                            </p>
                        </div>
                        <br />
                        <br />
                    </div>
                    :
                    <div class="all-meetings-center-layout">
                        <center>
                            {this.props.data.meetings.map(meeting => (
                                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 item-col">
                                    <div class="item-of-table ">
                                        <span class="meetings-hub-name-position">
                                            <h5 class="meetings-hub-name">
                                                {meeting.hub_name}
                                            </h5>
                                        </span>
                                        <div class="row">
                                            <div class="top-logo">
                                                <Link to={{ pathname: '/hub', state: { hub_id: meeting.hub_id } }}>
                                                    <img
                                                        class="meetings-hub-logo-size"
                                                        src={meeting.hub_logo}
                                                        alt=""
                                                    />
                                                </Link>
                                            </div>
                                            <Modal visible={this.state.wifiModalVisible} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                                                <WifiModal
                                                    data={{
                                                        hub_name: this.state.hub_name,
                                                        hub_wifi: this.state.wifi_code,
                                                        closeModal: this.closeModal.bind(this)
                                                    }}
                                                />
                                            </Modal>
                                            {meeting.wifi_code ?
                                                <div class="meeteing-wifi-position">
                                                    <span>
                                                        <div
                                                            onClick={() => this.openWifiModal(meeting.hub_name, meeting.wifi_code)}
                                                            class="meeteing-wifi-round-button meeteing-wifi-round-button-unpressed">
                                                            <img
                                                                class="meeteing-wifi-icon"
                                                                src={require("../../../../assets/meeting/svg/wireless.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </span>
                                                    <h5 class="meeteing-wifi-text">
                                                        WiFi
                                                    </h5>
                                                </div>
                                                :
                                                null
                                            }

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w text-center">
                                                {meeting.creator_profile_picture ?
                                                    <img
                                                        class="m-t"
                                                        src={meeting.creator_profile_picture}
                                                        alt=""
                                                    />
                                                    :
                                                    <img
                                                        class="m-t"
                                                        src={require("../../../../assets/authentication/svg/account.svg")}
                                                        alt=""
                                                    />
                                                }
                                                <br />
                                                <strong class="d-b creator-name-color">{meeting.creator}</strong>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w text text1 text-left">
                                                <strong class="day-layout">
                                                    {meeting.day}
                                                </strong>
                                                <span class="time-layout">
                                                    {meeting.time}
                                                </span>
                                                <p class="date-layout">
                                                    {meeting.date}
                                                </p>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w img-text">
                                                <img class="number-img" src={require("../../../../assets/meeting/svg/Attending reservation.svg")} alt="" />
                                                <div class="numbers">
                                                    <span>{meeting.persons_attending}</span>
                                                    <span>/</span>
                                                    <span>{meeting.persons_participating}</span>
                                                </div>
                                                <strong class="attending-position meetings-invitations-status-color">Attending</strong>
                                            </div>

                                            <br />

                                            {meeting.qr_code !== "" ?
                                                <div
                                                    onClick={() => this.openReservationQrModal(meeting.qr_code, meeting.hub_name)}
                                                    class="reservation-meetings-qr-position"
                                                >
                                                    <div class="reservation-meetings-qr-text">
                                                        Reservation's Qr Code
                                                    </div>
                                                    <div class="reservation-meetings-qr-button">
                                                        <img
                                                            class="reservation-meetings-qr-img"
                                                            src={require("../../../../assets/meeting/svg/Qr-code.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <br />
                                                </div>
                                                :
                                                null
                                            }



                                            <br />
                                            <br />

                                            {this.state.openTab.includes(meeting.id) ?
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <div class="">
                                                        <div class="form-group text-left">
                                                            <strong class="meetings-invitations-status-color">
                                                                note:
                                                            </strong>
                                                            <span class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 meeting-buddies-text-layout">
                                                                {meeting.buddies_note}
                                                            </span>
                                                        </div>
                                                        <span class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 meeting-buddies-text-layout">
                                                            Buddies Responses
                                                        </span>
                                                        <div class="row second-row accepted-row my-3">
                                                            <strong class="meetings-invitations-status-color">Accepted:</strong>
                                                            <span>
                                                                <span>
                                                                    {meeting.creator_profile_picture ?
                                                                        <img
                                                                            class="invitations-img"
                                                                            src={meeting.creator_profile_picture}
                                                                            alt=""
                                                                        />
                                                                        :
                                                                        <img
                                                                            class="invitations-img"
                                                                            src={require("../../../../assets/authentication/svg/account.svg")}
                                                                            alt=""
                                                                        />
                                                                    }
                                                                </span>
                                                            </span>
                                                            {this.props.data.invitations.map(invitation => (
                                                                <div>
                                                                    {invitation.status === "Accepted" ?
                                                                        <span>
                                                                            {invitation.receiver_profile_picture ?
                                                                                < img class="invitations-img" src={invitation.receiver_profile_picture} alt="" />
                                                                                :
                                                                                < img class="invitations-img" src={require("../../../../assets/authentication/svg/account.svg")} alt="" />
                                                                            }
                                                                        </span>
                                                                        : null}
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div class="row third-row second-row no-answer-row my-3">
                                                            <strong class="meetings-invitations-status-color">No Answer:</strong>
                                                            {this.props.data.invitations.map(invitation => (
                                                                <div>
                                                                    {invitation.status === "No Response" ?
                                                                        <span>
                                                                            {invitation.receiver_profile_picture ?
                                                                                < img class="invitations-img" src={invitation.receiver_profile_picture} alt="" />
                                                                                :
                                                                                < img class="invitations-img" src={require("../../../../assets/authentication/svg/account.svg")} alt="" />
                                                                            }
                                                                        </span>
                                                                        : null}
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div class="row second-row accepted-row my-3">
                                                            <strong class="meetings-invitations-status-color">Declined:</strong>
                                                            {this.props.data.invitations.map(invitation => (
                                                                <div>
                                                                    {invitation.status === "Declined" ?
                                                                        <span>
                                                                            {invitation.receiver_profile_picture ?
                                                                                < img class="invitations-img" src={invitation.receiver_profile_picture} alt="" />
                                                                                :
                                                                                < img class="invitations-img" src={require("../../../../assets/authentication/svg/account.svg")} alt="" />
                                                                            }
                                                                        </span>
                                                                        : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <Modal visible={this.state.meetingAreYouSureModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                                                            <AreYouSure
                                                                data={{
                                                                    sellected: "cancel-reservation",
                                                                    functionToRun: this.cancelReservationMeeting.bind(this),
                                                                    closeModal: this.closeModal.bind(this)
                                                                }}
                                                            />
                                                        </Modal>
                                                        {meeting.creator_user === user ?
                                                            <div class="btn-row row text-center">
                                                                <div class="col-xl-12 text-center red-btn">
                                                                    <button
                                                                        onClick={() => this.openMeetingAreYouSureModal(meeting.id)}
                                                                        class="btn-meetings btn-danger-not-going-meetings">
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        <div class="col-xl-12 text-center down-arrow">
                                                            <img
                                                                onClick={this.changeModeOpenTab(meeting.id)}
                                                                class="up-toggle1"
                                                                src={require("../../../../assets/meeting/svg/Path 625-2.svg")}
                                                                alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div class="col-xl-12 text-center down-arrow">
                                                    <img
                                                        onClick={this.changeModeOpenTab(meeting.id)}
                                                        class="toggle1"
                                                        src={require("../../../../assets/meeting/svg/arrow2.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            ))}

                            {this.props.data.hub_reservations.map(hub_reservation => (
                                <>
                                    {this.state.cancelledHubReservations.includes(hub_reservation.id) ?
                                        null
                                        :
                                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 item-col">
                                            {this.state.shareReservationModal ?
                                                <Modal visible={this.state.shareReservationModal} effect="fadeInDown" width="100px" height="100px" onClickAway={() => this.closeModal()}>
                                                    <ShareReservationModal
                                                        searchBuddy={this.props.searchBuddy}
                                                        getBuddies={this.props.getBuddies}
                                                        shareHubReservation={this.props.shareHubReservation}
                                                        data={{
                                                            hub_reservation_id: hub_reservation.id,
                                                            buddies: this.props.data.buddies,
                                                            hub_reservation_shares: reservation_shares,
                                                            profile: this.props.data.profile,
                                                            closeModal: this.closeModal.bind(this)
                                                        }}
                                                    />
                                                </Modal>
                                                : null}
                                            <div class="item-of-table ">
                                                <span class="meetings-hub-name-position">
                                                    <h5 class="meetings-hub-name">
                                                        {hub_reservation.hub_name}
                                                    </h5>
                                                </span>
                                                <div class="row">
                                                    <div class="top-logo">
                                                        <Link to={{ pathname: '/hub', state: { hub_id: hub_reservation.hub_id } }}>
                                                            <img
                                                                class="meetings-hub-logo-size"
                                                                src={hub_reservation.hub_logo}
                                                                alt=""
                                                            />
                                                        </Link>
                                                    </div>

                                                    {hub_reservation.wifi_code ?
                                                        <div class="meeteing-wifi-position">
                                                            <span>
                                                                <div
                                                                    onClick={() => this.openWifiModal(hub_reservation.hub_name, hub_reservation.wifi_code)}
                                                                    class="meeteing-wifi-round-button meeteing-wifi-round-button-unpressed">
                                                                    <img
                                                                        class="meeteing-wifi-icon"
                                                                        src={require("../../../../assets/meeting/svg/wireless.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </span>
                                                            <h5 class="meeteing-wifi-text">
                                                                WiFi
                                                            </h5>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    {hub_reservation.user === user ?
                                                        <>
                                                            {hub_reservation.is_shared ?
                                                                <div
                                                                    onClick={() => this.openShareReservationModal(hub_reservation.id)}
                                                                    class="reservations-share-button share-button-layout"
                                                                >
                                                                    <img
                                                                        class="reservations-share-icon"
                                                                        src={require("../../../../assets/meeting/svg/shared.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                :
                                                                <div
                                                                    onClick={() => this.openShareReservationModal(hub_reservation.id)}
                                                                    class="reservations-pre-share-button pre-share-button-layout"
                                                                >
                                                                    <img
                                                                        class="reservations-pre-share-icon"
                                                                        src={require("../../../../assets/meeting/svg/pre-share.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w text-center">
                                                        {hub_reservation.reservation_user_photo ?
                                                            <img
                                                                class="m-t"
                                                                src={hub_reservation.reservation_user_photo}
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                class="m-t"
                                                                src={require("../../../../assets/authentication/svg/account.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                        <br />
                                                        <strong class="d-b creator-name-color">{hub_reservation.creator}</strong>
                                                    </div>
                                                    <div class=
                                                        {hub_reservation.is_shared ?
                                                            "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w text shared-hub-reservation-box text-left"
                                                            :
                                                            "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w text pre-shared-hub-reservation-box text-left"
                                                        }>
                                                        <strong class="day-layout">
                                                            {hub_reservation.day}
                                                        </strong>
                                                        <span class="time-layout">
                                                            {hub_reservation.time}
                                                        </span>
                                                        <p class="date-layout">
                                                            {hub_reservation.date}
                                                        </p>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w img-text">
                                                        <img class="number-img" src={require("../../../../assets/meeting/svg/Attending reservation.svg")} alt="" />
                                                        <div class="numbers">
                                                            <span>{hub_reservation.persons}</span>
                                                        </div>
                                                        <strong class="attending-position meetings-invitations-status-color">Persons</strong>
                                                    </div>
                                                    {hub_reservation.qr_code !== "" ?
                                                        <div
                                                            onClick={() => this.openReservationQrModal(hub_reservation.qr_code, hub_reservation.hub_name)}
                                                            class="reservation-meetings-qr-position"
                                                        >
                                                            <div class="reservation-meetings-qr-text">
                                                                Reservation's Qr Code
                                                            </div>
                                                            <div class="reservation-meetings-qr-button">
                                                                <img
                                                                    class="reservation-meetings-qr-img"
                                                                    src={require("../../../../assets/meeting/svg/Qr-code.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <br />
                                                    <br /><br />
                                                    {this.state.openHubReservationTab.includes(hub_reservation.id) ?
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <span class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 meeting-buddies-text-layout">
                                                                Shared with
                                                            </span>
                                                            <div class="">
                                                                <div class="row">
                                                                    {reservation_shares.map(shared_with => (
                                                                        <div class="reservation-shares-photo-layout">
                                                                            <Link to={{ pathname: `/user-profile/${shared_with.receiving_sharing_reservation}` }}>
                                                                                {shared_with.receiver_profile_picture ?
                                                                                    <img
                                                                                        class="reservation-shares-profile-pic"
                                                                                        src={shared_with.receiver_profile_picture}
                                                                                        alt=""
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        class="reservation-shares-profile-pic"
                                                                                        src={require("../../../../assets/authentication/svg/account.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                }
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <Modal visible={this.state.reservationAreYouSureModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                                                                    <AreYouSure
                                                                        data={{
                                                                            sellected: "cancel-reservation",
                                                                            functionToRun: this.userCancelHubReservation(hub_reservation.id, hub_reservation.user).bind(this),
                                                                            closeModal: this.closeModal.bind(this)
                                                                        }}
                                                                    />
                                                                </Modal>
                                                                {hub_reservation.user === user ?
                                                                    <div class="btn-row row text-center">
                                                                        <div class="col-xl-12 text-center red-btn" >
                                                                            <button
                                                                                onClick={() => this.openReservationAreYouSureModal()}
                                                                                class="btn-meetings btn-danger-not-going-meetings">
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                <div class="col-xl-12 text-center down-arrow">
                                                                    {hub_reservation.is_shared ?
                                                                        <img
                                                                            onClick={this.changeModeOpenHubReservationTab(hub_reservation.id)}
                                                                            class="up-toggle1"
                                                                            src={require("../../../../assets/meeting/svg/arrow up  hubst3r.svg")}
                                                                            alt=""
                                                                        />
                                                                        :
                                                                        <img
                                                                            onClick={this.changeModeOpenHubReservationTab(hub_reservation.id)}
                                                                            class="up-toggle1"
                                                                            src={require("../../../../assets/meeting/svg/arrow up grey.svg")}
                                                                            alt=""
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div class="col-xl-12 text-center down-arrow">
                                                            {hub_reservation.is_shared ?
                                                                <img
                                                                    onClick={this.changeModeOpenHubReservationTab(hub_reservation.id)}
                                                                    class="toggle1"
                                                                    src={require("../../../../assets/meeting/svg/arrow down hubst3r.svg")}
                                                                    alt=""
                                                                />
                                                                :
                                                                <img
                                                                    onClick={this.changeModeOpenHubReservationTab(hub_reservation.id)}
                                                                    class="toggle1"
                                                                    src={require("../../../../assets/meeting/svg/arrow down grey.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    }
                                </>

                            ))}
                        </center>
                    </div>
                }
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    cancellations: state.meeting.cancellations,
    invitations: state.meeting.invitations,
    hub_reservation_shares: state.reservation.hub_reservation_shares
});

export default connect(mapStateToProps, {
    getReservationMeetings,
    getMeetingInvitations,
    cancelMeeting,
    // getHubReservtionShares
})(ReservationMeetings);