import React, { Fragment, lazy, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubMobileTraffic from '../../../components/hub/layout/hubMobileTraffic';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
import HubsTermsModal from '../../../components/hub/layout/HubsTermsModal';
import { getHubPage, changeHubIsOvernight } from '../../../store/actions/hub'
import { switchToProfileAccount } from '../../../store/actions/profile'
import { acceptHubsTerms } from '../../../store/actions/auth'
import { getMyProfile } from '../../../store/actions/profile';

import "../../../assets/hub/hub-homepage/hub-homepage.css"


export class HubHomepage extends Component {


    componentDidMount() {
        this.props.getMyProfile();
        this.props.getHubPage();
    }

    switchToProfileAccount() {
        this.props.switchToProfileAccount()
    }


    acceptHubsTerms() {
        this.props.acceptHubsTerms()
    }

    changeIsOvernight() {
        this.props.changeHubIsOvernight()
    }

    render() {
        if (this.props.auth.profile_redirect) {
            return <Redirect to="/my-profile" />
        }
        let profile = this.props.profile
        let profile_pic = ""
        if (profile.length > 0) {
            profile_pic = profile[0].profile_picture
        }

        let is_overnight = false
        if (this.props.hub.length > 0) {
            is_overnight = this.props.hub[0].is_overnight
        }

        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "hub-homepage-lottie-appear" : "hub-homepage-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                {this.props.hub.map(hub => (
                    <>
                        <HubDesktopNavbar data={{ sellected: "" }} />
                        {/* <div class={this.props.isLoading ? "hub-homepage-section-disappear" : null}> */}
                        <div>
                            <div class="whole-hub-homepage-page-layout">
                                <OfflineLabel />



                                {hub.hubs_terms_accepted === false ?
                                    <>
                                        <div class="hub-homepage-welcome-title">
                                            <div onClick={() => this.switchToProfileAccount()} class="hub-homepage-back-box">
                                                <div class="hub-homepage-back-icon-box">
                                                    <img
                                                        class="hub-homepage-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="hub-homepage-profile-pic-box">
                                                    {profile_pic ?
                                                        <img
                                                            class="hub-homepage-profile-pic"
                                                            src={profile_pic}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="hub-homepage-profile-pic"
                                                            src={require("../../../assets/authentication/svg/account.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            {hub.name}

                                        </div>

                                        <div class="hub-homepage-explaing-text">
                                            You are now interacting as <br />
                                            <b>{hub.name}</b>
                                        </div>

                                        <div class="hub-homepage-terms-icon-box">
                                            <img
                                                class="hub-homepage-terms-icon-img"
                                                src={require("../../../assets/hub/hub-layout/icons/open.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div class="hub-homepage-terms-top-text">
                                            In order to use the Business Site, you confirm that you have read,
                                            understood and accepted the
                                            &nbsp;<Link to="/hubs-terms"><a class="" href="#">Hubs Terms</a></Link>.
                                            By accepting the
                                            &nbsp;<Link to="/hubs-terms"><a class="" href="#">Hubs Terms</a></Link>,
                                            you agree and understand that today is the date which you are creating
                                            your Business Account.
                                        </div>

                                        <Link to="/setup-new-place"
                                            // onClick={() => this.acceptHubsTerms()}
                                            class="hub-homepage-terms-accept">
                                            Confirm & Continue
                                        </Link>
                                    </>
                                    // <Modal visible={this.state.hubsTermsModalVisible} width="100" height="100" effect="fadeInUp">
                                    // <div class="login-hubs-terms-box">
                                    //     <div class="row">
                                    //         <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                    //         </div>
                                    //         <div class="hubs-terms-box-6">
                                    //             <img
                                    //                 class="login-hubs-terms-icon"
                                    //                 src={require("../../../assets/hub/hub-layout/icons/open.svg")}
                                    //                 alt=""
                                    //             />
                                    //             <p class="login-hubs-terms-top-text">
                                    //                 In order to use the Business Site, you confirm that you have read,
                                    //                 understood and accepted the
                                    //                 &nbsp;<Link to="/hubs-terms"><a class="" href="#">Hubs Terms</a></Link>.
                                    //                 By accepting the
                                    //                 &nbsp;<Link to="/hubs-terms"><a class="" href="#">Hubs Terms</a></Link>,
                                    //                 you agree and understand that today is the date which you are creating
                                    //                 your Business Account.
                                    //             </p>
                                    //             <div class="">
                                    //                 <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center mt-4">
                                    //                     <div
                                    //                         onClick={() => this.acceptHubsTerms()}
                                    //                         class="btn accept-hubs-terms-btn-success">
                                    //                         Confirm
                                    //                     </div>
                                    //                 </div>

                                    //             </div>
                                    //         </div>
                                    //         <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    // </Modal>
                                    :

                                    <>

                                        <div class="hub-homepage-welcome-title">
                                            <div onClick={() => this.switchToProfileAccount()} class="hub-homepage-back-box">
                                                <div class="hub-homepage-back-icon-box">
                                                    <img
                                                        class="hub-homepage-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="hub-homepage-profile-pic-box">
                                                    {profile_pic ?
                                                        <img
                                                            class="hub-homepage-profile-pic"
                                                            src={profile_pic}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="hub-homepage-profile-pic"
                                                            src={require("../../../assets/authentication/svg/account.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            {hub.map_icon === "pharmacy" ?
                                                <label class="hub-homepage-pharmacy-switch">
                                                    <input
                                                        onChange={() => this.changeIsOvernight()}
                                                        type="checkbox"
                                                        checked={is_overnight}
                                                    />
                                                    <span class="hub-homepage-pharmacy-slider hub-homepage-pharmacy-round">
                                                        {is_overnight ?
                                                            <div class="hub-homepage-pharmacy-overnight-text">
                                                                Overnight
                                                            </div>
                                                            :
                                                            <div class="hub-homepage-pharmacy-overnight-text">
                                                                Not Overnight
                                                            </div>
                                                        }
                                                    </span>
                                                </label>
                                                :
                                                <>
                                                    {hub.name}
                                                </>
                                            }
                                        </div>


                                        <div class="hub-homepage-explaing-text">
                                            You are now interacting as <br />
                                            <b>{hub.name}</b>
                                        </div>

                                        <div class="hub-homepage-logo-box">
                                            <img
                                                class="hub-homepage-logo-img"
                                                src={hub.logo}
                                                alt=""
                                            />
                                        </div>


                                        <div class="hub-homepage-question-box">

                                            {!hub.reservation_acceptance && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                                <div class="hub-homepage-no-hubst3r-box">
                                                    <div class="hub-homepage-no-hubst3r-text">
                                                        You choose not<br />to accept Reservations
                                                    </div>
                                                </div>
                                                : hub.reservation_acceptance && hub.another_app_reservations && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?

                                                    <div class="hub-homepage-no-hubst3r-box">
                                                        <div class="hub-homepage-no-hubst3r-text2">
                                                            You accept Reservations through:<br />
                                                            <b>{hub.reservation_app_url}</b>
                                                        </div>
                                                    </div>
                                                    : hub.reservation_acceptance && hub.telephone_reservations && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                                        <div class="hub-homepage-no-hubst3r-box">
                                                            <div class="hub-homepage-no-hubst3r-text2">
                                                                You accept Appointments through:<br />
                                                                <b>{hub.telephone}</b>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                            }

                                            {!hub.appointment_acceptance && hub.place_type === "Services" ?
                                                <div class="hub-homepage-no-hubst3r-box">
                                                    <div class="hub-homepage-no-hubst3r-text">
                                                        You choose not<br />to accept Appointments
                                                    </div>
                                                </div>
                                                : hub.appointment_acceptance && hub.another_app_appointments && hub.place_type === "Services" ?

                                                    <div class="hub-homepage-no-hubst3r-box">
                                                        <div class="hub-homepage-no-hubst3r-text2">
                                                            You accept Appointments through:<br />
                                                            <b>{hub.appointment_app_url}</b>
                                                        </div>
                                                    </div>
                                                    : hub.appointment_acceptance && hub.telephone_appointments && hub.place_type === "Services" ?
                                                        <div class="hub-homepage-no-hubst3r-box">
                                                            <div class="hub-homepage-no-hubst3r-text2">
                                                                You accept Appointments through:<br />
                                                                <b>{hub.telephone}</b>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                            }

                                            {hub.hubst3r_reservations && hub.reservation_acceptance && ((hub.place_type === "Hospitality" || hub.place_type === "Other")) ?
                                                <div class="hub-homepage-question-left">
                                                    <Link to="/pending-reservations">
                                                        <div onTouchStart class="hub-homepage-question-btn">
                                                            <div class="hub-homepage-question-icon-box">
                                                                <img
                                                                    class="hub-homepage-question-icon"
                                                                    src={require("../../../assets/hub/hub-homepage/svg/requests.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            {hub.hub_reservation_requests !== 0 ?
                                                                <div class="hub-homepage-notification-number">
                                                                    {hub.hub_reservation_requests}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div class="hub-homepage-question-text">
                                                                Requests
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                : hub.place_type === "Services" && hub.hubst3r_appointments ?
                                                    <div class="hub-homepage-question-left">
                                                        <Link to="/pending-appointments">
                                                            <div onTouchStart class="hub-homepage-question-btn">
                                                                <div class="hub-homepage-question-icon-box">
                                                                    <img
                                                                        class="hub-homepage-question-icon"
                                                                        src={require("../../../assets/hub/hub-homepage/svg/requests.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                {/* {hub.hub_reservation_requests !== 0 ?
                                                                <div class="hub-homepage-notification-number">
                                                                    {hub.hub_reservation_requests}
                                                                </div>
                                                                :
                                                                null
                                                            } */}
                                                                <div class="hub-homepage-question-text">
                                                                    Requests
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    : hub.place_type === "Shops" ?
                                                        <>
                                                            {hub.map_icon === "gas station" ?
                                                                <div class="hub-homepage-question-left">
                                                                    <Link to="/set-gas-prices">
                                                                        <div onTouchStart class="hub-homepage-question-btn">
                                                                            <div class="hub-homepage-question-icon-box">
                                                                                <img
                                                                                    class="hub-homepage-question-icon"
                                                                                    src={require("../../../assets/hub/hub-homepage/svg/gas.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>

                                                                            <div class="hub-homepage-question-text">
                                                                                Gas Prices
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                                :
                                                                <div class="hub-homepage-question-left">
                                                                    <Link to="/set-sales">
                                                                        <div onTouchStart class="hub-homepage-question-btn">
                                                                            <div class="hub-homepage-question-icon-box">
                                                                                <img
                                                                                    class="hub-homepage-question-icon"
                                                                                    src={require("../../../assets/hub/hub-homepage/svg/sales.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>

                                                                            <div class="hub-homepage-question-text">
                                                                                Offers
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        null
                                            }

                                            {hub.hubst3r_reservations && hub.reservation_acceptance && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                                <div class="hub-homepage-question-right">
                                                    <Link to="/approved-reservations">
                                                        <div onTouchStart class="hub-homepage-question-btn">
                                                            <div class="hub-homepage-question-icon-box">
                                                                <img
                                                                    class="hub-homepage-question-icon"
                                                                    src={require("../../../assets/hub/hub-homepage/svg/reservations.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            {hub.hub_reservation_today_number !== 0 ?
                                                                <div class="hub-homepage-notification-number">
                                                                    {hub.hub_reservation_today_number}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div class="hub-homepage-question-text">
                                                                Reservations
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                : hub.place_type === "Services" && hub.hubst3r_appointments ?
                                                    <div class="hub-homepage-question-right">
                                                        <Link to="/approved-appointments">
                                                            <div onTouchStart class="hub-homepage-question-btn">
                                                                <div class="hub-homepage-question-icon-box">
                                                                    <img
                                                                        class="hub-homepage-question-icon"
                                                                        src={require("../../../assets/hub/hub-homepage/svg/reservations.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                {/* {hub.hub_reservation_today_number !== 0 ?
                                                            <div class="hub-homepage-notification-number">
                                                                {hub.hub_reservation_today_number}
                                                            </div>
                                                            :
                                                            null
                                                        } */}
                                                                <div class="hub-homepage-question-text">
                                                                    Appointments
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    : hub.place_type === "Shops" ?
                                                        <div class="hub-homepage-question-right">
                                                            <Link to="/set-online-shop">
                                                                <div onTouchStart class="hub-homepage-question-btn">
                                                                    <div class="hub-homepage-question-icon-box">
                                                                        <img
                                                                            class="hub-homepage-question-icon"
                                                                            src={require("../../../assets/hub/hub-homepage/svg/online-shop.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>

                                                                    <div class="hub-homepage-question-text">
                                                                        Online Shop
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        :
                                                        null
                                            }

                                            <div class="hub-homepage-question-bottom-left">
                                                <Link to={{ pathname: `/menu-page-view/${hub.id}`, state: { hub: hub.id } }}>
                                                    <div onTouchStart class="hub-homepage-question-btn">
                                                        <div class="hub-homepage-question-icon-box">
                                                            <img
                                                                class="hub-homepage-question-icon"
                                                                src={require("../../../assets/hub/hub-homepage/svg/menu.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        {(hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                                            <div class="hub-homepage-question-text">
                                                                Menu
                                                            </div>
                                                            :
                                                            <div class="hub-homepage-question-text">
                                                                Catalog
                                                            </div>
                                                        }
                                                    </div>
                                                </Link>
                                            </div>

                                            <div class="hub-homepage-question-bottom-right">
                                                <Link to="/information">
                                                    <div onTouchStart class="hub-homepage-question-btn">
                                                        <div class="hub-homepage-question-icon-box">
                                                            <img
                                                                class="hub-homepage-question-icon"
                                                                src={require("../../../assets/buddies/svg/hub-search.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="hub-homepage-question-text">
                                                            Profile
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                        </div>
                                    </>
                                }



                            </div >

                        </div >


                        {hub.hubs_terms_accepted === false ?
                            null
                            :
                            <>
                                {hub.hubst3r_reservations && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                    <Link to="/add-reservation">
                                        <div class="hub-homepage-create-reservation-btn">
                                            New Reservation
                                        </div>
                                    </Link>
                                    : hub.place_type === "Services" && hub.hubst3r_appointments ?
                                        <Link to="/add-appointment">
                                            <div class="hub-homepage-create-reservation-btn">
                                                New Appointment
                                            </div>
                                        </Link>
                                        :
                                        null
                                }
                            </>
                        }
                    </>
                ))}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    profile: state.profile.profile,
});

export default connect(mapStateToProps, {
    getHubPage,
    getMyProfile,
    switchToProfileAccount,
    acceptHubsTerms,
    changeHubIsOvernight
})(HubHomepage);