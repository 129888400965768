import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    setHubReservations,
    selectHubReservationHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';


export class SetReservation extends Component {

    state = {
        halfHourBox: false,
        quarterHourBox: false,
        specificHourBox: false,
        acceptReservationSection: false,
        acceptingReservations: false,
        throughReservations: "",
        reservation_app_url: "",
        reservationHoursMode: false,
        redirect: false,
        specificHourMode: false,
        hour: "18",
        minutes: "00",
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

    answerReservationAcceptance(answer) {
        const id = this.props.hub[0].id
        const reservation_acceptance = answer
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const telephone_reservations = false
        const another_app_reservations = true
        const reservation_app_url = this.state.reservation_app_url
        const hub = { id, appointment_acceptance, telephone_reservations, another_app_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
        this.setState({
            redirect: true,
        })

    }


    answerReservationAcceptanceHubst3r() {
        this.setState({
            reservationHoursMode: true
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = true
        const telephone_reservations = false
        const another_app_reservations = false
        const reservation_app_url = ""
        const hub = { id, appointment_acceptance, another_app_reservations, telephone_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }


    finishedOtherResercations() {
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const telephone_reservations = false
        const another_app_reservations = true
        const reservation_app_url = this.state.reservation_app_url
        const hub = { id, appointment_acceptance, telephone_reservations, another_app_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
        setTimeout(() => {
            this.setState({
                redirect: true
            })
        }, 500);
    }

    answerReservationAcceptanceTelephone() {
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const telephone_reservations = true
        const another_app_reservations = false
        const reservation_app_url = ""
        const hub = { id, appointment_acceptance, telephone_reservations, another_app_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
        setTimeout(() => {
            this.setState({
                redirect: true
            })
        }, 500);
    }


    answerAcceptingReservations() {
        this.setState({
            acceptingReservations: true,
        })
    }

    answerThroughReservation(answer) {
        this.setState({
            throughReservations: answer,
        })
    }

    answerReservationHours(choise) {
        const id = this.props.hub[0].id
        const reservation_hours_choise = choise
        const hub = { id, reservation_hours_choise }
        this.props.selectHubReservationHours(hub)
        if (choise === "specific") {
            this.setState({
                specificHourMode: true
            })
        } else {
            setTimeout(() => {
                this.setState({
                    redirect: true
                })
            }, 500);
        }
    }

    addNewSpecificHour() {
        let hour = this.state.hour
        let minutes = this.state.minutes
        const time = hour + ":" + minutes

        if (hour > 23 && hour > -1) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Hour" });
        } else if (minutes > 59) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Minutes" });
        } else {
            const specific_time = { time }
            this.props.createNewSpecificReservationHour(specific_time)
        }
    }

    removeSpecificHour(id, time) {
        const specific_time = { id, time }
        this.props.removeSpecificReservationHour(specific_time)
    }

    removeSpecificAppointmentHour(id, time) {
        const specific_time = { id, time }
        this.props.removeSpecificAppointmentHour(specific_time)
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/information' }} />
        }

        const defaultOptions = {
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <Fragment>

                {this.props.hub.map(hub => (
                    <>
                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />

                                <>

                                    <div class="setup-page-welcome-title">
                                        <Link to="/hub-homepage" class="setup-page-back-box">
                                            <div class="setup-page-back-icon-box">
                                                <img
                                                    class="setup-page-back-icon"
                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        Set Reservations
                                    </div>
                                    <>
                                        {this.state.acceptingReservations ?
                                            <>
                                                {this.state.throughReservations === "other" ?
                                                    <>

                                                        <div class="setup-page-info-text">
                                                            Type the url to the app you want people to request Reservation through?
                                                        </div>

                                                        <div class="setup-page-info-input-box">
                                                            <input
                                                                class='setup-page-name-input-field'
                                                                name="reservation_app_url"
                                                                onChange={this.onChange}
                                                                placeholder="Reservations APP URL..."
                                                                value={this.state.reservation_app_url === "" ? hub.reservation_app_url : this.state.reservation_app_url}
                                                            />
                                                        </div>
                                                        <div onClick={() => this.finishedOtherResercations(false)} class="setup-page-info-done">
                                                            Done
                                                        </div>

                                                    </>
                                                    :
                                                    <>
                                                        {this.state.reservationHoursMode ?
                                                            <>
                                                                <div class="setup-page-logo-text">
                                                                    Set the available Hours for Reservations
                                                                </div>
                                                                {this.state.specificHourMode ?
                                                                    <>

                                                                        <div class="row setup-page-res-hours-row">
                                                                            <div class="row setup-page-res-hours-margin">
                                                                                <input
                                                                                    class='setup-page-res-hours-hour'
                                                                                    name="hour"
                                                                                    onChange={this.onChange}
                                                                                    value={this.state.hour}
                                                                                    type="number"
                                                                                    min="00"
                                                                                    max="23"
                                                                                />
                                                                                <div class="setup-page-res-hours-middle">
                                                                                    :
                                                                                </div>
                                                                                <input
                                                                                    class='setup-page-res-hours-hour'
                                                                                    name="minutes"
                                                                                    onChange={this.onChange}
                                                                                    value={this.state.minutes}
                                                                                    type="number"
                                                                                    min="00"
                                                                                    max="60"
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                onClick={() => this.addNewSpecificHour()}
                                                                                class="setup-page-res-hours-add">
                                                                                Add
                                                                            </div>
                                                                        </div>


                                                                        <div class="row setup-page-created-hours-box">
                                                                            {this.props.reservation_hours.map(hour => (
                                                                                <div class="setup-page-created-hour">
                                                                                    {hour.time}
                                                                                    <div
                                                                                        onClick={() => this.removeSpecificHour(hour.id, hour.time)}
                                                                                        class="setup-page-created-hour-remove">
                                                                                        x
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>

                                                                        <Link to="/information" class="setup-page-new-hour-done">
                                                                            Done
                                                                        </Link>
                                                                    </>
                                                                    :
                                                                    <div class="setup-page-reservation-hour-all-choises">

                                                                        <div onClick={() => this.answerReservationHours("quarter")} class="setup-page-reservation-hour-choise">
                                                                            Every 15 minutes  <br />(i.e. 19:00, 19:15, 19:30, 19:45)
                                                                        </div>

                                                                        <div onClick={() => this.answerReservationHours("half")} class="setup-page-reservation-hour-choise">
                                                                            Every 30 minutes <br />(i.e. 19:30, 20:00, 20:30, 21:00)
                                                                        </div>

                                                                        <div onClick={() => this.answerReservationHours("hourly")} class="setup-page-reservation-hour-choise">
                                                                            Every 1 hour <br />(i.e. 18:00, 19:00, 20:00, 21:00)
                                                                        </div>

                                                                        <div onClick={() => this.answerReservationHours("specific")} class="setup-page-reservation-hour-choise">
                                                                            Specific Hours <br />(i.e. at 15:00, at 19:00, at 21:00)
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <div class="setup-page-reservation-question-text">
                                                                    Where do you like people to be able to Request a Reseration?
                                                                </div>

                                                                <div class="setup-page-reservation-question-box">
                                                                    <div onClick={() => this.answerThroughReservation("other")} class="setup-page-reservation-through-other">
                                                                        Through another app
                                                                    </div>
                                                                    <div onClick={() => this.answerReservationAcceptanceHubst3r()} class="setup-page-reservation-through-hubst3r">
                                                                        Through Hubst3r
                                                                    </div>
                                                                    <div onClick={() => this.answerReservationAcceptanceTelephone()} class="setup-page-reservation-through-telephone">
                                                                        Only Through Telephone
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <div class="setup-page-reservation-question-text">
                                                    Do you accept Reservations?
                                                </div>

                                                <div class="setup-page-reservation-question-box">
                                                    <div onClick={() => this.answerReservationAcceptance(false)} class="setup-page-reservation-question-no">
                                                        No
                                                    </div>
                                                    <div onClick={() => this.answerAcceptingReservations(true)} class="setup-page-reservation-question-yes">
                                                        Yes
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                </>
                            </div>
                        </div>

                        <Link to="/information"
                            //  onClick={() => this.acceptHubsTerms()} 
                            class="setup-page-next-btn">
                            Done
                        </Link>


                    </>
                ))}

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    reservation_hours: state.hub.reservation_hours,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    setHubReservations,
    selectHubReservationHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
})(SetReservation);