import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import "../../../assets/hub/hub-page/enjoy-here-modal.css"


export class ProfilePictureModal extends Component {

    state = {
        reaction_sellected: false,
        reaction: "",
        show_reaction: false,
    }

    closeModal = () => {
        this.props.data.closeModal()
    }

    updateReactionSelection = (selected_reaction) => (e) => {
        this.setState({
            reaction_sellected: true,
            reaction: selected_reaction
        })
        // const reacted_profile_pic_id = memory_id
        // const reaction_status = selected_reaction
        // const reaction = { reaction_status, reacted_profile_pic_id }
        // this.props.createMemoryReaction(reaction)
        setTimeout(() => {
            this.setState({
                reaction_sellected: false,
                show_reaction: true,
            })
        }, 800);

    }

    render() {
        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="enjoy-here-box">
                            <img
                                onClick={() => this.closeModal()}
                                class="enjoy-here-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <img
                                class="enjoy-here-photo-layout-cover"
                                src={this.props.data.profile_picture}
                                alt=""
                            />
                            <img
                                onDoubleClick={this.updateReactionSelection("love")}
                                class="enjoy-here-photo-layout"
                                src={this.props.data.profile_picture}
                                alt=""
                            />

                            {this.state.reaction_sellected ?
                                <img
                                    class="enjoy-here-like-layout"
                                    src={require("../../../assets/profile/svg/gif-heart.gif")}
                                    alt=""
                                />
                                :
                                null
                            }

                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}


export default (ProfilePictureModal);