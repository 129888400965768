import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginToken, login } from '../../../store/actions/auth';
import { createAlert } from '../../../store/actions/alerts'
import CookiesModal from '../../../components/user/layout/cookiesModal';
import Modal from 'react-awesome-modal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import '../../../assets/authentication/login.css'


export class Login extends Component {
    state = {
        credentials: '',
        password: '',
        passwordModalVisible: false,
        video_finished: false,
        audio_finished: true,
        isLoading: false,
        showSendAgain: false,
        active_code: false,
        btnAppear: true,
    };

    componentWillMount() {
        if (localStorage.getItem('lgvshn')) {
            this.setState({
                video_finished: true,
                audio_finished: true,
            })
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                video_finished: true,
                audio_finished: true,
            })
            localStorage.setItem('lgvshn', JSON.stringify("True"));
        }, 5000);

        const previous_code_request = JSON.parse(localStorage.getItem("lgncrdtls"))
        if (previous_code_request) {
            const now = new Date()
            // compare the expiry time of the item with the current time
            if (now.getTime() > previous_code_request.expiry) {
                // If the 6 digit password is expired, delete the item from storage
                localStorage.removeItem("lgncrdtls")
            } else {
                this.setState({
                    active_code: true,
                    passwordModalVisible: true
                })
            }
        }
    }


    onCredentialsSubmit = () => (e) => {
        this.setState({
            isLoading: true,
            btnAppear: false,
        })
        e.preventDefault();
        const { credentials } = this.state;
        if (credentials === "") {
            this.props.createAlert({ noCridentialsGiven: "You haven't given an Email address or a Username!" });
        } else {
            setTimeout(() => {
                this.setState({
                    isLoading: false,
                })
            }, 5000);
            this.props.loginToken(credentials);
            setTimeout(() => {
                if (this.props.correct_credentials) {
                    this.openModal()
                    // // keep the pop up open if there is an active code sent to the email
                    const now = new Date()
                    const after_ten_minutes = 578612
                    const item = {
                        value: credentials,
                        expiry: now.getTime() + after_ten_minutes,
                    }
                    localStorage.setItem("lgncrdtls", JSON.stringify(item))
                }
            }, 5000);
            setTimeout(() => {
                this.setState({
                    showSendAgain: true,
                })
            }, 180000);
        }
        setTimeout(() => {
            this.setState({
                btnAppear: true,
            })
        }, 2000);
    };

    onPressEnter = () => (e) => {
        if (e.charCode === 13) {
            this.setState({
                isLoading: true,
                btnAppear: false,
            })
            e.preventDefault();
            const { credentials } = this.state;
            if (credentials === "") {
                this.props.createAlert({ noCridentialsGiven: "You haven't given an Email address or a Username!" });
            } else {
                setTimeout(() => {
                    this.setState({
                        isLoading: false,
                    })
                }, 5000);
                this.props.loginToken(credentials);
                setTimeout(() => {
                    if (this.props.correct_credentials) {
                        this.openModal()
                        // // keep the pop up open if there is an active code sent to the email
                        const now = new Date()
                        const after_ten_minutes = 578612
                        const item = {
                            value: credentials,
                            expiry: now.getTime() + after_ten_minutes,
                        }
                        localStorage.setItem("lgncrdtls", JSON.stringify(item))
                    }
                }, 5000);

                setTimeout(() => {
                    this.setState({
                        showSendAgain: true,
                    })
                }, 180000);
            }
            setTimeout(() => {
                this.setState({
                    btnAppear: true,
                })
            }, 2000);
        }

    }

    onPressSendAgain = () => (e) => {
        this.setState({
            showSendAgain: false,
        })
        // // renew the keep the pop up open if there is an active code sent to the email
        const now = new Date()
        const after_ten_minutes = 578612
        const item = {
            value: JSON.parse(localStorage.getItem("lgncrdtls")).value,
            expiry: now.getTime() + after_ten_minutes,
        }
        localStorage.setItem("lgncrdtls", JSON.stringify(item))
        setTimeout(() => {
            this.setState({
                showSendAgain: true,
            })
        }, 180000);
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    openModal() {
        this.setState({
            passwordModalVisible: true,
            active_code: true
        });
    }

    closeModal() {
        this.setState({
            credentials: '',
            password: '',
            passwordModalVisible: false,
            active_code: false
        });
        localStorage.removeItem("lgncrdtls")
    }

    submitCallbackToken = () => (e) => {
        this.setState({
            active_code: true,
            passwordModalVisible: true
        })
        e.preventDefault();
        let credentials = this.state.credentials;
        const previous_code_request = JSON.parse(localStorage.getItem("lgncrdtls"))
        if (credentials === "") {
            credentials = previous_code_request.value
        }
        const { password } = this.state;
        if (password === "") {
            this.props.createAlert({ noCridentialsGiven: "You haven't given the 6 Digit code sent to your email!" });
        } else {
            this.openModal()
            this.props.login(credentials, password);
            localStorage.removeItem("lgncrdtls")
        }
    };

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        const { credentials, password } = this.state;
        let active_credentials = false
        if (this.state.active_code) {
            active_credentials = true
        }

        return (
            < Fragment >
                {/* {this.state.video_finished ? */}
                <div>
                    {/* <div class={this.state.isLoading ? "user-register-lottie-appear" : "user-register-lottie-dissappear"}>
                            <div class="register-lottie-position">
                                <Hubst3rLottie />
                            </div>
                        </div> */}


                    {/* <div class={this.state.isLoading ? "user-register-section-disappear" : null}> */}
                    <div>
                        <div class="login-whole-page-layout">
                            <CookiesModal />

                            {active_credentials ?
                                <Modal visible={this.state.passwordModalVisible} width="100" height="100" effect="fadeInUp">
                                    <div class="authentication-password-box">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                            </div>

                                            <div class="authentication-box-6">
                                                <p class="login-modal-top-text">Enter the 6-digit Code <u><b>ALREADY</b></u> sent to your <u><b>EMAIL. </b></u><u><b>Check also your Spam</b></u></p>

                                                <div class="login-form-input-layout">
                                                    <div class="form-group">
                                                        <input
                                                            type="text"
                                                            onChange={this.onChange}
                                                            value={password}
                                                            maxlength="6"
                                                            class="modal-password-field"
                                                            name="password"
                                                            placeholder="Enter the Code here"
                                                        // required
                                                        />
                                                    </div>

                                                    <br />
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center mt-4 continue-button-center">
                                                        <div
                                                            onTouchStart
                                                            onClick={this.submitCallbackToken()}
                                                            class="btn-login login-modal-btn-success">
                                                            Continue
                                                        </div>
                                                    </div>
                                                    {this.state.showSendAgain ?
                                                        <center onClick={this.onPressSendAgain()}>
                                                            <a
                                                                onClick={this.onCredentialsSubmit()}
                                                                class="send-again-a" href="#"
                                                            >
                                                                Send again
                                                            </a>
                                                        </center>
                                                        :
                                                        <center>
                                                            <div class="mail-spinner-layout">
                                                                <img
                                                                    class="mail-spinner-size"
                                                                    src={require("../../../assets/authentication/svg/mail-spinner.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </center>
                                                    }
                                                    <center class="restart-link-register">
                                                        <a
                                                            onClick={() => this.closeModal()}
                                                            class="send-again-a" href="#">
                                                            Restart
                                                        </a>
                                                    </center>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                :
                                null
                            }
                            <div class="form-color">
                                <div class="login-form-header text-center">
                                    <img
                                        class="hubst3r-login-word-size"
                                        src={require("../../../assets/authentication/svg/Logo-h.svg")}
                                        alt=""
                                    />
                                </div>

                                <div class="login-signup-form">
                                    <form onKeyPress={this.onPressEnter()} class="login-form">
                                        <div class="login-form-logo text-center">
                                            <img
                                                class="hubster-login-logo-size"
                                                src={require("../../../assets/authentication/svg/Logo.png")}
                                                alt=""
                                            />
                                        </div>
                                        <h2>Login</h2>
                                        <p class="hint-text login-top-text">Login to enjoy the most memorable Experiences with your Buddies 🥳</p>

                                        <div class="form-group">
                                            <input
                                                type="text"
                                                onChange={this.onChange}
                                                value={credentials}
                                                class="login-form-control"
                                                name="credentials"
                                                placeholder="Enter your username or your email"
                                            // required
                                            />
                                        </div>

                                        <div class="login-button-position">
                                            {this.state.btnAppear ?
                                                <div
                                                    onTouchStart
                                                    onEnter
                                                    onClick={this.onCredentialsSubmit()}
                                                    // type="submit"
                                                    class="btn-login btn-success btn-lg btn-block"
                                                >
                                                    Login
                                                </div>
                                                :
                                                <div
                                                    onTouchStart
                                                    onEnter
                                                    // type="submit"
                                                    class="btn-login btn-success btn-lg btn-block"
                                                >
                                                    Login
                                                </div>
                                            }
                                        </div>
                                        <div class="text-center login-account-have">
                                            Don't have an account? &nbsp;
                                            <Link to="/register">
                                                <a href="#">
                                                    Create one
                                                </a>
                                            </Link>
                                        </div>
                                        <div class="text-center login-account-have">
                                            You own a Shop that wants to be a Hub? &nbsp;
                                            <Link to="/hub-contact-us">
                                                <a href="#">
                                                    Yes
                                                </a>
                                            </Link>
                                        </div>

                                        <div class="row login-legal-position">
                                            <div class="col-xl-12 text-center mt-2">
                                                <Link to="/terms-of-use">
                                                    <a class="link2" href="#">Terms</a>
                                                </Link>
                                                <Link to="/privacy-policy">
                                                    <a class="link2" href="#">Privacy</a>
                                                </Link>
                                                <Link to="/cookies-policy">
                                                    <a class="link2" href="#">Cookies</a>
                                                </Link>
                                                <br />
                                                {/* <Link to="/about"> */}
                                                <a class="link2" href="http://about.hubst3r.com/">About</a>
                                                {/* </Link> */}
                                                <Link to="/licenses">
                                                    <a class="link2" href="#">Licenses</a>
                                                </Link>
                                            </div>

                                        </div>

                                        <br />
                                        <br />
                                        <br />
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* :
                    <div class="">
                        <img
                            class="welcome-video-background"
                            src={require("../../../assets/authentication/video/Intro Video.gif")}
                            alt=""
                        />
                    </div>
                } */}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    correct_credentials: state.auth.correct_credentials,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { loginToken, login, createAlert })(Login);