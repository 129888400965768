import React, { Component, Fragment } from 'react';
import "../../../assets/meeting/reservation-qr-modal.css"


export class QrModal extends Component {

    state = {
    }


    closeModal = () => {
        this.props.data.closeModal()
    }

    render() {
        return (
            <Fragment>

                <div class="qr-click-box">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>

                        <div class="reservation-qr-box">
                            <div class="reservation-qr-code-title">My Reservation's at {this.props.data.hub_name} QR Code</div>
                            <div
                                class="reservation-times-box"
                                onClick={() => this.closeModal()}
                            >
                                <img
                                    class="reservation-times-modal-size"
                                    src={require("../../../assets/layout/homepage svg/times.svg")}
                                    alt=""
                                />
                            </div>

                            <div class="reservation-qr-image-box">
                                <img class="reservation-qr-image-size" src={this.props.data.qr_code} alt="" />
                            </div>

                            <div class="reservation-qr-code-subtitle">
                                Show your QR code at {this.props.data.hub_name} at the date you made the Reservation and enjoy the magic
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}


export default (QrModal);