import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import NotificationSettingsModal from '../../../components/user/layout/notificationSettingsModal';
import { getNotificationsStatus } from '../../../store/actions/notification'
// import Modal from 'react-awesome-modal';
import "../../../assets/layout/desktopNavbar.css"


export class DesktopNavbar extends Component {
    state = {
        modalVisible: false,
    }

    componentWillMount() {
        if (JSON.parse(localStorage.getItem('ur'))) {
            this.props.getNotificationsStatus();
        }

    }

    openModal() {
        this.setState({
            modalVisible: true,
        });
    }

    closeModal() {
        this.setState({
            modalVisible: false,
        });
    }


    render() {
        const browser = navigator.userAgent.search("Chrome")
        let show_notification_modal = false
        if (browser === -1) {
            show_notification_modal = true
        }
        let new_notifications = ""
        let notification_status = this.props.notifications_status[0]
        if (notification_status) {
            new_notifications = notification_status["new_notifications"]
        }
        let user_profile = []
        if (JSON.parse(localStorage.getItem('urpfl'))) {
            user_profile = JSON.parse(localStorage.getItem('urpfl'))
        }
        // const user_profile = profile_query[0]
        const sellected = this.props.data.sellected
        return (
            <Fragment>
                <div class="body-left col-xl-2 col-lg-3" id="sticky-sidebar">
                    <div class="desktop-navbar-icons">
                        {/* {show_notification_modal ?
                            <div>
                                <Modal visible={this.state.modalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <NotificationSettingsModal
                                        data={{
                                            closeModal: this.closeModal.bind(this)
                                        }}
                                    />
                                </Modal>
                                <img
                                    class="profile-settings-icons-layout"
                                    onClick={() => this.openModal()}
                                    src={require("../../../assets/profile/svg/settings.svg")}
                                    alt=""
                                />
                            </div>
                            :
                            null
                        } */}

                    </div>
                    <Link to="/">
                        <img class="hubst3r-word-logo" src={require("../../../assets/meeting/svg/Logo.svg")} alt="" />
                    </Link>
                    <Link to="/my-profile">
                        {user_profile ?
                            <div>
                                {user_profile.map(profile => (
                                    <div class="row profile-detail">
                                        <div class="col-lg-4 profile-pic">
                                            {sellected === "profile" ?
                                                < img
                                                    class="desktop-profile-sellected"
                                                    src={require("../../../assets/layout/homepage svg/desktop-sellected-profile.svg")} alt=""
                                                    alt=""
                                                />
                                                :
                                                null
                                            }
                                            {profile.profile_picture ?
                                                <img
                                                    class="desktop-profile-picture-layout"
                                                    src={profile.profile_picture}
                                                    alt=""
                                                />
                                                :
                                                <img
                                                    class="desktop-profile-picture-layout"
                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                        <div class="col-lg-8 desktop-profile-text mt-2">
                                            <strong>{profile.username}</strong>
                                            {profile.full_name === "null" ?
                                                <p>Insert Full Name</p>
                                                : profile.full_name === null ?
                                                    <p>Insert Full Name</p>
                                                    : profile.full_name === "" ?
                                                        <p>Insert Full Name</p>
                                                        : profile.full_name === "undefined" ?
                                                            <p>Insert Full Name</p>
                                                            :
                                                            <p>{profile.full_name} </p>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div>
                                {this.props.data.profile.map(profile => (
                                    <div class="row profile-detail">
                                        <div class="col-lg-4 profile-pic">
                                            <img class="profile-picture-layout" src={profile.profile_picture} alt="" />
                                        </div>
                                        <div class="col-lg-8 profile-text mt-2">
                                            <strong>{profile.username}</strong>
                                            {profile.full_name ?
                                                <p> {profile.full_name} </p>
                                                :
                                                <p>Insert Full Name</p>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </Link>

                    <div class="row left-home py-3">
                        {sellected === "home" ?
                            <div class="col-xl-6 col-lg-6 left-icon text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/layout/homepage svg/desktop_sellected_home.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon text-right">
                                <img src={require("../../../assets/meeting/svg/home.svg")} alt="" />
                            </div>
                        }
                        <Link to="/">
                            <div class="col-xl-8 col-lg-8 left-text-desktop-navbar text-white mt-1">
                                {sellected === "home" ?
                                    <strong class="desktop-navbar-sellected">Home</strong>
                                    :
                                    <strong>Home</strong>
                                }
                            </div>
                        </Link>
                    </div>


                    <div class="row left-home py-3">
                        {sellected === "search" ?
                            <div class="col-xl-6 col-lg-6 left-icon text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/layout/homepage svg/desktop_sellected_search.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon text-right">
                                <img src={require("../../../assets/meeting/svg/Icon1.svg")} alt="" />
                            </div>
                        }

                        <Link to="/search-buddies">
                            <div class="col-xl-8 col-lg-8 left-text-desktop-navbar text-white mt-1">
                                {sellected === "search" ?
                                    <strong class="desktop-navbar-sellected">Search Buddies</strong>
                                    :
                                    <strong>Search Buddies</strong>
                                }
                            </div>
                        </Link>
                    </div>

                    <div class="row left-home py-3">
                        {sellected === "meetings" ?
                            <div class="col-xl-6 col-lg-6 left-icon text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/layout/homepage svg/desktop_sellected_meetings.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon text-right">
                                <img src={require("../../../assets/meeting/svg/meeting.svg")} alt="" />
                            </div>
                        }

                        <Link to="/meetings">
                            <div class="col-xl-8 col-lg-8 left-text-desktop-navbar text-white mt-1">
                                {sellected === "meetings" ?
                                    <strong class="desktop-navbar-sellected">Moments of Magic</strong>
                                    :
                                    <strong>Moments of Magic</strong>
                                }
                            </div>
                        </Link>
                    </div>

                    <div class="row left-home py-3">
                        {/* <div class="col-xl-4 col-lg-4 left-icon text-right"> */}
                        {sellected === "notification" ?
                            <div class="col-xl-6 col-lg-6 left-icon text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/layout/homepage svg/desktop_sellected_notification.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon text-right">
                                {new_notifications ?
                                    <img src={require("../../../assets/meeting/svg/alarm notitification.svg")} alt="" />
                                    :
                                    <img src={require("../../../assets/meeting/svg/alarm.svg")} alt="" />
                                }
                            </div>
                        }

                        {/* </div> */}
                        <Link to="/notifications">
                            <div class="col-xl-8 col-lg-8 left-text-desktop-navbar text-white mt-1">
                                {sellected === "notification" ?
                                    <strong class="desktop-navbar-sellected">Notifications</strong>
                                    :
                                    <strong>Notifications</strong>
                                }
                            </div>
                        </Link>
                    </div>

                </div>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    notifications_status: state.notification.notifications_status
});

export default connect(mapStateToProps, { getNotificationsStatus })(DesktopNavbar);