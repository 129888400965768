import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import { getMyProfile } from '../../../store/actions/profile';
import { getBuddies, searchBuddy } from '../../../store/actions/buddies'
import { getNotificationsStatus } from '../../../store/actions/notification';
import { sendVoiceMessage } from '../../../store/actions/chat';
import { Link, Redirect } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import "../../../assets/chat/chat-room.css"
import { render } from "react-dom";
import ChatRecorder from "../../../components/user/chat/chatRecorder";
import ChatVisualizer from "../../../components/user/chat/chatVisualizer";
import { SendMessageModal } from '../../../components/user/chat/sendMessageModal';
import Modal from 'react-awesome-modal';


export class ChatRoom extends Component {
    constructor(props) {
        super(props);
        this.state = { localStream: null };
    }


    state = {
        backgroundBlue: false,
        sendMessageModal: false,
        audioFile: "",
        text_message_file: "",
        conversation_redirect: false,
        changePage: false,
    };


    componentDidMount() {
        const id = JSON.parse(localStorage.getItem('ur')).id
        this.props.getBuddies(id);
        this.props.getMyProfile();
        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }

        if (window.navigator.mediaDevices) {
            window.navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                    console.log('You let me use your mic!')
                })
                .catch(function (err) {
                    console.log('No mic for you!')
                });
        }
    }

    changeBackgroundColor() {
        this.setState({
            backgroundBlue: !this.state.backgroundBlue
        })
    }

    handleBack = () => {
        this.setState({
            changePage: true,
        })
        this.props.history.goBack()
    }


    openSendMessageModal() {
        this.setState({
            sendMessageModal: true,
        })
    }

    closeModal = () => {
        this.setState({
            sendMessageModal: false,
        })
    }

    setSoundFile = (audioFile) => {
        this.setState({
            audioFile: audioFile,
        })
    }

    setTextMessageFile = (text_message_file) => {
        this.setState({
            text_message_file: text_message_file,
        })
    }

    setConversationRedirect = () => {
        this.setState({
            changePage: true,
        })
        setTimeout(() => {
            this.setState({
                conversation_redirect: true,
            })
        }, 1000);
    }

    startRecordingMedia = () => {
        // const that = this;
        // navigator.mediaDevices
        //     .getUserMedia({
        //         audio: true,
        //     })
        //     .then((stream) => {
        //         that.setState({ localStream: stream });
        //     });
    }

    stopRecordingMedia = () => {
        // window.navigator.getUserMedia(mediaConstraints, function (stream) {
        //     window.streamReference = stream;
        //     onMediaSuccess(stream);
        // }, onMediaError);


        if (!window.streamReference) return;
        window.streamReference.getAudioTracks().forEach(function (track) {
            track.stop();
        });
        window.streamReference = null;

    };

    render() {
        let selected_user = ""
        let selected_user_profile_pic = ""
        let selected_user_username = ""
        if (this.props.location.state.selected_user) {
            selected_user = this.props.location.state.selected_user
            selected_user_profile_pic = this.props.location.state.selected_user_profile_pic
            selected_user_username = this.props.location.state.selected_user_username
            if (this.state.conversation_redirect) {
                return <Redirect to={{ pathname: "/conversation-room", state: { selected_user: selected_user, selected_user_profile_pic: selected_user_profile_pic, selected_user_username: selected_user_username } }} />
            }
        }

        return (
            <Fragment>
                <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />

                {this.state.sendMessageModal ?
                    <Modal visible={this.state.sendMessageModal} width="0" height="0" effect="fadeInUp">
                        <SendMessageModal
                            data={{
                                closeModal: this.closeModal.bind(this),
                                buddies: this.props.buddies,
                                profile: this.props.profile,
                                audioFile: this.state.audioFile,
                                text_message_file: this.state.text_message_file,
                            }}
                            searchBuddy={this.props.searchBuddy}
                            getBuddies={this.props.getBuddies}
                            searchBuddy={this.props.searchBuddy}
                            sendVoiceMessage={this.props.sendVoiceMessage}
                        />
                    </Modal>
                    :
                    null
                }

                <div>
                    <div class="chat-room-total-page-layout">
                        <OfflineLabel />
                        <div class="chat-room-mobile-navbar">
                            <div onClick={() => this.handleBack()} class="back-button-chat-room-box">
                                <img
                                    class="chat-room-back-button"
                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                    alt=""
                                />
                            </div>
                            {selected_user !== "" ?
                                <>

                                    <Link to={{ pathname: `/user-profile/${selected_user}/profile` }} class="chat-room-sending-username-align">
                                        To:  &nbsp; &nbsp; &nbsp;&nbsp;
                                        {/* <Link to={{ pathname: `/user-profile/${selected_user}/profile` }}> */}
                                        {selected_user_username}
                                    </Link>
                                    {/* </div> */}

                                    <div class="chat-room-sending-profile-pic-box">
                                        <Link to={{ pathname: `/user-profile/${selected_user}/profile` }}>

                                            <img
                                                class="chat-room-sending-profile-pic-img"
                                                src={selected_user_profile_pic}
                                                alt=""
                                            />
                                        </Link>
                                    </div>

                                </>
                                :
                                <>
                                    Recording Room
                                </>
                            }
                        </div>

                        <div class="chat-room-sound-proof-box">
                            {this.state.backgroundBlue ?
                                <img
                                    class="chat-room-sound-proof-img"
                                    src={require("../../../assets/chat/svg/sound-proof-blue.png")}
                                    alt=""
                                />
                                :
                                <img
                                    class="chat-room-sound-proof-img"
                                    src={require("../../../assets/chat/svg/sound-proof-black.png")}
                                    alt=""
                                />
                            }
                        </div>


                        <ChatRecorder
                            data={{
                                profile: this.props.profile,
                                selected_user: selected_user,
                                selected_user_profile_pic: selected_user_profile_pic,
                                selected_user_username: selected_user_username,
                                changePage: this.state.changePage,
                                startRecordingMedia: this.startRecordingMedia.bind(this),
                                stopRecordingMedia: this.stopRecordingMedia.bind(this),
                                changeBackgroundColor: this.changeBackgroundColor.bind(this),
                                setSoundFile: this.setSoundFile.bind(this),
                                setTextMessageFile: this.setTextMessageFile.bind(this),
                                openSendMessageModal: this.openSendMessageModal.bind(this),
                                setConversationRedirect: this.setConversationRedirect.bind(this),
                            }}
                            sendVoiceMessage={this.props.sendVoiceMessage}
                        />


                    </div>
                </div>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    notifications_status: state.notification.notifications_status,
    buddies: state.buddies.buddies,
    profile: state.profile.profile,
});

export default connect(mapStateToProps, {
    getMyProfile,
    getNotificationsStatus,
    getMyProfile,
    getBuddies,
    searchBuddy,
    sendVoiceMessage,

})(ChatRoom);