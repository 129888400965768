import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/map/place-claim-modal.css"


export class MapLayoutModal extends Component {

    state = {
        selected_layout: "",
    }

    componentDidMount() {
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }


    closeModal() {
        this.props.data.closeModal()
    }

    selectMapLayout(layout) {
        localStorage.setItem('map_layout', JSON.stringify(layout));
        this.props.data.changeMapLayout(layout)
        this.props.data.closeModal()
    }


    render() {

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const sliderSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };
        return (
            <Fragment>
                <div class="map-search-hub-contact-box">
                    <div onClick={() => this.closeModal()} class="map-search-acces-modal-times-box">
                        <img
                            class="map-search-acces-modal-times-img"
                            src={require("../../../assets/layout/homepage svg/times.svg")}
                            alt=""
                        />
                    </div>


                    <div class="map-search-map-layout-modal-title">
                        Select the way you want the map to appear
                    </div>

                    <div class="map-search-map-layout-modal-slider">
                        <Slider {...sliderSettings} >
                            <>
                                <div
                                    // style={{ width: "10px", height: width + "10px", marginTop: "10px" }}
                                    class="map-search-map-layout-modal-frame"
                                    onClick={() => this.selectMapLayout("mapbox://styles/mapbox/dark-v10")}
                                >
                                    <img
                                        class="map-search-map-layout-modal-map-img"
                                        src={require("../../../assets/map/svg/map_black.png")}
                                        alt=""
                                    />
                                </div>
                                <div class="map-search-category-name">
                                    Black
                                </div>
                            </>

                            <>
                                <div
                                    // style={{ width: "10px", height: width + "10px", marginTop: "10px" }}
                                    class="map-search-map-layout-modal-frame"
                                    onClick={() => this.selectMapLayout("mapbox://styles/kleitos14/cl26jbn1e00gi14pivdtce0oa")}
                                >
                                    <img
                                        class="map-search-map-layout-modal-map-img"
                                        src={require("../../../assets/map/svg/basic-map.png")}
                                        alt=""
                                    />
                                </div>
                                <div class="map-search-category-name">
                                    Basic
                                </div>
                            </>

                            {/* <>
                                <div
                                    // style={{ width: "10px", height: width + "10px", marginTop: "10px" }}
                                    class="map-search-map-layout-modal-frame"
                                    onClick={() => this.selectMapLayout("mapbox://styles/mapbox/satellite-v9")}
                                >
                                    <img
                                        class="map-search-map-layout-modal-map-img"
                                        src={require("../../../assets/map/svg/map_satellite.png")}
                                        alt=""
                                    />
                                </div>
                                <div class="map-search-category-name">
                                    Satellite
                                </div>
                            </> */}

                            {this.props.data.dashboard_access ?
                                <>
                                    <div
                                        // style={{ width: "10px", height: width + "10px", marginTop: "10px" }}
                                        class="map-search-map-layout-modal-frame"
                                        onClick={() => this.selectMapLayout("mapbox://styles/mapbox/streets-v8")}
                                    >
                                        <img
                                            class="map-search-map-layout-modal-map-img"
                                            src={require("../../../assets/map/svg/street-map.png")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="map-search-category-name">
                                        Streets
                                    </div>
                                </>
                                :
                                null
                            }
                        </Slider>
                    </div>

                </div>

            </Fragment >
        )
    }
}


export default (MapLayoutModal);