import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import { acceptHubsTerms } from '../../../store/actions/auth'
import '../../../assets/hub/hub-layout/hub-terms-modal.css'
// assets/hub/hub-layout/hub-terms-modal.css'


export class HubsTermsModal extends Component {

    state = {
        hubsTermsModalVisible: true,
    };

    acceptHubsTerms() {
        const { hubs_terms_accepted } = true
        this.props.acceptHubsTerms()
        this.setState({
            hubsTermsModalVisible: false,
        })
    }

    render() {
        const hubsTermsShow = this.props.data.hub.hubs_terms_accepted
        return (
            <Fragment>
                {hubsTermsShow === false ?
                    <Modal visible={this.state.hubsTermsModalVisible} width="100" height="100" effect="fadeInUp">
                        <div class="login-hubs-terms-box">
                            <div class="row">
                                <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                </div>
                                <div class="hubs-terms-box-6">
                                    <img
                                        class="login-hubs-terms-icon"
                                        src={require("../../../assets/hub/hub-layout/icons/open.svg")}
                                        alt=""
                                    />
                                    <p class="login-hubs-terms-top-text">
                                        In order to use the Business Site, you confirm that you have read,
                                        understood and accepted the
                                        &nbsp;<Link to="/hubs-terms"><a class="" href="#">Hubs Terms</a></Link>.
                                        By accepting the
                                        &nbsp;<Link to="/hubs-terms"><a class="" href="#">Hubs Terms</a></Link>,
                                        you agree and understand that today is the date which you are creating
                                        your Business Account.
                                    </p>
                                    <div class="">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center mt-4">
                                            <div
                                                onClick={() => this.acceptHubsTerms()}
                                                class="btn accept-hubs-terms-btn-success">
                                                Confirm
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                </div>
                            </div>
                        </div>
                    </Modal>
                    :
                    null
                }
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { acceptHubsTerms })(HubsTermsModal);
