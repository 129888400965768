import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import "../../../assets/layout/insert-profile-data-modal.css"


export class InsertProfileDataModal extends Component {
    state = {
        full_name: "",
        photo: null,
        profile_picture: "",
        profile_picture_updated: true,
    }

    closeModal = () => {
        this.props.getMyProfile()
        const full_name = this.state.full_name

        if (full_name !== "") {
            const id = this.props.data.profile.id
            const username = this.props.data.profile.username
            const profile = { id, username, full_name }
            this.props.updateProfileFullName(profile)
        }

        // window.location.reload(false);
        this.props.data.closeModal()
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onProfilePictureSubmit = (e) => {
        e.preventDefault();
        this.setState({
            profile_picture_updated: false,
            profile_picture: e.target.files[0],
            photo: URL.createObjectURL(e.target.files[0]),
        })
        const profile_picture = e.target.files[0]
        const id = this.props.data.profile.id
        const username = this.props.data.profile.username
        const profile = { id, username, profile_picture }
        setTimeout(() => {
            this.props.updateProfilePicture(profile)
            this.setState({
                profile_picture_updated: true
            })
        }, 2000);
    };

    render() {
        return (
            <Fragment>
                <div class="profile-data-box">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                        <div class="profile-data-box-6">
                            {this.state.profile_picture_updated ?

                                <div
                                    onClick={() => this.closeModal()}
                                    class="profile-data-times-icon">
                                    <img class="" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                                :
                                <div
                                    class="profile-data-times-icon">
                                    <img class="" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                            }
                            <div class="profile-data-title">
                                Make it easy for your Buddies to find you!
                            </div>

                            <div class="profile-data-picture-text">
                                Update your Profile Picture
                            </div>

                            <div class="profile-data-picture-photo-box">
                                {this.state.photo ?
                                    <img
                                        class="profile-data-picture-photo"
                                        src={this.state.photo}
                                        onClick={() => this.fileInput.click()}
                                        alt=""
                                    />
                                    :

                                    <img
                                        onClick={() => this.fileInput.click()}
                                        class="profile-data-picture-photo"
                                        src={require("../../../assets/authentication/svg/account.svg")}
                                        alt=""
                                    />


                                }
                                <input
                                    style={{ display: 'none' }}
                                    accept="image/png, image/jpeg"
                                    type="file"
                                    name="photo"
                                    onChange={this.onProfilePictureSubmit}
                                    ref={fileInput => this.fileInput = fileInput}
                                />
                                {/* <img
                                    onClick={() => this.fileInput.click()}
                                    class="profile-data-picture-photo"
                                    src={require("../../../assets/authentication/svg/account.svg")}
                                    alt=""
                                /> */}
                            </div>

                            <div class="profile-data-fullname-text">
                                Update your Full Name
                            </div>

                            <div className="profile-data-fullname">

                                <div className="form-inline w-100">
                                    <input
                                        className="profile-data-fullname form-control mr-sm-2 w-100 py-4"
                                        type="search"
                                        name="full_name"
                                        onChange={this.onChange}
                                        placeholder="Insert Full Name"
                                        value={this.state.full_name}
                                        aria-label="Search"
                                    />
                                </div>
                            </div>


                            {this.state.full_name === "" ?
                                null
                                : !this.state.profile_picture_updated ?
                                    null
                                    : this.state.photo === null ?
                                        null
                                        :
                                        < div
                                            onClick={() => this.closeModal()}
                                            class="profile-data-done">
                                            Done
                                        </div>
                            }

                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (InsertProfileDataModal);