import React, { Component, Fragment } from 'react';
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/booked_page/tableModal.css"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";


export class TablesModal extends Component {
    state = {
        date: new Date(),
        date_changed: false,
        freeTablesMode: false
    }

    closeModal = () => {
        this.setState({
            freeTablesMode: false,
        })
        this.props.data.closeModal()
    }

    updateAllTablesDateField(date) {
        this.setState({
            date: date,
            date_changed: true
        })
        // make date only numbers
        const correct_datetime = moment(date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.data.updateAllTablesDateField(date_id)
        this.props.getAllFreeTables(date_id);
    }

    changeFreeTablesMode = () => {
        this.setState({
            freeTablesMode: true,
        })
        const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getAllFreeTables(date_id);
    }

    changeAllTablesMode = () => {
        this.setState({
            freeTablesMode: false,
        })
    }

    render() {
        const ExampleCustomInput = ({ value, onClick }) => (
            <div onClick={onClick} class="all-tables-datepicker">
                <strong onClick={onClick}>
                    {value}
                </strong>
            </div>
        );
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            // slidesToScroll: 2,
            // speed: 500,
            swipeToSlide: true,
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />, 
        };
        let date = this.state.date
        if (!this.state.date_changed) {
            date = this.props.data.sellected_date
        }
        return (
            <Fragment>
                <div class="tables-modal-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="tables-modal-modal-box">
                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="tables-modal-cancle-btn">
                                        <img class="tables-modal-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row">
                                    <div class="text-left">
                                        <h4><strong class="tables-modal-title">Tables at</strong></h4>
                                    </div>
                                    <DatePicker
                                        selected={this.props.data.sellected_date}
                                        onChange={this.updateAllTablesDateField.bind(this)}
                                        // minDate={new Date()}
                                        name="date"
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<ExampleCustomInput />}
                                        minDate={new Date()}
                                    // withPortal
                                    />
                                    {this.state.freeTablesMode ?
                                        <div
                                            onClick={this.changeAllTablesMode}
                                            class="btn-danger-free-tables"
                                        >
                                            See All Tables
                                        </div>
                                        :
                                        <div
                                            onClick={this.changeFreeTablesMode}
                                            class="btn-danger-free-tables"
                                        >
                                            See Free Tables
                                        </div>
                                    }
                                </div>

                                {this.state.freeTablesMode ?
                                    <>
                                        <div class="tables-modal-slider-layout">
                                            <Slider {...settings} >
                                                {this.props.data.free_tables.map(table => (
                                                    <div key={table.id}>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
                                                            <div class="tables-modal-modal-frame" >
                                                                <div class="all-tables-modal-table-number text-center">Table {table.number}</div>
                                                                <img
                                                                    class="tables-modal-modal-logo"
                                                                    src={require("../../../assets/booked_page/svg/table.svg")}
                                                                    alt=""
                                                                />
                                                                < div class="tables-modal-table-reservation"><b>Free</b></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                        < div class="tables-modal-table-reservation"><b>Total Free Tables: {this.props.data.free_tables.length}</b></div>

                                    </>
                                    :
                                    <div class="tables-modal-slider-layout">
                                        <Slider {...settings} >
                                            {this.props.data.tables.map(table => (
                                                <div key={table.id}>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
                                                        <div class="tables-modal-modal-frame" >
                                                            <div class="all-tables-modal-table-number">Table {table.number}</div>
                                                            <img
                                                                class="tables-modal-modal-logo"
                                                                src={require("../../../assets/booked_page/svg/table.svg")}
                                                                alt=""
                                                            />
                                                            {table.todays_reservations !== "" ?
                                                                < div class="tables-modal-table-reservation">Reserved at:<br /><b>{table.todays_reservations}</b></div>
                                                                :
                                                                <div class="tables-modal-table-reservation">No Reservations</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                }


                            </div>
                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (TablesModal);