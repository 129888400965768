import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import '../../../assets/shops/shop-magazine.css'
import { getShopMagazineProducts, getMagazineCover } from '../../../store/actions/shops'
import HTMLFlipBook from 'react-pageflip';
import { createFirstTimeVisit } from '../../../store/actions/hubst3rActions';

export class ShopMagazine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            totalPage: 0,
            page_selected: false,
            selected_page: "",
            catalog_name: "",
            pagesMode: false,
            nav1: null,
            nav2: null,
            miniCatalogMode: false,
            page_show_more: false,
            page_changing: false,
            showQrModal: false
        };
    }

    componentWillMount() {
        this.props.getShopMagazineProducts(this.props.match.params.shopID)
        this.props.getMagazineCover(this.props.match.params.shopID)
    }


    componentDidMount() {
        // if (!JSON.parse(localStorage.getItem("frsttmvst"))) {
        //     localStorage.setItem('frsttmvst', JSON.stringify("True"));
        const browser = window.loadVersionBrowser().name;
        const full_browser = window.clientInformation.userAgent;
        const device_width = window.innerWidth
        const device_height = window.innerHeight
        fetch(`https://geolocation-db.com/json/`)
            .then(response => {
                return response.json();
            }, "jsonp")
            .then(res => {
                const ip = res.IPv4
                const country = res.country_name
                const city = res.city
                const latitude = res.latitude
                const longitude = res.longitude
                const state = res.state
                const from_nfc = false
                const nfc_number = "magazine with id:" + this.props.match.params.profileID
                const data = { from_nfc, nfc_number, browser, full_browser, device_width, device_height, ip, country, city, latitude, longitude, state }
                this.props.createFirstTimeVisit(data)
            }).catch(err => {
                console.log("Hiiiiiii")
            });
    }
    // }

    // openZoomPage(page_photo) {
    //     this.setState({
    //         page_selected: true,
    //         selected_page: page_photo
    //     });
    // };

    // closeZoomPage() {
    //     this.setState({
    //         page_selected: false,
    //         selected_page: ""
    //     });
    //     setTimeout(() => {
    //         this.flipBook.pageFlip().turnToPage(this.state.page);
    //     }, 100);
    // };

    nextButtonClick() {
        this.setState({
            page_selected: false,
            selected_page: ""
        });

        this.flipBook.pageFlip().flipNext();
    };

    prevButtonClick() {
        this.setState({
            page_selected: false,
            selected_page: ""
        });
        this.flipBook.pageFlip().flipPrev();
    };

    onPage = (e) => {
        this.setState({
            page: e.data,
            page_changing: false,
            page_show_more: false,
        });
    };


    render() {
        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const tagSettings = {
            dots: true,
            infinite: false,
            slidesToShow: 1,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        let user = JSON.parse(localStorage.getItem('ur'))

        let catalogsMode = true
        if (this.state.pagesMode) {
            catalogsMode = false
        }

        let magazine_pages = this.props.magazine_products

        const magazine_cover = this.props.magazine_cover

        if (magazine_cover.length>0){
            magazine_pages = magazine_cover.concat(magazine_pages);
        }

        let current_page = {}
        if (magazine_pages.length > 0) {
            current_page = magazine_pages[this.state.page]
        }

        const products_varations = this.props.magazine_products_variations

        const current_page_variations = products_varations.filter(product=>product.sku===current_page.sku)

        let height = window.innerHeight * 0.9
        let width = window.innerWidth

        return (
            <>
            {this.props.magazine_products.length>0?
                <div class="magazine-zoom-all-magazine-size">
                    <>
                        <>
                            <div class={this.state.page_selected ? "magazine-all-magazine-size-none" : "magazine-all-magazine-size"}>
                                <HTMLFlipBook
                                    width={600}
                                    height={1000}
                                    showCover={true}
                                    onFlip={this.onPage}
                                    ref={(el) => (this.flipBook = el)}
                                >
                                    {magazine_pages.map(page => (
                                        <div >
                                            < div class="magazine-page-size">    
                                                {page.name?
                                                    <img
                                                        class="magazine-page-img-size"
                                                        src={page.images[0].src}
                                                        alt=""
                                                    />
                                                :
                                                    <img
                                                        class="magazine-page-img-size"
                                                        src={page.cover_photo}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                        </div>
                                    ))}

                                </HTMLFlipBook>

                            </div >

                            <div class="magazine-all-magazine-size-small">
                                <HTMLFlipBook
                                    size="fixed"
                                    width={width}
                                    height={height}
                                    maxShadowOpacity={0.5}
                                    showCover={true}
                                    mobileScrollSupport={true}
                                    onFlip={this.onPage}
                                    onChangeOrientation={this.onChangeOrientation}
                                    onChangeState={this.onChangeState}
                                    className="demo-book"
                                    ref={(el) => (this.flipBook = el)}
                                >

                                    {magazine_pages.map(page => (
                                        <div class="magazine-page-size" >
                                            {page.name?
                                                <img
                                                    class="magazine-page-img-size"
                                                    src={page.images[0].src}
                                                    alt=""
                                                />
                                            :
                                                <img
                                                    class="magazine-cover-page-img-size"
                                                    src={page.cover_photo}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                    ))}
                                </HTMLFlipBook>
                            </div >

                            {this.state.page === 0 ?
                                null
                                :
                                <div
                                    class="magazine-bottom-navbar-small-1"
                                    onClick={() => this.prevButtonClick()}
                                >
                                    <div
                                        class="magazine-bottom-navbar-btn-zoom">
                                        <img
                                            class="magazine-bottom-navbar-icon"
                                            src={require("../../../assets/menu/svg/back.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }

                            {magazine_pages.length - 1 === this.state.page ?
                                null
                                :
                                <div
                                    class="magazine-bottom-navbar-small-4"
                                    onClick={() => this.nextButtonClick()}
                                >
                                    <div
                                        class="magazine-bottom-navbar-btn-zoom">
                                        <img
                                            class="magazine-bottom-navbar-icon"
                                            src={require("../../../assets/menu/svg/next.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }
                            
                            {current_page.name?
                                <a href={current_page.permalink}>
                                    <div class="row setup-page-magazine-bottom-place-row">
                                        <div class="setup-page-magazine-bottom-page-description">
                                            {current_page.name} 
                                        </div>
                                        

                                        <div class="setup-page-magazine-bottom-page-price">
                                            Τιμή:
                                            <br />
                                            {current_page.price} €
                                        </div>            
                            
                                    </div>
                                    <div class="magazine-bottom-see-more">
                                        Περισσότερες Πληροφορίες...
                                    </div>
                                </a>
                            :
                                null
                            }
                        </>
                    </>
                </div>
                :
                    null
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    magazine_cover: state.shops.magazine_cover,
    magazine_products: state.shops.magazine_products,
    magazine_products_variations:state.shops.magazine_products_variations,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    getShopMagazineProducts,
    createFirstTimeVisit,
    getMagazineCover
})(ShopMagazine);