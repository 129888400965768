import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import '../../../assets/authentication/cookies.css'


export class CookiesModal extends Component {

    state = {
        cookiesModalVisible: true,
    };


    componentWillMount() {
        if (!localStorage.getItem('cka')){
            localStorage.setItem('cka', JSON.stringify(false));
        }
    }

    closeCookiesModal() {
        localStorage.setItem('cka', JSON.stringify(true));
        this.setState({
            cookiesModalVisible: false
        });
    }

    render() {
        const cookiesShown = localStorage.getItem('cka')
        return (
            <Fragment>
                    {cookiesShown ==="false" ? 
                        <Modal visible={this.state.cookiesModalVisible} width="100" height="100" effect="fadeInUp">
                            <div class="login-cookies-box">
                                <div class="row">
                                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                    </div>
                                    <div class="cookies-box-6">
                                        <img
                                            class="login-cookies-icon"
                                            src={require("../../../assets/authentication/svg/cookies.svg")}
                                            alt=""
                                        />
                                        <p class="login-cookies-top-text">
                                            We use cookies to help personalize and improve content and services.
                                            You can review your cookie controls at any time.
                                            Learn more about cookie uses and controls in our
                                            &nbsp;<Link to="/cookies-policy"><a class="" href="#">Cookies Policy</a></Link>.
                                        </p>
                                        <div class="">
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center mt-4">
                                                <div
                                                    onClick={() => this.closeCookiesModal()}
                                                    class="btn accept-cookies-btn-success">
                                                    Got it
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                    </div>
                                </div>
                            </div>
                        </Modal>  
                        :
                        null
                                                
                    }
            </Fragment >
        )
    }
}

export default (CookiesModal);