import axios from 'axios';

import { createAlert, returnErrors } from './alerts';

import {
    GET_DATES_BOOKED_RESERVATIONS,
    GET_DATES_BOOKED_HUB_RESERVATIONS,
    GET_HUB_FREE_TABLES,
    SET_TABLE_STATUS,
    SET_TABLE_PERSONS,
    GET_ENTERTAINEMENT_JOBS,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    HUB_PAGE_LOCALY_LOADED,
    GET_HUB_PAGE,
    GET_HUB_PERSONEL,
    START_EMPLOYEE_SHIFT,
    END_EMPLOYEE_SHIFT,
    CREATE_HUB_EMPLOYEE,
    GET_HUB_PERSONEL_SHIFTS,
    REMOVE_PERSONEL_ACCESS,
    CHANGE_EMPLOYEE_ACCESS,
    REMOVE_EMPLOYEE_ACCESS,
    GET_MY_EMPLOYEE_ACCESS
} from './actionTypes';
import { tokenConfig } from './auth';


export const getHubPersonel = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/get-hub-jobs/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_PERSONEL,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) { dispatch({ type: NO_INTERNET_CONNECTION }); }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const startEmployeeShift = (employee) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/start-employee-shift/`, employee, tokenConfig())
        .then(res => {
            dispatch({
                type: START_EMPLOYEE_SHIFT,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const endEmployeeShift = (employee) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/end-employee-shift/`, employee, tokenConfig())
        .then(res => {
            dispatch({
                type: END_EMPLOYEE_SHIFT,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}




export const getUserCurrentJobs = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user/get-entertainement-jobs/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_ENTERTAINEMENT_JOBS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUB_PAGE_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}



export const getWaitressHub = (job_id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${job_id}/hub-waitress/page/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_PAGE,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUB_PAGE_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}



export const getWaitressDatesReservations = (date, job_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${job_id}/hub-waitress/reservation/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_BOOKED_RESERVATIONS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getWaitressDatesHubReservations = (date, job_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${job_id}/hub-waitress/reservation/get-hub-reservation/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_BOOKED_HUB_RESERVATIONS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getHubWaitressAllTables = (date, job_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${job_id}/hub-waitress/${date}/all-tables/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_FREE_TABLES,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const setTableStatusWaitress = (table_id, table) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-waitress/table/${table_id}/set-table-status/`, table, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_TABLE_STATUS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const setTablePersonsWaitress = (table) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-waitress/table/set-table-persons/`, table, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_TABLE_PERSONS,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const createHubEmployee = (employee) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/create-hub-employee/`, employee, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_HUB_EMPLOYEE,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const getHubPersonelShifts = (job_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${job_id}/get-hub-personels-shifts/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_PERSONEL_SHIFTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const removeHubEmployee = (employee) => (dispatch, getstate) => {
    // axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/remove-hub-employee/`, employee, tokenConfig())
    //     .then(res => {
    //         dispatch({
    //             type: REMOVE_PERSONEL_ACCESS,
    //             payload: res.data
    //         });
    //     })
    //     .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const changeHubEmployeeAccess = (employee) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/change-hub-employee-access/`, employee, tokenConfig())
        .then(res => {
            dispatch({
                type: CHANGE_EMPLOYEE_ACCESS,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const removeHubEmployeeAccess = (employee) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/remove-hub-employee-access/`, employee, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_EMPLOYEE_ACCESS,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};



export const getMyEmploeeAccess = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/get-my-employee-access/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MY_EMPLOYEE_ACCESS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}