import React, { Component, Fragment } from 'react';
import "../../../assets/chat/show-message-modal.css"
import moment from "moment-timezone";


export class ShowMessageModal extends Component {

    state = {
        isPlaying: false
    }

    componentDidMount() {
    }


    closeModal = () => {
        this.props.data.closeModal()
    }


    render() {
        // let audio = new Audio();
        // let audio = new Blob(['https://d1udvqcad2watg.cloudfront.net/media/chat/voice/blob_1gC9Kzi'], { type: 'audio/mp3' });
        // console.log(audio)
        // console.log(URL.createObjectURL(audio))

        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="show-message-modal-box">
                            <div onClick={() => this.closeModal()} class="show-message-modal-times-box">
                                <img
                                    class="show-message-modal-times"
                                    src={require("../../../assets/layout/homepage svg/times.svg")}
                                    alt=""
                                />
                            </div>


                            <div onClick={() => this.closeHi()} class="show-message-modal-username">
                                {this.props.data.username}
                            </div>

                            <img
                                class="show-message-modal-photo-layout-cover"
                                src={this.props.data.profile_picture}
                                alt=""
                            />

                            <img
                                class="show-message-modal-photo-layout"
                                src={this.props.data.profile_picture}
                                alt=""
                            />


                            <div class="show-message-modal-timestamp">
                                {moment(this.props.data.messageTime).fromNow()}
                            </div>

                            <div class="show-message-modal-sound-wave">

                                <audio

                                    class="show-message-modal-audio-controls"
                                    // id="audio-element"
                                    // type="audio/mp3"
                                    src={this.props.data.message}
                                    // src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                                    autoPlay
                                    controls

                                // type="audio/mp3"
                                // onEnded={() => this.closeModal()}
                                >
                                    {/* <source
                                        src={URL.createObjectURL(audio)}
                                    ></source> */}
                                </audio>

                                <div class="show-message-modal-wave-gif">
                                    <img
                                        class="show-message-modal-wave-gif-img"
                                        src={require("../../../assets/chat/svg/wave2.gif")}
                                        alt=""
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </Fragment >
        )
    }
}


export default (ShowMessageModal);