import {
    CREATE_AMBASSANDOR_HUB_JOB,
    CREATE_HUB_JOB,
    GET_AMBASSANDOR_PROFILE,
    GET_HUB_JOBS,
    UPDATE_HUB_JOB_ACTIVATION,
    DELETE_HUB_JOB,
    GET_USER_AMBASSANDOR,
    CHECK_USER_IS_AMBASSANDOR,
    GET_COMPANY_AMBASSANDOR_PROFILE,
    UPDATE_USER_AMBASSANDOR,
} from '../actions/actionTypes';

const initialState = {
    ambassandor: [],
    jobs: [],
    is_ambassandor: [],
    company_ambassandor: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_AMBASSANDOR:
            return {
                ...state,
                ambassandor: action.payload
            };
        case UPDATE_USER_AMBASSANDOR:
            const updated = [action.payload]
            return {
                ...state,
                ambassandor: updated
            };
        case GET_AMBASSANDOR_PROFILE:
            return {
                ...state,
                ambassandor: action.payload
            };
        case GET_COMPANY_AMBASSANDOR_PROFILE:
            return {
                ...state,
                company_ambassandor: action.payload
            };
        case GET_HUB_JOBS:
            return {
                ...state,
                jobs: action.payload
            };
        case CREATE_HUB_JOB:
            return {
                ...state,
                ambassandor: action.payload
            };

        case UPDATE_HUB_JOB_ACTIVATION:
            const updated_jobs = [action.payload]
            return {
                ...state,
                jobs: state.jobs.map(job => updated_jobs.find(change => change.id === job.id) || job)
            };

        case CREATE_AMBASSANDOR_HUB_JOB:
            return {
                ...state,
                jobs: [...state.jobs, action.payload]
            };
        case DELETE_HUB_JOB:
            return {
                ...state,
                jobs: state.jobs.filter(job => job.id !== action.payload),
            };
        case CHECK_USER_IS_AMBASSANDOR:
            return {
                ...state,
                is_ambassandor: action.payload,
            };


        default:
            return state;
    }


}



