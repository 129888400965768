import React, { Component, Fragment } from 'react';
import "../../../assets/hub/hub-page/show-more-modal.css"


export class ShowMoreModal extends Component {

    closeModal = () => {
        this.props.data.closeModal()
    }

    render() {
        const hub = this.props.data.hub
        return (
            <Fragment>
                <div class="">
                    <center>
                        <div class="row">

                            <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                            <div class="show-more-box">
                                <img
                                    onClick={this.closeModal}
                                    class="times-modal-size"
                                    src={require("../../../assets/layout/homepage svg/times.svg")}
                                    alt=""
                                />
                                <div class="item show-more-field">
                                    <img class="show-more-icon" src={require("../../../assets/profile/svg/location-red.svg")} alt="" />
                                    <br /><strong class="show-more-text">{hub.city}</strong>
                                </div>
                                <div class="item show-more-field">
                                    <img class="show-more-icon" src={require("../../../assets/layout/homepage svg/flag.svg")} alt="" />
                                    <br /><strong class="show-more-text">{hub.area}</strong>
                                </div>
                                <div class="item show-more-field">
                                    <img class="show-more-icon" src={require("../../../assets/layout/homepage svg/building.svg")} alt="" />
                                    <br /><strong class="show-more-text">{hub.address}</strong>
                                </div>
                                <div class="item show-more-field">
                                    <img class="show-more-icon" src={require("../../../assets/layout/homepage svg/age.svg")} alt="" />
                                    <br /><strong class="show-more-text">Suggested Age: {hub.minimum_age}+</strong>
                                </div>
                                <div class="item show-more-field">
                                    <img class="show-more-icon" src={require("../../../assets/layout/homepage svg/wallet.svg")} alt="" />
                                    <br /><strong class="show-more-text">Mininum Charge: {hub.minimum_charge} €</strong>
                                </div>
                                <div class="item show-more-field">
                                    <img class="show-more-icon" src={require("../../../assets/layout/homepage svg/spoon.svg")} alt="" />
                                    <br /><strong class="show-more-text">Kitchen Close at: {hub.cousine_closing_hour}</strong>
                                </div>
                                {hub.reservation_acceptance ?
                                    <div class="item show-more-field">
                                        <img class="show-more-icon" src={require("../../../assets/layout/homepage svg/clock.svg")} alt="" />
                                        <br /><strong class="show-more-text">Reservation Until: {hub.maximum_reservation_hour}</strong>
                                    </div>
                                    :
                                    <div class="item show-more-field">
                                        <img class="show-more-icon" src={require("../../../assets/notifications/svg/meeting 1.svg")} alt="" />
                                        <br /><strong class="show-more-text">No Reservations From Hubst3r</strong>
                                    </div>
                                }
                            </div>
                        </div>
                    </center>
                </div>
            </Fragment >
        )
    }
}

export default (ShowMoreModal);