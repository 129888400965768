import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { logout } from '../../../store/actions/auth';
import {
    getHubPage,
    updateHub,
    getHubActivities,
    createActivity,
    removeActivity,
    getHubWorkingDays,
    updateWorkingDay,
    activateHubAccount,

} from '../../../store/actions/hub'
import { getMyEmploeeAccess } from '../../../store/actions/hubPersonel'
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
import "../../../assets/hub/hub-info/hub-info.css"


export class InformationsPage extends Component {
    state = {
        photo: null,
        logo: "",
        cover_photo: "",
        category: "",
        minimum_charge: "",
        maximum_reservation_hour: "22:00",
        cousine_closing_hour: "23:00",
        minimum_age: "",
        description: "",
        hub_rules: "",
        wifi_code: "",
        phone: "",
        website: "",
        facebook_url: "",
        instagram_url: "",
        hub_just_activated: false,

        isInMinimumChargeEditMode: false,
        maximum_reservation_hour_updated: false,
        cousine_closing_hour_updated: false,
        isInMinimumAgeEditMode: false,
        isInDescriptionEditMode: false,
        isLogoUpdated: false,
        activity_name: "",
        isInActivityAddMode: false,

        day: "",
        opening_hour: "",
        closing_hour: "",
        isInWorkingDayAddMode: false,
        hoursModalVisible: false,
        descriptionModalVisible: false,
        mapModalVisible: false,
        showMoreModalVisible: false,
        enjoyHereModalVisible: false,
        logout: "false",
        isInPhoneEditMode: false,
        isInWebsiteEditMode: false,
        isInFacebookEditMode: false,
        isInInstagramEditMode: false,
    };


    componentWillMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
        // this.props.getHubActivities(hub_id);
        // this.props.getHubWorkingDays(hub_id);
    }




    logoutUser = e => {
        this.props.logout()
        setTimeout(() => {
            // window.location.reload(false);
            this.setState({ logout: "true" })
        }, 1000);
    }

    handleBack() {
        this.props.history.goBack()
    }

    render() {
        if (this.state.logout === "true") {
            return <Redirect to="/register" />;
        }
        const hub = this.props.hub
        const my_employement_access = []
        if (this.props.my_employement_access.length > 0) {
            my_employement_access = this.props.my_employement_access[0]
        }
        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "hub-info-lottie-appear" : "hub-info-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <HubDesktopNavbar data={{ sellected: "info" }} />


                {/* <div class={this.props.isLoading ? "hub-info-section-disappear" : null}> */}
                <div>
                    <div class="whole-hub-info-page-layout">
                        <OfflineLabel />
                        {hub.map(hub => (
                            <div class="color-6">
                                <div class="hub-mobile-info-mobile-navbar">
                                    <Link to="/hub-homepage">
                                        <div class="back-button-hub-mobile-info-box">
                                            <img
                                                class="hub-mobile-info-back-button"

                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </Link>
                                    {hub.name}
                                </div>

                                <div class="col-xl-10 col-lg-12 body-right-6 pb-5">

                                    <div class="row hub-info-all-boxes">
                                        <Link to="/set-name-logo" ontouchstart class="hub-info-set-boxe">
                                            <div class="hub-info-set-img-box">
                                                <img
                                                    class="hub-info-set-img-img"
                                                    src={hub.logo}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="hub-info-set-text">
                                                Set<br />Name & Logo
                                            </div>
                                        </Link>

                                        {my_employement_access.is_admin ?
                                            <Link to="/set-access" ontouchstart class="hub-info-set-boxe">
                                                <div class="hub-info-set-img-box">
                                                    <img
                                                        class="hub-info-set-img-img"
                                                        src={require("../../../assets/hub/hub-homepage/svg/personel.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="hub-info-set-text">
                                                    Set Accounts <br />with Access
                                                </div>
                                            </Link>
                                            :
                                            null
                                        }

                                        <Link to="/set-catalog" ontouchstart class="hub-info-set-boxe">
                                            <div class="hub-info-set-img-box">
                                                <img
                                                    class="hub-info-set-img-img"
                                                    src={require("../../../assets/hub/hub-homepage/svg/menu.svg")}
                                                    alt=""
                                                />
                                            </div>

                                            {hub.place_type === "Hospitality" || hub.place_type === "Other" ?
                                                <div class="hub-info-set-text">
                                                    Set<br />Menu
                                                </div>
                                                :
                                                <div class="hub-info-set-text">
                                                    Set<br />Catalog
                                                </div>
                                            }

                                        </Link>

                                        <Link to="/set-map-photo" ontouchstart class="hub-info-set-boxe">
                                            <div class="hub-info-set-img-box">
                                                <img
                                                    class="hub-info-set-img-img"
                                                    src={require("../../../assets/hub/hub-homepage/svg/map.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="hub-info-set-text">
                                                Set<br />Map Photo
                                            </div>
                                        </Link>

                                        {/* <Link to="/set-promo-photo" ontouchstart class="hub-info-set-boxe">
                                            <div class="hub-info-set-img-box">
                                                <img
                                                    class="hub-info-set-img-img"
                                                    src={require("../../../assets/hub/hub-homepage/svg/promo.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="hub-info-set-text">
                                                Set<br />Promo Photo
                                            </div>
                                        </Link> */}

                                        <Link to="/set-working-hours" ontouchstart class="hub-info-set-boxe">
                                            <div class="hub-info-set-img-box">
                                                <img
                                                    class="hub-info-set-img-img"
                                                    src={hub.logo}
                                                    src={require("../../../assets/hub/hub-homepage/svg/working-hours.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="hub-info-set-text">
                                                Set<br />Working Hours
                                            </div>
                                        </Link>

                                        {hub.place_type === "Hospitality" || hub.place_type === "Other" ?
                                            <Link to="/set-reservation" ontouchstart class="hub-info-set-boxe">
                                                <div class="hub-info-set-img-box">
                                                    <img
                                                        class="hub-info-set-img-img"
                                                        src={require("../../../assets/hub/hub-homepage/svg/reservations.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="hub-info-set-text">
                                                    Set<br />Reservations
                                                </div>
                                            </Link>
                                            :
                                            null
                                        }

                                        {hub.place_type === "Services" ?
                                            <Link to="/set-appointment" ontouchstart class="hub-info-set-boxe">
                                                <div class="hub-info-set-img-box">
                                                    <img
                                                        class="hub-info-set-img-img"
                                                        src={require("../../../assets/hub/hub-homepage/svg/reservations.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="hub-info-set-text">
                                                    Set<br />Appointments
                                                </div>
                                            </Link>
                                            :
                                            null
                                        }

                                        {/* {hub.place_type === "Shops" ?
                                            <Link to="/set-online-shop" ontouchstart class="hub-info-set-boxe">
                                                <div class="hub-info-set-img-box">
                                                    <img
                                                        class="hub-info-set-img-img"
                                                        src={require("../../../assets/hub/hub-homepage/svg/online-shop.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="hub-info-set-text">
                                                    Set<br />Online Shop
                                                </div>
                                            </Link>
                                            :
                                            null
                                        } */}

                                        <Link to="/set-category" ontouchstart class="hub-info-set-boxe">
                                            <div class="hub-info-set-img-box">
                                                <img
                                                    class="hub-info-set-img-img"
                                                    src={require("../../../assets/hub/hub-homepage/svg/category.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="hub-info-set-text">
                                                Set<br />Category
                                            </div>
                                        </Link>

                                        <Link to="/set-social-media" ontouchstart class="hub-info-set-boxe">
                                            <div class="hub-info-set-img-box">
                                                <img
                                                    class="hub-info-set-img-img"
                                                    src={require("../../../assets/hub/hub-homepage/svg/social_media.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="hub-info-set-text">
                                                Set<br />Social Media
                                            </div>
                                        </Link>



                                    </div>

                                    <div class="hub-user-logout-button" onClick={this.logoutUser}>Logout</div>
                                    <br /><br /><br /><br />
                                    <HubGdprLinks />
                                    <br /><br />
                                </div>
                            </div>
                        ))
                        }
                    </div>

                </div>
                {/* <HubBottomNavbar data={{ sellected: "info" }} /> */}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hub: state.hub.hub,
    activities: state.hub.activities,
    working_days: state.hub.working_days,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    my_employement_access: state.hubPersonel.my_employement_access
});

export default connect(mapStateToProps, {
    getHubPage,
    activateHubAccount,
    updateHub,
    getHubActivities,
    createActivity,
    removeActivity,
    getHubWorkingDays,
    updateWorkingDay,
    logout,
    getMyEmploeeAccess
})(InformationsPage);