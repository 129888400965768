import React, { Component, Fragment } from 'react';
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/booked_page/sellectedTableModal.css"
import moment from "moment-timezone";
import Modal from 'react-awesome-modal';

export class SellectedTableModal extends Component {
    state = {
        date: new Date(),
        date_changed: false,
        freeTablesMode: false,
        addReservationModalVisible: false,
        table_sellected_option: "",
        addReservationMode: false,
        ReservationsListModalVisible: false,
    }

    closeModal = () => {
        this.setState({
            date: new Date(),
            date_changed: false,
            freeTablesMode: false,
            addReservationModalVisible: false,
            table_sellected_option: "",
            addReservationMode: false,
            ReservationsListModalVisible: false,
        })
        this.props.data.closeModal()
    }


    changeAddReservationMode() {
        this.setState({
            addReservationMode: true,
        });
    }

    closeAddReservationMode() {
        this.setState({
            addReservationMode: false,
        });
    }


    updateSellectedTable = (table_option) => (e) => {
        this.setState({
            table_sellected_option: table_option,
        })
        const id = this.props.data.sellected_table.id
        const status = table_option
        const table = { id, status }
        this.props.setTableStatus(id, table)
        setTimeout(() => {
            const correct_datetime = moment(new Date()).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getHubAllTables(date_id)
            this.props.data.closeModal()
            this.setState({
                date: new Date(),
                date_changed: false,
                freeTablesMode: false,
                addReservationModalVisible: false,
                table_sellected_option: "",
                addReservationMode: false,
                ReservationsListModalVisible: false,
            })
        }, 500);
    }

    openAddReservationModal() {
        this.props.data.openAddReservationModal(this.props.data.table_id)
    }

    openChooseReservationListModal() {
        this.props.data.openChooseReservationListModal()
    }

    render() {
        const ExampleCustomInput = ({ value, onClick }) => (
            <div onClick={onClick} class="all-tables-datepicker">
                <strong onClick={onClick}>
                    {value}
                </strong>
            </div>
        );
        const settings = {
            dots: true,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
        };
        let date = this.state.date
        if (!this.state.date_changed) {
            date = this.props.data.sellected_date
        }
        const table_options = ['Empty', 'Reserved', 'In Use', 'Empty Soon']
        let table_sellected_option = ""
        if (this.state.table_sellected_option === "") {
            table_sellected_option = this.props.data.table_sellected_option
        } else {
            table_sellected_option = this.state.table_sellected_option
        }
        return (
            <Fragment>
                <div class="sellected-table-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="sellected-table-modal-box">
                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="sellected-table-cancle-btn">
                                        <img class="sellected-table-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row">
                                    <div class="text-left">
                                        <h4><strong class="sellected-table-title">Table No. {this.props.data.sellected_table.number}</strong></h4>
                                    </div>
                                </div>

                                {this.state.addReservationMode ?
                                    <>
                                        <div
                                            onClick={() => this.closeAddReservationMode()}
                                            class="sellected-table-back-icon">
                                            <img
                                                src={require("../../../assets/layout/homepage svg/back arrow.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div class="sellected-table-slider-layout">
                                            <Slider {...settings} >
                                                <div
                                                    onClick={() => this.openAddReservationModal()}
                                                    class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 sellected-table-item"
                                                >
                                                    <div
                                                        class="sellected-table-round-buttons sellected-table-round-button-unpressed">
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/new-reservation.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div class="sellected-table-choise-text">
                                                        <div class="sellected-table-table-reservation ">
                                                            <b>Create a New Reservation</b>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => this.openChooseReservationListModal()}
                                                    class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 sellected-table-item" >
                                                    <div
                                                        class="sellected-table-round-buttons sellected-table-round-button-unpressed">
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/list-reservation.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div class="sellected-table-choise-text">
                                                        < div class="sellected-table-table-reservation">
                                                            <b>Choose Reservation from List</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </>
                                    :
                                    <div class="sellected-table-slider-layout">
                                        <Slider {...settings} >
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 sellected-table-item" >
                                                <div
                                                    onClick={this.updateSellectedTable("Empty")}
                                                    class="sellected-table-round-buttons sellected-table-round-button-unpressed">
                                                    {table_sellected_option === "Empty" ?
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/empty-table.svg")}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/table-white.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                                <div class="sellected-table-choise-text">
                                                    < div class="sellected-table-table-reservation">
                                                        <b>Empty</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 sellected-table-item" >
                                                <div
                                                    onClick={this.updateSellectedTable("Reserved")}
                                                    class="sellected-table-round-buttons sellected-table-round-button-unpressed">

                                                    {table_sellected_option === "Reserved" ?
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/tables-icon.svg")}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/table-white.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                                <div class="sellected-table-choise-text">
                                                    < div class="sellected-table-table-reservation">
                                                        <b>Reserved</b>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 sellected-table-item" >
                                                <div
                                                    onClick={this.updateSellectedTable("In Use")}
                                                    class="sellected-table-round-buttons sellected-table-round-button-unpressed"
                                                >
                                                    {table_sellected_option === "In Use" ?
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/using-table.svg")}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/table-white.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                                <div class="sellected-table-choise-text">
                                                    < div class="sellected-table-table-reservation">
                                                        <b>In Use</b>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 sellected-table-item" >
                                                <div
                                                    onClick={this.updateSellectedTable("Empty Soon")}
                                                    class="sellected-table-round-buttons sellected-table-round-button-unpressed">
                                                    {table_sellected_option === "Empty Soon" ?
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/going-empty-table.svg")}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="sellected-table-icon"
                                                            src={require("../../../assets/booked_page/svg/table-white.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                                <div class="sellected-table-choise-text">
                                                    < div class="sellected-table-table-reservation">
                                                        <b>Empty Soon</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                        {this.props.data.reservation_acceptance ?
                                            <div class="row sellected-table-persons-box">
                                                <div
                                                    onClick={() => this.changeAddReservationMode()}
                                                    class="sellected-table-btn-danger"
                                                >
                                                    Add Reservation
                                            </div>
                                                {/* <div
                                                class="sellected-table-btn-danger"
                                            >
                                                Take Order
                                            </div> */}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>



                </div>
            </Fragment >
        )
    }
}

export default (SellectedTableModal);