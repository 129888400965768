import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/map/place-claim-modal.css"


export class PlaceClaimModal extends Component {

    state = {
        hub_contact_name: "",
        submitedHubContact: "",
        hub_contact_telephone: "",
        claimMode: false,
    }

    componentDidMount() {
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }


    closeModal() {
        this.props.data.closeModal()
        this.setState({
            claimMode: false,
        })
    }

    changeClaimMode() {
        this.setState({
            claimMode: true,
        })
    }


    render() {
        const closer_name = this.props.data.closer_name
        const closer_place_id = this.props.data.closer_place_id
        const category = this.props.data.closer_place_map_icon
        return (
            <Fragment>
                <div class="map-search-hub-contact-box">
                    <div onClick={() => this.closeModal()} class="map-search-acces-modal-times-box">
                        <img
                            class="map-search-acces-modal-times-img"
                            src={require("../../../assets/layout/homepage svg/times.svg")}
                            alt=""
                        />
                    </div>
                    {!this.state.claimMode ?
                        <>
                            <div class="map-search-hub-contact-titel">
                                <b>
                                    {closer_name.length > 30 ?
                                        <>
                                            {closer_name.slice(0, 28) + "..."}
                                        </>
                                        :
                                        <>
                                            {closer_name}
                                        </>
                                    }
                                </b>
                            </div>


                            <div class="row place-claim-row-position">
                                <div class="place-claim-offer-box-big">
                                    <div class="place-claim-price-box-big">
                                        <div class="">
                                            <div class="place-claim-price-label basic">Annual Plan</div>
                                            <div class="place-claim-price">€ 11.99</div>
                                            <div class="place-claim-price-info">Per Year, including V.A.T.</div>
                                        </div>
                                        <div class="info">

                                            {category === "restaurant" || category === "coffee shop" || category === "bar" || category === "night club" || category === "food" || category === "casino" || category === "meal takeaway" ?
                                                <ul>
                                                    <li class="row">
                                                        <div class="place-claim-correct-box">
                                                            <img
                                                                class="place-claim-correct-img"
                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        Free Trial
                                                    </li>
                                                    <li class="row">
                                                        <div class="place-claim-correct-box">
                                                            <img
                                                                class="place-claim-correct-img"
                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        Location based Advertisement
                                                    </li>
                                                    <li class="row">
                                                        <div class="place-claim-correct-box">
                                                            <img
                                                                class="place-claim-correct-img"
                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        Inform customers about Events
                                                    </li>
                                                    <li class="row">
                                                        <div class="place-claim-correct-box">
                                                            <img
                                                                class="place-claim-correct-img"
                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        Share your working hours, social media, phone, website
                                                    </li>
                                                    <li class="row">
                                                        <div class="place-claim-correct-box">
                                                            <img
                                                                class="place-claim-correct-img"
                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        System for Online Reservations
                                                    </li>
                                                    <li class="row">
                                                        <div class="place-claim-correct-box">
                                                            <img
                                                                class="place-claim-correct-img"
                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        Online Menus and full screen products
                                                    </li>
                                                </ul>
                                                : category === "gas station" ?
                                                    <ul>
                                                        {/* <li class="row">
                                                            <div class="place-claim-correct-box">
                                                                <img
                                                                    class="place-claim-correct-img"
                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            Free Trial
                                                        </li> */}
                                                        <li class="row">
                                                            <div class="place-claim-correct-box">
                                                                <img
                                                                    class="place-claim-correct-img"
                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            Location based Advertisement
                                                        </li>
                                                        <li class="row">
                                                            <div class="place-claim-correct-box">
                                                                <img
                                                                    class="place-claim-correct-img"
                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            Promote your Prices
                                                        </li>
                                                        <li class="row">
                                                            <div class="place-claim-correct-box">
                                                                <img
                                                                    class="place-claim-correct-img"
                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            Share your working hours, social media, phone, website
                                                        </li>
                                                        <li class="row">
                                                            <div class="place-claim-correct-box">
                                                                <img
                                                                    class="place-claim-correct-img"
                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            Catalogs of your Products
                                                        </li>
                                                    </ul>
                                                    : category === "pharmacy" ?
                                                        <ul>
                                                            {/* <li class="row">
                                                                <div class="place-claim-correct-box">
                                                                    <img
                                                                        class="place-claim-correct-img"
                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                Free Trial
                                                            </li> */}
                                                            <li class="row">
                                                                <div class="place-claim-correct-box">
                                                                    <img
                                                                        class="place-claim-correct-img"
                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                Let your Customers know when you are Overnight
                                                            </li>
                                                            <li class="row">
                                                                <div class="place-claim-correct-box">
                                                                    <img
                                                                        class="place-claim-correct-img"
                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                Location based Advertisement
                                                            </li>
                                                            <li class="row">
                                                                <div class="place-claim-correct-box">
                                                                    <img
                                                                        class="place-claim-correct-img"
                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                Inform customers about your Products
                                                            </li>

                                                            <li class="row">
                                                                <div class="place-claim-correct-box">
                                                                    <img
                                                                        class="place-claim-correct-img"
                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                Share your working hours, social media, phone, website
                                                            </li>

                                                            <li class="row">
                                                                <div class="place-claim-correct-box">
                                                                    <img
                                                                        class="place-claim-correct-img"
                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                Catalogs of your Products
                                                            </li>
                                                        </ul>
                                                        : category === "cinema" ?
                                                            <ul>
                                                                {/* <li class="row">
                                                                    <div class="place-claim-correct-box">
                                                                        <img
                                                                            class="place-claim-correct-img"
                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    Free Trial
                                                                </li> */}
                                                                <li class="row">
                                                                    <div class="place-claim-correct-box">
                                                                        <img
                                                                            class="place-claim-correct-img"
                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    Location based Advertisement
                                                                </li>
                                                                <li class="row">
                                                                    <div class="place-claim-correct-box">
                                                                        <img
                                                                            class="place-claim-correct-img"
                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    Share your working hours, social media, phone, website
                                                                </li>
                                                                <li class="row">
                                                                    <div class="place-claim-correct-box">
                                                                        <img
                                                                            class="place-claim-correct-img"
                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    Send your Customers to your page to buy Tickets Online
                                                                </li>
                                                                <li class="row">
                                                                    <div class="place-claim-correct-box">
                                                                        <img
                                                                            class="place-claim-correct-img"
                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    Promotional Catalog for Current & Upcoming Movies
                                                                </li>
                                                            </ul>
                                                            : category === "lodging" || category === "campground" || category === "rv park" ?
                                                                <ul>
                                                                    {/* <li class="row">
                                                                        <div class="place-claim-correct-box">
                                                                            <img
                                                                                class="place-claim-correct-img"
                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        Free Trial
                                                                    </li> */}
                                                                    <li class="row">
                                                                        <div class="place-claim-correct-box">
                                                                            <img
                                                                                class="place-claim-correct-img"
                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        Location based Advertisement
                                                                    </li>
                                                                    <li class="row">
                                                                        <div class="place-claim-correct-box">
                                                                            <img
                                                                                class="place-claim-correct-img"
                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        Share your working hours, social media, phone, website
                                                                    </li>
                                                                    <li class="row">
                                                                        <div class="place-claim-correct-box">
                                                                            <img
                                                                                class="place-claim-correct-img"
                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        Send your Customers to your page to Book
                                                                    </li>
                                                                    <li class="row">
                                                                        <div class="place-claim-correct-box">
                                                                            <img
                                                                                class="place-claim-correct-img"
                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        Promotional Catalog for Pricing & Facilities
                                                                    </li>
                                                                </ul>
                                                                : category === "minifootball" || category === "sports" || category === "bowling alley" ?
                                                                    <ul>
                                                                        {/* <li class="row">
                                                                            <div class="place-claim-correct-box">
                                                                                <img
                                                                                    class="place-claim-correct-img"
                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            Free Trial
                                                                        </li> */}
                                                                        <li class="row">
                                                                            <div class="place-claim-correct-box">
                                                                                <img
                                                                                    class="place-claim-correct-img"
                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            Location based Advertisement
                                                                        </li>
                                                                        <li class="row">
                                                                            <div class="place-claim-correct-box">
                                                                                <img
                                                                                    class="place-claim-correct-img"
                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            Share your working hours, social media, phone, website
                                                                        </li>
                                                                        <li class="row">
                                                                            <div class="place-claim-correct-box">
                                                                                <img
                                                                                    class="place-claim-correct-img"
                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            System for Online Reservations
                                                                        </li>
                                                                        <li class="row">
                                                                            <div class="place-claim-correct-box">
                                                                                <img
                                                                                    class="place-claim-correct-img"
                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            Promotional Catalog for Pricing & Facilities
                                                                        </li>
                                                                    </ul>
                                                                    : category === "art gallery" || category === "museum" ?
                                                                        <ul>
                                                                            {/* <li class="row">
                                                                                <div class="place-claim-correct-box">
                                                                                    <img
                                                                                        class="place-claim-correct-img"
                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                Free Trial
                                                                            </li> */}
                                                                            <li class="row">
                                                                                <div class="place-claim-correct-box">
                                                                                    <img
                                                                                        class="place-claim-correct-img"
                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                Location based Advertisement
                                                                            </li>
                                                                            <li class="row">
                                                                                <div class="place-claim-correct-box">
                                                                                    <img
                                                                                        class="place-claim-correct-img"
                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                Share your working hours, social media, phone, website
                                                                            </li>
                                                                            <li class="row">
                                                                                <div class="place-claim-correct-box">
                                                                                    <img
                                                                                        class="place-claim-correct-img"
                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                System for Online Reservations or Tickets
                                                                            </li>
                                                                            <li class="row">
                                                                                <div class="place-claim-correct-box">
                                                                                    <img
                                                                                        class="place-claim-correct-img"
                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                Promotional Catalog for Pricing & Exhibits
                                                                            </li>
                                                                        </ul>
                                                                        : category === "aquarium" ?
                                                                            <ul>
                                                                                {/* <li class="row">
                                                                                    <div class="place-claim-correct-box">
                                                                                        <img
                                                                                            class="place-claim-correct-img"
                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    Free Trial
                                                                                </li> */}
                                                                                <li class="row">
                                                                                    <div class="place-claim-correct-box">
                                                                                        <img
                                                                                            class="place-claim-correct-img"
                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    Location based Advertisement
                                                                                </li>
                                                                                <li class="row">
                                                                                    <div class="place-claim-correct-box">
                                                                                        <img
                                                                                            class="place-claim-correct-img"
                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    Share your working hours, social media, phone, website
                                                                                </li>
                                                                                <li class="row">
                                                                                    <div class="place-claim-correct-box">
                                                                                        <img
                                                                                            class="place-claim-correct-img"
                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    System for Online Reservations or Tickets
                                                                                </li>
                                                                                <li class="row">
                                                                                    <div class="place-claim-correct-box">
                                                                                        <img
                                                                                            class="place-claim-correct-img"
                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    Promotional Catalog for Pricing & Species
                                                                                </li>
                                                                            </ul>
                                                                            : category === "amusement park" ?
                                                                                <ul>
                                                                                    {/* <li class="row">
                                                                                        <div class="place-claim-correct-box">
                                                                                            <img
                                                                                                class="place-claim-correct-img"
                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        Free Trial
                                                                                    </li> */}
                                                                                    <li class="row">
                                                                                        <div class="place-claim-correct-box">
                                                                                            <img
                                                                                                class="place-claim-correct-img"
                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        Location based Advertisement
                                                                                    </li>
                                                                                    <li class="row">
                                                                                        <div class="place-claim-correct-box">
                                                                                            <img
                                                                                                class="place-claim-correct-img"
                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        Share your working hours, social media, phone, website
                                                                                    </li>
                                                                                    <li class="row">
                                                                                        <div class="place-claim-correct-box">
                                                                                            <img
                                                                                                class="place-claim-correct-img"
                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        System for Online Reservations
                                                                                    </li>
                                                                                    <li class="row">
                                                                                        <div class="place-claim-correct-box">
                                                                                            <img
                                                                                                class="place-claim-correct-img"
                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        Promotional Catalog for Pricing & Facilities
                                                                                    </li>
                                                                                </ul>
                                                                                : category === "zoo" ?
                                                                                    <ul>
                                                                                        {/* <li class="row">
                                                                                            <div class="place-claim-correct-box">
                                                                                                <img
                                                                                                    class="place-claim-correct-img"
                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            Free Trial
                                                                                        </li> */}
                                                                                        <li class="row">
                                                                                            <div class="place-claim-correct-box">
                                                                                                <img
                                                                                                    class="place-claim-correct-img"
                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            Location based Advertisement
                                                                                        </li>
                                                                                        <li class="row">
                                                                                            <div class="place-claim-correct-box">
                                                                                                <img
                                                                                                    class="place-claim-correct-img"
                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            Share your working hours, social media, phone, website
                                                                                        </li>
                                                                                        <li class="row">
                                                                                            <div class="place-claim-correct-box">
                                                                                                <img
                                                                                                    class="place-claim-correct-img"
                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            System for Online Reservations
                                                                                        </li>
                                                                                        <li class="row">
                                                                                            <div class="place-claim-correct-box">
                                                                                                <img
                                                                                                    class="place-claim-correct-img"
                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            Promotional Catalog for Pricing & Animals
                                                                                        </li>
                                                                                    </ul>
                                                                                    : category === "university" ?
                                                                                        <ul>
                                                                                            {/* <li class="row">
                                                                                                <div class="place-claim-correct-box">
                                                                                                    <img
                                                                                                        class="place-claim-correct-img"
                                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                Free Trial
                                                                                            </li> */}
                                                                                            <li class="row">
                                                                                                <div class="place-claim-correct-box">
                                                                                                    <img
                                                                                                        class="place-claim-correct-img"
                                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                Location based Advertisement
                                                                                            </li>
                                                                                            <li class="row">
                                                                                                <div class="place-claim-correct-box">
                                                                                                    <img
                                                                                                        class="place-claim-correct-img"
                                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                Share your working hours, social media, phone, website
                                                                                            </li>

                                                                                            <li class="row">
                                                                                                <div class="place-claim-correct-box">
                                                                                                    <img
                                                                                                        class="place-claim-correct-img"
                                                                                                        src={require("../../../assets/map/svg/correct.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                Promotional Catalog for Offered Degrees
                                                                                            </li>
                                                                                        </ul>
                                                                                        : category === "clothing store" || category === "electronics store" || category === "shoe store" || category === "supermarket" || category === "jewelry store" || category === "liquor store" || category === "pet store" || category === "shopping mall" || category === "convenience store" || category === "home goods store" || category === "hardware store" || category === "florist" || category === "furniture store" || category === "bicycle store" || category === "book store" || category === "car dealer" || category === "bakery" ?
                                                                                            <ul>
                                                                                                {/* <li class="row">
                                                                                                    <div class="place-claim-correct-box">
                                                                                                        <img
                                                                                                            class="place-claim-correct-img"
                                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    Free Trial
                                                                                                </li> */}
                                                                                                <li class="row">
                                                                                                    <div class="place-claim-correct-box">
                                                                                                        <img
                                                                                                            class="place-claim-correct-img"
                                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    Location based Advertisement
                                                                                                </li>
                                                                                                <li class="row">
                                                                                                    <div class="place-claim-correct-box">
                                                                                                        <img
                                                                                                            class="place-claim-correct-img"
                                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    Inform customers about New Collections or Sales
                                                                                                </li>
                                                                                                <li class="row">
                                                                                                    <div class="place-claim-correct-box">
                                                                                                        <img
                                                                                                            class="place-claim-correct-img"
                                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    Promote your Online Store
                                                                                                </li>
                                                                                                <li class="row">
                                                                                                    <div class="place-claim-correct-box">
                                                                                                        <img
                                                                                                            class="place-claim-correct-img"
                                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    Share your working hours, social media, phone, website
                                                                                                </li>

                                                                                                <li class="row">
                                                                                                    <div class="place-claim-correct-box">
                                                                                                        <img
                                                                                                            class="place-claim-correct-img"
                                                                                                            src={require("../../../assets/map/svg/correct.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    Catalogs of New Collections or Products
                                                                                                </li>
                                                                                            </ul>
                                                                                            : category === "beauty salon" || category === "hair care" || category === "spa" || category === "doctor" || category === "dentist" || category === "lawyer" || category === "electrician" || category === "accounting" || category === "veterinary care" || category === "painter" || category === "physiotherapist" || category === "plumber" || category === "car repair" ?
                                                                                                <ul>
                                                                                                    {/* <li class="row">
                                                                                                        <div class="place-claim-correct-box">
                                                                                                            <img
                                                                                                                class="place-claim-correct-img"
                                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        Free Trial
                                                                                                    </li> */}
                                                                                                    <li class="row">
                                                                                                        <div class="place-claim-correct-box">
                                                                                                            <img
                                                                                                                class="place-claim-correct-img"
                                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        Location based Advertisement
                                                                                                    </li>
                                                                                                    <li class="row">
                                                                                                        <div class="place-claim-correct-box">
                                                                                                            <img
                                                                                                                class="place-claim-correct-img"
                                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        Share your working hours, social media, phone, website
                                                                                                    </li>
                                                                                                    <li class="row">
                                                                                                        <div class="place-claim-correct-box">
                                                                                                            <img
                                                                                                                class="place-claim-correct-img"
                                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        System for Online Appointments
                                                                                                    </li>
                                                                                                    <li class="row">
                                                                                                        <div class="place-claim-correct-box">
                                                                                                            <img
                                                                                                                class="place-claim-correct-img"
                                                                                                                src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        Promotional Catalog
                                                                                                    </li>
                                                                                                </ul>
                                                                                                : category === "laundry" || category === "bank" || category === "gym" || category === "insurance agency" || category === "travel agency" || category === "car rental" || category === "real estate agency" || category === "car wash" || category === "locksmith" ?
                                                                                                    <ul>
                                                                                                        {/* <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Free Trial
                                                                                                        </li> */}
                                                                                                        <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Location based Advertisement
                                                                                                        </li>
                                                                                                        <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Share your working hours, social media, phone, website
                                                                                                        </li>

                                                                                                        <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Promotional Catalog
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                    :
                                                                                                    // : category === "parking" ?
                                                                                                    // : category === "library" ?
                                                                                                    <ul>
                                                                                                        {/* <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Free Trial
                                                                                                        </li> */}
                                                                                                        <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Location based Advertisement
                                                                                                        </li>
                                                                                                        <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Real time information customers needs to know
                                                                                                        </li>
                                                                                                        <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            System for Online Reservations or Appointments
                                                                                                        </li>
                                                                                                        <li class="row">
                                                                                                            <div class="place-claim-correct-box">
                                                                                                                <img
                                                                                                                    class="place-claim-correct-img"
                                                                                                                    src={require("../../../assets/map/svg/correct.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            Promotional Catalog
                                                                                                        </li>
                                                                                                    </ul>
                                            }

                                            <a onClick={() => this.changeClaimMode()} class="place-claim-plan-btn">Claim Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="row place-claim-row-position">
                                <div class="place-claim-offer-box">
                                    <div class="place-claim-price-box">
                                        <div class="">
                                            <div class="place-claim-price-label basic">Monthly Plan</div>
                                            <div class="place-claim-price">$ 5.99</div>
                                            <div class="place-claim-price-info">Per Month.</div>
                                        </div>
                                        <div class="info">
                                            <ul>
                                                <li><i class="fas fa-check"></i>5k Searchable messages</li>
                                                <li><i class="fas fa-check"></i>10 custom Apps/services</li>
                                                <li><i class="fas fa-check"></i>Minimum 3 users, max 10 users</li>
                                                <li><i class="fas fa-check"></i>1 Voice and video call</li>
                                            </ul>
                                            <a href="#" class="place-claim-plan-btn">Join Monthly Plan</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="place-claim-price-box">
                                        <div class="">
                                            <div class="place-claim-price-label value">Value Plan</div>
                                            <div class="place-claim-price">$ 10.99</div>
                                            <div class="place-claim-price-info">Per Month, Inlc GST.</div>
                                        </div>
                                        <div class="info">
                                            <ul>
                                                <li><i class="fas fa-check"></i>5k Searchable messages</li>
                                                <li><i class="fas fa-check"></i>10 custom Apps/services</li>
                                                <li><i class="fas fa-check"></i>Minimum 3 users, max 10 users</li>
                                                <li><i class="fas fa-check"></i>1 Voice and video call</li>
                                            </ul>
                                            <a href="#" class="place-claim-plan-btn">Join Value Plan</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="place-claim-offer-box">
                                    <div class="place-claim-price-box">
                                        <div class="">
                                            <div class="place-claim-price-label premium">Annual Plan</div>
                                            <div class="place-claim-price">$ 15.99</div>
                                            <div class="place-claim-price-info">Per Year.</div>
                                        </div>
                                        <div class="info">
                                            <ul>
                                                <li><i class="fas fa-check"></i>5k Searchable messages</li>
                                                <li><i class="fas fa-check"></i>10 custom Apps/services</li>
                                                <li><i class="fas fa-check"></i>Minimum 3 users, max 10 users</li>
                                                <li><i class="fas fa-check"></i>1 Voice and video call</li>
                                            </ul>
                                            <a href="#" class="place-claim-plan-btn">Join Annual Plan</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </>
                        :
                        <>
                            {this.props.data.submitedHubContact ?
                                <>
                                    <div class="map-search-contact-success-box">
                                        <img
                                            class="map-search-contact-success-img"
                                            src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="map-search-contact-success-subtitle">Your Request has been Submited and we will contact you as soon as possible!!</div>
                                    <div onClick={() => this.closeModal()} class="map-search-hub-contact-success-done-btn">
                                        Done
                                    </div>
                                </>
                                :
                                <>
                                    <div class="map-search-hub-contact-titel">
                                        <b>
                                            {closer_name.length > 30 ?
                                                <>
                                                    {closer_name.slice(0, 28) + "..."}
                                                </>
                                                :
                                                <>
                                                    {closer_name}
                                                </>
                                            }
                                        </b>
                                        <br />
                                        Complete your Contact Information and we will contact you as soon as possible
                                    </div>

                                    <div class="map-search-hub-contact-name-text">
                                        Insert your Name
                                    </div>
                                    {/* <div class="map-search-hub-contact-phone-input"> */}
                                    <input
                                        // maxlength="18"
                                        className="map-search-hub-contact-name-input"
                                        type="text"
                                        name="hub_contact_name"
                                        onChange={this.props.data.onChange}
                                        value={this.props.data.hub_contact_name}
                                        placeholder="Name..."
                                    />

                                    <div class="map-search-hub-contact-phone-text">
                                        Insert your Mobile Phone Number
                                    </div>
                                    {/* <div class="map-search-hub-contact-phone-input"> */}
                                    <input
                                        maxlength="18"
                                        className="map-search-hub-contact-phone-input"
                                        type="number"
                                        name="hub_contact_telephone"
                                        onChange={this.props.data.onChange}
                                        value={this.props.data.hub_contact_telephone}
                                        placeholder="Mobile Phone Number..."
                                    />


                                    <div onClick={() => this.props.data.submitContactForm(closer_place_id, closer_name)} class="map-search-hub-contact-claim-btn">
                                        Send Claim Request
                                    </div>

                                </>
                            }
                        </>
                    }
                </div>

            </Fragment >
        )
    }
}


export default (PlaceClaimModal);