import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import { getMemoryImpressions } from '../../../store/actions/memory'
import { getMyProfile } from '../../../store/actions/profile';
import { getNotificationsStatus } from '../../../store/actions/notification';
import { sendBuddyRequest } from '../../../store/actions/buddies'
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import "../../../assets/memories/impressions.css"


export class MemoryImpressions extends Component {

    state = {
        user_search: "",
        send_buddyrequests: [],
    };

    componentDidMount() {
        this.props.getMyProfile();
        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }
        if (this.props.location.state.memory) {
            this.props.getMemoryImpressions(this.props.location.state.memory);
        }
    }

    buddyRequestSubmit = (user) => (e) => {
        e.preventDefault();
        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }
        const sending_user = profile.user
        const recieving_user = user
        const status = "Send"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.sendBuddyRequest(buddyrequest);
        const old_list = [...this.state.send_buddyrequests]
        old_list.push(user)
        this.setState({ send_buddyrequests: old_list })
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {
        let impressions = []
        if (this.props.impressions) {
            impressions = this.props.impressions
        }
        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "impressions-lottie-appear" : "impressions-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />

                {/* <div class={this.props.isLoading ? "impressions-section-disappear" : null}> */}
                <div>
                    <div class="impressions-total-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row">

                                <div class="likes-page-mobile-navbar">
                                    <div onClick={() => this.handleBack()} class="back-button-likes-page-box">
                                        <img
                                            class="likes-page-back-button"

                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                            alt=""
                                        />
                                    </div>
                                    Likes

                                </div>

                                {/* <div class="impressions-back-icon-box">
                                    <img
                                        class="impressions-back-icon"
                                        onClick={this.handleBack}
                                        src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                        alt=""
                                    />
                                </div> */}
                                <div class="impressions-hub-color">
                                    <div id="page-scroll-up" class="container-fluid">
                                        <div class="row impressions-row">
                                            <div class="text-center">
                                                {/* <h2 class="impressions">
                                                    Impressions
                                                </h2> */}
                                            </div>
                                        </div>
                                    </div>



                                    <div class="">
                                        <div class="impressions-page-body-right pb-5">
                                            {/* <div class="col-xl-12 text-center hide-h3">
                                                <h3 class="text-blue-empty-impressions"><strong>Impressions</strong></h3>
                                            </div> */}

                                            <div class="all-impressions-itemds">
                                                {impressions.map(impression => (
                                                    <div class="row single-item-impression">
                                                        <div class="impression-profile-picture-position">
                                                            <Link to={{ pathname: `/user-profile/${impression.sending_user}/profile`, state: { user: impression.sending_user } }}>
                                                                {impression.sending_user_profile_picture ?
                                                                    <img
                                                                        class="impression-profile-picture"
                                                                        src={impression.sending_user_profile_picture}
                                                                        alt=""
                                                                    />
                                                                    :
                                                                    <img
                                                                        class="impression-profile-picture"
                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                        alt=""
                                                                    />
                                                                }



                                                            </Link>
                                                        </div>
                                                        <div class="impression-profile-username">
                                                            <Link to={{ pathname: `/user-profile/${impression.sending_user}`, state: { user: impression.sending_user } }}>
                                                                <h2 class="text-blue">
                                                                    {impression.sending_user_username}
                                                                </h2>
                                                            </Link>
                                                        </div>


                                                        <div class="likes-heart-icon">
                                                            <img
                                                                class="likes-heart-icon-img"
                                                                src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                            />
                                                        </div>

                                                        {/* {impression.by_buddy ?
                                                            <div class="impression-non-buddy-mika-img-position">
                                                                {impression.reaction_status === "love" ?
                                                                    <img
                                                                        class="impression-non-buddy-mika-img"
                                                                        // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                                        src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                                    />
                                                                    : impression.reaction_status === "wow" ?
                                                                        <img
                                                                            class="impression-non-buddy-mika-img"
                                                                            src={require("../../../assets/layout/mika/star-eyes-animation.gif")}
                                                                        />
                                                                        : impression.reaction_status === "like" ?
                                                                            <img
                                                                                class="impression-non-buddy-mika-img"
                                                                                src={require("../../../assets/layout/mika/mika-like-animation.gif")}
                                                                            />
                                                                            : impression.reaction_status === "cursing" ?
                                                                                <img
                                                                                    class="impression-non-buddy-mika-img"
                                                                                    src={require("../../../assets/layout/mika/cursing-animation.gif")}
                                                                                />
                                                                                :
                                                                                null
                                                                }
                                                            </div>

                                                            : this.state.send_buddyrequests.includes(impression.sending_user) ?
                                                                <div class="impression-non-buddy-mika-img-position">
                                                                    {impression.reaction_status === "love" ?
                                                                        <img
                                                                            class="impression-non-buddy-mika-img"
                                                                            // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                                            src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                                        />
                                                                        : impression.reaction_status === "wow" ?
                                                                            <img
                                                                                class="impression-non-buddy-mika-img"
                                                                                src={require("../../../assets/layout/mika/star-eyes-animation.gif")}
                                                                            />
                                                                            : impression.reaction_status === "like" ?
                                                                                <img
                                                                                    class="impression-non-buddy-mika-img"
                                                                                    src={require("../../../assets/layout/mika/mika-like-animation.gif")}
                                                                                />
                                                                                : impression.reaction_status === "cursing" ?
                                                                                    <img
                                                                                        class="impression-non-buddy-mika-img"
                                                                                        src={require("../../../assets/layout/mika/cursing-animation.gif")}
                                                                                    />
                                                                                    :
                                                                                    null
                                                                    }
                                                                </div>

                                                                : impression.sending_user === impression.sending_user ?
                                                                    <div class="impression-non-buddy-mika-img-position">
                                                                        {impression.reaction_status === "love" ?
                                                                            <img
                                                                                class="impression-non-buddy-mika-img"
                                                                                // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                                                src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                                            />
                                                                            : impression.reaction_status === "wow" ?
                                                                                <img
                                                                                    class="impression-non-buddy-mika-img"
                                                                                    src={require("../../../assets/layout/mika/star-eyes-animation.gif")}
                                                                                />
                                                                                : impression.reaction_status === "like" ?
                                                                                    <img
                                                                                        class="impression-non-buddy-mika-img"
                                                                                        src={require("../../../assets/layout/mika/mika-like-animation.gif")}
                                                                                    />
                                                                                    : impression.reaction_status === "cursing" ?
                                                                                        <img
                                                                                            class="impression-non-buddy-mika-img"
                                                                                            src={require("../../../assets/layout/mika/cursing-animation.gif")}
                                                                                        />
                                                                                        :
                                                                                        null
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div class="impression-mika-img-position">
                                                                            {impression.reaction_status === "love" ?
                                                                                <img
                                                                                    class="impression-mika-img"
                                                                                    // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                                                    src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                                                />
                                                                                : impression.reaction_status === "wow" ?
                                                                                    <img
                                                                                        class="impression-mika-img"
                                                                                        src={require("../../../assets/layout/mika/star-eyes-animation.gif")}
                                                                                    />
                                                                                    : impression.reaction_status === "like" ?
                                                                                        <img
                                                                                            class="impression-mika-img"
                                                                                            src={require("../../../assets/layout/mika/mika-like-animation.gif")}
                                                                                        />
                                                                                        : impression.reaction_status === "cursing" ?
                                                                                            <img
                                                                                                class="impression-mika-img"
                                                                                                src={require("../../../assets/layout/mika/cursing-animation.gif")}
                                                                                            />
                                                                                            :
                                                                                            null
                                                                            }
                                                                        </div>
                                                                        <div class="impression-add-buddy-position">
                                                                            <div
                                                                                onClick={this.buddyRequestSubmit(impression.sending_user)}
                                                                                class="impression-add-buddy-button">
                                                                                Add
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                        } */}
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />
                </ScrollLink>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    notifications_status: state.notification.notifications_status,
    impressions: state.memory.impressions
});

export default connect(mapStateToProps, { getNotificationsStatus, getMyProfile, getMemoryImpressions, sendBuddyRequest })(MemoryImpressions);