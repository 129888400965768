import reservations from '../../components/user/meeting/userMeetings/reservations';
import {
    GET_PENDING_MEETINGS,
    PENDING_MEETINGS_LOCALLY_LOADED,
    GET_CANCELLED_MEETINGS,
    CANCELLED_MEETINGS_LOCALLY_LOADED,
    GET_RESERVATION_MEETINGS,
    RESERVATION_MEETINGS_LOCALLY_LOADED,
    GET_MEETING_INVITATIONS,
    GET_ACCEPTED_MEETINGS,
    GET_DECLINED_MEETINGS,
    CREATE_MEETING,
    UPDATE_MEETING,
    CANCEL_MEETING,
    ANSWER_INVITATION,
    CLEAR_MEETING,
    CANCEL_RESERVATION_MEETING
} from '../actions/actionTypes';

const initialState = {
    meetings: [],
    acceptedmeetings: [],
    declinedmeetings: [],
    invitations: [],
    cancellations: [],
    reservations: [],
    meetingIsCreated: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_MEETING:
            return {
                ...state,
                meetings: [],
                acceptedmeetings: [],
                declinedmeetings: [],
                invitations: [],
                cancellations: [],
                reservations: [],
                meetingIsCreated: null,
            };
        case GET_PENDING_MEETINGS:
            localStorage.setItem('pdgmtg', JSON.stringify(action.payload));
            return {
                ...state,
                meetings: action.payload
            };
        case PENDING_MEETINGS_LOCALLY_LOADED:
            return {
                ...state,
                meetings: JSON.parse(localStorage.getItem('pdgmtg'))
            };
        case GET_ACCEPTED_MEETINGS:
            return {
                ...state,
                acceptedmeetings: action.payload
            };
        case GET_DECLINED_MEETINGS:
            return {
                ...state,
                declinedmeetings: action.payload
            };
        case GET_CANCELLED_MEETINGS:
            localStorage.setItem('ccldmtg', JSON.stringify(action.payload));
            return {
                ...state,
                cancellations: action.payload
            };
        case CANCELLED_MEETINGS_LOCALLY_LOADED:
            return {
                ...state,
                cancellations: JSON.parse(localStorage.getItem('ccldmtg'))
            };
        case GET_RESERVATION_MEETINGS:
            localStorage.setItem('rsvts', JSON.stringify(action.payload));
            return {
                ...state,
                reservations: action.payload
            };
        case CANCEL_RESERVATION_MEETING:
            localStorage.setItem('rsvts', JSON.stringify(action.payload));
            return {
                ...state,
                reservations: action.payload
            };
        case RESERVATION_MEETINGS_LOCALLY_LOADED:
            return {
                ...state,
                reservations: JSON.parse(localStorage.getItem('rsvts'))
            };
        case GET_MEETING_INVITATIONS:
            return {
                ...state,
                invitations: action.payload
            };
        case CREATE_MEETING:
            return {
                ...state,
                meetingIsCreated: true,
                // meetings: [...state.meetings, action.payload]
            };


        // case UPDATE_MEETING:
        //     console.log(action.payload)
        //     return {
        //         ...state,
        //         meetings: state.meetings.map(meeting => action.payload.find(change => change.id === meeting.id) || meeting)
        //     };


        case CANCEL_MEETING:
            return {
                ...state,
                meetings: state.meetings.filter(meeting => meeting.id !== action.payload),
                reservations: state.reservations.filter(meeting => meeting.id !== action.payload)
            };
        default:
            return state;
    }
}

