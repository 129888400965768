import React from 'react';
import "../../../assets/hub/hub-info/hub-jobs-modal.css"



class CreateJobModal extends React.Component {
    state = {
        name: "",
        description: "",
        money: ""
    };

    closeModal = () => {
        this.props.data.closeModal()
        this.setState({
            name: "",
            description: "",
            money: ""
        })
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onJobSubmit = (e) => {
        // setTimeout(() => {
        const name = this.state.name
        const description = this.state.description
        const money = this.state.money
        const hubjob = { name, description, money }
        this.props.createAmbassandorHubjob(hubjob)
        this.setState({
            name: "",
            description: "",
            money: ""
        })
        this.props.data.closeModal()
        // }, 500);
    }

    render() {
        return (

            <div class="">
                <div class="row">
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                    <div class="create-job-box">
                        <div class="row text-right">
                            <div class="col-xl-12 text-right">
                                <div
                                    onClick={() => this.closeModal()}
                                    class="create-job-times-frame">
                                    <img class="create-job-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="text-left">
                            <h4>
                                <strong class="create-job-hub-name">
                                    Create the job you need to hire
                                </strong>
                            </h4>
                        </div>

                        <p class="create-job-hub-section-title">Name of the Job</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-hub-reason">
                                    <textarea
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        placeholder="Write the Name of the Job..."
                                        rows="1"
                                        name="name"
                                        onChange={this.onChange}
                                        maxlength="20"
                                    ></textarea>
                                </strong>
                            </h4>
                        </div>

                        <p class="create-job-hub-section-title">Job Description (250 letters)</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-hub-reason">
                                    <textarea
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        placeholder="Write a description for the Job..."
                                        rows="4"
                                        name="description"
                                        onChange={this.onChange}
                                        maxlength="200"
                                    ></textarea>
                                </strong>
                            </h4>
                        </div>

                        <p class="create-job-hub-section-title">Monthly Salary for the Job</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-hub-reason">
                                    <textarea
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        placeholder="Write the Salary for the Job..."
                                        rows="1"
                                        name="money"
                                        onChange={this.onChange}
                                        maxlength="20"
                                    ></textarea>
                                </strong>
                            </h4>
                        </div>

                        <div onClick={this.onJobSubmit} class="create-job-create-job">
                            Create
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                    </div>
                </div>
            </div>
        )
    }
}


export default (CreateJobModal);