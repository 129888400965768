import {
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION
} from "../actions/actionTypes";

const initialState = {
    isLoading: false,
    noInternet: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_PAGE:
            return {
                ...state,
                isLoading: true,
                noInternet: false
            };
        case PAGE_LOADED:
            return {
                ...state,
                isLoading: false,
                noInternet: false
            };
        case NO_INTERNET_CONNECTION:
            return {
                ...state,
                isLoading: false,
                noInternet: true
            };
        default:
            return state;
    }
};