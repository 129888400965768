import React, { Fragment, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import HubBottomNavbar from '../../../components/hub/layout/hubBottomNavabar';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import HubMobileTraffic from '../../../components/hub/layout/hubMobileTraffic';
import HubDesktopTraffic from '../../../components/hub/layout/hubDesktopTraffic';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
import '../../../assets/hub/hub-info/hub-splash.css'
import { getHubAmbasaddorsProfile } from '../../../store/actions/hub';
import { changeHubJobActivation, updateHubAmbassandor, getAmbasaddorsHubJobs, createAmbassandorHubjob, deleteAmbassandorHubjob } from '../../../store/actions/ambassandor';
import CreateJobModal from '../../../components/hub/hub-info/CreateJobModal';
import ShowJobModal from '../../../components/hub/hub-info/ShowJobModal';




export class Hubst3rSplash extends Component {

    state = {
        createJobModalVisible: false,
        showJobModalVisible: false,
        job: {}
    };


    componentDidMount() {
        // const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        // this.props.getHubPage();
        // this.props.getHubAmbasaddorsProfile(hub_id)
        this.props.getAmbasaddorsHubJobs()
    }

    openCreateHubJobsModal() {
        this.setState({
            createJobModalVisible: true,
        });
    }

    openShowHubJobsModal(job) {
        this.setState({
            showJobModalVisible: true,
            job: job
        });
    }

    closeModal() {
        this.setState({
            createJobModalVisible: false,
            showJobModalVisible: false,
            job: {}
        });
    }


    changeHubJobActivation = (id, is_active) => {
        const hubjob = { id, is_active }
        this.props.changeHubJobActivation(hubjob)
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <Fragment>
                <Modal visible={this.state.createJobModalVisible} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                    <CreateJobModal
                        data={{
                            closeModal: this.closeModal.bind(this)
                        }}
                        createAmbassandorHubjob={this.props.createAmbassandorHubjob}
                    />
                </Modal>
                <Modal visible={this.state.showJobModalVisible} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                    <ShowJobModal
                        data={{
                            job: this.state.job,
                            closeModal: this.closeModal.bind(this)
                        }}
                        deleteAmbassandorHubjob={this.props.deleteAmbassandorHubjob}
                    />
                </Modal>
                <div class={this.props.isLoading ? "ambassandors-page-lottie-appear" : "ambassandors-page-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div>
                <div class="ambassandor-all-page-display">
                    <div class={this.props.isLoading ? "ambassandors-page-section-disappear" : null}>
                        <div class="ambassandors-page-whole-page-layout">
                            <OfflineLabel />
                            <div class="color-4">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="container-fluid small-header">
                                            <div class="row py-4">
                                                <div class="">
                                                    <img
                                                        class="ambassandor-top-back-button"
                                                        onClick={this.handleBack}
                                                        src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="ambassandor-top-bar-logo">
                                                    <Link to="/">
                                                        <img src={require("../../../assets/layout/homepage svg/Logo.svg")} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ambassandors-body-right col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <>
                                                <>
                                                    <div class="ambassandor-we-are-hiring">
                                                        <img
                                                            class="ambassandor-hiring-icon-size"
                                                            src={require("../../../assets/ambassandors/svg/hiring.png")}
                                                            alt=""
                                                        />

                                                    </div>
                                                    <h2 class="ambassandor-profile-username">
                                                        What are we looking for?
                                                    </h2>
                                                    <div>
                                                        {this.props.jobs.map(job => (
                                                            <div class="row hub-job-item">
                                                                <h2
                                                                    onClick={() => this.openShowHubJobsModal(job)}
                                                                    class="hub-job-name">
                                                                    {job.name}
                                                                </h2>
                                                                {job.is_active ?
                                                                    <div
                                                                        onClick={() => this.changeHubJobActivation(job.id, false)}
                                                                        class="hub-job-item-active">
                                                                        Active
                                                                            </div>
                                                                    :
                                                                    <div
                                                                        onClick={() => this.changeHubJobActivation(job.id, true)}
                                                                        class="hub-job-item-inactive">
                                                                        Not active
                                                                    </div>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>

                                                <div
                                                    onClick={() => this.openCreateHubJobsModal()}
                                                    class="ambassandor-profile-save-button">
                                                    Create new Job
                                                </div>
                                            </>

                                            <div class="ambassandor-gdpr-place">
                                                <HubGdprLinks />
                                            </div>

                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <HubBottomNavbar data={{ sellected: "" }} />
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    hub: state.hub.hub,
    ambassandor: state.hub.ambassandor,
    jobs: state.ambassandor.jobs,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    changeHubJobActivation,
    getAmbasaddorsHubJobs,
    createAmbassandorHubjob,
    deleteAmbassandorHubjob
})(Hubst3rSplash);
