import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_STATUS,
    NOTIFICATIONS_LOCALY_LOADED,
    DELETE_NOTIFICATION,
    GET_EMAIL_NOTIFICATIONS_STATUS,
    CHANGE_EMAIL_NOTIFICATIONS_STATUS,
    GET_HUB_PENDING_NOTIFICATIONS_STATUS,
    GET_HUB_CANCELLATIONS_NOTIFICATIONS_STATUS,
    CLEAR_NOTIFICATION,
    SEND_PUSH_NOTIFICATION_INFO,
} from '../actions/actionTypes';

const initialState = {
    notifications: [],
    notifications_status: [],
    pending_notifications_status: [],
    cancellations_notifications_status: [],
    email_notification: []

}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notifications: [],
                notifications_status: [],
                pending_notifications_status: [],
                cancellations_notifications_status: [],
                email_notification: []
            };
        case SEND_PUSH_NOTIFICATION_INFO:
            localStorage.setItem("ntfctnsrgstr", JSON.stringify(true))
            return {
                ...state,
            };

        case GET_NOTIFICATIONS:
            localStorage.setItem('ntfctn', JSON.stringify(action.payload));
            return {
                ...state,
                notifications: action.payload
            };
        case NOTIFICATIONS_LOCALY_LOADED:
            return {
                ...state,
                notifications: JSON.parse(localStorage.getItem('ntfctn'))
            };
        case GET_NOTIFICATIONS_STATUS:
            return {
                ...state,
                notifications_status: action.payload
            };
        case GET_HUB_PENDING_NOTIFICATIONS_STATUS:
            return {
                ...state,
                pending_notifications_status: action.payload
            };
        case GET_HUB_CANCELLATIONS_NOTIFICATIONS_STATUS:
            return {
                ...state,
                cancellations_notifications_status: action.payload
            };
        case GET_EMAIL_NOTIFICATIONS_STATUS:
            localStorage.setItem('entfcs', JSON.stringify(action.payload));
            return {
                ...state,
                email_notification: action.payload
            };
        // case CHANGE_EMAIL_NOTIFICATIONS_STATUS:
        //     return {
        //         ...state,
        //         email_notification: action.payload
        //     };
        default:
            return state;
    }
}