import React, { Fragment, Component, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { cancelBuddyRequest, sendBuddyRequest, getSearchUsers } from '../../../store/actions/buddies'
import { getMyProfile } from '../../../store/actions/profile';
import { getNotificationsStatus } from '../../../store/actions/notification'
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import '../../../assets/buddies/swipeBuddies.css'
import TinderCard from 'react-tinder-card'
import SwipeBuddiesFull from '../../../components/user/search-buddies/swipeBuddiesFull';

export class SwipeBuddies extends Component {

    state = {
        user_search: "",
        send_buddyrequests: [],
        answered_request: [],
        cancelled_requests: [],
        swiping_user: false,
    };

    componentDidMount() {
        if (this.props.users.length === 0) {
            this.props.getSearchUsers();
        }
        this.props.getMyProfile();
    }


    buddyRequestSubmit(user) {
        // e.preventDefault();
        const sending_user = this.props.profile[0].user
        const recieving_user = user
        const status = "Send"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.sendBuddyRequest(buddyrequest);
    }

    cancelBuddyRequest(user) {
        const sending_user = this.props.profile[0].user
        const recieving_user = user
        const status = "Cancelled"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.cancelBuddyRequest(buddyrequest);
    }

    updateBuddyrequestList(user) {
        // e.preventDefault();
        const old_list = [...this.state.send_buddyrequests]
        if ((old_list.indexOf(user)) !== -1) {
            const new_remove = old_list.filter(f => f !== user)
            this.setState({ send_buddyrequests: new_remove })
        }
        else {
            old_list.push(user)
            this.setState({ send_buddyrequests: old_list })
        }
    }

    updateCancelledRequestsList = (user) => (e) => {
        e.preventDefault();
        const old_list = [...this.state.cancelled_requests]
        if ((old_list.indexOf(user)) !== -1) {
            const new_remove = old_list.filter(f => f !== user)
            this.setState({ cancelled_requests: new_remove })
        }
        else {
            old_list.push(user)
            this.setState({ cancelled_requests: old_list })
        }
    }



    onSwipe = (direction) => {
        // console.log('You swiped: ' + direction)
        this.setState({
            swiping_user: false
        })
    }

    outOfFrame = (myIdentifier) => {
        // console.log(myIdentifier + ' left the screen')
        this.setState({
            swiping_user: false
        })
    }

    clickOnSwipe = (user) => (e) => {
        // console.log(window)
        this.setState({
            swiping_user: true
        })
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    getNewBuddies() {
        this.props.getSearchUsers();
    }


    render() {
        let profile = []
        if (profile) {
            profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        }

        return (
            <Fragment>

                <div class="swipe-buddies-whole-page-layout">
                    <OfflineLabel />
                    <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />

                    {/* <div class="swipe-buddies-right-10"> */}
                    <div id="page-scroll-up" class="">
                        {/* <div onClick={() => this.handleBack()} class="back-button-swipe-buddies-box">
                            <img
                                class="swipe-buddies-back-button"
                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                alt=""
                            />
                        </div>
                        Find Buddies */}
                    </div>
                    {this.props.users.length > 0 ?
                        <SwipeBuddiesFull
                            data={{
                                users: this.props.users,
                                profile_picture: profile.profile_picture,
                                send_buddyrequests: this.state.send_buddyrequests,
                                buddyRequestSubmit: this.buddyRequestSubmit.bind(this),
                                cancelBuddyRequest: this.cancelBuddyRequest.bind(this),
                                updateBuddyrequestList: this.updateBuddyrequestList.bind(this),
                                getNewBuddies: this.getNewBuddies.bind(this),
                            }}
                        />
                        :
                        null
                    }
                    {/* {this.props.users.map(user => (

                        <>
                            <TinderCard
                                className='swipe-buddies-swipe-box'
                                key={user.user}
                                onSwipe={(dir) => this.onSwipe(dir, user.user)}
                                onCardLeftScreen={() => this.outOfFrame(user.user)}
                                onSwipeRequirementUnfulfilled={this.clickOnSwipe(user.user)}
                            >

                                <div class="swipe-buddies-swipe-card">
                                    <div
                                        style={{ backgroundImage: 'url(' + user.profile_picture + ')' }}
                                        class='swipe-buddies-swipe-square'
                                    >
                                        <div class="swipe-buddies-username">
                                            {user.username}
                                        </div>

                                        <div class="swipe-buddies-black-cover-box"> </div>

                                        {user.user_memory_1 || user.user_memory_2 || user.user_memory_3 ?
                                            <div class="swipe-buddies-memories-box">
                                                <div class="row ">
                                                    {user.user_memory_1 !== null ?
                                                        <div class="swipe-buddies-memory-box-1">
                                                            <img
                                                                class="swipe-buddies-memory-size"
                                                                src={user.user_memory_1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        :
                                                        <div class="swipe-buddies-memory-box-1">
                                                            <div class="swipe-buddies-memory-empty">
                                                            </div>
                                                        </div>
                                                    }
                                                    {user.user_memory_2 !== null ?
                                                        <div class="swipe-buddies-memory-box-2">
                                                            <img
                                                                class="swipe-buddies-memory-size"
                                                                src={user.user_memory_2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        :
                                                        <div class="swipe-buddies-memory-box-2">
                                                            <div class="swipe-buddies-memory-empty">
                                                            </div>
                                                        </div>
                                                    }
                                                    {user.user_memory_3 !== null ?
                                                        <div class="swipe-buddies-memory-box-3">
                                                            <img
                                                                class="swipe-buddies-memory-size"
                                                                src={user.user_memory_3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        :
                                                        <div class="swipe-buddies-memory-box-3">
                                                            <div class="swipe-buddies-memory-empty">
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div class="swipe-buddies-memories-box-empty">
                                                <div class="row ">
                                                    <div class="swipe-buddies-empty-photos-box">
                                                        <img
                                                            class="swipe-buddies-empty-photos-icon"
                                                            src={require("../../../assets/layout/homepage svg/no-post.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div class="swipe-buddies-no-memories-yet">
                                                        No Memories yet
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>

                            </TinderCard>

                            <div class="swipe-buddies-all-choises-btns">
                                <div class="row swipe-buddies-all-choises-row">
                                    <div class="swipe-buddies-choise-box">
                                        <div class="swipe-buddies-choise-icon-box">
                                            <img
                                                onClick={() => this.onSwipe('left')}
                                                class="swipe-buddies-choise-icon-img"
                                                src={require("../../../assets/buddies/svg/swipe-previous.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div onClick={() => this.onSwipe('up')} class="swipe-buddies-choise-box">
                                        <div class="swipe-buddies-choise-icon-box">
                                            <img
                                                class="swipe-buddies-choise-icon-img"
                                                src={require("../../../assets/buddies/svg/swipe-profile.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    {!this.state.send_buddyrequests.includes(user.user) ?
                                        <div onClick={() => this.onSwipe('down')} class="swipe-buddies-choise-box">
                                            <div class="swipe-buddies-choise-icon-box">
                                                <img
                                                    class="swipe-buddies-choise-icon-img"
                                                    src={require("../../../assets/buddies/svg/swipe-add.svg")}
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                        :
                                        <div onClick={() => this.onSwipe('down')} class="swipe-buddies-choise-box">
                                            <div class="swipe-buddies-choise-icon-box">

                                                <img
                                                    class="swipe-buddies-choise-icon-img"
                                                    src={require("../../../assets/buddies/svg/swipe-cancel.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    }


                                    <div onClick={() => this.onSwipe('right')} class="swipe-buddies-choise-box">
                                        <div class="swipe-buddies-choise-icon-box">
                                            <img

                                                class="swipe-buddies-choise-icon-img"
                                                src={require("../../../assets/buddies/svg/swipe-next.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    ))} */}

                </div >
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />
                </ScrollLink>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in buddies.js
    profile: state.profile.profile,
    auth: state.auth,
    users: state.buddies.users,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { getSearchUsers, cancelBuddyRequest, sendBuddyRequest, getNotificationsStatus, getMyProfile })(SwipeBuddies);

