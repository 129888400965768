import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { switchToHubAccount, getMyProfile, getProfileHubsAccess, updateProfile, getProfileHubList } from '../../../store/actions/profile';
import { getBuddies, getGags, getGagMembers } from '../../../store/actions/buddies';
import { getUserAmbasaddorsProfile } from '../../../store/actions/ambassandor';
import { CheckStaffAccess } from '../../../store/actions/auth';
import {
    getMyMemories,
    createMemoryReaction,
    deleteReaction,
    deleteMemory,
    getMemoryTags,
    clearMemoryTags,
} from '../../../store/actions/memory';
import { getNotificationsStatus } from '../../../store/actions/notification'
import { getUserNotificationAcceptance, changeUserNotificationAcceptance } from '../../../store/actions/notification';
import { getUserCurrentJobs } from '../../../store/actions/hubPersonel'
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import BuddiesTab from '../../../components/user/profile/buddies';
import MemoriesTab from '../../../components/user/profile/memories';
import HubsAccessModal from '../../../components/user/profile/hubAccessModal';
import HubsTab from '../../../components/user/profile/hubs';
import InfoTab from '../../../components/user/profile/info';
import SplashTab from '../../../components/user/profile/SplashTab';
import SnapMemory from '../../../components/user/profile/SnapMemory';
import NotificationSettingsModal from '../../../components/user/layout/notificationSettingsModal';
import MemorySettingsModal from '../../../components/user/layout/MemorySettingsModal';
import Modal from 'react-awesome-modal';
import "../../../assets/profile/my-profile.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Stories from 'react-insta-stories';
import moment from "moment-timezone";


export class MyProfile extends Component {

    state = {
        photo: null,
        profile: this.props.profile,
        profile_picture: "",

        isInUsernameEditMode: false,
        isInFullNameEditMode: false,
        isInBioEditMode: false,
        isInGenterEditMode: false,
        isInBirthadyEditMode: false,
        isInEmailEditMode: false,
        isInCityEditMode: false,
        isInCountryEditMode: false,

        memoriesTab: true,
        personalInfoTab: false,
        hubsTab: false,
        buddiesTab: false,

        modalVisible: false,
        memorySettingsModalVisible: false,
        new_memory: "",
        memory_photo: null,
        memory_submited: false,
        setSplashTab: false,
        hubAccessModal: false,
        showstories: false,
        one_person: true,
        memories_user_id: "",
        story_memory_id: "",
        next_user_memory: true,
        story_index: 0,
        is_story_paused: null,
    }

    componentWillMount() {
        const id = JSON.parse(localStorage.getItem('ur')).id
        this.props.getMyProfile();
        this.props.getMyMemories();
        // this.props.getProfileHubList(id)
        // this.props.getBuddies(id);
        // this.props.getGags();
        this.props.getUserNotificationAcceptance();
        this.props.CheckStaffAccess()
        // this.props.getUserCurrentJobs()
        this.props.getUserAmbasaddorsProfile()
        this.props.getProfileHubsAccess()
    }

    changeMemoriesMode = () => {
        this.setState({
            memoriesTab: true,
            personalInfoTab: false,
            hubsTab: false,
            buddiesTab: false,
            setSplashTab: false
        })
    }

    changeHubsMode = () => {
        this.setState({
            memoriesTab: false,
            personalInfoTab: false,
            hubsTab: true,
            buddiesTab: false,
            setSplashTab: false
        })
    }

    changeSetSplashTab = () => {
        this.setState({
            memoriesTab: false,
            personalInfoTab: false,
            hubsTab: false,
            buddiesTab: false,
            setSplashTab: true
        })
    }

    changeBuddiesMode = () => {
        this.setState({
            memoriesTab: false,
            personalInfoTab: false,
            hubsTab: false,
            buddiesTab: true,
            setSplashTab: false
        })
    }

    changePersonalInfoMode = () => {
        this.setState({
            memoriesTab: false,
            personalInfoTab: true,
            hubsTab: false,
            buddiesTab: false,
            setSplashTab: false
        })
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onProfilePictureSubmit = (e, id) => {
        e.preventDefault();
        this.setState({
            profile_picture: e.target.files[0],
            photo: URL.createObjectURL(e.target.files[0]),
        })
        setTimeout(() => {
            this.updateProfilePicture()
        }, 3000);
    };

    updateProfilePicture = () => {
        const profile = this.props.profile[0]
        const new_profile_picture = this.state.profile_picture
        profile.profile_picture = new_profile_picture
        this.props.updateProfile(profile)
    }

    openModal() {
        this.setState({
            modalVisible: true,
        });
    }

    openMemorySettingsModal() {
        this.setState({
            memorySettingsModalVisible: true,
        });
    }

    openHubAccessModal() {
        this.setState({
            hubAccessModal: true,
        });
    }

    closeModal() {
        this.setState({
            modalVisible: false,
            memorySettingsModalVisible: false,
            hubAccessModal: false,
            showstories: false,
            one_person: true,
            memories_user_id: "",
            story_memory_id: "",
        });
        this.props.clearMemoryTags()
    }


    openStoriesModal(user, one_person, story_memory_id) {
        this.setState({
            showstories: true,
            one_person: one_person,
            memories_user_id: user,
            story_memory_id: story_memory_id
        });
    }

    onTouchStart(touchStartEvent) {
        this.setState({
            is_story_paused: true,
            start_swipe_x: touchStartEvent.targetTouches[0].clientX,
            start_swipe_y: touchStartEvent.targetTouches[0].clientY,
        })
    }


    onTouchMove(touchMoveEvent) {
        this.setState({
            swipe_extend_x: touchMoveEvent.targetTouches[0].clientX,
            swipe_extend_y: touchMoveEvent.targetTouches[0].clientY
        })
    }


    onTouchEnd() {
        this.setState({
            is_story_paused: false,
        })
        if (this.state.swipe_extend_x > 100 || this.state.swipe_extend_y > 100) {
            if (this.state.start_swipe_x - this.state.swipe_extend_x < -50) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {
                    // console.log("swiped right")
                    const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index - 1
                    if (new_index > -1) {
                        if (this.props.memories.length > new_index) {
                            this.setState({
                                next_user_memory: false,
                                memories_user_id: this.props.memories[new_index].user,
                                story_index: 0,
                            });

                            setTimeout(() => {
                                this.setState({
                                    next_user_memory: true,
                                });
                            }, 100);
                        }
                    } else {
                        this.closeModal()
                    }
                    this.closeStoryShowMore()
                }

            }


            if (this.state.start_swipe_x - this.state.swipe_extend_x > 100) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {

                    // console.log("swiped left")
                    const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index + 1

                    if (this.props.memories.length > new_index) {
                        this.setState({
                            next_user_memory: false,
                            memories_user_id: this.props.memories[new_index].user,
                            story_index: 0,
                        });

                        setTimeout(() => {
                            this.setState({
                                next_user_memory: true,
                            });
                        }, 100);
                    } else {
                        this.closeModal()
                    }
                }
                this.closeStoryShowMore()
            }

            if (this.state.start_swipe_y - this.state.swipe_extend_y < -100) {
                // console.log("swiped down")
                if (this.state.storyShowMore) {
                    this.props.clearMemoryTags()
                    this.setState({
                        storyShowMore: false,
                        is_story_paused: false,
                    })
                } else {
                    this.closeModal()
                }

            }

            // console.log(this.state.start_swipe_y - this.state.swipe_extend_y)
            if (this.state.start_swipe_y - this.state.swipe_extend_y > 100) {
                this.props.getMemoryTags(this.state.story_memory_id)
                // console.log("swiped up")
                this.setState({
                    storyShowMore: true,
                    is_story_paused: true,
                })
            }
        } else {
            const half_width = window.screen.width / 2
            if (this.state.start_swipe_x < half_width) {
                const memory_stories = this.props.memories
                const new_index = this.state.story_index - 1
                if (new_index > -1) {
                    this.setState({
                        story_memory_id: memory_stories[new_index].id
                    })
                }
            } else {
                const memory_stories = this.props.memories
                const new_index = this.state.story_index + 1
                if (memory_stories.length === new_index) {
                    this.setState({
                        modalVisible: false,
                        memorySettingsModalVisible: false,
                        hubAccessModal: false,
                        showstories: false,
                        one_person: true,
                        memories_user_id: "",
                        story_memory_id: "",
                    });
                }

                if (memory_stories.length !== new_index) {
                    this.setState({
                        story_memory_id: memory_stories[new_index].id
                    })
                }
            }
            this.closeStoryShowMore()
        }

        this.setState({
            start_swipe_x: "",
            start_swipe_y: "",
            swipe_extend_x: "",
            swipe_extend_y: "",
        })
    }

    openStoryShowMore(memory_id) {
        this.props.getMemoryTags(this.state.story_memory_id)
        this.setState({
            storyShowMore: true,
            is_story_paused: true,
            memory_show_more_id: memory_id
        });
    }

    storyStarted = (e, id) => {
        this.setState({
            story_index: e,
            is_story_paused: false,
        })
    }

    userStoriesEnded = e => {
        this.setState({
            story_index: 0,
            is_story_paused: false,
        })
        if (this.state.one_person) {
            this.closeModal()
            this.setState({
                streetViewModal: false
            })
        } else {
            const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
            const new_index = index + 1


            if (this.props.memories.length > new_index) {
                this.setState({
                    next_user_memory: false,
                    memories_user_id: this.props.memories[new_index].user
                });

                setTimeout(() => {
                    this.setState({
                        next_user_memory: true,
                    });
                }, 100);
            } else {
                this.closeModal()
                this.setState({
                    streetViewModal: false
                })
            }
        }
    }

    closeStoryShowMore() {
        this.props.clearMemoryTags()
        this.setState({
            storyShowMore: false,
            is_story_paused: false,
        })
    }

    updateReactionSelection(selected_reaction, memory_id) {
        this.setState({
            reaction_sellected: true,
            reaction: selected_reaction

        })
        const reacted_memory_id = memory_id
        const reaction_status = selected_reaction
        const reaction = { reaction_status, reacted_memory_id }
        this.props.createMemoryReaction(reaction)
        setTimeout(() => {
            this.props.getMyMemories()
        }, 600);

        setTimeout(() => {
            this.setState({
                reaction_sellected: false,
                show_reaction: true,
            })
        }, 1000);
    }

    deleteMikaImpression(memory_id) {
        // const memory_animation_id_list = [...this.state.memory_animation_id]
        // if ((memory_animation_id_list.indexOf(memory_id)) !== -1) {
        //     const new_remove = memory_animation_id_list.filter(f => f !== memory_id)
        //     this.setState({
        //         memory_animation_id: new_remove,
        //     })
        // }
        // setTimeout(() => {


        this.props.deleteReaction(memory_id)
        setTimeout(() => {
            this.props.getMyMemories()
        }, 600);


        // }, 500);
    }


    removeMyMemory(id) {
        this.props.deleteMemory(id)
        this.closeModal()
    }

    render() {
        const dashboard_access = this.props.auth.dashboard_access
        const profile = this.props.profile;
        const profile_picture = this.state.profile_picture
        const top_memories = this.props.memories.filter(memory => memory.was_top_memory)
        const top_memories_number = top_memories.length

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />,
        };

        const memoryTagsSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const screen_width = window.screen.width
        const screen_height = window.screen.height

        let stories = []
        let rendred = false
        const sellected_user_memories = this.props.memories
        let memories_displayed = []

        if (sellected_user_memories.length > 0 && this.state.next_user_memory && profile[0]) {
            const config = {
                delta: 10,
            }
            rendred = true
            sellected_user_memories.map(memory => {
                memories_displayed.push(memory.id)
                let time = moment(memory.timestamp).fromNow()
                const is_story_paused = this.state.is_story_paused
                const story =
                {
                    duration: 10000,
                    memory_user: memory.user,
                    content: ({ action, isPaused }) => {

                        const pauseStory = () => { action("pause") }
                        if (is_story_paused) {
                            action("pause")
                        }
                        return (
                            <>
                                {is_story_paused ?
                                    <span onClick={pauseStory()}></span>
                                    :
                                    null
                                }
                                <div style={{ background: 'pink' }}>
                                    <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                        <div class="prolfie-story-profile-pic-box">
                                            <img
                                                class="prolfie-story-profile-pic"
                                                src={profile[0].profile_picture}
                                                alt=""
                                            />
                                        </div>
                                    </Link>
                                    <div class="prolfie-story-top-text">
                                        {/* <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}> */}
                                        <div class="prolfie-story-top-heading">
                                            <b>{profile[0].username}</b>
                                            {memory.has_tags ?
                                                <span class="prolfie-story-top-heading-tags">
                                                    {memory.tags.length === 2 ?
                                                        <>
                                                            &nbsp;with&nbsp; <b> {memory.tagged_user_username} </b>
                                                        </>
                                                        :
                                                        <>
                                                            {memory.tagged_user_username.length > 14 && memory.user_username.length > 14 ?

                                                                <span
                                                                    class="prolfie-story-top-heading-tags"
                                                                // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                >
                                                                    &nbsp;and&nbsp;
                                                                    <b>{memory.tags.length - 1}&nbsp;others</b>
                                                                </span>

                                                                :
                                                                <span class="prolfie-story-top-heading-tags">
                                                                    &nbsp;with&nbsp;<b>
                                                                        {memory.tagged_user_username}
                                                                    </b>
                                                                    {/* <br /> */}
                                                                    &nbsp;and&nbsp;
                                                                    <span
                                                                        class="prolfie-story-top-heading-tags"
                                                                    // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                    >
                                                                        <b>{memory.tags.length - 2}&nbsp;others</b>
                                                                    </span>
                                                                </span>
                                                            }
                                                        </>
                                                    }

                                                </span>
                                                :
                                                null
                                            }
                                        </div>
                                        {/* </Link> */}
                                        <div class="prolfie-story-top-subheading1">
                                            {memory.hub_name}
                                        </div>
                                        <div class="prolfie-story-top-subheading">
                                            {moment(memory.timestamp).fromNow()}
                                        </div>
                                    </div>


                                    <div class="prolfie-story-photo">
                                        <img class="prolfie-story-photo-size" src={memory.photo} />
                                    </div>

                                    {this.state.reaction_sellected ?
                                        <>
                                            {this.state.reaction === "love" ?
                                                <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                    <img
                                                        class="memory-mika-animation-size"
                                                        // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                        src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </>
                        );
                    },
                    url: memory.photo,
                }
                stories.push(story)
            })
        }

        const index = sellected_user_memories.findIndex(x => x.id === this.state.story_memory_id);


        return (
            <Fragment>


                {this.state.showstories ?
                    <div class="prolfie-story-all-black">

                    </div>
                    :
                    null
                }

                {rendred ?
                    <>
                        {this.state.showstories ?
                            <Modal visible={this.state.showstories} width="100%" height="100%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <>
                                    <div class="prolfie-story-index">
                                        <div onClick={() => this.closeModal()} class="prolfie-story-times-box">
                                            <img
                                                onClick={() => this.closeModal()}
                                                class="prolfie-story-times-img"
                                                src={require("../../../assets/map/svg/story-times.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="prolfie-memories-full-box"
                                        >



                                            <Stories
                                                preventDefault={true}
                                                stories={stories}
                                                defaultInterval={1500}
                                                width={screen_width}
                                                height={screen_height}
                                                currentIndex={index}
                                                // isPaused={True}
                                                onStoryStart={(e, id) => this.storyStarted(e, id)}
                                                onStoryEnd={e => this.storyStarted(e)}
                                                onAllStoriesEnd={e => this.userStoriesEnded(e)}
                                            />

                                        </div>

                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="all-page-touches-invisible-box">

                                        </div>



                                        {this.state.storyShowMore ?
                                            <>
                                                {sellected_user_memories.length > 0 ?
                                                    <div class="prolfie-show-more-modal" >

                                                        {sellected_user_memories[this.state.story_index].has_tags ?
                                                            <div class="map-search-show-more-tags-box">
                                                                <Slider {...memoryTagsSettings} >
                                                                    {this.props.tags.map(tag => (
                                                                        // <div class="map-search-show-more-tag-buddy-box">
                                                                        <Link class="map-search-show-more-tag-buddy-box" to={{ pathname: `/user-profile/${tag.user}/profile`, state: { user: tag.user } }}>
                                                                            <div class="map-search-show-more-tag-profile-box">
                                                                                {tag.profile_picture ?
                                                                                    < img
                                                                                        class="map-search-show-more-tag-profile-img"
                                                                                        src={tag.profile_picture}
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        class="map-search-show-more-tag-profile-img"
                                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div class="map-search-show-more-tag-username">
                                                                                {tag.username}
                                                                            </div>
                                                                        </Link>
                                                                    ))}
                                                                </Slider>
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                        {sellected_user_memories[this.state.story_index].hub_logo ?
                                                            <div class="prolfie-show-more-single-box">
                                                                <Link to={{ pathname: '/hub', state: { hub_id: sellected_user_memories[this.state.story_index].hub_id } }}>
                                                                    <div class="row prolfie-show-more-single-row">
                                                                        <div class="prolfie-show-more-image">
                                                                            < img
                                                                                class="prolfie-show-more-img"
                                                                                src={sellected_user_memories[this.state.story_index].hub_logo}
                                                                            />
                                                                        </div>
                                                                        {sellected_user_memories[this.state.story_index].hub_name.length > 18 ?
                                                                            <>
                                                                                {sellected_user_memories[this.state.story_index].hub_name.slice(0, 17) + "..."}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {sellected_user_memories[this.state.story_index].hub_name}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            :
                                                            <div class="prolfie-show-more-single-box">
                                                                <Link to={{ pathname: '/hub', state: { hub_id: sellected_user_memories[this.state.story_index].hub_id } }}>
                                                                    {sellected_user_memories[this.state.story_index].hub_name.length > 25 ?
                                                                        <>
                                                                            {sellected_user_memories[this.state.story_index].hub_name.slice(0, 24) + "..."}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {sellected_user_memories[this.state.story_index].hub_name}
                                                                        </>
                                                                    }
                                                                </Link>
                                                            </div>
                                                        }


                                                        <Link to={{ pathname: '/memory-impressions', state: { memory: sellected_user_memories[this.state.story_index].id } }}>
                                                            <div class="prolfie-show-more-single-box">
                                                                {sellected_user_memories[this.state.story_index].reactions_number} reactions
                                                            </div>
                                                        </Link>



                                                        {sellected_user_memories[this.state.story_index].user === profile[0].user ?
                                                            <div onClick={() => this.removeMyMemory(sellected_user_memories[this.state.story_index].id)} class="map-search-show-more-single-box">
                                                                Remove Memory
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        <div onClick={() => this.closeStoryShowMore()} class="prolfie-show-more-x-box">
                                                            x
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </>
                                            :
                                            <div onClick={() => this.openStoryShowMore()}
                                                class="prolfie-story-swipe-up">
                                                ⌃<br />
                                                Swipe for More
                                            </div>
                                        }


                                        {sellected_user_memories.length > 0 ?
                                            <div class="prolfie-story-bottom-row">
                                                {sellected_user_memories[this.state.story_index].memory_reaction !== null ?
                                                    <div onClick={() => this.deleteMikaImpression(sellected_user_memories[this.state.story_index].id)} class="prolfie-story-reactions-xo">
                                                        < img
                                                            class="prolfie-story-reactions-img"
                                                            src={require("../../../assets/layout/homepage svg/love-red.svg")}
                                                        />
                                                    </div>
                                                    :
                                                    <div onClick={() => this.updateReactionSelection("love", sellected_user_memories[this.state.story_index].id)} class="prolfie-story-reactions-xo">
                                                        < img
                                                            class="prolfie-story-reactions-img"
                                                            src={require("../../../assets/layout/homepage svg/white-love.svg")}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </>

                            </Modal>

                            :
                            null
                        }
                    </>
                    :
                    <></>
                }

                {this.state.hubAccessModal ?
                    <Modal visible={this.state.hubAccessModal} width="10" height="10" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <HubsAccessModal
                            data={{
                                profile: profile[0],
                                hubs_access: this.props.hubs_access,
                                hub_redirect: this.props.auth.hub_redirect,
                                closeModal: this.closeModal.bind(this),
                            }}
                            switchToHubAccount={this.props.switchToHubAccount}
                        />
                    </Modal>
                    :
                    null
                }

                {/* <div class={this.props.isLoading ? "profile-lottie-appear" : "profile-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <DesktopNavbar data={{ profile: this.props.profile, sellected: "profile", notifications_status: this.props.notifications_status }} />
                {/* <div class={this.props.isLoading ? "profile-section-disappear" : null}> */}
                <div>
                    <div id="page-scroll-up" class="profile-whole-page-layout">
                        <OfflineLabel />
                        <div class="color-5">
                            {profile.map(profile => (
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="container-fluid small-header-5 text-center">
                                            {this.state.showstories ?
                                                null
                                                :
                                                <div class="profile-top-username-navbar">
                                                    <div id="page-scroll-up" class="profiles-mobile-navbar">
                                                        {profile.has_hubs ?
                                                            <div onClick={() => this.openHubAccessModal()} class="row profiles-mobile-navbar-username">
                                                                {profile.username}
                                                                <div class="profiles-mobile-navbar-has-hubs">
                                                                    <img
                                                                        class="profiles-mobile-navbar-has-hubs-icon"
                                                                        src={require("../../../assets/profile/svg/down-arrow.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div class="row profiles-mobile-navbar-username">
                                                                {profile.username}
                                                            </div>
                                                        }
                                                    </div>


                                                    <div>
                                                        <Modal visible={this.state.modalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                                            <NotificationSettingsModal
                                                                data={{
                                                                    profile: this.props.profile,
                                                                    closeModal: this.closeModal.bind(this)
                                                                }}
                                                                updateProfile={this.props.updateProfile}
                                                            />
                                                        </Modal>
                                                        <Modal visible={this.state.memorySettingsModalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                                            <MemorySettingsModal
                                                                data={{
                                                                    profile: this.props.profile,
                                                                    closeModal: this.closeModal.bind(this)
                                                                }}
                                                                updateProfile={this.props.updateProfile}
                                                            />
                                                        </Modal>

                                                        {dashboard_access ?
                                                            <Link class="profile-dashboard-z-ndex" to="/los-pollos-hermanos-the-expirience">
                                                                <img
                                                                    class="profile-dashboard-icons-layout"
                                                                    // onClick={() => this.openModal()}
                                                                    src={require("../../../assets/authentication/svg/Logo.svg")}
                                                                    alt=""
                                                                />
                                                            </Link>
                                                            :
                                                            null
                                                        }

                                                        <img
                                                            class="profile-settings-icons-layout"
                                                            onClick={() => this.openModal()}
                                                            src={require("../../../assets/profile/svg/settings.svg")}
                                                            alt=""
                                                        />
                                                        <img
                                                            class="profile-memory-settings-icons-layout"
                                                            onClick={() => this.openMemorySettingsModal()}
                                                            src={require("../../../assets/profile/svg/memory-settings.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    {/* :
                                                     null
                                                } */}
                                                </div>
                                            }
                                        </div>

                                        <div class="body-right-5 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <div class="row">
                                                {/* <div class="col-xl-2"></div> */}


                                                <div class="profile-top-all-info">
                                                    <div class="profile-top-profile-pic-box">
                                                        {this.state.photo ?
                                                            <img
                                                                class="profile-top-profile-picture-photo"
                                                                src={this.state.photo}
                                                                onClick={() => this.fileInput.click()}
                                                                alt=""
                                                            />
                                                            :
                                                            <span>
                                                                {profile.profile_picture ?
                                                                    <img
                                                                        onClick={() => this.fileInput.click()}
                                                                        class="profile-top-profile-picture-photo"
                                                                        src={profile.profile_picture}
                                                                        alt=""
                                                                    />
                                                                    :
                                                                    <img
                                                                        onClick={() => this.fileInput.click()}
                                                                        class="profile-top-profile-picture-photo"
                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                        alt=""
                                                                    />
                                                                }
                                                            </span>
                                                        }
                                                        <input
                                                            style={{ display: 'none' }}
                                                            accept="image/png, image/jpeg"
                                                            type="file"
                                                            name="photo"
                                                            onChange={this.onProfilePictureSubmit}
                                                            ref={fileInput => this.fileInput = fileInput}
                                                        />
                                                        <div class="profile-top-change-profilepic-icon">
                                                            <img
                                                                class="profile-top-change-icon-size"
                                                                onClick={() => this.fileInput.click()}
                                                                src={require("../../../assets/hub/hub-info/svg/add-photo.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="pofile-top-box-nubinfo">
                                                    <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                                        <div onClick={() => this.changeMemoriesMode()} class="pofile-top-box-info-1">

                                                            {/* <div class="pofile-top-box-info-1"> */}
                                                            <div class="pofile-top-box-number">
                                                                {profile.memories_number}
                                                            </div>


                                                            <div class="pofile-top-box-info-name">
                                                                Memories
                                                            </div>
                                                        </div>
                                                    </ScrollLink>
                                                    <Link to={{ pathname: `/show-profile-buddies/${profile.user}`, state: { profile_username: profile.username } }}>
                                                        <div class="pofile-top-box-info-2">
                                                            <div class="pofile-top-box-number">
                                                                {profile.buddies_number}
                                                            </div>

                                                            <div class="pofile-top-box-info-name">
                                                                Buddies
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>


                                                <div class="profile-top-username-and-fullname">
                                                    {profile.full_name === "null" ?
                                                        <h9>Your Full name will appear here!</h9>
                                                        : profile.full_name === null ?
                                                            <h9>Your Full name will appear here!</h9>
                                                            : profile.full_name === "" ?
                                                                <h9>Your Full name will appear here!</h9>
                                                                : profile.full_name === "undefined" ?
                                                                    <h9>Your Full name will appear here!</h9>
                                                                    :
                                                                    <h7><b>{profile.full_name}</b></h7>
                                                    }
                                                </div>

                                                <div class="profile-top-bio-text">
                                                    {profile.bio === "null" ?
                                                        <h12>Your Bio will appear here!</h12>
                                                        : profile.bio === null ?
                                                            <h12>Your Bio will appear here!</h12>
                                                            : profile.bio === "" ?
                                                                <h12>Your Bio will appear here!</h12>
                                                                : profile.bio === "undefined" ?
                                                                    <h12>Your Bio will appear here!</h12>
                                                                    :
                                                                    <h9>{profile.bio}</h9>
                                                    }
                                                </div>


                                                <div
                                                    onTouchStart
                                                    onClick={this.changePersonalInfoMode}
                                                    class="profile-top-edit-profile"
                                                >
                                                    Edit Profile
                                                </div>

                                                <div class="profile-top-two-btns">
                                                    {this.props.ambassandor.length > 0 ?
                                                        <>
                                                            <SnapMemory data={{ ambassandor: true }} />

                                                            <Link to="/user-splash">


                                                                <div
                                                                    // onClick={() => this.changeSetSplashTab()}
                                                                    onTouchStart
                                                                    class="profile-top-two-btns-right"
                                                                >
                                                                    Splash
                                                                </div>



                                                            </Link>
                                                        </>
                                                        :
                                                        <SnapMemory data={{ ambassandor: false }} />
                                                    }
                                                </div>


                                                {/* {this.props.ambassandor.length > 0 ?
                                                    <Link to="/user-splash">
                                                        <div class="my-profile-set-splash-box">
                                                            <p class="my-profile-set-splash-text">Set Splash</p>
                                                            <img
                                                                class="my-profile-set-splash-img"
                                                                src={require("../../../assets/hub/hub-info/svg/splash-btn.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    :
                                                    null
                                                }

                                                <SnapMemory /> */}


                                                <div class="">




                                                    {/* 
                                                    <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                                        <div class="row profile-top-info-all-boxes">
                                                            <div
                                                                onClick={this.changeMemoriesMode}
                                                                class="profile-top-info-box">
                                                                <div class="profile-top-info-img">
                                                                    <img class="profile-top-info-icon" src={require("../../../assets/profile/svg/Photos icon.svg")} alt="" />
                                                                </div>
                                                                <div class="profile-top-info-title">
                                                                    Memories
                                                                </div>
                                                                <div class="profile-top-info-info-number">
                                                                    {this.props.memories.length}
                                                                </div>
                                                            </div>

                                                            <div
                                                                onClick={this.changeHubsMode}
                                                                class="profile-top-info-box">

                                                                <div class="profile-top-info-img">
                                                                    <img class="profile-top-info-icon" src={require("../../../assets/profile/svg/Hubs Icon.svg")} alt="" />
                                                                </div>
                                                                <div class="profile-top-info-title">
                                                                    Hubs
                                                                </div>
                                                                <div class="profile-top-info-info-number">
                                                                    {this.props.hubs.length}
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={this.changeBuddiesMode}
                                                                class="profile-top-info-box">
                                                                <div class="profile-top-info-img">
                                                                    <img class="profile-top-info-icon" src={require("../../../assets/profile/svg/Buddies Icon.svg")} alt="" />
                                                                </div>
                                                                <div class="profile-top-info-title">
                                                                    Buddies
                                                                </div>

                                                                <div class="profile-top-info-info-number">
                                                                    {this.props.buddies.length}
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={this.changeMemoriesMode}
                                                                class="profile-top-info-box">
                                                                <div class="profile-top-info-img">
                                                                    <img class="profile-top-info-icon" src={require("../../../assets/profile/svg/TM icon.svg")} alt="" />
                                                                </div>
                                                                <div class="profile-top-info-title">
                                                                    Top Memories
                                                                </div>
                                                                <div class="profile-top-info-info-number">
                                                                    {top_memories_number}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ScrollLink> */}






                                                </div>



                                                {/* <div class="col-xl-2"></div> */}
                                            </div>

                                            {/* {this.props.entertainement_jobs.length > 0 ?
                                                <div class="my-profile-working-hubs-sc">
                                                    <div class="my-profile-working-hubs-title">
                                                        The Hubs I work in
                                                    </div>
                                                    <div class="my-profile-working-hubs-slider">
                                                        <Slider {...settings} >
                                                            {this.props.entertainement_jobs.map(job => (

                                                                <div class="my-profile-working-hubs-item" >
                                                                    <div class="my-profile-working-hubs-logo">
                                                                        <Link to={{ pathname: `/user-employee-page`, state: { job_id: job.id, working_now: job.working_now } }}>
                                                                            <img
                                                                                // onClick={this.openModal(gag.name, gag.id, gag.persons)}
                                                                                class="my-profile-working-hubs-img"
                                                                                src={job.hub_logo}
                                                                                alt=""
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                    <Link to={{ pathname: `/user-employee-page`, state: { job_id: job.id } }}>
                                                                        <p class="my-profile-working-hubs-name">{job.hub_name}</p>
                                                                    </Link>
                                                                </div>

                                                            ))}
                                                        </Slider>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            } */}

                                            <span id="field-to-show">


                                                {/* <div class="row mt-4">
                                                    <div class="col-xl-2"></div>
                                                    <div class="col-xl-8">
                                                        <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                                            <div class="row">
                                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 all-btn-5">
                                                                    <div
                                                                        onClick={this.changeMemoriesMode}
                                                                        class={this.state.memoriesTab ?
                                                                            "btn-danger-profile"
                                                                            :
                                                                            "btn-light info-btn-5"
                                                                        }>
                                                                        Memories
                                                                    </div>
                                                                </div>
                                                                <div class="profile-buttons-space"></div>
                                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 all-btn-5">
                                                                    <div
                                                                        onClick={this.changeHubsMode}
                                                                        class={this.state.hubsTab ?
                                                                            "btn-danger-profile"
                                                                            :
                                                                            "btn-light info-btn-5"
                                                                        }>
                                                                        Hubs
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 all-btn-5">
                                                                    <div
                                                                        onClick={this.changeBuddiesMode}
                                                                        class={this.state.buddiesTab ?
                                                                            "btn-danger-profile"
                                                                            :
                                                                            "btn-light info-btn-5"
                                                                        }>
                                                                        Buddies
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 all-btn-5 info-btn-5">
                                                                    <div
                                                                        onClick={this.changePersonalInfoMode}
                                                                        class={this.state.personalInfoTab ?
                                                                            "btn-danger-profile"
                                                                            :
                                                                            "btn-light info-btn-5"
                                                                        }>
                                                                        Info
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ScrollLink>
                                                    </div>
                                                    <div class="col-xl-2"></div>
                                                </div> */}




                                                {this.state.memoriesTab ?
                                                    <>
                                                        <br />
                                                        <div class="my-profile-working-hubs-title">
                                                            My Nightlife
                                                        </div>
                                                        <hr />
                                                        <div>
                                                            <MemoriesTab
                                                                data={{
                                                                    user: profile.user,
                                                                    memories: this.props.memories,
                                                                    openStoriesModal: this.openStoriesModal.bind(this)
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }

                                                {/* {this.state.hubsTab ?
                                                    <HubsTab
                                                        data={{
                                                            hubs: this.props.hubs,
                                                        }}
                                                    />
                                                    :
                                                    null
                                                } */}
                                                {/* {this.state.buddiesTab ?
                                                    <BuddiesTab
                                                        getGagMembers={this.props.getGagMembers}
                                                        deleteGag={this.props.deleteGag}
                                                        data={{
                                                            gags: this.props.gags,
                                                            buddies: this.props.buddies,
                                                            profile_username: profile.username
                                                        }}
                                                    />
                                                    :
                                                    null
                                                } */}
                                                {this.state.personalInfoTab ?
                                                    <InfoTab
                                                        updateProfile={this.props.updateProfile}
                                                        data={{
                                                            profile: this.props.profile,
                                                        }}
                                                    />
                                                    :
                                                    null
                                                }

                                                {this.state.setSplashTab ?
                                                    <SplashTab
                                                        updateProfile={this.props.updateProfile}
                                                        data={{
                                                            profile: this.props.profile,
                                                            ambassandor: this.props.ambassandor,
                                                        }}
                                                    />
                                                    :
                                                    null
                                                }





                                            </span>


                                        </div>
                                        <div class="profile-bottom-empty"></div>
                                    </div>

                                </div>

                            ))}
                        </div>
                    </div>
                </div>
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    {this.state.hubAccessModal || this.state.showstories ?
                        null
                        :
                        <BottomNavbar data={{ sellected: "profile", notifications_status: this.props.notifications_status }} />
                    }
                </ScrollLink>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    profile: state.profile.profile,
    hubs_access: state.profile.hubs_access,
    memories: state.memory.my_memories,
    ambassandor: state.ambassandor.ambassandor,
    hubs: state.profile.hubs,
    buddies: state.buddies.buddies,
    gags: state.buddies.gags,
    notifications_status: state.notification.notifications_status,
    email_notification: state.notification.email_notification,
    entertainement_jobs: state.hubPersonel.entertainement_jobs,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    tags: state.memory.tags,
});

export default connect(mapStateToProps, {
    getMyProfile,
    updateProfile,
    getMyMemories,
    createMemoryReaction,
    deleteReaction,
    getProfileHubList,
    getBuddies,
    getGags,
    getGagMembers,
    getNotificationsStatus,
    getUserNotificationAcceptance,
    changeUserNotificationAcceptance,
    CheckStaffAccess,
    getUserCurrentJobs,
    getUserAmbasaddorsProfile,
    getProfileHubsAccess,
    switchToHubAccount,
    deleteMemory,
    getMemoryTags,
    clearMemoryTags,
})(MyProfile);