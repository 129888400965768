import {
    CLEAR_RESERVATION,
    GET_DATES_APPOINTMENT_HOURS,
    HUB_CREATE_NEW_APPOINTMENT,
    GET_DATES_APPOINTMENTS,
    UPDATE_APPOINTMENT_NOTE,
    CANCEL_APPOINTMENT,
    UPDATE_APPOINTMENT,
    GET_PENDING_APPOINTMENTS,
    ANSWER_APPOINTMENT,
    REQUEST_APPOINTMENT,
    GET_USER_APPOINTMENTS,
    USER_CANCEL_APPOINTMENT
} from '../actions/actionTypes';

const initialState = {
    appointments: [],
    hub_appointments: [],
    appointment_hours: [],
    requested_appointments: [],
    user_appointments: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_RESERVATION:
            return {
                ...state,
                appointments: [],
                hub_appointments: [],
            };

        case GET_DATES_APPOINTMENT_HOURS:
            // localStorage.setItem('pndgrsvts', JSON.stringify(action.payload));
            return {
                ...state,
                appointment_hours: action.payload
            };
        case GET_DATES_APPOINTMENTS:
            return {
                ...state,
                appointments: action.payload
            };

        case HUB_CREATE_NEW_APPOINTMENT:
            return {
                ...state,
                appointments: [...state.appointments, action.payload]
            };
        case UPDATE_APPOINTMENT_NOTE:
            const new_appointment = [action.payload]
            return {
                ...state,
                appointments: state.appointments.map(appointment => new_appointment.find(change => change.id === appointment.id) || appointment)
            };

        case CANCEL_APPOINTMENT:
            return {
                ...state,
                appointments: state.appointments.filter(appointment => appointment.id !== action.payload),
            };


        case GET_PENDING_APPOINTMENTS:
            // localStorage.setItem('pndgrsvts', JSON.stringify(action.payload));
            return {
                ...state,
                requested_appointments: action.payload
            };

        case ANSWER_APPOINTMENT:
            const answered_appointment = [action.payload]
            return {
                ...state,
                requested_appointments: state.requested_appointments.map(appointment => answered_appointment.find(change => change.id === appointment.id) || appointment)
            };

        // case UPDATE_APPOINTMENT:
        //     const updated_appointment = [action.payload]
        //     return {
        //         ...state,
        //         appointments: state.appointments.map(appointment => updated_appointment.find(change => change.id === appointment.id) || appointment)
        //     };

        case GET_USER_APPOINTMENTS:
            return {
                ...state,
                user_appointments: action.payload
            };
        case CANCEL_APPOINTMENT:
            return {
                ...state,
                user_appointments: state.user_appointments.filter(appointment => appointment.id !== action.payload),
            };
        default:
            return state;
    }
}