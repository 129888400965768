import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    getHubWorkingDays,
    hubWorkingDayClose,
    setWorkingDayHours,
    uploadHubLogo,
    uploadHubCoverPhoto,
    uploadHubPromoPhoto,
    setHubName,
    setHubCategory,
    setHubMapIcon,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
    setHubReservations,
    setHubAppointments,
    setHubOnlineShop,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    selectHubReservationHours,
    selectHubAppointmentHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
} from '../../../store/actions/hub'
import { acceptHubsTerms } from '../../../store/actions/auth'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';

import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import moment from "moment";
import hub from '../../../store/reducers/hub';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class SetupPage extends Component {

    state = {
        halfHourBox: false,
        quarterHourBox: false,
        specificHourBox: false,

        place_type: "",

        cover_photo: "",
        photo: null,

        logo_photo: "",
        logo_photo_file: null,

        promo_photo: "",
        promo_photo_file: null,

        name: '',
        opening_hour: "",
        closing_hour: "",
        changing_day_id: "",
        changing_opening: "",
        day_close_change: "",
        shop_url: "",

        logoNameSection: true,
        workingHoursSection: false,
        placeProvideSection: false,

        acceptReservationSection: false,
        acceptingReservations: false,
        throughReservations: "",
        reservation_app_url: "",



        reservationHoursMode: false,
        appointmentHoursMode: false,

        onlineShopSection: false,
        haveOnlineShop: false,


        acceptAppointmentSection: false,
        acceptingAppointments: false,
        throughAppointments: "",
        appointment_app_url: "",

        categorySection: false,
        mapSection: false,
        promoSection: false,
        socialSection: false,
        menuSection: false,


        wifiAnswer: false,
        telephoneAnswer: false,
        websiteAnswer: false,
        facebookAnswer: false,
        instagramAnswer: false,

        wifiMiniSection: true,
        wifi_code: "",
        telephoneMiniSection: true,
        telephone: "",
        has_telephone: false,
        websiteMiniSection: true,
        website: "",
        has_website: false,
        facebookMiniSection: true,
        facebook: "",
        has_facebook: false,
        instagramMiniSection: true,
        instagram: "",
        has_instagram: false,
        menu: null,

        finishedCategoryName: false,
        category: "",
        map_icon: "",
        catalogView: true,
        catalog_view_name: "",
        catalogNameView: false,
        catalog_name: "",
        catalog_id: "",
        viewMenuPages: false,
        uploading_pages: false,
        done_setup: false,
        redirect: false,
        specificHourMode: false,
        specificAppointmentHourMode: false,
        hour: "18",
        minutes: "00",
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

    answerAppointmentAcceptanceHubst3r() {
        this.setState({
            appointmentHoursMode: true
        })
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const has_online_shop = false
        const reservation_acceptance = false
        const hubst3r_appointments = true
        const telephone_appointments = false
        const another_app_appointments = false
        const appointment_app_url = ""
        const hub = { id, appointment_acceptance, telephone_appointments, another_app_appointments, reservation_acceptance, has_online_shop, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
    }

    answerAppointmentAcceptanceTelephone() {
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const hubst3r_appointments = false
        const has_online_shop = false
        const reservation_acceptance = false
        const telephone_appointments = true
        const another_app_appointments = false
        const appointment_app_url = this.state.appointment_app_url
        const hub = { id, appointment_acceptance, telephone_appointments, another_app_appointments, reservation_acceptance, has_online_shop, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
        setTimeout(() => {
            this.setState({
                redirect: true
            })
        }, 500);
    }


    finishedOtherAppointment() {
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const hubst3r_appointments = false
        const has_online_shop = false
        const reservation_acceptance = false
        const telephone_appointments = false
        const another_app_appointments = true
        const appointment_app_url = this.state.appointment_app_url
        const hub = { id, appointment_acceptance, telephone_appointments, another_app_appointments, reservation_acceptance, has_online_shop, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
        setTimeout(() => {
            this.setState({
                redirect: true
            })
        }, 500);
    }

    answerAcceptingAppointments() {
        this.setState({
            acceptingAppointments: true,
        })
    }

    answerThroughAppointment(answer) {
        this.setState({
            throughAppointments: answer,
        })
    }

    answerAppointmentAcceptance() {
        const id = this.props.hub[0].id
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_appointments = false
        const reservation_acceptance = false
        const telephone_appointments = false
        const another_app_appointments = false
        const appointment_app_url = this.state.appointment_app_url
        const hub = { id, appointment_acceptance, telephone_appointments, another_app_appointments, appointment_acceptance, reservation_acceptance, has_online_shop, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
        this.setState({
            redirect: true,
        })
    }

    answerAppointmentHours(choise) {
        const id = this.props.hub[0].id
        const appointment_hours_choise = choise
        const hub = { id, appointment_hours_choise }
        this.props.selectHubAppointmentHours(hub)
        if (choise === "specific") {
            this.setState({
                specificAppointmentHourMode: true
            })
        } else {
            this.setState({
                redirect: true,
            })
        }
    }

    addNewSpecificAppointmentHour() {
        let hour = this.state.hour
        let minutes = this.state.minutes
        const time = hour + ":" + minutes
        if (hour > 23 && hour > -1) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Hour" });
        } else if (minutes > 59) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Minutes" });
        } else {
            const specific_time = { time }
            this.props.createNewSpecificAppointmentHour(specific_time)
        }
    }


    removeSpecificAppointmentHour(id, time) {
        const specific_time = { id, time }
        this.props.removeSpecificAppointmentHour(specific_time)
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/information' }} />
        }

        return (
            <Fragment>

                {this.props.hub.map(hub => (
                    <>
                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />

                                <>

                                    <div class="setup-page-welcome-title">
                                        <Link to="/hub-homepage" class="setup-page-back-box">
                                            <div class="setup-page-back-icon-box">
                                                <img
                                                    class="setup-page-back-icon"
                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        Set Appointments
                                    </div>
                                    <>
                                        {this.state.acceptingAppointments ?
                                            <>
                                                {this.state.throughAppointments === "other" ?
                                                    <>
                                                        <div class="setup-page-info-text">
                                                            Type the url to the app you want people to request Appointment through?
                                                        </div>
                                                        <div class="setup-page-info-input-box">
                                                            <input
                                                                class='setup-page-name-input-field'
                                                                name="appointment_app_url"
                                                                onChange={this.onChange}
                                                                placeholder="Appointments APP URL..."
                                                                value={this.state.appointment_app_url === "" ? hub.appointment_app_url : this.state.appointment_app_url}
                                                            />
                                                        </div>
                                                        <div onClick={() => this.finishedOtherAppointment(false)} class="setup-page-info-done">
                                                            Done
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.appointmentHoursMode ?
                                                            <>
                                                                <div class="setup-page-logo-text">
                                                                    Set the available Hours for Appointments
                                                                </div>
                                                                {this.state.specificAppointmentHourMode ?
                                                                    <>

                                                                        <div class="row setup-page-res-hours-row">
                                                                            <div class="row setup-page-res-hours-margin">
                                                                                <input
                                                                                    class='setup-page-res-hours-hour'
                                                                                    name="hour"
                                                                                    onChange={this.onChange}
                                                                                    value={this.state.hour}
                                                                                    type="number"
                                                                                    min="00"
                                                                                    max="23"
                                                                                />
                                                                                <div class="setup-page-res-hours-middle">
                                                                                    :
                                                                                </div>
                                                                                <input
                                                                                    class='setup-page-res-hours-hour'
                                                                                    name="minutes"
                                                                                    onChange={this.onChange}
                                                                                    value={this.state.minutes}
                                                                                    type="number"
                                                                                    min="00"
                                                                                    max="60"
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                onClick={() => this.addNewSpecificAppointmentHour()}
                                                                                class="setup-page-res-hours-add">
                                                                                Add
                                                                            </div>
                                                                        </div>


                                                                        <div class="row setup-page-created-hours-box">
                                                                            {this.props.appointment_hours.map(hour => (
                                                                                <div class="setup-page-created-hour">
                                                                                    {hour.time}
                                                                                    <div
                                                                                        onClick={() => this.removeSpecificAppointmentHour(hour.id, hour.time)}
                                                                                        class="setup-page-created-hour-remove">
                                                                                        x
                                                                                    </div>
                                                                                </div>
                                                                            ))}


                                                                        </div>



                                                                        <Link to="/information" class="setup-page-new-hour-done">
                                                                            Done
                                                                        </Link>
                                                                    </>
                                                                    :
                                                                    <div class="setup-page-reservation-hour-all-choises">

                                                                        <div onClick={() => this.answerAppointmentHours("quarter")} class="setup-page-reservation-hour-choise">
                                                                            Every 15 minutes  <br />(i.e. 19:00, 19:15, 19:30, 19:45)
                                                                        </div>

                                                                        <div onClick={() => this.answerAppointmentHours("half")} class="setup-page-reservation-hour-choise">
                                                                            Every 30 minutes <br />(i.e. 19:30, 20:00, 20:30, 21:00)
                                                                        </div>

                                                                        <div onClick={() => this.answerAppointmentHours("hourly")} class="setup-page-reservation-hour-choise">
                                                                            Every 1 hour <br />(i.e. 18:00, 19:00, 20:00, 21:00)
                                                                        </div>

                                                                        <div onClick={() => this.answerAppointmentHours("specific")} class="setup-page-reservation-hour-choise">
                                                                            Specific Hours <br />(i.e. at 15:00, at 19:00, at 21:00)
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </>
                                                            :

                                                            <>
                                                                <div class="setup-page-reservation-question-text">
                                                                    Where do you like people to be able to Request an Appointment?
                                                                </div>

                                                                <div class="setup-page-reservation-question-box">
                                                                    <div onClick={() => this.answerThroughAppointment("other")} class="setup-page-reservation-through-other">
                                                                        Through another app
                                                                    </div>
                                                                    <div onClick={() => this.answerAppointmentAcceptanceHubst3r()} class="setup-page-reservation-through-hubst3r">
                                                                        Through Hubst3r
                                                                    </div>
                                                                    <div onClick={() => this.answerAppointmentAcceptanceTelephone()} class="setup-page-reservation-through-telephone">
                                                                        Only Through Telephone
                                                                    </div>
                                                                </div>
                                                            </>

                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <div class="setup-page-reservation-question-text">
                                                    Do you accept Appointments?
                                                </div>

                                                <div class="setup-page-reservation-question-box">
                                                    <div onClick={() => this.answerAppointmentAcceptance(false)} class="setup-page-reservation-question-no">
                                                        No
                                                    </div>
                                                    <div onClick={() => this.answerAcceptingAppointments(true)} class="setup-page-reservation-question-yes">
                                                        Yes
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>



                                </>
                            </div>
                        </div>

                        <Link to="/information"
                            //  onClick={() => this.acceptHubsTerms()} 
                            class="setup-page-next-btn">
                            Done
                        </Link>


                    </>
                ))}

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    appointment_hours: state.hub.appointment_hours,

});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    setHubAppointments,
    selectHubAppointmentHours,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
})(SetupPage);