import React from 'react';
// import './styles.css';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"

import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import Hubst3rLogo from '../../../assets/layout/lotties/Hubst3rLogo.png';
// import '../../../assets/layout/lotties/LottieStyle.css';


export default function Hubst3rLottie() {
    const defaultOptions = {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <div class="hubster-lottie-position">
                <Lottie
                    // class="lottie"
                    // style={{ marginTop: "30%", width: "10%" }}
                    options={defaultOptions}
                // height={600}
                // width={600}
                />
            </div>
            <div class="hubster-name-logo-position">
                <img
                    class="hubster-name-logo-size"
                    src={Hubst3rLogo}
                    alt=""
                />
            </div>
        </>
    );
}