import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    setMenuPageDescription,
    setMenuPagePrice,
    changeHubCatalogIndex,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';
import moment from "moment";
import Modal from 'react-awesome-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HTMLFlipBook from 'react-pageflip';


export class SetCatalog extends Component {

    state = {
        menu: null,
        catalogView: true,
        catalog_view_name: "",
        catalogNameView: false,
        catalog_name: "",
        catalog_id: "",
        viewMenuPages: false,
        uploading_pages: false,
        redirect: false,
        page_show_more: false,
        page: 0,
        page_changing: false,
        editing_description: false,
        page_description: "-",
        editing_price: false,
        page_price: "-",
        cerrent_catalog: "",
        catalogIndexModal: false,
        index_catalog_id: "",
        index_catalog_index: "",
        index_catalog_name: "",
    };


    componentWillMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
        this.props.getHubCatalogs(hub_id)
    }

    backToCatalogs() {
        this.setState({
            menu: null,
            catalogView: true,
            catalog_view_name: "",
            catalogNameView: false,
            catalog_name: "",
            catalog_id: "",
            viewMenuPages: false,
            uploading_pages: false,
            redirect: false,
            page_show_more: false,
            page: 0,
            page_changing: false,
            editing_description: false,
            page_description: "-",
            editing_price: false,
            page_price: "-",
            cerrent_catalog: "",
        })
    }


    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }



    changeCatalogView() {
        this.setState({
            catalogView: !this.state.catalogView,
            catalogNameView: false,
        })
    }

    setCatalogName() {
        this.setState({
            catalogNameView: !this.state.catalogNameView,
            catalog_name: "",
        })
    }

    createNewCatalog() {
        const hub = this.props.hub[0].id
        const name = this.state.catalog_name
        if (name === "") {
            this.props.createAlert({ noInvitationsSent: "You haven't given a name to the catalog!" });
        } else {
            const catalog = { hub, name }
            this.props.createHubCataloge(catalog)
            this.setState({
                catalogView: !this.state.catalogView,
            })
        }
    }


    onMenuPagesUpload = (e) => {
        this.setState({
            uploading_pages: true
        })
        const hub = this.props.hub[0].id
        let photo = ""
        let page = 0
        let menu_page = {}
        let container_id = ""
        if (this.state.catalog_id !== "") {
            container_id = this.state.catalog_id
        } else {
            container_id = this.props.new_catalog.id
        }
        for (let i = 0; i < e.target.files.length; i++) {
            photo = e.target.files[i]
            page = i + 1
            menu_page = { hub, photo, page, container_id }
            this.props.createNewMenuPage(menu_page)
        }
        setTimeout(() => {
            const hub_id = this.props.hub[0].id
            this.props.getHubCatalogs(hub_id)
        }, 5000);
        setTimeout(() => {
            this.setState({
                uploading_pages: false,
                catalogView: true,
                catalogNameView: false,
                viewMenuPages: false,
            })
        }, 5000);
    }



    openCatalogPagesView(catalog_id, catalog_name) {
        if (catalog_id !== "") {
            this.props.getHubMenuPages(catalog_id)
            this.setState({
                cerrent_catalog: catalog_id
            })
        } else {

        }
        this.setState({
            viewMenuPages: true,
            catalogView: !this.state.catalogView,
            catalog_view_name: catalog_name
        })
    }

    closeCatalogPagesView() {
        this.setState({
            catalogView: true,
            viewMenuPages: false,
        })
    }

    removeCatalog(id) {
        const hub = this.props.hub[0].id
        const is_active = false
        const catalog = { id, hub, is_active }
        this.props.removeHubCataloge(catalog)
    }

    onPage = (e) => {
        this.setState({
            page_changing: false,
            page: e.data,
            page_show_more: false,
        });
    };

    onChangeState = (e) => {
        this.setState({
            page_changing: true,
        });
        setTimeout(() => {
            this.setState({
                page_changing: false,
            });
        }, 800);
    };

    closeModal() {
        this.setState({
            editing_description: false,
            page_description: "-",
            editing_price: false,
            page_price: "-",
            catalogIndexModal: false,
            index_catalog_id: "",
            index_catalog_index: "",
            index_catalog_name: "",
        });
        setTimeout(() => {
            this.flipBook.pageFlip().turnToPage(this.state.page);
        }, 500);
    }

    openCatalogIndexModal(catalog_id, catalog_index, catalog_name) {
        this.setState({
            index_catalog_id: catalog_id,
            index_catalog_index: catalog_index,
            index_catalog_name: catalog_name,
            catalogIndexModal: true
        });
    }

    closeCatalogIndexModal() {
        this.setState({
            catalogIndexModal: false,
            index_catalog_id: "",
            index_catalog_index: "",
            index_catalog_name: "",
        });
    }

    openShowMoreDescription() {
        this.setState({
            page_show_more: !this.state.page_show_more,
        })
    }

    startDescriptionEditing() {
        this.setState({
            editing_description: true,
        })
    }

    startPriceEditing() {
        this.setState({
            editing_price: true,
        })
    }

    submitNewDescription(id) {
        const description = this.state.page_description
        if (description !== "-") {
            let has_description = true
            if (description.length === 0) {
                has_description = false
            }
            const page = { id, has_description, description }
            this.props.setMenuPageDescription(page)
        }
        setTimeout(() => {
            this.props.getHubMenuPages(this.state.cerrent_catalog)
        }, 2000);
        this.closeModal()
    }


    submitNewPrice(id) {
        const price = this.state.page_price
        if (price !== "-") {
            let has_price = true
            if (price.length === 0) {
                has_price = false
            }
            const page = { id, has_price, price }
            this.props.setMenuPagePrice(page)
        }
        setTimeout(() => {
            this.props.getHubMenuPages(this.state.cerrent_catalog)
        }, 2000);
        this.closeModal()

    }

    changeCatalogIndex(catalog_id, new_index) {
        this.setState({
            index_catalog_index: new_index
        })
        const id = catalog_id
        const index = new_index
        const catalog = { id, index }
        this.props.changeHubCatalogIndex(catalog)

        setTimeout(() => {
            this.closeCatalogIndexModal()
        }, 500);


        setTimeout(() => {
            const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
            this.props.getHubCatalogs(hub_id)
        }, 1000);

    }

    render() {

        const defaultOptions = {
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        const height = window.innerHeight
        const width = window.innerWidth
        const screen_width = window.screen.width
        const menu_file = this.state.menu
        const hub = this.props.hub

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const tagSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const catalogs = this.props.catalogs

        let current_page = {}
        if (this.props.menu_pages.length > 0) {
            current_page = this.props.menu_pages[this.state.page]
        }


        return (
            <Fragment>
                {this.props.hub.map(hub => (
                    <>


                        {this.state.catalogIndexModal ?
                            <Modal visible={this.state.catalogIndexModal} width="100%" height="30%" effect="fadeInUp" onClickAway={() => this.closeCatalogIndexModal()} >
                                <div class="setup-page-index-modal-box">
                                    <div class="setup-page-index-modal-title">
                                        In which position <b>{this.state.index_catalog_name}</b> should appear?
                                    </div>

                                    <div class="setup-page-index-modal-slider">
                                        <Slider {...tagSettings} >
                                            {catalogs.map(catalog => (
                                                <>
                                                    <div class="setup-page-index-modal-catalog-index">
                                                        <div
                                                            onClick={() => this.changeCatalogIndex(this.state.index_catalog_id, catalog.index)}
                                                            class={catalog.index === this.state.index_catalog_index ? "setup-page-index-modal-catalog-index-btn-slctd" : "setup-page-index-modal-catalog-index-btn"}
                                                        >
                                                            {catalog.index}
                                                            {catalog.index === 1 ?
                                                                <>st</>
                                                                : catalog.index === 2 ?
                                                                    <>nd</>
                                                                    : catalog.index === 3 ?
                                                                        <>rd</>
                                                                        :
                                                                        <>th</>
                                                            }
                                                        </div>
                                                    </div>
                                                    {catalog.index === this.state.index_catalog_index ?
                                                        <div class="setup-page-index-modal-catalog-index-now">
                                                            Now
                                                        </div>
                                                        :
                                                        null
                                                    }


                                                </>
                                            ))}

                                        </Slider>
                                    </div>

                                </div>
                            </Modal>
                            :
                            null
                        }



                        {this.state.editing_description ?
                            <Modal visible={this.state.editing_description} width="100%" height="50%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <div class="setup-page-menu-description-modal-box">
                                    <div onClick={() => this.closeModal()} class="setup-page-menu-description-modaltimes-box">
                                        <img
                                            class="setup-page-menu-description-modaltimes-img"
                                            src={require("../../../assets/layout/homepage svg/times.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <>
                                        <div class="setup-page-menu-description-modal-titel">
                                            Edit the Description of this Page
                                        </div>

                                        <textarea
                                            maxlength="500"
                                            className="setup-page-menu-description-modal-name-input"
                                            type="text"
                                            name="page_description"
                                            onChange={this.onChange}
                                            value={this.state.page_description === "-" ? current_page.description : this.state.page_description}
                                            placeholder="Description..."
                                            rows="8"
                                        />

                                        <div onClick={() => this.submitNewDescription(current_page.id)} class="setup-page-menu-description-modal-claim-btn">
                                            Done
                                        </div>

                                    </>
                                </div>
                            </Modal>
                            :
                            null
                        }

                        {this.state.editing_price ?
                            <Modal visible={this.state.editing_price} width="100%" height="30%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <div class="setup-page-menu-description-modal-box">
                                    <div onClick={() => this.closeModal()} class="setup-page-menu-description-modaltimes-box">
                                        <img
                                            class="setup-page-menu-description-modaltimes-img"
                                            src={require("../../../assets/layout/homepage svg/times.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <>
                                        <div class="setup-page-menu-description-modal-titel">
                                            Edit the Price of this Page
                                        </div>
                                        <div class="setup-page-menu-price-input-text">
                                            Please include the currency<br />($, €, £, ¥, e.t.c)
                                        </div>
                                        <input
                                            maxlength="50"
                                            className="setup-page-menu-prcie-modal-input"
                                            type="text"
                                            name="page_price"
                                            onChange={this.onChange}
                                            value={this.state.page_price === "-" ? current_page.price : this.state.page_price}
                                            placeholder="Price..."
                                        />
                                        <div onClick={() => this.submitNewPrice(current_page.id)} class="setup-page-menu-description-modal-claim-btn">
                                            Done
                                        </div>
                                    </>
                                </div>
                            </Modal>
                            :
                            null
                        }


                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />

                                <>
                                    {this.state.viewMenuPages ?
                                        <div
                                            onClick={() => this.backToCatalogs()}
                                            class="row menu-top-navbar-back"
                                        >
                                            <div class="menu-top-navbar-back-box">
                                                <img
                                                    class="menu-top-navbar-back-icon"
                                                    src={require("../../../assets/menu/svg/back.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            Back
                                        </div>
                                        :
                                        <div class="setup-page-welcome-title">
                                            <Link to="/information" class="setup-page-back-box">
                                                <div class="setup-page-back-icon-box">
                                                    <img
                                                        class="setup-page-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </Link>
                                            Setup your Catalog
                                        </div>
                                    }
                                    {this.state.editing_price || this.state.editing_description ?
                                        null
                                        :
                                        <>
                                            {this.state.uploading_pages ?
                                                <>
                                                    <div class="setup-page-lottie-position">
                                                        <Lottie
                                                            // class="lottie"
                                                            // style={{ marginTop: "30%", width: "10%" }}
                                                            options={defaultOptions}
                                                        // height={600}
                                                        // width={600}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {this.state.catalogView ?
                                                        <>
                                                            {!this.state.catalogNameView ?
                                                                <>
                                                                    <div
                                                                        class="setup-page-new-catalog-btn"
                                                                        onClick={() => this.setCatalogName()}>
                                                                        Create New Catalog
                                                                    </div>
                                                                    <div class="setup-page-all-catalogs">
                                                                        <Slider {...tagSettings} >
                                                                            {catalogs.map(catalog => (
                                                                                <>
                                                                                    {catalog.first_page ?
                                                                                        <>
                                                                                            <div class="setup-page-catalog-index">
                                                                                                <div onClick={() => this.openCatalogIndexModal(catalog.id, catalog.index, catalog.name)} class="setup-page-catalog-index-btn">
                                                                                                    {catalog.index}
                                                                                                    {catalog.index === 1 ?
                                                                                                        <>st</>
                                                                                                        : catalog.index === 2 ?
                                                                                                            <>nd</>
                                                                                                            : catalog.index === 3 ?
                                                                                                                <>rd</>
                                                                                                                :
                                                                                                                <>th</>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="setup-page-catalog-select-budies-frame">

                                                                                                <div
                                                                                                    onClick={() => this.openCatalogPagesView(catalog.id, catalog.name)}
                                                                                                    class="setup-page-catalog-buddies-card">
                                                                                                    <div
                                                                                                        style={{ backgroundImage: 'url(' + catalog.first_page + ')' }}
                                                                                                        class='setup-page-catalog-buddies-card-square'
                                                                                                    >

                                                                                                        <div class="setup-page-catalog-buddies-username">
                                                                                                            {catalog.name}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                onClick={() => this.removeCatalog(catalog.id)}
                                                                                                class="setup-page-catalog-remove">
                                                                                                Remove
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </>
                                                                            ))}

                                                                        </Slider>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div class="setup-page-info-text">
                                                                        Type a name for the catalog.
                                                                    </div>

                                                                    <div class="setup-page-info-input-box">
                                                                        <input
                                                                            class='setup-page-info-input-field'
                                                                            name="catalog_name"
                                                                            onChange={this.onChange}
                                                                            placeholder="Catalog name..."
                                                                            value={this.state.catalog_name}
                                                                        />
                                                                    </div>
                                                                    <div onClick={() => this.createNewCatalog()} class="setup-page-info-done">
                                                                        Done
                                                                    </div>
                                                                    <div onClick={() => this.setCatalogName()} class="setup-page-back-btn-big">
                                                                        Back
                                                                    </div>
                                                                </>
                                                            }

                                                        </>
                                                        :
                                                        <>

                                                            {this.state.viewMenuPages ?
                                                                <div class="whole-menu-page-background-clr">
                                                                    <>

                                                                        <div class="setup-page-menu-description-modal">
                                                                            <HTMLFlipBook

                                                                                size="fixed"
                                                                                width={width}
                                                                                height={0.95 * height}
                                                                                maxShadowOpacity={0.5}
                                                                                showCover={true}
                                                                                mobileScrollSupport={true}
                                                                                onFlip={this.onPage}
                                                                                onChangeOrientation={this.onChangeOrientation}
                                                                                onChangeState={this.onChangeState}
                                                                                className="demo-book"
                                                                                ref={(el) => (this.flipBook = el)}
                                                                            >
                                                                                {this.props.menu_pages.map(page => (
                                                                                    < div class="setup-page-menu-size" >
                                                                                        <img
                                                                                            class="menu-page-img-size"
                                                                                            src={page.photo}
                                                                                            alt=""
                                                                                        />


                                                                                    </div>
                                                                                ))}
                                                                            </HTMLFlipBook>
                                                                        </div >

                                                                        {!this.state.page_changing ?
                                                                            <>
                                                                                {this.state.page_show_more && this.state.page + 1 === current_page.page ?
                                                                                    <>

                                                                                        <div class="setup-page-menu-show-more-box">
                                                                                            <div class="setup-page-menu-show-more-description">
                                                                                                <div class="setup-page-menu-show-more-down-arrow-box">
                                                                                                    <img
                                                                                                        onClick={() => this.openShowMoreDescription()}
                                                                                                        class="setup-page-menu-show-more-down-arrow"
                                                                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                {current_page.description}

                                                                                                <div onClick={() => this.startDescriptionEditing()} class="setup-page-menu-show-more-description-edit">
                                                                                                    <b>edit</b>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="setup-page-menu-show-more-price">
                                                                                                {current_page.price}
                                                                                                <div onClick={() => this.startPriceEditing()} class="setup-page-menu-show-more-price-edit">
                                                                                                    <b>edit</b>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <div class="row setup-page-menu-bottom-place-row">
                                                                                        {current_page.has_description ?
                                                                                            <div onClick={() => this.openShowMoreDescription()} class="setup-page-menu-bottom-page-description">
                                                                                                {current_page.description.length > 51 ?
                                                                                                    <>
                                                                                                        {current_page.description.slice(0, 50)}&nbsp;&nbsp;
                                                                                                        <span class="setup-page-menu-show-blue">see more...</span>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {current_page.description}
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <div onClick={() => this.startDescriptionEditing()} class="setup-page-menu-bottom-page-add-description">
                                                                                                + Add Page's Description
                                                                                            </div>
                                                                                        }

                                                                                        {current_page.has_price ?
                                                                                            <div onClick={() => this.openShowMoreDescription()} class="setup-page-menu-bottom-page-price">
                                                                                                Price:
                                                                                                <br />
                                                                                                {current_page.price}

                                                                                            </div>
                                                                                            :
                                                                                            <div onClick={() => this.startPriceEditing()} class="setup-page-menu-bottom-page-add-price">
                                                                                                + Add Price
                                                                                            </div>
                                                                                        }

                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                                        }


                                                                    </>
                                                                </div>
                                                                :
                                                                <>

                                                                    <div class="setup-page-logo-text">
                                                                        Upload the Pages of your Catalog
                                                                    </div>

                                                                    <div
                                                                        class="setup-page-menu-input-box"
                                                                        onClick={() => this.menuInput.click()}
                                                                    >
                                                                        <img
                                                                            class="setup-page-menu-input-img"
                                                                            src={require("../../../assets/hub/hub-homepage/svg/menu.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        class="setup-page-menu-input-btn"
                                                                        onClick={() => this.menuInput.click()}
                                                                    >
                                                                        Upload Pages
                                                                    </div>

                                                                    <input
                                                                        class="setup-page-pdf-input"
                                                                        onChange={this.onMenuPagesUpload}
                                                                        id="menu-pages-to-upload"
                                                                        name="menu_photos"
                                                                        accept="image/png, image/jpeg"
                                                                        type="file"
                                                                        multiple
                                                                        style={{ display: 'none' }}
                                                                        ref={menuInput => this.menuInput = menuInput}
                                                                    />
                                                                    <div onClick={() => this.changeCatalogView()} class="setup-page-back-btn-big">
                                                                        Back
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>

                            </div>
                        </div>

                        {/* <Link to="/information"
                            //  onClick={() => this.acceptHubsTerms()} 
                            class="setup-page-next-btn">
                            Done
                        </Link> */}
                    </>
                ))}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    catalogs: state.hub.catalogs,
    new_catalog: state.hub.new_catalog,
    menu_pages: state.hub.menu_pages,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    setMenuPageDescription,
    setMenuPagePrice,
    changeHubCatalogIndex,
})(SetCatalog);