import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import "../../../assets/buddies/buddy-relation-modal.css"


export class BuddyRelationModal extends Component {

    state = {
    }

    closeModal = () => {
        this.props.data.closeModal()
    }

    // removeBuddy() {
    //     console.log("hiiiiiii")

    // }

    render() {
        const buddy = this.props.data.buddy
        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="buddy-relation-box">
                            <img
                                onClick={() => this.closeModal()}
                                class="buddy-relation-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <div class="buddy-relation-buddy-name">
                                {buddy.username}
                            </div>
                            <div class="buddy-relation-title-hr">
                                <hr />
                            </div>

                            <div class="buddy-relation-all-choises">
                                <hr />
                                <div
                                    onClick={this.props.data.removeBuddy(this.props.data.buddy.user)}
                                    class="buddy-relation-choise"
                                >
                                    <div class="buddy-relation-choise-text">
                                        Remove from Buddies
                                    </div>

                                    <div class="buddy-relation-remove-icon-box">
                                        <img
                                            class="buddy-relation-remove-icon"
                                            src={require("../../../assets/profile/svg/remove-buddy.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>

                                <hr />
                            </div>



                        </div>
                    </div>

                </div>
            </Fragment >
        )
    }
}


export default (BuddyRelationModal);