import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class ShareReservation extends Component {


    render() {
        const { notification } = this.props.data
        return (
            <Fragment>
                <Link to="/meetings">
                    <div class="row single-item-9">
                        <div class="notification-related-photo-position">
                            {notification.related_picture ?
                                <img class="related-picture-layout" src={notification.related_picture} alt="" />
                                :
                                <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                            }
                        </div>
                        {/* <span class="notification-section-icon-layout">
                            <img class="notification-section-icon" src={require("../../../assets/notifications/svg/Meetings-b.svg")} alt="" />
                        </span> */}
                        <div class="notification-information-section">
                            <span class="row notification-meeting-info">
                                <p class="text-blue-9">{notification.invitation_date}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {notification.invitation_day}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {notification.invitation_time}</p>
                            </span>
                            <div class="notification-text-position">
                                <p class="text-blue-9 notification-text-layout">
                                    <strong>{notification.related_name}</strong> Shared a Reservation with you! Check it out!
                                </p>
                            </div>
                        </div>
                        <div class="notification-related-icon-layout text-center">
                            <img class="notification-related-icon-size" src={notification.hub_invitation_logo} alt="" />
                            <p class="text-blue-9 notification-text-layout">{notification.hub_invitation_name}</p>
                        </div>
                    </div>
                </Link>
            </Fragment >
        )
    }
}


export default (ShareReservation);