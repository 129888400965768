import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import { getMyProfile } from '../../../store/actions/profile';
import { getBuddies } from '../../../store/actions/buddies'
import { getMyConersations } from '../../../store/actions/chat'
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import "../../../assets/chat/chat-list.css"
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export class ChatList extends Component {

    componentWillMount() {
        const id = JSON.parse(localStorage.getItem('ur')).id
        this.props.getBuddies(id);
        this.props.getMyProfile();
        this.props.getMyConersations();
        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.props.getMyConersations(), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleBack = () => {
        this.props.history.goBack()
    }


    render() {

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
        };

        let conversations = this.props.conversations

        if (this.props.conversations.length === 0) {
            if (JSON.parse(localStorage.getItem('cnvrstns'))) {
                conversations = JSON.parse(localStorage.getItem('cnvrstns'))
            }
        }

        return (
            <Fragment>
                <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />

                <div>
                    <div class="chat-list-total-page-layout">
                        <OfflineLabel />
                        <div class="chat-list-mobile-navbar">
                            <Link to="/">
                                <div class="back-button-chat-list-box">
                                    <img
                                        class="chat-list-back-button"

                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                        alt=""
                                    />
                                </div>
                            </Link>
                            {this.props.profile.map(profile => (
                                <>
                                    <div class="chat-list-top-profile-box">
                                        <Link to="/my-profile">
                                            <div class="chat-list-top-profile-pic-box">
                                                <img
                                                    class="chat-list-top-profile-pic"
                                                    src={profile.profile_picture}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        <Link to="/my-profile">
                                            <div class="chat-list-top-profile-username-box">
                                                {profile.username}
                                            </div>
                                        </Link>
                                    </div>
                                    {/* {this.props.buddies.length !== 0 ?
                                        <Link to={{ pathname: "/chat-room", state: { selected_user: "", selected_user_profile_pic: "", selected_user_username: "" } }}>
                                            <div class="chat-list-top-microphone-box">
                                                <img
                                                    class="chat-list-top-microphone-img"
                                                    src={require("../../../assets/chat/svg/microphone-black.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        :
                                        null
                                    } */}
                                </>
                            ))}
                        </div>




                        <div class="chat-lists-page-body-right pb-5">
                            {this.props.conversations.length !== 0 ?
                                <>
                                    <div class="chat-list-buddies-slider-title">
                                        My Buddies
                                    </div>
                                    <div class="chat-list-buddies-slider">
                                        <Slider {...settings} >
                                            {this.props.buddies.map(buddy => (
                                                <Link to={{ pathname: "/conversation-room", state: { selected_user: buddy.user, selected_user_profile_pic: buddy.profile_picture, selected_user_username: buddy.username } }}>
                                                    <div class="chat-list-buddies-slider-frame">
                                                        {buddy.profile_picture ?
                                                            <img
                                                                class="chat-list-buddies-slider-profile-pic"
                                                                src={buddy.profile_picture}
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                class="chat-list-buddies-slider-profile-pic"
                                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                        {buddy.username.length > 10 ?
                                                            <div class="chat-list-buddies-slider-username-big">
                                                                {buddy.username}
                                                            </div>
                                                            :
                                                            <div class="chat-list-buddies-slider-username">
                                                                {buddy.username}
                                                            </div>
                                                        }

                                                    </div>
                                                </Link>
                                            ))}
                                        </Slider>

                                    </div>

                                    <div class="all-chat-lists-itemds-conv">
                                        {this.props.conversations.map(conversation => (

                                            <div class="row single-item-chat-list">
                                                <div class="chat-list-profile-picture-position">
                                                    <Link to={{ pathname: "/conversation-room", state: { selected_user: conversation.appearing_user_id, selected_user_profile_pic: conversation.appearing_picture, selected_user_username: conversation.appearing_username } }}>
                                                        {conversation.appearing_picture ?
                                                            <img
                                                                class="chat-list-profile-picture"
                                                                src={conversation.appearing_picture}
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                class="chat-list-profile-picture"
                                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                    </Link>
                                                </div>
                                                {/* <div > */}
                                                <Link class="chat-list-profile-username" to={{ pathname: "/conversation-room", state: { selected_user: conversation.appearing_user_id, selected_user_profile_pic: conversation.appearing_picture, selected_user_username: conversation.appearing_username } }}>
                                                    {conversation.label === null || conversation.label === "null" ?
                                                        <>{conversation.appearing_username}</>
                                                        :
                                                        <b>{conversation.appearing_username}</b>
                                                    }
                                                    {/* elenacharalambous */}
                                                    <div class="chat-list-explaining-text">
                                                        {conversation.label === null || conversation.label === "null" ?
                                                            <>
                                                                Tap to see your full conversation
                                                            </>
                                                            :
                                                            <>
                                                                <b>{conversation.label}</b>
                                                            </>
                                                        }
                                                    </div>
                                                    {conversation.label === null || conversation.label === "null" ?
                                                        null
                                                        :
                                                        <div class="chat-list-notification-cyrcle"></div>
                                                    }
                                                </Link>
                                                {/* </div> */}


                                                {/* <div class="chat-list-micro">
                                                    <Link to={{ pathname: "/chat-room", state: { selected_user: conversation.appearing_user_id, selected_user_profile_pic: conversation.appearing_picture, selected_user_username: conversation.appearing_username } }}>
                                                        <img
                                                            class="chat-list-micro-img"
                                                            src={require("../../../assets/chat/svg/microphone-grey.svg")}
                                                        />
                                                    </Link>
                                                </div> */}



                                            </div>

                                        ))}
                                    </div>
                                </>
                                :
                                <div class="all-chat-lists-itemds">
                                    <>
                                        {this.props.buddies.length !== 0 ?
                                            <>
                                                {this.props.buddies.map(buddy => (

                                                    <div class="row single-item-chat-list">
                                                        <div class="chat-list-profile-picture-position">
                                                            <Link to={{ pathname: "/conversation-room", state: { selected_user: buddy.user, selected_user_profile_pic: buddy.profile_picture, selected_user_username: buddy.username } }}>
                                                                {buddy.profile_picture ?
                                                                    <img
                                                                        class="chat-list-profile-picture"
                                                                        src={buddy.profile_picture}
                                                                        alt=""
                                                                    />
                                                                    :
                                                                    <img
                                                                        class="chat-list-profile-picture"
                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                        alt=""
                                                                    />
                                                                }
                                                            </Link>
                                                        </div>


                                                        <div class="chat-list-profile-username">
                                                            <Link to={{ pathname: "/conversation-room", state: { selected_user: buddy.user, selected_user_profile_pic: buddy.profile_picture, selected_user_username: buddy.username } }}>
                                                                <span class="chat-list-profile-username-font">
                                                                    {buddy.username}
                                                                </span>
                                                                {/* elenacharalambous */}
                                                                <div class="chat-list-explaining-text">
                                                                    Tap to start a conversation
                                                                </div>
                                                            </Link>
                                                        </div>


                                                        {/* <div class="chat-list-micro">
                                                            <Link to={{ pathname: "/chat-room", state: { selected_user: buddy.user, selected_user_profile_pic: buddy.profile_picture, selected_user_username: buddy.username } }}>
                                                                <img
                                                                    class="chat-list-micro-img"
                                                                    src={require("../../../assets/chat/svg/microphone-grey.svg")}
                                                                />
                                                            </Link>
                                                        </div> */}



                                                    </div>

                                                ))}
                                            </>
                                            :
                                            <div class="all-chat-empty-buddies">
                                                <Link to='/search-buddies'>
                                                    <div class="all-chat-empty-buddies-icon-box">
                                                        <img
                                                            class="all-chat-empty-buddies-icon-img"
                                                            src={require("../../../assets/chat/svg/search-grey.svg")}
                                                        />
                                                    </div>
                                                    <div class="all-chat-empty-buddies-text">
                                                        <b><u>Find some Buddies to talk</u></b><br />You will be able to see the people you talk with here
                                                    </div>
                                                </Link>
                                            </div>
                                        }
                                    </>
                                </div>
                            }

                        </div>



                    </div>
                </div>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profile.profile,
    buddies: state.buddies.buddies,
    conversations: state.chat.conversations,
});

export default connect(mapStateToProps, {
    getMyProfile,
    getBuddies,
    getMyConersations
})(ChatList);