import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import GdprLinks from '../../../components/user/layout/gdprLinks';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HUB_USER_LOADED } from '../../../store/actions/actionTypes';


export class HubsTab extends Component {

    render() {
        const settingsTrollSlider = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 2,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 1000,
            cssEase: "linear",
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />,
        };
        const auth_user = JSON.parse(localStorage.getItem('ur')).id
        return (
            <Fragment>
                {this.props.data.hubs.length === 0 ?
                    <div>
                        {this.props.data.user === auth_user ?
                            <div class="profile-slider-size">
                                <br /><br /><br />
                                <Slider {...settingsTrollSlider} >
                                    <div class="" >
                                        <div class="item-img-4">
                                            <Link to='/'>
                                                <img
                                                    class="arrange-forever-alone-img"
                                                    src={require("../../../assets/layout/mika/think.png")}
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="" >
                                        <div class="item-img-4">
                                            <Link to='/'>
                                                <img
                                                    class="arrange-forever-alone-img"
                                                    src={require("../../../assets/layout/mika/tsaki.png")}
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="" >
                                        <div class="item-img-4">
                                            <Link to='/'>
                                                <img
                                                    class="arrange-forever-alone-img"
                                                    src={require("../../../assets/layout/mika/why.png")}
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="" >
                                        <div class="item-img-4">
                                            <Link to='/'>
                                                <img
                                                    class="arrange-forever-alone-img"
                                                    src={require("../../../assets/layout/mika/girl.png")}
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </Slider>
                                <br /><br />
                                <p class="item-text-4 blue pt-1">So you are trying<br /> to tell me that you are<br />not going out from home<br /><Link to='/'>Arrange something now!</Link></p>
                                <br /><br />
                            </div>
                            :
                            <div>
                                <br /><br /><br /><br /><br />
                                <p class="item-text-4 blue pt-1">
                                    No Hubs to show.<br />
                                    <Link to='/'>Arrange something now!</Link><br />
                                </p>
                                <br /><br /><br /><br /><br /><br /><br /><br />
                            </div>

                        }
                    </div>

                    :
                    <div>
                        <br />
                        <br />
                        <h5 class="blue">
                            Hubs visited the last 3 months
                        </h5>
                        <br />
                        {this.props.data.hubs.map(hub => (
                            <Link to={{ pathname: '/hub', state: { hub_id: hub.hub_id } }}>
                                <div class="profile-item-hub-field">
                                    <div class="">
                                        <div class="">
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                                                {/* <Link to={{ pathname: `/user-profile/${user.user}`, state: { user: user.user } }}> */}
                                                {hub.hub_logo ?
                                                    <img
                                                        class="profile-hubs-picture-layout"
                                                        src={hub.hub_logo}
                                                        alt=""
                                                    />
                                                    :
                                                    <img
                                                        class="profile-hubs-picture-layout"
                                                        src={require("../../../assets/hub/hub-page/svg/location-red.png")}
                                                        alt=""
                                                    />
                                                }
                                                {/* <img class="profile-hubs-picture-layout" src={hub.hub_logo} alt="" /> */}
                                                {/* </Link> */}
                                            </div>
                                            <div class="profile-hubs-hub-name-layout">
                                                <p class="text-blue-19"><strong class="search-user-username-layout">{hub.hub_name}</strong> </p>
                                            </div>
                                            <div class="profile-hubs-visites-layout">
                                                <p class="text-blue-19"><strong class="search-user-username-layout">{hub.average_visites}</strong><strong class="profile-hubs-month-word">/month</strong> </p>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br /><br /><br />
                                </div>
                            </Link>
                        ))}
                    </div>
                }
                <br /><br /><br /><br /><br /><br /><br /><br />
                <GdprLinks />
            </Fragment >
        )
    }
}

export default (HubsTab);