

import {
    GET_SCARLETT_ANSWER,
    GET_SCARLETT_SESSION_ALL_MESSAGES,
    CREATE_MESSAGE_WITH_SCARLETT,
} from '../actions/actionTypes';

const initialState = {
    scarlett_answer: [],
    session_messages:[],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SCARLETT_ANSWER:
            return {
                ...state,
                scarlett_answer: action.payload,
            };
        case GET_SCARLETT_SESSION_ALL_MESSAGES:
            return {
                ...state,
                session_messages: action.payload,
            };
        case CREATE_MESSAGE_WITH_SCARLETT:
            return {
                ...state,
                session_messages: [...state.session_messages, action.payload]
            };
            
            
        default:
            return state;
    }
}