import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class HubReservationRequested extends Component {
    render() {
        const { notification } = this.props.data
        return (
            <Fragment>
                <Link to="/meetings">
                    <div class="row single-item-9">
                        <div class="notification-related-photo-position">
                            {notification.related_picture ?
                                <img class="related-picture-layout" src={notification.related_picture} alt="" />
                                :
                                <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                            }
                        </div>
                        <div class="notification-information-section">
                            {/* <span class="row notification-meeting-info">
                                <p class="text-blue-9">{notification.invitation_date}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {notification.invitation_day}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {notification.invitation_time}</p>
                            </span> */}
                            <div class="notification-text-position">
                                <p class="text-blue-9 notification-text-layout">

                                    <strong>{notification.related_name}</strong> has received your request for Reservation at <strong>{notification.invitation_time}</strong> on <strong>{notification.invitation_day}</strong> <strong>{notification.invitation_date}</strong> and will reply soon
                                </p>
                            </div>
                        </div>
                        {/* <div class="notification-related-icon-layout">
                            <img class="notification-related-icon-size" src={require("../../../assets/notifications/svg/Accepted icon.svg")} alt="" />
                        </div> */}
                    </div>
                </Link>
            </Fragment >
        )
    }
}


export default (HubReservationRequested);