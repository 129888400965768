import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerStaff } from '../../../store/actions/authhub';
import { getRegisterHubList } from '../../../store/actions/hub'
import { createAlert } from '../../../store/actions/alerts'
import Modal from 'react-awesome-modal';
import CookiesModal from '../../../components/user/layout/cookiesModal';
import '../../../assets/hub/hub-authentication/hub-registration.css'
// dropdown menu
import Select from 'react-select'
import 'bootstrap/dist/css/bootstrap.min.css';

export class HubRegister extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        hub_id: '',
        hub_logo: "",
        hub_name: "",
        ageBox: false,
        termsBox: false,
        modalVisible: false
    };

    componentDidMount() {
        this.props.getRegisterHubList();
    }

    onCredentialsSubmit = e => {
        e.preventDefault();
        const { username, email, hub_id, ageBox, termsBox } = this.state;
        if (email === "") {
            this.props.createAlert({ noEmailGiven: "You haven't given an Email address!" });
        } else if (username === "") {
            this.props.createAlert({ noUsenameGiven: "You haven't given a Username!" });
        } else if (hub_id === "") {
            this.props.createAlert({ noRegisterHubSelected: "You haven't sellected any Hub to register to!" });
        } else if (termsBox === false) {
            this.props.createAlert({ noTermsConfirmation: "You haven't declared that you accept our Terms, Data Policy and Cookies!" });
        } else if (!ageBox) {
            this.props.createAlert({ noAgeConfirmation: "You haven't declared that you are over 18!" });
        } else {
            this.openModal()
        }
        // const newUser = {
        //     username,
        //     email
        // }
        // this.props.register(newUser);
    };



    // onSubmit = e => {
    //     e.preventDefault();
    //     const { username, email, password, password2, hub_id } = this.state;

    //     // const newUser = {
    //     //     username,
    //     //     email,
    //     //     password,
    //     //     hub_id
    //     // }
    //     // if (this.state.is_staff)
    //     //     this.props.registerStaff(newUser);

    // };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    changeAgeBox = e => this.setState({ ageBox: !this.state.ageBox });

    changeTermsBox = e => this.setState({ termsBox: !this.state.termsBox });

    openModal() {
        this.setState({
            modalVisible: true
        });
    }

    closeModal() {
        this.setState({
            modalVisible: false
        });
    }

    handleHubChange(e) {
        this.setState({
            hub_id: e.value,
            hub_name: e.name,
            hub_logo: e.logo
        })
    }

    render() {
        if (this.props.isHubAuthenticated) {
            return <Redirect to="/pending-reservations" />;
        }
        const { username, email, password, hub_id } = this.state;

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                display: "block",
                width: "100%",
                height: "45px",
                padding: "5px 12px",
                fontSize: "14px",
                lineHeight: "1.42857143",
                color: "#555",
                backgroundImage: "none",
                border: "none",
                textAlign: "center"
            }),
            singleValue: (provided, state) => ({
                ...provided,
                display: "block",
                width: "100%",
                height: "45px",
                padding: "5px 12px",
                fontSize: "14px",
                lineHeight: "1.42857143",
                color: "#555",
                backgroundImage: "none",
                border: "none",
                marginTop: "5px",
                textAlign: "center",
                borderRadius: "50px",
            }),
        }

        return (
            <Fragment>
                <CookiesModal />
                <Modal visible={this.state.modalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div class="authentication-password-box">
                        <div class="row">
                            <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                            </div>
                            <div class="col-lg-6 col-md-10 col-sm-8 col-8 c-box-6">
                                <p class="hub-register-modal-top-text">Enter the 6 Digit Password sent to your email</p>
                                <div class="">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            onChange={this.onChange}
                                            value={password}
                                            class="modal-password-field"
                                            name="password"
                                            placeholder="Enter password"
                                            required
                                        />
                                    </div>
                                    <br />
                                    <center>
                                        <a class="send-again-a" href="#">
                                            Send again
                                        </a>
                                    </center>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center mt-4">
                                        <div
                                            onClick={() => this.closeModal()}
                                            class="btn hub-register-modal-btn-success">
                                            Continue
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                            </div>
                        </div>
                    </div>
                </Modal>
                <div class="hub-register-form-color">
                    <div class="hub-register-form-header text-center">
                        <img
                            class="hubst3r-login-word-size"
                            src={require("../../../assets/authentication/svg/Logo-h.svg")}
                            alt=""
                        />
                    </div>

                    <div class="hub-register-signup-form">
                        <form action="/examples/actions/confirmation.php" method="post">
                            <div class="hub-register-form-logo text-center">
                                <img
                                    class="hubster-login-logo-size"
                                    src={require("../../../assets/authentication/svg/Logo.svg")}
                                    alt=""
                                />
                            </div>
                            <h2>Registration</h2>
                            <p class="hint-text">Register to have access to the Hub Page your working in</p>
                            <div class="form-group">
                                <input
                                    type="email"
                                    class="hub-register-form-control"
                                    name="email"
                                    placeholder="Enter your email"
                                    onChange={this.onChange}
                                    value={email}
                                    required
                                    autoFocus
                                />
                            </div>
                            <div class="form-group">
                                <input
                                    type="text"
                                    onChange={this.onChange}
                                    value={username}
                                    class="hub-register-form-control"
                                    name="username"
                                    placeholder="Enter your username"
                                    required
                                />
                            </div>

                            <Select
                                className=""
                                placeholder="Select Hub"
                                styles={customStyles}
                                options={this.props.hubs.map(hub => ({
                                    "value": hub.id,
                                    "label":
                                        <div>
                                            <span
                                                style={{ marginLeft: "-100%" }}
                                            >
                                                {hub.name}
                                            </span>
                                            <span style={{ marginLeft: "50%", marginRight: "-50%" }}>
                                                <img
                                                    src={hub.logo}
                                                    class=""
                                                    style={{ width: "20px", height: "20px", border: "1", marginRight: "-50%", borderRadius: "50%" }}
                                                />
                                            </span>
                                        </div>,
                                    "name": hub.name,
                                    "logo": hub.logo
                                }))

                                }
                                onChange={this.handleHubChange.bind(this)}
                            />
                            <div class="form-group">
                                <label class="hub-register-checkbox-inline">
                                    <input type="checkbox" required="required" onClick={this.changeTermsBox} />&nbsp;&nbsp;
                                    By clicking, you agree to our Terms, Data Policy and Cookies Policy
                                </label>
                            </div>
                            <div class="form-group">
                                <label class="hub-register-checkbox-inline">
                                    <input type="checkbox" required="required" onClick={this.changeAgeBox} />&nbsp;&nbsp;
                                    I am over 18 years old
                                </label>
                            </div>
                            <div class="form-group">
                                <button
                                    onClick={this.onCredentialsSubmit}
                                    class="btn btn-success btn-lg btn-block"
                                >
                                    Register
                                </button>
                            </div>
                            <div class="text-center hub-register-account-have">
                                Already have an account? &nbsp;
                                <Link to="/register">
                                    <a href="#">
                                        Login now
                                    </a>
                                </Link>
                            </div>
                            <div class="text-center hub-register-account-have">
                                I have a Shop that isn't in Hubst3r yet? &nbsp;
                                <Link to="/login">
                                    <a href="#">
                                        Click Here
                                    </a>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    hubs: state.hub.hubs,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { registerStaff, getRegisterHubList, createAlert })(HubRegister);