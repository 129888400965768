import React, { Component, Fragment } from 'react';
import "../../../assets/layout/homepage.css"


export class MapModal extends Component {

    closeModal = () => {
        this.props.data.closeModal()
    }

    render() {
        return (
            <Fragment>
                <div class="qr-click-box">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="qr-box">
                            <img
                                onClick={this.closeModal}
                                class="times-modal-size"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <span class="map-modal-frame-radious2">
                                <iframe
                                    src={this.props.data.map_url}
                                    class="map-modal-map"
                                    aria-hidden="false"
                                    tabindex="0"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}


export default (MapModal);