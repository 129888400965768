import React, { Fragment, Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import 'react-times/css/material/default.css';
import { createAlert } from '../../../store/actions/alerts';
//datepicker
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// Invitation Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getRequestReservationHubs } from '../../../store/actions/hub';
import { getNotificationsStatus } from '../../../store/actions/notification';
import { getMemoryUploadHubList, searchReservationHubs } from '../../../store/actions/hub'

import { createHubReservationUser, getHubDaysReservationHours } from '../../../store/actions/reservation'

import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import moment from "moment-timezone";
import "../../../assets/arrange-meeting/arrange-page.css";
import WheelPicker from 'react-simple-wheel-picker';

// use material theme
import 'react-times/css/material/default.css';

export class ArrangePage extends Component {

    state = {
        reservation_note: '-',
        telephone: "-",
        hub: '',
        date: new Date(),
        date_updated: false,
        redirect: false,
        show_datepicker: false,
        hour: '',
        minute: '',
        time: '',
        persons: '',
        time_updated: false,
        hour_sellected: false,
        hubMode: true,
        dateMode: false,
        personsMode: false,
        timeMode: false,
        noteMode: false,
        hub_name: "",
        hub_logo: "",
        hub_id: "",
        timeModalVisible: true,
        pre_selected: false,
        selected_time: ""
    };

    componentDidMount() {
        if (this.props.location.state) {
            let pre_selected_hub = this.props.location.state.hub
            if (pre_selected_hub) {
                this.setState({
                    hubMode: false,
                    dateMode: true,
                    personsMode: false,
                    timeMode: false,
                    noteMode: false,
                })
            }
        }
        this.props.getRequestReservationHubs()
        // const id = JSON.parse(localStorage.getItem('ur')).id
        // this.props.getBuddies(id);
        // this.props.getGags()
        // this.props.getArrangeMeetingHub(this.props.location.state.hub.id);
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    selectHubMode(hub_name, hub_logo) {
        this.setState({
            hubMode: true,
            dateMode: false,
            timeMode: false,
            personsMode: false,
            noteMode: false,
        })
    }

    selectDateMode(hub_name, hub_logo, hub_id) {
        this.setState({
            hubMode: false,
            dateMode: true,
            personsMode: false,
            timeMode: false,
            noteMode: false
        })
        if (hub_name !== "") {
            this.setState({
                hub_name: hub_name,
                hub_logo: hub_logo,
                hub_id: hub_id
            })
        }
    }

    selectDateModeTop() {
        this.setState({
            hubMode: false,
            dateMode: true,
            personsMode: false,
            timeMode: false,
            noteMode: false
        })
    }

    selectTimeMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            personsMode: false,
            timeMode: true,
            noteMode: false,
            timeModalVisible: true,

        })
    }

    selectNoteMode(person) {
        if (person) {
            this.setState({
                persons: person
            })
        }
        this.setState({
            hubMode: false,
            dateMode: false,
            personsMode: false,
            timeMode: false,
            noteMode: true
        })
    }

    selectPersonsMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            personsMode: true,
            timeMode: false,
            noteMode: false
        })
    }

    updateDateField(day) {
        let hub_id = this.state.hub_id
        if (this.props.location.state) {
            hub_id = this.props.location.state.hub.id
        } else {
            hub_id = this.state.hub_id
        }
        const correct_datetime = moment(day).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubDaysReservationHours(hub_id, date_id)
        this.setState({
            show_datepicker: false,
            date: day,
            date_updated: true,
            hubMode: false,
            dateMode: false,
            timeMode: true,
            timeModalVisible: true,
            noteMode: false,
            pre_selected: true
        })
    }




    updateReservatonHour = (options) => {
        const {
            hour,
            minute,
        } = options;
        if (this.state.hour_sellected) {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                time_updated: true,
                hubMode: false,
                dateMode: false,
                timeMode: false,
                hour_sellected: false,
                personsMode: true,
                noteMode: false
            });
        } else {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                hour_sellected: true,
            });
        }


    }

    onChange = e => {
        if (e.target.name === "hub_search") {
            if (e.target.value === "") {
                this.setState({ [e.target.name]: e.target.value })
                const country = JSON.parse(localStorage.getItem('cntr'))
                this.props.getRequestReservationHubs()
                // this.props.getMemoryUploadHubList(country);
            } else {
                if (e.target.name === "hub_search") {
                    const query = e.target.value
                    if (query.toLowerCase() !== "ad") {
                        this.props.searchReservationHubs(query)
                    }
                }
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onMeetingSubmit = e => {
        e.preventDefault();
        const { buddies_note } = this.state;
        const creator = JSON.parse(localStorage.getItem('ur')).username
        const hub_name = this.props.location.state.hub.name
        const invitations_list = this.state.invitations
        const invitations = invitations_list.toString();
        const gag_invitations_list = this.state.gag_invitations
        const gag_invitations = gag_invitations_list.toString();
        const time = this.state.time
        // const date = this.state.date
        const date = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")

        const meeting = { buddies_note, hub_name, creator, invitations, gag_invitations, time, date };
        if ((invitations === "" && gag_invitations === "") || (invitations === "0" && gag_invitations === "0")) {
            this.props.createAlert({ noInvitationsSent: "You haven't Invite Anyone!" });
        } else if (time === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Select time!" });
        } else {
            this.props.createMeeting(meeting);
            setTimeout(() => {
                this.setState({
                    buddies_note: "-",
                    hub_name: "",
                    invitations: [],
                    gag_invitations: [],
                    time: "",
                    date: new Date(),
                    redirect: true
                });
            }, 500);
        }

    };

    openModal = (name, id, persons) => (e) => {
        this.setState({
            gag_name: name,
            gag_id: id,
            gag_persons: persons,
            gagModal: true
        });
        this.props.getGagMembers(id);
    }

    setDatepickerMode = () => (e) => {
        this.setState({
            show_datepicker: true
        });
    }

    openTimeModal() {
        this.setState({
            selected_hour: false,
            timeModalVisible: true,
        });
    }

    closeModal() {
        this.setState({
            timeModalVisible: false,
            hubMode: false,
            dateMode: false,
            personsMode: true,
            timeMode: false,
            noteMode: false
        });
    }

    sellectedHourMode(hour) {
        let minute = this.state.minute
        if (minute === "") {
            minute = "00"
        }
        const time = hour + ':' + minute
        this.setState({
            hour: hour,
            time: time

        });
        setTimeout(() => {
            this.setState({
                selected_hour: true,
            });
        }, 500);
    }

    sellectedMinuteMode(minute) {
        const time = this.state.hour + ':' + minute
        this.setState({
            minute: minute,
            time: time,

            hubMode: false,
            dateMode: false,
            timeMode: false,
            timeModalVisible: false,
            personsMode: true,
            noteMode: false

        });
        setTimeout(() => {
            this.setState({
                selected_hour: false,
            });
        }, 700);
    }

    backToHours = e => {
        this.setState({
            selected_hour: false,
        });
    }


    selectSpecificTime() {
        this.setState({
            time: this.state.selected_time.value,
            dateMode: false,
            timeMode: false,
            personsMode: true,
            noteMode: false
        })
    }


    handleOnChange(time) {
        this.setState({
            selected_time: time
        })
    }


    onRequestReservation() {
        const creator = JSON.parse(localStorage.getItem('ur')).username
        let hub_id = this.state.hub_id
        if (this.props.location.state) {
            hub_id = this.props.location.state.hub.id
        } else {
            hub_id = this.state.hub_id
        }

        let hub_name = this.state.hub_name
        if (this.props.location.state) {
            hub_name = this.props.location.state.hub.name
        } else {
            hub_name = this.state.hub_name
        }

        const time = this.state.time
        const persons = this.state.persons
        const telephone = this.state.telephone
        const reservation_request_note = this.state.reservation_note
        const date = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const reservation = { hub_name, hub_id, creator, time, date, persons, reservation_request_note, telephone };
        if (hub_id === "") {
            this.props.createAlert({ noInvitationsSent: "You haven't Selected a Hub" });
        } else if (!this.state.date_updated) {
            this.props.createAlert({ noTimeSelected: "You haven't Select a Date!" });
        } else if (time === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Select time!" });
        } else if (persons === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Select for how many persons is the reservation for!" });
        } else if (telephone === "-") {
            this.props.createAlert({ noTimeSelected: "You haven't given a phone number to the Hub!" });
        } else {
            this.props.createHubReservationUser(reservation)
            setTimeout(() => {
                this.setState({
                    buddies_note: "-",
                    hub_name: "",
                    invitations: [],
                    gag_invitations: [],
                    time: "",
                    date: new Date(),
                    redirect: true
                });
            }, 700);
        }

    };


    render() {
        const ExampleCustomInput = ({ value, onClick }) => (
            <div
            // onClick={onClick}
            >
                {value}
            </div>
        );

        if (this.state.redirect) {
            return <Redirect to="/meetings" />;
        }
        // carousel arrows
        const ArrowNext = (props) => (
            <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-right-position">
                <div
                    style={{ display: "none" }}
                    class="arrow arrow-right"
                >
                    <img src={require("../../../assets/arrange-meeting/svg/next.svg")} alt="" />
                </div>
            </div>
        );

        const ArrowPrev = (props) => (
            <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-left-position">
                <div
                    style={{ display: "none" }}
                // class="arrow arrow-left"
                >
                    <img src={require("../../../assets/arrange-meeting/svg/Icon.svg")} alt="" />
                </div>
            </div>
        );



        //invitation carousel settings
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            // slidesToScroll: 1,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };
        const { auth } = this.props
        const persons = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]


        let pre_selected_hub = {}
        let pre_selected_logo = ""
        let pre_selected_hub_id = ""
        let pre_selected = false

        if (this.props.location.state) {
            pre_selected_logo = this.props.location.state.hub.logo
            pre_selected_hub_id = this.props.location.state.hub.id
            pre_selected = true
        }

        const data = this.props.reservation_hours

        return (

            < Fragment >
                {/* <div class={this.props.isLoading ? "arrange-page-lottie-appear" : "arrange-page-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <DesktopNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />


                {/* <div class={this.props.isLoading ? "arrange-page-section-disappear" : null}> */}
                <div>
                    <div class="arrange-page-whole-page-layout">
                        <OfflineLabel />
                        <div class="color-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div id="page-scroll-up" class="request-reservation-mobile-navbar">
                                        <div onClick={() => this.handleBack()} class="back-button-request-reservation-box">
                                            <img
                                                class="request-reservation-back-button"
                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                        Request Reservation
                                    </div>
                                    {/* <div class="container-fluid small-header">
                                        <div class="row py-4">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-4 top-back-4">
                                                <img
                                                    onClick={this.handleBack}
                                                    src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-8 upper-text-4">
                                                <h2>
                                                    Request a Reservation
                                                </h2>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="body-right-4 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">


                                        <div class="arrange-invite-your-buddies-text">
                                            <div class="text-top text-center blue pt-5 pb-3">
                                                <h3>
                                                    Request a Reservation
                                                </h3>
                                            </div>
                                        </div>

                                        <div class="request-reservation-top">
                                            <div class="request-reservation-top-row">
                                                <div
                                                    onClick={() => this.selectHubMode()}
                                                    class="request-reservation-top-step"
                                                >
                                                    <div class="request-reservation-top-title">
                                                        Hub
                                                    </div>

                                                    <div
                                                        class={this.state.hubMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {pre_selected_logo !== "" ?
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={pre_selected_logo}
                                                                alt=""
                                                            />
                                                            : this.state.hub_logo === "" ?
                                                                1
                                                                :
                                                                <img
                                                                    class="request-reservation-btn-hub-logo"
                                                                    src={this.state.hub_logo}
                                                                    alt=""
                                                                />
                                                        }

                                                    </div>
                                                    <div class="request-reservation-top-text">{this.state.hub_name}</div>
                                                </div>
                                                <div
                                                    onClick={() => this.selectDateModeTop()}
                                                    class="request-reservation-top-step">
                                                    <div class="request-reservation-top-title">
                                                        Date
                                                    </div>

                                                    <div
                                                        class={this.state.dateMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {this.state.date_updated ?
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                alt=""
                                                            />
                                                            :
                                                            2
                                                        }
                                                    </div>
                                                    {this.state.date_updated ?
                                                        <div class="request-reservation-top-text">
                                                            <DatePicker
                                                                selected={this.state.date}
                                                                dateFormat="dd/MM/yyyy"
                                                                customInput={<ExampleCustomInput />}
                                                                readOnly
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>


                                                {this.state.date_updated ?
                                                    <div
                                                        onClick={() => this.selectTimeMode()}
                                                        class="request-reservation-top-step">
                                                        <div class="request-reservation-top-title"  >
                                                            Time
                                                        </div>
                                                        <div
                                                            class={this.state.timeMode ?
                                                                "request-reservation-btn-circle-sellected"
                                                                :
                                                                "request-reservation-btn-circle"
                                                            }
                                                        >
                                                            {this.state.time === "" ?
                                                                2
                                                                :
                                                                <img
                                                                    class="request-reservation-btn-hub-logo"
                                                                    src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="request-reservation-top-text">{this.state.time}</div>
                                                    </div>
                                                    :
                                                    <div
                                                        class="request-reservation-top-step">
                                                        <div class="request-reservation-top-title"  >
                                                            Time
                                                        </div>
                                                        <div
                                                            class={this.state.timeMode ?
                                                                "request-reservation-btn-circle-sellected"
                                                                :
                                                                "request-reservation-btn-circle"
                                                            }
                                                        >
                                                            {this.state.time === "" ?
                                                                2
                                                                :
                                                                <img
                                                                    class="request-reservation-btn-hub-logo"
                                                                    src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="request-reservation-top-text">{this.state.time}</div>
                                                    </div>
                                                }

                                                {/* <div
                                                    onClick={() => this.selectTimeMode()}
                                                    class="request-reservation-top-step">
                                                    <div class="request-reservation-top-title"  >
                                                        Time
                                                    </div>
                                                    <div
                                                        class={this.state.timeMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {this.state.time === "" ?
                                                            3
                                                            :
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                    <div class="request-reservation-top-text">{this.state.time}</div>
                                                </div> */}


                                                <div
                                                    onClick={() => this.selectPersonsMode()}
                                                    class="request-reservation-top-step"
                                                >
                                                    <div class="request-reservation-top-title">
                                                        Persons
                                                    </div>
                                                    <div
                                                        class={this.state.personsMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {this.state.persons === "" ?
                                                            4
                                                            :
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                    <div class="request-reservation-top-text">{this.state.persons}</div>
                                                </div>

                                                <div
                                                    onClick={() => this.selectNoteMode()}
                                                    class="request-reservation-top-step"
                                                >
                                                    <div
                                                        class="request-reservation-top-title">
                                                        Details
                                                    </div>
                                                    <div
                                                        class={this.state.noteMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        5
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />


                                        <div class="row">
                                            <div class="request-reservation-explaining-img-box">
                                                <img
                                                    class="request-reservation-explaining-img"
                                                    src={require("../../../assets/arrange-meeting/svg/share.svg")}
                                                    alt=""
                                                />
                                            </div>

                                            <div class="request-reservation-explaining-text">
                                                Once you make a reservation you will be able to Share it with your Buddies!
                                            </div>
                                        </div>



                                        <hr />

                                        <div>
                                            {pre_selected_hub_id !== "" ?
                                                null
                                                : this.state.hubMode ?
                                                    <>
                                                        <div class="request-reservation-section-title">
                                                            Select a Hub
                                                        </div>


                                                        <div className="request-reservation-search">
                                                            <input
                                                                className="request-reservation-search"
                                                                type="search"
                                                                name="hub_search"
                                                                onChange={this.onChange}
                                                                placeholder="Search Hub"
                                                                value={this.state.hub_search}
                                                                aria-label="Search"
                                                            />
                                                        </div>


                                                        <div class="request-reservation-hub-carousel">
                                                            <Slider {...settings} >
                                                                {this.props.hubs.map(hub => (
                                                                    <div
                                                                        onClick={() => this.selectDateMode(hub.name, hub.logo, hub.id)}
                                                                        class="request-reservation-hub-carouse-item"
                                                                    >

                                                                        {pre_selected_hub_id === hub.id ?
                                                                            <img
                                                                                class="request-reservation-hub-logo-selected"
                                                                                src={hub.logo}
                                                                                alt=""
                                                                            />
                                                                            : this.state.hub_id === hub.id ?
                                                                                <img
                                                                                    class="request-reservation-hub-logo-selected"
                                                                                    src={hub.logo}
                                                                                    alt=""
                                                                                />
                                                                                :
                                                                                <img
                                                                                    class="request-reservation-hub-logo"
                                                                                    src={hub.logo}
                                                                                    alt=""
                                                                                />
                                                                        }

                                                                        <p
                                                                            onClick={() => this.selectDateMode(hub.name, hub.logo, hub.id)}
                                                                            class="request-reservation-hub-name">{hub.name}</p>
                                                                    </div>
                                                                ))}

                                                            </Slider>

                                                        </div>
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </>
                                                    :
                                                    null
                                            }
                                            {this.state.timeMode ?
                                                <>
                                                    {data.length !== 0 ?
                                                        <>
                                                            <div class="request-reservation-timewheel-box">
                                                                {data.length !== 0 ?

                                                                    <>
                                                                        <div class="request-reservation-timewheel-arrow-left-box">
                                                                            <img
                                                                                class="request-reservation-timewheel-arrow-img"
                                                                                src={require("../../../assets/hub/hub-homepage/svg/arrow-left.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="request-reservation-timewheel-arrow-right-box">
                                                                            <img
                                                                                class="request-reservation-timewheel-arrow-img"
                                                                                src={require("../../../assets/hub/hub-homepage/svg/arrow-right.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <WheelPicker
                                                                            data={data}
                                                                            onChange={this.handleOnChange.bind(this)}
                                                                            height={350}
                                                                            width={180}
                                                                            itemHeight={60}
                                                                            selectedID={data[5].id}
                                                                            color="#000"
                                                                            activeColor="white"
                                                                            backgroundColor="grey"
                                                                        />


                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                            </div>

                                                            <div onTouchStart>
                                                                <div
                                                                    onClick={() => this.selectSpecificTime()}
                                                                    class="request-reservation-request-button"
                                                                >
                                                                    Select {this.state.selected_time.value}
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <div class="request-reservation-no-reservation-hours">
                                                            You have sellected to be Closed<br />on this day of the week
                                                        </div>
                                                    }
                                                </>

                                                :
                                                null
                                            }

                                            {this.state.dateMode ?
                                                <>
                                                    <div class="request-reservation-section-title">
                                                        Select the Date
                                                    </div>

                                                    <DatePicker
                                                        selected={this.state.date}
                                                        todayButton="Press for Today"
                                                        onChange={this.updateDateField.bind(this)}
                                                        minDate={new Date()}
                                                        name="date"
                                                        dateFormat="dd/MM/yyyy"
                                                        // customInput={<ExampleCustomInput />}
                                                        // withPortal
                                                        inline
                                                    />
                                                </>
                                                :
                                                null
                                            }

                                            {this.state.personsMode ?
                                                <>
                                                    <div class="request-reservation-section-title">
                                                        Select for how many persons
                                                    </div>
                                                    <div class="request-reservation-hub-carousel">

                                                        {this.state.persons === "" ?
                                                            <>
                                                                <Slider {...settings} >
                                                                    {persons.map(person => (
                                                                        <div
                                                                            onClick={() => this.selectNoteMode(person)}
                                                                            class="request-reservation-hub-carouse-item"
                                                                        >
                                                                            {this.state.persons === person ?
                                                                                <img
                                                                                    class="request-reservation-hub-logo-selected"
                                                                                    src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                                    alt=""
                                                                                />
                                                                                :
                                                                                <img
                                                                                    class="request-reservation-hub-logo"
                                                                                    src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                                    alt=""
                                                                                />
                                                                            }
                                                                            <p class="request-reservation-hub-name">{person} persons</p>
                                                                        </div>
                                                                    ))}
                                                                </Slider>
                                                            </>
                                                            :
                                                            <Slider {...settings} >
                                                                <div
                                                                    onClick={() => this.selectNoteMode(this.state.persons)}
                                                                    class="request-reservation-hub-carouse-item"
                                                                >
                                                                    <img
                                                                        class="request-reservation-hub-logo-selected"
                                                                        src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                        alt=""
                                                                    />
                                                                    <p class="request-reservation-hub-name">{this.state.persons} persons</p>
                                                                </div>
                                                            </Slider>
                                                        }

                                                    </div>
                                                    <div class="request-reservation-section-title">
                                                        or
                                                    </div>

                                                    <div class="request-reservation-section-title">
                                                        Write for how many Persons
                                                    </div>

                                                    <div className="request-reservation-write-persons">
                                                        <input
                                                            className="request-reservation-write-persons form-control mr-sm-2 w-100 py-4"
                                                            type="number"
                                                            name="persons"
                                                            onChange={this.onChange}
                                                            placeholder="Persons..."
                                                            value={this.state.persons}
                                                        />
                                                    </div>

                                                    <div
                                                        onClick={() => this.selectNoteMode(this.state.persons)}
                                                        class="request-reservation-persons-next"
                                                    >
                                                        Next
                                                    </div>

                                                    <br /><br /><br /><br />
                                                </>
                                                :
                                                null
                                            }

                                            {this.state.noteMode ?
                                                <>
                                                    <div class="request-reservation-notes">
                                                        Insert your Mobile Phone Number
                                                    </div>
                                                    <div class="">
                                                        <div class="form-group mt-4">
                                                            <input
                                                                maxlength="18"
                                                                className="form-control"
                                                                type="number"
                                                                name="telephone"
                                                                onChange={this.onChange}
                                                                placeholder={this.state.telephone === "-" ? "Mobile Phone Number..." : this.state.telephone}
                                                            />

                                                        </div>

                                                        <div class="request-reservation-notes">
                                                            Write something extra you need to inform the Hub
                                                        </div>
                                                        <div class="form-group mt-4">
                                                            <textarea
                                                                class="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                placeholder={this.state.reservation_note === "-" ? "Message to the Hub..." : this.state.reservation_note}
                                                                rows="3"
                                                                name="reservation_note"
                                                                onChange={this.onChange}
                                                            ></textarea>
                                                        </div>

                                                        <div onTouchStart>
                                                            <div

                                                                onClick={() => this.onRequestReservation()}
                                                                class="request-reservation-request-button"
                                                            >
                                                                Request Reservation
                                                            </div>
                                                        </div>
                                                        <br /><br /><br /><br /><br />
                                                        <br /><br /><br /><br /><br />
                                                    </div>
                                                </>
                                                :
                                                null
                                            }



                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    {this.state.noteMode || this.state.timeMode ?
                        null
                        :
                        <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />
                    }
                </ScrollLink>
            </Fragment >

        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    buddies: state.buddies.buddies,
    hub: state.hub.hub,
    hubs: state.hub.hubs,
    gags: state.buddies.gags,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    reservation_hours: state.reservation.reservation_hours
});

export default connect(mapStateToProps, {
    createAlert,
    getNotificationsStatus,
    getRequestReservationHubs,
    getMemoryUploadHubList,
    searchReservationHubs,
    createHubReservationUser,
    getHubDaysReservationHours
})(ArrangePage);