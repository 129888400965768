import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { getHubReservtionShares } from '../../../store/actions/reservation'
import { connect } from 'react-redux';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/meeting/share-reservation-modal.css"



class ShareReservationModal extends React.Component {
    state = {
        shared_with: [],
        buddy_search: '',
        shareReservationMode: false,
        btnAppear: true,
    };

    closeModal = () => {
        this.props.data.closeModal()
    }

    shareReservationMode = () => {
        this.setState({
            shareReservationMode: true
        })
    }

    closeShareReservationMode = () => {
        this.setState({
            shareReservationMode: false
        })
    }

    onChange = e => {
        if (e.target.name === "buddy_search") {
            if (e.target.value === "") {
                this.setState({ [e.target.name]: e.target.value })
                const profile = this.props.data.profile[0]
                this.props.getBuddies(profile.user)
            } else {
                const query = e.target.value
                if (query.length > 2) {
                    if (query.toLowerCase() !== "ad") {
                        this.props.searchBuddy(query)
                    }
                    this.setState({ [e.target.name]: e.target.value })
                }
            }
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    sumbitHubReservationShare(buddy_user, buddy_id) {
        const profile = this.props.data.profile[0]
        const hub_reservation = this.props.data.hub_reservation_id
        const sharing_user = profile.user
        const receiving_sharing_reservation = buddy_user
        const reservation_share = { hub_reservation, sharing_user, receiving_sharing_reservation }
        this.props.shareHubReservation(reservation_share, hub_reservation)
        this.updateSharedWithList(buddy_id)
    }

    updateSharedWithList(id) {
        // e.preventDefault();
        const shared_with_list = [...this.state.shared_with]
        shared_with_list.push(id)
        this.setState({ shared_with: shared_with_list })
        setTimeout(() => {
            this.props.getHubReservtionShares(this.props.data.hub_reservation_id)
            this.props.getUserHubReservations()
        }, 300);
    }

    updateCancelledHubReservations(id) {
        const cancellations_tab = [...this.state.cancelledHubReservations]
        cancellations_tab.push(id)
        this.setState({ cancelledHubReservations: cancellations_tab })
    }

    render() {
        const reservation_user = this.props.data.hub_reservation_user
        const reservation_shares = [...this.props.data.hub_reservation_shares]
        const buddies = [...this.props.data.buddies]

        let shared_with_ids = []
        shared_with_ids = reservation_shares.map(shared_with => shared_with.receiving_sharing_reservation);

        // setTimeout(() => {
        //     if (buddies.length !== 0) {
        //         const persons_shared_with = reservation_shares.map(share => buddies.find(buddy => buddy.user === share.receiving_sharing_reservation))
        //         // console.log(persons_shared_with)

        //         // if (persons_shared_with.length !== 0) {
        //         //     shared_with_ids = persons_shared_with.map(shared_with => shared_with.user);
        //         // }
        //     }
        // }, 1000);


        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 3,
            vertical: true,
            verticalSwiping: true,
            swipeToSlide: true,
        };

        const buddiesSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
        };


        const user = this.props.data.profile[0].user

        return (
            <div class="memory-share-reservation-modal-position">
                <div class="row">
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                    <div class="memory-share-reservation-modal-box">
                        <div class="row text-right">
                            <div class="col-xl-12 text-right">
                                <div
                                    onClick={() => this.closeModal()}
                                    class="memory-share-reservation-cancle-btn">
                                    <img class="memory-share-reservation-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-left memory-share-reservation-title-box">
                                <h4><strong class="memory-share-reservation-title">People the Reservation shared to:</strong></h4>
                            </div>
                        </div>
                        <hr />


                        {this.state.shareReservationMode ?

                            <>
                                <div class="memory-share-reservation-add-slider-title">
                                    Share with your Buddies
                                </div>

                                <div className="share-search-reservation-search">
                                    <div className="form-inline w-100">
                                        <input
                                            className="share-search-reservation-search form-control mr-sm-2 w-100 py-4"
                                            type="search"
                                            name="buddy_search"
                                            onChange={this.onChange}
                                            placeholder="Search Buddy"
                                            value={this.state.buddy_search}
                                            aria-label="Search"
                                        />
                                    </div>
                                </div>

                                <div class="memory-share-reservation-add-slider">
                                    <Slider {...buddiesSettings} >
                                        {buddies.map(buddy => (
                                            <div key={buddy.id}>
                                                <div class="memory-share-reservation-add-buddy-frame" >
                                                    <div
                                                        class=""
                                                    >
                                                        {buddy.profile_picture ?
                                                            <img
                                                                class="memory-share-reservation-add-buddy"
                                                                src={buddy.profile_picture} alt=""
                                                            />
                                                            :
                                                            <img
                                                                class="memory-share-reservation-add-buddy"
                                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                    <div class="memory-share-reservation-add-buddy-username">
                                                        {buddy.username}
                                                    </div>
                                                </div>
                                                {buddy.user === this.props.data.hub_reservation_user ?
                                                    <div
                                                        class="memory-share-reservation-added-tag-btn"
                                                    >
                                                        Creator
                                                    </div>
                                                    : shared_with_ids.includes(buddy.user) ?
                                                        <div
                                                            class="memory-share-reservation-added-tag-btn"
                                                        >
                                                            Shared
                                                        </div>
                                                        :
                                                        <>
                                                            <div
                                                                // onTouchStart
                                                                onClick={() => this.sumbitHubReservationShare(buddy.user, buddy.id)}
                                                                class="memory-share-reservation-add-tag-btn"
                                                            >
                                                                Share
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        ))}
                                    </Slider>
                                </div>

                                <div
                                    onClick={() => this.closeShareReservationMode()}
                                    class="add-tag-back-icon">
                                    <img
                                        class="add-tag-back-img"
                                        src={require("../../../assets/layout/homepage svg/back arrow.svg")}
                                        alt=""
                                    />
                                </div>
                            </>

                            :

                            <div class="memory-share-reservation-slider">
                                <Slider {...settings} >
                                    < div class="memory-share-reservation-item" >
                                        <div class="row memory-share-reservation-single-item">
                                            <div class="memory-share-reservation-user-info">
                                                <Link to={{ pathname: `/user-profile/${this.props.data.hub_reservation_user}/profile`, state: { user: this.props.data.hub_reservation_user } }}>
                                                    {this.props.data.hub_reservation_creator_profile_pic ?
                                                        <img class="memory-share-reservation-user-picture" src={this.props.data.hub_reservation_creator_profile_pic} alt="" />
                                                        :
                                                        <img class="memory-share-reservation-user-picture" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                    }
                                                </Link>
                                            </div>
                                            <div class="memory-share-reservation-info">
                                                <div class="memory-share-reservation-info-layout">
                                                    <Link to={{ pathname: `/user-profile/${this.props.data.hub_reservation_user}/profile`, state: { user: this.props.data.hub_reservation_user } }}>
                                                        <>
                                                            <p class="memory-share-reservation-text">
                                                                {this.props.data.hub_reservation_creator_username}
                                                            </p>
                                                            <p class="memory-share-reservation-small-text">
                                                                Creator
                                                            </p>
                                                        </>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    {this.props.data.hub_reservation_shares.map(buddy => (
                                        < div class="memory-share-reservation-item" >
                                            <div class="row memory-share-reservation-single-item">
                                                <div class="memory-share-reservation-user-info">
                                                    <Link to={{ pathname: `/user-profile/${buddy.receiving_sharing_reservation}/profile`, state: { user: buddy.receiving_sharing_reservation } }}>
                                                        {buddy.receiver_profile_picture ?
                                                            <img class="memory-share-reservation-user-picture" src={buddy.receiver_profile_picture} alt="" />
                                                            :
                                                            <img class="memory-share-reservation-user-picture" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                        }
                                                    </Link>
                                                </div>
                                                <div class="memory-share-reservation-info">
                                                    <div class="memory-share-reservation-info-layout">
                                                        <Link to={{ pathname: `/user-profile/${buddy.receiving_sharing_reservation}/profile`, state: { user: buddy.receiving_sharing_reservation } }}>
                                                            <>
                                                                <p class="memory-share-reservation-text">
                                                                    {buddy.receiver_username}
                                                                </p>
                                                                <p class="memory-share-reservation-small-text">
                                                                    Shared by

                                                                    {user === buddy.sharing_user ?
                                                                        <> you</>
                                                                        :
                                                                        <> {buddy.sharing_username}</>
                                                                    }
                                                                </p>
                                                            </>




                                                        </Link>


                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>

                                    ))}
                                    < div class="memory-share-reservation-item" >
                                        <div class="row memory-share-reservation-single-item">
                                            <div class="memory-share-reservation-user-info">

                                            </div>
                                            <div class="memory-share-reservation-info">

                                            </div>
                                        </div>

                                    </div>


                                </Slider>


                                <div
                                    onClick={() => this.shareReservationMode()}
                                    class="row"
                                >
                                    <div class="memory-share-reservation-action-icon">
                                        <img class="memory-share-reservation-action-img" src={require("../../../assets/memories/svg/add.svg")} alt="" />
                                    </div>
                                    <div class="memory-share-reservation-action-text">
                                        Share the Reservation
                                    </div>
                                </div>

                            </div>

                        }


                    </div>
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                    </div>
                </div>
            </div >


            // <div class="post-hubs-modal-position">
            //     <div class="row">
            //         <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
            //         <div class="share-reservation-modal-box">

            //             <div class="row text-right">
            //                 <div class="col-xl-12 text-right">
            //                     <div
            //                         onClick={() => this.closeModal()}
            //                         class="share-reservation-times-frame">
            //                         <img class="share-reservation-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
            //                     </div>
            //                 </div>
            //             </div>
            //             <div class="">
            //                 <div class="row">
            //                     <div class="col-xl-12 text-left share-reservation-title-size">
            //                         <h4><strong class="share-reservation-title">Share the Reservation with your buddies that will participate!</strong></h4>
            //                     </div>
            //                 </div>
            //                 <div className="share-reservation-search">
            //                     <div className="form-inline w-100">
            //                         <input
            //                             className="share-reservation-search form-control mr-sm-2 w-100 py-4"
            //                             type="search"
            //                             name="buddy_search"
            //                             onChange={this.onChange}
            //                             placeholder="Search Buddy"
            //                             value={this.state.buddy_search}
            //                             aria-label="Search"
            //                         />
            //                     </div>
            //                 </div>
            //                 <div class="share-reservation-slider-layout">
            //                     {this.props.data.buddies.length === 0 ?
            //                         <div>
            //                             <Slider {...settingsForeverAlone} >
            //                                 <div class="" >
            //                                     <div class="share-reservation-modal-frame">
            //                                         <Link to='/search-buddies'>
            //                                             <img
            //                                                 class="share-forever-alone-img"
            //                                                 src={require("../../../assets/layout/mika/forever_alone.png")}
            //                                                 alt=""
            //                                             />
            //                                         </Link>
            //                                     </div>
            //                                 </div>
            //                                 <div class="" >
            //                                     <div class="share-reservation-modal-frame">
            //                                         <Link to='/search-buddies'>
            //                                             <img
            //                                                 class="share-forever-alone-img"
            //                                                 src={require("../../../assets/layout/mika/forever_alone.png")}
            //                                                 alt=""
            //                                             />
            //                                         </Link>
            //                                     </div>
            //                                 </div>
            //                                 <div class="" >
            //                                     <div class="share-reservation-modal-frame">
            //                                         <Link to='/search-buddies'>
            //                                             <img
            //                                                 class="share-forever-alone-img"
            //                                                 src={require("../../../assets/layout/mika/forever_alone.png")}
            //                                                 alt=""
            //                                             />
            //                                         </Link>
            //                                     </div>
            //                                 </div>
            //                                 <div class="" >
            //                                     <div class="share-reservation-modal-frame">
            //                                         <Link to='/search-buddies'>
            //                                             <img
            //                                                 class="share-forever-alone-img"
            //                                                 src={require("../../../assets/layout/mika/forever_alone.png")}
            //                                                 alt=""
            //                                             />
            //                                         </Link>
            //                                     </div>
            //                                 </div>
            //                             </Slider>
            //                             <p class="share-forever-alone-text">Don't be this guy. <br /> <Link to='/search-buddies'>Add your Buddies</Link></p>

            //                         </div>
            //                         :
            //                         <Slider {...settings} >
            //                             {this.props.data.buddies.map(buddy => (
            //                                 <div key={buddy.id}>
            //                                     <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
            //                                         <div
            //                                             // onClick={this.handleHubChange(hub.id)}
            //                                             class="share-reservation-modal-frame"
            //                                         >
            //                                             {buddy.profile_picture ?
            //                                                 <img
            //                                                     class={this.state.shared_with.includes(buddy.id) ? "share-reservation-sellected-logo" : "share-reservation-modal-logo"}
            //                                                     src={buddy.profile_picture}
            //                                                     alt=""
            //                                                 />
            //                                                 :
            //                                                 <img
            //                                                     class={this.state.shared_with.includes(buddy.id) ? "share-reservation-sellected-logo" : "share-reservation-modal-logo"}
            //                                                     src={require("../../../assets/authentication/svg/account.svg")}
            //                                                     alt=""
            //                                                 />
            //                                             }
            //                                             <h4><strong class="share-reservation-username">{buddy.username}</strong></h4>
            //                                         </div>
            //                                     </div>
            //                                     {this.state.shared_with.includes(buddy.id) ?
            //                                         <div
            //                                             class="share-reservation-btn-light" >
            //                                             Shared
            //                                         </div>
            //                                         : shared_with_ids.includes(buddy.user) ?
            //                                             <div
            //                                                 class="share-reservation-btn-light" >
            //                                                 Shared
            //                                             </div>
            //                                             :
            //                                             <span onClick={this.sumbitHubReservationShare(buddy.user, buddy.id)}>
            //                                                 <div
            //                                                     // onClick={this.updateSharedWithList(buddy.id)}
            //                                                     class="share-reservation-btn-danger" >
            //                                                     Share
            //                                                 </div>
            //                                             </span>
            //                                     }
            //                                 </div>


            //                             ))}
            //                         </Slider>
            //                     }
            //                 </div>
            //             </div>

            //         </div>
            //         <div class="col-lg-3 col-md-1 col-sm-2 col-2">
            //         </div>
            //     </div>
            // </div>




        )
    }
}

const mapStateToProps = state => ({
    hub_reservation_shares: state.reservation.hub_reservation_shares
});

export default connect(mapStateToProps, { getHubReservtionShares })(ShareReservationModal);