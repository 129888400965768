import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { getUserProfile, getProfileHubList } from '../../../store/actions/profile';
import { getBuddies, removeBuddy, answerBuddyRequest, cancelBuddyRequest, sendBuddyRequest } from '../../../store/actions/buddies';
import {
    getUserMemories,
    createMemoryReaction,
    deleteReaction,
    createMemoryView,
    getMemoryTags,
    clearMemoryTags,
} from '../../../store/actions/memory';
import { getNotificationsStatus } from '../../../store/actions/notification';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import BuddiesTab from '../../../components/user/buddys-profile/buddies';
import MemoriesTab from '../../../components/user/profile/memories';
import HubsTab from '../../../components/user/profile/hubs';
import InfoTab from '../../../components/user/buddys-profile/info';
import "../../../assets/profile/my-profile.css"
import { checkUserIsAmbassandor } from '../../../store/actions/ambassandor';
import Modal from 'react-awesome-modal';
import { ProfilePictureModal } from '../../../components/user/buddys-profile/ProfilePictureModal';
import { BuddyRelationModal } from '../../../components/user/buddys-profile/BuddyRelationModal';
import SplashTab from '../../../components/user/profile/SplashTab';
import Stories from 'react-insta-stories';
import moment from "moment-timezone";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class MyProfile extends Component {

    state = {
        memoriesTab: true,
        personalInfoTab: false,
        hubsTab: false,
        buddiesTab: false,
        send_buddyrequests: [],
        answered_request: "",
        cancelled_request: false,
        profilePicModalVisible: false,
        relationModal: false,
        ambassandors_mode: false,
        showstories: false,
        one_person: true,
        memories_user_id: "",
        story_memory_id: "",
        next_user_memory: true,
        story_index: 0,
        is_story_paused: null,
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem('ur'))) {
            this.props.getUserMemories(this.props.match.params.profileID);
        }

        if (this.props.match.params.splash === "splash") {
            this.setState({
                ambassandors_mode: true,
                memoriesTab: false,
                personalInfoTab: false,
                hubsTab: false,
                buddiesTab: false,
            })
        }


        this.props.getUserProfile(this.props.match.params.profileID);

        // this.props.getBuddies(this.props.match.params.profileID);
        // this.props.getProfileHubList(this.props.match.params.profileID)
        this.props.checkUserIsAmbassandor(this.props.match.params.profileID)
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    buddyRequestSubmit(user) {
        const sending_user = JSON.parse(localStorage.getItem('ur')).id
        const recieving_user = user
        const status = "Send"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.sendBuddyRequest(buddyrequest);
    }

    cancelBuddyRequest(user) {
        const sending_user = JSON.parse(localStorage.getItem('ur')).id
        const recieving_user = user
        const status = "Cancelled"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.cancelBuddyRequest(buddyrequest);
    }

    updateBuddyrequestList = (user) => (e) => {
        e.preventDefault();
        const old_list = [...this.state.send_buddyrequests]
        if ((old_list.indexOf(user)) !== -1) {
            const new_remove = old_list.filter(f => f !== user)
            this.setState({ send_buddyrequests: new_remove })
            this.cancelBuddyRequest(user)
        }
        else {
            old_list.push(user)
            this.setState({ send_buddyrequests: old_list })
            this.setState({ cancelled_request: false })
            this.buddyRequestSubmit(user)
        }
    }


    updateCancelBuddyrequestList = (user) => (e) => {
        this.setState({ cancelled_request: true })
        this.cancelBuddyRequest(user)
    }


    changeMemoriesMode = () => {
        this.setState({
            memoriesTab: true,
            personalInfoTab: false,
            hubsTab: false,
            buddiesTab: false,
            ambassandors_mode: false,
        })
    }

    changeHubsMode = () => {
        this.setState({
            memoriesTab: false,
            personalInfoTab: false,
            hubsTab: true,
            buddiesTab: false,
            ambassandors_mode: false,
        })
    }

    changeBuddiesMode = () => {
        this.setState({
            memoriesTab: false,
            personalInfoTab: false,
            hubsTab: false,
            buddiesTab: true,
            ambassandors_mode: false,
        })
    }

    changePersonalInfoMode = () => {
        this.setState({
            memoriesTab: false,
            personalInfoTab: true,
            hubsTab: false,
            buddiesTab: false,
            ambassandors_mode: false,
        })
    }

    acceptBuddyRequest = (user) => (e) => {
        this.setState({ answered_request: user })
        const sending_user = user
        const recieving_user = JSON.parse(localStorage.getItem('ur')).id
        const status = "Accepted"
        const answer = { sending_user, recieving_user, status };
        this.props.answerBuddyRequest(answer);
        window.location.reload(false);
    }

    declineBuddyRequest = (user) => (e) => {
        this.setState({ answered_request: user })
        const sending_user = user
        const recieving_user = JSON.parse(localStorage.getItem('ur')).id
        const status = "Declined"
        const answer = { sending_user, recieving_user, status };
        this.props.answerBuddyRequest(answer);
        window.location.reload(false);
    }

    removeBuddy = (user) => (e) => {
        this.setState({ answered_request: user })
        const sending_user = user
        const recieving_user = JSON.parse(localStorage.getItem('ur')).id
        const status = "Declined"
        const answer = { sending_user, recieving_user, status };
        this.props.removeBuddy(answer);
        window.location.reload(false);
    }

    linkToAnotherProfile = (e, id) => {
        setTimeout(() => {
            this.closeModal()
        }, 1000);
        setTimeout(() => {
            this.props.getUserProfile(this.props.match.params.profileID);
            this.props.getUserMemories(this.props.match.params.profileID);
            this.props.getBuddies(this.props.match.params.profileID);
            this.props.getProfileHubList(this.props.match.params.profileID)
        }, 1500);
        this.setState({
            memoriesTab: true,
            personalInfoTab: false,
            hubsTab: false,
            buddiesTab: false,
        })
    }


    profilePictureFullSize = () => {
        this.setState({
            profilePicModalVisible: true,
        })
    }

    openRelationModal() {
        this.setState({
            relationModal: true,
        })
    }

    closeModal = () => {
        this.setState({
            profilePicModalVisible: false,
            relationModal: false,
            showstories: false,
            one_person: true,
            memories_user_id: "",
            story_memory_id: "",
        })
        this.props.clearMemoryTags()
    }




    openStoriesModal(user, one_person, story_memory_id) {
        this.setState({
            showstories: true,
            one_person: one_person,
            memories_user_id: user,
            story_memory_id: story_memory_id
        });
    }

    onTouchStart(touchStartEvent) {
        this.setState({
            is_story_paused: true,
            start_swipe_x: touchStartEvent.targetTouches[0].clientX,
            start_swipe_y: touchStartEvent.targetTouches[0].clientY,
        })
    }


    onTouchMove(touchMoveEvent) {
        this.setState({
            swipe_extend_x: touchMoveEvent.targetTouches[0].clientX,
            swipe_extend_y: touchMoveEvent.targetTouches[0].clientY
        })
    }


    onTouchEnd() {
        this.setState({
            is_story_paused: false,
        })
        if (this.state.swipe_extend_x > 100 || this.state.swipe_extend_y > 100) {
            if (this.state.start_swipe_x - this.state.swipe_extend_x < -50) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {
                    // console.log("swiped right")
                    const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index - 1
                    if (new_index > -1) {
                        if (this.props.memories.length > new_index) {
                            this.setState({
                                next_user_memory: false,
                                memories_user_id: this.props.memories[new_index].user,
                                story_index: 0,
                            });

                            setTimeout(() => {
                                this.setState({
                                    next_user_memory: true,
                                });
                            }, 100);
                        }
                    } else {
                        this.closeModal()
                    }
                    this.closeStoryShowMore()
                }
            }


            if (this.state.start_swipe_x - this.state.swipe_extend_x > 100) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {

                    // console.log("swiped left")
                    const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index + 1

                    if (this.props.memories.length > new_index) {
                        this.setState({
                            next_user_memory: false,
                            memories_user_id: this.props.memories[new_index].user,
                            story_index: 0,
                        });

                        setTimeout(() => {
                            this.setState({
                                next_user_memory: true,
                            });
                        }, 100);
                    } else {
                        this.closeModal()
                    }
                    this.closeStoryShowMore()
                }
            }

            if (this.state.start_swipe_y - this.state.swipe_extend_y < -100) {
                // console.log("swiped down")
                if (this.state.storyShowMore) {
                    this.props.clearMemoryTags()
                    this.setState({
                        storyShowMore: false,
                        is_story_paused: false,
                    })
                } else {
                    this.closeModal()
                }

            }

            // console.log(this.state.start_swipe_y - this.state.swipe_extend_y)
            if (this.state.start_swipe_y - this.state.swipe_extend_y > 100) {
                // console.log("swiped up")
                this.props.getMemoryTags(this.state.story_memory_id)
                this.setState({
                    storyShowMore: true,
                    isStoryPaused: true,
                    is_story_paused: true,
                })
            }
        } else {
            const half_width = window.screen.width / 2
            if (this.state.start_swipe_x < half_width) {
                const memory_stories = this.props.memories
                const new_index = this.state.story_index - 1
                if (new_index > -1) {
                    this.setState({
                        story_memory_id: memory_stories[new_index].id
                    })
                }
            } else {
                const memory_stories = this.props.memories
                const new_index = this.state.story_index + 1
                if (memory_stories.length === new_index) {
                    this.setState({
                        showstories: false,
                    })
                    this.setState({
                        modalVisible: false,
                        memorySettingsModalVisible: false,
                        hubAccessModal: false,
                        showstories: false,
                        one_person: true,
                        memories_user_id: "",
                        story_memory_id: "",
                    });

                }

                if (memory_stories.length !== new_index) {
                    this.setState({
                        story_memory_id: memory_stories[new_index].id
                    })
                }

            }
            this.closeStoryShowMore()
        }

        this.setState({
            start_swipe_x: "",
            start_swipe_y: "",
            swipe_extend_x: "",
            swipe_extend_y: "",
        })
    }

    openStoryShowMore(memory_id) {
        this.props.getMemoryTags(this.state.story_memory_id)
        this.setState({
            storyShowMore: true,
            is_story_paused: true,
            memory_show_more_id: memory_id
        });
    }

    storyStarted = (e, story_data) => {
        // if (id) {
        this.setState({
            // displayed_memory_id: id.id,
            story_index: e,
            is_story_paused: false,
        })
        setTimeout(() => {
            if (story_data) {
                const memory = story_data.memory_id
                const view = { memory }
                this.props.createMemoryView(view)
            }
        }, 100);

        // }
    }

    userStoriesEnded = e => {
        this.setState({
            story_index: 0,
            is_story_paused: false,
        })
        if (this.state.one_person) {
            this.closeModal()
            this.setState({
                streetViewModal: false
            })
        } else {
            const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
            const new_index = index + 1


            if (this.props.memories.length > new_index) {
                this.setState({
                    next_user_memory: false,
                    memories_user_id: this.props.memories[new_index].user
                });

                setTimeout(() => {
                    this.setState({
                        next_user_memory: true,
                    });
                }, 100);
            } else {
                this.closeModal()
                this.setState({
                    streetViewModal: false
                })
            }
        }
    }

    closeStoryShowMore() {
        this.props.clearMemoryTags()
        this.setState({
            storyShowMore: false,
            is_story_paused: false,
        })
    }

    updateReactionSelection(selected_reaction, memory_id) {
        this.setState({
            reaction_sellected: true,
            reaction: selected_reaction

        })
        const reacted_memory_id = memory_id
        const reaction_status = selected_reaction
        const reaction = { reaction_status, reacted_memory_id }
        this.props.createMemoryReaction(reaction)
        setTimeout(() => {
            if (JSON.parse(localStorage.getItem('ur'))) {
                this.props.getUserMemories(this.props.match.params.profileID);
            }
        }, 600);

        setTimeout(() => {
            this.setState({
                reaction_sellected: false,
                show_reaction: true,
            })
        }, 1000);
    }

    deleteMikaImpression(memory_id) {
        // const memory_animation_id_list = [...this.state.memory_animation_id]
        // if ((memory_animation_id_list.indexOf(memory_id)) !== -1) {
        //     const new_remove = memory_animation_id_list.filter(f => f !== memory_id)
        //     this.setState({
        //         memory_animation_id: new_remove,
        //     })
        // }
        // setTimeout(() => {


        this.props.deleteReaction(memory_id)
        setTimeout(() => {
            if (JSON.parse(localStorage.getItem('ur'))) {
                this.props.getUserMemories(this.props.match.params.profileID);
            }
        }, 600);


        // }, 500);
    }

    render() {
        let ambassandor = this.props.ambassandor
        if (ambassandor) {
            ambassandor = ambassandor[0]
        }
        const profile = this.props.user_profile;

        if (JSON.parse(localStorage.getItem('ur'))) {
            if (profile[0]) {
                const user_profile_id = profile[0].id
                const my_profile = JSON.parse(localStorage.getItem('urpfl'))
                const my_profile_id = my_profile[0].id
                if (user_profile_id === my_profile_id) {
                    return <Redirect to="/my-profile" />
                }
            }
        }

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const memoryTagsSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };


        const screen_width = window.screen.width
        const screen_height = window.screen.height

        let stories = []
        let rendred = false
        const sellected_user_memories = this.props.memories
        let memories_displayed = []

        if (sellected_user_memories.length > 0 && this.state.next_user_memory && profile[0]) {
            const config = {
                delta: 10,
            }
            rendred = true
            sellected_user_memories.map(memory => {
                memories_displayed.push(memory.id)
                let time = moment(memory.timestamp).fromNow()
                const is_story_paused = this.state.is_story_paused
                const story =
                {
                    duration: 10000,
                    memory_user: memory.user,
                    memory_id: memory.id,
                    content: ({ action, isPaused }) => {

                        const pauseStory = () => { action("pause") }
                        if (is_story_paused) {
                            action("pause")
                        }
                        return (
                            <>
                                {is_story_paused ?
                                    <span onClick={pauseStory()}></span>
                                    :
                                    null
                                }
                                <div style={{ background: 'pink' }}>
                                    <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                        <div class="prolfie-story-profile-pic-box">
                                            <img
                                                class="prolfie-story-profile-pic"
                                                src={profile[0].profile_picture}
                                                alt=""
                                            />
                                        </div>
                                    </Link>
                                    <div class="prolfie-story-top-text">
                                        {/* <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}> */}
                                        <div class="prolfie-story-top-heading">
                                            <b>{profile[0].username}</b>
                                            {memory.has_tags ?
                                                <span class="prolfie-story-top-heading-tags">
                                                    {memory.tags.length === 2 ?
                                                        <>
                                                            &nbsp;with&nbsp; <b> {memory.tagged_user_username} </b>
                                                        </>
                                                        :
                                                        <>
                                                            {memory.tagged_user_username.length > 14 && memory.user_username.length > 14 ?

                                                                <span
                                                                    class="prolfie-story-top-heading-tags"
                                                                // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                >
                                                                    &nbsp;and&nbsp;
                                                                    <b>{memory.tags.length - 1}&nbsp;others</b>
                                                                </span>

                                                                :
                                                                <span class="prolfie-story-top-heading-tags">
                                                                    &nbsp;with&nbsp;<b>
                                                                        {memory.tagged_user_username}
                                                                    </b>
                                                                    {/* <br /> */}
                                                                    &nbsp;and&nbsp;
                                                                    <span
                                                                        class="prolfie-story-top-heading-tags"
                                                                    // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                    >
                                                                        <b>{memory.tags.length - 2}&nbsp;others</b>
                                                                    </span>
                                                                </span>
                                                            }
                                                        </>
                                                    }

                                                </span>
                                                :
                                                null
                                            }
                                        </div>
                                        {/* </Link> */}
                                        <div class="prolfie-story-top-subheading1">
                                            {memory.hub_name}
                                        </div>
                                        <div class="prolfie-story-top-subheading">
                                            {moment(memory.timestamp).fromNow()}
                                        </div>
                                    </div>


                                    <div class="prolfie-story-photo">
                                        <img class="prolfie-story-photo-size" src={memory.photo} />
                                    </div>

                                    {this.state.reaction_sellected ?
                                        <>
                                            {this.state.reaction === "love" ?
                                                <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                    <img
                                                        class="memory-mika-animation-size"
                                                        // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                        src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </>
                        );
                    },
                    url: memory.photo,
                }
                stories.push(story)
            })
        }

        const index = sellected_user_memories.findIndex(x => x.id === this.state.story_memory_id);


        return (
            <Fragment>


                {this.state.showstories ?
                    <div class="prolfie-story-all-black">

                    </div>
                    :
                    null
                }

                {rendred ?
                    <>
                        {this.state.showstories ?
                            <Modal visible={this.state.showstories} width="100%" height="100%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <>
                                    <div class="prolfie-story-index">
                                        <div onClick={() => this.closeModal()} class="prolfie-story-times-box">
                                            <img
                                                onClick={() => this.closeModal()}
                                                class="prolfie-story-times-img"
                                                src={require("../../../assets/map/svg/story-times.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="prolfie-memories-full-box"
                                        >


                                            <Stories
                                                // preventDefault={true}
                                                stories={stories}
                                                defaultInterval={1500}
                                                width={screen_width}
                                                height={screen_height}
                                                currentIndex={index}
                                                // isPaused={True}
                                                onStoryStart={(e, id) => this.storyStarted(e, id)}
                                                onStoryEnd={e => this.storyStarted(e)}
                                                onAllStoriesEnd={e => this.userStoriesEnded(e)}
                                            />

                                        </div>

                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="all-page-touches-invisible-box">

                                        </div>

                                        {this.state.storyShowMore ?
                                            <>
                                                {sellected_user_memories.length > 0 ?
                                                    <div class="prolfie-show-more-modal" >
                                                        {sellected_user_memories[this.state.story_index].has_tags ?
                                                            <div class="map-search-show-more-tags-box">
                                                                <Slider {...memoryTagsSettings} >
                                                                    {this.props.tags.map(tag => (
                                                                        // <div class="map-search-show-more-tag-buddy-box">
                                                                        <Link onClick={this.linkToAnotherProfile} class="map-search-show-more-tag-buddy-box" to={{ pathname: `/user-profile/${tag.user}/profile`, state: { user: tag.user } }}>
                                                                            <div class="map-search-show-more-tag-profile-box">
                                                                                {tag.profile_picture ?
                                                                                    < img
                                                                                        class="map-search-show-more-tag-profile-img"
                                                                                        src={tag.profile_picture}
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        class="map-search-show-more-tag-profile-img"
                                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div class="map-search-show-more-tag-username">
                                                                                {tag.username}
                                                                            </div>
                                                                        </Link>
                                                                    ))}
                                                                </Slider>
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                        {sellected_user_memories[this.state.story_index].hub_logo ?
                                                            <div class="prolfie-show-more-single-box">
                                                                <Link to={{ pathname: '/hub', state: { hub_id: sellected_user_memories[this.state.story_index].hub_id } }}>
                                                                    <div class="row prolfie-show-more-single-row">
                                                                        <div class="prolfie-show-more-image">
                                                                            < img
                                                                                class="prolfie-show-more-img"
                                                                                src={sellected_user_memories[this.state.story_index].hub_logo}
                                                                            />
                                                                        </div>
                                                                        {sellected_user_memories[this.state.story_index].hub_name.length > 18 ?
                                                                            <>
                                                                                {sellected_user_memories[this.state.story_index].hub_name.slice(0, 17) + "..."}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {sellected_user_memories[this.state.story_index].hub_name}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            :
                                                            <div class="prolfie-show-more-single-box">
                                                                <Link to={{ pathname: '/hub', state: { hub_id: sellected_user_memories[this.state.story_index].hub_id } }}>
                                                                    {sellected_user_memories[this.state.story_index].hub_name.length > 25 ?
                                                                        <>
                                                                            {sellected_user_memories[this.state.story_index].hub_name.slice(0, 24) + "..."}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {sellected_user_memories[this.state.story_index].hub_name}
                                                                        </>
                                                                    }
                                                                </Link>
                                                            </div>
                                                        }

                                                        {/* {sellected_user_memories[this.state.story_index].user_profile_picture ?
                                                            <div class="prolfie-show-more-single-box">
                                                                <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                                                    <div class="row prolfie-show-more-single-row">
                                                                        <div class="prolfie-show-more-image">
                                                                            < img
                                                                                class="prolfie-show-more-img"
                                                                                src={sellected_user_memories[this.state.story_index].user_profile_picture}
                                                                            />
                                                                        </div>

                                                                        {sellected_user_memories[this.state.story_index].user_username}`s Profile

                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            :
                                                            <div class="prolfie-show-more-single-box">
                                                                <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                                                    {sellected_user_memories[this.state.story_index].user_username}`s Profile
                                                                </Link>
                                                            </div>
                                                        } */}
                                                        <Link to={{ pathname: '/memory-impressions', state: { memory: sellected_user_memories[this.state.story_index].id } }}>
                                                            <div class="prolfie-show-more-single-box">
                                                                {sellected_user_memories[this.state.story_index].reactions_number} reactions
                                                            </div>
                                                        </Link>
                                                        <Link to={{
                                                            pathname: "/conversation-room",
                                                            state: {
                                                                selected_user: sellected_user_memories[this.state.story_index].user,
                                                                selected_user_profile_pic: sellected_user_memories[this.state.story_index].user_profile_picture,
                                                                selected_user_username: sellected_user_memories[this.state.story_index].user_username
                                                            }
                                                        }}
                                                        >
                                                            <div class="prolfie-show-more-single-box">
                                                                Send Message
                                                            </div>
                                                        </Link>
                                                        <div onClick={() => this.closeStoryShowMore()} class="prolfie-show-more-x-box">
                                                            x
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </>
                                            :
                                            <div onClick={() => this.openStoryShowMore()}
                                                class="prolfie-story-swipe-up">
                                                ⌃<br />
                                                Swipe for More
                                            </div>
                                        }


                                        {sellected_user_memories.length > 0 ?
                                            <div class="prolfie-story-bottom-row">
                                                {sellected_user_memories[this.state.story_index].memory_reaction !== null ?
                                                    <div onClick={() => this.deleteMikaImpression(sellected_user_memories[this.state.story_index].id)} class="prolfie-story-reactions-xo">
                                                        < img
                                                            class="prolfie-story-reactions-img"
                                                            src={require("../../../assets/layout/homepage svg/love-red.svg")}
                                                        />
                                                    </div>
                                                    :
                                                    <div onClick={() => this.updateReactionSelection("love", sellected_user_memories[this.state.story_index].id)} class="prolfie-story-reactions-xo">
                                                        < img
                                                            class="prolfie-story-reactions-img"
                                                            src={require("../../../assets/layout/homepage svg/white-love.svg")}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </>

                            </Modal>

                            :
                            null
                        }
                    </>
                    :
                    <></>
                }



                {this.state.profilePicModalVisible ?
                    <Modal visible={this.state.profilePicModalVisible} width="100" height="100" effect="fadeInRight" onClickAway={() => this.closeModal()}>
                        <ProfilePictureModal
                            data={{
                                profile_picture: profile[0].profile_picture,
                                closeModal: this.closeModal.bind(this),
                            }}
                        />
                    </Modal>
                    :
                    null
                }

                {this.state.relationModal ?
                    <Modal visible={this.state.relationModal} width="10" height="10" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <BuddyRelationModal
                            data={{
                                buddy: profile[0],
                                removeBuddy: this.removeBuddy.bind(this),
                                closeModal: this.closeModal.bind(this),
                            }}
                        />
                    </Modal>
                    :
                    null
                }


                {/* <div class={this.props.isLoading ? "profile-lottie-appear" : "profile-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <DesktopNavbar data={{ profile: this.props.profile }} />

                {/* <div class={this.props.isLoading ? "profile-section-disappear" : null}> */}

                {this.state.showstories ?
                    null
                    :
                    <>

                        <div>
                            <div id="page-scroll-up" class="profile-whole-page-layout">
                                <OfflineLabel />
                                <div class="color-5">
                                    {profile.map(profile => (
                                        <div class="container-fluid ">
                                            <div class="row">

                                                <div id="page-scroll-up" class="profiles-mobile-navbar">
                                                    <div onClick={() => this.handleBack()} class="back-button-user-profil-box">
                                                        <img
                                                            class="user-profil-back-button"

                                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    {profile.username}

                                                </div>


                                                {/* <div class="container-fluid small-header-5">
                                            <div class="row py-4">
                                                <div class="">
                                                    <img
                                                        class="user-profile-back-button"
                                                        onClick={this.handleBack}
                                                        src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="profile-buddy-top-username-navbar">
                                                    {profile.username}
                                                </div>
                                            </div>
                                        </div> */}
                                                <div class="user-profil-all-body">
                                                    <div class="body-right-5 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                        <div class="row">
                                                            <div class="profile-top-all-info">
                                                                <div class="profile-top-profile-pic-box">
                                                                    {this.state.photo ?
                                                                        <img
                                                                            class="profile-top-profile-picture-photo"
                                                                            src={this.state.photo}
                                                                            onClick={() => this.fileInput.click()}
                                                                            alt=""
                                                                        />
                                                                        :
                                                                        <span>
                                                                            {profile.profile_picture ?
                                                                                <img
                                                                                    onClick={() => this.profilePictureFullSize()}
                                                                                    class="profile-top-profile-picture-photo"
                                                                                    src={profile.profile_picture}
                                                                                    alt=""
                                                                                />
                                                                                :
                                                                                <img
                                                                                    onClick={() => this.profilePictureFullSize()}
                                                                                    class="profile-top-profile-picture-photo"
                                                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                                                    alt=""
                                                                                />
                                                                            }
                                                                        </span>
                                                                    }

                                                                </div>
                                                            </div>

                                                            <div class="pofile-top-box-nubinfo">

                                                                <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                                                    {/* <div onClick={this.changeMemoriesMode()} class="pofile-top-box-info-1"> */}

                                                                    <div onClick={() => this.changeMemoriesMode()} class="pofile-top-box-info-1">
                                                                        <div class="pofile-top-box-number">
                                                                            {profile.memories_number}
                                                                        </div>
                                                                        <div class="pofile-top-box-info-name">
                                                                            Memories
                                                                        </div>
                                                                    </div>
                                                                </ScrollLink>

                                                                <Link to={{ pathname: `/show-profile-buddies/${profile.user}`, state: { profile_username: profile.username } }}>
                                                                    <div class="pofile-top-box-info-2">
                                                                        <div class="pofile-top-box-number">
                                                                            {profile.buddies_number}
                                                                        </div>

                                                                        <div class="pofile-top-box-info-name">
                                                                            Buddies
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>


                                                            <div class="profile-top-username-and-fullname">
                                                                {profile.full_name === "null" ?
                                                                    null
                                                                    : profile.full_name === null ?
                                                                        null
                                                                        : profile.full_name === "" ?
                                                                            null
                                                                            : profile.full_name === "undefined" ?
                                                                                null
                                                                                :
                                                                                <h7><b>{profile.full_name}</b></h7>
                                                                }
                                                            </div>

                                                            <div class="profile-top-bio-text">
                                                                {profile.bio === "null" ?
                                                                    null
                                                                    : profile.bio === null ?
                                                                        null
                                                                        : profile.bio === "" ?
                                                                            null
                                                                            : profile.bio === "undefined" ?
                                                                                null
                                                                                :
                                                                                <h9>{profile.bio}</h9>
                                                                }
                                                            </div>







                                                            {/* {this.state.ambassandors_mode ?
                                                        <>
                                                            {JSON.parse(localStorage.getItem('ur')) ?
                                                                null
                                                                :
                                                                <div class="profile-top-request-buddy" >
                                                                    <Link to="/">Request</Link>
                                                                </div>
                                                            }
                                                        </>
                                                        : */}
                                                            <>
                                                                {!JSON.parse(localStorage.getItem('ur')) ?
                                                                    <div class="profile-top-buddy-profile-btn" >
                                                                        <Link to="/">
                                                                            Join Hubst3r Now
                                                                        </Link>
                                                                    </div>
                                                                    : this.state.answered_request === profile.user ?
                                                                        null
                                                                        : profile.is_buddy === "Unanswered" ?
                                                                            <div class="profile-top-two-btns">
                                                                                <div
                                                                                    onClick={this.acceptBuddyRequest(profile.user)}
                                                                                    class="profile-top-two-btns-accept">
                                                                                    Accept
                                                                                </div>

                                                                                <div
                                                                                    onClick={this.declineBuddyRequest(profile.user)}
                                                                                    class="profile-top-two-btns-decline"
                                                                                >
                                                                                    Decline
                                                                                </div>
                                                                            </div>
                                                                            : this.state.cancelled_request ?
                                                                                <div
                                                                                    onClick={this.updateBuddyrequestList(profile.user)}
                                                                                    class="profile-top-request-buddy" >
                                                                                    Request
                                                                                </div>
                                                                                : profile.is_buddy === "Requested" ?
                                                                                    <div
                                                                                        onClick={this.updateCancelBuddyrequestList(profile.user)}
                                                                                        class="profile-top-edit-profile" >
                                                                                        Requested
                                                                                    </div>
                                                                                    : this.state.send_buddyrequests.includes(profile.user) ?
                                                                                        <div
                                                                                            onClick={this.updateCancelBuddyrequestList(profile.user)}
                                                                                            class="profile-top-edit-profile" >
                                                                                            Requested
                                                                                        </div>
                                                                                        : profile.is_buddy === "buddies" ?
                                                                                            <>
                                                                                                <div
                                                                                                    // onClick={this.removeBuddy(profile.user)}
                                                                                                    onClick={() => this.openRelationModal()}
                                                                                                    class="profile-top-buddy-profile-btn" >
                                                                                                    Buddy
                                                                                                </div>
                                                                                                <Link class="profile-top-buddy-profile-btn" to={{ pathname: "/conversation-room", state: { selected_user: profile.user, selected_user_profile_pic: profile.profile_picture, selected_user_username: profile.username } }}>
                                                                                                    {/* <div > */}
                                                                                                    Message
                                                                                                    {/* </div> */}
                                                                                                </Link>
                                                                                            </>
                                                                                            :
                                                                                            <div
                                                                                                onClick={this.updateBuddyrequestList(profile.user)}
                                                                                                class="profile-top-request-buddy" >
                                                                                                Request
                                                                                            </div>
                                                                }
                                                            </>

                                                            {/* } */}



                                                        </div>


                                                        {this.state.ambassandors_mode ?
                                                            <SplashTab
                                                                // updateProfile={this.props.updateProfile}
                                                                data={{
                                                                    profile: this.props.user_profile[0],
                                                                    ambassandor: this.props.is_ambassandor[0],
                                                                    updateBuddyrequestList: this.updateBuddyrequestList.bind(this),
                                                                    send_buddyrequests: this.state.send_buddyrequests,
                                                                }}
                                                            />
                                                            :
                                                            <>
                                                                {this.state.memoriesTab ?
                                                                    <>
                                                                        <br />
                                                                        <div id="field-to-show" class="my-profile-working-hubs-title">
                                                                            {profile.username}`s Nightlife
                                                                        </div>
                                                                        <hr />
                                                                        <MemoriesTab
                                                                            data={{
                                                                                user: profile.user,
                                                                                memories: this.props.memories,
                                                                                user_username: profile.username,
                                                                                openStoriesModal: this.openStoriesModal.bind(this)

                                                                            }}
                                                                        />

                                                                    </> :
                                                                    null
                                                                }
                                                            </>
                                                        }


                                                    </div>
                                                    <div class="profile-bottom-empty"></div>

                                                </div>
                                            </div>

                                        </div>

                                    ))}
                                </div>
                            </div>
                        </div>
                        <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                            {this.state.relationModal ?
                                null
                                :
                                <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />
                            }
                        </ScrollLink>
                    </>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user_profile: state.profile.user_profile,
    memories: state.memory.memories,
    hubs: state.profile.hubs,
    buddies: state.buddies.buddies,
    is_ambassandor: state.ambassandor.is_ambassandor,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    tags: state.memory.tags,
});

export default connect(mapStateToProps, {
    getUserProfile,
    getUserMemories,
    getBuddies,
    getProfileHubList,
    answerBuddyRequest,
    cancelBuddyRequest,
    sendBuddyRequest,
    getNotificationsStatus,
    checkUserIsAmbassandor,
    removeBuddy,
    createMemoryReaction,
    deleteReaction,
    createMemoryView,
    getMemoryTags,
    clearMemoryTags,
})(MyProfile);