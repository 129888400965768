import React, { useState, useRef } from 'react'
import TinderCard from 'react-tinder-card'


function SwipeProductsFull(props) {
    const db = props.data.user_products

    db.sort(function (a, b) {
        return a.id - b.id;
    });

    const [timesRequested, setTimesRequested] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(db.length - 1)
    const [lastDirection, setLastDirection] = useState()

    const [nextPressed, setNextPressed] = useState(false)
    const [previousPressed, setPreviousPressed] = useState(false)
    const [profilePressed, setProfilePressed] = useState(false)
    const [addPressed, setAddPressed] = useState(false)
    const [cancelPressed, setCancelPressed] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState("")
    const [selectedUser, setSelectedUser] = useState(db[db.length - 1].user)
    const [pressedOpacity, setPressedOpacity] = useState(0)
    const [dataRequested, setDataRequested] = useState(false)

    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex)


    const updateCurrentIndex = (val) => {
        setCurrentIndex(val)
        currentIndexRef.current = val
    }

    const canGoBack = currentIndex < db.length - 1

    const canSwipe = currentIndex >= 0

    // set last direction and decrease current index
    const swiped = (direction, swiped_user, index) => {
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 80);
        setSelectedIndex("")
        setLastDirection(direction)
        updateCurrentIndex(index - 1)
        if (direction === "right") {
            // props.data.updateBuddyrequestList(swiped_user)
            // props.data.buddyRequestSubmit(swiped_user)
        }
        if (direction === "up") {
            window.location = `/user-profile/${swiped_user}/profile`;
        }
    }

    const outOfFrame = (name, idx) => {
        // console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
        // handle the case in which go back is pressed before card goes outOfFrame
        currentIndexRef.current >= idx && db[idx].current.restoreCard()
        if (currentIndexRef.current > -1) {
            setSelectedUser(db[currentIndexRef.current].user)
        } else {
            setSelectedUser("")
        }

        if (currentIndexRef.current === -1) {
            // props.data.getNewBuddies()
            setDataRequested(true)
            setTimeout(() => {
                setDataRequested(false)
                setCurrentIndex(db.length - 1)
                setSelectedUser(db[db.length - 1].user)
            }, 2000);
            setTimeout(() => {
                setCurrentIndex(db.length - 1)
                setSelectedUser(db[db.length - 1].user)
            }, 2500);
        }

        // TODO: when quickly swipe and restore multiple times the same card,
        // it happens multiple outOfFrame events are queued and the card disappear
        // during latest swipes. Only the last outOfFrame event should be considered valid
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 80);
    }

    const swipe = async (dir, swiped_user) => {
        correctLayout(dir)
        if (canSwipe && currentIndex < db.length) {
            await db[currentIndex].current.swipe(dir) // Swipe the card!
            setSelectedIndex("")
            if (currentIndexRef.current > -1) {
                setSelectedUser(db[currentIndexRef.current].user)
            } else {
                setSelectedUser("")
            }

        } else if (currentIndex === -1) {
            await db[db.length - 1].current.swipe(dir) // Swipe the card!
            setSelectedIndex("")
            if (currentIndexRef.current > -1) {
                setSelectedUser(db[currentIndexRef.current].user)
            } else {
                setSelectedUser("")
            }

        }
        // console.log(currentIndex < db.length)
        // console.log(currentIndex)
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 80);
    }

    // increase current index and show card
    const goBack = async () => {
        setPreviousPressed(true)
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
            setPreviousPressed(false)
        }, 50);
        if (!canGoBack) return
        if (currentIndex !== -1) {
            setSelectedIndex("")
            const newIndex = currentIndex + 1
            if (currentIndexRef.current > -1) {
                setSelectedUser(db[newIndex].user)
            } else {
                setSelectedUser("")
            }
            updateCurrentIndex(newIndex)
            await db[newIndex].current.restoreCard()
        }
    }

    const swipeRequirementFulfilled = (dir, user, index) => {
        setSelectedIndex(index)
        correctLayout(dir)
        // console.log(dir)
    }



    let swipeRequirementUnfulfilled = (dir, user, index) => {
        correctLayout(dir)
        setSelectedIndex(index)

        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 50);
    }

    let correctLayout = (dir) => {
        if (dir === "left") {
            setNextPressed(true)
            setAddPressed(false)
            setProfilePressed(false)
            setCancelPressed(false)
        } else if (dir === "right") {
            setAddPressed(true)
            setNextPressed(false)
            setProfilePressed(false)
            setCancelPressed(false)
        } else if (dir === "up") {
            setProfilePressed(true)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }
    }

    const cancelBuddyRequest = async (user) => {
        setCancelPressed(true)
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setCancelPressed(false)
            setCancelPressed(false)
            setPreviousPressed(false)
        }, 100);
        props.data.updateBuddyrequestList(user)
        props.data.cancelBuddyRequest(user)
    }

    return (
        <>
            {/* {dataRequested ?
                <div class="search-swip-products-waiting-box">
                    <div class="search-swip-products-waiting-img">
                        <img
                            class="search-swip-products-waiting-profile"
                            src={props.data.profile_picture}
                            alt=""
                        />
                    </div>
                </div>
                : */}
            <>
                {db.map((product, index) => (
                    <>
                        <TinderCard
                            ref={db[index]}
                            className='swip-products-swipe-box'
                            key={product.id}
                            swipeRequirementType='position'
                            swipeThreshold="15"
                            onSwipe={(dir) => swiped(dir, product.id, index)}
                            onCardLeftScreen={() => outOfFrame(product.id, index)}
                            preventSwipe="down"
                            onSwipeRequirementFulfilled={(dir) => swipeRequirementFulfilled(dir, product.id, index, pressedOpacity)}
                            onSwipeRequirementUnfulfilled={() => swipeRequirementUnfulfilled()}
                        >

                            <div class="swip-products-swipe-card">
                                <div
                                    style={{ backgroundImage: 'url(' + product.images[0].src + ')' }}
                                    class='swip-products-swipe-square'
                                >

                                    <div class="swip-products-username">
                                        {product.name}
                                    </div>

                                    <div class="swip-products-black-cover-box"> </div>

                                </div>
                            </div>

                            {nextPressed && selectedIndex === index ?
                                <div class="swip-products-visit-next-position">


                                    <img
                                        class="swip-products-visit-icon-img"
                                        src={require("../../../assets/buddies/svg/swipe-visit-next.svg")}
                                        alt=""
                                    />
                                </div>
                                :
                                null
                            }

                            {profilePressed && selectedIndex === index ?
                                <div class="swip-products-visit-profile-position">
                                    <img
                                        class="swip-products-visit-icon-img"
                                        src={require("../../../assets/buddies/svg/swipe-visit-profile.svg")}
                                        alt=""
                                    />
                                </div>
                                :
                                null
                            }

                            {addPressed && selectedIndex === index ?
                                <div class="swip-products-visit-add-position">
                                    <img
                                        class="swip-products-choise-icon-img"
                                        src={require("../../../assets/buddies/svg/swipe-visit-add.svg")}
                                        alt=""
                                    />
                                </div>
                                :
                                null
                            }

                        </TinderCard>

                         
                    
                    </>
                ))}
            </>
             {/* } */}



        </>

    )
}

export default SwipeProductsFull