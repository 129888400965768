import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import { getHubPage } from '../../../store/actions/hub'
import {
    getHubDaysAppointmentHours,
    createNewAppointment,
    getDatesAppointments,
    updateAppointmentNote,
    cancelAppointment,
    updateAppointment
} from '../../../store/actions/appointment'
import { createAlert } from '../../../store/actions/alerts'
//datepicker
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import "../../../assets/hub/appointments/approved-appointments.css";
import WheelPicker from 'react-simple-wheel-picker';


export class ApprovedAppointments extends Component {
    state = {
        appointment_note: '-',
        appointment_name: '-',
        telephone: "-",
        hub: '',
        date: new Date(),
        changeDate: new Date(),
        changeDateUpdated: false,
        date_updated: false,
        redirect: false,
        show_datepicker: false,
        hour: '',
        minute: '',
        time: '',
        time_updated: false,
        hour_sellected: false,
        dateMode: true,
        timeMode: false,
        noteMode: false,
        hub_name: "",
        hub_logo: "",
        hub_id: "",
        timeModalVisible: true,
        pre_selected: false,
        redirect: false,
        selected_time: "",
        show_appointment_more: false,
        selected_hour: "",
        new_appointment_note: "",
        addAppointmentModal: false,
        appointment_date: "",
        appointment_time: "",
        editAppointmentModal: false,
        cancelAppointmentModal: false,
        changeTimeMode: false,
    }

    componentDidMount() {
        this.props.getHubPage();
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        // make date only numbers
        const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getDatesAppointments(date_id)
        this.props.getHubDaysAppointmentHours(hub_id, date_id)
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleBack = () => {
        this.props.history.goBack()
    }


    selectDateMode(hub_name, hub_logo, hub_id) {
        this.setState({
            hubMode: false,
            dateMode: true,
            timeMode: false,
            noteMode: false
        })
        if (hub_name !== "") {
            this.setState({
                hub_name: hub_name,
                hub_logo: hub_logo,
                hub_id: hub_id
            })
        }
    }

    selectDateModeTop() {
        this.setState({
            hubMode: false,
            dateMode: true,
            timeMode: false,
            noteMode: false
        })
    }

    selectTimeMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            timeMode: true,
            noteMode: false,
            timeModalVisible: true,

        })
    }

    selectNoteMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            timeMode: false,
            noteMode: true
        })
    }

    updateDateField(day) {
        const hub_id = this.props.hub[0].id
        const correct_datetime = moment(day).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubDaysAppointmentHours(hub_id, date_id)
        this.props.getDatesAppointments(date_id)

        this.setState({
            show_appointment_more: false,
            show_datepicker: false,
            date: day,
            date_updated: true,
            hubMode: false,
            dateMode: false,
            timeMode: true,
            timeModalVisible: true,
            noteMode: false,
            pre_selected: true
        })
    }

    updateChangeDateField(day) {

        this.setState({
            changeDate: day,
            changeDateUpdated: true,
        })
    }


    handleOnChange(time) {
        this.setState({
            selected_time: time
        })
    }

    updateReservatonHour = (options) => {
        const {
            hour,
            minute,
        } = options;
        if (this.state.hour_sellected) {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                time_updated: true,
                hubMode: false,
                dateMode: false,
                timeMode: false,
                hour_sellected: false,
                noteMode: true
            });
        } else {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                hour_sellected: true,
            });
        }


    }


    setDatepickerMode = () => (e) => {
        this.setState({
            show_datepicker: true
        });
    }


    selectSpecificTime() {
        this.setState({
            dateMode: false,
            timeMode: false,
            noteMode: true
        })
    }

    changeAppointmnetMore(hour) {
        this.setState({
            show_appointment_more: true,
            selected_hour: hour
        })
    }

    closeAppointmnetMore() {
        this.setState({
            show_appointment_more: false,
            selected_hour: "",
            new_appointment_note: ""
        })
    }

    changeAppointmentNote(appointment_id) {
        const note = this.state.new_appointment_note
        const id = appointment_id
        const data = { note, id }
        this.props.updateAppointmentNote(data, appointment_id)
        setTimeout(() => {
            this.setState({
                new_appointment_note: ""
            });
        }, 500);
    }

    onSubmitAppointment = e => {
        const appointment_name = this.state.appointment_name
        const time = this.state.appointment_time
        const telephone = this.state.telephone
        const note = this.state.appointment_note
        const date = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")

        const appointment = { time, date, note, telephone, appointment_name };
        if (this.state.appointment_name === "-") {
            this.props.createAlert({ noTimeSelected: "You haven't Selected a name for the appointment!" });
        } else if (time === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Selected time!" });
        } else {
            this.props.createNewAppointment(appointment)
            setTimeout(() => {
                this.setState({
                    appointment_note: '-',
                    telephone: "-",
                    hub: '',
                    // date: new Date(),
                    date_updated: false,
                    redirect: false,
                    show_datepicker: false,
                    hour: '',
                    minute: '',
                    time: '',
                    time_updated: false,
                    hour_sellected: false,
                    hubMode: true,
                    dateMode: false,
                    timeMode: false,
                    noteMode: false,
                    hub_name: "",
                    hub_logo: "",
                    hub_id: "",
                    timeModalVisible: true,
                    pre_selected: false,
                    appointment_name: "-",
                    redirect: true,
                    addAppointmentModal: false,
                    appointment_date: "",
                    appointment_time: "",
                    changeTimeMode: false,
                });

            }, 500);
            setTimeout(() => {
                const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
                // make date only numbers
                const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
                const date1 = correct_datetime.replace(/[^\d.-]/g, '');
                const date2 = date1.split('.').join("");
                const date_id = date2.split('-').join("");
                this.props.getHubDaysAppointmentHours(hub_id, date_id)
            }, 700);
        }
    }

    submitAppointmentChange(appointment_id) {

        this.setState({
            editAppointmentModal: false
        })
        const id = appointment_id
        let appointment_name = this.state.appointment_name
        const time = this.state.time
        let telephone = this.state.telephone
        let date = ""

        if (time !== "") {
            this.setState({
                show_appointment_more: false,
            })
        }

        if (this.state.changeDateUpdated) {
            this.setState({
                show_appointment_more: false,
            })
            date = moment(this.state.changeDate).tz("Europe/Athens").format("YYYY-MM-DD")
        }

        if (telephone === "-") {
            telephone = ""
        }

        if (appointment_name === "-") {
            appointment_name = ""
        }
        const appointment = { id, time, date, telephone, appointment_name };

        this.props.updateAppointment(appointment)
        // setTimeout(() => {
        this.setState({
            appointment_note: '-',
            telephone: "-",
            hub: '',
            // date: new Date(),
            changeDateUpdated: false,
            date_updated: false,
            redirect: false,
            show_datepicker: false,
            hour: '',
            minute: '',
            time: '',
            time_updated: false,
            hour_sellected: false,
            hubMode: true,
            dateMode: false,
            timeMode: false,
            noteMode: false,
            hub_name: "",
            hub_logo: "",
            hub_id: "",
            timeModalVisible: true,
            pre_selected: false,
            appointment_name: "-",
            redirect: true,
            addAppointmentModal: false,
            appointment_date: "",
            appointment_time: "",
            changeTimeMode: false,
        });
        // }, 500);
        setTimeout(() => {
            const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
            // make date only numbers
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getDatesAppointments(date_id)
            this.props.getHubDaysAppointmentHours(hub_id, date_id)
        }, 700);

    }

    openAddAppointmentModal(time) {
        this.setState({
            addAppointmentModal: true,
            appointment_time: time,
        })
    }

    openCancelAppointmentModal() {
        this.setState({
            cancelAppointmentModal: true,
        })
    }

    openEditAppointmentModal() {
        this.setState({
            editAppointmentModal: true,
        })
    }


    closeModal() {
        this.setState({
            addAppointmentModal: false,
            appointment_date: "",
            appointment_time: "",
            cancelAppointmentModal: false,
            editAppointmentModal: false,
            appointment_note: '-',
            telephone: "-",
            hub: '',
            date_updated: false,
            redirect: false,
            show_datepicker: false,
            hour: '',
            minute: '',
            time: '',
            time_updated: false,
            hour_sellected: false,
            hubMode: true,
            dateMode: false,
            timeMode: false,
            noteMode: false,
            hub_name: "",
            hub_logo: "",
            hub_id: "",
            timeModalVisible: true,
            pre_selected: false,
            appointment_name: "-",
            addAppointmentModal: false,
            appointment_date: "",
            appointment_time: "",
            changeTimeMode: false,
        })
    }

    cancelAppointment(appointment_id) {
        const status = "Cancelled by Hub"
        const id = appointment_id
        const answer = { status, id };
        this.props.cancelAppointment(answer, id);
        setTimeout(() => {
            const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
            // make date only numbers
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getHubDaysAppointmentHours(hub_id, date_id)
            this.setState({
                cancelAppointmentModal: false,
                show_appointment_more: false,
                selected_hour: "",
                new_appointment_note: ""
            })
        }, 700);
    }


    changeChangeTimeMode() {
        this.setState({
            changeTimeMode: !this.state.changeTimeMode,
        })
    }

    changeAppointmentHour() {
        this.setState({
            time: this.state.selected_time.value,
            changeTimeMode: !this.state.changeTimeMode,
        })
    }


    render() {

        const ExampleCustomInput = ({ value, onClick }) => (
            <strong onClick={onClick}>
                {value}
            </strong>
        );

        let selected_appointment = []

        if (this.state.selected_hour !== "") {
            selected_appointment = this.props.appointments.filter(appointment => appointment.time === this.state.selected_hour)[0]
        }

        const data = this.props.appointment_hours
        return (


            < Fragment >


                {this.state.cancelAppointmentModal ?
                    <Modal visible={this.state.cancelAppointmentModal} width="100%" height="30%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                        <div class="approved-appointments-modal-box">
                            <div class="approved-appointments-cancel-modal-title">
                                Are you sure you want to cancel the appointment of <b>{selected_appointment.appointment_name}</b> at <b>{selected_appointment.time}</b> o'clock?
                            </div>

                            <img
                                onClick={() => this.closeModal()}
                                class="approved-appointments-cancel-modal-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />

                            <div onClick={() => this.closeModal()} class="approved-appointments-cancel-modal-no">
                                No
                            </div>
                            <div onClick={() => this.cancelAppointment(selected_appointment.id)} class="approved-appointments-cancel-modal-yes">
                                Yes
                            </div>
                        </div>
                    </Modal>
                    :
                    null
                }

                {this.state.editAppointmentModal ?
                    <Modal visible={this.state.editAppointmentModal} width="100%" height="60%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                        <div class="approved-appointments-modal-box">

                            {this.state.changeTimeMode ?
                                <>

                                    <div onClick={() => this.changeChangeTimeMode()} class="approved-appointments-timewheel-back-arrow">
                                        <img class="approved-appointments-timewheel-arrow-img" src={require("../../../assets/layout/homepage svg/back arrow.svg")} alt="" />
                                    </div>

                                    <div class="approved-appointments-timewheel-title-text">
                                        Currently at: <b>{selected_appointment.time}</b>
                                    </div>

                                    <div class="approved-appointments-timewheel-arrow-left-box">
                                        <img
                                            class="approved-appointments-timewheel-arrow-img"
                                            src={require("../../../assets/hub/hub-homepage/svg/arrow-left.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="approved-appointments-timewheel-arrow-right-box">
                                        <img
                                            class="approved-appointments-timewheel-arrow-img"
                                            src={require("../../../assets/hub/hub-homepage/svg/arrow-right.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="approved-appointments-timewheel-box">
                                        {data.length !== 0 ?
                                            <WheelPicker
                                                data={data}
                                                onChange={this.handleOnChange.bind(this)}
                                                height={200}
                                                width={180}
                                                itemHeight={60}
                                                selectedID={data[5].id}
                                                color="#000"
                                                activeColor="white"
                                                backgroundColor="white"
                                            />
                                            :
                                            null
                                        }
                                    </div>

                                    {this.props.appointment_hours.map(hour => (
                                        <>
                                            {hour.value === this.state.selected_time.value ?
                                                <div class="approved-appointments-timewheel-text">
                                                    Appointments at <b>{hour.value}</b>:<br />
                                                    <b>{hour.appointment}</b>
                                                </div>
                                                :
                                                null
                                            }
                                            {hour.appointment ?
                                                null
                                                : hour.value === this.state.selected_time.value ?
                                                    <div onClick={() => this.changeAppointmentHour()} class="approved-appointments-timewheel-change-btn">
                                                        Change to {this.state.selected_time.value}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </>
                                    ))}
                                </>
                                :
                                <>
                                    <div class="approved-appointments-cancel-modal-title">
                                        Change the Appointmnent of <b>{selected_appointment.appointment_name}</b> at <b>{selected_appointment.time}</b> o'clock?
                                    </div>

                                    <img
                                        onClick={() => this.closeModal()}
                                        class="approved-appointments-cancel-modal-times"
                                        src={require("../../../assets/layout/homepage svg/times.svg")}
                                        alt=""
                                    />

                                    <div class="row approved-edit-modal-name-title">
                                        Name:
                                        <div class="approved-appointments-modal-input">
                                            <input
                                                maxlength="22"
                                                className="form-control"
                                                type="text"
                                                name="appointment_name"
                                                onChange={this.onChange}
                                                value={this.state.appointment_name === '-' ? selected_appointment.appointment_name : this.state.appointment_name}
                                            />

                                        </div>
                                    </div>

                                    <div class="row approved-edit-modal-phone-title">
                                        Phone:
                                        <div class="approved-appointments-modal-input">
                                            <input
                                                maxlength="18"
                                                className="form-control"
                                                type="number"
                                                name="telephone"
                                                onChange={this.onChange}
                                                value={this.state.telephone === "-" ? selected_appointment.telephone : this.state.telephone}
                                            />
                                        </div>
                                    </div>

                                    <div class="approved-appointments-edit-modal-date">
                                        <DatePicker
                                            selected={this.state.changeDateUpdated ? this.state.changeDate : this.state.date}
                                            onChange={this.updateChangeDateField.bind(this)}
                                            // minDate={new Date()}
                                            locale="GTM+2"
                                            name="date"
                                            dateFormat="dd/MM/yyyy"
                                            todayButton="Press for Today"
                                            customInput={<ExampleCustomInput />}
                                        // withPortal
                                        />
                                    </div>

                                    {this.state.time === "" ?
                                        <div onClick={() => this.changeChangeTimeMode()} class="approved-appointments-edit-modal-time">
                                            {selected_appointment.time}
                                        </div>
                                        :
                                        <div onClick={() => this.changeChangeTimeMode()} class="approved-appointments-edit-modal-time">
                                            {this.state.time}
                                        </div>
                                    }


                                    {this.state.changeDateUpdated || this.state.telephone !== "-" || this.state.appointment_name !== '-' || this.state.time !== '' ?
                                        <div onClick={() => this.submitAppointmentChange(selected_appointment.id)} class="approved-appointments-edit-modal-change">
                                            Change
                                        </div>
                                        :
                                        null
                                    }
                                </>
                            }
                        </div>
                    </Modal>
                    :
                    null
                }


                {this.state.addAppointmentModal ?
                    <Modal visible={this.state.addAppointmentModal} width="100%" height="40%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                        <div class="approved-appointments-modal-box">
                            <div class="approved-appointments-modal-title">
                                Add an Appointment at {this.state.appointment_time} o'clock
                            </div>
                            <img
                                onClick={() => this.closeModal()}
                                class="approved-appointments-cancel-modal-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <div class="approved-appointments-modal-all-title">
                                <div class="row approved-appointments-modal-phone-title">
                                    Name:
                                    <div class="approved-appointments-modal-input">
                                        <input
                                            maxlength="22"
                                            className="form-control"
                                            type="text"
                                            name="appointment_name"
                                            onChange={this.onChange}
                                            placeholder={this.state.appointment_name === "-" ? "Appointment name..." : this.state.appointment_name}
                                        />

                                    </div>
                                </div>

                                <div class="row approved-appointments-modal-phone-title">
                                    Phone:
                                    <div class="approved-appointments-modal-input">
                                        <input
                                            maxlength="18"
                                            className="form-control"
                                            type="number"
                                            name="telephone"
                                            onChange={this.onChange}
                                            placeholder={this.state.telephone === "-" ? "Mobile Phone Number..." : this.state.telephone}
                                        />
                                    </div>
                                </div>

                                <div class="row approved-appointments-modal-phone-title">
                                    Note:
                                    <div class="approved-appointments-modal-input">
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            placeholder={this.state.appointment_note === "-" ? "Note..." : this.state.appointment_note}
                                            rows="2"
                                            name="appointment_note"
                                            onChange={this.onChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div onClick={() => this.onSubmitAppointment()} class="approved-appointments-dad-button">
                                    Add
                                </div>
                            </div>
                        </div>
                    </Modal>
                    :
                    null
                }



                <HubDesktopNavbar data={{ sellected: "" }} />

                <div>
                    <div class="arrange-page-whole-page-layout">
                        <OfflineLabel />
                        <div class="color-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="approved-appointments-mobile-navbar">
                                        <div onClick={() => this.handleBack()} class="back-button-request-reservation-box">
                                            <img
                                                class="request-reservation-back-button"
                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                        Appointments
                                    </div>

                                    <div class="approved-appointments-day-box">
                                        <DatePicker
                                            selected={this.state.date}
                                            onChange={this.updateDateField.bind(this)}
                                            // minDate={new Date()}
                                            locale="GTM+2"
                                            name="date"
                                            dateFormat="dd/MM/yyyy"
                                            todayButton="Press for Today"
                                            customInput={<ExampleCustomInput />}
                                            withPortal
                                        />
                                    </div>


                                    <div class="approved-appointments-all-appointment-hours">
                                        {this.props.appointment_hours.map(hour => (
                                            <div class=
                                                {this.state.show_appointment_more && this.state.selected_hour === hour.value ?
                                                    "row approved-appointments-appointment-hour-box-selected"
                                                    :
                                                    "row approved-appointments-appointment-hour-box"
                                                }
                                            >
                                                <div class="approved-appointments-appointment-hour-value">
                                                    {hour.value}
                                                </div>
                                                {hour.appointment ?
                                                    <>
                                                        <div onClick={() => this.changeAppointmnetMore(hour.value)} class="approved-appointments-appointment-hour-appointment">
                                                            {hour.appointment}
                                                        </div>
                                                        {!this.state.show_appointment_more ?
                                                            <div
                                                                onClick={() => this.changeAppointmnetMore(hour.value)}
                                                                class="approved-appointments-top-down-arrow">
                                                                <img
                                                                    class="approved-appointments-top-down-arrow-icon"

                                                                    src={require("../../../assets/map/svg/down-arrow.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                        {this.state.show_appointment_more && this.state.selected_hour === hour.value ?
                                                            <div class="approved-appointments-show-more-box">
                                                                {selected_appointment.user ?
                                                                    null
                                                                    :
                                                                    <div onClick={() => this.openEditAppointmentModal()} class="approved-appointments-show-more-edit">
                                                                        Change
                                                                    </div>
                                                                }

                                                                <div class="approved-appointments-show-more-phone">
                                                                    Phone: <b>{selected_appointment.telephone}</b>
                                                                </div>
                                                                <div class="approved-appointments-show-more-note">
                                                                    My Note:
                                                                </div>
                                                                <div class="approved-appointments-show-more-note-box">
                                                                    <textarea
                                                                        class="form-control"
                                                                        id="exampleFormControlTextarea1"
                                                                        placeholder="Note..."
                                                                        rows="3"
                                                                        value={this.state.new_appointment_note === "" ? selected_appointment.note : this.state.new_appointment_note}
                                                                        name="new_appointment_note"
                                                                        onChange={this.onChange}
                                                                    ></textarea>
                                                                </div>
                                                                {this.state.new_appointment_note === "" ?
                                                                    null
                                                                    : this.state.new_appointment_note !== selected_appointment.note ?
                                                                        <div
                                                                            onClick={() => this.changeAppointmentNote(selected_appointment.id)}
                                                                            class="approved-appointments-save-button">
                                                                            Save
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                                <div onClick={() => this.openCancelAppointmentModal()} class="approved-appointments-cancel-appointment-button">
                                                                    Cancel
                                                                </div>
                                                                <div
                                                                    onClick={() => this.closeAppointmnetMore()}
                                                                    class="approved-appointments-top-up-arrow"
                                                                >
                                                                    <img
                                                                        class="approved-appointments-top-down-arrow-icon"
                                                                        src={require("../../../assets/map/svg/up-arrow.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                    </>
                                                    :
                                                    <div onClick={() => this.openAddAppointmentModal(hour.value)} class="approved-appointments-appointment-hour-appointment-empty">
                                                        Add Appointment
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >

        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    appointment_hours: state.appointment.appointment_hours,
    appointments: state.appointment.appointments,
});

export default connect(mapStateToProps, {
    getHubPage,
    createNewAppointment,
    getHubDaysAppointmentHours,
    createAlert,
    getDatesAppointments,
    updateAppointmentNote,
    cancelAppointment,
    updateAppointment
})(ApprovedAppointments);