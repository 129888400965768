import {
  USER_LOADED,
  USER_LOADING,
  USER_LOCALY_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_TOKEN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ACCEPT_HUBS_TERMS,
  CHECK_DASHBOARD_ACCESS,
  SWITCH_TO_HUB_ACCOUNT,
  SWITCH_TO_PROFILE_ACCOUNT
} from "../actions/actionTypes";


const initialState = {
  token: localStorage.getItem("tk"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  offlineMode: false,
  correct_credentials: false,
  hubs_terms_accepted: null,
  dashboard_access: false,
  hub_redirect: false,
  profile_redirect: false,
  incorect_redentials: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('tk', action.payload.token);
      localStorage.setItem('ur', JSON.stringify(action.payload.user));
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        offlineMode: false,
        isLoading: false,
        user: action.payload,
        correct_credentials: true,
        incorect_redentials: false,
      };
    case SWITCH_TO_HUB_ACCOUNT:
      localStorage.setItem('ur', JSON.stringify(action.payload[0]));
      setTimeout(() => {
      }, 200);
      return {
        ...state,
        ...action.payload,
        hub_redirect: true,
        profile_redirect: false,
        isAuthenticated: true,
        offlineMode: false,
        isLoading: false,
        // user: action.payload[0],
        correct_credentials: true,
        incorect_redentials: false,
      };
    case SWITCH_TO_PROFILE_ACCOUNT:
      localStorage.setItem('ur', JSON.stringify(action.payload[0]));
      setTimeout(() => {
      }, 200);
      return {
        ...state,
        ...action.payload,
        profile_redirect: true,
        hub_redirect: false,
        isAuthenticated: true,
        offlineMode: false,
        isLoading: false,
        // user: action.payload[0],
        correct_credentials: true,
        incorect_redentials: false,
      };

    case LOGIN_TOKEN_SUCCESS:
      return {
        isAuthenticated: null,
        correct_credentials: true,
        incorect_redentials: false,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      // localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        offlineMode: false,
        user: JSON.parse(localStorage.getItem('ur'))
      };
    case USER_LOCALY_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        offlineMode: true,
        user: localStorage.getItem('ur')
      };
    case REGISTER_SUCCESS:
      // localStorage.setItem('tk', action.payload.token);
      // localStorage.setItem('ur', JSON.stringify(action.payload.user));
      return {
        isAuthenticated: null,
        correct_credentials: true,
        incorect_redentials: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
      localStorage.clear()
      localStorage.setItem('cka', JSON.stringify(true));
      localStorage.setItem('lgvshn', JSON.stringify("True"));
      sessionStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        offlineMode: false,
        isAuthenticated: false,
        isLoading: false,
        correct_credentials: false,
        incorect_redentials: true
      };
    case LOGOUT_SUCCESS:
      localStorage.clear()
      localStorage.setItem('cka', JSON.stringify(true));
      sessionStorage.clear();
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (const registration of registrations) {
            // unregister service worker
            console.log('serviceWorker unregistered')
            registration.unregister();
          }
        })
      }
      return {
        ...state,
        token: null,
        user: null,
        offlineMode: false,
        isAuthenticated: false,
        isLoading: false,
        correct_credentials: false
      };
    case ACCEPT_HUBS_TERMS:
      return {
        ...state,
        hubs_terms_accepted: action.payload
      };
    case CHECK_DASHBOARD_ACCESS:
      if (action.payload.length === 1) {
        return {
          ...state,
          dashboard_access: action.payload[0].dashboard_access
        }
      }
      else {
        return {
          ...state,
          dashboard_access: false
        }
      }
    default:
      return state;
  }
};
