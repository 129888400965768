import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    getHubWorkingDays,
    hubWorkingDayClose,
    setWorkingDayHours,
    uploadHubLogo,
    uploadHubCoverPhoto,
    uploadHubPromoPhoto,
    setHubName,
    setHubCategory,
    setHubMapIcon,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
    setHubReservations,
    setHubAppointments,
    setHubOnlineShop,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    selectHubReservationHours,
    selectHubAppointmentHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
    createHubMenuQr,
} from '../../../store/actions/hub'
import { acceptHubsTerms } from '../../../store/actions/auth'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';

import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import moment from "moment";
import hub from '../../../store/reducers/hub';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class SetupPage extends Component {

    state = {
        halfHourBox: false,
        quarterHourBox: false,
        specificHourBox: false,

        place_type: "",

        cover_photo: "",
        photo: null,

        logo_photo: "",
        logo_photo_file: null,

        promo_photo: "",
        promo_photo_file: null,

        name: '',
        opening_hour: "",
        closing_hour: "",
        changing_day_id: "",
        changing_opening: "",
        day_close_change: "",
        shop_url: "",

        logoNameSection: true,
        workingHoursSection: false,
        placeProvideSection: false,

        acceptReservationSection: false,
        acceptingReservations: false,
        throughReservations: "",
        reservation_app_url: "",



        reservationHoursMode: false,
        appointmentHoursMode: false,

        onlineShopSection: false,
        haveOnlineShop: false,


        acceptAppointmentSection: false,
        acceptingAppointments: false,
        throughAppointments: "",
        appointment_app_url: "",

        categorySection: false,
        mapSection: false,
        promoSection: false,
        socialSection: false,
        menuSection: false,


        wifiAnswer: false,
        telephoneAnswer: false,
        websiteAnswer: false,
        facebookAnswer: false,
        instagramAnswer: false,

        wifiMiniSection: true,
        wifi_code: "",
        telephoneMiniSection: true,
        telephone: "",
        has_telephone: false,
        websiteMiniSection: true,
        website: "",
        has_website: false,
        facebookMiniSection: true,
        facebook: "",
        has_facebook: false,
        instagramMiniSection: true,
        instagram: "",
        has_instagram: false,
        menu: null,

        finishedCategoryName: false,
        category: "",
        map_icon: "",
        catalogView: true,
        catalog_view_name: "",
        catalogNameView: false,
        catalog_name: "",
        catalog_id: "",
        viewMenuPages: false,
        uploading_pages: false,
        done_setup: false,
        redirect: false,
        specificHourMode: false,
        specificAppointmentHourMode: false,
        hour: "18",
        minutes: "00",
        telephone_reservations: false,
        telephone_appointments: false,
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
        this.props.getHubWorkingDays(hub_id);
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === "opening_hour" && e.target.value.length === 5) {
            const id = this.state.changing_day_id
            const opening_hour = e.target.value
            const closing_hour = ""
            const day = { id, opening_hour, closing_hour }
            this.props.setWorkingDayHours(day)
        }
        if (e.target.name === "closing_hour" && e.target.value.length === 5) {
            const id = this.state.changing_day_id
            const opening_hour = ""
            const closing_hour = e.target.value
            const day = { id, opening_hour, closing_hour }
            this.props.setWorkingDayHours(day)
        }
    }

    addCategory(category) {
        const my_categories_list = [...this.state.my_categories]
        if ((my_categories_list.indexOf(category)) !== -1) {
            const new_remove = my_categories_list.filter(f => f !== category)
            this.setState({ my_categories: new_remove })
        } else {
            my_categories_list.push(category)
            this.setState({ my_categories: my_categories_list })
        }
    }

    clickOnCloseChange(day_id) {
        this.setState({
            day_close_change: day_id,
        })
        const id = day_id
        const is_close = false
        const day = { id, is_close }
        this.props.hubWorkingDayClose(day)
    }

    clickOnDayClose(day_id) {
        const id = day_id
        const is_close = true
        const day = { id, is_close }
        this.props.hubWorkingDayClose(day)
    }

    clickOnDayClock(day, hour_type) {
        this.setState({
            changing_day_id: day.id,
            changing_opening: hour_type
        })

    }

    handleTimeValueChange = (value, day) => {
        console.log("value" + value);
        console.log(day)
    };

    goToLogoNameSection() {
        this.setState({
            logoNameSection: true,
            workingHoursSection: false,
            placeProvideSection: false,
            categorySection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    goToWorkingHoursSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: true,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        if (this.state.name !== "") {
            const id = this.props.hub[0].id
            const name = this.state.name
            const hub = { id, name }
            this.props.setHubName(hub)
        }
    }

    goToProvideSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: true,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            acceptingReservations: false,
            acceptingAppointments: false,
            haveOnlineShop: false,
            throughReservations: "",
            throughAppointments: "",
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
    }


    goToReservationSection(place_type) {
        this.setState({
            place_type: place_type,
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: true,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    answerReservationAcceptance(answer) {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = answer
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const telephone_reservations = false
        const another_app_reservations = false
        const reservation_app_url = this.state.reservation_app_url
        const hub = { id, appointment_acceptance, another_app_reservations, telephone_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }


    answerReservationAcceptanceHubst3r() {
        this.setState({
            reservationHoursMode: true
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = true
        const telephone_reservations = false
        const another_app_reservations = false
        const reservation_app_url = ""
        const hub = { id, appointment_acceptance, another_app_reservations, telephone_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }

    answerReservationAcceptanceTelephone() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const another_app_reservations = false
        const telephone_reservations = true
        const reservation_app_url = ""
        const hub = { id, appointment_acceptance, another_app_reservations, telephone_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }

    finishReservationHours() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
    }

    answerAppointmentAcceptanceHubst3r() {
        // this.setState({
        //     logoNameSection: false,
        //     workingHoursSection: false,
        //     placeProvideSection: false,
        //     acceptReservationSection: false,
        //     acceptAppointmentSection: false,
        //     onlineShopSection: false,
        //     categorySection: false,
        //     mapSection: false,
        //     promoSection: false,
        //     socialSection: false,
        //     menuSection: false,
        //     finishedCategoryName: false,
        // })
        this.setState({
            appointmentHoursMode: true
        })
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const has_online_shop = false
        const reservation_acceptance = false
        const hubst3r_appointments = true
        const telephone_appointments = false
        const another_app_appointments = false
        const appointment_app_url = ""
        const hub = { id, appointment_acceptance, another_app_appointments, telephone_appointments, reservation_acceptance, has_online_shop, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
    }

    answerAppointmentAcceptanceTelephone() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const has_online_shop = false
        const reservation_acceptance = false
        const hubst3r_appointments = false
        const telephone_appointments = true
        const another_app_appointments = false
        const appointment_app_url = ""
        const hub = { id, appointment_acceptance, another_app_appointments, telephone_appointments, reservation_acceptance, has_online_shop, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
    }

    finishedOtherResercations() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const telephone_reservations = false
        const another_app_reservations = true
        const reservation_app_url = this.state.reservation_app_url
        const hub = { id, appointment_acceptance, another_app_reservations, telephone_reservations, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }

    finishedOtherAppointment() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const has_online_shop = false
        const reservation_acceptance = false
        const hubst3r_appointments = false
        const telephone_appointments = false
        const another_app_appointments = true
        const appointment_app_url = this.state.appointment_app_url
        const hub = { id, appointment_acceptance, has_online_shop, reservation_acceptance, another_app_appointments, telephone_appointments, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
    }

    answerAcceptingReservations() {
        this.setState({
            acceptingReservations: true,
        })
    }

    answerAcceptingAppointments() {
        this.setState({
            acceptingAppointments: true,
        })
    }

    answerHaveOnlineShop() {
        this.setState({
            haveOnlineShop: true,
        })
    }

    answerThroughReservation(answer) {
        this.setState({
            throughReservations: answer,
        })
    }

    goToAppointementSection(place_type) {
        this.setState({
            place_type: place_type,
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: true,
            onlineShopSection: false,
            categorySection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    goToOnlineShopSection(place_type) {
        this.setState({
            place_type: place_type,
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: true,
            categorySection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    answerThroughAppointment(answer) {
        this.setState({
            throughAppointments: answer,
        })
    }

    answerAppointmentAcceptance(answer) {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
    }

    answerOnlineShop(answer) {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
    }

    finishedOnlineShop() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
        const id = this.props.hub[0].id
        const place_type = this.state.place_type
        const has_online_shop = true
        const reservation_acceptance = false
        const appointment_acceptance = false
        const shop_url = this.state.shop_url
        const hub = { id, place_type, appointment_acceptance, reservation_acceptance, has_online_shop, shop_url }
        this.props.setHubOnlineShop(hub)
    }

    goToCategorySection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false,
            appointmentHoursMode: false,
        })
    }

    goToMapSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: true,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    submitHubCategory() {
        this.setState({
            finishedCategoryName: true,
        })
        const category = this.state.category
        if (category !== "") {
            const id = this.props.hub[0].id
            const place_type = this.state.place_type
            const hub = { id, category, place_type }
            this.props.setHubCategory(hub)
        }
    }

    selectCategory(category) {
        this.setState({
            map_icon: category
        })
    }

    submitHubMapIcon() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: true,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        const map_icon = this.state.map_icon
        if (map_icon !== "") {
            const id = this.props.hub[0].id
            const hub = { id, map_icon }
            this.props.setHubMapIcon(hub)
        }
    }


    goToPromoSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: true,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    goToSocialSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: true,

            wifiMiniSection: true,
            wifi_code: "",
            telephoneMiniSection: false,
            telephone: "",
            has_telephone: false,
            websiteMiniSection: false,
            website: "",
            has_website: false,
            facebookMiniSection: false,
            facebook: "",
            has_facebook: false,
            instagramMiniSection: false,
            instagram: "",
            has_instagram: false,
            finishedCategoryName: false,
        })
    }

    goToMenuSection() {
        const hub_id = this.props.hub[0].id
        this.props.getHubCatalogs(hub_id)
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: true,
            catalogView: true,
            finishedCategoryName: false,
        })
        if (this.state.has_instagram && this.state.instagram !== "") {
            const id = this.props.hub[0].id
            const has_instagram = this.state.has_instagram
            const instagram_url = this.state.instagram
            const hub = { id, has_instagram, instagram_url }
            this.props.setHubInstagram(hub)
        }
        if (!this.state.has_instagram) {
            const id = this.props.hub[0].id
            const has_instagram = this.state.has_instagram
            const instagram_url = ""
            const hub = { id, has_instagram, instagram_url }
            this.props.setHubInstagram(hub)
        }
    }


    answerWifiMiniSection() {
        this.setState({
            wifiAnswer: true,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedWifiMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: true,
            websiteMiniSection: false,
            facebookMiniSection: false,
            instagramMiniSection: false,
        })
        const id = this.props.hub[0].id
        const wifi_code = this.state.wifi_code
        if (wifi_code !== this.props.hub[0].wifi_code && this.state.wifi_code !== "") {
            const id = this.props.hub[0].id
            const hub = { id, wifi_code }
            this.props.setHubWifiCode(hub)
        }

    }

    finishedTelephoneMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: true,
            facebookMiniSection: false,
            instagramMiniSection: false,
        })
        if (this.state.has_telephone && this.state.telephone !== "") {
            const id = this.props.hub[0].id
            const has_telephone = this.state.has_telephone
            const telephone = this.state.telephone
            const hub = { id, has_telephone, telephone }
            this.props.setHubTelephone(hub)
        }
        if (!this.state.has_telephone) {
            const id = this.props.hub[0].id
            const has_telephone = this.state.has_telephone
            const telephone = ""
            const hub = { id, has_telephone, telephone }
            this.props.setHubTelephone(hub)
        }
    }

    answerTelephoneMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: true,
            has_telephone: true,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedWebsiteMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: false,
            facebookMiniSection: true,
            instagramMiniSection: false,
        })
        if (this.state.has_website && this.state.website !== "") {
            const id = this.props.hub[0].id
            const has_website = this.state.has_website
            const website = this.state.website
            const hub = { id, has_website, website }
            this.props.setHubWebsite(hub)
        }
        if (!this.state.has_website) {
            const id = this.props.hub[0].id
            const has_website = this.state.has_website
            const website = ""
            const hub = { id, has_website, website }
            this.props.setHubWebsite(hub)
        }
    }

    answerWebsiteMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: true,
            has_website: true,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedFacebookMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: false,
            facebookMiniSection: false,
            instagramMiniSection: true,
        })
        if (this.state.has_facebook && this.state.facebook !== "") {
            const id = this.props.hub[0].id
            const has_facebook = this.state.has_facebook
            const facebook_url = this.state.facebook
            const hub = { id, has_facebook, facebook_url }
            this.props.setHubFacebook(hub)
        }
        if (!this.state.has_telephone) {
            const id = this.props.hub[0].id
            const has_facebook = this.state.has_facebook
            const facebook_url = ""
            const hub = { id, has_facebook, facebook_url }
            this.props.setHubFacebook(hub)
        }
    }

    answerFacebookMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: true,
            has_facebook: true,
            instagramAnswer: false,
        })
    }

    answerInstagramMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: true,
            has_instagram: true,
        })
    }


    onCoverPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            photo: URL.createObjectURL(e.target.files[0]),
            cover_photo: e.target.files[0]
        })
        setTimeout(() => {
            this.updateCoverPhoto()
        }, 4000);
    };

    updateCoverPhoto = () => {
        const id = this.props.hub[0].id
        const cover_photo = this.state.cover_photo
        const hub = { id, cover_photo }
        this.props.uploadHubCoverPhoto(hub)
        // hub.cover_photo = new_cover_photo
        // hub.logo = ""
        // hub.promo_photo = ""
        // this.props.updateHub(hub)
    }

    onPromoPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            promo_photo: URL.createObjectURL(e.target.files[0]),
            promo_photo_file: e.target.files[0]
        })
        setTimeout(() => {
            this.updatePromoPhoto()
        }, 4000);
    };

    updatePromoPhoto = () => {
        const id = this.props.hub[0].id
        const photo = this.state.promo_photo_file
        const hub = { id, photo }
        this.props.uploadHubPromoPhoto(hub)
        // hub.cover_photo = new_cover_photo
        // hub.logo = ""
        // hub.promo_photo = ""
        // this.props.updateHub(hub)
    }

    onLogoPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            logo_photo: URL.createObjectURL(e.target.files[0]),
            logo_photo_file: e.target.files[0]
        })
        setTimeout(() => {
            this.updateLogoPhoto()
        }, 4000);
    };

    updateLogoPhoto = () => {
        const id = this.props.hub[0].id
        const logo = this.state.logo_photo_file
        const hub = { id, logo }
        this.props.uploadHubLogo(hub)
    }

    changeCatalogView() {
        this.setState({
            catalogView: !this.state.catalogView,
            catalogNameView: false,
        })
    }


    setCatalogName() {
        this.setState({
            catalogNameView: !this.state.catalogNameView,
            catalog_name: "",
        })
    }

    createNewCatalog() {
        const hub = this.props.hub[0].id
        const name = this.state.catalog_name

        if (name === "") {
            this.props.createAlert({ noInvitationsSent: "You haven't given a name to the catalog!" });

        } else {
            const catalog = { hub, name }
            this.props.createHubCataloge(catalog)
            this.setState({
                catalogView: !this.state.catalogView,
            })
        }

        // this.setState({
        //     catalog_name: "",
        //     catalogNameView: false,
        // })
    }


    onMenuPagesUpload = (e) => {
        this.setState({
            uploading_pages: true
        })

        const hub = this.props.hub[0].id
        let photo = ""
        let page = 0
        let menu_page = {}
        let container_id = ""

        if (this.state.catalog_id !== "") {
            container_id = this.state.catalog_id
        } else {
            container_id = this.props.new_catalog.id
        }
        for (let i = 0; i < e.target.files.length; i++) {
            photo = e.target.files[i]
            page = i + 1
            menu_page = { hub, photo, page, container_id }
            this.props.createNewMenuPage(menu_page)

        }

        setTimeout(() => {
            const hub_id = this.props.hub[0].id
            this.props.getHubCatalogs(hub_id)
        }, 5000);



        setTimeout(() => {
            this.setState({
                uploading_pages: false,
                catalogView: true,
                catalogNameView: false,
                viewMenuPages: false,
            })
        }, 3000);


        // const cataloge_file = e.target.files[0]
        // const cataloge = { hub, cataloge_file }
    }



    openCatalogPagesView(catalog_id, catalog_name) {
        if (catalog_id !== "") {
            this.props.getHubMenuPages(catalog_id)
        } else {

        }
        this.setState({
            viewMenuPages: true,
            catalogView: !this.state.catalogView,
            catalog_view_name: catalog_name
        })
    }

    closeCatalogPagesView() {
        this.setState({
            catalogView: true,
            viewMenuPages: false,
        })
    }


    acceptHubsTerms() {
        this.setState({
            done_setup: true
        })
        const id = this.props.hub[0].id
        const hub = { id }
        this.props.acceptHubsTerms()
        this.props.createHubMenuQr(hub)
        setTimeout(() => {
            this.setState({
                redirect: true
            })
        }, 500);
    }

    answerReservationHours(choise) {
        const id = this.props.hub[0].id
        const reservation_hours_choise = choise
        const hub = { id, reservation_hours_choise }
        this.props.selectHubReservationHours(hub)
        if (choise === "specific") {
            this.setState({
                specificHourMode: true
            })
        } else {
            this.setState({
                logoNameSection: false,
                workingHoursSection: false,
                placeProvideSection: false,
                acceptReservationSection: false,
                acceptAppointmentSection: false,
                onlineShopSection: false,
                categorySection: true,
                mapSection: false,
                promoSection: false,
                socialSection: false,
                menuSection: false,
                finishedCategoryName: false,
                reservationHoursMode: false,
                appointmentHoursMode: false,
            })
        }
    }

    answerAppointmentHours(choise) {
        const id = this.props.hub[0].id
        const appointment_hours_choise = choise
        const hub = { id, appointment_hours_choise }
        this.props.selectHubAppointmentHours(hub)
        if (choise === "specific") {
            this.setState({
                specificAppointmentHourMode: true
            })
        } else {
            this.setState({
                logoNameSection: false,
                workingHoursSection: false,
                placeProvideSection: false,
                acceptReservationSection: false,
                acceptAppointmentSection: false,
                onlineShopSection: false,
                categorySection: true,
                mapSection: false,
                promoSection: false,
                socialSection: false,
                menuSection: false,
                finishedCategoryName: false,
                reservationHoursMode: false,
                appointmentHoursMode: false,
            })
        }
    }

    addNewSpecificHour() {
        let hour = this.state.hour
        let minutes = this.state.minutes
        const value = hour + ":" + minutes
        if (hour > 23 && hour > -1) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Hour" });
        } else if (minutes > 59) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Minutes" });
        } else {
            const specific_time = { value }
            this.props.createNewSpecificReservationHour(specific_time)
        }
    }

    addNewSpecificAppointmentHour() {
        let hour = this.state.hour
        let minutes = this.state.minutes
        const value = hour + ":" + minutes

        if (hour > 23 && hour > -1) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Hour" });
        } else if (minutes > 59) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Minutes" });
        } else {
            const specific_time = { value }
            this.props.createNewSpecificAppointmentHour(specific_time)
        }
    }


    removeSpecificHour(id, value) {
        const specific_time = { id, value }
        this.props.removeSpecificReservationHour(specific_time)
    }

    removeSpecificAppointmentHour(id, value) {
        const specific_time = { id, value }
        this.props.removeSpecificAppointmentHour(specific_time)
    }

    removeCatalog(id) {
        const hub = this.props.hub[0].id
        const is_active = false
        const catalog = { id, hub, is_active }
        this.props.removeHubCataloge(catalog)
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/hub-homepage' }} />
        }

        const defaultOptions = {
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        let categories = []
        if (this.state.place_type === "Hospitality") {
            categories = [
                "restaurant",
                "coffee shop",
                "lodging",
                "bar",
                "night club",
                // "cafe",
                "food",
                // 'drinks',
                'casino',
                "meal takeaway"]
        }
        if (this.state.place_type === "Shops") {
            categories = [
                "clothing store",
                "electronics store",
                "shoe store",
                "supermarket",
                "jewelry store",
                "liquor store",
                "pet store",
                "shopping mall",
                "convenience store",
                "gas station",
                "home goods store",
                "hardware store",
                "pharmacy",
                "florist",
                "furniture store",
                "bicycle store",
                "book store",
                "car dealer",
                "bakery"]
        }
        if (this.state.place_type === "Services") {
            categories = [
                "beauty salon",
                "hair care",
                "spa",
                "doctor",
                "dentist",
                "lawyer",
                "electrician",
                "accounting",
                "bank",
                "gym",
                "veterinary care",
                // "movie rental", 
                // "meal delivery", 
                "insurance agency",
                "travel agency",
                "parking",
                "painter",
                "car rental",
                "physiotherapist",
                // "roofing contractor", 
                "real estate agency",
                "plumber",
                "car repair",
                "car wash",
                // "embassy", 
                // "local government office", 
                "laundry",
                "locksmith"]
        }
        if (this.state.place_type === "Other") {
            categories = [
                "cinema",
                "minifootball",
                "sports",
                "bowling alley",
                "art gallery",
                "museum",
                "aquarium",
                "amusement park",
                "zoo",
                "campground",
                "library",
                "rv park",
                // "tourist attraction",
                "university"]
        }

        const screen_width = window.screen.width
        const menu_file = this.state.menu
        const hub = this.props.hub
        let promo_photo = ""
        if (hub.length > 0) {
            promo_photo = hub[0].promo_photo
        }

        if (this.state.promo_photo !== "") {
            promo_photo = this.state.promo_photo
        }

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const tagSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const categorySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const catalogs = this.props.catalogs

        return (
            <Fragment>
                {this.state.done_setup ?

                    <div class="setup-page-lottie-position">
                        <Lottie options={defaultOptions} />
                    </div>

                    :
                    <>
                        {this.props.hub.map(hub => (
                            <>
                                <HubDesktopNavbar data={{ sellected: "" }} />
                                <div>
                                    <div class="whole-setup-page-page-layout">
                                        <OfflineLabel />

                                        <>

                                            <div class="setup-page-welcome-title">
                                                <Link to="/hub-homepage" class="setup-page-back-box">
                                                    <div class="setup-page-back-icon-box">
                                                        <img
                                                            class="setup-page-back-icon"
                                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                </Link>
                                                Setup Page
                                            </div>
                                            {this.state.logoNameSection ?
                                                <>

                                                    <div class="setup-page-logo-text">
                                                        Set the logo photo
                                                    </div>

                                                    <div class="setup-page-logo-box">
                                                        {this.state.logo_photo !== "" ?
                                                            <img
                                                                class="setup-page-logo-img"
                                                                src={this.state.logo_photo}
                                                                onClick={() => this.logoInput.click()}
                                                                alt=""
                                                            />
                                                            : hub.logo ?
                                                                <img
                                                                    class="setup-page-logo-img"
                                                                    src={hub.logo}
                                                                    onClick={() => this.logoInput.click()}
                                                                    alt=""
                                                                />
                                                                :
                                                                <div onClick={() => this.logoInput.click()} class="setup-page-set-logo-photo">
                                                                    Set logo
                                                                </div>
                                                        }
                                                        <input
                                                            style={{ display: 'none' }}
                                                            accept="image/png, image/jpeg"
                                                            type="file"
                                                            name="photo"
                                                            onChange={this.onLogoPhotoSubmit}
                                                            ref={logoInput => this.logoInput = logoInput}
                                                        />
                                                    </div>

                                                    <div class="setup-page-name-text">
                                                        Is the name correct?
                                                    </div>

                                                    <div class="setup-page-name-input-box">
                                                        <input
                                                            class='setup-page-name-input-field'
                                                            name="name"
                                                            onChange={this.onChange}
                                                            placeholder="Place name..."
                                                            value={this.state.name === "" ? hub.name : this.state.name}
                                                        />
                                                    </div>
                                                </>
                                                : this.state.workingHoursSection ?
                                                    <>
                                                        <div class="setup-page-logo-text">
                                                            Is the Working Hours right?
                                                        </div>
                                                        <div class="setup-page-all-days-layout">
                                                            {this.props.working_days.map(day => (
                                                                <>
                                                                    <div class="row setup-page-day-row">
                                                                        <div class="setup-page-day-layout">
                                                                            {day.day}
                                                                        </div>
                                                                        {day.is_close ?
                                                                            // {day.index === 1 ?
                                                                            <>
                                                                                <div class="setup-page-day-is_close">
                                                                                    Close
                                                                                </div>
                                                                                <div onClick={() => this.clickOnCloseChange(day.id)} class="setup-page-day-change_close">
                                                                                    change
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div onClick={() => this.clickOnDayClock(day, "opening")}>
                                                                                    {this.state.changing_day_id === day.id && this.state.changing_opening === "opening" ?
                                                                                        <input
                                                                                            class='setup-page-name-input-field-opening'
                                                                                            name="opening_hour"
                                                                                            onChange={this.onChange}
                                                                                            value={this.state.opening_hour === "" ? day.opening_hour : this.state.opening_hour}
                                                                                        />
                                                                                        :
                                                                                        <div class="setup-page-opening-btn">
                                                                                            {day.opening_hour}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div onClick={() => this.clickOnDayClock(day, "closing")}>
                                                                                    {this.state.changing_day_id === day.id && this.state.changing_opening === "closing" ?
                                                                                        <input
                                                                                            class='setup-page-name-input-field-closing'
                                                                                            name="closing_hour"
                                                                                            onChange={this.onChange}
                                                                                            value={this.state.closing_hour === "" ? day.closing_hour : this.state.closing_hour}
                                                                                        />
                                                                                        :
                                                                                        <div class="setup-page-closing-btn">
                                                                                            {day.closing_hour}
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                <div onClick={() => this.clickOnDayClose(day.id)} class="setup-page-day-close">
                                                                                    close
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </>
                                                    : this.state.placeProvideSection ?
                                                        <>
                                                            <div class="setup-page-logo-text">
                                                                What Category fits you better?
                                                            </div>

                                                            <div class="place-setup-page-question-box">
                                                                <div class="place-setup-page-question-left">
                                                                    <div onClick={() => this.goToReservationSection("Hospitality")} class="place-setup-page-question-btn">
                                                                        <div class="place-setup-page-question-icon-box">
                                                                            <img
                                                                                class="place-setup-page-question-icon"
                                                                                src={require("../../../assets/hub/hub-homepage/svg/menu.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div class="place-setup-page-question-text">
                                                                            Hospitality
                                                                        </div>
                                                                        <div class="place-setup-page-question-text-small">
                                                                            (Coffee Shop, Restaurant, Bar, Club e.t.c)
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="place-setup-page-question-right">
                                                                    <div onClick={() => this.goToOnlineShopSection("Shops")} class="place-setup-page-question-btn">
                                                                        <div class="place-setup-page-question-icon-box">
                                                                            <img
                                                                                class="place-setup-page-question-icon"
                                                                                src={require("../../../assets/hub/setup/svg/selling-products.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="place-setup-page-question-text">
                                                                            Selling Products
                                                                        </div>
                                                                        <div class="place-setup-page-question-text-small">
                                                                            (Clothing Shop, Electronics store, Supermarket, e.t.c)
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="place-setup-page-question-bottom-left">
                                                                    <div onClick={() => this.goToAppointementSection("Services")} class="place-setup-page-question-btn">
                                                                        <div class="place-setup-page-question-icon-box">
                                                                            <img
                                                                                class="place-setup-page-question-icon"
                                                                                src={require("../../../assets/hub/setup/svg/consulting.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="place-setup-page-question-text">
                                                                            Providing Services
                                                                        </div>
                                                                        <div class="place-setup-page-question-text-small">
                                                                            (Consultant, Beauty Salon, Doctor, e.t.c)
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="place-setup-page-question-bottom-right">
                                                                    <div onClick={() => this.goToReservationSection("Other")} class="place-setup-page-question-btn">
                                                                        <div class="place-setup-page-question-icon-box">
                                                                            <img
                                                                                class="place-setup-page-question-icon"
                                                                                src={require("../../../assets/buddies/svg/hub-search.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="place-setup-page-question-text">
                                                                            Other
                                                                        </div>
                                                                        <div class="place-setup-page-question-text-small">
                                                                            (Cinema, Escape Rooms, Theatre, e.t.c)
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                        : this.state.acceptReservationSection ?
                                                            <>
                                                                {this.state.acceptingReservations ?
                                                                    <>
                                                                        {this.state.throughReservations === "other" ?
                                                                            <>

                                                                                <div class="setup-page-info-text">
                                                                                    Type the url to the app you want people to request Reservation through?
                                                                                </div>

                                                                                <div class="setup-page-info-input-box">
                                                                                    <input
                                                                                        class='setup-page-name-input-field'
                                                                                        name="reservation_app_url"
                                                                                        onChange={this.onChange}
                                                                                        placeholder="Reservations APP URL..."
                                                                                        value={this.state.reservation_app_url === "" ? hub.reservation_app_url : this.state.reservation_app_url}
                                                                                    />
                                                                                </div>
                                                                                <div onClick={() => this.finishedOtherResercations(false)} class="setup-page-info-done">
                                                                                    Done
                                                                                </div>

                                                                            </>
                                                                            :
                                                                            <>
                                                                                {this.state.reservationHoursMode ?
                                                                                    <>
                                                                                        <div class="setup-page-logo-text">
                                                                                            Set the available Hours for Reservations
                                                                                        </div>
                                                                                        {this.state.specificHourMode ?
                                                                                            <>

                                                                                                <div class="row setup-page-res-hours-row">
                                                                                                    <div class="row setup-page-res-hours-margin">
                                                                                                        <input
                                                                                                            class='setup-page-res-hours-hour'
                                                                                                            name="hour"
                                                                                                            onChange={this.onChange}
                                                                                                            value={this.state.hour}
                                                                                                            type="number"
                                                                                                            min="00"
                                                                                                            max="23"
                                                                                                        />
                                                                                                        <div class="setup-page-res-hours-middle">
                                                                                                            :
                                                                                                        </div>
                                                                                                        <input
                                                                                                            class='setup-page-res-hours-hour'
                                                                                                            name="minutes"
                                                                                                            onChange={this.onChange}
                                                                                                            value={this.state.minutes}
                                                                                                            type="number"
                                                                                                            min="00"
                                                                                                            max="60"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        onClick={() => this.addNewSpecificHour()}
                                                                                                        class="setup-page-res-hours-add">
                                                                                                        Add
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="row setup-page-created-hours-box">
                                                                                                    {this.props.reservation_hours.map(hour => (
                                                                                                        <div class="setup-page-created-hour">
                                                                                                            {hour.value}
                                                                                                            <div
                                                                                                                onClick={() => this.removeSpecificHour(hour.id, hour.value)}
                                                                                                                class="setup-page-created-hour-remove">
                                                                                                                x
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))}


                                                                                                </div>



                                                                                                <div
                                                                                                    onClick={() => this.goToCategorySection()}
                                                                                                    class="setup-page-new-hour-done">
                                                                                                    Done
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <div class="setup-page-reservation-hour-all-choises">

                                                                                                <div onClick={() => this.answerReservationHours("quarter")} class="setup-page-reservation-hour-choise">
                                                                                                    Every 15 minutes  <br />(i.e. 19:00, 19:15, 19:30, 19:45)
                                                                                                </div>

                                                                                                <div onClick={() => this.answerReservationHours("half")} class="setup-page-reservation-hour-choise">
                                                                                                    Every 30 minutes <br />(i.e. 19:30, 20:00, 20:30, 21:00)
                                                                                                </div>

                                                                                                <div onClick={() => this.answerReservationHours("hourly")} class="setup-page-reservation-hour-choise">
                                                                                                    Every 1 hour <br />(i.e. 18:00, 19:00, 20:00, 21:00)
                                                                                                </div>

                                                                                                <div onClick={() => this.answerReservationHours("specific")} class="setup-page-reservation-hour-choise">
                                                                                                    Specific Hours <br />(i.e. at 15:00, at 19:00, at 21:00)
                                                                                                </div>

                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div class="setup-page-reservation-question-text">
                                                                                            Where would you like people to be able to Request a Reseration?
                                                                                        </div>

                                                                                        <div class="setup-page-reservation-question-box">
                                                                                            <div onClick={() => this.answerThroughReservation("other")} class="setup-page-reservation-through-other">
                                                                                                Through another app
                                                                                            </div>
                                                                                            <div onClick={() => this.answerReservationAcceptanceHubst3r()} class="setup-page-reservation-through-hubst3r">
                                                                                                Through Hubst3r
                                                                                            </div>
                                                                                            <div onClick={() => this.answerReservationAcceptanceTelephone()} class="setup-page-reservation-through-telephone">
                                                                                                Only Through Telephone
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div class="setup-page-reservation-question-text">
                                                                            Do you accept Reservations?
                                                                        </div>

                                                                        <div class="setup-page-reservation-question-box">
                                                                            <div onClick={() => this.answerReservationAcceptance(false)} class="setup-page-reservation-question-no">
                                                                                No
                                                                            </div>
                                                                            <div onClick={() => this.answerAcceptingReservations(true)} class="setup-page-reservation-question-yes">
                                                                                Yes
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                            : this.state.acceptAppointmentSection ?
                                                                <>
                                                                    {this.state.acceptingAppointments ?
                                                                        <>
                                                                            {this.state.throughAppointments === "other" ?
                                                                                <>
                                                                                    <div class="setup-page-info-text">
                                                                                        Type the url to the app you want people to request Appointment through?
                                                                                    </div>
                                                                                    <div class="setup-page-info-input-box">
                                                                                        <input
                                                                                            class='setup-page-name-input-field'
                                                                                            name="appointment_app_url"
                                                                                            onChange={this.onChange}
                                                                                            placeholder="Appointments APP URL..."
                                                                                            value={this.state.appointment_app_url === "" ? hub.appointment_app_url : this.state.appointment_app_url}
                                                                                        />
                                                                                    </div>
                                                                                    <div onClick={() => this.finishedOtherAppointment(false)} class="setup-page-info-done">
                                                                                        Done
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {this.state.appointmentHoursMode ?
                                                                                        <>
                                                                                            <div class="setup-page-logo-text">
                                                                                                Set the available Hours for Appointments
                                                                                            </div>
                                                                                            {this.state.specificAppointmentHourMode ?
                                                                                                <>

                                                                                                    <div class="row setup-page-res-hours-row">
                                                                                                        <div class="row setup-page-res-hours-margin">
                                                                                                            <input
                                                                                                                class='setup-page-res-hours-hour'
                                                                                                                name="hour"
                                                                                                                onChange={this.onChange}
                                                                                                                value={this.state.hour}
                                                                                                                type="number"
                                                                                                                min="00"
                                                                                                                max="23"
                                                                                                            />
                                                                                                            <div class="setup-page-res-hours-middle">
                                                                                                                :
                                                                                                            </div>
                                                                                                            <input
                                                                                                                class='setup-page-res-hours-hour'
                                                                                                                name="minutes"
                                                                                                                onChange={this.onChange}
                                                                                                                value={this.state.minutes}
                                                                                                                type="number"
                                                                                                                min="00"
                                                                                                                max="60"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div
                                                                                                            onClick={() => this.addNewSpecificAppointmentHour()}
                                                                                                            class="setup-page-res-hours-add">
                                                                                                            Add
                                                                                                        </div>
                                                                                                    </div>


                                                                                                    <div class="row setup-page-created-hours-box">
                                                                                                        {this.props.appointment_hours.map(hour => (
                                                                                                            <div class="setup-page-created-hour">
                                                                                                                {hour.value}
                                                                                                                <div
                                                                                                                    onClick={() => this.removeSpecificAppointmentHour(hour.id, hour.value)}
                                                                                                                    class="setup-page-created-hour-remove">
                                                                                                                    x
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}


                                                                                                    </div>



                                                                                                    <div
                                                                                                        onClick={() => this.goToCategorySection()}
                                                                                                        class="setup-page-new-hour-done">
                                                                                                        Done
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <div class="setup-page-reservation-hour-all-choises">

                                                                                                    <div onClick={() => this.answerAppointmentHours("quarter")} class="setup-page-reservation-hour-choise">
                                                                                                        Every 15 minutes  <br />(i.e. 19:00, 19:15, 19:30, 19:45)
                                                                                                    </div>

                                                                                                    <div onClick={() => this.answerAppointmentHours("half")} class="setup-page-reservation-hour-choise">
                                                                                                        Every 30 minutes <br />(i.e. 19:30, 20:00, 20:30, 21:00)
                                                                                                    </div>

                                                                                                    <div onClick={() => this.answerAppointmentHours("hourly")} class="setup-page-reservation-hour-choise">
                                                                                                        Every 1 hour <br />(i.e. 18:00, 19:00, 20:00, 21:00)
                                                                                                    </div>

                                                                                                    <div onClick={() => this.answerAppointmentHours("specific")} class="setup-page-reservation-hour-choise">
                                                                                                        Specific Hours <br />(i.e. at 15:00, at 19:00, at 21:00)
                                                                                                    </div>

                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                        :

                                                                                        <>
                                                                                            <div class="setup-page-reservation-question-text">
                                                                                                Where do you like people to be able to Request an Appointment?
                                                                                            </div>

                                                                                            <div class="setup-page-reservation-question-box">
                                                                                                <div onClick={() => this.answerThroughAppointment("other")} class="setup-page-reservation-through-other">
                                                                                                    Through another app
                                                                                                </div>
                                                                                                <div onClick={() => this.answerAppointmentAcceptanceHubst3r()} class="setup-page-reservation-through-hubst3r">
                                                                                                    Through Hubst3r
                                                                                                </div>
                                                                                                <div onClick={() => this.answerAppointmentAcceptanceTelephone()} class="setup-page-reservation-through-telephone">
                                                                                                    Only Through Telephone

                                                                                                </div>
                                                                                            </div>
                                                                                        </>

                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div class="setup-page-reservation-question-text">
                                                                                Do you accept Appointments?
                                                                            </div>

                                                                            <div class="setup-page-reservation-question-box">
                                                                                <div onClick={() => this.answerAppointmentAcceptance(false)} class="setup-page-reservation-question-no">
                                                                                    No
                                                                                </div>
                                                                                <div onClick={() => this.answerAcceptingAppointments(true)} class="setup-page-reservation-question-yes">
                                                                                    Yes
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>

                                                                : this.state.onlineShopSection ?
                                                                    <>
                                                                        {this.state.haveOnlineShop ?
                                                                            <>
                                                                                <div class="setup-page-info-text">
                                                                                    Type the url to your Online Shop Page?
                                                                                </div>
                                                                                <div class="setup-page-info-input-box">
                                                                                    <input
                                                                                        class='setup-page-name-input-field'
                                                                                        name="shop_url"
                                                                                        onChange={this.onChange}
                                                                                        placeholder="Online Shop URL..."
                                                                                        value={this.state.shop_url === "" ? hub.shop_url : this.state.shop_url}
                                                                                    />
                                                                                </div>
                                                                                <div onClick={() => this.finishedOnlineShop()} class="setup-page-info-done">
                                                                                    Done
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div class="setup-page-reservation-question-text">
                                                                                    Do you have an Online Shop?
                                                                                </div>

                                                                                <div class="setup-page-reservation-question-box">
                                                                                    <div onClick={() => this.answerOnlineShop(false)} class="setup-page-reservation-question-no">
                                                                                        No
                                                                                    </div>
                                                                                    <div onClick={() => this.answerHaveOnlineShop(true)} class="setup-page-reservation-question-yes">
                                                                                        Yes
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>




                                                                    : this.state.categorySection ?
                                                                        <>
                                                                            {this.state.finishedCategoryName ?
                                                                                <>
                                                                                    <div class="setup-page-map_iconslider">
                                                                                        <Slider {...categorySettings} >
                                                                                            {categories.map(category => (
                                                                                                <>
                                                                                                    {this.state.map_icon === category ?
                                                                                                        <div
                                                                                                            class="setup-page-map_iconframe"
                                                                                                            onClick={() => this.selectCategory(category)}
                                                                                                        >
                                                                                                            <>
                                                                                                                {category === "restaurant" ?
                                                                                                                    <img
                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                        src={require("../../../assets/map/svg/restaurant-blue.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "coffee shop" ?
                                                                                                                        <img
                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                            src={require("../../../assets/map/svg/cafe-blue.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "bar" ?
                                                                                                                            <img
                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                src={require("../../../assets/map/svg/bar-blue.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "night club" ?
                                                                                                                                <img
                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/club-blue.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "food" ?
                                                                                                                                    <img
                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/food-blue.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "casino" ?
                                                                                                                                        <img
                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/casino-blue.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "meal takeaway" ?
                                                                                                                                            <img
                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/takeaway-blue.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "clothing store" ?
                                                                                                                                                <img
                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/clothestore-blue.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "electronics store" ?
                                                                                                                                                    <img
                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/electronicsstore-blue.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "shoe store" ?
                                                                                                                                                        <img
                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/shoestore-blue.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "supermarket" ?

                                                                                                                                                            <img
                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/supermarket-blue.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "bakery" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/bakery-blue.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "jewelry store" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/jewelry-blue.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "liquor store" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/liquorstore-blue.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "pet store" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/petstore-blue.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "shopping mall" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/mall-blue.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "convenience store" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/conviniencestore-blue.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "gas station" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/gasstation-blue.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "home goods store" ?

                                                                                                                                                                                            <img
                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/homegoodsstore-blue.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "hardware store" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/hardwarestore-blue.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "pharmacy" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/pharmacy-blue.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "florist" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/florist-blue.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "furniture store" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/furniturestore-blue.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "bicycle store" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/bicyclestore-blue.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "book store" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/bookshop-blue.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "car dealer" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/cardealer-blue.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "beauty salon" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/beautysalon-blue.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "hair care" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/hairsalon-blue.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "spa" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/spa-blue.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "doctor" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/doctor-blue.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "dentist" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/dentist-blue.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "lawyer" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lawyer-blue.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "electrician" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/electrician-blue.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "accounting" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/accountant-blue.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "laundry" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/laundry-blue.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "bank" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/bank-blue.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "gym" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/gym-blue.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    : category === "veterinary care" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/veterany-blue.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "insurance agency" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/insurance-blue.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "travel agency" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/travel-blue.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : category === "parking" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/parking-blue.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "painter" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/painter-blue.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "car rental" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rentcar-blue.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "physiotherapist" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/physiotherapist-blue.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "real estate agency" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/realestateagent-blue.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "plumber" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/plumber-blue.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "car repair" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/carrepair-blue.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "car wash" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carwash-blue.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                                                : category === "locksmith" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/locksmith-blue.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "cinema" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/cinema-blue.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "lodging" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/lodging-blue.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "minifootball" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/minifootball-blue.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                : category === "sports" ?
                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/sports-blue.svg")}
                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                    : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/bowling-blue.svg")}
                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                        : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/artgallery-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                            : category === "museum" ?
                                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/museum-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/aquarium-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                    : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/amusementpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                        : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/zoo-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                            : category === "campground" ?
                                                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/campground-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                : category === "library" ?
                                                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/library-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                    : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/rvpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                        : category === "university" ?
                                                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/university-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                                                            />

                                                                                                                                                                                                                                                                                                                                                                            :
                                                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/place-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                }


                                                                                                            </>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div
                                                                                                            class="setup-page-map_iconframe"
                                                                                                            onClick={() => this.selectCategory(category)}
                                                                                                        >
                                                                                                            <>
                                                                                                                {category === "restaurant" ?
                                                                                                                    <img
                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                        src={require("../../../assets/map/svg/restaurant-red.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "coffee shop" ?
                                                                                                                        <img
                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                            src={require("../../../assets/map/svg/cafe-red.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "bar" ?
                                                                                                                            <img
                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                src={require("../../../assets/map/svg/bar-red.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "night club" ?
                                                                                                                                <img
                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/club-red.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "food" ?
                                                                                                                                    <img
                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/food-red.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "casino" ?
                                                                                                                                        <img
                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/casino-red.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "meal takeaway" ?
                                                                                                                                            <img
                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/takeaway-red.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "clothing store" ?
                                                                                                                                                <img
                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/clothestore-red.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "electronics store" ?
                                                                                                                                                    <img
                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/electronicsstore-red.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "shoe store" ?
                                                                                                                                                        <img
                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/shoestore-red.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "supermarket" ?

                                                                                                                                                            <img
                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/supermarket-red.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "bakery" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/bakery-red.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "jewelry store" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/jewelry-red.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "liquor store" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/liquorstore-red.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "pet store" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/petstore-red.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "shopping mall" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/mall-red.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "convenience store" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/conviniencestore-red.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "gas station" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/gasstation-red.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "home goods store" ?

                                                                                                                                                                                            <img
                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/homegoodsstore-red.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "hardware store" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/hardwarestore-red.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "pharmacy" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/pharmacy-red.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "florist" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/florist-red.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "furniture store" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/furniturestore-red.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "bicycle store" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/bicyclestore-red.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "book store" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/bookshop-red.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "car dealer" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/cardealer-red.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />

                                                                                                                                                                                                                        : category === "beauty salon" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/beautysalon-red.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "hair care" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/hairsalon-red.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "spa" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/spa-red.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "doctor" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/doctor-red.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "dentist" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/dentist-red.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "lawyer" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lawyer-red.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "electrician" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/electrician-red.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "accounting" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/accountant-red.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "laundry" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/laundry-red.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "bank" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/bank-red.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "gym" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/gym-red.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    : category === "veterinary care" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/veterany-red.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "insurance agency" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/insurance-red.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "travel agency" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/travel-red.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : category === "parking" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/parking-red.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "painter" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/painter-red.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "car rental" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rentcar-red.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "physiotherapist" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/physiotherapist-red.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "real estate agency" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/realestateagent-red.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "plumber" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/plumber-red.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "car repair" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/carrepair-red.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "car wash" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carwash-red.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                                                : category === "locksmith" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/locksmith-red.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "cinema" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/cinema-red.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "lodging" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/lodging-red.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "minifootball" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/minifootball-red.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                : category === "sports" ?
                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/sports-red.svg")}
                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                    : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/bowling-red.svg")}
                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                        : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/artgallery-red.svg")}
                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                            : category === "museum" ?
                                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/museum-red.svg")}
                                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/aquarium-red.svg")}
                                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                    : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/amusementpark-red.svg")}
                                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                        : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/zoo-red.svg")}
                                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                                                            : category === "campground" ?
                                                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/campground-red.svg")}
                                                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                                                : category === "library" ?
                                                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/library-red.svg")}
                                                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                                                    : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/rvpark-red.svg")}
                                                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                                                        : category === "university" ?
                                                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/university-red.svg")}
                                                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                                                            />

                                                                                                                                                                                                                                                                                                                                                                            :
                                                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/place-red.svg")}
                                                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                                                            />
                                                                                                                }


                                                                                                            </>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div class="setup-page-map_iconname">
                                                                                                        {category}
                                                                                                    </div>
                                                                                                </>
                                                                                            ))}
                                                                                        </Slider>
                                                                                    </div>



                                                                                    <div onClick={() => this.submitHubMapIcon(false)} class="setup-page-info-done">
                                                                                        Done
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div class="setup-page-info-text">
                                                                                        Type the category of your place.
                                                                                    </div>

                                                                                    <div class="setup-page-info-input-box">
                                                                                        <input
                                                                                            class='setup-page-info-input-field'
                                                                                            name="category"
                                                                                            onChange={this.onChange}
                                                                                            placeholder="Category..."
                                                                                            value={this.state.category}
                                                                                        />
                                                                                    </div>
                                                                                    <div onClick={() => this.submitHubCategory(false)} class="setup-page-info-done">
                                                                                        Done
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>
                                                                        // <>
                                                                        //     <div class="setup-page-logo-text">
                                                                        //         What Categories fits you better?
                                                                        //     </div>
                                                                        //     <div class="setup-page-my-categories">
                                                                        //         <div class="row setup-page-my-categories-row">
                                                                        //             {this.state.my_categories.map(category => (
                                                                        //                 <div onClick={() => this.addCategory(category)} class="setup-page-all-my-categories-category">
                                                                        //                     {category}  &nbsp;&nbsp;x
                                                                        //                 </div>
                                                                        //             ))}
                                                                        //         </div>
                                                                        //     </div>
                                                                        //     <hr />
                                                                        //     <div class="setup-page-all-categories">
                                                                        //         <div class="row setup-page-all-categories-row">
                                                                        //             {categories.map(category => (
                                                                        //                 <>
                                                                        //                     {!this.state.my_categories.includes(category) ?
                                                                        //                         <div onClick={() => this.addCategory(category)} class="setup-page-all-categories-category">
                                                                        //                             {category}
                                                                        //                         </div>
                                                                        //                         :
                                                                        //                         null
                                                                        //                     }
                                                                        //                 </>
                                                                        //             ))}
                                                                        //         </div>
                                                                        //         <br /><br /><br /><br /><br /><br /><br /><br />
                                                                        //     </div>
                                                                        // </>
                                                                        : this.state.mapSection ?
                                                                            <>

                                                                                <iframe
                                                                                    src={hub.map_url}
                                                                                    class="setup-page-map-layout-sml2"
                                                                                    aria-hidden="false"
                                                                                    tabindex="0"
                                                                                />

                                                                                <div
                                                                                    style={{ width: screen_width + "px", height: screen_width / 2.3 + "px" }}
                                                                                    class="setup-page-place-show-bubble"
                                                                                >
                                                                                    <div
                                                                                        style={{ width: screen_width + "px", height: screen_width / 3 + "px" }}
                                                                                        class="setup-page-promo-photo-box"
                                                                                    >




                                                                                        {this.state.photo ?
                                                                                            <img
                                                                                                class="setup-page--promo-photo-img"
                                                                                                src={this.state.photo}
                                                                                                onClick={() => this.fileInput.click()}
                                                                                                alt=""
                                                                                            />
                                                                                            :
                                                                                            <>
                                                                                                {hub.cover_photo !== "" ?

                                                                                                    <img
                                                                                                        class="setup-page--promo-photo-img"
                                                                                                        onClick={() => this.fileInput.click()}
                                                                                                        src={hub.cover_photo}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    :
                                                                                                    <img
                                                                                                        class="setup-page--promo-photo-img"
                                                                                                        onClick={() => this.fileInput.click()}
                                                                                                        alt=""
                                                                                                    />
                                                                                                }
                                                                                            </>
                                                                                        }

                                                                                        {!this.state.photo ?
                                                                                            <div onClick={() => this.fileInput.click()} class="setup-page-set-cover-photo">
                                                                                                Set Photo
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        }



                                                                                        <input
                                                                                            style={{ display: 'none' }}
                                                                                            accept="image/png, image/jpeg"
                                                                                            type="file"
                                                                                            name="photo"
                                                                                            onChange={this.onCoverPhotoSubmit}
                                                                                            ref={fileInput => this.fileInput = fileInput}
                                                                                        />




                                                                                        <div class="setup-page-bubble-closer-name">
                                                                                            {hub.name}

                                                                                        </div>
                                                                                        <div class="setup-page-bubble-closer-category">
                                                                                            {hub.category}
                                                                                        </div>

                                                                                        {/* {hub.is_open_label === "" ?
                                                                                <>
                                                                                    {hub.is_open ?
                                                                                        <div class="setup-page-bubble-closer-is-open">
                                                                                            <div class="setup-page-bubble-closer-green-bubble"></div>
                                                                                            open
                                                                                        </div>
                                                                                        :
                                                                                        <div class="setup-page-bubble-closer-is-closed">
                                                                                            <div class="setup-page-bubble-closer-red-bubble"></div>
                                                                                            closed
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <div class="setup-page-bubble-closer-has-label">
                                                                                    <div class="setup-page-bubble-closer-yellow-bubble"></div>
                                                                                    {hub.is_open_label}
                                                                                </div>
                                                                            } */}

                                                                                    </div>
                                                                                    <div
                                                                                        style={{ width: screen_width + "px", height: screen_width / 9.857 + "px" }}
                                                                                        class="setup-page-bubble-bottom-text">



                                                                                        {/* {this.state.show_places_bubble ?
                                                                                <div
                                                                                    onClick={() => this.changeShowPlaceBubble()}
                                                                                    class="setup-page-top-down-arrow">
                                                                                    <img
                                                                                        class="setup-page-top-down-arrow-icon"
                                                                                        src={require("../../../assets/map/svg/up-arrow.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                null
                                                                            } */}

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            // : this.state.promoSection ?
                                                                            //     <>
                                                                            //         <div className='setup-page-hubs-swipe-box' >

                                                                            //             <div class="setup-page-hubs-swipe-card">
                                                                            //                 <div
                                                                            //                     // onClick={() => this.promoInput.click()}
                                                                            //                     style={{ backgroundImage: 'url(' + promo_photo + ')' }}
                                                                            //                     class='setup-page-hubs-swipe-square'
                                                                            //                 >

                                                                            //                     <div onClick={() => this.promoInput.click()} class="setup-page-set-promo-photo">
                                                                            //                         Set Photo
                                                                            //                     </div>

                                                                            //                     <input
                                                                            //                         style={{ display: 'none' }}
                                                                            //                         accept="image/png, image/jpeg"
                                                                            //                         type="file"
                                                                            //                         name="photo"
                                                                            //                         onChange={this.onPromoPhotoSubmit}
                                                                            //                         ref={promoInput => this.promoInput = promoInput}
                                                                            //                     />

                                                                            //                     {/* <iframe
                                                                            //             src={hub.map_url}
                                                                            //             class="setup-page-hubs-map-frame"
                                                                            //             aria-hidden="false"
                                                                            //             tabindex="0"
                                                                            //         /> */}

                                                                            //                     <div class="setup-page-hubs-logo-box">
                                                                            //                         <img
                                                                            //                             class="setup-page-hubs-logo-img"
                                                                            //                             src={hub.logo}
                                                                            //                             alt=""
                                                                            //                         />
                                                                            //                     </div>


                                                                            //                     <div class="setup-page-hubs-username">
                                                                            //                         {hub.name}
                                                                            //                     </div>

                                                                            //                     <div class="setup-page-hubs-black-cover-box"> </div>


                                                                            //                     <div class="setup-page-hubs-memories-box-empty">
                                                                            //                         <div class="row ">
                                                                            //                             <div class="setup-page-hubs-empty-photos-box">
                                                                            //                                 <img
                                                                            //                                     class="setup-page-hubs-empty-photos-icon"
                                                                            //                                     src={require("../../../assets/layout/homepage svg/no-post.svg")}
                                                                            //                                     alt=""
                                                                            //                                 />
                                                                            //                             </div>
                                                                            //                             <div class="setup-page-hubs-no-memories-yet">
                                                                            //                                 No Memories yet
                                                                            //                             </div>
                                                                            //                         </div>
                                                                            //                     </div>
                                                                            //                 </div>
                                                                            //             </div>
                                                                            //         </div>
                                                                            //     </>
                                                                            : this.state.socialSection ?
                                                                                <>
                                                                                    {this.state.wifiMiniSection ?
                                                                                        <>
                                                                                            {!this.state.wifiAnswer ?
                                                                                                <>
                                                                                                    <div class="setup-page-reservation-question-text">
                                                                                                        Do you have Wifi Code?
                                                                                                    </div>

                                                                                                    <div class="setup-page-reservation-question-box">
                                                                                                        <div onClick={() => this.finishedWifiMiniSection(false)} class="setup-page-reservation-question-no">
                                                                                                            No
                                                                                                        </div>
                                                                                                        <div onClick={() => this.answerWifiMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                                                            Yes
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <div class="setup-page-info-text">
                                                                                                        Type your Wifi Code.
                                                                                                    </div>

                                                                                                    <div class="setup-page-info-input-box">
                                                                                                        <input
                                                                                                            class='setup-page-info-input-field'
                                                                                                            name="wifi_code"
                                                                                                            onChange={this.onChange}
                                                                                                            placeholder="Wifi Code..."
                                                                                                            value={this.state.wifi_code === "" ? hub.wifi_code : this.state.wifi_code}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div onClick={() => this.finishedWifiMiniSection(false)} class="setup-page-info-done">
                                                                                                        Done
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                        : this.state.telephoneMiniSection ?
                                                                                            <>
                                                                                                {!this.state.telephoneAnswer ?
                                                                                                    <>
                                                                                                        <div class="setup-page-reservation-question-text">
                                                                                                            Do you have a contacting Phone?
                                                                                                        </div>

                                                                                                        <div class="setup-page-reservation-question-box">
                                                                                                            <div onClick={() => this.finishedTelephoneMiniSection(false)} class="setup-page-reservation-question-no">
                                                                                                                No
                                                                                                            </div>
                                                                                                            <div onClick={() => this.answerTelephoneMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                                                                Yes
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div class="setup-page-info-text">
                                                                                                            Type your Telephone Number.
                                                                                                        </div>

                                                                                                        <div class="setup-page-info-input-box">
                                                                                                            <input
                                                                                                                class='setup-page-info-input-field'
                                                                                                                name="telephone"
                                                                                                                onChange={this.onChange}
                                                                                                                placeholder="Telephone Number..."
                                                                                                                value={this.state.telephone === "" ? hub.telephone : this.state.telephone}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div onClick={() => this.finishedTelephoneMiniSection(false)} class="setup-page-info-done">
                                                                                                            Done
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                            : this.state.websiteMiniSection ?
                                                                                                <>
                                                                                                    {!this.state.websiteAnswer ?
                                                                                                        <>
                                                                                                            <div class="setup-page-reservation-question-text">
                                                                                                                Do you have a Website?
                                                                                                            </div>

                                                                                                            <div class="setup-page-reservation-question-box">
                                                                                                                <div onClick={() => this.finishedWebsiteMiniSection(false)} class="setup-page-reservation-question-no">
                                                                                                                    No
                                                                                                                </div>
                                                                                                                <div onClick={() => this.answerWebsiteMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                                                                    Yes
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <div class="setup-page-info-text">
                                                                                                                Type your Website's URL.
                                                                                                            </div>

                                                                                                            <div class="setup-page-info-input-box">
                                                                                                                <input
                                                                                                                    class='setup-page-info-input-field'
                                                                                                                    name="website"
                                                                                                                    onChange={this.onChange}
                                                                                                                    placeholder="Example: https://hubst3r.com"
                                                                                                                    value={this.state.website === "" ? hub.website : this.state.website}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div onClick={() => this.finishedWebsiteMiniSection(false)} class="setup-page-info-done">
                                                                                                                Done
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                : this.state.facebookMiniSection ?
                                                                                                    <>
                                                                                                        {!this.state.facebookAnswer ?
                                                                                                            <>
                                                                                                                <div class="setup-page-reservation-question-text">
                                                                                                                    Do you have a Facebook account?
                                                                                                                </div>

                                                                                                                <div class="setup-page-reservation-question-box">
                                                                                                                    <div onClick={() => this.finishedFacebookMiniSection(false)} class="setup-page-reservation-question-no">
                                                                                                                        No
                                                                                                                    </div>
                                                                                                                    <div onClick={() => this.answerFacebookMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                                                                        Yes
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <div class="setup-page-info-text">
                                                                                                                    Type your Facebook's Account URL.
                                                                                                                </div>

                                                                                                                <div class="setup-page-info-input-box">
                                                                                                                    <input
                                                                                                                        class='setup-page-info-input-field'
                                                                                                                        name="facebook"
                                                                                                                        onChange={this.onChange}
                                                                                                                        placeholder="Example: https://facebook.com/hubst3r"
                                                                                                                        value={this.state.facebook === "" ? hub.facebook_url : this.state.facebook}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div onClick={() => this.finishedFacebookMiniSection(false)} class="setup-page-info-done">
                                                                                                                    Done
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                    : this.state.instagramMiniSection ?
                                                                                                        <>
                                                                                                            {!this.state.instagramAnswer ?
                                                                                                                <>
                                                                                                                    <div class="setup-page-reservation-question-text">
                                                                                                                        Do you have an Instagram account?
                                                                                                                    </div>

                                                                                                                    <div class="setup-page-reservation-question-box">
                                                                                                                        <div onClick={() => this.goToMenuSection(false)} class="setup-page-reservation-question-no">
                                                                                                                            No
                                                                                                                        </div>
                                                                                                                        <div onClick={() => this.answerInstagramMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                                                                            Yes
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <div class="setup-page-info-text">
                                                                                                                        Type your Instagram's Account URL.
                                                                                                                    </div>

                                                                                                                    <div class="setup-page-info-input-box">
                                                                                                                        <input
                                                                                                                            class='setup-page-info-input-field'
                                                                                                                            name="instagram"
                                                                                                                            onChange={this.onChange}
                                                                                                                            placeholder="Example: https://instagram.com/hubst3r"
                                                                                                                            value={this.state.instagram === "" ? hub.instagram_url : this.state.instagram}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div onClick={() => this.goToMenuSection(false)} class="setup-page-info-done">
                                                                                                                        Done
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }
                                                                                                        </>
                                                                                                        : null
                                                                                    }

                                                                                </>
                                                                                : this.state.menuSection ?
                                                                                    <>

                                                                                        {this.state.uploading_pages ?
                                                                                            <>
                                                                                                <div class="setup-page-lottie-position">
                                                                                                    <Lottie
                                                                                                        // class="lottie"
                                                                                                        // style={{ marginTop: "30%", width: "10%" }}
                                                                                                        options={defaultOptions}
                                                                                                    // height={600}
                                                                                                    // width={600}
                                                                                                    />
                                                                                                </div>






                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {this.state.catalogView ?
                                                                                                    <>
                                                                                                        {!this.state.catalogNameView ?
                                                                                                            <>
                                                                                                                <div
                                                                                                                    class="setup-page-new-catalog-btn"
                                                                                                                    onClick={() => this.setCatalogName()}>
                                                                                                                    Create New Catalog
                                                                                                                </div>

                                                                                                                <div class="setup-page-all-catalogs">
                                                                                                                    <Slider {...tagSettings} >
                                                                                                                        {catalogs.map(catalog => (
                                                                                                                            <>
                                                                                                                                {catalog.first_page ?
                                                                                                                                    <>
                                                                                                                                        <div class="setup-page-catalog-select-budies-frame">

                                                                                                                                            <div
                                                                                                                                                onClick={() => this.openCatalogPagesView(catalog.id, catalog.name)}
                                                                                                                                                class="setup-page-catalog-buddies-card">
                                                                                                                                                <div
                                                                                                                                                    style={{ backgroundImage: 'url(' + catalog.first_page + ')' }}
                                                                                                                                                    class='setup-page-catalog-buddies-card-square'
                                                                                                                                                >

                                                                                                                                                    <div class="setup-page-catalog-buddies-username">
                                                                                                                                                        {/* elenacharalambous */}
                                                                                                                                                        {catalog.name}
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            onClick={() => this.removeCatalog(catalog.id)}
                                                                                                                                            class="setup-page-catalog-remove">
                                                                                                                                            Remove
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                    : null
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        ))}

                                                                                                                    </Slider>
                                                                                                                </div>

                                                                                                                <div onClick={() => this.goToSocialSection()} class="setup-page-back-btn">
                                                                                                                    Back
                                                                                                                </div>
                                                                                                                <div onClick={() => this.acceptHubsTerms()} class="setup-page-next-btn-half">
                                                                                                                    Done
                                                                                                                </div>

                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <div class="setup-page-info-text">
                                                                                                                    Type a name for the catalog.
                                                                                                                </div>

                                                                                                                <div class="setup-page-info-input-box">
                                                                                                                    <input
                                                                                                                        class='setup-page-info-input-field'
                                                                                                                        name="catalog_name"
                                                                                                                        onChange={this.onChange}
                                                                                                                        placeholder="Catalog name..."
                                                                                                                        value={this.state.catalog_name}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div onClick={() => this.createNewCatalog()} class="setup-page-info-done">
                                                                                                                    Done
                                                                                                                </div>
                                                                                                                <div onClick={() => this.setCatalogName()} class="setup-page-back-btn-big">
                                                                                                                    Back
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }

                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {this.state.viewMenuPages ?
                                                                                                            <>
                                                                                                                <div class="setup-page-logo-text">
                                                                                                                    {this.state.catalog_view_name}'s Pages
                                                                                                                </div>
                                                                                                                <div class="setup-page-all-catalogs">
                                                                                                                    <Slider {...tagSettings} >
                                                                                                                        {this.props.menu_pages.map(page => (
                                                                                                                            <>
                                                                                                                                {page.photo ?
                                                                                                                                    <div class="setup-page-catalog-select-budies-frame">
                                                                                                                                        <div class="setup-page-catalog-buddies-card">
                                                                                                                                            <div
                                                                                                                                                style={{ backgroundImage: 'url(' + page.photo + ')' }}
                                                                                                                                                class='setup-page-catalog-buddies-card-square'
                                                                                                                                            >
                                                                                                                                                <div class="setup-page-catalog-buddies-username">
                                                                                                                                                    {/* elenacharalambous */}
                                                                                                                                                    {page.page}
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    null
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        ))}
                                                                                                                    </Slider>
                                                                                                                    <div onClick={() => this.closeCatalogPagesView()} class="setup-page-back-btn-big">
                                                                                                                        Back
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>

                                                                                                                <div class="setup-page-logo-text">
                                                                                                                    Upload the Pages of your Catalog
                                                                                                                </div>

                                                                                                                <div
                                                                                                                    class="setup-page-menu-input-box"
                                                                                                                    onClick={() => this.menuInput.click()}
                                                                                                                >
                                                                                                                    <img
                                                                                                                        class="setup-page-menu-input-img"
                                                                                                                        src={require("../../../assets/hub/hub-homepage/svg/menu.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="setup-page-menu-input-btn"
                                                                                                                    onClick={() => this.menuInput.click()}
                                                                                                                >
                                                                                                                    Upload Pages
                                                                                                                </div>

                                                                                                                <input
                                                                                                                    class="setup-page-pdf-input"
                                                                                                                    onChange={this.onMenuPagesUpload}
                                                                                                                    id="menu-pages-to-upload"
                                                                                                                    name="menu_photos"
                                                                                                                    accept="image/png, image/jpeg"
                                                                                                                    type="file"
                                                                                                                    multiple
                                                                                                                    style={{ display: 'none' }}
                                                                                                                    ref={menuInput => this.menuInput = menuInput}
                                                                                                                />
                                                                                                                <div onClick={() => this.changeCatalogView()} class="setup-page-back-btn-big">
                                                                                                                    Back
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </>

                                                                                                }
                                                                                            </>
                                                                                        }

                                                                                    </>

                                                                                    :

                                                                                    null
                                            }
                                        </>
                                    </div>
                                </div>

                                {this.state.logoNameSection ?
                                    <div onClick={() => this.goToWorkingHoursSection()} class="setup-page-next-btn">
                                        Next
                                    </div>
                                    : this.state.workingHoursSection ?
                                        <>
                                            <div onClick={() => this.goToLogoNameSection()} class="setup-page-back-btn">
                                                Back
                                            </div>
                                            <div onClick={() => this.goToProvideSection()} class="setup-page-next-btn-half">
                                                Next
                                            </div>
                                        </>
                                        : this.state.placeProvideSection ?
                                            <>
                                                <div onClick={() => this.goToWorkingHoursSection()} class="setup-page-back-btn-big">
                                                    Back
                                                </div>
                                            </>
                                            : this.state.acceptReservationSection ?
                                                <>
                                                    <div onClick={() => this.goToProvideSection()} class="setup-page-back-btn-big">
                                                        Back
                                                    </div>

                                                </>
                                                : this.state.acceptAppointmentSection ?
                                                    <>
                                                        <div onClick={() => this.goToProvideSection()} class="setup-page-back-btn-big">
                                                            Back
                                                        </div>
                                                    </>
                                                    : this.state.onlineShopSection ?
                                                        <>
                                                            <div onClick={() => this.goToProvideSection()} class="setup-page-back-btn-big">
                                                                Back
                                                            </div>
                                                        </>
                                                        : this.state.categorySection ?
                                                            <>
                                                                <div onClick={() => this.goToProvideSection()} class="setup-page-back-btn">
                                                                    Back
                                                                </div>
                                                                <div onClick={() => this.goToMapSection()} class="setup-page-next-btn-half">
                                                                    Next
                                                                </div>
                                                            </>
                                                            : this.state.mapSection ?
                                                                <>
                                                                    <div onClick={() => this.goToCategorySection()} class="setup-page-back-btn">
                                                                        Back
                                                                    </div>
                                                                    <div onClick={() => this.goToSocialSection()} class="setup-page-next-btn-half">
                                                                        Next
                                                                    </div>
                                                                </>
                                                                // : this.state.promoSection ?
                                                                //     <>
                                                                //         <div onClick={() => this.goToMapSection()} class="setup-page-back-btn">
                                                                //             Back
                                                                //         </div>
                                                                //         <div onClick={() => this.goToSocialSection()} class="setup-page-next-btn-half">
                                                                //             Next
                                                                //         </div>
                                                                //     </>
                                                                : this.state.socialSection ?
                                                                    <>
                                                                        <div onClick={() => this.goToMapSection()} class="setup-page-back-btn">
                                                                            Back
                                                                        </div>
                                                                        <div onClick={() => this.goToMenuSection()} class="setup-page-next-btn-half">
                                                                            Next
                                                                        </div>
                                                                    </>
                                                                    // : this.state.menuSection && this.state.catalogView ?
                                                                    //     <div onClick={() => this.acceptHubsTerms()} class="setup-page-next-btn">
                                                                    //         Done
                                                                    //     </div>
                                                                    :
                                                                    null
                                }


                            </>
                        ))}
                    </>
                }
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    profile: state.profile.profile,
    working_days: state.hub.working_days,
    catalogs: state.hub.catalogs,
    new_catalog: state.hub.new_catalog,
    menu_pages: state.hub.menu_pages,
    reservation_hours: state.hub.reservation_hours,
    appointment_hours: state.hub.appointment_hours,

});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    getHubWorkingDays,
    acceptHubsTerms,
    hubWorkingDayClose,
    setWorkingDayHours,
    uploadHubLogo,
    uploadHubCoverPhoto,
    uploadHubPromoPhoto,
    setHubName,
    setHubCategory,
    setHubMapIcon,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
    setHubReservations,
    setHubAppointments,
    setHubOnlineShop,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    selectHubReservationHours,
    selectHubAppointmentHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
    createHubMenuQr,
})(SetupPage);