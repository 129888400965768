import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import HubBottomNavbar from '../../../components/hub/layout/hubBottomNavabar';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import '../../../assets/menu/menu-page.css'
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { getHubMenuPages, getHubCatalogs, getHubFirstCatalogPages, getHubMenuQr } from '../../../store/actions/hub'
import HTMLFlipBook from 'react-pageflip';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from 'react-awesome-modal';



export class MenuPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            totalPage: 0,
            page_selected: false,
            selected_page: "",
            catalog_name: "",
            pagesMode: false,
            nav1: null,
            nav2: null,
            miniCatalogMode: false,
            page_show_more: false,
            page_changing: false,
            showQrModal: false
        };
    }

    componentWillMount() {
        this.props.getHubFirstCatalogPages(this.props.match.params.hubID)
        this.props.getHubCatalogs(this.props.match.params.hubID)
        this.props.getHubMenuQr(this.props.match.params.hubID)

        // setTimeout(() => {
        //     if (this.props.catalogs.length === 1) {
        //         this.setState({
        //             pagesMode: true,
        //         });
        //     }
        // }, 1000);
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    goToCatalogSection() {
        this.setState({
            page: 0,
            totalPage: 0,
            page_selected: false,
            selected_page: "",
            catalog_name: "",
            pagesMode: false,
            nav1: null,
            nav2: null,
            page_show_more: false,
            page_changing: false,
            miniCatalogMode: false,
        })
    }

    openZoomPage(page_photo) {
        this.setState({
            page_selected: true,
            selected_page: page_photo
        });
    };

    closeZoomPage() {
        this.setState({
            page_selected: false,
            selected_page: ""
        });
        setTimeout(() => {
            this.flipBook.pageFlip().turnToPage(this.state.page);
        }, 100);
    };

    nextButtonClick() {
        this.setState({
            page_selected: false,
            selected_page: ""
        });

        this.flipBook.pageFlip().flipNext();
    };

    prevButtonClick() {
        this.setState({
            page_selected: false,
            selected_page: ""
        });
        this.flipBook.pageFlip().flipPrev();
    };

    onPage = (e) => {
        this.setState({
            page: e.data,
            page_changing: false,
            page_show_more: false,
        });
    };

    handleBack = () => {
        this.props.history.goBack()
    }

    openCatalogPagesView(catalog_id, catalog_name) {
        this.props.getHubMenuPages(catalog_id)
        this.setState({
            miniCatalogMode: false,
            pagesMode: true,
            catalog_name: catalog_name,
            page: 0
        })
    }

    openSelectCatalogMini() {
        this.setState({
            miniCatalogMode: !this.state.miniCatalogMode
        })
    }


    openShowMoreDescription() {
        this.setState({
            page_show_more: !this.state.page_show_more,
        })
    }

    openMenuQrModal() {
        this.setState({
            showQrModal: true
        })
    }

    closeModal() {
        this.setState({
            showQrModal: false
        })
    }

    render() {

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const tagSettings = {
            dots: true,
            infinite: false,
            slidesToShow: 1,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        let user = JSON.parse(localStorage.getItem('ur'))
        const height = window.innerHeight
        const width = window.innerWidth

        let catalogsMode = true
        if (this.state.pagesMode) {
            catalogsMode = false
        }

        let menu_pages = []
        if (this.state.pagesMode) {
            menu_pages = this.props.menu_pages
        }

        let small_width = 0.3
        if (this.props.catalogs.length > 2) {
            small_width = 0.2
        }

        let current_page = {}
        if (this.props.menu_pages.length > 0) {
            current_page = this.props.menu_pages[this.state.page]
        }

        let hub = {}
        if (this.props.hub.length > 0) {
            hub = this.props.hub[0]
        }

        return (
            <>
                <div class="menu-zoom-all-menu-size">

                    {this.state.showQrModal && this.props.hub.length > 0 ?
                        <Modal visible={this.state.showQrModal} width="100%" height="65%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                            <div class="menu-qr-modal-box">
                                <div onClick={() => this.closeModal()} class="menu-qr-acces-modal-times-box">
                                    <img
                                        class="menu-qr-acces-modal-times-img"
                                        src={require("../../../assets/layout/homepage svg/times.svg")}
                                        alt=""
                                    />
                                </div>
                                <div class="menu-qr-modal-title">
                                    This Page's Qr Code
                                </div>
                                <div
                                    style={{ width: width * 0.8, height: width * 0.8 }}
                                    class="menu-qr-modal-qr-box">
                                    <img
                                        class="menu-qr-modal-qr-img"
                                        src={hub.menu_qr_code}
                                        alt=""
                                    />
                                </div>
                                <a
                                    href={hub.menu_qr_code}
                                    download="catalog-qr-code"
                                    class="menu-qr-modal-download-btn"
                                >
                                    Download Qr
                                </a>
                            </div>
                        </Modal>
                        :
                        null
                    }

                    <div class="menu-top-navbar-layout">
                        {user ?
                            <>
                                {catalogsMode ?
                                    <div
                                        onClick={() => this.handleBack()}
                                        class="row menu-top-navbar-back"
                                    >
                                        <div class="menu-top-navbar-back-box">
                                            <img
                                                class="menu-top-navbar-back-icon"
                                                src={require("../../../assets/menu/svg/back.svg")}
                                                alt=""
                                            />
                                        </div>
                                        Back
                                    </div>
                                    :
                                    <>
                                        {!this.state.miniCatalogMode && this.props.catalogs.length > 1 ?
                                            <div onClick={() => this.openSelectCatalogMini()}
                                                style={{ maxWidth: width }}
                                                class="row menu-top-navbar-catalog">
                                                <div
                                                    onClick={() => this.goToCatalogSection()}
                                                    class="row menu-top-navbar-back-2"
                                                >
                                                    <div class="menu-top-navbar-back-box">
                                                        <img
                                                            class="menu-top-navbar-back-icon"
                                                            src={require("../../../assets/menu/svg/back.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    Back
                                                </div>

                                                {this.state.catalog_name}
                                                <div class="menu-has-more-catalogs">
                                                    <img
                                                        class="profiles-mobile-navbar-has-hubs-icon"
                                                        src={require("../../../assets/profile/svg/down-arrow.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <div
                                                onClick={() => this.handleBack()}
                                                class="row menu-top-navbar-back"
                                            >
                                                <div class="menu-top-navbar-back-box">
                                                    <img
                                                        class="menu-top-navbar-back-icon"
                                                        src={require("../../../assets/menu/svg/back.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                Back
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                {catalogsMode ?
                                    <Link to="/">
                                        <div class="menu-top-navbar-logo">
                                            JOIN HUBST3R NOW
                                        </div>
                                    </Link>
                                    :
                                    <>
                                        {!this.state.miniCatalogMode && this.props.catalogs.length > 1 ?
                                            <div onClick={() => this.openSelectCatalogMini()}
                                                style={{ maxWidth: width }}
                                                class="row menu-top-navbar-catalog">
                                                <Link to="/">
                                                    JOIN HUBST3R NOW
                                                </Link>
                                                <div class="menu-has-more-catalogs">
                                                    <img
                                                        class="profiles-mobile-navbar-has-hubs-icon"
                                                        src={require("../../../assets/profile/svg/down-arrow.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>

                    {this.state.miniCatalogMode ?
                        <div class="menu-page-catalog-mini-slider-up">
                            <Slider
                                // asNavFor={this.state.nav1}
                                // ref={slider => (this.slider2 = slider)}
                                slidesToShow={2}
                                swipeToSlide={true}
                                focusOnSelect={true}
                            >
                                {this.props.catalogs.map(catalog => (
                                    <>
                                        {catalog.first_page ?
                                            <>
                                                <div
                                                    style={{
                                                        width: 0.36 * width,
                                                        height: 0.28 * height,
                                                        marginLeft: 0.07 * width
                                                    }}
                                                    class="menu-page-catalog-select-budies-frame">

                                                    <div
                                                        onClick={() => this.openCatalogPagesView(catalog.id, catalog.name)}
                                                        class="menu-page-catalog-buddies-card"
                                                    >
                                                        <img
                                                            class="menu-page-catalog-img"
                                                            src={catalog.first_page}
                                                            alt=""
                                                        />

                                                    </div>
                                                    <div class="menu-page-catalog-buddies-username-small-up">
                                                        {catalog.name}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                ))}

                            </Slider>
                            <div onClick={() => this.openSelectCatalogMini()} class="menu-has-more-catalogs-don">
                                <img
                                    class="profiles-mobile-navbar-has-hubs-icon"
                                    src={require("../../../assets/profile/svg/down-arrow.svg")}
                                    alt=""
                                />
                            </div>
                        </div>
                        :
                        null
                    }

                    {this.state.miniCatalogMode ?
                        null
                        :
                        <>
                            {catalogsMode ?
                                <>
                                    <div class="menu-catalogs-box">
                                        <Slider
                                            {...tagSettings}
                                            asNavFor={this.state.nav2}
                                            ref={slider => (this.slider1 = slider)}
                                        >
                                            {this.props.catalogs.map(catalog => (
                                                <>
                                                    {catalog.first_page ?
                                                        <>
                                                            {/* <div class="menu-page-catalog-buddies-username">
                                                                {catalog.name}
                                                            </div> */}
                                                            <div
                                                                style={{ width: 0.95 * width, height: 0.75 * height, marginLeft: 0.025 * width }}
                                                                class="menu-page-catalog-select-budies-frame">

                                                                <div
                                                                    onClick={() => this.openCatalogPagesView(catalog.id, catalog.name)}
                                                                    class="menu-page-catalog-buddies-card"
                                                                >
                                                                    <img
                                                                        class="menu-page-catalog-img"
                                                                        src={catalog.first_page}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        null
                                                    }
                                                </>
                                            ))}

                                        </Slider>
                                    </div>

                                    <div class="menu-page-catalog-mini-slider">
                                        <Slider
                                            asNavFor={this.state.nav1}
                                            ref={slider => (this.slider2 = slider)}
                                            slidesToShow={this.props.catalogs.length > 2 ? 3 : 2}
                                            swipeToSlide={true}
                                            focusOnSelect={true}
                                        >
                                            {this.props.catalogs.map(catalog => (
                                                <>
                                                    {catalog.first_page ?
                                                        <>
                                                            <div
                                                                style={{
                                                                    width: small_width * width,
                                                                    height: 0.1 * height,
                                                                    marginLeft: 0.025 * width
                                                                }}
                                                                class="menu-page-catalog-select-budies-frame">

                                                                <div
                                                                    // onClick={() => this.openCatalogPagesView(catalog.id)}
                                                                    class="menu-page-catalog-buddies-card"
                                                                >
                                                                    <img
                                                                        class="menu-page-catalog-img"
                                                                        src={catalog.first_page}
                                                                        alt=""
                                                                    />

                                                                </div>
                                                                {/* <div class="menu-page-catalog-buddies-username-small">
                                                                    {catalog.name}
                                                                </div> */}
                                                            </div>


                                                        </>
                                                        :
                                                        null
                                                    }
                                                </>
                                            ))}

                                        </Slider>
                                    </div>

                                    {this.props.hub.length > 0 && hub.menu_qr_code ?
                                        <div onClick={() => this.openMenuQrModal()} class="menu-page-download-qr">
                                            <img
                                                class="menu-bottom-navbar-icon"
                                                src={require("../../../assets/layout/homepage svg/QR Scan.svg")}
                                                alt=""
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </>
                                :
                                <>
                                    {this.state.page_selected ?
                                        < TransformWrapper
                                            initialScale={1.5}
                                            initialPositionX={-80}
                                            initialPositionY={-250}
                                        >
                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                <Fragment>
                                                    {/* <div className="tools">
                                        <button onClick={() => zoomIn()}>+</button>
                                        <button onClick={() => zoomOut()}>-</button>
                                        <button onClick={() => resetTransform()}>x</button>
                                    </div> */}

                                                    <TransformComponent>
                                                        < div class="menu-zoom-page-size" >
                                                            {menu_pages.map(page => (
                                                                <>
                                                                    {page.page === this.state.page + 1 ?
                                                                        <img
                                                                            class="menu-zoom-page-img-size"
                                                                            src={page.photo}
                                                                            alt=""
                                                                        />
                                                                        : null
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    </TransformComponent>


                                                    <div class="fixed-bottom menu-bottom-navbar ">
                                                        <div
                                                            class="menu-bottom-navbar-small-zoom-1"
                                                            onClick={() => zoomOut()}
                                                        >
                                                            <div class="menu-bottom-navbar-btn">
                                                                <img
                                                                    class="menu-bottom-navbar-icon"
                                                                    src={require("../../../assets/menu/svg/zoom-out.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>


                                                        <div class="menu-bottom-navbar-small-2" >  </div>


                                                        <div
                                                            class="menu-bottom-navbar-big"
                                                            onClick={() => this.closeZoomPage()}
                                                        >
                                                            {/* <div
                                                                class="menu-bottom-navbar-big-btn">
                                                                <img
                                                                    class="menu-bottom-navbar-logo"
                                                                    src={require("../../../assets/menu/svg/reset.png")}
                                                                    alt=""
                                                                />
                                                            </div> */}
                                                            <div class="menu-bottom-zoom-out-btn">
                                                                Zoom Out
                                                            </div>
                                                        </div>


                                                        <div class="menu-bottom-navbar-small-3" > </div>


                                                        <div
                                                            class="menu-bottom-navbar-small-zoom-4"
                                                            onClick={() => zoomIn()}
                                                        >
                                                            <div
                                                                class="menu-bottom-navbar-btn">
                                                                <img
                                                                    class="menu-bottom-navbar-icon"
                                                                    src={require("../../../assets/menu/svg/zoom-in.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Fragment>
                                            )}
                                        </TransformWrapper>
                                        :

                                        <>

                                            <div class={this.state.page_selected ? "menu-all-menu-size-none" : "menu-all-menu-size"}>

                                                <HTMLFlipBook

                                                    width={600}
                                                    height={1000}
                                                    showCover={true}
                                                    onFlip={this.onPage}
                                                    ref={(el) => (this.flipBook = el)}
                                                >
                                                    {menu_pages.map(page => (
                                                        <div >
                                                            < div class="menu-page-size">
                                                                <img
                                                                    class="menu-page-img-size"
                                                                    src={page.photo}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}

                                                </HTMLFlipBook>

                                            </div >

                                            <div class="menu-all-menu-size-small">
                                                <HTMLFlipBook
                                                    size="fixed"
                                                    width={width}
                                                    height={height}
                                                    // showCover={true}
                                                    // mobileScrollSupport={true}
                                                    // onFlip={this.onPage}
                                                    // ref={(el) => (this.flipBook = el)}
                                                    // size="stretch"
                                                    // minWidth={315}
                                                    // maxWidth={1000}
                                                    // minHeight={400}
                                                    // maxHeight={1533}
                                                    maxShadowOpacity={0.5}
                                                    showCover={true}
                                                    mobileScrollSupport={true}
                                                    onFlip={this.onPage}
                                                    onChangeOrientation={this.onChangeOrientation}
                                                    onChangeState={this.onChangeState}
                                                    className="demo-book"
                                                    ref={(el) => (this.flipBook = el)}
                                                >

                                                    {menu_pages.map(page => (
                                                        // <div style={{ backgroundColor: "rgba(35, 45, 80)" }}>
                                                        < div class="menu-page-size" >
                                                            <img
                                                                class="menu-page-img-size"
                                                                src={page.photo}
                                                                alt=""
                                                            />
                                                        </div>
                                                        // </div>
                                                    ))}
                                                </HTMLFlipBook>
                                            </div >


                                            {this.state.page_show_more && this.state.page + 1 === current_page.page ?
                                                <>

                                                    <div class="setup-page-menu-show-more-box">
                                                        <div class="setup-page-menu-show-more-description">
                                                            <div class="setup-page-menu-show-more-down-arrow-box">
                                                                <img
                                                                    onClick={() => this.openShowMoreDescription()}
                                                                    class="setup-page-menu-show-more-down-arrow"
                                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            {current_page.description}
                                                        </div>
                                                        <div class="setup-page-menu-show-more-price">
                                                            {current_page.price}
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div class="row setup-page-menu-bottom-place-row">
                                                    {current_page.has_description ?
                                                        <div onClick={() => this.openShowMoreDescription()} class="setup-page-menu-bottom-page-description">
                                                            {current_page.description.length > 51 ?
                                                                <>
                                                                    {current_page.description.slice(0, 50)}&nbsp;&nbsp;
                                                                    <span class="setup-page-menu-show-blue">see more...</span>
                                                                </>
                                                                :
                                                                <>
                                                                    {current_page.description}
                                                                </>
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    {current_page.has_price ?
                                                        <div onClick={() => this.openShowMoreDescription()} class="setup-page-menu-bottom-page-price">
                                                            Price:
                                                            <br />
                                                            {current_page.price}

                                                        </div>
                                                        :
                                                        null
                                                    }

                                                </div>
                                            }

                                            <div
                                                class="menu-bottom-navbar-big-zoom"
                                                onClick={() => this.openZoomPage()}
                                            >
                                                <div
                                                    class="menu-bottom-navbar-big-btn-zoom">

                                                    <img
                                                        class="menu-bottom-navbar-logo-zoom"
                                                        src={require("../../../assets/menu/svg/zoom.svg")}
                                                        alt=""
                                                    />

                                                </div>
                                            </div>

                                            {this.state.page === 0 ?
                                                null
                                                :
                                                <div
                                                    class="menu-bottom-navbar-small-1"
                                                    onClick={() => this.prevButtonClick()}
                                                >
                                                    <div
                                                        class="menu-bottom-navbar-btn-zoom">
                                                        <img
                                                            class="menu-bottom-navbar-icon"
                                                            src={require("../../../assets/menu/svg/back.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {this.props.menu_pages.length - 1 === this.state.page ?
                                                null
                                                :
                                                <div
                                                    class="menu-bottom-navbar-small-4"
                                                    onClick={() => this.nextButtonClick()}
                                                >
                                                    <div
                                                        class="menu-bottom-navbar-btn-zoom">
                                                        <img
                                                            class="menu-bottom-navbar-icon"
                                                            src={require("../../../assets/menu/svg/next.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </>

                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    hub: state.hub.hub_menu_qr,
    menu_pages: state.hub.menu_pages,
    catalogs: state.hub.catalogs,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    getHubMenuPages,
    getHubCatalogs,
    getHubFirstCatalogPages,
    getHubMenuQr
})(MenuPage);