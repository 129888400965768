import {
    GET_USER_PROFILE,
    USER_PROFILE_LOCALY_LOADED,
    GET_OTHER_USER_PROFILE,
    UPDATE_PROFILE,
    UPDATE_PROFILE_PICTURE,
    GET_PROFILE_BUDDIES,
    GET_PROFILE_BUDDIES_LOCALLY_LOADED,
    GET_PROFILE_HUB_LIST,
    GET_PROFILE_HUB_LIST_LOCALLY_LOADED,
    GET_PROFILE_GAGS,
    GET_PROFILE_GAGS_LOCALY_LOADED,
    SCAN_QR_CODE,
    CLEAR_PROFILE,
    GET_ADD_RESERVATION_PROFILE,
    GET_PROFILE_HUBS_ACCESS,
    SWITCH_TO_HUB_ACCOUNT
} from '../actions/actionTypes';
import { updateObject } from "../utility";


const initialState = {
    profile: [],
    user_profile: [],
    buddies: [],
    hubs: [],
    gags: [],
    scanInfo: [],
    hubs_access: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_PROFILE:
            return {
                ...state,
                profile: [],
                user_profile: [],
                buddies: [],
                hubs: [],
                gags: [],
                scanInfo: []
            };
        case GET_USER_PROFILE:
            localStorage.setItem('urpfl', JSON.stringify(action.payload));
            return {
                ...state,
                profile: action.payload
            };

        case USER_PROFILE_LOCALY_LOADED:
            return {
                ...state,
                profile: JSON.parse(localStorage.getItem('urpfl'))
            };
        case GET_PROFILE_HUBS_ACCESS:
            return {
                ...state,
                hubs_access: action.payload
            };

        case GET_ADD_RESERVATION_PROFILE:
            return {
                ...state,
                user_profile: action.payload
            };
        case GET_OTHER_USER_PROFILE:
            return {
                ...state,
                user_profile: action.payload
            };
        case GET_PROFILE_BUDDIES:
            localStorage.setItem('urbd', JSON.stringify(action.payload));
            return {
                ...state,
                buddies: action.payload
            };
        case GET_PROFILE_BUDDIES_LOCALLY_LOADED:
            return {
                ...state,
                buddies: JSON.parse(localStorage.getItem('urbd'))
            };
        case GET_PROFILE_HUB_LIST:
            localStorage.setItem('urpflhbl', JSON.stringify(action.payload));
            return {
                ...state,
                hubs: action.payload
            };
        case GET_PROFILE_HUB_LIST_LOCALLY_LOADED:
            return {
                ...state,
                hubs: JSON.parse(localStorage.getItem('urpflhbl'))
            };
        case GET_PROFILE_GAGS:
            localStorage.setItem('urpflg', JSON.stringify(action.payload));
            return {
                ...state,
                gags: action.payload
            };
        case GET_PROFILE_GAGS_LOCALY_LOADED:
            return {
                ...state,
                gags: JSON.parse(localStorage.getItem('urpflg'))
            };

        default:
            return state;
    }
}