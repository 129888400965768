import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class BuddyRequest extends Component {
    state = {
        request: ""
    };

    acceptBuddyRequest = (user, id) => (e) => {
        const sending_user = user
        const recieving_user = JSON.parse(localStorage.getItem('ur')).id
        const status = "Accepted"
        const answer = { sending_user, recieving_user, status };
        // this.props.deleteNotification(id)
        this.props.answerBuddyRequest(answer);
        this.setState({ request: "Accepted" })
    }

    declineBuddyRequest = (user, id) => (e) => {
        const sending_user = user
        const recieving_user = JSON.parse(localStorage.getItem('ur')).id
        const status = "Declined"
        const answer = { sending_user, recieving_user, status };
        // this.props.deleteNotification(id)
        this.props.answerBuddyRequest(answer);
        this.setState({ request: "Declined" })
    }

    render() {
        const { notification } = this.props.data
        return (
            <Fragment>
                <div class="row single-item-9">
                    <div class="notification-related-photo-position">
                        <Link to={{ pathname: `/user-profile/${notification.related_user_id}/profile`, state: { user: notification.related_user_id } }}>
                            {notification.related_picture ?
                                <img class="related-picture-layout" src={notification.related_picture} alt="" />
                                :
                                <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                            }
                        </Link>
                    </div>
                    {/* <span class="notification-section-icon-layout">
                        <img class="notification-section-icon" src={require("../../../assets/notifications/svg/Buddies Icon-g.svg")} alt="" />
                    </span> */}
                    <div class="notification-information-section">
                        <div class="notification-text-position">
                            <p class="text-blue-9 notification-text-layout">
                                <strong>{notification.related_name}</strong> wants to be your Buddy!!!
                            </p>
                        </div>
                    </div>
                    <div class="row notification-buttons-layout">
                        {this.state.request === "Accepted" ?
                            <div
                                class="btn-light-notifications"
                            >
                                Accepted
                            </div>
                            : this.state.request === "Declined" ?
                                <div
                                    class="btn-light-notifications"
                                >
                                    Declined
                                </div>
                                :
                                <>
                                    <div
                                        onClick={this.acceptBuddyRequest(notification.related_user_id, notification.id)}
                                        class="btn-green-9"
                                    >
                                        Accept
                                    </div>
                                    <div
                                        onClick={this.declineBuddyRequest(notification.related_user_id, notification.id)}
                                        class="btn-red-9"
                                    >
                                        Decline
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </Fragment >
        )
    }
}


export default (BuddyRequest);