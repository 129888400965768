import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import { getMyProfile, getUserProfile } from '../../../store/actions/profile';
import { getNotificationsStatus } from '../../../store/actions/notification';
import {
    sendVoiceMessage,
    sendMessageWithImage,
    getConversationMessages,
    getTheConversation,
    setMessageToListened,
    setConversationBackground,
    setConversationMyColor,
    clearStateMessages,
} from '../../../store/actions/chat';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import "../../../assets/chat/conversation-room.css"
import Modal from 'react-awesome-modal';
// import moment from "moment-timezone";
import { ShowMessageModal } from '../../../components/user/chat/showMessageModal';
// import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { ConversationDesignModal } from '../../../components/user/chat/conversationDesignModal';
import TextareaAutosize from 'react-textarea-autosize';
import Stories from 'react-insta-stories';

export class ConversationRoom extends Component {

    state = {
        showMessageModal: false,
        showDesignModal: false,
        message: "",
        messageTime: "",
        username: "",
        profile_picture: "",
        conversation_id: "",
        newBackground: "",
        messageColor: "",
        text_message: "",
        new_photo: null,
        photo: "",
        photo_submited: false,
        showstories: false,
        memory_to_show: "",
        is_story_paused: false,
    };

    componentWillMount() {
        this.props.getUserProfile(this.props.location.state.selected_user);
        this.props.getMyProfile();
        this.props.getTheConversation(this.props.location.state.selected_user);
        this.props.getConversationMessages(this.props.location.state.selected_user);
        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }
    }

    componentDidMount() {
        this.scrollToBottom();
        this.interval = setInterval(() => this.props.getConversationMessages(this.props.location.state.selected_user), 10000);
    }

    componentWillUnmount() {
        this.props.clearStateMessages()
        clearInterval(this.interval);
    }


    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

    scrollToBottom = () => {
        const srolRef = this.myRef
        if (this.myRef !== undefined || this.myRef !== null) {
            try {
                // setTimeout(() => {
                srolRef.scrollIntoView()
                // }, 1000);
            } catch (e) {
                console.error(e);
            }

        }
        // setTimeout(() => {
        //     this.myRef.scrollIntoView()
        // }, 1300);
        // this.myRef.current.scrollIntoView()
        // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    };


    componentDidUpdate() {
        this.scrollToBottom();
    }


    handleBack = () => {
        this.props.history.goBack()
    }

    openShowMessageModal(message, profile_picture, username, time, message_id) {
        this.setState({
            showMessageModal: true,
            message: message,
            messageTime: time,
            username: username,
            profile_picture: profile_picture,
        })
        if (message_id !== "") {
            const id = message_id
            const not_listened = false
            const message = { id, not_listened }
            this.props.setMessageToListened(message)
        }
    }

    openShowDesignModal(conversation_id) {
        this.setState({
            showDesignModal: true,
            conversation_id: conversation_id
        })
    }

    closeModal = () => {
        this.setState({
            showMessageModal: false,
            showDesignModal: false,
            message: "",
            messageTime: "",
            username: "",
            profile_picture: "",
            conversation_id: "",
            showstories: false,
            memory_to_show: "",
            is_story_paused: false,
        })
    }

    setNewBackground = (newBackground) => {
        this.setState({
            newBackground: newBackground,
        })
    }

    setNewMessageColor = (messageColor) => {
        this.setState({
            messageColor: messageColor,
        })
        let conversation = this.props.conversation[0]
        const current_user = this.props.profile[0].user
        let person_1_color = ""
        let person_2_color = ""
        if (current_user === conversation.person_1) {
            person_1_color = messageColor

        } else {
            person_2_color = messageColor
        }
        const id = conversation.id
        const new_conversation = { id, person_1_color, person_2_color }

        this.props.setConversationMyColor(new_conversation)
    }


    sendMessageToBuddy = e => {
        e.preventDefault();
        const profile = this.props.profile[0]
        const sending_user = profile.user
        const recieving_user = this.props.location.state.selected_user
        const voice_message = ""
        const text_message = this.state.text_message
        const message_file = this.state.new_photo
        const has_file = this.state.photo_submited
        const message = { sending_user, recieving_user, voice_message, text_message, message_file, has_file }

        if (has_file) {
            this.props.sendMessageWithImage(message)
        } else {
            this.props.sendVoiceMessage(message)
        }

        window.onload = document.getElementById('mytext').select();
        this.setState({
            text_message: "",
            photo_submited: false
        })
    }


    snapPhotoSubmit = (e, id) => {
        e.preventDefault();
        this.setState({
            new_photo: e.target.files[0],
            photo: URL.createObjectURL(e.target.files[0]),
            photo_submited: true
        })
    };

    removeImageFromMessage() {
        this.setState({
            new_photo: null,
            photo: "",
            photo_submited: false
        })
    }


    openStoriesModal(photo) {
        this.setState({
            showstories: true,
            memory_to_show: photo
        });
    }

    onTouchStart(touchStartEvent) {
        this.setState({
            is_story_paused: true,
            start_swipe_x: touchStartEvent.targetTouches[0].clientX,
            start_swipe_y: touchStartEvent.targetTouches[0].clientY,
        })
    }


    onTouchMove(touchMoveEvent) {
        this.setState({
            swipe_extend_x: touchMoveEvent.targetTouches[0].clientX,
            swipe_extend_y: touchMoveEvent.targetTouches[0].clientY
        })
    }


    onTouchEnd() {
        this.setState({
            is_story_paused: false,
            // storyShowMore: false,
        })
        if (this.state.swipe_extend_x > 100 || this.state.swipe_extend_y > 100) {
            if (this.state.start_swipe_x - this.state.swipe_extend_x < -50) {
                this.closeModal()
            }

            if (this.state.start_swipe_x - this.state.swipe_extend_x > 100) {
                this.closeModal()
            }

            if (this.state.start_swipe_y - this.state.swipe_extend_y > 100) {
                this.closeModal()
            }

            if (this.state.start_swipe_y - this.state.swipe_extend_y < -100) {
                this.closeModal()
            }


        } else {
            this.setState({
                is_story_paused: true,
                // storyShowMore: false,
            })
            // this.closeModal()
        }

        this.setState({
            start_swipe_x: "",
            start_swipe_y: "",
            swipe_extend_x: "",
            swipe_extend_y: "",
        })
    }



    renderMessages = messages => {
        const { auth } = this.props
        const { profile } = this.props
        const my_profile = this.props.profile
        const last_message = messages[messages.length - 1]
        let last_message_seen = true
        if (last_message) {
            last_message_seen = !last_message.is_new
        }

        if (my_profile.length === 1) {

            const current_user = profile[0].user

            const conversation = this.props.conversation
            let my_color = "initial"
            let conversator_color = "initial"
            if (conversation) {
                if (current_user === conversation[0].person_1) {
                    my_color = conversation[0].person_1_color
                    conversator_color = conversation[0].person_2_color
                } else {
                    my_color = conversation[0].person_2_color
                    conversator_color = conversation[0].person_1_color
                }
            }

            if (this.state.messageColor !== "") {
                my_color = this.state.messageColor
            }
            // "hbstr"  "blue"  "initial"  "roze"  "green" "red"  "pale"  "white"   "orange"

            return (
                <>
                    {messages.length === 0 ?
                        <>
                            {this.props.user_profile.map(conversator => (
                                <div class="conversation-messages-no-messages-box">
                                    <Link to={{ pathname: `/user-profile/${conversator.user}/profile` }}>
                                        <div class="conversation-messages-no-messages-profile-pic-box">
                                            <img
                                                class="conversation-messages-no-messages-profile-pic"
                                                src={conversator.profile_picture}
                                            />
                                        </div>
                                    </Link>
                                    <div class="conversation-messages-no-messages-text-margin">
                                        <div class="conversation-messages-no-messages-username">
                                            {conversator.username} • Hubst3r
                                        </div>
                                        <div class="conversation-messages-no-messages-username">
                                            {conversator.buddies_number} buddies • {conversator.memories_number} memories
                                        </div>
                                        <Link to={{ pathname: `/user-profile/${conversator.user}/profile` }}>
                                            <div class="conversation-messages-no-messages-visit">
                                                View Profile
                                            </div>
                                        </Link>
                                    </div>

                                </div>
                            ))}
                        </>
                        :
                        <>
                            {messages.map((message, i, arr) => (

                                <li
                                    key={message.id}
                                    class="row conversation-message-item-box"
                                    style={{ marginBottom: arr.length - 1 === i ? "100px" : "15px" }}
                                >
                                    <div class="conversation-message-row-correction">

                                        {message.sending_user === current_user ?
                                            <div />
                                            :
                                            <Link to={{ pathname: `/user-profile/${this.props.location.state.selected_user}/profile` }}>
                                                <div class="conversation-room-conversator-profile-pic-box">
                                                    <img
                                                        class="conversation-room-conversator-profile-pic"
                                                        src={this.props.location.state.selected_user_profile_pic}
                                                    />
                                                </div>
                                            </Link>
                                        }


                                        <div
                                            class={message.sending_user !== current_user && conversator_color === "blue" ?
                                                "conversation-room-conversation-text-message conversation-room-message-color-blue"
                                                : message.sending_user !== current_user && conversator_color === "roze" ?
                                                    "conversation-room-conversation-text-message conversation-room-message-color-roze"
                                                    : message.sending_user !== current_user && conversator_color === "hbstr" ?
                                                        "conversation-room-conversation-text-message conversation-room-message-color-hbstr"
                                                        : message.sending_user !== current_user && conversator_color === "green" ?
                                                            "conversation-room-conversation-text-message conversation-room-message-color-green"
                                                            : message.sending_user !== current_user && conversator_color === "red" ?
                                                                "conversation-room-conversation-text-message conversation-room-message-color-red"
                                                                : message.sending_user !== current_user && conversator_color === "pale" ?
                                                                    "conversation-room-conversation-text-message conversation-room-message-color-pale"
                                                                    : message.sending_user !== current_user && conversator_color === "white" ?
                                                                        "conversation-room-conversation-text-message conversation-room-message-color-white"
                                                                        : message.sending_user !== current_user && conversator_color === "orange" ?
                                                                            "conversation-room-conversation-text-message conversation-room-message-color-orange"
                                                                            : message.sending_user !== current_user && conversator_color === "initial" ?
                                                                                "conversation-room-conversation-text-message conversation-room-conversator-message-color-initial"

 

                                                                                : message.sending_user === current_user && my_color === "blue" ?
                                                                                    "conversation-room-conversation-my-text-message conversation-room-message-color-blue"
                                                                                    : message.sending_user === current_user && my_color === "roze" ?
                                                                                        "conversation-room-conversation-my-text-message conversation-room-message-color-roze"
                                                                                        : message.sending_user === current_user && my_color === "hbstr" ?
                                                                                            "conversation-room-conversation-my-text-message conversation-room-message-color-hbstr"
                                                                                            : message.sending_user === current_user && my_color === "green" ?
                                                                                                "conversation-room-conversation-my-text-message conversation-room-message-color-green"
                                                                                                : message.sending_user === current_user && my_color === "red" ?
                                                                                                    "conversation-room-conversation-my-text-message conversation-room-message-color-red"
                                                                                                    : message.sending_user === current_user && my_color === "pale" ?
                                                                                                        "conversation-room-conversation-my-text-message conversation-room-message-color-pale"
                                                                                                        : message.sending_user === current_user && my_color === "white" ?
                                                                                                            "conversation-room-conversation-my-text-message conversation-room-message-color-white"
                                                                                                            : message.sending_user === current_user && my_color === "orange" ?
                                                                                                                "conversation-room-conversation-my-text-message conversation-room-message-color-orange"
                                                                                                                : message.sending_user === current_user && my_color === "initial" ?
                                                                                                                    "conversation-room-conversation-my-text-message conversation-room-message-color-initial"
                                                                                                                    :
                                                                                                                    null
                                            }


                                        >



                                            {/* <span
                                class={this.state.my_messages_color === "blue" ?
                                    "conversation-room-message-color-blue"
                                    :
                                    "conversation-room-message-color-initial"

                                }
                            > */}
                                            {message.has_file ?
                                                <div
                                                    onClick={() => this.openStoriesModal(message.message_file)}
                                                    class="conversation-room-conversation-text-image-box">
                                                    <img
                                                        class="conversation-room-conversation-text-image"
                                                        src={message.message_file}
                                                    />

                                                </div>
                                                :
                                                null
                                            }
                                            <div class="conversation-room-text-braek">
                                                {message.text_message}
                                            </div>


                                            {message.sending_user === current_user ?
                                                <>
                                                    {message.voice_message === "" || message.voice_message === "null" || message.voice_message === null ?

                                                        null
                                                        :
                                                        <div
                                                            onClick={() => this.openShowMessageModal(message.voice_message, my_profile[0].profile_picture, my_profile[0].username, message.timestamp, "")}
                                                            class="conversation-room-my-play-audio-box"
                                                        >
                                                            {message.not_listened ?
                                                                <img
                                                                    class="conversation-room-my-play-audio-img"
                                                                    src={require("../../../assets/chat/svg/audio-message-blue.svg")}
                                                                />
                                                                :
                                                                <img
                                                                    class="conversation-room-my-play-audio-img"
                                                                    src={require("../../../assets/chat/svg/audio-message-grey.svg")}
                                                                />
                                                            }
                                                        </div>

                                                    }
                                                </>
                                                :
                                                <>
                                                    {message.voice_message === "" || message.voice_message === "null" || message.voice_message === null ?
                                                        null
                                                        :
                                                        <>
                                                            <div
                                                                onClick={() => this.openShowMessageModal(message.voice_message, this.props.location.state.selected_user_profile_pic, this.props.location.state.selected_user_username, message.timestamp, message.id)}
                                                                class="conversation-room-other-play-audio-box">
                                                                {message.not_listened ?
                                                                    <img
                                                                        class="conversation-room-my-play-audio-img"
                                                                        src={require("../../../assets/chat/svg/audio-message-green.svg")}
                                                                    />
                                                                    :
                                                                    <img
                                                                        class="conversation-room-my-play-audio-img"
                                                                        src={require("../../../assets/chat/svg/audio-message-grey.svg")}
                                                                    />
                                                                }
                                                            </div>
                                                        </>

                                                    }
                                                </>
                                            }
                                            {/* </span> */}

                                        </div>
                                        {/* {is_new && message.sending_user === current_user && last_message.id === message.id ? */}
                                        {last_message.id !== message.id ?
                                            null
                                            : message.sending_user !== current_user ?
                                                null
                                                : last_message_seen ?
                                                    < div class="conversation-room-seen-box">
                                                        Seen
                                                    </div>
                                                    :
                                                    null

                                        }

                                    </div>


                                    {/* <div class="conversation-room-conversation-text-message">
                            {message.text_message}
                            
                        </div> */}


                                    {/* <small>{this.renderTimestamp(message.timestamp)}</small> */}
                                </li >
                            ))}
                        </>

                    }
                </>
            )
        }
    };



    userStoriesEnded = e => {
        this.closeModal()
    }




    render() {
        let selected_user = ""
        let selected_user_profile_pic = ""
        let selected_user_username = ""
        if (this.props.location.state.selected_user) {
            selected_user = this.props.location.state.selected_user
            selected_user_profile_pic = this.props.location.state.selected_user_profile_pic
            selected_user_username = this.props.location.state.selected_user_username
        }

        let myMessageColor = ""
        if (this.props.conversation[0]) {
            let conversation = this.props.conversation[0]
            if (this.props.profile) {
                const current_user = this.props.profile[0].user
                if (current_user === conversation.person_1) {
                    myMessageColor = conversation.person_1_color

                } else {
                    myMessageColor = conversation.person_2_color
                }
                if (this.state.messageColor !== "") {
                    myMessageColor = this.state.messageColor
                }
            }
        }

        let rendred = false
        let stories = []

        if (this.state.memory_to_show) {
            const config = {
                delta: 10,
            }
            rendred = true
            const is_story_paused = this.state.is_story_paused
            const story =
            {
                duration: 10000,
                content: ({ action, isPaused }) => {

                    const pauseStory = () => { action("pause") }
                    if (is_story_paused) {
                        action("pause")
                    }
                    return (
                        <>
                            <div style={{ background: 'pink' }}>
                                <div class="hyb-page-story-photo">
                                    <img class="hyb-page-story-photo-size" src={this.state.memory_to_show} />
                                </div>
                            </div>
                        </>
                    );
                },
                url: this.state.memory_to_show,
            }
            stories.push(story)
        }

        const screen_width = window.screen.width
        const screen_height = window.screen.height

        return (
            <Fragment>
                {rendred ?
                    <>
                        {this.state.showstories ?
                            <Modal visible={this.state.showstories} width="100%" height="100%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <>
                                    <div class="hyb-page-story-index">

                                        <div onClick={() => this.closeModal()} class="hyb-page-story-times-box">
                                            <img
                                                onClick={() => this.closeModal()}
                                                class="hyb-page-story-times-img"
                                                src={require("../../../assets/map/svg/story-times.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="hyb-page-memories-full-box"
                                        >


                                            <Stories
                                                preventDefault={true}
                                                stories={stories}
                                                defaultInterval={1500}
                                                width={screen_width}
                                                height={screen_height}
                                                // currentIndex={index}
                                                // isPaused={True}
                                                // onStoryStart={(e, id) => this.storyStarted(e, id)}
                                                // onStoryEnd={e => this.storyStarted(e)}
                                                onAllStoriesEnd={e => this.userStoriesEnded(e)}
                                            />

                                        </div>

                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="hyb-page-touches-invisible-box">

                                        </div>

                                    </div>
                                </>

                            </Modal>

                            :
                            null
                        }
                    </>
                    :
                    <></>
                }

                <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />



                {this.state.showDesignModal ?
                    <Modal visible={this.state.showDesignModal} width="0%" height="0%" effect="fadeInRight" onClickAway={() => this.closeModal()}>
                        <ConversationDesignModal
                            data={{
                                closeModal: this.closeModal.bind(this),
                                setNewBackground: this.setNewBackground.bind(this),
                                setNewMessageColor: this.setNewMessageColor.bind(this),
                                conversation_id: this.state.conversation_id,
                                myMessageColor: myMessageColor
                            }}
                            setConversationBackground={this.props.setConversationBackground}
                        />

                    </Modal>
                    :
                    null
                }


                {this.state.showMessageModal ?
                    <Modal visible={this.state.showMessageModal} width="100%" height="70%" effect="fadeInRight" onClickAway={() => this.closeModal()}>
                        <ShowMessageModal
                            data={{
                                profile_picture: this.state.profile_picture,
                                username: this.state.username,
                                message: this.state.message,
                                messageTime: this.state.messageTime,
                                closeModal: this.closeModal.bind(this),
                            }}
                        />
                    </Modal>
                    :
                    null
                }

                <div>
                    <div class="conversation-room-total-page-layout">
                        <OfflineLabel />
                        <div class="conversation-room-mobile-navbar">
                            <Link to="/chat-list">
                                <div class="back-button-conversation-room-box">
                                    <img
                                        class="conversation-room-back-button"
                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                        alt=""
                                    />
                                </div>
                            </Link>
                            <Link class="conversation-room-sending-username-link" to={{ pathname: `/user-profile/${selected_user}/profile` }}>
                                <div class="conversation-room-sending-username-align">
                                    {selected_user_username}
                                </div>

                                <div class="conversation-room-sending-profile-pic-box">
                                    <img
                                        class="conversation-room-sending-profile-pic-img"
                                        src={selected_user_profile_pic}
                                        alt=""
                                    />
                                </div>
                            </Link>
                            <div onClick={() => this.openShowDesignModal(this.props.conversation[0].id)} class="conversation-top-pinelo-box">
                                <img
                                    class="conversation-top-pinelo-img"
                                    src={require("../../../assets/chat/svg/design-icon.svg")}
                                    alt=""
                                />
                            </div>
                        </div>

                        {this.props.conversation.map(conversation => (
                            <>
                                <div class="conversation-room-sound-proof-box">
                                    {this.state.newBackground !== "" ?
                                        <img
                                            class="conversation-room-sound-proof-img"
                                            src={this.state.newBackground}
                                            alt=""
                                        />
                                        : conversation.background_image ?
                                            <img
                                                class="conversation-room-sound-proof-img"
                                                src={conversation.background_image}
                                                alt=""
                                            />
                                            :
                                            null
                                    }
                                </div>



                                <div className="conversation-all-message-items-box">
                                    <ul id="chat-log">
                                        {this.props.conversation_messages && this.renderMessages(this.props.conversation_messages)}
                                        <div
                                            style={{ float: "left", clear: "both" }}
                                            ref={(ref) => this.myRef = ref}
                                        />
                                    </ul>
                                </div>




                                {/* 
                                <ul id="chat-log">
                                    {this.props.conversation_messages.map(message => (
                                        <li
                                            key={message.id}
                                            // style={{ marginBottom: arr.length - 1 === i ? "300px" : "15px" }}
                                            className={message.sending_user === current_user ? "replies" : "sent"}
                                        >
                                            {message.sending_user === current_user ?
                                                <div />
                                                :
                                                <div>
                                                    <Link to={{ pathname: '/friend', state: { profile: this.props.chatting_with } }}>
                                                        <img src={this.props.chatting_with.profile_pic} />
                                                    </Link>
                                                </div>
                                            }
                                            <p>
                                                {message.text_message}
                                                <br />
                                                <small>{this.renderTimestamp(message.timestamp)}</small>
                                            </p>
                                        </li >
                                    ))}
                                    <div
                                        style={{ float: "left", clear: "both" }}
                                        ref={el => {
                                            this.messagesEnd = el;
                                        }}
                                    />
                                </ul> */}



                                {/* <div class="conversation-all-message-items-box">
                                    {this.props.conversation_messages.reverse().map(message => (
                                        <div class="conversation-message-item-box">
                                            {message.text_message}
                                        </div>
                                    ))}
                                </div> */}

                                {/* <Link to={{ pathname: "/chat-room", state: { selected_user: selected_user, selected_user_profile_pic: selected_user_profile_pic, selected_user_username: selected_user_username } }}>
                                    <div class="conversation-room-conversator-new-message">
                                        New Message
                                    </div>
                                </Link> */}

                                <div id="field-to-show" class="conversation-room-type-message-bar">
                                    {/* <textarea
                                        className="conversation-room-input-field"
                                        name="text_message"
                                        onChange={this.onChange}
                                        placeholder="Type a message..."
                                        value={this.state.text_message}
                                        rows={1}
                                        ref={c => this.textarea = c}
                                    /> */}

                                    {!this.state.photo_submited ?
                                        <>
                                            <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                                <TextareaAutosize
                                                    minRows={1}
                                                    maxRows={4}
                                                    className="conversation-room-input-field"
                                                    name="text_message"
                                                    onChange={this.onChange}
                                                    placeholder="Type a message..."
                                                    value={this.state.text_message}
                                                    autofocus
                                                    id="mytext"
                                                />
                                            </ScrollLink>

                                            <div class="conversation-room-senf-img-box">
                                                <img
                                                    class="conversation-room-senf-img"
                                                    onClick={() => this.photoInput.click()}
                                                    src={require("../../../assets/chat/svg/img-send.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div class="conversation-room-uploaded-photo-box">
                                                <img
                                                    class="conversation-room-uploaded-photo-img"
                                                    src={this.state.photo}
                                                />
                                            </div>

                                            <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                                <TextareaAutosize
                                                    minRows={6}
                                                    maxRows={6}
                                                    className="conversation-room-input-field-with-img"
                                                    name="text_message"
                                                    onChange={this.onChange}
                                                    placeholder="Type a message..."
                                                    value={this.state.text_message}
                                                    autofocus
                                                    id="mytext"
                                                />
                                            </ScrollLink>
                                        </>
                                    }



                                    <input
                                        style={{ display: 'none' }}
                                        accept="image/png, image/jpeg, video/*"
                                        type="file"
                                        name="photo"
                                        onChange={
                                            this.snapPhotoSubmit
                                        }
                                        ref={photoInput => this.photoInput = photoInput}
                                    />

                                    {this.state.text_message.length === 0 ?
                                        <div class="conversation-room-input-send-grey" >
                                            Send
                                        </div>
                                        :
                                        <div onClick={this.sendMessageToBuddy} class="conversation-room-input-send" >
                                            Send
                                        </div>
                                    }
                                </div>

                            </>
                        ))}
                    </div>
                </div>
                {this.state.photo_submited ?
                    <>
                        <div class="conversation-room-uploaded-photo-box">
                            <img
                                class="conversation-room-uploaded-photo-img"
                                src={this.state.photo}
                            />
                        </div>
                        <div class="conversation-room-uploaded-photo-x-box">
                            <img
                                onClick={() => this.removeImageFromMessage()}
                                class="conversation-room-uploaded-photo-x-img"
                                src={require("../../../assets/chat/svg/x-red.svg")}
                            />
                        </div>
                    </>
                    :
                    null
                }
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    notifications_status: state.notification.notifications_status,
    profile: state.profile.profile,
    user_profile: state.profile.user_profile,
    my_messages: state.chat.my_messages,
    conversation_messages: state.chat.conversation_messages,
    conversation: state.chat.conversation,
});

export default connect(mapStateToProps, {
    getMyProfile,
    getUserProfile,
    getNotificationsStatus,
    sendVoiceMessage,
    sendMessageWithImage,
    getConversationMessages,
    getTheConversation,
    setMessageToListened,
    setConversationBackground,
    setConversationMyColor,
    clearStateMessages,
})(ConversationRoom);