import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/layout/gdpr-links.css"


export class GdprLinks extends Component {

    state = {
        loading_time: true,
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loading_time: false,
            })
        }, 2000);
    }

    render() {
        return (
            <Fragment>
                {!this.state.loading_time ?
                    <div class="footer">
                        <Link to="/feedback">
                            <div className="gdpr-links-feedback-btn">
                                Help us with your feedback
                            </div>
                        </Link>

                        <div class="row">
                            <div class="col-xl-12 text-center mt-2">
                                <Link to="/terms-of-use">
                                    <a class="link2" href="http://www.hubst3r.com/terms-of-use">Terms</a>
                                </Link>
                                <Link to="/privacy-policy">
                                    <a class="link2" href="http://www.hubst3r.com/privacy-policy">Privacy</a>
                                </Link>
                                <Link to="/cookies-policy">
                                    <a class="link2" href="http://www.hubst3r.com/cookies-policy">Cookies</a>
                                </Link>
                                <br />
                                {/* <Link> */}
                                <a href="http://about.hubst3r.com/" class="link2" >About</a>
                                {/* </Link> */}
                                <Link to="/licenses">
                                    <a class="link2" href="http://www.hubst3r.com/licenses/">Licenses</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <br />
            </Fragment >
        )
    }
}

export default GdprLinks;