import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../../assets/chat/conversation-design-modal.css"


export class ConversationDesignModal extends Component {

    state = {
        photo: null,
        background_image: "",
        colorSelecttion: false,
    }

    closeModal = () => {
        this.props.data.closeModal()
        this.setState({
            photo: null,
            background_image: "",
            colorSelecttion: false,
        })
    }

    pressColorSelection = () => {
        this.setState({
            colorSelecttion: true,
        })
    }

    conversationBackgroundSubmit = (e) => {
        e.preventDefault();
        this.setState({
            background_image: e.target.files[0],
            photo: URL.createObjectURL(e.target.files[0]),
        })
        setTimeout(() => {
            this.updateConversationBackground()
        }, 3000);
    }

    updateConversationBackground = () => {
        const id = this.props.data.conversation_id
        const background_image = this.state.background_image
        const conversation = { id, background_image }
        this.props.data.setNewBackground(this.state.photo)
        setTimeout(() => {
            this.props.setConversationBackground(conversation)
        }, 3000);
    }


    setMessagesColor = (color) => {
        this.props.data.setNewMessageColor(color)
    }


    render() {

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
        };
        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="conversation-design-box">
                            <img
                                onClick={() => this.closeModal()}
                                class="conversation-design-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <div class="conversation-design-buddy-name">
                                Design your Conversation
                            </div>
                            <div class="conversation-design-title-hr">
                                <hr />
                            </div>

                            {this.state.colorSelecttion ?
                                <div class="conversation-design-all-choises">
                                    <div class="conversation-design-slider-title">
                                        Select the color you would like your messages to appear in the conversation:
                                    </div>
                                    <div class="conversation-design-slider-layout">
                                        <Slider {...settings} >

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("initial")}
                                                    class={this.props.data.myMessageColor === "initial" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-initial"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-initial"
                                                    }
                                                >

                                                </div>
                                            </div>
                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("hbstr")}
                                                    class={this.props.data.myMessageColor === "hbstr" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-hbstr"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-hbstr"
                                                    }
                                                >

                                                </div>
                                            </div>

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("blue")}
                                                    class={this.props.data.myMessageColor === "blue" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-blue"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-blue"
                                                    }
                                                >

                                                </div>
                                            </div>

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("red")}
                                                    class=
                                                    {this.props.data.myMessageColor === "red" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-red"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-red"
                                                    }
                                                >

                                                </div>
                                            </div>

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("roze")}
                                                    class={this.props.data.myMessageColor === "roze" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-roze"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-roze"
                                                    }
                                                >

                                                </div>
                                            </div>

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("green")}
                                                    class={this.props.data.myMessageColor === "green" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-green"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-green"
                                                    }
                                                >

                                                </div>
                                            </div>

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("pale")}
                                                    class={this.props.data.myMessageColor === "pale" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-pale"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-pale"
                                                    }
                                                >

                                                </div>
                                            </div>

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("orange")}
                                                    class={this.props.data.myMessageColor === "orange" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-orange"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-orange"
                                                    }
                                                >

                                                </div>
                                            </div>

                                            <div class="conversation-design-slider-frame" >
                                                <div
                                                    onClick={() => this.setMessagesColor("white")}
                                                    class={this.props.data.myMessageColor === "white" ?
                                                        "conversation-design-color-box-pressed conversation-room-message-color-white"
                                                        :
                                                        "conversation-design-color-box conversation-room-message-color-white"
                                                    }
                                                >

                                                </div>
                                            </div>



                                        </Slider>
                                    </div>
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="conversation-design-done-btn"
                                    >
                                        Done
                                    </div>

                                </div>
                                :
                                <>
                                    <div class="conversation-design-all-choises">
                                        <hr />
                                        <div
                                            onClick={() => this.fileInput.click()}
                                            class="conversation-design-choise"
                                        >
                                            <div class="conversation-design-choise-text">
                                                Set conversation Background
                                            </div>

                                            <div class="conversation-design-remove-icon-box">
                                                <img
                                                    class="conversation-design-remove-icon"
                                                    src={require("../../../assets/chat/svg/set-backround.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </div>

                                        <hr />
                                        <div onClick={() => this.pressColorSelection()}
                                            class="conversation-design-choise"
                                        >
                                            <div class="conversation-design-choise-text">
                                                Set my messages color
                                            </div>

                                            <div class="conversation-design-remove-icon-box">
                                                <img
                                                    class="conversation-design-remove-icon"
                                                    src={require("../../../assets/chat/svg/bubble-icon.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </div>

                                        <hr />


                                    </div>

                                    <input
                                        style={{ display: 'none' }}
                                        accept="image/png, image/jpeg"
                                        type="file"
                                        name="photo"
                                        onChange={this.conversationBackgroundSubmit}
                                        ref={fileInput => this.fileInput = fileInput}
                                    />
                                </>
                            }
                        </div>
                    </div>

                </div>
            </Fragment >
        )
    }
}


export default (ConversationDesignModal);