import {
    CREATE_NEW_HUB,
    CONVERT_USER_TO_HUB_STAFF,
    DASHBOARD_GET_ALL_HUBS,
    GET_COMPANY_STATS,
    DASHBOARD_GET_ALL_PROSPECTS,
    DASHBOARD_GET_ALL_FREEZE_PROSPECTS,
    DASHBOARD_GET_ALL_SALES_COMMUNICATIONS,
    DASHBOARD_GET_ALL_SUPPORT_COMMUNICATIONS,
    DASHBOARD_GET_ALL_TEAM_TASKS,
    DASHBOARD_GET_TEAM_QUORTER_GOALS,
    CREATE_NEW_VISIT,
    DASHBOARD_GET_LOCATIONS,
    GET_HUBS_WITH_DATA,


} from '../actions/actionTypes';
import { updateObject } from "../utility";


const initialState = {
    is_created: false,
    hub: [],
    hubs: [],
    hubs_with_data: [],
    company: [],
    prospects: [],
    freeze_prospects: [],
    sales_communications: [],
    support_communications: [],
    tasks: [],
    goals: [],
    in_locations: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_NEW_HUB:
            return {
                ...state,
                is_created: true,
                hub: action.payload,
            };
        case CREATE_NEW_VISIT:
            localStorage.setItem('vstbjctd', JSON.stringify(action.payload.id));
        case DASHBOARD_GET_ALL_TEAM_TASKS:
            return {
                ...state,
                tasks: action.payload,
            };
        case DASHBOARD_GET_TEAM_QUORTER_GOALS:
            return {
                ...state,
                goals: action.payload,
            };
        case DASHBOARD_GET_ALL_HUBS:
            return {
                ...state,
                hubs: action.payload,
            };
        case DASHBOARD_GET_ALL_PROSPECTS:
            return {
                ...state,
                prospects: action.payload,
            };
        case DASHBOARD_GET_ALL_FREEZE_PROSPECTS:
            return {
                ...state,
                freeze_prospects: action.payload,
            };
        case GET_COMPANY_STATS:
            return {
                ...state,
                company: action.payload,
            };
        case DASHBOARD_GET_ALL_SALES_COMMUNICATIONS:
            return {
                ...state,
                sales_communications: action.payload,
            };
        case DASHBOARD_GET_ALL_SUPPORT_COMMUNICATIONS:
            return {
                ...state,
                support_communications: action.payload,
            };
        case DASHBOARD_GET_LOCATIONS:
            return {
                ...state,
                in_locations: action.payload.body,
            };
        case GET_HUBS_WITH_DATA:
            return {
                ...state,
                hubs_with_data: action.payload,
            };

        default:
            return state;
    }
}