import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import GdprLinks from '../../../components/user/layout/gdprLinks';


export class MemoriesTab extends Component {


    state = {
        new_memory: "",
        memory_photo: null,
        memory_submited: false,
        loading_time: true,
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loading_time: false,
            })
        }, 2000);
    }


    snapMemorySubmit = (e, id) => {
        e.preventDefault();
        this.setState({
            new_memory: e.target.files[0],
            memory_photo: URL.createObjectURL(e.target.files[0]),
            memory_submited: true
        })
    };


    openStoriesModal(user, one_person, memory_id) {
        this.props.data.openStoriesModal(user, one_person, memory_id)
    }

    render() {
        if (this.state.memory_submited) {
            return <Redirect to={{ pathname: '/post-memory', state: { new_memory: this.state.new_memory, memory_photo: this.state.memory_photo } }} />
        }
        const settingsTrollSlider = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 2,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 1000,
            cssEase: "linear",
        };
        const auth_user = JSON.parse(localStorage.getItem('ur')).id
        return (
            <Fragment>
                {this.props.data.memories.length === 0 && !this.state.loading_time ?
                    <div>
                        {this.props.data.user === auth_user ?

                            <div class="profile-no-memories-mika-layout">

                                <div class="profile-memories-snap-memory-layout">
                                    <div class="profile-memories-share-memory-text">
                                        Share a Memory Now
                                    </div>
                                    <img
                                        onClick={() => this.fileInput.click()}
                                        class="profile-memories-snap-memory-size"
                                        src={require("../../../assets/hub/hub-page/svg/photo-icon.svg")}
                                        alt=""
                                    />

                                    <input
                                        style={{ display: 'none' }}
                                        accept="image/png, image/jpeg"
                                        type="file"
                                        name="memory_photo"
                                        onChange={
                                            this.snapMemorySubmit
                                        }
                                        ref={fileInput => this.fileInput = fileInput}
                                    />
                                </div>

                                {/* <div class="profile-no-memories-image">
                                    <img
                                        class="profile-no-memories-created"
                                        src={require("../../../assets/layout/mika/dont-know-grey.svg")}
                                        alt=""
                                    />
                                </div> */}
                                <p class="profile-no-memories-text">
                                    <b>No Memories</b><br />
                                    Once you Share a Memory you <br /> will be able to see it here
                                </p>
                            </div>
                            :
                            <div class="profile-no-memories-mika-layout">
                                <div class="profile-memories-snap-memory-layout">
                                    <img
                                        class="profile-memories-snap-memory-size"
                                        src={require("../../../assets/hub/hub-page/svg/photo-icon.svg")}
                                        alt=""
                                    />
                                </div>
                                <p class="profile-no-memories-text">
                                    <b>No Memories</b><br />
                                    When {this.props.data.user_username} Share a Memory<br />you will be able to see it here
                                </p>
                            </div>
                        }
                    </div>
                    :
                    // <div class="memory-img-all-img">
                    <div class="row">
                        {this.props.data.memories.map(memory => (
                            <div
                                onClick={() => this.openStoriesModal(memory.user, true, memory.id)}
                                class="memory-img-size-box" >
                                {/* <Link to={{ pathname: '/memories', state: { memory: memory.id } }}> */}
                                <img
                                    class="memory-img-size-full"
                                    src={memory.photo}
                                    alt=""
                                />
                                {/* </Link> */}
                            </div>

                        ))}
                    </div>
                    // </div>
                }
                <br /><br /><br /><br />
                <GdprLinks />

            </Fragment >
        )
    }
}

export default (MemoriesTab);