import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/layout/bottomNavbar.css"


export class BottomNavbar extends Component {
    // state = {
    //     show_mika: true
    // }

    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({
    //             show_mika: false
    //         })
    //     }, 5000);
    // }

    render() {
        let new_notifications = ""
        let notification_status = this.props.data.notifications_status[0]
        if (notification_status) {
            new_notifications = notification_status["new_notifications"]
        }


        // const sellected = "map"
        const sellected = this.props.data.sellected
        // const profile_picture = this.props.data.profile_picture
        return (
            <Fragment>

                <div class="row bottom-navbar-all-choices">
                    <Link to="/" class="bottom-navbar-choice-box">
                        {/* <div > */}
                        <div class="bottom-navbar-choice-img-box">
                            {sellected === "map" ?
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/location-color.svg")}
                                    alt=""
                                />
                                :
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/location-white.svg")}
                                    alt=""
                                />
                            }
                        </div>
                        <div class={sellected === "map" ? "bottom-navbar-choice-title-selected" : "bottom-navbar-choice-title"}>
                            Map
                        </div>
                        {/* </div> */}
                    </Link>
                    <Link to="/search-buddies" class="bottom-navbar-choice-box">
                        {/* <div> */}
                        <div class="bottom-navbar-choice-img-box">
                            {sellected === "search" ?
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/people-color.svg")}
                                    alt=""
                                />
                                :
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/people-white.svg")}
                                    alt=""
                                />
                            }
                        </div>
                        <div class={sellected === "search" ? "bottom-navbar-choice-title-selected" : "bottom-navbar-choice-title"}>
                            People
                        </div>
                        {/* </div> */}
                    </Link>
                    <Link to="/meetings" class="bottom-navbar-choice-box">
                        {/* <div> */}
                        <div class="bottom-navbar-choice-img-box">
                            {sellected === "meetings" ?
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/meeting-color.svg")}
                                    alt=""
                                />
                                :
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/meeting-white.svg")}
                                    alt=""
                                />
                            }
                        </div>
                        <div class={sellected === "meetings" ? "bottom-navbar-choice-title-selected" : "bottom-navbar-choice-title"}>
                            Arranged
                        </div>
                        {/* </div> */}
                    </Link>
                    <Link to="/notifications" class="bottom-navbar-choice-box">
                        {/* <div > */}
                        <div class="bottom-navbar-choice-img-box">
                            {sellected === "notifications" ?
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/notification-color.svg")}
                                    alt=""
                                />
                                :
                                <>
                                    {new_notifications ?
                                        <img
                                            class="bottom-navbar-notification-tremple"
                                            src={require("../../../assets/notifications/svg/notification-red.svg")}
                                            alt=""
                                        />
                                        :
                                        <img
                                            class="bottom-navbar-choice-img"
                                            src={require("../../../assets/layout/bottom-navbar/notification-white.svg")}
                                            alt=""
                                        />
                                    }
                                </>
                            }

                        </div>
                        <div class={sellected === "notifications" ? "bottom-navbar-choice-title-selected" : "bottom-navbar-choice-title"}>
                            Notifications
                        </div>
                        {/* </div> */}
                    </Link>
                    <Link to="/my-profile" class="bottom-navbar-choice-box">
                        {/* <div > */}
                        <div class="bottom-navbar-choice-img-box">
                            {sellected === "profile" ?
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/profile-color.svg")}
                                    alt=""
                                />
                                :
                                <img
                                    class="bottom-navbar-choice-img"
                                    src={require("../../../assets/layout/bottom-navbar/profile-white.svg")}
                                    alt=""
                                />
                            }
                        </div>
                        <div class={sellected === "profile" ? "bottom-navbar-choice-title-selected" : "bottom-navbar-choice-title"}>
                            Profile
                        </div>
                        {/* </div> */}
                    </Link>
                </div>

                {/* <div class="nav-bottom-9 fixed-bottom">
                    <div class="container-fluid">
                        <div class="row all-icon-footer-9">
                            <div class="col-lg-1 col-md-1"></div>
                            <div class="col-lg-2 col-md-2 footer-width-9 py-3 text-center home-div-9">
                                <Link to="/">
                                    <div
                                        class={sellected === "home" ?
                                            "home-9 footer-icon-9 middle-icon-footer-9"
                                            :
                                            "home-9 footer-icon-9 footer-icon-p-9"
                                        }
                                    >

                                        <a href="#">
                                            {sellected === "home" ?
                                                <img src={require("../../../assets/notifications/svg/sellected-home-lin.svg")} alt="" />
                                                :
                                                <img src={require("../../../assets/notifications/svg/Home-blue.svg")} alt="" />
                                            }
                                        </a>

                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-2 col-md-2 footer-width-9 py-3 text-center">
                                <Link to="/search-buddies">
                                    <div
                                        class={sellected === "search" ?
                                            "home-9 footer-icon-9 middle-icon-footer-9-search"
                                            :
                                            "home-9 footer-icon-9 footer-icon-p-9"
                                        }
                                    >

                                        <a href="#">
                                            {sellected === "search" ?
                                                <img src={require("../../../assets/notifications/svg/sellected-search-lin.svg")} alt="" />
                                                :
                                                <img src={require("../../../assets/notifications/svg/Search.svg")} alt="" />
                                            }
                                        </a>

                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-2 col-md-2 footer-width-9 py-3 text-center">
                                <Link to="/meetings">
                                    <div
                                        class={sellected === "meetings" ?
                                            "home-9 footer-icon-9 middle-icon-footer-9"
                                            :
                                            "home-9 footer-icon-9 footer-icon-p-9"
                                        }
                                    >
                                        <a href="#">
                                            {sellected === "meetings" ?
                                                <img src={require("../../../assets/notifications/svg/sellected-meeting-lin.svg")} alt="" />
                                                :
                                                <img src={require("../../../assets/notifications/svg/meeting 1.svg")} alt="" />
                                            }
                                        </a>

                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-2 col-md-2 footer-width-9 py-3 text-center">
                                <Link to="/notifications">
                                    <div
                                        class={sellected === "notifications" ?
                                            "home-9 footer-icon-9 middle-icon-footer-9"
                                            :
                                            "home-9 footer-icon-9 footer-icon-p-9"
                                        }
                                    >

                                        {sellected === "notifications" ?
                                            <img src={require("../../../assets/notifications/svg/sellected-notification-lin.svg")} alt="" />
                                            :
                                            <span>
                                                {new_notifications ?
                                                    <img class="bottom-navbar-notification-tremple" src={require("../../../assets/notifications/svg/notification-red.svg")} alt="" />
                                                  :
                                                    <img src={require("../../../assets/notifications/svg/Notification.svg")} alt="" />
                                                }
                                            </span>
                                        }

                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-2 col-md-2 footer-width-9 py-3 text-center">
                                <Link to="/my-profile">
                                    <div
                                        class={sellected === "profile" ?
                                            "home-9 footer-icon-9 middle-icon-footer-9"
                                            :
                                            "home-9 footer-icon-9 footer-icon-p-9"
                                        }>

                                        {sellected === "profile" ?
                                            <>
                                                <img src={require("../../../assets/notifications/svg/sellected-profile-lin.svg")} alt="" />
                                            </>
                                            :
                                            <>
                                             
                                                <img src={require("../../../assets/notifications/svg/Profile.svg")} alt="" />

                                            </>
                                        }
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-1 col-md-1"></div>
                        </div>
                    </div>
                </div> */}

            </Fragment >
        )
    }
}


export default (BottomNavbar);