import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import "../../../assets/layout/notification-settings.css"


export class NotificationSettingsModal extends Component {
    state = {
        sellected: "",
        sellection_changed: false
    }

    closeModal = () => {
        // window.location.reload(false);
        localStorage.setItem('ntfctsmdl', JSON.stringify("True"));
        this.props.data.closeModal()
    }

    updateSellectedStatus = (answer) => (e) => {
        localStorage.setItem('ntfctsmdl', JSON.stringify("True"));
        const profile = this.props.data.profile[0]
        this.setState({
            sellected: answer,
            sellection_changed: true
        })
        let accept_email_notifications = false
        if (answer === "yes") {
            accept_email_notifications = true
        } else {
            accept_email_notifications = false
        }
        profile.accept_email_notifications = accept_email_notifications
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
        this.props.data.closeModal()
    }

    render() {
        const browser = navigator.userAgent.search("Android")
        let apple_device = false
        if (browser === -1) {
            apple_device = true
        }
        return (
            <Fragment>
                <div class="notification-settings-box">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                        <div class="notification-settings-box-6">
                            <div
                                onClick={() => this.closeModal()}
                                class="notification-settings-times-icon">
                                <img class="" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                            </div>
                            {apple_device ?
                                <h4 class="notification-settings-title">
                                    Even though we love Apple, Apple doesn't love us. 🙁 So we can't send you notifications for <Link to="/privacy-policy"> specific updates</Link>.
                                    <br /><br /><strong>Would you like to be notified by email instead?</strong>
                                </h4>
                                :
                                <h4 class="notification-settings-title">
                                    Do not stay out of the fun. Never miss a notification. Do not even allow the slightest chance to miss out.
                                    <br /><br /><strong>Would you like to be notified by email too?</strong>
                                </h4>
                            }
                            {this.props.data.profile.map(user => (
                                <div>
                                    {this.state.sellection_changed ?
                                        <div class="row">
                                            <img
                                                class=
                                                {this.state.sellected === "no" ?
                                                    "notification-settings-sellected-no"
                                                    :
                                                    "mika-decline-button"}
                                                onClick={this.updateSellectedStatus("no")}
                                                src={require("../../../assets/layout/mika/decline.svg")}
                                                alt=""
                                            />
                                            <img
                                                class=
                                                {this.state.sellected === "yes" ?
                                                    "notification-settings-sellected-yes"
                                                    :
                                                    "mika-approve-button"}
                                                onClick={this.updateSellectedStatus("yes")}
                                                src={require("../../../assets/layout/mika/approve.svg")}
                                                alt=""
                                            />
                                        </div>
                                        :
                                        <div class="row">
                                            <img
                                                class=
                                                {user.accept_email_notifications === false ?
                                                    "notification-settings-sellected-no"
                                                    :
                                                    "mika-decline-button"}
                                                onClick={this.updateSellectedStatus("no")}
                                                src={require("../../../assets/layout/mika/decline.svg")}
                                                alt=""
                                            />
                                            <img
                                                class=
                                                {user.accept_email_notifications === true ?
                                                    "notification-settings-sellected-yes"
                                                    :
                                                    "mika-approve-button"}
                                                onClick={this.updateSellectedStatus("yes")}
                                                src={require("../../../assets/layout/mika/approve.svg")}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    <div class="row">
                                        <h4
                                            onClick={this.updateSellectedStatus("no")}
                                            class="notification-settings-choises-no text-center"
                                        >
                                            <strong>No</strong>, I will frequently<br />check the app for<br />such updates
                                        </h4>
                                        <h4
                                            onClick={this.updateSellectedStatus("yes")}
                                            class="notification-settings-choises-yes text-center">
                                            <strong>Yes</strong>, I would like<br />to be notified for <br /><Link to="/privacy-policy"> such updates </Link>

                                        </h4>

                                    </div>
                                </div>
                            ))}
                        </div>

                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    email_notification: state.notification.email_notification,
});

export default (NotificationSettingsModal);