import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';


export class AppointmentNotificationForHub extends Component {
    render() {
        const { notification } = this.props.data
        return (
            <Fragment>
                <Link to={{ pathname: '/my-profile' }}>
                    <div class="row single-item-9">
                        <div class="notification-related-photo-position">
                            {notification.related_picture ?
                                <img class="related-picture-layout" src={notification.related_picture} alt="" />
                                :
                                <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                            }
                        </div>
                        <div class="notification-information-section">
                            <div class="notification-text-position">
                                <p class="text-blue-9 notification-text-layout">
                                    <strong>{notification.related_name}</strong> has a new Request for an Appointment!
                                </p>
                            </div>
                        </div>

                    </div>
                </Link>
            </Fragment >
        )
    }
}


export default (AppointmentNotificationForHub);