import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import "../../../assets/layout/notification-settings.css"


export class MemorySettingsModal extends Component {
    state = {
        sellected: "",
        sellection_changed: false
    }

    closeModal = () => {
        this.props.data.closeModal()
    }

    updateSellectedStatus = (answer) => (e) => {
        localStorage.setItem('ntfctsmdl', JSON.stringify("True"));
        const profile = this.props.data.profile[0]
        this.setState({
            sellected: answer,
            sellection_changed: true
        })
        let appear_memory_suggestions = false
        if (answer === "yes") {
            appear_memory_suggestions = true
        } else {
            appear_memory_suggestions = false
        }
        profile.appear_memory_suggestions = appear_memory_suggestions
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
        this.props.data.closeModal()
    }

    render() {
        return (
            <Fragment>
                <div class="notification-settings-box">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                        <div class="notification-settings-box-6">
                            <div
                                onClick={() => this.closeModal()}
                                class="notification-settings-times-icon">
                                <img class="" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                            </div>



                            <h4 class="notification-settings-title">
                                When you upload Memories they might appear as suggested to any user of Hubst3r
                                <br /><br /><strong>Would you like your memories to appear as suggested?</strong>
                            </h4>



                            {this.props.data.profile.map(user => (
                                <div>
                                    {this.state.sellection_changed ?
                                        <div class="row">
                                            <img
                                                class=
                                                {this.state.sellected === "no" ?
                                                    "notification-settings-sellected-no"
                                                    :
                                                    "mika-decline-button"}
                                                onClick={this.updateSellectedStatus("no")}
                                                src={require("../../../assets/layout/mika/decline.svg")}
                                                alt=""
                                            />
                                            <img
                                                class=
                                                {this.state.sellected === "yes" ?
                                                    "notification-settings-sellected-yes"
                                                    :
                                                    "mika-approve-button"}
                                                onClick={this.updateSellectedStatus("yes")}
                                                src={require("../../../assets/layout/mika/approve.svg")}
                                                alt=""
                                            />
                                        </div>
                                        :
                                        <div class="row">
                                            <img
                                                class=
                                                {user.appear_memory_suggestions === false ?
                                                    "notification-settings-sellected-no"
                                                    :
                                                    "mika-decline-button"}
                                                onClick={this.updateSellectedStatus("no")}
                                                src={require("../../../assets/layout/mika/decline.svg")}
                                                alt=""
                                            />
                                            <img
                                                class=
                                                {user.appear_memory_suggestions === true ?
                                                    "notification-settings-sellected-yes"
                                                    :
                                                    "mika-approve-button"}
                                                onClick={this.updateSellectedStatus("yes")}
                                                src={require("../../../assets/layout/mika/approve.svg")}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    <div class="row">
                                        <h4
                                            onClick={this.updateSellectedStatus("no")}
                                            class="notification-settings-choises-no text-center"
                                        >
                                            <strong>No</strong>, I would like<br />my memories<br />not to appear
                                        </h4>
                                        <h4
                                            onClick={this.updateSellectedStatus("yes")}
                                            class="notification-settings-choises-yes text-center">
                                            <strong>Yes</strong>, I would like<br />my memories<br />to appear
                                        </h4>

                                    </div>
                                </div>
                            ))}
                        </div>

                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    email_notification: state.notification.email_notification,
});

export default (MemorySettingsModal);