import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/hub/hub-layout/hubGdprLinks.css"


export class HubGdprLinks extends Component {
    render() {
        return (
            <Fragment>
                <div class="hub-footer">
                    <div class="">
                        <div class="col-xl-12 text-center mt-2">
                            <Link to="/hubs-terms">
                                <a class="link2" href="http://www.hubst3r.com/hubs-terms">Terms</a>
                            </Link>
                            <Link to="/privacy-policy">
                                <a class="link2" href="http://www.hubst3r.com/privacy-policy">Privacy</a>
                            </Link>
                            <Link to="/cookies-policy">
                                {/* <a class="link2" href="http://www.hubst3r.com/cookies-policy">Cookies</a> */}
                            </Link>
                            <br />
                            <Link>
                                <a class="link2" href="http://about.hubst3r.com/">About</a>
                            </Link>
                            <Link to="/licenses">
                                <a class="link2" href="http://www.hubst3r.com/licenses/">Licenses</a>
                            </Link>
                            <div class="hub-text-blue text-center">This is a Business Account</div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </Fragment >
        )
    }
}

export default HubGdprLinks;