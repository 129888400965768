import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    getMemoryTags,
    getFullScreenMemory,
    createMemoryReaction,
    deleteMemory,
    addTagedBuddy,
    removeTagedBuddy,
    removeMytag
} from '../../../store/actions/memory'
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import "../../../assets/memories/memories.css"
import { getMyProfile } from '../../../store/actions/profile';
import Modal from 'react-awesome-modal';
import MemoryTagsModal from '../../../components/user/homepage/MemoryTagsModal';
import { getBuddies, searchBuddy } from '../../../store/actions/buddies';


export class Memory extends Component {

    state = {
        reaction_sellected: false,
        reaction: "",
        show_reaction: false,
        sellected: "",
        sellection_changed: false,
        deleteModalVisible: false,
        tagsModalVisible: false,
        selectedMemoryTags: "",
        memory_hub: "",
        memory_user: "",
        memory_id: "",
    }

    componentDidMount() {
        this.props.getMyProfile();
        this.props.getFullScreenMemory(this.props.location.state.memory);
        this.props.getMemoryTags(this.props.location.state.memory)
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    updateReactionSelection = (selected_reaction, memory_id) => (e) => {
        this.setState({
            reaction_sellected: true,
            reaction: selected_reaction
        })
        const reacted_memory_id = memory_id
        const reaction_status = selected_reaction
        const reaction = { reaction_status, reacted_memory_id }
        this.props.createMemoryReaction(reaction)
        setTimeout(() => {
            this.setState({
                reaction_sellected: false,
                show_reaction: true,
            })
        }, 2000);
    }

    closeModal = () => {
        this.setState({
            deleteModalVisible: false,
            tagsModalVisible: false,
            selectedMemoryTags: "",
            memory_hub: "",
            memory_user: ""
        })
    }


    openModal = () => {
        this.setState({
            deleteModalVisible: true,
        })
    }

    sellectNo() {
        this.setState({
            sellected: "no",
        })
        setTimeout(() => {
            this.setState({
                sellected: "",
                sellection_changed: false,
                deleteModalVisible: false,
            })
        }, 1000);
    }


    sellectYes(id) {
        this.setState({
            sellected: "yes",
        })
        setTimeout(() => {
            this.props.deleteMemory(id)
            this.setState({
                sellected: "",
                sellection_changed: false,
                deleteModalVisible: false,
                memoryDeleted: true,
            })
        }, 1000);
    }


    openTagsModal(memory_id, memory_hub, memory_user) {
        const id = JSON.parse(localStorage.getItem('ur')).id
        this.props.getBuddies(id)
        this.setState({
            tagsModalVisible: true,
            selectedMemoryTags: memory_id,
            memory_hub: memory_hub,
            memory_user: memory_user,
            memory_id: memory_id,
        });
    }

    render() {
        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }
        if (this.state.memoryDeleted) {
            return <Redirect to="/my-profile" />
        }
        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "memory-full-lottie-appear" : "memory-full-lottie-dissappear"}>
                    <div class="memory-full-lottie-position">
                        <Hubst3rLottie />
                    </div>

                </div> */}


                {/* <div class={this.props.isLoading ? "memory-full-section-disappear" : null}> */}
                <div>
                    {this.props.memory.map(memory => (
                        <div class="post-whole-page-backround" >
                            <div class="post-memory-out-layout">
                                <div class="row">
                                    <div class="">
                                        <div
                                            onClick={this.handleBack}
                                            class="memory-buddys-cancel-around"
                                        >
                                            <img
                                                class="memory-buddys-times-icon"
                                                src={require("../../../assets/memories/svg/Cancel.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal visible={this.state.deleteModalVisible} effect="fadeInDown" width="100px" height="100px" onClickAway={() => this.closeModal()}>
                                    <div class="are-you-sure-box">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                            </div>
                                            <div class="are-you-sure-box-6">
                                                <div
                                                    onClick={() => this.closeModal()}
                                                    class="are-you-sure-times-icon">
                                                    <img class="" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                                </div>
                                                <div class="are-you-sure-title-size">
                                                    <h4 class="are-you-sure-title">
                                                        Are you Sure that you want to Remove this Memory??
                                                    </h4>
                                                </div>
                                                <div>
                                                    <div class="row">
                                                        {this.state.sellected === "no" ?
                                                            <img
                                                                class="are-you-sure-sellected-no"
                                                                // onClick={() => this.sellectNo()}
                                                                src={require("../../../assets/layout/mika/mika-are-you-sure-sellected-no.svg")}
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                class="are-you-sure-mika-decline"
                                                                onClick={() => this.sellectNo()}
                                                                src={require("../../../assets/layout/mika/mika-are-you-sure-no.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                        <img
                                                            class=
                                                            {this.state.sellected === "yes" ?
                                                                "are-you-sure-sellected-yes"
                                                                :
                                                                "are-you-sure-mika-approve"}
                                                            onClick={() => this.sellectYes(memory.id)}
                                                            src={require("../../../assets/layout/mika/mika-are-you-sure-yes.svg")}
                                                            alt=""
                                                        />
                                                    </div>

                                                    <div class="row">
                                                        <h4
                                                            onClick={() => this.sellectNo()}
                                                            class="are-you-sure-choises-no text-center"
                                                        >
                                                            <strong>No leave it as it is</strong>
                                                        </h4>
                                                        <h4
                                                            onClick={() => this.sellectYes(memory.id)}
                                                            class="are-you-sure-choises-yes text-center">
                                                            <strong>Yes I am Sure</strong>

                                                        </h4>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                            </div>
                                        </div>
                                    </div>
                                </Modal>



                                <div class="all-memory-footer text-center ">
                                    <Link to={{ pathname: `/user-profile/${memory.user}/profile`, state: { user: memory.user } }}>
                                        {memory.user_profile_picture ?
                                            <img
                                                class="memory-buddys-profile-picture memory-buddies-top-index"
                                                src={memory.user_profile_picture}
                                                alt=""
                                            />
                                            :
                                            <img
                                                class="memory-buddys-profile-picture memory-buddies-top-index"
                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                alt=""
                                            />
                                        }

                                        <h12 class="memory-full-username-top-layout">
                                            {memory.user_username}
                                        </h12>
                                    </Link>
                                </div>

                                {memory.has_tags ?
                                    <div
                                        onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                        className="full-memory-tags-btn">
                                        <img
                                            class="full-memory-tag-icon"
                                            src={require("../../../assets/arrange-meeting/svg/Icon-p.svg")}
                                            alt=""
                                        />
                                    </div>
                                    : profile.user === memory.user ?
                                        <div
                                            onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                            className="full-memory-tags-btn">
                                            <img
                                                class="full-memory-tag-icon"
                                                src={require("../../../assets/arrange-meeting/svg/add-tag.svg")}
                                                alt=""
                                            />
                                        </div>
                                        :
                                        null
                                }
                                {this.state.tagsModalVisible ?
                                    <Modal visible={this.state.tagsModalVisible} width="100" height="100" effect="fadeInRight" onClickAway={() => this.closeModal()}>
                                        <MemoryTagsModal
                                            data={{
                                                profile: this.props.profile,
                                                buddies: this.props.buddies,
                                                closeModal: this.closeModal.bind(this),
                                                memory_hub: this.state.memory_hub,
                                                tags: this.props.tags,
                                                memory_user: this.state.memory_user,
                                                memory_id: this.state.memory_id,
                                                from_homepage: false
                                            }}
                                            getBuddies={this.props.getBuddies}
                                            updateProfile={this.props.updateProfile}
                                            searchBuddy={this.props.searchBuddy}
                                            addTagedBuddy={this.props.addTagedBuddy}
                                            removeTagedBuddy={this.props.removeTagedBuddy}
                                            removeMytag={this.props.removeMytag}
                                        />
                                    </Modal>
                                    :
                                    null
                                }

                                {this.state.reaction_sellected ?
                                    <>
                                        {this.state.reaction === "love" ?
                                            <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                <img
                                                    class="memory-mika-animation-size"
                                                    // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                    src={require("../../../assets/profile/svg/gif-heart.gif")}

                                                />
                                            </div>
                                            : this.state.reaction === "wow" ?
                                                <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                    <img
                                                        class="memory-mika-animation-size"
                                                        src={require("../../../assets/layout/mika/star-eyes-animation.gif")}
                                                    />
                                                </div>
                                                : this.state.reaction === "like" ?
                                                    <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                        <img
                                                            class="memory-mika-animation-size"
                                                            src={require("../../../assets/layout/mika/mika-like-animation.gif")}
                                                        />
                                                    </div>
                                                    : this.state.reaction === "cursing" ?
                                                        <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                            <img
                                                                class="memory-mika-animation-size"
                                                                src={require("../../../assets/layout/mika/cursing-animation.gif")}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                        }
                                    </>
                                    :
                                    null
                                }

                                <div class="snapped-photo-layout">
                                    {profile.username === memory.user_username ?
                                        <img
                                            class="snapped-photo-size" src={memory.photo}
                                        />
                                        : memory.memory_reaction !== null ?
                                            <img
                                                class="snapped-photo-size" src={memory.photo}
                                            />
                                            : this.state.reaction === "love" ?
                                                <img
                                                    class="snapped-photo-size" src={memory.photo}
                                                />
                                                :
                                                <img
                                                    onDoubleClick={this.updateReactionSelection("love", memory.id)}
                                                    class="snapped-photo-size" src={memory.photo}
                                                />
                                    }
                                    {/* <img class="snapped-photo-size-cover" src={memory.photo} /> */}
                                    <div class="snapped-photo-size-cover"></div>
                                </div>
                                <Link to={{ pathname: '/hub', state: { hub_id: memory.hub_id } }}>
                                    {memory.hub_logo ?
                                        <img class="memory-buddys-hub-logo" src={memory.hub_logo} alt="" />
                                        :
                                        <img class="memory-buddys-hub-logo" src={require("../../../assets/hub/hub-page/svg/location-red.png")} alt="" />

                                    }
                                </Link>
                                {profile.username === memory.user_username ?
                                    <div
                                        onClick={() => this.openModal()}
                                        class="memory-trash-can"
                                    >
                                        < img
                                            class=""
                                            // onClick={this.updateReactionSelection("love", memory.id)}
                                            src={require("../../../assets/memories/svg/trash.svg")}
                                        />
                                    </div>

                                    : memory.memory_reaction !== null ?
                                        <div class="memory-all-reactions">
                                            < img
                                                class="memory-mika-sellected-reaction"
                                                // onClick={this.updateReactionSelection("love", memory.id)}
                                                src={require("../../../assets/layout/homepage svg/love-red.svg")}
                                            />
                                        </div>
                                        : this.state.reaction === "love" ?
                                            <div class="memory-all-reactions">
                                                < img
                                                    class="memory-mika-sellected-reaction"
                                                    // onClick={this.updateReactionSelection("love", memory.id)}
                                                    src={require("../../../assets/layout/homepage svg/love-red.svg")}
                                                />
                                            </div>
                                            :
                                            <div class="memory-all-reactions">
                                                < img
                                                    class="memory-mika-sellected-reaction"
                                                    onClick={this.updateReactionSelection("love", memory.id)}
                                                    src={require("../../../assets/layout/homepage svg/white-love.svg")}
                                                />
                                            </div>

                                }
                                <div class="my-memory-reactions">
                                    <Link to={{ pathname: '/memory-impressions', state: { memory: memory.id } }}>
                                        {this.state.reaction === "love" ?
                                            <>
                                                <p class="my-memory-reactions-count">
                                                    {memory.reactions_number + 1} Likes
                                                </p>
                                            </>
                                            :
                                            <>
                                                {memory.reactions_number === 1 ?
                                                    < p class="my-memory-reactions-count">
                                                        1 like
                                                    </p>
                                                    :
                                                    <p class="my-memory-reactions-count">
                                                        {memory.reactions_number} Likes
                                                    </p>
                                                }
                                            </>
                                        }
                                    </Link>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    tags: state.memory.tags,
    buddies: state.buddies.buddies,
    memory: state.memory.memories,
    profile: state.profile.profile,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    getFullScreenMemory,
    createMemoryReaction,
    deleteMemory,
    getMyProfile,
    createMemoryReaction,
    getMemoryTags,
    getBuddies,
    searchBuddy,
    addTagedBuddy,
    removeTagedBuddy,
    removeMytag
})(Memory);