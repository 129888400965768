import axios from 'axios';

import { createAlert, returnErrors } from './alerts';

import {
    ACCEPT_HUB_RESERVATION,
    DECLINE_HUB_RESERVATION,
    REQUEST_RESERVATION,
    GET_PENDING_HUB_RESERVATIONS,
    PENDING_HUB_RESERVATIONS_LOCALY_LOADED,
    GET_PENDING_RESERVATIONS,
    GET_BOOKED_RESERVATIONS,
    GET_ALL_CANCELLED_RESERVATIONS,
    CANCELLED_RESERVATIONS_LOCALY_LOADED,
    GET_DATES_BOOKED_RESERVATIONS,
    GET_DATES_BOOKED_RESERVATIONS_CANCELLED,
    GET_DATES_BOOKED_HUB_RESERVATIONS,
    ANSWER_RESERVATION,
    GET_BOOKED_RESERVATIONS_NUMBERS,
    GET_HUB_FREE_TABLES,
    UPDATE_RESERVATION_TABLE,
    UPDATE_RESERVATION_NOTE,
    INCREASE_RESERVATION_ARRIVED_PERSONS,
    DECREASE_RESERVATION_ARRIVED_PERSONS,
    HUB_CREATE_NEW_RESERVATION,
    GET_USER_HUB_RESERVATIONS,
    USER_HUB_RESERVATIONS_LOCALY_LOADED,
    INCREASE_HUB_RESERVATION_ARRIVED_PERSONS,
    DECREASE_HUB_RESERVATION_ARRIVED_PERSONS,
    UPDATE_HUB_RESERVATION_TABLE,
    GET_HUB_ALL_FREE_TABLES,
    EMPTY_HUB_RESERVATION_TABLE,
    USER_CREATE_NEW_RESERVATION,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    PENDING_RESERVATIONS_LOCALY_LOADED,
    BOOKED_RESERVATIONS_LOCALY_LOADED,
    BOOKED_RESERVATIONS_NUMBERS_LOCALY_LOADED,
    CANCEL_HUB_RESERVATION,
    GET_HUB_RESERVATION_SHARES,
    SHARE_HUB_RESERVATION,
    USER_CANCEL_HUB_RESERVATION,
    GET_ALL_HUB_RESERVATIONS_CANCELLED,
    ALL_HUB_RESERVATIONS_CANCELLED_LOCALY_LOADED,
    EMPTY_RESERVATION_TABLE,
    INCREASE_TABLE_PERSONS,
    DECREASE_TABLE_PERSONS,
    CREATE_TABLE,
    SET_TABLE_STATUS,
    SET_HUB_RESERVATION_TABLE,
    SET_RESERVATION_TABLE,
    SET_TABLE_PERSONS,
    UPDATE_HUB_RESERVATION_NOTE,
    GET_DATES_RESERVATION_HOURS
} from './actionTypes';
import { tokenConfig } from './auth';
import { bindActionCreators } from 'redux';

export const requestReservation = (request, meeting_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/${meeting_id}/request-reservation/`, request, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'Reservation has been requested' }));
            dispatch({
                type: REQUEST_RESERVATION,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getPendingReservations = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/reservation/pending-reservations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PENDING_RESERVATIONS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: PENDING_RESERVATIONS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}

export const getAllCancelledReservations = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/all-cancelled-reservations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_ALL_CANCELLED_RESERVATIONS,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: CANCELLED_RESERVATIONS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}

export const getBookedReservations = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/reservation/booked-reservations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_BOOKED_RESERVATIONS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: BOOKED_RESERVATIONS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const getDatesReservations = (date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/reservation/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_BOOKED_RESERVATIONS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getDatesHubReservations = (date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/get-hub-reservation/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_BOOKED_HUB_RESERVATIONS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getDatesCancellations = (date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/reservation/reservation-cacelled/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_BOOKED_RESERVATIONS_CANCELLED,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}



// export const handleReservation = (answer, reservation_id) => (dispatch, getstate) => {
//     axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/${reservation_id}/response/`, answer, tokenConfig())
//         .then(res => {
//             dispatch({
//                 type: ANSWER_RESERVATION,
//                 payload: res.data
//             });
//         }).catch(err => console.log(err));
// }

export const createTable = (table) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/create-table/`, table, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_TABLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const acceptReservation = (answer, reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/${reservation_id}/accept/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: ANSWER_RESERVATION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const declineReservation = (answer, reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/${reservation_id}/decline/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: ANSWER_RESERVATION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const reservationNotAttended = (answer, reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/${reservation_id}/not-attended/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: ANSWER_RESERVATION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getHubFreeTables = (reservation_id) => (dispatch, getstate) => {
    if (reservation_id !== undefined) {
        axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${reservation_id}/available-tables/`, tokenConfig())
            .then(res => {
                dispatch({
                    type: GET_HUB_FREE_TABLES,
                    payload: res.data
                });
            }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
    }
}

export const getHubAllTables = (date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${date}/all-tables/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_FREE_TABLES,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getAllFreeTables = (date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${date}/all-free-tables/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_ALL_FREE_TABLES,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const onEmptyTableReservation = (reservation_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${reservation_id}/empty-reservation-table/`, tokenConfig())
        .then(res => {
            dispatch({
                type: EMPTY_RESERVATION_TABLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const onEmptyTableHubReservation = (hub_reservation_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation_id}/empty-hub-reservation-table/`, tokenConfig())
        .then(res => {
            dispatch({
                type: EMPTY_HUB_RESERVATION_TABLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const updateReservationTable = (table, reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${reservation_id}/update-reservation-table/`, table, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_RESERVATION_TABLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const updateHubReservationTable = (table, hub_reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation_id}/update-hub-reservation-table/`, table, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_HUB_RESERVATION_TABLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const updateReservationNote = (note, reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${reservation_id}/update-reservation-note/`, note, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_RESERVATION_NOTE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const setReservationTable = (reservation) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${reservation["table"].id}/set-reservation-table/`, reservation["table"], tokenConfig())
        .then(res => {
            dispatch({
                type: SET_RESERVATION_TABLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const setHubReservationTable = (hub_reservation) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation["table"].id}/set-hub-reservation-table/`, hub_reservation["table"], tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_RESERVATION_TABLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const increaseTablePersons = (table_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${table_id}/increase-table-persons/`, tokenConfig())
        .then(res => {
            dispatch({
                type: INCREASE_TABLE_PERSONS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));

}

export const decreaseTablePersons = (table_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${table_id}/decrease-table-persons/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DECREASE_TABLE_PERSONS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));

}

// export const increaseReservationArrivedPersons = (reservation_id) => (dispatch, getstate) => {
//     axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${reservation_id}/increase-reservation-attendence/`, tokenConfig())
//         .then(res => {
//             dispatch({
//                 type: INCREASE_RESERVATION_ARRIVED_PERSONS,
//                 payload: res.data
//             });
//         }).catch(err => console.log(err));
// }

// export const decreaseReservationArrivedPersons = (reservation_id) => (dispatch, getstate) => {
//     axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${reservation_id}/decrease-reservation-attendence/`, tokenConfig())
//         .then(res => {
//             dispatch({
//                 type: DECREASE_RESERVATION_ARRIVED_PERSONS,
//                 payload: res.data
//             });
//         }).catch(err => console.log(err));
// }

export const increaseHubReservationArrivedPersons = (hub_reservation_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation_id}/increase-hub-reservation-attendence/`, tokenConfig())
        .then(res => {
            dispatch({
                type: INCREASE_HUB_RESERVATION_ARRIVED_PERSONS,
                payload: res.data
            });
        }).catch(err => console.log(err));
}

export const decreaseHubReservationArrivedPersons = (hub_reservation_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation_id}/decrease-hub-reservation-attendence/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DECREASE_HUB_RESERVATION_ARRIVED_PERSONS,
                payload: res.data
            });
        }).catch(err => console.log(err));
}

export const createNewReservation = (reservation) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/create-reservation/`, reservation, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'The Reservation has been created' }));
            dispatch({
                type: HUB_CREATE_NEW_RESERVATION,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const cancelHubReservation = (hub_reservation, hub_reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation_id}/hub-reservation/cancel-hub-reservation/`, hub_reservation, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'The Reservation has been cancelled' }));
            dispatch({
                type: CANCEL_HUB_RESERVATION,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getUserHubReservations = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-user-hub-reservation/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_USER_HUB_RESERVATIONS,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: USER_HUB_RESERVATIONS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}

export const getHubReservtionShares = (hub_reservation_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-reservation/${hub_reservation_id}/shares/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_RESERVATION_SHARES,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const shareHubReservation = (hub_reservation_share, hub_reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-reservation/${hub_reservation_id}/create-share/`, hub_reservation_share, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'The Reservation has been shared' }));
            dispatch({
                type: SHARE_HUB_RESERVATION,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const userCancelHubReservation = (hub_reservation, hub_reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation_id}/hub-reservation/user-cancel-hub-reservation/`, hub_reservation, tokenConfig())
        .then(res => {
            dispatch({
                type: USER_CANCEL_HUB_RESERVATION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const setTableStatus = (table_id, table) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/table/${table_id}/set-table-status/`, table, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_TABLE_STATUS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getHubAllCancelledHubReservations = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/all-cancelled-hub-reservations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_ALL_HUB_RESERVATIONS_CANCELLED,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: ALL_HUB_RESERVATIONS_CANCELLED_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const getUserAllCancelledHubReservations = () => (dispatch) => {

    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user/all-cancelled-hub-reservations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_ALL_HUB_RESERVATIONS_CANCELLED,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: ALL_HUB_RESERVATIONS_CANCELLED_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const setTablePersons = (table) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/table/set-table-persons/`, table, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_TABLE_PERSONS,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const createHubReservationUser = (reservation) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservation/user-request-hub-reservation/`, reservation, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'The Hub will answer your Request soon' }));
            dispatch({
                type: USER_CREATE_NEW_RESERVATION,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const getPendingHubReservations = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/pending-hub-reservations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PENDING_HUB_RESERVATIONS,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: PENDING_HUB_RESERVATIONS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const acceptHubReservationRequest = (answer, reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub_reservation/${reservation_id}/accept/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: ACCEPT_HUB_RESERVATION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const declineHubReservationRequest = (answer, reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub_reservation/${reservation_id}/decline/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: DECLINE_HUB_RESERVATION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const updateHubReservationNote = (note, hub_reservation_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_reservation_id}/update-hub-reservation-note/`, note, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_HUB_RESERVATION_NOTE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getHubDaysReservationHours = (hub_id, date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${hub_id}/reservation-hours/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_RESERVATION_HOURS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}