import axios from 'axios';

import { createAlert, returnErrors } from './alerts';

import {
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    GET_DATES_APPOINTMENT_HOURS,
    HUB_CREATE_NEW_APPOINTMENT,
    GET_DATES_APPOINTMENTS,
    UPDATE_APPOINTMENT_NOTE,
    CANCEL_APPOINTMENT,
    UPDATE_APPOINTMENT,
    GET_PENDING_APPOINTMENTS,
    ANSWER_APPOINTMENT,
    REQUEST_APPOINTMENT,
    GET_USER_APPOINTMENTS,
    USER_CANCEL_APPOINTMENT,
} from './actionTypes';
import { tokenConfig } from './auth';
import { bindActionCreators } from 'redux';


export const getHubDaysAppointmentHours = (hub_id, date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${hub_id}/appointment-hours/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_APPOINTMENT_HOURS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const createNewAppointment = (appointment) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/create-appointment/`, appointment, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'The Appointment has been created' }));
            dispatch({
                type: HUB_CREATE_NEW_APPOINTMENT,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getDatesAppointments = (date) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/get-hub-appointment/${date}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_DATES_APPOINTMENTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const updateAppointmentNote = (note, appointment_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${appointment_id}/update-appointment-note/`, note, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_APPOINTMENT_NOTE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const cancelAppointment = (appointment, appointment_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${appointment_id}/appointment/cancel-appointment/`, appointment, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'The Appointment has been cancelled' }));
            dispatch({
                type: CANCEL_APPOINTMENT,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const updateAppointment = (appointment) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/update-appointment/`, appointment, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_APPOINTMENT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getPendingAppointments = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/get-pending-appointments/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PENDING_APPOINTMENTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const acceptAppointment = (answer, appointment_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/${appointment_id}/accept/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: ANSWER_APPOINTMENT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const declineAppointment = (answer, appointment_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/${appointment_id}/decline/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: ANSWER_APPOINTMENT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const requestAppointment = (appointment) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/request-appointment/`, appointment, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'YOUR APPOINTMENT REQUEST HAS BEEN SENT. YOU WILL RECEIVE AN ANSWER SOON' }));

            dispatch({
                type: REQUEST_APPOINTMENT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getUserAppointments = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/get-user-appointments/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_USER_APPOINTMENTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const userCancelAppointment = (appointment) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/appointment/user-cancel-appointment/`, appointment, tokenConfig())
        .then(res => {
            dispatch(createAlert({ requestReservation: 'YOUR APPOINTMENT HAS BEEN CANCELLED. WE WILL NOTIFY THE PLACE' }));

            dispatch({
                type: USER_CANCEL_APPOINTMENT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}