import React, { Fragment, Component, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { cancelBuddyRequest, sendBuddyRequest, getSearchUsers } from '../../../store/actions/buddies'
import { getMyProfile } from '../../../store/actions/profile';
import { getNotificationsStatus } from '../../../store/actions/notification'
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import '../../../assets/shops/swipe-products.css'
import SwipeProductsFull from '../../../components/user/shopping/SwipeProductsFull';

import { getUserProducts } from '../../../store/actions/shops'


export class SwipeProducts extends Component {

    state = {

    };

    componentDidMount() {
        this.props.getUserProducts()
    }

    onSwipe = (direction) => {
        // console.log('You swiped: ' + direction)
        this.setState({
            swiping_user: false
        })
    }

    outOfFrame = (myIdentifier) => {
        // console.log(myIdentifier + ' left the screen')
        this.setState({
            swiping_user: false
        })
    }

    clickOnSwipe = (user) => (e) => {
        // console.log(window)
        this.setState({
            swiping_user: true
        })
    }


    render() {
        console.log(this.props)
        return (
            <Fragment>

                <div class="swipe-buddies-whole-page-layout">
                    <OfflineLabel />
                    <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />

                    <div id="page-scroll-up" class="">
         
                    </div>
                    {this.props.user_products.length > 0 ?
                        <SwipeProductsFull
                            data={{
                                user_products: this.props.user_products,
                                
                            }}
                        />
                        :
                        null
                    }

                </div >
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />
                </ScrollLink>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in buddies.js
    user_products: state.shops.user_products,
    profile: state.profile.profile,
    auth: state.auth,
    users: state.buddies.users,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { 
    getSearchUsers, 
    cancelBuddyRequest, 
    sendBuddyRequest, 
    getNotificationsStatus, 
    getMyProfile,
    getUserProducts 
})(SwipeProducts);

