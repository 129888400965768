import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import GdprLinks from '../../../components/user/layout/gdprLinks';
import {
    getCompanyAmbasaddorsProfile,
    // getCompanyAmbasaddorsHubJobs
} from '../../../store/actions/ambassandor';
import CookiesModal from '../../../components/user/layout/cookiesModal';
import "../../../assets/hubst3r-staff/company-ambassandor.css";
import ShowUserJobModal from '../../../components/user/ambassandor/ShowUserJobModal';
import Modal from 'react-awesome-modal';



export class Ambassadors extends Component {

    state = {
        isLoading: true,
        showJobModalVisible: false,
    };

    componentDidMount() {
        this.props.getCompanyAmbasaddorsProfile(this.props.match.params.profileID)
        // this.props.getCompanyAmbasaddorsHubJobs(this.props.match.params.profileID)
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 2800);
    }


    openShowHubJobsModal(job) {
        this.setState({
            showJobModalVisible: true,
            job: job
        });
    }

    closeModal() {
        this.setState({
            showJobModalVisible: false,
            job: {}
        });
    }


    render() {
        let arleady_logged_in = false
        if (JSON.parse(localStorage.getItem('ur'))) {
            arleady_logged_in = true
        }
        return (
            <Fragment>
                {this.state.job ?
                    <Modal visible={this.state.showJobModalVisible} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                        <ShowUserJobModal
                            data={{
                                job: this.state.job,
                                closeModal: this.closeModal.bind(this)
                            }}
                        />
                    </Modal>
                    :
                    null
                }
                <div class={this.state.isLoading ? "company-ambassandors-page-lottie-appear" : "company-ambassandors-page-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div>
                <CookiesModal />


                <div class="company-ambassandor-all-page-display">
                    <div class={this.state.isLoading ? "company-ambassandors-page-section-disappear" : null}>
                        <div class="company-ambassandors-page-whole-page-layout">
                            <OfflineLabel />
                            <div class="color-4">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="container-fluid small-header">
                                            <div class="row py-4">
                                                <div class="company-ambassandor-top-bar-logo">
                                                    <Link to="/">
                                                        <img src={require("../../../assets/layout/homepage svg/Logo.svg")} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="company-ambassandors-body-right col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            {this.props.company_ambassandor.map(ambassandor => (
                                                <>
                                                    <div>
                                                        <div class="company-ambassandor-profile-cover-photo">
                                                            <img
                                                                class="company-ambassandor-profile-cover-img"
                                                                src={ambassandor.ambassandor_cover_photo}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="company-ambassandor-profile-profile-photo">
                                                            <img
                                                                class="company-ambassandor-profile-profile-img"
                                                                src={ambassandor.ambassandor_profile_picture}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="company-ambassandor-profile-username-layout">
                                                            <h2 class="company-ambassandor-profile-username">
                                                                {ambassandor.name}
                                                            </h2>
                                                        </div>
                                                    </div>



                                                    <div class="row company-ambassandor-profile-social-media">
                                                        {ambassandor.has_instagram ?
                                                            <div class="company-ambassandor-profile-app">
                                                                <a href={ambassandor.instagram_redirect}>
                                                                    <img
                                                                        class="company-ambassandor-app-icon-size-insta"
                                                                        src={require("../../../assets/ambassandors/svg/instagram.svg")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="company-ambassandor-profile-app-username">{ambassandor.instagram_username}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_website ?
                                                            <div class="company-ambassandor-profile-app">
                                                                <a href={ambassandor.website}>
                                                                    <img
                                                                        class="company-ambassandor-app-icon-size-insta"
                                                                        src={require("../../../assets/ambassandors/svg/website.png")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="company-ambassandor-profile-app-username">Website</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_tiktok ?
                                                            <div class="company-ambassandor-profile-app">
                                                                <a href={ambassandor.tiktok_redirect}>
                                                                    <img
                                                                        class="company-ambassandor-app-icon-size-facebook"
                                                                        src={require("../../../assets/ambassandors/svg/tiktok.svg")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="company-ambassandor-profile-app-username">{ambassandor.tiktok_username}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_facebook ?
                                                            <div class="company-ambassandor-profile-app">
                                                                <a href={ambassandor.facebook_redirect}>
                                                                    <img
                                                                        class="company-ambassandor-app-icon-size-facebook"
                                                                        src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="company-ambassandor-profile-app-username">{ambassandor.facebook_username}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_mobile_number ?
                                                            <div class="company-ambassandor-profile-app">
                                                                {/* <a href={ambassandor.facebook_redirect}> */}
                                                                <img
                                                                    class="company-ambassandor-app-icon-size-facebook"
                                                                    src={require("../../../assets/ambassandors/svg/phone.png")}
                                                                    alt=""
                                                                />
                                                                {/* </a> */}
                                                                <a href={ambassandor.mobile_number}>
                                                                    <p class="company-ambassandor-profile-app-username">
                                                                        {ambassandor.mobile_number}
                                                                    </p>
                                                                </a>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_email ?
                                                            <div class="company-ambassandor-profile-app">
                                                                {/* <a href={ambassandor.facebook_redirect}> */}
                                                                <img
                                                                    class="company-ambassandor-app-icon-size-facebook"
                                                                    src={require("../../../assets/ambassandors/svg/emial.svg")}
                                                                    alt=""
                                                                />
                                                                {/* </a> */}
                                                                <a href={ambassandor.email}>
                                                                    <p class="company-ambassandor-profile-app-username">{ambassandor.email}</p>
                                                                </a>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>

                                                    <br /><br /><br />
                                                    <div class="company-ambassandor-profile-hiring-position">
                                                        <div class="company-ambassandor-constact-us-text">
                                                            Do you want one for your Company?
                                                        </div>
                                                        <br />
                                                        <Link to="/company-contact-us">
                                                            <div class="company-ambassandor-constact-us-btn">
                                                                Contact us Now
                                                            </div>
                                                        </Link>

                                                        <br /><br />
                                                        <div class="landing-page-download-google">
                                                            <a href="https://play.google.com/store/apps/details?id=com.app.hubst3r">
                                                                <img
                                                                    class="landing-page-logo-img"
                                                                    src={require("../../../assets/ambassandors/svg/google-play.png")}
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </div>
                                                        <div class="landing-page-download-huawei">
                                                            <a href="https://appgallery.huawei.com/#/app/C104177697">
                                                                <img
                                                                    class="landing-page-logo-img"
                                                                    src={require("../../../assets/ambassandors/svg/app-gallery.jpg")}
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </div>

                                                        <div class="landing-page-title-visite">
                                                            or visit
                                                        </div>

                                                        <Link to="/">
                                                            <div class="landing-page-site">
                                                                <u>www.hubst3r.com</u>
                                                            </div>
                                                        </Link>

                                                        {/* {ambassandor.hiring ?
                                                            <>
                                                                <div class="company-ambassandor-we-are-hiring">
                                                                    <img
                                                                        class="company-ambassandor-hiring-icon-size"
                                                                        src={require("../../../assets/ambassandors/svg/hiring.png")}
                                                                        alt=""
                                                                    />
                                                                    <h2 class="company-ambassandor-profile-username">
                                                                        What are we looking for?
                                                                    </h2>
                                                                    <div>
                                                                        {this.props.company_jobs.map(job => (
                                                                            <div class="row company-job-item">
                                                                                <h2
                                                                                    onClick={() => this.openShowHubJobsModal(job)}
                                                                                    class="company-job-name">
                                                                                    {job.name}
                                                                                </h2>
                                                                                <div
                                                                                    onClick={() => this.openShowHubJobsModal(job)}
                                                                                    class="company-job-item-show-more">
                                                                                    See More
                                                                                </div>

                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        } */}
                                                    </div>
                                                </>
                                            ))}

                                            <div class="company-ambassandor-gdpr-place-open">
                                                <div class="row login-legal-position">
                                                    <div class="col-xl-12 text-center mt-2">
                                                        <Link to="/terms-of-use">
                                                            <a class="link2" href="#">Terms</a>
                                                        </Link>
                                                        <Link to="/privacy-policy">
                                                            <a class="link2" href="#">Privacy</a>
                                                        </Link>
                                                        <Link to="/cookies-policy">
                                                            <a class="link2" href="#">Cookies</a>
                                                        </Link>
                                                        <br />
                                                        {/* <Link to="/about"> */}
                                                        <a class="link2" href="http://about.hubst3r.com/">About</a>
                                                        {/* </Link> */}
                                                        <Link to="/licenses">
                                                            <a class="link2" href="#">Licenses</a>
                                                        </Link>
                                                    </div>
                                                </div>
                                                {/* <GdprLinks /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    company_ambassandor: state.ambassandor.company_ambassandor,
    company_jobs: state.ambassandor.company_ambassandor,
});


export default connect(mapStateToProps, {
    getCompanyAmbasaddorsProfile,
    // getCompanyAmbasaddorsHubJobs
})(Ambassadors);