import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import 'mapbox-gl/dist/mapbox-gl.css';
// import "../../../assets/map/map-search.css";
import Geocoder from "react-mapbox-gl-geocoder";
import mapboxgl from 'mapbox-gl';
import HomepageMap from '../../../components/user/map/HomepageMap';
import MapGeocoder from '../../../components/user/map/MapGeocoder';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';


import { Link, Redirect } from 'react-router-dom';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { getMyProfile } from '../../../store/actions/profile';
import {
    getMapPlaces,
    getMapMemories,
    getMapUserWithMemories,
    userChangeHubCoverPhoto
} from '../../../store/actions/map';

import {
    getHubMemories,
    deleteReaction,
    createMemoryReaction,
    deleteMemory,
    createMemoryView,
    clearStateHubMemories,
    getMemoryTags,
    clearMemoryTags,
} from '../../../store/actions/memory';
import { getNotificationsStatus, registerPushNotificationDevice } from '../../../store/actions/notification'
import { updateProfilePicture, updateProfileFullName } from '../../../store/actions/profile'
import { visitedUserRegistered, createActiveUserVisit } from '../../../store/actions/hubst3rActions';

import { createAlert } from '../../../store/actions/alerts'
import {
    searchHubs,
    createHubProspect,
    createSuggestionHubProspect,
    createdHubMarker,
    getNewCreatedHub,
} from '../../../store/actions/hub'
import { submitHubContactForm } from '../../../store/actions/authhub'

import { requestAppointment } from '../../../store/actions/appointment'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useSwipeable } from 'react-swipeable';

import GoogleMapReact from 'google-map-react';
import MyMarker from '../../../components/user/map/MyMarker';
import MemoryMarker from '../../../components/user/map/MemoryMarker';
import CenterMarker from '../../../components/user/map/CenterMarker';
import PlaceMarker from '../../../components/user/map/PlaceMarker';
import { PlaceMarkerModal } from '../../../components/user/map/PlaceMarkerModal';
import InsertProfileDataModal from '../../../components/user/homepage/InsertProfileDataModal';

import Modal from 'react-awesome-modal';
import ReactStreetview from 'react-streetview';
import Stories from 'react-insta-stories';
import moment from "moment-timezone";

import TextareaAutosize from 'react-textarea-autosize';
import { Link as ScrollLink } from 'react-scroll';
import "../../../assets/map/map-search.css";
import "../../../assets/layout/homepage.css";
import { PlaceClaimModal } from '../../../components/user/map/PlaceClaimModal';



export class StreetView extends React.PureComponent {

    constructor(props) {
        super(props);
        // this.state = {
        //     lat: 34.688447,
        //     lng: 33.009168,
        //     zoom: 18,
        //     lastRequestedCenter: {
        //         lat: 34.688447,
        //         lng: 33.009168
        //     },
        // };

        this.state = {
            // for google map places autocomplete
            address: '',
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            mapCenter: {
                lat: 0,
                lng: 0
            },
            myMapCenterLocation: {
                lat: 0,
                lng: 0
            },
            lastRequestedCenter: {
                lat: 0,
                lng: 0
            },
            selected_location: [],
            tags: [],
            hub_id: "",
            redirect: false,
            isMapWindowOpen: false,
            is_rendered: false,
            marker_width: 0,
            zoom: 17,
            hubModal: false,
            streetViewModal: false,
            selected_hub: {},
            address: "",
            show_places_bubble: true,
            categoryFilterModal: false,
            category_search: "",
            sellected_categories: [],
            storyShowMore: false,
            memory_show_more_id: "",
            start_swipe_x: "",
            start_swipe_y: "",
            swipe_extend_x: "",
            swipe_extend_y: "",
            showstories: false,
            one_person: false,
            memories_user_id: "",
            next_user_memory: true,

            profileDataModalVisible: true,
            hub: {},
            new_memory: "",
            memory_photo: null,
            memory_submited: false,
            isLoading: true,
            reaction_sellected: true,
            reaction: "",
            reaction_sellected: false,
            show_reaction: false,
            displayed_memory_id: "",
            story_index: 0,
            isStoryPaused: false,
            hubContactModal: false,
            hub_contact_name: "",
            hub_contact_telephone: "",
            submitedHubContact: false,
            is_location_memories: false,
            user_memory_lat: "",
            user_memory_lng: "",
            is_hub_memories: false,
            photo: "",
            cover_photo: null,
            changing_cover_hub_id: "",
            seen_memories: [],
            all_users_stories: false,
            memory_to_show_index: 0,
            is_story_paused: null,
            created_suggestions: [],
        }

    }

    componentWillMount() {
        this.props.getNotificationsStatus()
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 1200);
        let city = JSON.parse(localStorage.getItem('slctct'))
        if (!city) {
            city = "show_all"
        }
        const category = "show_all"

        this.props.registerPushNotificationDevice()

        if (localStorage.getItem('ntfctsmdl')) {
            this.setState({
                profileDataModalVisible: false,
            })
        }

        if (localStorage.getItem('vstrgstr')) {
            setTimeout(() => {
                const visit_id = JSON.parse(localStorage.getItem("vstbjctd"))
                if (visit_id !== null) {
                    this.props.visitedUserRegistered(visit_id)
                }
                localStorage.removeItem("vstrgstr")
            }, 1500);
        }

        const browser = window.loadVersionBrowser().name;
        const full_browser = window.clientInformation.userAgent;
        const device_width = window.innerWidth
        const device_height = window.innerHeight
        fetch(`https://geolocation-db.com/json/`)
            .then(response => {
                return response.json();
            }, "jsonp")
            .then(res => {
                const ip = res.IPv4
                const country = res.country_name
                let city = res.city
                const latitude = res.latitude
                const longitude = res.longitude
                const state = res.state
                const data = { browser, full_browser, device_width, device_height, ip, country, city, latitude, longitude, state }
                this.props.createActiveUserVisit(data)
                if (JSON.parse(localStorage.getItem('slctct')) !== "") {
                    city = JSON.parse(localStorage.getItem('slctct'))
                }
                localStorage.setItem('cntr', JSON.stringify(country));
                // this.props.getHomepageAllHubs(country, city, category, 0);
            }).catch(err => {
                console.log("Hiiiiiii")
            });



        this.props.getMyProfile();
        const country = JSON.parse(localStorage.getItem('cntr'))

        const props = this.props
        const page_props = this

        window.navigator.geolocation.getCurrentPosition(position => {
            this.setState({
                mapCenter: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                myMapCenterLocation: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                lastRequestedCenter: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                is_rendered: true
            })
            this.props.getMapPlaces("show_all", position.coords.latitude, position.coords.longitude, 19);
        }, function (error) {
            fetch(`https://geolocation-db.com/json/`)
                .then(response => {
                    return response.json();
                }, "jsonp")
                .then(res => {
                    page_props.setState({
                        mapCenter: {
                            lat: res.latitude,
                            lng: res.longitude
                        },
                        myMapCenterLocation: {
                            lat: res.latitude,
                            lng: res.longitude
                        },
                        lastRequestedCenter: {
                            lat: res.latitude,
                            lng: res.longitude
                        },
                        is_rendered: true
                    })
                }).catch(err => {
                    console.log(err)
                });

            props.getMapPlaces("show_all", 'show_all', 'show_all', 19);
        })
        this.props.getMapMemories()
        this.props.getMapUserWithMemories()
    }


    componentDidMount() {
        const width = window.screen.width
        this.setState({
            marker_width: width,
        })
        this.interval = setInterval(() => {
            this.props.getNotificationsStatus()
            this.getMyCurrentLocation()
        }, 10000);
        // this.interval = setInterval(() => , 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    onCategoryChange = e => {
        this.setState({
            category_search: e.target.value
        })
    }

    getMyCurrentLocation() {
        setTimeout(() => {
            window.navigator.geolocation.getCurrentPosition(position => {
                this.setState({
                    myMapCenterLocation: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                })
            })
        }, 60000);
    }


    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }


    selectCategory(category) {
        const my_categories_list = [...this.state.sellected_categories]
        if ((my_categories_list.indexOf(category)) !== -1) {
            const new_remove = my_categories_list.filter(f => f !== category)
            this.setState({
                sellected_categories: new_remove,
                category_search: "",
            })
        } else {
            my_categories_list.push(category)
            this.setState({
                sellected_categories: my_categories_list,
                category_search: "",
            })
        }
        this.setState({
            is_category_changed: true,
        })
    }

    changeIsCategoryChanged() {
        this.setState({
            is_category_changed: false,
        })
    }

    clearCategories() {
        this.setState({
            sellected_categories: [],
            is_category_changed: true,
        })
        this.closeModal()
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    handleToggleOpen = () => {
        this.setState({
            isMapWindowOpen: true
        });
    }

    handleToggleClose = () => {
        this.setState({
            isMapWindowOpen: false
        });
    }

    setNewCoords(ev) {

    }

    handleCenterChanged(lat, lng, zoom) {
        this.setState({
            zoom: zoom,
            mapCenter: {
                lat: lat,
                lng: lng
            }
        })
    };



    closeModal() {
        this.props.clearMemoryTags()
        if (this.state.showstories) {
            this.props.getMapUserWithMemories()
            this.props.getMapMemories()
        } else {
            this.props.clearStateHubMemories()
        }
        const selected_hub = this.state.selected_hub
        const is_hub_memories = this.state.is_hub_memories
        this.setState({
            hubModal: false,
            streetViewModal: false,
            selected_hub: {},
            categoryFilterModal: false,
            showstories: false,
            one_person: false,
            storyShowMore: false,
            memories_user_id: "",
            profileDataModalVisible: false,
            story_index: 0,
            hubContactModal: false,
            submitedHubContact: false,
            is_location_memories: false,
            user_memory_lat: "",
            user_memory_lng: "",
            is_hub_memories: false,
            all_users_stories: false,
        });
        if (is_hub_memories) {
            this.setState({
                hubModal: true,
                selected_hub: selected_hub,
            });
        }
    }

    openStoriesModal(user, one_person, is_location_memories, memory_lat, memory_lng, is_hub_memories, all_users_stories) {
        let memory_to_show_index = 0
        if (all_users_stories) {
            let memory_with_unseen = []
            const user_unseen_memories = this.props.map_memories.filter(x => x.user === user && x.is_seen === false);
            user_unseen_memories.map(memory => {
                const memory_id = memory.id
                memory_with_unseen.push(memory_id)
            })
            const state_seen_memories = this.state.seen_memories
            memory_with_unseen.map(memory_id => {
                if (state_seen_memories.includes(memory_id)) {
                    for (var i = 0; i < memory_with_unseen.length; i++) {
                        if (memory_with_unseen[i] === memory_id) {
                            memory_with_unseen.splice(i, 1);
                            i--;
                        }
                    }
                }
            })
            let sellected_user_memories = this.props.map_memories.filter(memory => memory.user === user)
            if (memory_with_unseen.length > 0) {
                const memory_to_show_id = memory_with_unseen[0]
                const index = sellected_user_memories.findIndex(x => x.id === memory_to_show_id);
                memory_to_show_index = index
            }
        }

        this.setState({
            story_index: memory_to_show_index,
            one_person: one_person,
            memories_user_id: user,
            is_location_memories: is_location_memories,
            user_memory_lat: memory_lat,
            user_memory_lng: memory_lng,
            is_hub_memories: is_hub_memories,
            hubModal: false,
            all_users_stories: all_users_stories,
            showstories: true,
        });
    }

    openStoryShowMore(memory_id) {
        this.props.getMemoryTags(memory_id)
        this.setState({
            is_story_paused: true,
            storyShowMore: true,
            memory_show_more_id: memory_id
        });
    }

    changeShowPlaceBubble() {
        this.setState({
            show_places_bubble: !this.state.show_places_bubble
        });
    }

    openModal(hub_id) {
        const hub = this.props.map_places.filter(place => place.id === hub_id)[0]
        this.props.getHubMemories(hub_id);
        this.setState({
            hubModal: true,
            selected_hub: hub,
            streetViewModal: false,
            categoryFilterModal: false
        });
    }

    openStreetViewModal() {
        this.setState({
            hubModal: false,
            streetViewModal: true,
            categoryFilterModal: false
        });
    }


    openCategoryFilterModal() {
        this.setState({
            hubModal: false,
            streetViewModal: false,
            categoryFilterModal: true
        });
    }


    handleChange = address => {
        this.setState({
            currentlySearching: true
        })
        if (this.state.selected_location.length > 0) {
            this.setState({
                selected_location: [],
                hub_id: ""
            });
        }

        if (address === "") {
            this.setState({ address });
            const country = JSON.parse(localStorage.getItem('cntr'))
            this.props.getMapPlaces("show_all", this.state.mapCenter.lat, this.state.mapCenter.lng, this.state.zoom)
        } else if (address.length > 6) {
            this.setState({ address });
        } else {
            const query = address
            // if (query.length > 2) {
            //     if (query.toLowerCase() !== "ad") {
            //         this.props.searchHubs(query)
            //     }
            // }
            this.setState({ address });
        };
        if (address.length === 0) {
            this.setState({
                currentlySearching: false
            })
        }

    }


    handleHubSelect(hub_id, lat, lng, my_hub_name, hub_address) {
        const showing_address = my_hub_name + ", " + hub_address
        this.setState({
            hub_id: hub_id,
            selected_location: [],
            address: showing_address,
            mapCenter: {
                lat: lat,
                lng: lng
            }

        });
    }

    onTouchStart(touchStartEvent) {
        this.setState({
            is_story_paused: true,
            start_swipe_x: touchStartEvent.targetTouches[0].clientX,
            start_swipe_y: touchStartEvent.targetTouches[0].clientY,
        })
    }


    onTouchMove(touchMoveEvent) {
        this.setState({
            swipe_extend_x: touchMoveEvent.targetTouches[0].clientX,
            swipe_extend_y: touchMoveEvent.targetTouches[0].clientY
        })
    }


    onTouchEnd() {
        this.setState({
            is_story_paused: false,
        })
        if (this.state.swipe_extend_x > 100 || this.state.swipe_extend_y > 100) {
            if (this.state.start_swipe_x - this.state.swipe_extend_x < -50) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {
                    const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index - 1
                    if (new_index > -1) {
                        if (this.props.user_with_memories.length > new_index) {

                            let memory_to_show_index = 0
                            if (this.state.all_users_stories) {
                                let memory_with_unseen = []
                                const user_unseen_memories = this.props.map_memories.filter(x => x.user === this.props.user_with_memories[new_index].user && x.is_seen === false);
                                user_unseen_memories.map(memory => {
                                    const memory_id = memory.id
                                    memory_with_unseen.push(memory_id)
                                })
                                const state_seen_memories = this.state.seen_memories
                                memory_with_unseen.map(memory_id => {
                                    if (state_seen_memories.includes(memory_id)) {
                                        for (var i = 0; i < memory_with_unseen.length; i++) {
                                            if (memory_with_unseen[i] === memory_id) {
                                                memory_with_unseen.splice(i, 1);
                                                i--;
                                            }
                                        }
                                    }
                                })
                                let sellected_user_memories = this.props.map_memories.filter(memory => memory.user === this.props.user_with_memories[new_index].user)
                                if (memory_with_unseen.length > 0) {
                                    const memory_to_show_id = memory_with_unseen[0]
                                    const index = sellected_user_memories.findIndex(x => x.id === memory_to_show_id);
                                    memory_to_show_index = index
                                }
                            }

                            this.setState({
                                next_user_memory: false,
                                memories_user_id: this.props.user_with_memories[new_index].user,
                                story_index: memory_to_show_index,
                                is_story_paused: false,
                            });

                            setTimeout(() => {
                                this.setState({
                                    next_user_memory: true,
                                });
                            }, 100);
                        }
                    } else {
                        this.closeModal()
                    }
                    this.closeStoryShowMore()
                }
            }

            if (this.state.start_swipe_x - this.state.swipe_extend_x > 100) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {
                    const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index + 1

                    if (this.props.user_with_memories.length > new_index) {
                        let memory_to_show_index = 0
                        if (this.state.all_users_stories) {
                            let memory_with_unseen = []
                            const user_unseen_memories = this.props.map_memories.filter(x => x.user === this.props.user_with_memories[new_index].user && x.is_seen === false);
                            user_unseen_memories.map(memory => {
                                const memory_id = memory.id
                                memory_with_unseen.push(memory_id)
                            })
                            const state_seen_memories = this.state.seen_memories
                            memory_with_unseen.map(memory_id => {
                                if (state_seen_memories.includes(memory_id)) {
                                    for (var i = 0; i < memory_with_unseen.length; i++) {
                                        if (memory_with_unseen[i] === memory_id) {
                                            memory_with_unseen.splice(i, 1);
                                            i--;
                                        }
                                    }
                                }
                            })
                            let sellected_user_memories = this.props.map_memories.filter(memory => memory.user === this.props.user_with_memories[new_index].user)
                            if (memory_with_unseen.length > 0) {
                                const memory_to_show_id = memory_with_unseen[0]
                                const index = sellected_user_memories.findIndex(x => x.id === memory_to_show_id);
                                memory_to_show_index = index
                            }
                        }



                        this.setState({
                            next_user_memory: false,
                            memories_user_id: this.props.user_with_memories[new_index].user,
                            story_index: memory_to_show_index,
                            is_story_paused: false,
                        });

                        setTimeout(() => {
                            this.setState({
                                next_user_memory: true,
                            });
                        }, 100);
                    } else {
                        this.closeModal()
                    }
                    this.closeStoryShowMore()
                }
            }

            if (this.state.start_swipe_y - this.state.swipe_extend_y < -100) {
                if (this.state.storyShowMore) {
                    this.props.clearMemoryTags()
                    this.setState({
                        storyShowMore: false,
                        is_story_paused: false,
                    })
                } else {
                    this.closeModal()
                }
            }
            if (this.state.start_swipe_y - this.state.swipe_extend_y > 100) {
                const sellected_users_memories = this.props.map_memories.filter(memory => memory.user === this.state.memories_user_id)
                const story_index = this.state.story_index
                const memory_tags_id = sellected_users_memories[story_index].id
                this.props.getMemoryTags(memory_tags_id)

                this.setState({
                    storyShowMore: true,
                    is_story_paused: true,
                })
            }

        } else {
            const half_width = window.screen.width / 2
            if (this.state.start_swipe_x < half_width) {
                if (this.state.is_hub_memories) {
                    const new_index = this.state.story_index - 1
                    if (new_index > -1) {
                        this.setState({
                            story_index: new_index
                        })
                    }
                } else {
                    const memory_stories = this.props.map_memories
                    const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
                    const sellected_user_memories = memory_stories.filter(memory => memory.user === this.state.memories_user_id)
                    const new_index = this.state.story_index - 1
                    if (new_index > -1) {
                        this.setState({
                            story_index: new_index
                        })
                    } else {

                        if (this.state.one_person) {
                            // this.closeModal()
                        } else {
                            const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
                            const new_index = index - 1
                            if (new_index > -1) {
                                const last_story_index = memory_stories.filter(memory => memory.user === this.props.user_with_memories[new_index].user).length - 1
                                if (this.props.user_with_memories.length > new_index) {
                                    this.setState({
                                        next_user_memory: false,
                                        memories_user_id: this.props.user_with_memories[new_index].user,
                                        story_index: last_story_index,
                                        is_story_paused: false,
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            next_user_memory: true,
                                        });
                                    }, 100);
                                }
                            } else {
                                // this.closeModal()
                            }
                        }
                    }
                }


            } else {
                if (this.state.is_hub_memories) {
                    const memory_stories = this.props.memories
                    const index = this.state.story_index
                    const new_index = index + 1
                    if (memory_stories.length > new_index) {
                        this.setState({
                            story_index: new_index
                        })
                    } else {
                        this.closeModal()
                    }
                } else {
                    if (!this.state.is_location_memories) {
                        const memory_stories = this.props.map_memories
                        const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
                        const sellected_user_memories = memory_stories.filter(memory => memory.user === this.state.memories_user_id)
                        const new_index = this.state.story_index + 1

                        if (sellected_user_memories.length > new_index) {
                            this.setState({
                                story_index: new_index
                            })
                        } else {
                            if (this.state.one_person && !this.state.is_location_memories) {
                                this.closeModal()
                            } else {
                                const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
                                const new_index = index + 1

                                if (this.props.user_with_memories.length > new_index) {
                                    let memory_to_show_index = 0
                                    if (this.state.all_users_stories) {
                                        let memory_with_unseen = []
                                        const user_unseen_memories = this.props.map_memories.filter(x => x.user === this.props.user_with_memories[new_index].user && x.is_seen === false);
                                        user_unseen_memories.map(memory => {
                                            const memory_id = memory.id
                                            memory_with_unseen.push(memory_id)
                                        })
                                        const state_seen_memories = this.state.seen_memories
                                        memory_with_unseen.map(memory_id => {
                                            if (state_seen_memories.includes(memory_id)) {
                                                for (var i = 0; i < memory_with_unseen.length; i++) {
                                                    if (memory_with_unseen[i] === memory_id) {
                                                        memory_with_unseen.splice(i, 1);
                                                        i--;
                                                    }
                                                }
                                            }
                                        })
                                        let sellected_user_memories = this.props.map_memories.filter(memory => memory.user === this.props.user_with_memories[new_index].user)
                                        if (memory_with_unseen.length > 0) {
                                            const memory_to_show_id = memory_with_unseen[0]
                                            const index = sellected_user_memories.findIndex(x => x.id === memory_to_show_id);
                                            memory_to_show_index = index
                                        }
                                    }


                                    this.setState({
                                        next_user_memory: false,
                                        memories_user_id: this.props.user_with_memories[new_index].user,
                                        story_index: memory_to_show_index,
                                        is_story_paused: false,
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            next_user_memory: true,
                                        });
                                    }, 100);
                                } else {
                                    this.closeModal()
                                }
                            }
                        }
                    } else {
                        const new_index = this.state.story_index + 1
                        const location_memories = this.props.map_memories.filter(memory => memory.lat === this.state.user_memory_lat && memory.lng === this.state.user_memory_lng)
                        const location_memories_length = this.props.map_memories.filter(memory => memory.lat === this.state.user_memory_lat && memory.lng === this.state.user_memory_lng).length
                        if (location_memories_length > new_index) {
                            this.setState({
                                next_user_memory: false,
                                memories_user_id: location_memories[new_index - 1].user,
                                story_index: new_index,
                                is_story_paused: false,
                            });
                            setTimeout(() => {
                                this.setState({
                                    next_user_memory: true,
                                });
                            }, 100);
                        } else {
                            this.closeModal()
                        }

                    }
                }
            }
            this.closeStoryShowMore()
        }

        this.setState({
            start_swipe_x: "",
            start_swipe_y: "",
            swipe_extend_x: "",
            swipe_extend_y: "",
        })
    }

    storyStarted = (e, story_data) => {
        // if (id) {
        this.setState({
            // displayed_memory_id: id.id,
            story_index: e,
            is_story_paused: false,
        })

        // setTimeout(() => {
        if (story_data) {
            const my_user = this.props.profile[0].user
            if (my_user !== story_data.memory_user) {
                const memory = story_data.memory_id
                const view = { memory }
                this.props.createMemoryView(view)
                const seen_memories_list = [...this.state.seen_memories]
                seen_memories_list.push(story_data.memory_id)
                this.setState({ seen_memories: seen_memories_list })
            }
        }
        // }, 100);

        // }
    }

    userStoriesEnded = e => {
        this.setState({
            story_index: 0
        })
        if (this.state.one_person) {
            this.closeModal()
            this.setState({
                streetViewModal: false
            })
        } else {
            const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
            const new_index = index + 1


            if (this.props.user_with_memories.length > new_index) {
                this.setState({
                    next_user_memory: false,
                    memories_user_id: this.props.user_with_memories[new_index].user,
                    is_story_paused: false,
                });

                setTimeout(() => {
                    this.setState({
                        next_user_memory: true,
                    });
                }, 100);
            } else {
                this.closeModal()
                this.setState({
                    streetViewModal: false
                })
            }
        }


    }

    snapMemorySubmit = (e, id) => {
        e.preventDefault();
        this.setState({
            new_memory: e.target.files[0],
            memory_photo: URL.createObjectURL(e.target.files[0]),
            memory_submited: true
        })
    };




    handleSelectPlace(place) {
        let source_id = place.id
        let lat = place.geometry.coordinates[1]
        let lng = place.geometry.coordinates[0]
        let name = place.text
        let full_name = place.place_name
        let address = ""
        let category = ""
        let country_short = ""
        let postal_code = ""
        let place_name_array = full_name.split(", ");
        let country = place_name_array[place_name_array.length - 1]
        let city = ""
        if (place.properties.address) {
            address = place.properties.address
        }
        if (place.properties.category) {
            category = place.properties.category
        }

        if (place_name_array.length === 1) {
            city = ""
        } else if (place_name_array.length === 2) {
            city = place_name_array[0]
        } else if (place_name_array.length === 3) {
            city = place_name_array[1]
        } else {
            city = place_name_array[place_name_array.length - 3] + ", " + place_name_array[place_name_array.length - 2]
        }

        const place_context = place.context
        if (place_context.length > 0) {
            for (const context of place_context) {
                const context_id = context.id
                if (context_id.includes("country")) {
                    country_short = context.short_code
                    country = context.text
                }
                if (context_id.includes("postcode")) {
                    postal_code = context.text
                }
            }
        }

        const prospect = {
            name,
            address,
            city,
            country,
            country_short,
            postal_code,
            lat,
            lng,
            source_id,
            category,
            full_name
        }

        this.props.createHubProspect(prospect)
        // setTimeout(() => {
        //     this.props.getMapPlaces("show_all", this.state.mapCenter.lat, this.state.mapCenter.lng, this.state.zoom)
        // }, 10000);
    };



    getMapOptions = (maps) => {
        // if (this.state.zoom > 19) {
        return {

            streetViewControl: false,
            scaleControl: false,
            fullscreenControl: false,
            // styles: [{
            //     featureType: "road.arterial",
            //     elementType: "geometry",
            //     stylers: [{
            //         visibility: "off",
            //         opacity: "0.1"
            //     }],
            //     featureType: "road.arterial",
            //     elementType: "geometry",
            //     stylers: [{
            //         visibility: "off",
            //         opacity: "0.1"
            //     }],

            //     featureType: "administrative",
            //     elementType: "labels.text.fill",
            //     stylers: [
            //         {
            //             color: "#444444"
            //         }
            //     ],

            //     featureType: "landscape",
            //     elementType: "all",
            //     stylers: [
            //         {
            //             color: "#f2f2f2"
            //         }
            //     ],

            //     featureType: "poi",
            //     elementType: "all",
            //     stylers: [
            //         {
            //             visibility: "off"
            //         }
            //     ],

            //     featureType: "road",
            //     elementType: "all",
            //     stylers: [
            //         {
            //             saturation: -100
            //         },
            //         {
            //             lightness: 45
            //         }
            //     ],

            //     featureType: "road.highway",
            //     elementType: "all",
            //     stylers: [
            //         {
            //             visibility: "simplified"
            //         }
            //     ],

            //     featureType: "road.arterial",
            //     elementType: "labels.icon",
            //     stylers: [
            //         {
            //             visibility: "off"
            //         }
            //     ],

            //     featureType: "transit",
            //     elementType: "all",
            //     stylers: [
            //         {
            //             visibility: "off"
            //         }
            //     ],

            //     featureType: "water",
            //     elementType: "all",
            //     stylers: [
            //         {
            //             color: "#46bcec"
            //         },
            //         {
            //             visibility: "on"
            //         }
            //     ],
            //     hide: [
            //         {
            //             featureType: "poi.business",
            //             stylers: [{ visibility: "off" }],
            //         },
            //         {
            //             featureType: "transit",
            //             elementType: "labels.icon",
            //             stylers: [{ visibility: "off" }],
            //         },
            //     ],
            //     opacity: 0.3
            // }],
            gestureHandling: "greedy",
            disableDoubleClickZoom: true,
            minZoom: 3,
            maxZoom: 21,
            mapTypeControl: false,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.BOTTOM_CENTER,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            },
            zoomControl: false,
            clickableIcons: false
        };
        // } 
        // else {
        //     return {


        //     };
        // }

    }


    updateReactionSelection(selected_reaction, memory_id) {
        this.setState({
            reaction_sellected: true,
            reaction: selected_reaction

        })
        const reacted_memory_id = memory_id
        const reaction_status = selected_reaction
        const reaction = { reaction_status, reacted_memory_id }
        this.props.createMemoryReaction(reaction)
        setTimeout(() => {
            this.props.getMapMemories()
            if (this.state.selected_hub.id) {
                this.props.getHubMemories(this.state.selected_hub.id);
            }
        }, 600);

        setTimeout(() => {
            this.setState({
                reaction_sellected: false,
                show_reaction: true,
            })
        }, 1000);
    }

    deleteMikaImpression(memory_id) {
        this.props.deleteReaction(memory_id)
        setTimeout(() => {
            this.props.getMapMemories()
            if (this.state.selected_hub.id) {
                this.props.getHubMemories(this.state.selected_hub.id);
            }
        }, 600);
    }

    closeStoryShowMore() {
        this.props.clearMemoryTags()
        this.setState({
            storyShowMore: false,
            is_story_paused: false,
        })
    }


    removeMyMemory(id) {
        this.props.deleteMemory(id)
        this.closeModal()
    }

    openHubContactModal(hub) {
        this.setState({
            hubContactModal: true
        })
    }

    submitContactForm(hub_constact_id, closer_name) {
        const hub_name = closer_name
        const name = this.state.hub_contact_name;
        const phone = this.state.hub_contact_telephone;
        const contactForm = { name, phone, hub_constact_id, hub_name }
        if (name === "") {
            this.props.createAlert({ noNameGiven: "You haven't given us a Name! How should we call you?" });
        } else if (phone === "") {
            this.props.createAlert({ noPhoneGiven: "You haven't given us a phone number! How should we come in contact with you?" });
        } else {
            this.props.submitHubContactForm(contactForm);
            this.setState({
                hub_contact_name: '',
                hub_contact_telephone: '',
                submitedHubContact: true,
            });
            setTimeout(() => {
                this.props.getMapPlaces("show_all", this.state.mapCenter.lat, this.state.mapCenter.lng, this.state.zoom)
            }, 3000);
        }
    }

    changeSelectingHubPhoto(hub_id) {
        this.setState({
            changing_cover_hub_id: hub_id
        })
    }

    onCoverPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            photo: URL.createObjectURL(e.target.files[0]),
            cover_photo: e.target.files[0]
        })
        setTimeout(() => {
            this.updateCoverPhoto()
        }, 2000);
    };

    updateCoverPhoto = () => {
        const id = this.state.changing_cover_hub_id
        const cover_photo = this.state.cover_photo
        const hub = { id, cover_photo }

        this.props.userChangeHubCoverPhoto(hub)
        setTimeout(() => {
            this.props.getMapPlaces("show_all", this.state.mapCenter.lat, this.state.mapCenter.lng, this.state.zoom)
        }, 5000);
    }







    render() {
        let ambassandor = ""
        if (this.props.location.state) {
            if (this.props.location.state.ambassandor !== "") {
                ambassandor = this.props.location.state.ambassandor
                return <Redirect to={{ pathname: `/user-profile/${ambassandor}` }} />;
            }
            if (this.props.location.state.hub !== "") {
                ambassandor = this.props.location.state.ambassandor
                return <Redirect to={{ pathname: '/hub', state: { hub_id: this.props.location.state.hub_id } }} />;
            }
        }
        if (this.state.memory_submited) {
            return <Redirect to={{ pathname: '/post-memory', state: { new_memory: this.state.new_memory, memory_photo: this.state.memory_photo, hub: "", lat: "", lng: "", name: "" } }} />
        }

        const marker_width = this.state.marker_width
        const zoom = this.state.zoom


        const initial_state = this.state

        const defaultProps = {
            center: {
                lat: initial_state.mapCenter.lat,
                lng: initial_state.mapCenter.lng
            },
            zoom: 17
        };


        const is_rendered = this.state.is_rendered



        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };


        let map_places = []
        if (this.state.sellected_categories.length === 0) {
            map_places = this.props.map_places
        } else {

            for (const category of this.state.sellected_categories) {
                let filtered_places = []
                if (category === "food") {
                    const food_category = this.props.map_places.filter(place => place.map_icon === "food")
                    const retaurant_category = this.props.map_places.filter(place => place.map_icon === "restaurant")
                    filtered_places = food_category.concat(retaurant_category);
                } else if (category === "bar") {
                    const food_category = this.props.map_places.filter(place => place.map_icon === "bar")
                    const retaurant_category = this.props.map_places.filter(place => place.map_icon === "night club")
                    filtered_places = food_category.concat(retaurant_category);
                } else {
                    filtered_places = this.props.map_places.filter(place => category === place.map_icon)
                }
                map_places.push(...filtered_places)
            }

            // if (this.state.sellected_categories.includes("food")) {
            //     const food_category = this.props.map_places.filter(place => place.map_icon === "food")
            //     const retaurant_category = this.props.map_places.filter(place => place.map_icon === "restaurant")
            //     map_places = food_category.concat(retaurant_category);
            // } else {
            //     map_places = this.props.map_places.filter(place => this.state.sellected_categories.includes(place.map_icon))
            // }

            // if (this.state.sellected_categories.includes("bar")) {
            //     const food_category = this.props.map_places.filter(place => place.map_icon === "bar")
            //     const retaurant_category = this.props.map_places.filter(place => place.map_icon === "night club")
            //     map_places = food_category.concat(retaurant_category);
            // } else {
            //     map_places = this.props.map_places.filter(place => this.state.sellected_categories.includes(place.map_icon))
            // }
        }



        const all_places = map_places.sort(function (a, b) {
            return a.distance - b.distance
        })

        const mapCenterNow = this.state.mapCenter

        let places_distances = []
        all_places.map(place => {
            const R = 3958.8; // Radius of the Earth in miles
            const rlat1 = mapCenterNow.lat * (Math.PI / 180); // Convert degrees to radians
            const rlat2 = place.lat * (Math.PI / 180); // Convert degrees to radians
            const difflat = rlat2 - rlat1; // Radian difference (latitudes)
            const difflon = (place.lng - mapCenterNow.lng) * (Math.PI / 180); // Radian difference (longitudes)
            const miles_distance = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
            const km_distanes = miles_distance * 1.6;
            const place_id = place.id
            const distance_instance = { place_id, km_distanes }
            places_distances.push(distance_instance)
        })



        const sorted_distances = places_distances.sort(function (a, b) {
            return a.km_distanes - b.km_distanes
        })


        let closer_place_array = []
        if (sorted_distances.length > 0) {
            closer_place_array = all_places.filter(function (place) {
                return place.id === sorted_distances[0].place_id
            });
        }

        const closer_place = closer_place_array[0]

        let cover_photo = ""
        let closer_name = ""
        let closer_category = ""
        let closer_cover_props = ""
        let closer_is_open = false
        let closer_lat = ""
        let closer_lng = ""
        let is_open_label = "-"
        let show_places_bubble = false
        let has_sales = false
        let sales_text = ""
        let active_subscription = false
        let is_claimed = false
        let sales_text_gas = []

        if (closer_place) {
            cover_photo = closer_place.cover_photo
            closer_name = closer_place.name
            closer_category = closer_place.category
            closer_is_open = closer_place.is_open_now
            closer_lat = closer_place.lat
            closer_lng = closer_place.lng
            is_open_label = closer_place.is_open_label
            show_places_bubble = this.state.show_places_bubble
            has_sales = closer_place.has_sales
            sales_text = closer_place.sales_text
            active_subscription = closer_place.active_subscription
            is_claimed = closer_place.is_claimed
            closer_cover_props = closer_place.cover_photo_props

            if (closer_place.map_icon === "gas station") {
                if (has_sales) {
                    sales_text_gas = closer_place.sales_text.split(",,,,");
                }
            }
        }

        const screen_width = this.state.marker_width
        const screen_height = window.screen.height



        const initial_categories = [
            "restaurant",
            "coffee shop",
            "bar",
            "night club",
            "food",
            "shopping mall",
            "clothing store",
            "shoe store",
            "electronics store",
            "supermarket",
            "beauty salon",
            "hair care",
            "cinema",
            "lodging",
            "minifootball",
            "spa",
            "doctor",
            "dentist",
            "lawyer",
            "sports",
            "bowling alley",
            "art gallery",
            "museum",
            "aquarium",
            "amusement park",
            "zoo",
            "campground",
            "library",
            "rv park",
            "university",
            "jewelry store",
            "bakery",
            "liquor store",
            "pet store",
            "convenience store",
            "gas station",
            "home goods store",
            "hardware store",
            "pharmacy",
            "pharmacy overnight",
            "florist",
            "furniture store",
            "bicycle store",
            "book store",
            "car dealer",
            "electrician",
            "accounting",
            "bank",
            "gym",
            "veterinary care",
            "insurance agency",
            "travel agency",
            "parking",
            "painter",
            "car rental",
            "physiotherapist",
            "real estate agency",
            "plumber",
            "car repair",
            "car wash",
            "laundry",
            "locksmith",
            'casino',
            "meal takeaway"
        ]

        let categories = ""


        if (this.state.category_search === "") {
            categories = initial_categories
        } else {
            categories = initial_categories.filter(category => category.includes(this.state.category_search))
        }


        const categorySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const sellectedCategorySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const sellectedSalesSettings = {
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 500,
            cssEase: "linear"
        };

        const memoryTagsSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const storySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        let stories = []
        let rendred = false
        const memory_stories = this.props.map_memories
        const index = this.props.user_with_memories.findIndex(x => x.user === this.state.memories_user_id);
        let sellected_user_memories = memory_stories.filter(memory => memory.user === this.state.memories_user_id)
        let memories_displayed = []

        let my_memories = []
        if (this.props.profile.length > 0) {
            my_memories = memory_stories.filter(memory => memory.user === this.props.profile[0].user)
        }

        if (this.state.is_location_memories) {
            sellected_user_memories = memory_stories.filter(memory => memory.lat === this.state.user_memory_lat && memory.lng === this.state.user_memory_lng)
        }

        if (this.state.is_hub_memories) {
            sellected_user_memories = this.props.memories
        }

        if (sellected_user_memories.length > 0 && this.state.next_user_memory) {
            const config = {
                delta: 10,
            }
            rendred = true
            sellected_user_memories.map(memory => {

                memories_displayed.push(memory.id)
                let time = moment(memory.timestamp).fromNow()
                const is_story_paused = this.state.is_story_paused
                const story =
                {
                    duration: 10000,
                    memory_user: memory.user,
                    memory_id: memory.id,

                    content: ({ action, isPaused }) => {

                        const pauseStory = () => { action("pause") }

                        return (
                            <>
                                {is_story_paused ?
                                    <span onClick={pauseStory()}></span>
                                    :
                                    null
                                }
                                <div style={{ background: 'pink' }}>
                                    <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                        <div class="map-search-story-profile-pic-box">
                                            {memory.user_profile_picture ?
                                                <img
                                                    class="map-search-story-profile-pic"
                                                    src={memory.user_profile_picture}
                                                    alt=""
                                                />
                                                :
                                                <img
                                                    class="map-search-story-profile-pic"
                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                    alt=""
                                                />
                                            }


                                        </div>
                                    </Link>
                                    <div class="map-search-story-top-text">
                                        <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                            <div class="map-search-story-top-heading">
                                                <b>
                                                    {memory.user_username}
                                                </b>
                                                {memory.has_tags ?
                                                    <span class="map-search-story-top-heading-tags">
                                                        {memory.tags.length === 2 ?
                                                            <>
                                                                &nbsp;with&nbsp; <b> {memory.tagged_user_username} </b>
                                                            </>
                                                            :
                                                            <>
                                                                {memory.tagged_user_username.length > 14 && memory.user_username.length > 14 ?

                                                                    <span
                                                                        class="map-search-story-top-heading-tags"
                                                                    // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                    >
                                                                        &nbsp;and&nbsp;
                                                                        <b>{memory.tags.length - 1}&nbsp;others</b>
                                                                    </span>

                                                                    :
                                                                    <span class="map-search-story-top-heading-tags">
                                                                        &nbsp;with&nbsp;<b>
                                                                            {memory.tagged_user_username}
                                                                        </b>
                                                                        {/* <br /> */}
                                                                        &nbsp;and&nbsp;
                                                                        <span
                                                                            class="map-search-story-top-heading-tags"
                                                                        // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                        >
                                                                            <b>{memory.tags.length - 2}&nbsp;others</b>
                                                                        </span>
                                                                    </span>
                                                                }
                                                            </>
                                                        }

                                                    </span>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </Link>
                                        <div class="map-search-story-top-subheading1">
                                            {memory.hub_name}
                                        </div>
                                        <div class="map-search-story-top-subheading">
                                            {moment(memory.timestamp).fromNow()}
                                        </div>
                                    </div>


                                    <div class="map-search-story-photo">
                                        <img class="map-search-story-photo-size" src={memory.photo} />
                                    </div>

                                    {this.state.reaction_sellected ?
                                        <>
                                            {this.state.reaction === "love" ?
                                                <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                    <img
                                                        class="memory-mika-animation-size"
                                                        // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                        src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </div>

                            </>
                        );
                    },
                    url: memory.photo,
                }
                stories.push(story)
            })
        }

        const profile = this.props.profile
        let has_profile_pic = true
        if (profile[0]) {
            if (profile[0].profile_picture === "") {
                has_profile_pic = false
            }
            if (profile[0].profile_picture === null) {
                has_profile_pic = false
            }
        }

        let has_new_message = false
        let new_message_number = 0
        if (this.props.notifications_status[0]) {
            has_new_message = this.props.notifications_status[0].new_message
            new_message_number = this.props.notifications_status[0].new_message_number
        }

        let set_map_layout = 'roadmap'
        if (this.state.zoom > 17) {
            set_map_layout = 'satellite'
        }


        let map_places_log = []
        all_places.map(place => {
            const place_name = place.name
            const place_lat = place.lat
            const place_lng = place.lng
            const place_log = { place_name, place_lat, place_lng }
            map_places_log.push(place_log)
        })

        let seen_memories = []
        let seen_memories_list = []
        let state_seen_memories = this.state.seen_memories
        if (this.props.map_memories.length > 0) {
            seen_memories = memory_stories.filter(memory => memory.is_seen === true)
            seen_memories.map(mmry => {
                const memory_id = mmry.id
                seen_memories_list.push(memory_id)
            })
        }

        const all_seen_memories = seen_memories_list.concat(state_seen_memories);

        let users_with_unseen = []
        let all_unseen_memories_ids = []
        let user_with_memories_list = []
        let user_without_memories_list = []

        let final_unseen_memories = []
        let final_users_unseen = []
        let all_unseen_memories = memory_stories.filter(memory => memory.is_seen === false)
        if (this.props.user_with_memories.length > 0 && this.props.profile.length > 0) {
            user_with_memories_list = this.props.user_with_memories.filter(user => user.unseen_memories === true)
            user_without_memories_list = this.props.user_with_memories.filter(user => user.unseen_memories === false)

            user_with_memories_list.map(user => {
                const user_id = user.id
                users_with_unseen.push(user_id)
            })

            all_unseen_memories.map(memory => {
                all_unseen_memories_ids.push(memory.id)
            })

            final_unseen_memories = all_unseen_memories_ids.filter(function (el) {
                return state_seen_memories.indexOf(el) < 0;
            });
            final_unseen_memories.map(memory_id => {
                const the_unseen_memory = memory_stories.filter(memory => memory.id === memory_id)
                if (the_unseen_memory[0].user !== this.props.profile[0].user) {
                    final_users_unseen.push(the_unseen_memory[0].user)
                }
            })
        }


        return (
            <>
                {has_profile_pic ?
                    null
                    :
                    <Modal visible={this.state.profileDataModalVisible} width="100" height="100" effect="fadeInUp">
                        <InsertProfileDataModal
                            data={{
                                profile: this.props.profile,
                                closeModal: this.closeModal.bind(this)
                            }}
                            updateProfilePicture={this.props.updateProfilePicture}
                            updateProfileFullName={this.props.updateProfileFullName}
                            getMyProfile={this.props.getMyProfile}
                        />
                    </Modal>
                }
                {is_rendered ?
                    <>

                        {this.state.showstories ?
                            <div class="map-search-story-all-black">

                            </div>
                            :
                            null
                        }

                        {rendred ?
                            <>
                                {this.state.showstories ?

                                    <Modal visible={this.state.showstories} width="100%" height="100%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                        <>
                                            <div class="map-search-story-index">
                                                <div onClick={() => this.closeModal()} class="map-search-story-times-box">
                                                    <img
                                                        onClick={() => this.closeModal()}
                                                        class="map-search-story-times-img"
                                                        src={require("../../../assets/map/svg/story-times.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                                    onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                                    onTouchEnd={() => this.onTouchEnd()}
                                                    class="map-search-memories-full-box"
                                                >


                                                    <Stories
                                                        // preventDefault={true}
                                                        currentIndex={this.state.story_index}
                                                        stories={stories}
                                                        defaultInterval={1500}
                                                        width={screen_width}
                                                        height={screen_height}
                                                        isPaused={true}
                                                        onStoryStart={(e, id) => this.storyStarted(e, id)}
                                                        onStoryEnd={e => this.storyStarted(e)}
                                                        onAllStoriesEnd={e => this.userStoriesEnded(e)}
                                                    />

                                                </div>

                                                <div
                                                    onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                                    onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                                    onTouchEnd={() => this.onTouchEnd()}
                                                    class="all-page-touches-invisible-box">

                                                </div>



                                                {this.state.storyShowMore ?
                                                    <>
                                                        {sellected_user_memories.length > 0 ?
                                                            <div class="map-search-show-more-modal" >

                                                                {sellected_user_memories[this.state.story_index].has_tags ?
                                                                    <div class="map-search-show-more-tags-box">
                                                                        <Slider {...memoryTagsSettings} >
                                                                            {this.props.tags.map(tag => (
                                                                                // <div class="map-search-show-more-tag-buddy-box">
                                                                                <Link class="map-search-show-more-tag-buddy-box" to={{ pathname: `/user-profile/${tag.user}/profile`, state: { user: tag.user } }}>
                                                                                    <div class="map-search-show-more-tag-profile-box">
                                                                                        {tag.profile_picture ?
                                                                                            < img
                                                                                                class="map-search-show-more-tag-profile-img"
                                                                                                src={tag.profile_picture}
                                                                                            />
                                                                                            :
                                                                                            <img
                                                                                                class="map-search-show-more-tag-profile-img"
                                                                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                    <div class="map-search-show-more-tag-username">
                                                                                        {tag.username}
                                                                                    </div>
                                                                                </Link>
                                                                            ))}
                                                                        </Slider>
                                                                    </div>
                                                                    :
                                                                    <>

                                                                        {sellected_user_memories[this.state.story_index].user !== profile[0].user ?
                                                                            <>
                                                                                {sellected_user_memories[this.state.story_index].user_profile_picture ?
                                                                                    <div class="map-search-show-more-single-box">
                                                                                        <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                                                                            <div class="row map-search-show-more-single-row">
                                                                                                <div class="map-search-show-more-image">
                                                                                                    < img
                                                                                                        class="map-search-show-more-img"
                                                                                                        src={sellected_user_memories[this.state.story_index].user_profile_picture}
                                                                                                    />
                                                                                                </div>

                                                                                                {sellected_user_memories[this.state.story_index].user_username}`s Profile

                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    :
                                                                                    <div class="map-search-show-more-single-box">
                                                                                        <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                                                                            {sellected_user_memories[this.state.story_index].user_username}`s Profile
                                                                                        </Link>
                                                                                    </div>

                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                }

                                                                {sellected_user_memories[this.state.story_index].hub_logo ?
                                                                    <div class="map-search-show-more-single-box">
                                                                        <Link to={{ pathname: '/hub', state: { hub_id: sellected_user_memories[this.state.story_index].hub_id } }}>
                                                                            <div class="row map-search-show-more-single-row">
                                                                                <div class="map-search-show-more-image">
                                                                                    < img
                                                                                        class="map-search-show-more-img"
                                                                                        src={sellected_user_memories[this.state.story_index].hub_logo}
                                                                                    />
                                                                                </div>
                                                                                {sellected_user_memories[this.state.story_index].hub_name.length > 18 ?
                                                                                    <>
                                                                                        {sellected_user_memories[this.state.story_index].hub_name.slice(0, 17) + "..."}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {sellected_user_memories[this.state.story_index].hub_name}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    <div class="map-search-show-more-single-box">
                                                                        <Link to={{ pathname: '/hub', state: { hub_id: sellected_user_memories[this.state.story_index].hub_id } }}>
                                                                            {sellected_user_memories[this.state.story_index].hub_name.length > 25 ?
                                                                                <>
                                                                                    {sellected_user_memories[this.state.story_index].hub_name.slice(0, 24) + "..."}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {sellected_user_memories[this.state.story_index].hub_name}
                                                                                </>
                                                                            }
                                                                        </Link>
                                                                    </div>
                                                                }
                                                                {/* <div class="map-search-show-more-single-box">
                                                            {sellected_user_memories[this.state.story_index].user_username}`s Profile
                                                        </div> */}



                                                                {/* <div class="map-search-show-more-single-box">
                                                                    Get me there
                                                                </div> */}
                                                                <Link to={{ pathname: '/memory-impressions', state: { memory: sellected_user_memories[this.state.story_index].id } }}>
                                                                    <div class="map-search-show-more-single-box">
                                                                        {sellected_user_memories[this.state.story_index].reactions_number} reactions
                                                                    </div>
                                                                </Link>
                                                                {sellected_user_memories[this.state.story_index].user !== profile[0].user ?

                                                                    <Link to={{
                                                                        pathname: "/conversation-room",
                                                                        state: {
                                                                            selected_user: sellected_user_memories[this.state.story_index].user,
                                                                            selected_user_profile_pic: sellected_user_memories[this.state.story_index].user_profile_picture,
                                                                            selected_user_username: sellected_user_memories[this.state.story_index].user_username
                                                                        }
                                                                    }}
                                                                    >
                                                                        <div class="map-search-show-more-single-box">
                                                                            {/* <div class="map-search-show-more-single-box"> */}
                                                                            Send Message
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    null
                                                                }

                                                                {sellected_user_memories[this.state.story_index].user === profile[0].user ?
                                                                    <div onClick={() => this.removeMyMemory(sellected_user_memories[this.state.story_index].id)} class="map-search-show-more-single-box">
                                                                        Remove Memory
                                                                    </div>
                                                                    :
                                                                    null
                                                                }


                                                                <div onClick={() => this.closeStoryShowMore()} class="map-search-show-more-x-box">
                                                                    x
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                    :
                                                    <div onClick={() => this.openStoryShowMore(sellected_user_memories[this.state.story_index].id)}
                                                        class="map-search-story-swipe-up">
                                                        ⌃<br />
                                                        Swipe for More
                                                    </div>
                                                }


                                                {sellected_user_memories[this.state.story_index] ?
                                                    <div class="map-search-story-bottom-row">
                                                        {sellected_user_memories[this.state.story_index].memory_reaction !== null ?
                                                            <div onClick={() => this.deleteMikaImpression(sellected_user_memories[this.state.story_index].id)} class="map-search-story-reactions-xo">
                                                                < img
                                                                    class="map-search-story-reactions-img"
                                                                    src={require("../../../assets/layout/homepage svg/love-red.svg")}
                                                                />
                                                            </div>
                                                            :
                                                            <div onClick={() => this.updateReactionSelection("love", sellected_user_memories[this.state.story_index].id)} class="map-search-story-reactions-xo">
                                                                < img
                                                                    class="map-search-story-reactions-img"
                                                                    src={require("../../../assets/layout/homepage svg/white-love.svg")}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </>

                                    </Modal>

                                    :
                                    null
                                }
                            </>
                            :
                            <></>
                        }


                        {this.state.hubContactModal ?
                            <Modal visible={this.state.hubContactModal} width="100%" height="60%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <PlaceClaimModal
                                    data={{
                                        closer_name: closer_name,
                                        closer_place_id: closer_place.id,
                                        closer_place_map_icon: closer_place.map_icon,
                                        hub_contact_name: this.state.hub_contact_name,
                                        hub_contact_telephone: this.state.hub_contact_telephone,
                                        submitedHubContact: this.state.submitedHubContact,
                                        submitContactForm: this.submitContactForm.bind(this),
                                        onChange: this.onChange.bind(this),
                                        closeModal: this.closeModal.bind(this),
                                    }}
                                />
                            </Modal>
                            :
                            null
                        }


                        {this.state.hubModal ?
                            <Modal visible={this.state.hubModal} width="100%" height="70%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <PlaceMarkerModal
                                    data={{
                                        memories: this.props.memories,
                                        hub: this.state.selected_hub,
                                        closeModal: this.closeModal.bind(this),
                                        openStoriesModal: this.openStoriesModal.bind(this),
                                    }}
                                    requestAppointment={this.props.requestAppointment}
                                />
                            </Modal>
                            :
                            null
                        }


                        {this.state.categoryFilterModal ?
                            <Modal visible={this.state.categoryFilterModal} width="100%" height="65%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <div style={{
                                    width: screen_width,
                                    // height: '450px',
                                    // backgroundColor: '#eeeeee'
                                }}>
                                    <div class="map-search-category-title">
                                        Select a category of places
                                    </div>


                                    <div onClick={() => this.closeModal()} class="map-search-acces-modal-times-box">
                                        <img
                                            class="map-search-acces-modal-times-img"
                                            src={require("../../../assets/layout/homepage svg/times.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <input
                                        class='map-search-category-search-input'
                                        type="search"
                                        name="category_search"
                                        onChange={this.onCategoryChange}
                                        placeholder="Search Category..."
                                        value={this.state.category_search}
                                        aria-label="Search"
                                    />

                                    <div class="map-search-category-all-sellected">
                                        <Slider {...sellectedCategorySettings} >
                                            {/* <div class="map-search-category-box"> */}
                                            {this.state.sellected_categories.reverse().map(filter_category => (
                                                <div onClick={() => this.selectCategory(filter_category)} class="map-search-category-sellected">
                                                    {filter_category}&nbsp;&nbsp;x
                                                </div>
                                            ))}
                                            {/* </div> */}
                                        </Slider>
                                    </div>


                                    <div class="map-search-category-slider">
                                        <Slider {...categorySettings} >

                                            {categories.map(category => (
                                                <>
                                                    {this.state.sellected_categories.includes(category) ?
                                                        <div
                                                            // style={{ width: "10px", height: width + "10px", marginTop: "10px" }}
                                                            class="map-search-category-frame"
                                                            onClick={() => this.selectCategory(category)}
                                                        >
                                                            <>
                                                                {category === "restaurant" ?
                                                                    <img
                                                                        class="place-marker-icon-img"
                                                                        src={require("../../../assets/map/svg/restaurant-blue.svg")}
                                                                        alt=""
                                                                    />
                                                                    : category === "coffee shop" ?
                                                                        <img
                                                                            class="place-marker-icon-img"
                                                                            src={require("../../../assets/map/svg/cafe-blue.svg")}
                                                                            alt=""
                                                                        />
                                                                        : category === "bar" ?
                                                                            <img
                                                                                class="place-marker-icon-img"
                                                                                src={require("../../../assets/map/svg/bar-blue.svg")}
                                                                                alt=""
                                                                            />
                                                                            : category === "night club" ?
                                                                                <img
                                                                                    class="place-marker-icon-img"
                                                                                    src={require("../../../assets/map/svg/club-blue.svg")}
                                                                                    alt=""
                                                                                />
                                                                                : category === "food" ?
                                                                                    <img
                                                                                        class="place-marker-icon-img"
                                                                                        src={require("../../../assets/map/svg/food-blue.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    : category === "casino" ?
                                                                                        <img
                                                                                            class="place-marker-icon-img"
                                                                                            src={require("../../../assets/map/svg/casino-blue.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                        : category === "meal takeaway" ?
                                                                                            <img
                                                                                                class="place-marker-icon-img"
                                                                                                src={require("../../../assets/map/svg/takeaway-blue.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                            : category === "clothing store" ?
                                                                                                <img
                                                                                                    class="place-marker-icon-img"
                                                                                                    src={require("../../../assets/map/svg/clothestore-blue.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                                : category === "electronics store" ?
                                                                                                    <img
                                                                                                        class="place-marker-icon-img"
                                                                                                        src={require("../../../assets/map/svg/electronicsstore-blue.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    : category === "shoe store" ?
                                                                                                        <img
                                                                                                            class="place-marker-icon-img"
                                                                                                            src={require("../../../assets/map/svg/shoestore-blue.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        : category === "supermarket" ?

                                                                                                            <img
                                                                                                                class="place-marker-icon-img"
                                                                                                                src={require("../../../assets/map/svg/supermarket-blue.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                            : category === "jewelry store" ?
                                                                                                                <img
                                                                                                                    class="place-marker-icon-img"
                                                                                                                    src={require("../../../assets/map/svg/jewelry-blue.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                : category === "liquor store" ?
                                                                                                                    <img
                                                                                                                        class="place-marker-icon-img"
                                                                                                                        src={require("../../../assets/map/svg/liquorstore-blue.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "pet store" ?
                                                                                                                        <img
                                                                                                                            class="place-marker-icon-img"
                                                                                                                            src={require("../../../assets/map/svg/petstore-blue.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "shopping mall" ?
                                                                                                                            <img
                                                                                                                                class="place-marker-icon-img"
                                                                                                                                src={require("../../../assets/map/svg/mall-blue.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "convenience store" ?
                                                                                                                                <img
                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/conviniencestore-blue.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "gas station" ?
                                                                                                                                    <img
                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/gasstation-blue.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "home goods store" ?

                                                                                                                                        <img
                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/homegoodsstore-blue.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "hardware store" ?
                                                                                                                                            <img
                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/hardwarestore-blue.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "pharmacy" ?
                                                                                                                                                <img
                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/pharmacy-blue.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "pharmacy overnight" ?
                                                                                                                                                    <img
                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/pharmacy-blue.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "florist" ?
                                                                                                                                                        <img
                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/florist-blue.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "furniture store" ?
                                                                                                                                                            <img
                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/furniturestore-blue.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "bicycle store" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/bicyclestore-blue.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "book store" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/bookshop-blue.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "car dealer" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/cardealer-blue.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "bakery" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/bakery-blue.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "beauty salon" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/beautysalon-blue.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "hair care" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/hairsalon-blue.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "spa" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/spa-blue.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "doctor" ?
                                                                                                                                                                                            <img
                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/doctor-blue.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "dentist" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/dentist-blue.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "lawyer" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/lawyer-blue.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "electrician" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/electrician-blue.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "accounting" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/accountant-blue.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "laundry" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/laundry-blue.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "bank" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/bank-blue.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "gym" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/gym-blue.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "veterinary care" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/veterany-blue.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "insurance agency" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/insurance-blue.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "travel agency" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/travel-blue.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "parking" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/parking-blue.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "painter" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/painter-blue.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "car rental" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/rentcar-blue.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "physiotherapist" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/physiotherapist-blue.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "real estate agency" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/realestateagent-blue.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "plumber" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/plumber-blue.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "car repair" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carrepair-blue.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "car wash" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/carwash-blue.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />

                                                                                                                                                                                                                                                                    : category === "locksmith" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/locksmith-blue.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "cinema" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/cinema-blue.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "lodging" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lodging-blue.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : category === "minifootball" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/minifootball-blue.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "sports" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/sports-blue.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/bowling-blue.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/artgallery-blue.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "museum" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/museum-blue.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/aquarium-blue.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/amusementpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/zoo-blue.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                : category === "campground" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/campground-blue.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "library" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/library-blue.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rvpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "university" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/university-blue.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                                                                :
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/place-blue.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                }


                                                            </>
                                                        </div>
                                                        :
                                                        <div
                                                            // style={{ width: "10px", height: width + "10px", marginTop: "10px" }}
                                                            class="map-search-category-frame"
                                                            onClick={() => this.selectCategory(category)}
                                                        >
                                                            <>
                                                                {category === "restaurant" ?
                                                                    <img
                                                                        class="place-marker-icon-img"
                                                                        src={require("../../../assets/map/svg/restaurant-red.svg")}
                                                                        alt=""
                                                                    />
                                                                    : category === "coffee shop" ?
                                                                        <img
                                                                            class="place-marker-icon-img"
                                                                            src={require("../../../assets/map/svg/cafe-red.svg")}
                                                                            alt=""
                                                                        />
                                                                        : category === "bar" ?
                                                                            <img
                                                                                class="place-marker-icon-img"
                                                                                src={require("../../../assets/map/svg/bar-red.svg")}
                                                                                alt=""
                                                                            />
                                                                            : category === "night club" ?
                                                                                <img
                                                                                    class="place-marker-icon-img"
                                                                                    src={require("../../../assets/map/svg/club-red.svg")}
                                                                                    alt=""
                                                                                />
                                                                                : category === "food" ?
                                                                                    <img
                                                                                        class="place-marker-icon-img"
                                                                                        src={require("../../../assets/map/svg/food-red.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    : category === "casino" ?
                                                                                        <img
                                                                                            class="place-marker-icon-img"
                                                                                            src={require("../../../assets/map/svg/casino-red.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                        : category === "meal takeaway" ?
                                                                                            <img
                                                                                                class="place-marker-icon-img"
                                                                                                src={require("../../../assets/map/svg/takeaway-red.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                            : category === "clothing store" ?
                                                                                                <img
                                                                                                    class="place-marker-icon-img"
                                                                                                    src={require("../../../assets/map/svg/clothestore-red.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                                : category === "electronics store" ?
                                                                                                    <img
                                                                                                        class="place-marker-icon-img"
                                                                                                        src={require("../../../assets/map/svg/electronicsstore-red.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    : category === "shoe store" ?
                                                                                                        <img
                                                                                                            class="place-marker-icon-img"
                                                                                                            src={require("../../../assets/map/svg/shoestore-red.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        : category === "supermarket" ?

                                                                                                            <img
                                                                                                                class="place-marker-icon-img"
                                                                                                                src={require("../../../assets/map/svg/supermarket-red.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                            : category === "jewelry store" ?
                                                                                                                <img
                                                                                                                    class="place-marker-icon-img"
                                                                                                                    src={require("../../../assets/map/svg/jewelry-red.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                : category === "liquor store" ?
                                                                                                                    <img
                                                                                                                        class="place-marker-icon-img"
                                                                                                                        src={require("../../../assets/map/svg/liquorstore-red.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "pet store" ?
                                                                                                                        <img
                                                                                                                            class="place-marker-icon-img"
                                                                                                                            src={require("../../../assets/map/svg/petstore-red.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "shopping mall" ?
                                                                                                                            <img
                                                                                                                                class="place-marker-icon-img"
                                                                                                                                src={require("../../../assets/map/svg/mall-red.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "convenience store" ?
                                                                                                                                <img
                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/conviniencestore-red.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "gas station" ?
                                                                                                                                    <img
                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/gasstation-red.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "home goods store" ?

                                                                                                                                        <img
                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/homegoodsstore-red.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "hardware store" ?
                                                                                                                                            <img
                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/hardwarestore-red.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "pharmacy" ?
                                                                                                                                                <img
                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/pharmacy-red.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "pharmacy overnight" ?
                                                                                                                                                    <img
                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/pharmacy-overnight-red.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "florist" ?
                                                                                                                                                        <img
                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/florist-red.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "furniture store" ?
                                                                                                                                                            <img
                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/furniturestore-red.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "bicycle store" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/bicyclestore-red.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "book store" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/bookshop-red.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "car dealer" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/cardealer-red.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "bakery" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/bakery-red.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "beauty salon" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/beautysalon-red.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "hair care" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/hairsalon-red.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "spa" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/spa-red.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "doctor" ?
                                                                                                                                                                                            <img
                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/doctor-red.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "dentist" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/dentist-red.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "lawyer" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/lawyer-red.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "electrician" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/electrician-red.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "accounting" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/accountant-red.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "laundry" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/laundry-red.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "bank" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/bank-red.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "gym" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/gym-red.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "veterinary care" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/veterany-red.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "insurance agency" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/insurance-red.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "travel agency" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/travel-red.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "parking" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/parking-red.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "painter" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/painter-red.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "car rental" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/rentcar-red.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "physiotherapist" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/physiotherapist-red.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "real estate agency" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/realestateagent-red.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "plumber" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/plumber-red.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "car repair" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carrepair-red.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "car wash" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/carwash-red.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />

                                                                                                                                                                                                                                                                    : category === "locksmith" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/locksmith-red.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "cinema" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/cinema-red.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "lodging" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lodging-red.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : category === "minifootball" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/minifootball-red.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "sports" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/sports-red.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/bowling-red.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/artgallery-red.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "museum" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/museum-red.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/aquarium-red.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/amusementpark-red.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/zoo-red.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                : category === "campground" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/campground-red.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "library" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/library-red.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rvpark-red.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "university" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/university-red.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                                                                :
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="place-marker-icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/place-red.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                }


                                                            </>
                                                        </div>
                                                    }
                                                    <div class="map-search-category-name">
                                                        {category}
                                                    </div>
                                                </>
                                            ))}
                                        </Slider>
                                    </div>

                                    <div
                                        onClick={() => this.clearCategories()}

                                        class="map-search-category-sho-all"
                                    >
                                        Show All
                                    </div>
                                </div>
                            </Modal>
                            :
                            null
                        }



                        <div class="map-search-upload-image-box">
                            <img
                                class="map-search-upload-image-img"
                                onClick={() => this.memoryInput.click()}
                                src={require("../../../assets/layout/homepage svg/snap-photo-black.svg")}
                                alt=""
                            />
                            <input
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg, video/*"
                                type="file"
                                name="memory_photo"
                                onChange={
                                    this.snapMemorySubmit
                                }
                                ref={memoryInput => this.memoryInput = memoryInput}
                            />
                        </div>


                        <div
                            onClick={() => this.openCategoryFilterModal()}
                            class="map-search-top-filter">
                            {this.state.sellected_categories.length === 0 ?
                                <img
                                    class="map-search-top-filter-icon"
                                    src={require("../../../assets/layout/homepage svg/Path 541.svg")}
                                    alt=""
                                />
                                :
                                <img
                                    class="map-search-top-filter-icon"
                                    src={require("../../../assets/map/svg/filter-full.svg")}
                                    alt=""
                                />
                            }
                        </div>


                        <Link to="/chat-list">
                            <div class="map-search-uchat-image-box">
                                <img
                                    class="map-search-upload-image-img"
                                    src={require("../../../assets/layout/homepage svg/chat-black.svg")}
                                    alt=""
                                />
                                {has_new_message ?
                                    <div class="homepage-new-message-bbubble2">
                                        {new_message_number}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </Link>


                        {this.state.currentlySearching ?
                            null
                            :
                            <>
                                {!this.state.showstories ?
                                    <div class="row map-search-all-story-section">
                                        <div class="map-search-story-slider">
                                            <Slider {...storySettings} >
                                                {this.props.profile.map(profile => (
                                                    <>
                                                        {my_memories.length > 0 ?
                                                            <div onClick={() => this.openStoriesModal(profile.user, true, false, "", "", false, false)} class="map-search-my-story-box">

                                                                <div class="map-search-my-story-profile">
                                                                    {profile.profile_picture ?
                                                                        <img
                                                                            class="map-search-my-story-profile-img"
                                                                            src={profile.profile_picture}
                                                                            alt=""
                                                                        />
                                                                        :
                                                                        <img
                                                                            class="map-search-my-story-profile-img"
                                                                            src={require("../../../assets/authentication/svg/account.svg")}
                                                                            alt=""
                                                                        />
                                                                    }
                                                                    {this.props.uploading_memory ?
                                                                        <div class="map-search-my-story-spinner" >
                                                                            <div class="map-search-my-story-head"></div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div class="map-search-story-username-text">
                                                                    My Memories
                                                                </div>
                                                            </div>
                                                            :
                                                            <div onClick={() => this.fileInput.click()} class="map-search-my-story-box">
                                                                <div class="map-search-my-story-profile">
                                                                    {profile.profile_picture ?
                                                                        <img
                                                                            class="map-search-my-story-profile-img"
                                                                            src={profile.profile_picture}
                                                                            alt=""
                                                                        />
                                                                        :
                                                                        <img
                                                                            class="map-search-my-story-profile-img"
                                                                            src={require("../../../assets/authentication/svg/account.svg")}
                                                                            alt=""
                                                                        />
                                                                    }

                                                                    {this.props.uploading_memory ?
                                                                        <div class="map-search-my-story-spinner" >
                                                                            <div class="map-search-my-story-head"></div>
                                                                        </div>
                                                                        :
                                                                        <img
                                                                            class="map-search-my-story-profile-plus-img"
                                                                            src={require("../../../assets/map/svg/plus.svg")}
                                                                            alt=""
                                                                        />
                                                                    }
                                                                </div>

                                                                <div class="map-search-story-username-text">
                                                                    My Memories
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                ))}
                                                {user_with_memories_list.map(user => (
                                                    <div onClick={() => this.openStoriesModal(user.user, false, false, "", "", false, true)} class="map-search-my-story-box">
                                                        <div class={final_users_unseen.includes(user.user) ? "map-search-my-story-profile-unseen" : "map-search-my-story-profile"}>
                                                            {user.profile_picture ?
                                                                <img
                                                                    class="map-search-my-story-profile-img"
                                                                    src={user.profile_picture}
                                                                    alt=""
                                                                />
                                                                :
                                                                <img
                                                                    class="map-search-my-story-profile-img"
                                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="map-search-story-username-text">
                                                            {user.username.length > 12 ?
                                                                <>
                                                                    {user.username.slice(0, 10) + "..."}
                                                                </>
                                                                :
                                                                <>
                                                                    {user.username}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                                {user_without_memories_list.map(user => (
                                                    <div onClick={() => this.openStoriesModal(user.user, false, false, "", "", false, true)} class="map-search-my-story-box">
                                                        <div class={final_users_unseen.includes(user.user) ? "map-search-my-story-profile-unseen" : "map-search-my-story-profile"}>
                                                            {user.profile_picture ?
                                                                <img
                                                                    class="map-search-my-story-profile-img"
                                                                    src={user.profile_picture}
                                                                    alt=""
                                                                />
                                                                :
                                                                <img
                                                                    class="map-search-my-story-profile-img"
                                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="map-search-story-username-text">
                                                            {user.username.length > 12 ?
                                                                <>
                                                                    {user.username.slice(0, 10) + "..."}
                                                                </>
                                                                :
                                                                <>
                                                                    {user.username}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}

                                            </Slider>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </>
                        }

                        <div className="map-search-center-marker-box" >
                            <img
                                class="map-search-center-marker-icon"
                                src={require("../../../assets/map/svg/center.svg")}
                                alt=""
                            />
                        </div>

                        {this.props.map_places.length > 0 ?
                            <div className="map-box-search-map-map">
                                <HomepageMap
                                    data={{
                                        hubs: this.props.map_places,
                                        new_hub_created: this.props.new_hub_created,
                                        new_prospect_id: this.props.new_prospect_id,
                                        new_created_hub: this.props.new_created_hub,
                                        memories: this.props.map_memories,
                                        mapCenter: this.state.mapCenter,
                                        myMapCenterLocation: this.state.myMapCenterLocation,
                                        handleCenterChanged: this.handleCenterChanged.bind(this),
                                        openPlaceModal: this.openModal.bind(this),
                                        openStoriesModal: this.openStoriesModal.bind(this),
                                        handleSelectPlace: this.handleSelectPlace.bind(this),
                                        changeIsCategoryChanged: this.changeIsCategoryChanged.bind(this),
                                        final_users_unseen: final_users_unseen,
                                        final_unseen_memories: final_unseen_memories,
                                        my_profile: this.props.profile,
                                        closer_place: closer_place,
                                        is_category_changed: this.state.is_category_changed,
                                        sellected_categories: this.state.sellected_categories,
                                    }}
                                    getMapPlaces={this.props.getMapPlaces}
                                    getNewCreatedHub={this.props.getNewCreatedHub}
                                    createdHubMarker={this.props.createdHubMarker}
                                />
                            </div>
                            :
                            null
                        }


                        {!this.state.show_places_bubble ?
                            <>
                                <div
                                    onClick={() => this.changeShowPlaceBubble()}
                                    class="map-search-new-down-arrow-vox">
                                    <img
                                        class="map-search-new-down-arrow-icn"
                                        src={require("../../../assets/map/svg/up-arrow.svg")}
                                        alt=""
                                    />
                                </div>
                            </>
                            :
                            null
                        }

                        {show_places_bubble ?
                            <div
                                style={{ width: screen_width + "px", height: 1.1 * screen_width / 2.3 + "px" }}
                                class="map-search-place-show-bubble"
                            >
                                {/* {has_sales && closer_place.map_icon !== "gas station" ? */}
                                {has_sales ?
                                    <div
                                        style={{ bottom: screen_width / 12.857 + "px", height: (screen_width / 15.3) + "px" }}
                                        class="row map-search-place-bubble-discount">

                                        <div class="map-search-place-bubble-silder">
                                            {closer_place.map_icon === "gas station" ?
                                                <Slider {...sellectedSalesSettings} >
                                                    {sales_text_gas.map(price => (
                                                        <div class="map-search-place-bubble-sales-text">
                                                            {price}
                                                        </div>
                                                    ))}

                                                </Slider>
                                                :
                                                <Slider {...sellectedSalesSettings} >
                                                    <div class="map-search-place-bubble-sales-text">

                                                    </div>
                                                    <div class="map-search-place-bubble-sales-text">
                                                        {sales_text}
                                                    </div>
                                                    <div class="map-search-place-bubble-sales-text">

                                                    </div>
                                                    <div class="map-search-place-bubble-sales-text">
                                                        {sales_text}
                                                    </div>
                                                </Slider>
                                            }



                                        </div>

                                    </div>
                                    :
                                    null
                                }
                                <div
                                    style={{ width: screen_width + "px", height: screen_width / 3 + "px" }}
                                    class="map-search-promo-photo-box"
                                >
                                    {active_subscription ?
                                        <>
                                            {cover_photo ?
                                                <img
                                                    class="map-search--promo-photo-img"
                                                    src={cover_photo}
                                                    alt=""
                                                />
                                                :
                                                <img
                                                    class="map-search--promo-photo-blur"
                                                    src={require("../../../assets/map/svg/cover.jpg")}
                                                    alt=""
                                                />
                                            }
                                        </>
                                        :
                                        <>
                                            {cover_photo ?
                                                <img
                                                    class="map-search--promo-photo-img"
                                                    src={cover_photo}
                                                    alt=""
                                                />
                                                :
                                                <img
                                                    class="map-search--promo-photo-blur"
                                                    src={require("../../../assets/map/svg/cover.jpg")}
                                                    alt=""
                                                />
                                            }



                                            {is_claimed ?
                                                <div class="map-search-bubble-unclaimed-acconut2">
                                                    This Place started<br />the claim procedure
                                                </div>
                                                :
                                                <div onClick={() => this.openHubContactModal()} class="map-search-bubble-unclaimed-acconut">
                                                    Claim this<br />Place Now
                                                </div>
                                            }

                                            {active_subscription ?
                                                null
                                                : is_claimed ?
                                                    null
                                                    :
                                                    <span onClick={() => this.changeSelectingHubPhoto(closer_place.id)}>
                                                        {cover_photo ?
                                                            <>
                                                                <div onClick={() => this.fileInput.click()} class="map-search-user-change-cover-photo-box">
                                                                    Do you have a better<br />photo of this place?
                                                                </div>
                                                                <div class="map-search-user-cover-props">
                                                                    By: <b>{closer_cover_props}</b>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div class="map-search-user-set-cover-photo-box">
                                                                    Do you have a photo that shows what this place is?
                                                                </div>
                                                                <div onClick={() => this.fileInput.click()} class="map-search-user-set-cover-photo-btn">
                                                                    Set a Photo
                                                                </div>
                                                            </>
                                                        }
                                                        <input
                                                            style={{ display: 'none' }}
                                                            accept="image/png, image/jpeg"
                                                            type="file"
                                                            name="photo"
                                                            onChange={this.onCoverPhotoSubmit}
                                                            ref={fileInput => this.fileInput = fileInput}
                                                        />
                                                    </span>
                                            }
                                        </>
                                    }
                                    <div class="map-search-bubble-closer-name">
                                        {closer_name.length > 38 ?
                                            <>
                                                {closer_name.slice(0, 35) + "..."}
                                            </>
                                            :
                                            <>
                                                {closer_name}
                                            </>
                                        }

                                    </div>
                                    <div class="map-search-bubble-closer-category">
                                        {closer_category}
                                    </div>

                                    {is_open_label === "" ?
                                        <>
                                            {closer_is_open ?
                                                <div class="map-search-bubble-closer-is-open">
                                                    <div class="map-search-bubble-closer-green-bubble"></div>
                                                    open
                                                </div>
                                                :
                                                <div class="map-search-bubble-closer-is-closed">
                                                    <div class="map-search-bubble-closer-red-bubble"></div>
                                                    closed
                                                </div>
                                            }
                                        </>
                                        :
                                        <div class="map-search-bubble-closer-has-label">
                                            <div class="map-search-bubble-closer-yellow-bubble"></div>
                                            {is_open_label}
                                        </div>
                                    }
                                </div>

                                {has_sales ?
                                    < div
                                        style={{ width: screen_width + "px", height: screen_width / 12.857 + "px" }}
                                        class="map-search-bubble-bottom-text-disc">

                                        {this.state.show_places_bubble ?
                                            <div
                                                onClick={() => this.changeShowPlaceBubble()}
                                                class="map-search-top-down-arrow-disc">
                                                <img
                                                    class="map-search-top-down-arrow-icon"
                                                    src={require("../../../assets/map/svg/down-arrow.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                        <div onClick={() => this.openModal(closer_place.id)} class="map-search-bubble-see-more-disc">
                                            See more..
                                        </div>
                                    </div>
                                    :
                                    <div
                                        style={{ width: screen_width + "px", height: screen_width / 9.857 + "px" }}
                                        class="map-search-bubble-bottom-text">

                                        {this.state.show_places_bubble ?
                                            <div
                                                onClick={() => this.changeShowPlaceBubble()}
                                                class="map-search-top-down-arrow">
                                                <img
                                                    class="map-search-top-down-arrow-icon"
                                                    src={require("../../../assets/map/svg/down-arrow.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                        <div onClick={() => this.openModal(closer_place.id)} class="map-search-bubble-see-more">
                                            See more..
                                        </div>
                                    </div>

                                }
                            </div>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                {/* <BottomNavbar data={{ sellected: "map", notifications_status: this.props.notifications_status }} /> */}
                {this.state.showstories || this.state.hubModal ?
                    null
                    :
                    <BottomNavbar data={{ sellected: "map", notifications_status: this.props.notifications_status }} />
                }
            </>

        );
    }
}



const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    profile: state.profile.profile,
    map_places: state.map.map_places,
    new_hub_created: state.hub.new_hub_created,
    new_created_hub: state.hub.new_created_hub,
    new_prospect_id: state.hub.new_prospect_id,
    map_memories: state.map.map_memories,
    memories: state.memory.hub_memories,
    noInternet: state.layout.noInternet,
    user_with_memories: state.map.user_with_memories,
    story_memories: state.memory.memories,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    uploading_memory: state.map.uploading_memory,
    tags: state.memory.tags,
});


export default (connect(mapStateToProps, {
    createAlert,
    searchHubs,
    getMyProfile,
    getMapPlaces,
    getHubMemories,
    createHubProspect,
    createSuggestionHubProspect,
    requestAppointment,
    getMapMemories,
    getMapUserWithMemories,
    registerPushNotificationDevice,
    getNotificationsStatus,
    deleteReaction,
    createMemoryReaction,
    updateProfilePicture,
    updateProfileFullName,
    visitedUserRegistered,
    createActiveUserVisit,
    deleteMemory,
    submitHubContactForm,
    userChangeHubCoverPhoto,
    createMemoryView,
    clearStateHubMemories,
    getMemoryTags,
    clearMemoryTags,
    createdHubMarker,
    getNewCreatedHub
})(StreetView))








// export default StreetView;