import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { clearSearchUsers, getSearchUsers, answerBuddyRequest, cancelBuddyRequest, sendBuddyRequest, searchUsers } from '../../../store/actions/buddies'
import { getMyProfile } from '../../../store/actions/profile';
import { getNotificationsStatus } from '../../../store/actions/notification'
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import '../../../assets/buddies/searchBuddies.css'
import { searchHubs, getHomepageAllHubs } from '../../../store/actions/hub';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class SearchBuddies extends Component {

    state = {
        user_search: "",
        send_buddyrequests: [],
        answered_request: [],
        cancelled_requests: [],
        hubs_search: false,
        question_mode: false,
        users_mode: true,
        search_mode: false,
        swiping_user: false,
        hub_id: ""
    };


    componentWillMount() {
        this.props.clearSearchUsers()
        // if (this.props.users.length < 2) {
        this.props.getSearchUsers();
        // }
    }


    componentDidMount() {
        this.props.getMyProfile();
        let searched_before = JSON.parse(localStorage.getItem('srchbfr'))
        if (searched_before === true) {
            this.setState({
                question_mode: false,
                users_mode: true,
                // hubs_mode: true,
            })
        }

        fetch(`https://geolocation-db.com/json/`)
            .then(response => {
                return response.json();
            }, "jsonp")
            .then(res => {
                const country = res.country_name
                let city = res.city
                if (JSON.parse(localStorage.getItem('slctct')) !== "") {
                    city = JSON.parse(localStorage.getItem('slctct'))
                }
                localStorage.setItem('cntr', JSON.stringify(country));
                this.props.getHomepageAllHubs(country, city, "show_all", 0);
            }).catch(err => {
                this.props.getHomepageAllHubs("show_all", "show_all", "show_all", 0);
            });

        if (this.props.users.length === 0) {
            this.props.getSearchUsers();
        }
    }

    // getNewHubs() {
    //     fetch(`https://geolocation-db.com/json/`)
    //         .then(response => {
    //             return response.json();
    //         }, "jsonp")
    //         .then(res => {
    //             const country = res.country_name
    //             let city = res.city
    //             if (JSON.parse(localStorage.getItem('slctct')) !== "") {
    //                 city = JSON.parse(localStorage.getItem('slctct'))
    //             }
    //             localStorage.setItem('cntr', JSON.stringify(country));
    //             this.props.getHomepageAllHubs(country, city, "show_all", this.props.hubs_offset);
    //         }).catch(err => {
    //             this.props.getHomepageAllHubs("show_all", "show_all", "show_all", this.props.hubs_offset);
    //         });
    // }

    getNewBuddies() {
        this.props.getSearchUsers();
    }

    onChange = e => {
        const query = e.target.value
        if (query.length > 2) {
            this.setState({ search_mode: true })
        } else {
            this.setState({ search_mode: false })
        }
        if (e.target.value === "") {
            this.setState({ [e.target.name]: e.target.value })
            this.props.getSearchUsers(JSON.parse(localStorage.getItem('ur')).id);
            fetch(`https://geolocation-db.com/json/`)
                .then(response => {
                    return response.json();
                }, "jsonp")
                .then(res => {
                    const country = res.country_name
                    let city = res.city
                    if (JSON.parse(localStorage.getItem('slctct')) !== "") {
                        city = JSON.parse(localStorage.getItem('slctct'))
                    }
                    localStorage.setItem('cntr', JSON.stringify(country));
                    this.props.getHomepageAllHubs(country, city, "show_all", 0);
                }).catch(err => {
                    this.props.getHomepageAllHubs("show_all", "show_all", "show_all", 0);
                });
        } else {
            const query = e.target.value
            if (query.length > 2) {
                if (query.toLowerCase() !== "ad") {
                    this.setState({ hubs_search: true })
                    this.props.searchUsers(query)
                    this.props.searchHubs(query)
                }
            }
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    buddyRequestSubmit(user) {
        const sending_user = this.props.profile[0].user
        const recieving_user = user
        const status = "Send"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.sendBuddyRequest(buddyrequest);
    }

    cancelBuddyRequest(user) {
        const sending_user = this.props.profile[0].user
        const recieving_user = user
        const status = "Cancelled"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.cancelBuddyRequest(buddyrequest);
    }

    updateBuddyrequestList(user) {
        const old_list = [...this.state.send_buddyrequests]
        if ((old_list.indexOf(user)) !== -1) {
            const new_remove = old_list.filter(f => f !== user)
            this.setState({ send_buddyrequests: new_remove })
        }
        else {
            old_list.push(user)
            this.setState({ send_buddyrequests: old_list })
        }
    }

    updateCancelledRequestsList = (user) => (e) => {
        e.preventDefault();
        const old_list = [...this.state.cancelled_requests]
        if ((old_list.indexOf(user)) !== -1) {
            const new_remove = old_list.filter(f => f !== user)
            this.setState({ cancelled_requests: new_remove })
        }
        else {
            old_list.push(user)
            this.setState({ cancelled_requests: old_list })
        }
    }

    updateAnsweredRequest(user) {
        // e.preventDefault();
        const old_list = [...this.state.answered_request]
        old_list.push(user)
        this.setState({ answered_request: old_list })
    }

    acceptBuddyRequest = (user) => (e) => {
        this.updateAnsweredRequest(user)
        const sending_user = user
        const recieving_user = this.props.profile[0].user
        const status = "Accepted"
        const answer = { sending_user, recieving_user, status };
        this.props.answerBuddyRequest(answer);
    }

    declineBuddyRequest = (user) => (e) => {
        this.updateAnsweredRequest(user)
        const sending_user = user
        const recieving_user = this.props.profile[0].user
        const status = "Declined"
        const answer = { sending_user, recieving_user, status };
        this.props.answerBuddyRequest(answer);
    }

    selectHubsMode() {
        setTimeout(() => {
            this.setState({
                question_mode: false,
                hubs_mode: true,
                users_mode: false,
            });
        }, 40);
        localStorage.setItem('srchbfr', JSON.stringify(true));
    }

    selectBuddiesMode() {
        setTimeout(() => {
            this.setState({
                question_mode: false,
                hubs_mode: false,
                users_mode: true,
            });
        }, 40);
        localStorage.setItem('srchbfr', JSON.stringify(true));
    }

    redirectToHubPage(hub_id) {
        setTimeout(() => {
            this.setState({
                hub_id: hub_id,
                hub_page_redirect: true,
            });
        }, 40);
    }

    redirectToRequestReservation(hub_id, reservation_hub) {
        setTimeout(() => {
            this.setState({
                hub_id: hub_id,
                reservation_redirect: true,
                reservation_hub: reservation_hub
            });
        }, 40);
    }


    render() {
        if (this.state.reservation_redirect) {
            return <Redirect to={{ pathname: '/hub/request-reservation', state: { hub: this.state.reservation_hub } }} />
        }

        if (this.state.hub_page_redirect) {
            return <Redirect to={{ pathname: '/hub', state: { hub_id: this.state.hub_id } }} />
        }


        let profile = []
        if (profile) {
            profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        }
        let hubs = []
        if (this.state.hubs_search) {
            if (this.state.user_search.length > 2) {
                hubs = this.props.hubs
            }
        }

        let users_slides = []
        this.props.users.map(user =>
            users_slides.push(
                <div class="search-carousal13-carouserl-box">
                    <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                        <div class="search-carousal13-img-box">
                            <img
                                class="search-carousal13-img"
                                src={user.profile_picture}
                                alt=""
                            />
                        </div>
                    </Link>
                    <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                        <div class="search-carousal13-sugget-username">
                            {user.username}
                        </div>
                        {user.full_name === "null" ?
                            <div class="search-carousal13-sugget-full-name-empty">
                                Go out. Have fun!
                            </div>
                            : user.full_name === null ?
                                <div class="search-carousal13-sugget-full-name-empty">
                                    Go out. Have fun!
                                </div>
                                : user.full_name === "" ?
                                    <div class="search-carousal13-sugget-full-name-empty">
                                        Go out. Have fun!
                                    </div>
                                    : user.full_name === "undefined" ?
                                        <div class="search-carousal13-sugget-full-name-empty">
                                            Go out. Have fun!
                                        </div>
                                        :
                                        <div class="search-carousal13-sugget-full-name">
                                            {user.full_name}
                                        </div>
                        }
                    </Link>
                    <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                        {user.user_memory_1 || user.user_memory_2 || user.user_memory_3 ?
                            <div class="row search-carousal13-sugget-3-memories">
                                <>
                                    {user.user_memory_1 !== null ?
                                        <div class="search-carousal13-sugget-memory">
                                            <img
                                                class="search-carousal13-sugget-memory-size"
                                                src={user.user_memory_1}
                                                alt=""
                                            />
                                        </div>
                                        :
                                        <div class="search-carousal13-sugget-memory">
                                            <div class="search-carousal13-sugget-memory-empty">
                                            </div>
                                        </div>
                                    }
                                    {user.user_memory_2 !== null ?
                                        <div class="search-carousal13-sugget-memory">
                                            <img
                                                class="search-carousal13-sugget-memory-size"
                                                src={user.user_memory_2}
                                                alt=""
                                            />
                                        </div>
                                        :
                                        <div class="search-carousal13-sugget-memory">
                                            <div class="search-carousal13-sugget-memory-empty">
                                            </div>
                                        </div>
                                    }
                                    {user.user_memory_3 !== null ?
                                        <div class="search-carousal13-sugget-memory">
                                            <img
                                                class="search-carousal13-sugget-memory-size"
                                                src={user.user_memory_3}
                                                alt=""
                                            />
                                        </div>
                                        :
                                        <div class="search-carousal13-sugget-memory">
                                            <div class="search-carousal13-sugget-memory-empty">

                                            </div>
                                        </div>
                                    }
                                </>

                            </div>
                            :
                            <div class="row search-carousal13-sugget-3-memories-empty">
                                <div class="search-carousal13-empty-photos-box">
                                    <img
                                        class="search-carousal13-empty-photos-icon"
                                        src={require("../../../assets/layout/homepage svg/no-post.svg")}
                                        alt=""
                                    />
                                </div>
                                <div class="search-carousal13-no-memories-yet">
                                    No Memories yet
                                </div>
                            </div>
                        }
                    </Link>


                    {!this.state.send_buddyrequests.includes(user.user) ?
                        <div onTouchStart onClick={() => this.updateBuddyrequestList(user.user)} >
                            <div
                                onClick={() => this.buddyRequestSubmit(user.user)}
                                class="search-carousal13-sugget-add-btn">
                                Request
                            </div>
                        </div>
                        :
                        <div
                            onClick={() => this.updateBuddyrequestList(user.user)}
                        >
                            <div
                                onTouchStart
                                onClick={() => this.cancelBuddyRequest(user.user)}
                                class="search-carousal13-sugget-requested-btn">
                                Requested
                            </div>
                        </div>
                    }

                </div>

            ,)
        )

        let slides = []

        if (this.state.users_mode) {
            slides = users_slides
        }

        const browser = navigator.userAgent.search("Android")
        let apple_device = false
        if (browser === -1) {
            apple_device = true
        }

        const ArrowNext = (props) => (
            <div onClick={props.onClick} class="search-adroid-next-btn-box">
                <div class="search-adroid-arrow-btn-layout">
                    <img
                        class="search-adroid-arrow-icon"
                        src={require("../../../assets/buddies/svg/arrow-rigth.svg")}
                        alt=""
                    />
                </div>
            </div>
        );

        const ArrowPrev = (props) => (
            <div onClick={props.onClick} class="search-adroid-previous-btn-box">
                <div class="search-adroid-arrow-btn-layout">
                    <img
                        class="search-adroid-arrow-icon"
                        src={require("../../../assets/buddies/svg/arrow-left.svg")}
                        alt=""
                    />
                </div>
            </div>
        );

        const height = window.innerHeight
        const width = window.innerWidth

        const tagSettings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        return (
            <Fragment>
                <div>
                    <div id="page-scroll-up" class="search-buddies-mobile-navbar">
                        <div className="search-buddies-search-box">
                            <div className="search-top">
                                <div className="form-inline w-100">
                                    <div class="search-buddy-input-icon-box">
                                        <img class="search-buddy-input-icon-search" src={require("../../../assets/gags/svg/Icon.svg")} alt="" />
                                    </div>
                                    <input
                                        className="search-form-text"
                                        type="text"
                                        name="user_search"
                                        onChange={this.onChange}
                                        placeholder="Search"
                                        value={this.state.user_search}
                                        aria-label="Search"
                                    // id="field-to-show"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="search-buddies-whole-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row search-buddies-color-10">
                                <DesktopNavbar data={{ profile: this.props.profile, sellected: "search", notifications_status: this.props.notifications_status }} />
                                {/* <!-- small screen header start --> */}
                                <div class="container-fluid search-buddies-small-header-container-10">
                                    <div class="search-buddies-small-header-10">

                                    </div>
                                </div>
                                {/* <!-- small screen header end --> */}

                                <div class="col-xl-10 col-lg-12 body-right-10 pb-5">
                                    <div class="row">
                                        <div class="col-xl-12 text-center search-buddies-hide-h3">
                                            <h3 class="text-blue-search-users my-3"><strong>Search Buddies & Hubs</strong></h3>
                                        </div>
                                    </div>

                                    <div class="search-buddies-row-position">
                                        {this.state.search_mode ?
                                            <>
                                                {this.props.users !== null ?
                                                    <div>
                                                        {this.props.users.map(user => (

                                                            <div class="single-item-buddy-field">
                                                                <div class="item1-10">
                                                                    <div class="row">
                                                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                                                                            <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                                                                                {user.profile_picture ?
                                                                                    <img class="search-user-picture-layout" src={user.profile_picture} alt="" />
                                                                                    :
                                                                                    <img class="search-user-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                                                }
                                                                            </Link>
                                                                        </div>

                                                                        <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                                                                            <div class="search-buddies-all-info">
                                                                                <div class="search-buddies-info-username">
                                                                                    {user.username}
                                                                                </div>
                                                                                <div class="search-buddies-info-fullname">
                                                                                    {profile.full_name === "null" ?
                                                                                        null
                                                                                        : profile.full_name === null ?
                                                                                            null
                                                                                            : profile.full_name === "" ?
                                                                                                null
                                                                                                : profile.full_name === "undefined" ?
                                                                                                    null
                                                                                                    :
                                                                                                    <>{user.full_name}</>
                                                                                    }
                                                                                </div>
                                                                                {user.user === profile.user ?
                                                                                    null
                                                                                    :
                                                                                    <div class="search-buddies-info-extra">
                                                                                        Suggested for you
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        </Link>

                                                                        {/* {user.full_name === "null" ?
                                                                            null
                                                                            : user.full_name === null ?
                                                                                null
                                                                                : user.full_name === "" ?
                                                                                    null
                                                                                    : user.full_name === undefined ?
                                                                                        null
                                                                                        : user.full_name === "undefined" ?
                                                                                            null
                                                                                            :
                                                                                            <div class="search-user-user-username-alone">
                                                                                                <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                                                                                                    <p class="text-blue-username-search-users">{user.full_name}</p> 
                                                                                                </Link>
                                                                                            </div>
                                                                        } */}




                                                                        {/* <div class="search-all-buttons-layout"> */}



                                                                        {user.user === profile.user ?
                                                                            null
                                                                            : user.is_buddy === "Buddies" ?
                                                                                null
                                                                                : this.state.answered_request.includes(user.user) ?
                                                                                    <div class="">
                                                                                        <div class="search-buddy-requested-btn">
                                                                                            Answered
                                                                                        </div>
                                                                                    </div>
                                                                                    : user.is_buddy === "Unanswered" ?
                                                                                        <>
                                                                                            <div
                                                                                                onTouchStart
                                                                                                onClick={this.acceptBuddyRequest(user.user)}
                                                                                                class="search-buddies-btn-approve"
                                                                                            >
                                                                                                Accept
                                                                                            </div>
                                                                                            <div
                                                                                                onTouchStart
                                                                                                onClick={this.declineBuddyRequest(user.user)}
                                                                                                class="search-buddies-btn-decline"
                                                                                            >
                                                                                                Decline
                                                                                            </div>
                                                                                        </>
                                                                                        : this.state.cancelled_requests.includes(user.user) ?
                                                                                            <div
                                                                                                onTouchStart
                                                                                                onClick={() => this.updateBuddyrequestList(user.user)}
                                                                                                class="search-buddy-request-btn">
                                                                                                <span onClick={this.updateCancelledRequestsList(user.user)}>
                                                                                                    <span onClick={() => this.buddyRequestSubmit(user.user)} >
                                                                                                        Request
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                            : user.is_buddy === "Requested" ?
                                                                                                <div
                                                                                                    onTouchStart
                                                                                                    onClick={this.updateCancelledRequestsList(user.user)}
                                                                                                    class="">
                                                                                                    <div
                                                                                                        onClick={() => this.cancelBuddyRequest(user.user)}
                                                                                                        class="search-buddy-requested-btn"
                                                                                                    >
                                                                                                        Requested
                                                                                                    </div>
                                                                                                </div>
                                                                                                : this.state.send_buddyrequests.includes(user.user) ?
                                                                                                    <div
                                                                                                        onTouchStart
                                                                                                        onClick={() => this.updateBuddyrequestList(user.user)}
                                                                                                        class="">
                                                                                                        <div
                                                                                                            onClick={() => this.cancelBuddyRequest(user.user)}
                                                                                                            class="search-buddy-requested-btn"
                                                                                                        >
                                                                                                            Requested
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div
                                                                                                        onTouchStart
                                                                                                        onClick={() => this.updateBuddyrequestList(user.user)}
                                                                                                        class="">
                                                                                                        <div
                                                                                                            onClick={() => this.buddyRequestSubmit(user.user)}
                                                                                                            class="search-buddy-request-btn"
                                                                                                        >
                                                                                                            Request
                                                                                                        </div>
                                                                                                    </div>
                                                                        }
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ))}

                                                        {hubs.map(hub => (
                                                            <div class="single-item-buddy-field">
                                                                <div class="item1-10">
                                                                    <div class="row">
                                                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                                                                            <Link to={{ pathname: '/hub', state: { hub_id: hub.id } }}>
                                                                                {hub.logo ?
                                                                                    <img
                                                                                        class="search-user-picture-layout"
                                                                                        src={hub.logo}
                                                                                        alt=""
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        class="search-user-picture-layout"
                                                                                        src={require("../../../assets/hub/hub-page/svg/location-red.png")}
                                                                                        alt=""
                                                                                    />
                                                                                }


                                                                            </Link>
                                                                        </div>
                                                                        <Link to={{ pathname: '/hub', state: { hub_id: hub.id } }}>

                                                                            <div class="search-buddies-all-info">
                                                                                <div class="search-buddies-info-username">

                                                                                </div>
                                                                                <div class="search-buddies-info-fullname">
                                                                                    {hub.name}
                                                                                </div>
                                                                                <div class="search-buddies-info-extra">

                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                        {/* 
                                                                        <div class="search-user-user-username-alone">
                                                                                
                                                                                
                                                                                
                                                                                <p class="text-blue-username-search-users">
                                                                                    
                                                                                </p>
                                                                            
                                                                        </div> */}

                                                                        {/* <div class="search-buddies-hub-traffic-section">
                                                                    <div class="search-buddies-hub-traffic-title">
                                                                        Traffic
                                                                    </div>
                                                                    {hub.active_subscription ?
                                                                        <div
                                                                            onClick={() => this.openTrafficModal()}
                                                                            className="search-buddies-hub-traffic-img-box"
                                                                        >
                                                                            {hub.traffic === "Low" ?
                                                                                <img
                                                                                    className="search-buddies-hub-traffic-img-sc"
                                                                                    src={require("../../../assets/layout/homepage svg/Congestion Green.svg")}
                                                                                    alt=""
                                                                                />
                                                                                : hub.traffic === "Medium" ?
                                                                                    <img
                                                                                        className="search-buddies-hub-traffic-img-sc"

                                                                                        src={require("../../../assets/layout/homepage svg/Congestion Yellow.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        className="search-buddies-hub-traffic-img-sc"

                                                                                        src={require("../../../assets/layout/homepage svg/Congestion Red.svg")}
                                                                                        alt=""
                                                                                    />
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            onClick={() => this.openNoSuscriptionTrafficModal()}
                                                                            className="search-buddies-hub-traffic-img-sc"
                                                                        >
                                                                            <img
                                                                                className="search-buddies-hub-traffic-img-sc"
                                                                                src={require("../../../assets/layout/mika/dont-know.svg")}
                                                                                alt=""
                                                                            />
                                                                            <br />
                                                                        </div>
                                                                    }
                                                                </div>
                                                             */}
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        ))}

                                                    </div>
                                                    :
                                                    <div class="col-xl-12 single-item-buddy-field last-item-10 mb-3 margin-top-10">
                                                        <div class="item1-10">
                                                            <div class="row">
                                                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 w-small-10">
                                                                    <div class="cross-text-10">
                                                                        <span class="text-blue-search-users text-blue-empty">
                                                                            <strong>No Users to show yet</strong>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </>

                                            : this.state.users_mode ?
                                                <>
                                                    <div class="search-swipe-Whole-box-layout">
                                                        {this.props.users.length > 0 ?
                                                            <>
                                                                {/* {apple_device ?
                                                                    <SearchSwipeBuddies
                                                                        data={{
                                                                            users: this.props.users,
                                                                            profile_picture: profile.profile_picture,
                                                                            send_buddyrequests: this.state.send_buddyrequests,
                                                                            buddyRequestSubmit: this.buddyRequestSubmit.bind(this),
                                                                            cancelBuddyRequest: this.cancelBuddyRequest.bind(this),
                                                                            updateBuddyrequestList: this.updateBuddyrequestList.bind(this),
                                                                            getNewBuddies: this.getNewBuddies.bind(this),
                                                                        }}
                                                                    />
                                                                    : */}
                                                                <div class="search-buddies-adroid-slider-box">
                                                                    <Slider {...tagSettings} >
                                                                        {this.props.users.map(character => (
                                                                            <div class="search-adroid-buddies-swipe-card">
                                                                                <div
                                                                                    style={{
                                                                                        backgroundImage: 'url(' + character.profile_picture + ')',
                                                                                        height: height * 0.78,
                                                                                        width: 0.94 * width,
                                                                                        margin: "auto"
                                                                                    }}
                                                                                    class='search-adroid-buddies-swipe-square'
                                                                                >

                                                                                    <Link
                                                                                        class="search-adroid-buddies-invisible-redirect"
                                                                                        to={{
                                                                                            pathname: `/user-profile/${character.user}/profile`,
                                                                                            state: { user: character.user }
                                                                                        }}
                                                                                    >
                                                                                    </Link>

                                                                                    {/* <div class="search-swipe-buddies34-username"> */}
                                                                                    <Link
                                                                                        class="search-swipe-buddies34-username"
                                                                                        to={{
                                                                                            pathname: `/user-profile/${character.user}/profile`,
                                                                                            state: { user: character.user }
                                                                                        }}
                                                                                    >
                                                                                        {character.username}
                                                                                    </Link>
                                                                                    {/* </div> */}


                                                                                    {character.user_memory_1 || character.user_memory_2 || character.user_memory_3 ?
                                                                                        <Link to={{ pathname: `/user-profile/${character.user}/profile`, state: { user: character.user } }}>

                                                                                            <div class="search-swipe-buddies34-memories-box">
                                                                                                <div class="row ">
                                                                                                    {character.user_memory_1 !== null ?
                                                                                                        <div class="search-swipe-buddies34-memory-box-1">
                                                                                                            <img
                                                                                                                class="search-swipe-buddies34-memory-size"
                                                                                                                src={character.user_memory_1}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div class="search-swipe-buddies34-memory-box-1">
                                                                                                            <div class="search-swipe-buddies34-memory-empty">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {character.user_memory_2 !== null ?
                                                                                                        <div class="search-swipe-buddies34-memory-box-2">
                                                                                                            <img
                                                                                                                class="search-swipe-buddies34-memory-size"
                                                                                                                src={character.user_memory_2}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div class="search-swipe-buddies34-memory-box-2">
                                                                                                            <div class="search-swipe-buddies34-memory-empty">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {character.user_memory_3 !== null ?
                                                                                                        <div class="search-swipe-buddies34-memory-box-3">
                                                                                                            <img
                                                                                                                class="search-swipe-buddies34-memory-size"
                                                                                                                src={character.user_memory_3}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div class="search-swipe-buddies34-memory-box-3">
                                                                                                            <div class="search-swipe-buddies34-memory-empty">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                        :
                                                                                        <Link to={{ pathname: `/user-profile/${character.user}/profile`, state: { user: character.user } }}>
                                                                                            <div class="search-swipe-buddies34-memories-box-empty">
                                                                                                <div class="row ">
                                                                                                    <div class="search-swipe-buddies34-empty-photos-box">
                                                                                                        <img
                                                                                                            class="search-swipe-buddies34-empty-photos-icon"
                                                                                                            src={require("../../../assets/layout/homepage svg/no-post.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div class="search-swipe-buddies34-no-memories-yet">
                                                                                                        No Memories yet
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    }


                                                                                    {this.state.send_buddyrequests.includes(character.user) ?

                                                                                        <div onClick={() => this.cancelBuddyRequest(character.user)}
                                                                                            // {() => swipe('right', selectedUser)} 
                                                                                            class="search-adroid-buddies-center-choise-box">
                                                                                            <div onClick={() => this.updateBuddyrequestList(character.user)} class="search-adroid-buddies-center-recuestr-choise-icon-box">
                                                                                                Requested to be Buddies
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div onClick={() => this.buddyRequestSubmit(character.user)} class="search-adroid-buddies-center-choise-box">
                                                                                            <div onClick={() => this.updateBuddyrequestList(character.user)} class="search-adroid-buddies-center-choise-icon-box">
                                                                                                Request to be Buddies
                                                                                            </div>
                                                                                        </div>
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </Slider>
                                                                </div>
                                                                {/* } */}
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </>


                                                :
                                                null

                                        }

                                        <br /><br /><br /><br /><br /><br />
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    <BottomNavbar data={{ sellected: "search", notifications_status: this.props.notifications_status }} />
                </ScrollLink>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in buddies.js
    profile: state.profile.profile,
    auth: state.auth,
    users: state.buddies.users,
    hubs: state.hub.hubs,
    hubs_loading: state.hub.hubs_limit,
    hubs_offset: state.hub.hubs_offset,
    hubs_has_more: state.hub.hubs_has_more,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    getSearchUsers,
    answerBuddyRequest,
    cancelBuddyRequest,
    sendBuddyRequest,
    searchUsers,
    getNotificationsStatus,
    getMyProfile,
    searchHubs,
    getHomepageAllHubs,
    clearSearchUsers,
})(SearchBuddies);