import axios from "axios";
import { returnErrors } from "./alerts";

import {
  USER_LOADED,
  USER_LOADING,
  USER_LOCALY_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_TOKEN_SUCCESS,
  // LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT_SUCCESS,
  CLEAR_BUDDIES,
  CLEAR_HUBS,
  CLEAR_MEETING,
  CLEAR_MEMORY,
  CLEAR_NOTIFICATION,
  CLEAR_PROFILE,
  CLEAR_RESERVATION,
  ACCEPT_HUBS_TERMS,
  CHECK_DASHBOARD_ACCESS
} from "./actionTypes";


export const loginToken = (alias) => dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ alias });

  axios
    .post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/auth/login/callbacktoken/`, body, config)
    .then(res => {
      dispatch({
        type: LOGIN_TOKEN_SUCCESS,
        payload: res.data
      });
    }).catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR
      });
    });
}


export const login = (alias, token) => dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ alias, token });

  axios
    .post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/auth/login/`, body, config)
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
    }).catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
}

// load user

// export const loadUser = () => (dispatch, getState) => {
//   dispatch({ type: USER_LOADING });
//   axios
//     .get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/auth/user-load/`, tokenConfig(getState))
//     .then(res => {
//       dispatch({
//         type: USER_LOADED,
//         payload: res.data
//       });
//     }).catch(err => {
//       if (!err.response) {
//         dispatch({
//           type: USER_LOCALY_LOADED,
//         });
//       }
//       dispatch(returnErrors(err.response.data, err.response.status));
//       dispatch({
//         type: AUTH_ERROR
//       });
//     });
// };



//logout user

export const logout = () => (dispatch, getState) => {

  axios
    .post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/auth/logout/`, null, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: LOGOUT_SUCCESS
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_BUDDIES
        });
        dispatch({
          type: CLEAR_HUBS
        });
        dispatch({
          type: CLEAR_MEETING
        });
        dispatch({
          type: CLEAR_MEMORY
        });
        dispatch({
          type: CLEAR_NOTIFICATION
        });
        dispatch({
          type: CLEAR_PROFILE
        });
        dispatch({
          type: CLEAR_RESERVATION
        });
      }, 5000);

    }).catch(err => {
      console.log(err)
      // dispatch(returnErrors(err.response.data, err.response.status));
    });
};


//REGISTER user

export const register = (username, email) => dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ username, email });

  axios
    .post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/accounts/registration/`, body, config)
    .then(res => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data
      });
    }).catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: REGISTER_FAIL
      });
    });
};



//SETUP CONFIG WITH TOKEN - HELP FUNCTION
// axios.defaults.withCredentials = true;
export const tokenConfig = () => {
  const token = localStorage.getItem("tk");
  const config = {
    headers: {
      "Content-Type": "application/json"
    },
    // credentials: 'include'
  };

  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
}

export const acceptHubsTerms = (hub_terms) => (dispatch, getstate) => {
  axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/accept-hubs-terms/`, hub_terms, tokenConfig())
    .then(res => {
      dispatch({
        type: ACCEPT_HUBS_TERMS,
        payload: res.data
      });
    }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const CheckStaffAccess = () => (dispatch, getstate) => {
  axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/check-hubst3r-staff-validation/`, tokenConfig())
    .then(res => {
      dispatch({
        type: CHECK_DASHBOARD_ACCESS,
        payload: res.data
      });
    }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}