import React, { Component, lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import store from './store/store';
import { Provider as AlertProvider } from 'react-alert';

import Hubst3rPrivateRoute from './common/Hubst3rPrivateRoute';
import UserPrivateRoute from './common/UserPrivateRoute';
import HubPrivateRoute from './common/HubPrivateRoute';
import PublicRoute from './common/PublicRoute';
import AmbassadorsRoute from './common/AmbassadorsRoute';
import ShopsRoute from './common/ShopsRoute';

// import { loadUser } from './store/actions/auth';
import AlertTemplate from 'react-alert-template-basic';

// if made lazy changes in login page layout happens #everything fucks up
import HubRegister from './containers/hub/authentication/HubRegister';
import HubLogin from './containers/hub/authentication/HubLogin';

// import Homepage from './containers/user/Homepage'

import Hubst3rLottie from './components/user/layout/Hubst3rLottie';


import Login from './containers/user/authentication/Login';
import Register from './containers/user/authentication/Register';
import CompanyContact from './containers/hubst3r/authentication/CompanyContact';
import CompanySplash from './containers/hubst3r/company-spalsh/CompanySplash';

import Ambassadors from './containers/user/authentication/Ambassadors';
import HubJobs from './containers/hub/information/HubJobs';
import LandingPage from './containers/user/authentication/LandingPage';
import RequestReservation from './containers/user/meeting/RequestReservation';
import HubMenu from './containers/hub/menu/Menu';
import SingleMenu from './containers/hub/menu/SingleMenu'

import HubHomepage from './containers/hub/homepage/HubHomepage';
import SetupPage from './containers/hub/setup/SetupPage';
import InformationsPage from './containers/hub/information/InformationsPage';
import AddReservation from './containers/hub/reservations/AddReservation';
import PendingReservationsPage from './containers/hub/reservations/PendingReservationsPage';
import ApprovedReservationsPage from './containers/hub/reservations/ApprovedReservationsPage';

import AddAppointment from './containers/hub/appointments/AddAppointment';
import ApprovedAppointments from './containers/hub/appointments/ApprovedAppointments';
import PendingAppointments from './containers/hub/appointments/PendingAppointments';


import SetAppointment from './containers/hub/setFiles/SetAppointment';
import SetCatalog from './containers/hub/setFiles/SetCatalog';
import SetCategory from './containers/hub/setFiles/SetCategory';
import SetMapPhoto from './containers/hub/setFiles/SetMapPhoto';
import SetNameLogo from './containers/hub/setFiles/SetNameLogo';
import SetOnlineShop from './containers/hub/setFiles/SetOnlineShop';
import SetPromoPhoto from './containers/hub/setFiles/SetPromoPhoto';
import SetReservation from './containers/hub/setFiles/SetReservation';
import SetSocialMedia from './containers/hub/setFiles/SetSocialMedia';
import SetWorkingHours from './containers/hub/setFiles/SetWorkingHours';
import SetSales from './containers/hub/setFiles/SetSales';
import SetAccess from './containers/hub/setFiles/SetAccess';

import ShopMagazine from './containers/shops/magazine/ShopMagazine'
import ProductSwipe from './containers/user/shopping/ProductsSwipe';
// import Homepage from './containers/user/Homepage';
import HubPage from './containers/user/hub/HubPage';
import ArrangePage from './containers/user/meeting/ArrangePage';
import SearchBuddies from './containers/user/buddies/SearchBuddies';
import ShowProfileBuddies from './containers/user/buddies/ShowProfileBuddies';
import Meetings from './containers/user/meeting/Meetings';
import Notifications from './containers/user/notifications/Notifications';
import MyProfile from './containers/user/profile/MyProfile';
import UserSplash from './containers/user/profile/UserSplash';
import UserProfile from './containers/user/profile/UserProfile';
import Memory from './containers/user/memory/Memory';
import MemoryImpressions from './containers/user/memory/MemoryImpressions';
import PostMemory from './containers/user/memory/PostMemory';
import FindPlace from './containers/user/memory/FindPlace';
import SwipeBuddies from './containers/user/buddies/SwipeBuddies';
import ChatList from './containers/user/chat/ChatList';
import ChatRoom from './containers/user/chat/ChatRoom';
import ConversationRoom from './containers/user/chat/ConversationRoom';
import MapSearch from './containers/user/map/MapSearch';
import StreetView from './containers/user/map/StreetView';
import SetGasPrices from './containers/hub/setFiles/SetGasPrices';
import ScarletPage from './containers/scarlett/ScarlettPage/ScarlettPage';

// import StaffLogin from './containers/hubst3r/authentication/StaffLogin';
const TheExpirience = lazy(() => import('./containers/hubst3r/the-expirience/TheExpirience'))
const Invoices = lazy(() => import('./containers/hubst3r/invoices/Invoices'))
const Payments = lazy(() => import('./containers/hubst3r/payments/Payments'))
const AllCustomers = lazy(() => import('./containers/hubst3r/all-customers/AllCustomers'))
const Analytics = lazy(() => import('./containers/hubst3r/analytics/Analytics'))
const QuorterGoals = lazy(() => import('./containers/hubst3r/the-expirience/QuorterGoals'))

// import TheExpirience from './containers/hubst3r/the-expirience/TheExpirience';
// import Invoices from './containers/hubst3r/invoices/Invoices';
// import Payments from './containers/hubst3r/payments/Payments';
// import AllCustomers from './containers/hubst3r/all-customers/AllCustomers';
// import Analytics from './containers/hubst3r/analytics/Analytics';
// import QuorterGoals from './containers/hubst3r/the-expirience/QuorterGoals';


// import TermsOfUse from './containers/layout/legal/TermsOfUse';
// import Cookies from './containers/layout/legal/Cookies';
// import HubsTerms from './containers/layout/legal/HubsTerms';
// import InfringementPolicy from './containers/layout/legal/InfringementPolicy';
// import EventsTerms from './containers/layout/legal/EventsTerms';
// import ThirdPartiesTerms from './containers/layout/legal/ThirdPartiesTerms';

// layout containers
const Alerts = lazy(() => import('./containers/layout/Alerts'))
const About = lazy(() => import('./containers/layout/legal/About'))
const OfflinePage = lazy(() => import('./containers/layout/Offline'))
const Licenses = lazy(() => import('./containers/layout/legal/Licenses'))
const Privacy = lazy(() => import('./containers/layout/legal/PrivacyPolicy'))
const TermsOfUse = lazy(() => import('./containers/layout/legal/TermsOfUse'))
const Cookies = lazy(() => import('./containers/layout/legal/Cookies'))
const HubsTerms = lazy(() => import('./containers/layout/legal/HubsTerms'))
const InfringementPolicy = lazy(() => import('./containers/layout/legal/InfringementPolicy'))
const EventsTerms = lazy(() => import('./containers/layout/legal/EventsTerms'))
const ThirdPartiesTerms = lazy(() => import('./containers/layout/legal/ThirdPartiesTerms'))
const OopsPage = lazy(() => import('./containers/layout/OopsPage'))

// Hub staff containers
// const HubRegister = lazy(() => import('./containers/hub/authentication/HubRegister'))
// const HubLogin = lazy(() => import('./containers/hub/authentication/HubLogin'))
// const HubHomepage = lazy(() => import('./containers/hub/homepage/HubHomepage'))



const HubContact = lazy(() => import('./containers/hub/authentication/HubContact'))
const AnalyticsPage = lazy(() => import('./containers/hub/analytics/AnalyticsPage'))
const MemoriesPage = lazy(() => import('./containers/hub/gallery/MemoriesPage'))
// const InformationsPage = lazy(() => import('./containers/hub/information/InformationsPage'))
// const MenuPage = lazy(() => import('./containers/hub/information/MenuPage'))
const PostPromo = lazy(() => import('./containers/hub/information/PostPromo'))
const ShowPromo = lazy(() => import('./containers/hub/information/ShowPromo'))
// const PendingReservationsPage = lazy(() => import('./containers/hub/reservations/PendingReservationsPage'))
// const ApprovedReservationsPage = lazy(() => import('./containers/hub/reservations/ApprovedReservationsPage'))
const Hubst3rSplash = lazy(() => import('./containers/hub/information/Hubst3rSplash'))
const PersonelPage = lazy(() => import('./containers/hub/personel/PersonelPage'))





// user containers
// const Login = lazy(() => import('./containers/user/authentication/Login'))
// const Register = lazy(() => import('./containers/user/authentication/Register'))


const Homepage = lazy(() => import('./containers/user/Homepage'))
// const HubPage = lazy(() => import('./containers/user/hub/HubPage'))
const HubPromoPhoto = lazy(() => import('./containers/user/hub/HubPromoPhoto'))
// const ArrangePage = lazy(() => import('./containers/user/meeting/ArrangePage'))
const SelectHub = lazy(() => import('./containers/user/meeting/SelectHub'))

// const ArrangeSomething = lazy(() => import('./containers/user/meeting/ArrangeSomething'))
// const SearchBuddies = lazy(() => import('./containers/user/buddies/SearchBuddies'))
// const ShowProfileBuddies = lazy(() => import('./containers/user/buddies/ShowProfileBuddies'))
// const Meetings = lazy(() => import('./containers/user/meeting/Meetings'))
// const Notifications = lazy(() => import('./containers/user/notifications/Notifications'))
// const MyProfile = lazy(() => import('./containers/user/profile/MyProfile'))
// const UserSplash = lazy(() => import('./containers/user/profile/UserSplash'))

// const UserProfile = lazy(() => import('./containers/user/profile/UserProfile'))
// const Memory = lazy(() => import('./containers/user/memory/Memory'))
const AllMemories = lazy(() => import('./containers/user/memory/AllMemories'))
// const MemoryImpressions = lazy(() => import('./containers/user/memory/MemoryImpressions'))
// const PostMemory = lazy(() => import('./containers/user/memory/PostMemory'))
const CreateGag = lazy(() => import('./containers/user/gag/CreateGag'))
const Feedback = lazy(() => import('./containers/user/feedback/Feedback'))
const WaitressTables = lazy(() => import('./containers/hub/weitress/WaitressTables'))


const ProductPage = lazy(() => import('./containers/shops/products/products'))
const CategoriesPage = lazy(() => import('./containers/shops/categories/categories'))
const NewProductPage = lazy(() => import('./containers/shops/products/NewProduct'))
const NewBulkProducts = lazy(() => import('./containers/shops/products/NewBulkProducts'))

const ProductStockPage = lazy(() => import('./containers/shops/products/ProductStock'))
const BarcodeScannerPage = lazy(() => import('./containers/shops/barcode/BarcodeScanner'))
const BarcodeProductPage = lazy(() => import('./containers/shops/barcode/BarcodeProduct'))


const QrProductsPage = lazy(() => import('./containers/shops/qr-page/QrProducts'))
const AddQrProduct = lazy(() => import('./containers/shops/qr-page/AddQrProduct'))



//Alert options
const alertOptions = {
  timeout: 7000,
  position: 'top center',
  containerStyle: {
    zIndex: 99999999999999
  }
}


class App extends Component {

  // async componentDidMount() {
  //   store.dispatch(loadUser());
  // }

  render() {
    return (
      <Suspense
        fallback={
          <div>
            <Hubst3rLottie />
          </div>
        }
      >

        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router>
            <Alerts />
            <div className="container">
              <Switch>
                {/* layout */}
                <Route exact path="/offline" component={OfflinePage} />
                <Route exact path="/privacy-policy" component={Privacy} />
                <Route exact path="/terms-of-use" component={TermsOfUse} />
                <Route exact path="/cookies-policy" component={Cookies} />
                <Route exact path="/hubs-terms" component={HubsTerms} />
                <Route exact path="/infringement-policy" component={InfringementPolicy} />
                <Route exact path="/events-terms" component={EventsTerms} />
                <Route exact path="/third-parties-terms" component={ThirdPartiesTerms} />
                <Route exact path="/about" component={About} />
                <Route exact path="/licenses" component={Licenses} />
                {/* user */}
                <PublicRoute exact path="/register" component={Register} />
                <PublicRoute exact path="/login" component={Login} />
                {/* <PublicRoute  /> */}
                <AmbassadorsRoute exact path="/hubst3r-social-media-show/:profileID" component={Ambassadors} />
                <AmbassadorsRoute exact path="/nfc-welcome-message/:nfcID" component={LandingPage} />
                <AmbassadorsRoute exact path="/company-social-media-show/:profileID" component={CompanySplash} />
                {/* <AmbassadorsRoute exact path="/hub-menu/:profileID" component={HubMenu} /> */}
                <AmbassadorsRoute exact path="/menu-page-view/:hubID" component={HubMenu} />
                <AmbassadorsRoute exact path="/menu/:hubID" component={SingleMenu} />

                <Route exact path="/company-contact-us" component={CompanyContact} />

                <UserPrivateRoute exact path="/" component={MapSearch} />
                <UserPrivateRoute exact path="/all-memories-page" component={Homepage} />
                <UserPrivateRoute exact path="/hub" component={HubPage} />
                <UserPrivateRoute exact path="/hub/arrange" component={ArrangePage} />
                <UserPrivateRoute exact path="/hub/request-reservation" component={RequestReservation} />
                <UserPrivateRoute exact path="/select-hub-to-arrange" component={SelectHub} />
                <UserPrivateRoute exact path="/search-buddies" component={SearchBuddies} />
                <UserPrivateRoute exact path="/swipe-buddies" component={SwipeBuddies} />
                <UserPrivateRoute exact path="/show-profile-buddies/:profileID" component={ShowProfileBuddies} />
                <UserPrivateRoute exact path="/meetings" component={Meetings} />
                <UserPrivateRoute exact path="/notifications" component={Notifications} />
                <UserPrivateRoute exact path="/my-profile" component={MyProfile} />
                <UserPrivateRoute exact path="/user-splash" component={UserSplash} />
                <AmbassadorsRoute exact path="/user-profile/:profileID/:splash" component={UserProfile} />
                <UserPrivateRoute exact path="/memories" component={Memory} />
                <UserPrivateRoute exact path="/show-memories" component={AllMemories} />
                <UserPrivateRoute exact path="/chat-list" component={ChatList} />
                <UserPrivateRoute exact path="/chat-room" component={ChatRoom} />
                <UserPrivateRoute exact path="/conversation-room" component={ConversationRoom} />


                <UserPrivateRoute exact path="/memory-impressions" component={MemoryImpressions} />
                <UserPrivateRoute exact path="/hub-promo-photo" component={HubPromoPhoto} />
                <UserPrivateRoute exact path="/post-memory" component={PostMemory} />
                <UserPrivateRoute exact path="/find-place" component={FindPlace} />
                
                <UserPrivateRoute exact path="/swipe-products" component={ProductSwipe} />
                <AmbassadorsRoute exact path="/magazine/:shopID" component={ShopMagazine} />
                <UserPrivateRoute exact path="/scarlett" component={ScarletPage} />

                
                {/* <UserPrivateRoute exact path="/street-view" component={StreetView} /> */}

                <UserPrivateRoute exact path="/feedback" component={Feedback} />
                <UserPrivateRoute exact path="/gag" component={CreateGag} />

                <UserPrivateRoute exact path="/user-employee-page" component={WaitressTables} />

                {/* hub */}
                <Route exact path="/hub-contact-us" component={HubContact} />
                {/* <PublicRoute exact path="/hub-register" component={HubRegister} />
                <PublicRoute exact path="/hub-login" component={HubLogin} /> */}

                <HubPrivateRoute exact path="/hub-homepage" component={HubHomepage} />
                <HubPrivateRoute exact path="/setup-new-place" component={SetupPage} />
                <HubPrivateRoute exact path="/analytics" component={AnalyticsPage} />
                <HubPrivateRoute exact path="/hub-memories" component={MemoriesPage} />
                <HubPrivateRoute exact path="/information" component={InformationsPage} />

                <HubPrivateRoute exact path="/set-appointment" component={SetAppointment} />
                <HubPrivateRoute exact path="/set-catalog" component={SetCatalog} />
                <HubPrivateRoute exact path="/set-category" component={SetCategory} />
                <HubPrivateRoute exact path="/set-map-photo" component={SetMapPhoto} />
                <HubPrivateRoute exact path="/set-name-logo" component={SetNameLogo} />
                <HubPrivateRoute exact path="/set-online-shop" component={SetOnlineShop} />
                <HubPrivateRoute exact path="/set-promo-photo" component={SetPromoPhoto} />
                <HubPrivateRoute exact path="/set-reservation" component={SetReservation} />
                <HubPrivateRoute exact path="/set-social-media" component={SetSocialMedia} />
                <HubPrivateRoute exact path="/set-working-hours" component={SetWorkingHours} />
                <HubPrivateRoute exact path="/set-sales" component={SetSales} />
                <HubPrivateRoute exact path="/set-gas-prices" component={SetGasPrices} />
                <HubPrivateRoute exact path="/set-access" component={SetAccess} />



                <HubPrivateRoute exact path="/pending-reservations" component={PendingReservationsPage} />
                <HubPrivateRoute exact path="/approved-reservations" component={ApprovedReservationsPage} />
                {/* <HubPrivateRoute exact path="/menu" component={MenuPage} /> */}
                <HubPrivateRoute exact path="/post-promo-photo" component={PostPromo} />
                <HubPrivateRoute exact path="/show-promo-photo" component={ShowPromo} />
                <HubPrivateRoute exact path="/hub-tables-splash" component={Hubst3rSplash} />
                <HubPrivateRoute exact path="/hub-jobs" component={HubJobs} />
                <HubPrivateRoute exact path="/hub-personel" component={PersonelPage} />
                <HubPrivateRoute exact path="/add-reservation" component={AddReservation} />
                <HubPrivateRoute exact path="/add-appointment" component={AddAppointment} />
                <HubPrivateRoute exact path="/approved-appointments" component={ApprovedAppointments} />
                <HubPrivateRoute exact path="/pending-appointments" component={PendingAppointments} />


                {/* <HubPrivateRoute exact path="/los-pollos-hermanos-the-gag" component={StaffLogin} /> */}
                <Hubst3rPrivateRoute exact path="/los-pollos-hermanos-the-expirience" component={TheExpirience} />
                <Hubst3rPrivateRoute exact path="/los-pollos-hermanos-invoices" component={Invoices} />
                <Hubst3rPrivateRoute exact path="/los-pollos-hermanos-payments" component={Payments} />
                <Hubst3rPrivateRoute exact path="/los-pollos-hermanos-all-customers" component={AllCustomers} />
                <Hubst3rPrivateRoute exact path="/los-pollos-hermanos-analytics" component={Analytics} />
                <Hubst3rPrivateRoute exact path="/los-pollos-hermanos-company-goals" component={QuorterGoals} />

                <ShopsRoute exact path="/shop-products/:shopCategoryID" component={ProductPage} />
                <ShopsRoute exact path="/shop-categories" component={CategoriesPage} />
                <ShopsRoute exact path="/shop-new-product/:shopCategoryID" component={NewProductPage} />
                <ShopsRoute exact path="/shop-new-bulk-products" component={NewBulkProducts} />
                <ShopsRoute exact path="/shop-product-stock/:productID" component={ProductStockPage} />
                <ShopsRoute exact path="/shop-barcode-scanner" component={BarcodeScannerPage} />
                <ShopsRoute exact path="/shop-barcode-product" component={BarcodeProductPage} />
                <AmbassadorsRoute exact path="/shop-qr-products/:shopQrID" component={QrProductsPage} />
                <ShopsRoute exact path="/add-product-to-qr" component={AddQrProduct} />

                

                <Route path="*" component={OopsPage} />
              </Switch>
            </div>
          </Router>
        </AlertProvider>


      </Suspense>
    )
  }
}

export default (App);


