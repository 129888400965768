import axios from 'axios';

import { createAlert, returnErrors } from './alerts';

import {
    GET_SEARCH_USERS,
    SEARCH_USERS_LOCALLY_LOADED,
    GET_BUDDIES,
    BUDDIES_LOCALLY_LOADED,
    GAGS_LOCALLY_LOADED,
    GET_GAGS,
    GET_GAG_MEMBERS,
    DELETE_GAG,
    CREATE_GAG,
    CREATE_BUDDY_REQUEST,
    ANSWER_BUDDY_REQUEST,
    CANCEL_BUDDY_REQUEST,
    REMOVE_BUDDY,
    GET_SEARCHED_USERS_QUERY,
    GET_SEARCHED_BUDDY_QUERY,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    CLEAR_SEARCH_USERS
} from './actionTypes';
import { tokenConfig } from './auth';


export const getSearchUsers = () => (dispatch, getstate) => {
    // dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/search-users/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SEARCH_USERS,
                payload: res.data
            });
            // setTimeout(() => {
            //     dispatch({ type: PAGE_LOADED });
            // }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: SEARCH_USERS_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const searchUsers = (query) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/search-users/search/${query}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SEARCHED_USERS_QUERY,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getBuddies = (user_id) => (dispatch, getstate) => {
    // dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${user_id}/user-buddies/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_BUDDIES,
                payload: res.data
            });
            // setTimeout(() => {
            //     dispatch({ type: PAGE_LOADED });
            // }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: BUDDIES_LOCALLY_LOADED,
                });
            }
            this.props.createAlert({ problemAppeared: "Something went wrong. We apologize. You can help us by reporting the problem!" });
            // dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const searchBuddy = (query) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/search-buddy/search/${query}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SEARCHED_BUDDY_QUERY,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getGags = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user-gags/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_GAGS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: GAGS_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getGagMembers = (id) => (dispatch, getstate) => {
    if (id !== "") {
        axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/gag/${id}/gag-members/`, tokenConfig())
            .then(res => {
                dispatch({
                    type: GET_GAG_MEMBERS,
                    payload: res.data
                });

            }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) });
    }
}

export const deleteGag = id => (dispatch, getstate) => {
    axios.delete(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user-gags/${id}/`, tokenConfig())
        .then(res => {
            dispatch(createAlert({ deleteGag: 'The Gag has been Deleted' }));
            dispatch({
                type: DELETE_GAG,
                payload: id
            });
        }).catch(err => console.log(err));
}

export const createGag = (gag) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-gag/`, gag, tokenConfig())
        .then(res => {
            dispatch(createAlert({ createGag: 'Your Gag has been Created!!' }));
            dispatch({
                type: CREATE_GAG,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const sendBuddyRequest = buddyrequest => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-buddy-request/`, buddyrequest, tokenConfig())
        .then(res => {
            // dispatch(createAlert({ friendRequest: 'Request Delivered' }));
            dispatch({
                type: CREATE_BUDDY_REQUEST,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const answerBuddyRequest = buddyrequest => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/answer-buddy-request/`, buddyrequest, tokenConfig())
        .then(res => {
            // dispatch(createAlert({ friendRequest: 'Request Delivered' }));
            dispatch({
                type: ANSWER_BUDDY_REQUEST,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const removeBuddy = buddyrequest => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/remove-buddy/`, buddyrequest, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_BUDDY,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const cancelBuddyRequest = buddyrequest => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/cancel-buddy-request/`, buddyrequest, tokenConfig())
        .then(res => {
            // dispatch(createAlert({ friendRequest: 'Request Delivered' }));
            dispatch({
                type: CANCEL_BUDDY_REQUEST,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const clearSearchUsers = updated_conversation => (dispatch, getstate) => {
    dispatch({ type: CLEAR_SEARCH_USERS, });
}

