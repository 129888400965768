import { CREATE_ALERT, GET_ERRORS } from './actionTypes';

export const createAlert = (msg) => {
    return {
        type: CREATE_ALERT,
        payload: msg
    };
};

//return errors

export const returnErrors = (msg, status) => {
    return {
        type: GET_ERRORS,
        payload: { msg, status }
    };
};
