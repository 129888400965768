import axios from 'axios';

import { createAlert, returnErrors } from './alerts';

import {
    GET_PENDING_MEETINGS,
    PENDING_MEETINGS_LOCALLY_LOADED,
    GET_CANCELLED_MEETINGS,
    CANCELLED_MEETINGS_LOCALLY_LOADED,
    GET_RESERVATION_MEETINGS,
    RESERVATION_MEETINGS_LOCALLY_LOADED,
    GET_MEETING_INVITATIONS,
    CREATE_ALERT,
    CANCEL_MEETING,
    CREATE_MEETING,
    UPDATE_MEETING,
    CREATE_INVITATION,
    ANSWER_INVITATION,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    CANCEL_RESERVATION_MEETING
} from './actionTypes';
import { tokenConfig } from './auth';


export const getPendingMeetings = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/pending-meetings/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PENDING_MEETINGS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: PENDING_MEETINGS_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

// export const getAcceptedMeetings = () => (dispatch, getstate) => {
//     axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/accepted-meetings`, tokenConfig())
//         .then(res => {
//             dispatch({
//                 type: GET_ACCEPTED_MEETINGS,
//                 payload: res.data
//             });
//         }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
// }

// export const getDeclinedMeetings = () => (dispatch, getstate) => {
//     axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/declined-meetings`, tokenConfig())
//         .then(res => {
//             dispatch({
//                 type: GET_DECLINED_MEETINGS,
//                 payload: res.data
//             });
//         }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
// }

export const getCancelledMeetings = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/cancelled-meetings/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_CANCELLED_MEETINGS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: CANCELLED_MEETINGS_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getReservationMeetings = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/reservations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_RESERVATION_MEETINGS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: RESERVATION_MEETINGS_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getMeetingInvitations = (meeting_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/meeting/${meeting_id}/invitations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MEETING_INVITATIONS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const handleInvitation = (answer) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/invitation/response/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: ANSWER_INVITATION,
                payload: res.data
            });
        }).catch(err => console.log(err));
}

export const createInvitation = (invitation, meeting_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${meeting_id}/invitation/create/`, invitation, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_INVITATION,
                payload: res.data
            });
        }).catch(err => console.log(err));
}

export const createMeeting = (meeting) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/meetings/`, meeting, tokenConfig())
        .then(res => {
            dispatch(createAlert({ createMeeting: 'The Moment has been Successfully Created' }));
            dispatch({
                type: CREATE_MEETING,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const updateMeeting = (meeting, id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/update-meeting/${id}/`, meeting, tokenConfig())
        .then(res => {
            dispatch(createAlert({ createMeeting: 'The Moment has been Updated' }));
            dispatch({
                type: UPDATE_MEETING,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const cancelMeeting = id => (dispatch, getstate) => {
    axios.delete(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/meetings/${id}/`, tokenConfig())
        .then(res => {
            dispatch(createAlert({ cancelMeeting: 'The Moment has been Cancelled' }));
            dispatch({
                type: CANCEL_MEETING,
                payload: id
            });
        }).catch(err => console.log(err));
}


export const cancelReservationMeeting = id => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${id}/cancel-reservation-meeting/`, tokenConfig())
        .then(res => {
            dispatch(createAlert({ cancelMeeting: 'The Moment has been Cancelled' }));
            dispatch({
                type: CANCEL_RESERVATION_MEETING,
                payload: res.data
            });
        }).catch(err => console.log(err));
}

