import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import "../../../assets/hub/hub-page/enjoy-here-modal.css"


export class EnjoyHereModal extends Component {


    closeModal = () => {
        this.props.data.closeModal()
    }

    render() {
        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="enjoy-here-box">
                            <img
                                onClick={this.closeModal}
                                class="enjoy-here-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <img
                                class="enjoy-here-photo-layout"
                                src={this.props.data.hub_promo}
                                alt=""
                            />

                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}


export default (EnjoyHereModal);