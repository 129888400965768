import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import '../../../assets/menu/menu-page.css'
import { getHubFirstCatalogPages } from '../../../store/actions/hub'
import HTMLFlipBook from 'react-pageflip';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { createFirstTimeVisit } from '../../../store/actions/hubst3rActions';




export class MenuPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            totalPage: 0,
            page_selected: false,
            selected_page: "",
            catalog_name: "",
            pagesMode: false,
            nav1: null,
            nav2: null,
            miniCatalogMode: false,
            page_show_more: false,
            page_changing: false,
            showQrModal: false
        };
    }

    componentWillMount() {
        this.props.getHubFirstCatalogPages(this.props.match.params.hubID)
    }


    componentDidMount() {
        // if (!JSON.parse(localStorage.getItem("frsttmvst"))) {
        //     localStorage.setItem('frsttmvst', JSON.stringify("True"));
        const browser = window.loadVersionBrowser().name;
        const full_browser = window.clientInformation.userAgent;
        const device_width = window.innerWidth
        const device_height = window.innerHeight
        fetch(`https://geolocation-db.com/json/`)
            .then(response => {
                return response.json();
            }, "jsonp")
            .then(res => {
                const ip = res.IPv4
                const country = res.country_name
                const city = res.city
                const latitude = res.latitude
                const longitude = res.longitude
                const state = res.state
                const from_nfc = false
                const nfc_number = ""
                const data = { from_nfc, nfc_number, browser, full_browser, device_width, device_height, ip, country, city, latitude, longitude, state }
                this.props.createFirstTimeVisit(data)
            }).catch(err => {
                console.log("Hiiiiiii")
            });
    }
    // }

    // openZoomPage(page_photo) {
    //     this.setState({
    //         page_selected: true,
    //         selected_page: page_photo
    //     });
    // };

    // closeZoomPage() {
    //     this.setState({
    //         page_selected: false,
    //         selected_page: ""
    //     });
    //     setTimeout(() => {
    //         this.flipBook.pageFlip().turnToPage(this.state.page);
    //     }, 100);
    // };

    nextButtonClick() {
        this.setState({
            page_selected: false,
            selected_page: ""
        });

        this.flipBook.pageFlip().flipNext();
    };

    prevButtonClick() {
        this.setState({
            page_selected: false,
            selected_page: ""
        });
        this.flipBook.pageFlip().flipPrev();
    };

    onPage = (e) => {
        this.setState({
            page: e.data,
            page_changing: false,
            page_show_more: false,
        });
    };


    render() {
        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const tagSettings = {
            dots: true,
            infinite: false,
            slidesToShow: 1,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        let user = JSON.parse(localStorage.getItem('ur'))
        const height = window.innerHeight
        const width = window.innerWidth

        let catalogsMode = true
        if (this.state.pagesMode) {
            catalogsMode = false
        }

        let menu_pages = this.props.menu_pages

        let small_width = 0.3
        if (this.props.catalogs.length > 2) {
            small_width = 0.2
        }

        let current_page = {}
        if (this.props.menu_pages.length > 0) {
            current_page = this.props.menu_pages[this.state.page]
        }

        let hub = {}
        if (this.props.hub.length > 0) {
            hub = this.props.hub[0]
        }

        return (
            <>
                <div class="menu-zoom-all-menu-size">
                    <>
                        <>
                            <div class={this.state.page_selected ? "menu-all-menu-size-none" : "menu-all-menu-size"}>
                                <HTMLFlipBook
                                    width={600}
                                    height={1000}
                                    showCover={true}
                                    onFlip={this.onPage}
                                    ref={(el) => (this.flipBook = el)}
                                >
                                    {menu_pages.map(page => (
                                        <div >
                                            < div class="menu-page-size">
                                                <img
                                                    class="menu-page-img-size"
                                                    src={page.photo}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    ))}

                                </HTMLFlipBook>

                            </div >

                            <div class="menu-all-menu-size-small">
                                <HTMLFlipBook
                                    size="fixed"
                                    width={width}
                                    height={height}
                                    // showCover={true}
                                    // mobileScrollSupport={true}
                                    // onFlip={this.onPage}
                                    // ref={(el) => (this.flipBook = el)}
                                    // size="stretch"
                                    // minWidth={315}
                                    // maxWidth={1000}
                                    // minHeight={400}
                                    // maxHeight={1533}
                                    maxShadowOpacity={0.5}
                                    showCover={true}
                                    mobileScrollSupport={true}
                                    onFlip={this.onPage}
                                    onChangeOrientation={this.onChangeOrientation}
                                    onChangeState={this.onChangeState}
                                    className="demo-book"
                                    ref={(el) => (this.flipBook = el)}
                                >

                                    {menu_pages.map(page => (
                                        // <div style={{ backgroundColor: "rgba(35, 45, 80)" }}>
                                        < div class="menu-page-size" >
                                            <img
                                                class="menu-page-img-size"
                                                src={page.photo}
                                                alt=""
                                            />
                                        </div>
                                        // </div>
                                    ))}
                                </HTMLFlipBook>
                            </div >


                            {/* <div
                                    class="menu-bottom-navbar-big-zoom"
                                    onClick={() => this.openZoomPage()}
                                >
                                    <div
                                        class="menu-bottom-navbar-big-btn-zoom">

                                        <img
                                            class="menu-bottom-navbar-logo-zoom"
                                            src={require("../../../assets/menu/svg/zoom.svg")}
                                            alt=""
                                        />

                                    </div>
                                </div> */}

                            {this.state.page === 0 ?
                                null
                                :
                                <div
                                    class="menu-bottom-navbar-small-1"
                                    onClick={() => this.prevButtonClick()}
                                >
                                    <div
                                        class="menu-bottom-navbar-btn-zoom">
                                        <img
                                            class="menu-bottom-navbar-icon"
                                            src={require("../../../assets/menu/svg/back.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }

                            {this.props.menu_pages.length - 1 === this.state.page ?
                                null
                                :
                                <div
                                    class="menu-bottom-navbar-small-4"
                                    onClick={() => this.nextButtonClick()}
                                >
                                    <div
                                        class="menu-bottom-navbar-btn-zoom">
                                        <img
                                            class="menu-bottom-navbar-icon"
                                            src={require("../../../assets/menu/svg/next.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    </>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    hub: state.hub.hub_menu_qr,
    menu_pages: state.hub.menu_pages,
    catalogs: state.hub.catalogs,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    getHubFirstCatalogPages,
    createFirstTimeVisit
})(MenuPage);