import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHubPendingNotificationsStatus, getHubCancellationsNotificationsStatus } from '../../../store/actions/notification'
import "../../../assets/hub/hub-layout/bottomNavbar.css"


export class HubBottomNavbar extends Component {

    componentWillMount() {
        this.props.getHubPendingNotificationsStatus()
        this.props.getHubCancellationsNotificationsStatus()
    }

    render() {
        let new_pending_notifications = ""
        let pending_notification_status = this.props.pending_notifications_status[0]
        if (this.props.pending_notifications_status[0]) {
            new_pending_notifications = pending_notification_status["new_notifications"]
        }
        let new_cancellations_notifications = ""
        let cancellations_notification_status = this.props.cancellations_notifications_status[0]
        if (cancellations_notification_status) {
            new_cancellations_notifications = cancellations_notification_status["new_notifications"]
        }
        const sellected = this.props.data.sellected

        return (
            <Fragment>
                <div class="fixed-bottom ml-1">
                    <div class="container-fluid">
                        <div class="row">

                            {sellected === "pending" ?
                                <div class="hub-nav-bottom-left-sellected">
                                    <Link to="/pending-reservations">
                                        <div class="hub-nav-bottom-text">
                                            Requests
                                        </div>
                                    </Link>
                                </div>
                                :
                                <div class="hub-nav-bottom-left">
                                    <Link to="/pending-reservations">
                                        <div class="hub-nav-bottom-text">
                                            Requests
                                        </div>
                                    </Link>
                                </div>
                            }

                            {sellected === "booked" ?
                                <div class="hub-nav-bottom-center-sellected">
                                    <Link to="/pending-reservations">
                                        <div class="hub-nav-bottom-text">
                                            Booked
                                        </div>
                                    </Link>
                                </div>
                                :
                                <div class="hub-nav-bottom-center">
                                    <Link to="/approved-reservations">
                                        <div class="hub-nav-bottom-text">
                                            Booked
                                        </div>
                                    </Link>
                                </div>
                            }

                            {sellected === "info" ?
                                <div class="hub-nav-bottom-right-sellected">
                                    <Link to="/information">
                                        <div class="hub-nav-bottom-text">
                                            Profile
                                        </div>
                                    </Link>
                                </div>
                                :
                                <div class="hub-nav-bottom-right">
                                    <Link to="/information">
                                        <div class="hub-nav-bottom-text">
                                            Profile
                                        </div>
                                    </Link>
                                </div>
                            }

                            {/* <div class="hub-nav-bottom-right">
                                <Link to="/information">
                                    <div class="hub-nav-bottom-text">
                                        Profile
                                    </div>
                                </Link>
                            </div> */}


                            {/* <div class="col-lg-1 col-md-1"></div>
                            <div class="col-lg-2 col-md-2 footer-width-7 py-3 text-center home-div-7">
                                <Link to="/pending-reservations">
                                    <div
                                        class={sellected === "pending" ?
                                            "home-7 footer-icon-7 middle-icon-footer-7"
                                            :
                                            "home-7 footer-icon-7 footer-icon-p-7"
                                        }
                                    >

                                        <a href="#">
                                            {sellected === "pending" ?
                                                <img src={require("../../../assets/hub/hub-gallery/svg/sellected-pending.svg")} alt="" />
                                                :
                                                <span>
                                                    {new_pending_notifications ?
                                                        <img src={require("../../../assets/hub/hub-gallery/svg/pending-notification.svg")} alt="" />
                                                        :
                                                        <img src={require("../../../assets/hub/hub-gallery/svg/Path 656-b.svg")} alt="" />
                                                    }
                                                </span>
                                            }
                                        </a>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-2 col-md-2 footer-width-7 py-3 text-center">
                                <Link to="/approved-reservations">
                                    <div
                                        class={sellected === "booked" ?
                                            "home-7 footer-icon-7 middle-icon-footer-7"
                                            :
                                            "home-7 footer-icon-7 footer-icon-p-7"
                                        }
                                    >
                                        <a href="#">
                                            {sellected === "booked" ?
                                                <img src={require("../../../assets/hub/hub-gallery/svg/sellected-booked.svg")} alt="" />
                                                :
                                                <span>
                                                    {new_cancellations_notifications ?
                                                        <img src={require("../../../assets/hub/hub-gallery/svg/cancellations-notification.svg")} alt="" />
                                                        :
                                                        <img src={require("../../../assets/hub/hub-gallery/svg/Reservations-b.svg")} alt="" />
                                                    }
                                                </span>
                                            }
                                        </a>
                                    </div>
                                </Link>
                            </div>

                            <div class="col-lg-2 col-md-2 footer-width-7 py-3 text-center">
                                <Link to="/hub-memories">
                                    <div
                                        class={sellected === "gallery" ?
                                            "home-7 footer-icon-7 middle-icon-footer-7"
                                            :
                                            "home-7 footer-icon-7 footer-icon-p-7"
                                        }
                                    >
                                        <a href="#">
                                            {sellected === "gallery" ?
                                                <img src={require("../../../assets/hub/hub-gallery/svg/sellected-gallery.svg")} alt="" />
                                                :
                                                <img src={require("../../../assets/hub/hub-gallery/svg/gallery1.svg")} alt="" />
                                            }
                                        </a>
                                    </div>
                                </Link>
                            </div>

                            <div class="col-lg-2 col-md-2 footer-width-7 py-3 text-center">
                                <Link to="/analytics">
                                    <div
                                        class={sellected === "analytics" ?
                                            "home-7 footer-icon-7 middle-icon-footer-7"
                                            :
                                            "home-7 footer-icon-7 footer-icon-p-7"
                                        }
                                    >
                                        <a href="#">
                                            {sellected === "analytics" ?
                                                <img src={require("../../../assets/hub/hub-gallery/svg/sellected-analytics.svg")} alt="" />
                                                :
                                                <img src={require("../../../assets/hub/hub-gallery/svg/Analytics-b.svg")} alt="" />
                                            }
                                        </a>
                                    </div>
                                </Link>
                            </div>

                            <div class="col-lg-2 col-md-2 footer-width-7 py-3 text-center">
                                <Link to="/information">
                                    <div
                                        class={sellected === "info" ?
                                            "home-7 footer-icon-7 middle-icon-footer-7"
                                            :
                                            "home-7 footer-icon-7 footer-icon-p-7"
                                        }
                                    >
                                        <a href="#">
                                            {sellected === "info" ?
                                                <img src={require("../../../assets/hub/hub-gallery/svg/sellected-info.svg")} alt="" />
                                                :
                                                <img src={require("../../../assets/hub/hub-gallery/svg/information-b.svg")} alt="" />
                                            }
                                        </a>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-1 col-md-1"></div> */}


                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    pending_notifications_status: state.notification.pending_notifications_status,
    cancellations_notifications_status: state.notification.cancellations_notifications_status
});

export default connect(mapStateToProps, { getHubPendingNotificationsStatus, getHubCancellationsNotificationsStatus })(HubBottomNavbar);