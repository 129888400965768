import React, { Fragment, lazy, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    uploadHubLogo,
    setHubName,
    createHubMenuQr,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';


export class SetupPage extends Component {

    state = {
        logo_photo: "",
        logo_photo_file: null,
        name: '',
        redirect: false,
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

    doneWithName() {
        if (this.state.name !== "") {
            const id = this.props.hub[0].id
            const name = this.state.name
            const hub = { id, name }
            this.props.setHubName(hub)
        }
        this.setState({
            redirect: true,
        })
    }

    onLogoPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            logo_photo: URL.createObjectURL(e.target.files[0]),
            logo_photo_file: e.target.files[0]
        })
        setTimeout(() => {
            this.updateLogoPhoto()
        }, 4000);
    };

    updateLogoPhoto = () => {
        const id = this.props.hub[0].id
        const logo = this.state.logo_photo_file
        let hub = { id, logo }
        this.props.uploadHubLogo(hub)
        setTimeout(() => {
            hub = { id }
            this.props.createHubMenuQr(hub)
        }, 2000);
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/information' }} />
        }

        return (
            <Fragment>
                <>
                    {this.props.hub.map(hub => (
                        <>
                            <HubDesktopNavbar data={{ sellected: "" }} />
                            <div>
                                <div class="whole-setup-page-page-layout">
                                    <OfflineLabel />
                                    <>
                                        <div class="setup-page-welcome-title">
                                            <Link to="/hub-homepage" class="setup-page-back-box">
                                                <div class="setup-page-back-icon-box">
                                                    <img
                                                        class="setup-page-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </Link>
                                            Set Logo & Name
                                        </div>
                                        <>
                                            <div class="setup-page-logo-text">
                                                Set the logo photo
                                            </div>

                                            <div class="setup-page-logo-box">
                                                {this.state.logo_photo !== "" ?
                                                    <img
                                                        class="setup-page-logo-img"
                                                        src={this.state.logo_photo}
                                                        onClick={() => this.logoInput.click()}
                                                        alt=""
                                                    />
                                                    : hub.logo ?
                                                        <img
                                                            class="setup-page-logo-img"
                                                            src={hub.logo}
                                                            onClick={() => this.logoInput.click()}
                                                            alt=""
                                                        />
                                                        :
                                                        <div onClick={() => this.logoInput.click()} class="setup-page-set-logo-photo">
                                                            Set logo
                                                        </div>
                                                }
                                                <input
                                                    style={{ display: 'none' }}
                                                    accept="image/png, image/jpeg"
                                                    type="file"
                                                    name="photo"
                                                    onChange={this.onLogoPhotoSubmit}
                                                    ref={logoInput => this.logoInput = logoInput}
                                                />
                                            </div>

                                            <div class="setup-page-name-text">
                                                Is the name right?
                                            </div>

                                            <div class="setup-page-name-input-box">
                                                <input
                                                    class='setup-page-name-input-field'
                                                    name="name"
                                                    onChange={this.onChange}
                                                    placeholder="Place name..."
                                                    value={this.state.name === "" ? hub.name : this.state.name}
                                                />
                                            </div>
                                        </>

                                    </>
                                </div>
                            </div>

                            <div
                                onClick={() => this.doneWithName()}
                                class="setup-page-next-btn">
                                Done
                            </div>


                        </>
                    ))}
                </>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    uploadHubLogo,
    setHubName,
    createHubMenuQr
})(SetupPage);