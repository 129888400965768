import axios from 'axios';
import { returnErrors, createAlert } from './alerts';
import {
    GET_SCARLETT_ANSWER,
    GET_SCARLETT_SESSION_ALL_MESSAGES,
    CREATE_NEW_SCARELTT_MESSAGE,
    CREATE_MESSAGE_WITH_SCARLETT,
    NO_INTERNET_CONNECTION,

} from './actionTypes';
import { tokenConfig } from './auth';
import { Configuration, OpenAIApi } from "openai";


const configuration = new Configuration({
    apiKey: "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",
  });

const chatGPDApi = new OpenAIApi(configuration);


export const askScarlet = (prompt) => (dispatch) => { 
    const lastQuestion = JSON.parse(localStorage.getItem('skrlttlstqstn'))
    // if (prompt.includes("Scarlett")||prompt.includes("Scarlet")){
    if (lastQuestion !== prompt){
        console.log(prompt)
        localStorage.setItem('skrlttlstqstn', JSON.stringify(prompt));

        chatGPDApi.createCompletion({ 
            model: "text-davinci-003",
            temperature: 0,
            max_tokens: 150,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
            prompt: prompt
        })
            .then(res => {
                window.sayText(res.data.choices[0].text,4,1,3);
                localStorage.setItem('chtgptanswer', JSON.stringify(true));
                dispatch({
                    type: GET_SCARLETT_ANSWER,
                    payload: res.data.choices[0].text
                })
            }).catch(err => {
                dispatch(returnErrors(err.response.data, err.response.status))
            });        
            // }, 200);
        // }
    }
}


export const getScarlettSessionMessages = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user-messages-with-scarlett/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SCARLETT_SESSION_ALL_MESSAGES,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const createMessageWithScarlett = (message) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-message-with-scarlett/`, message, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_MESSAGE_WITH_SCARLETT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

