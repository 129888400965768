import React from 'react';
import "../../../assets/meeting/wifi-modal.css"



class WifiModal extends React.Component {
    state = {

    };

    closeModal = () => {
        this.props.data.closeModal()
    }


    render() {
        return (
            <div class="">
                <div class="row">
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                    <div class="wifi-modal-box">
                        <div class="row text-right">
                            <div class="col-xl-12 text-right">
                                <div
                                    onClick={() => this.closeModal()}
                                    class="wifi-modal-times-frame">
                                    <img class="wifi-modal-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4>
                                <strong class="wifi-modal-hub-name">
                                    {this.props.data.hub_name}'s WiFi Code
                                </strong>
                            </h4>
                        </div>
                        <div class="wifi-modal-hub-wifi-position">
                            <h4>
                                <strong class="wifi-modal-hub-wifi">
                                    {this.props.data.hub_wifi}
                                </strong>
                            </h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                    </div>
                </div>
            </div>
        )
    }
}


export default (WifiModal);