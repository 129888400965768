import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            const user = JSON.parse(localStorage.getItem('ur'))
            if (user) {
                if (user.hub_id === 0) {
                    return <Redirect to="/" />
                }
                if (user.hub_id !== 0) {
                    return <Redirect to="/hub-homepage" />
                }
            } else {
                return <Component {...props} />;
            }
            // if (user) {
            // } else {
            //     return <Redirect to="/login" />;
            // }
        }}
    />
);

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(PublicRoute);