import axios from 'axios';
import { returnErrors, createAlert } from './alerts';
import {
    CREATE_NEW_HUB,
    CONVERT_USER_TO_HUB_STAFF,
    DASHBOARD_GET_ALL_HUBS,
    GET_COMPANY_STATS,
    DASHBOARD_GET_ALL_PROSPECTS,
    DASHBOARD_GET_ALL_FREEZE_PROSPECTS,
    CREATE_NEW_PROSPECT,
    DASHBOARD_GET_ALL_SALES_COMMUNICATIONS,
    DASHBOARD_GET_ALL_SUPPORT_COMMUNICATIONS,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    CREATE_NEW_SALES_COMMUNICATION,
    CREATE_NEW_SUPPORT_COMMUNICATION,
    DASHBOARD_GET_ALL_TEAM_TASKS,
    DASHBOARD_GET_TEAM_QUORTER_GOALS,
    CREATE_NEW_VISIT,
    VISITED_USER_REGISTERED,
    DASHBOARD_GET_LOCATIONS,
    CREATE_ACTIVE_USER_VISIT,
    CREATE_HUB_AUTO,
    GET_HUBS_WITH_DATA,
    DEACTIVATE_GOOGLE_HUBS,
    ACTIVATE_GOOGLE_HUBS
} from './actionTypes';
import { tokenConfig } from './auth';



export const createHubAuto = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/create-hub-auto/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_HUB_AUTO,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const createActiveUserVisit = (data) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/create-active-user-visit/`, data, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_ACTIVE_USER_VISIT,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const getCompany = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-company-stats/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_COMPANY_STATS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const createFirstTimeVisit = (data) => (dispatch, getstate) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/create-first-time-visit/`, data, config)
        .then(res => {
            dispatch({
                type: CREATE_NEW_VISIT,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const visitedUserRegistered = (visite_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/visited-user-registered/${visite_id}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: VISITED_USER_REGISTERED,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}



export const createNewHub = (hub, prospect_id) => (dispatch, getstate) => {
    const hubData = new FormData();
    // hubData.append("area", hub.area);
    // hubData.append("cover_photo", hub.cover_photo);
    hubData.append("category", hub.category);
    hubData.append("map_url", hub.map_url);
    hubData.append("prospect_id", hub.prospect_id);

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/${prospect_id}/create-hub/`, hubData, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_NEW_HUB,
                payload: res.data
            });
            dispatch(createAlert({ createHub: 'The Hub has been Created!!' }));
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const createNewProspect = (prospect) => (dispatch, getstate) => {
    const prospectData = new FormData();
    prospectData.append("logo", prospect.logo);
    prospectData.append("name", prospect.name);
    prospectData.append("legal_name", prospect.legal_name);
    prospectData.append("city", prospect.city);
    prospectData.append("country", prospect.country);

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/create-prospect/`, prospectData, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_NEW_PROSPECT,
                payload: res.data
            });
            dispatch(createAlert({ createHub: 'The Prospect has been Created!!' }));
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const createNewSalesCommunication = (communication) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/create-new-communication/`, communication, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_NEW_SALES_COMMUNICATION,
                payload: res.data
            });
            dispatch(createAlert({ createHub: 'The Communication has been Created!!' }));
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const createNewSupportCommunication = (communication) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/create-new-support-communication/`, communication, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_NEW_SUPPORT_COMMUNICATION,
                payload: res.data
            });
            dispatch(createAlert({ createHub: 'The Communication has been Created!!' }));
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const convertUserToHubStaff = (profile_id, hub_id) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/${profile_id}/${hub_id}/conver-to-hub-staff/`, tokenConfig())
        .then(res => {
            dispatch({
                type: CONVERT_USER_TO_HUB_STAFF,
                payload: res.data
            });
            dispatch(createAlert({ converUserToHub: 'The User has been Succesfully binden to a Hub!!' }));
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const getDashboardAllHubs = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/dashboard-get-all-hubs/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_HUBS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const searchDashboardHubsHubs = (query) => (dispatch, getstate) => {
    const corrected_query1 = query.replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_query2 = corrected_query1.replace(/\ /g, 'k0l0kenogam1m3n01111');
    const final_query = corrected_query2.replace(/\//g, 'k0l0slashgam1m3n01111');
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubs/search-dashboard/${final_query}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_HUBS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const searchDashboardProspect = (query) => (dispatch, getstate) => {
    const corrected_query1 = query.replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_query2 = corrected_query1.replace(/\ /g, 'k0l0kenogam1m3n01111');
    const final_query = corrected_query2.replace(/\//g, 'k0l0slashgam1m3n01111');
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/prospects/search-dashboard/${final_query}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_PROSPECTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getDashboardProspects = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/dashboard-get-all-prospects/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_PROSPECTS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}



export const getDashboardAllFreezeProspects = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/dashboard-get-all-freeze-prospects/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_FREEZE_PROSPECTS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getProspectAllSalesCommunications = (prospect_id) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/${prospect_id}/get-all-sales-communications/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_SALES_COMMUNICATIONS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getHubAllSupportCommunications = (customer_id) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/${customer_id}/get-all-support-communications/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_SUPPORT_COMMUNICATIONS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const getAllTeamsTasks = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/get-all-team-tasks/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_ALL_TEAM_TASKS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getTeamsQuorterGoals = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/get-all-quorter-goals/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DASHBOARD_GET_TEAM_QUORTER_GOALS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}



export const getLocationsAPI = (query) => (dispatch, getstate) => {
    console.log("hiiiiiii")
    // const options = {
    //     method: 'GET',
    //     url: 'https://instagram47.p.rapidapi.com/location_search',
    //     params: { search: query + " Limassol Cyprus" },
    //     headers: {
    //         'x-rapidapi-key': '8f6604c93dmsh4b857b13e437d50p1e1616jsn80e3b31e569a',
    //         'x-rapidapi-host': 'instagram47.p.rapidapi.com'
    //     }
    // };

    // axios.request(options).then(function (response) {
    //     // console.log(response.data);
    //     dispatch({
    //         type: DASHBOARD_GET_LOCATIONS,
    //         payload: response.data
    //     });

    // }).catch(function (error) {
    //     console.error(error);
    // });


    // const options = {
    //     method: 'GET',
    //     url: `https://wyre-data.p.rapidapi.com/restaurants/town/${query}`,
    //     headers: {
    //         'x-rapidapi-key': '8f6604c93dmsh4b857b13e437d50p1e1616jsn80e3b31e569a',
    //         'x-rapidapi-host': 'wyre-data.p.rapidapi.com'
    //     }
    // };


    // axios.request(options).then(function (response) {
    //     console.log(response.data);
    // }).catch(function (error) {
    //     console.error(error);
    // });

    // const googleApiKey = "AIzaSyDExpLoFrPF7fEYRLfedZcapZpH-6Q78lw"

    // var config = {
    //     method: 'get',
    //     url: `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Museum%20of%20Contemporary%20Art%20Australia&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=${googleApiKey}`,
    //     headers: {}
    // };

    // axios(config)
    //     .then(function (response) {
    //         console.log(JSON.stringify(response.data));
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });



}




export const changeOldHubsSource = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/change-old-hubs-source/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUBS_WITH_DATA,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const clickDeactivateGoogleSource = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/deactivate-google-hubs-source/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DEACTIVATE_GOOGLE_HUBS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const clickActivateGoogleSource = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubst3r/activate-google-hubs-source/`, tokenConfig())
        .then(res => {
            dispatch({
                type: ACTIVATE_GOOGLE_HUBS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}