import {
    GET_PENDING_RESERVATIONS,
    GET_PENDING_HUB_RESERVATIONS,
    PENDING_HUB_RESERVATIONS_LOCALY_LOADED,
    GET_BOOKED_RESERVATIONS,
    GET_ALL_CANCELLED_RESERVATIONS,
    CANCELLED_RESERVATIONS_LOCALY_LOADED,
    GET_DATES_BOOKED_RESERVATIONS,
    ANSWER_RESERVATION,
    GET_HUB_FREE_TABLES,
    UPDATE_RESERVATION_NOTE,
    UPDATE_HUB_RESERVATION_NOTE,
    INCREASE_RESERVATION_ARRIVED_PERSONS,
    DECREASE_RESERVATION_ARRIVED_PERSONS,
    GET_DATES_BOOKED_RESERVATIONS_CANCELLED,
    PENDING_RESERVATIONS_LOCALY_LOADED,
    BOOKED_RESERVATIONS_LOCALY_LOADED,
    CLEAR_RESERVATION,
    GET_DATES_BOOKED_HUB_RESERVATIONS,
    GET_USER_HUB_RESERVATIONS,
    INCREASE_HUB_RESERVATION_ARRIVED_PERSONS,
    DECREASE_HUB_RESERVATION_ARRIVED_PERSONS,
    CANCEL_HUB_RESERVATION,
    USER_HUB_RESERVATIONS_LOCALY_LOADED,
    GET_HUB_RESERVATION_SHARES,
    USER_CANCEL_HUB_RESERVATION,
    GET_ALL_HUB_RESERVATIONS_CANCELLED,
    ALL_HUB_RESERVATIONS_CANCELLED_LOCALY_LOADED,
    GET_HUB_ALL_FREE_TABLES,
    INCREASE_TABLE_PERSONS,
    DECREASE_TABLE_PERSONS,
    SET_TABLE_STATUS,
    SET_HUB_RESERVATION_TABLE,
    SET_RESERVATION_TABLE,
    SET_TABLE_PERSONS,
    USER_CREATE_NEW_RESERVATION,
    ACCEPT_HUB_RESERVATION,
    DECLINE_HUB_RESERVATION,
    GET_DATES_RESERVATION_HOURS
} from '../actions/actionTypes';

const initialState = {
    reservations: [],
    hub_reservations: [],
    hub_reservation_shares: [],
    cancellations: [],
    numbers: [],
    tables: [],
    free_tables: [],
    all_cancellations: [],
    all_hub_cancellations: [],
    pending_hub_reservations: [],
    reservation_hours: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_RESERVATION:
            return {
                ...state,
                reservations: [],
                hub_reservations: [],
                cancellations: [],
                numbers: [],
                tables: [],
                all_cancellations: []
            };
        case GET_PENDING_RESERVATIONS:
            localStorage.setItem('pndgrsvts', JSON.stringify(action.payload));
            return {
                ...state,
                reservations: action.payload
            };
        case PENDING_RESERVATIONS_LOCALY_LOADED:
            return {
                ...state,
                reservations: JSON.parse(localStorage.getItem('pndgrsvts'))
            };
        case GET_BOOKED_RESERVATIONS:
            localStorage.setItem('bkrsvts', JSON.stringify(action.payload));
            return {
                ...state,
                reservations: action.payload
            };
        // case EMPTY_RESERVATION_TABLE:
        //     return {
        //         ...state,
        //         reservations: action.payload
        //     };
        case BOOKED_RESERVATIONS_LOCALY_LOADED:
            return {
                ...state,
                reservations: JSON.parse(localStorage.getItem('bkrsvts'))
            };
        case GET_ALL_CANCELLED_RESERVATIONS:
            localStorage.setItem('aclrsvts', JSON.stringify(action.payload));
            return {
                ...state,
                all_cancellations: action.payload
            };
        case CANCELLED_RESERVATIONS_LOCALY_LOADED:
            return {
                ...state,
                all_cancellations: JSON.parse(localStorage.getItem('aclrsvts'))
            };
        case GET_PENDING_HUB_RESERVATIONS:
            localStorage.setItem('pdhbrsvts', JSON.stringify(action.payload));
            return {
                ...state,
                pending_hub_reservations: action.payload
            };
        case PENDING_HUB_RESERVATIONS_LOCALY_LOADED:
            return {
                ...state,
                pending_hub_reservations: JSON.parse(localStorage.getItem('pdhbrsvts'))
            };
        case GET_DATES_BOOKED_RESERVATIONS:
            return {
                ...state,
                reservations: action.payload
            };
        case GET_DATES_BOOKED_HUB_RESERVATIONS:
            return {
                ...state,
                hub_reservations: action.payload
            };
        case GET_DATES_BOOKED_RESERVATIONS_CANCELLED:
            return {
                ...state,
                cancellations: action.payload
            };
        case ANSWER_RESERVATION:
            return {
                ...state,
                reservations: state.reservations.filter(reservation => reservation !== action.payload)
            };
        case GET_HUB_FREE_TABLES:
            return {
                ...state,
                tables: action.payload
            };
        case INCREASE_TABLE_PERSONS:
            return {
                ...state,
                tables: state.tables.map(table => action.payload.find(change => change.id === table.id) || table)

            };
        case DECREASE_TABLE_PERSONS:
            return {
                ...state,
                tables: state.tables.map(table => action.payload.find(change => change.id === table.id) || table)
            };
        case SET_TABLE_PERSONS:
            const updated_table = [action.payload]
            return {
                ...state,
                tables: state.tables.map(table => updated_table.find(change => change.id === table.id) || table)
            };
        case GET_HUB_ALL_FREE_TABLES:
            return {
                ...state,
                free_tables: action.payload
            };
        // case SET_TABLE_STATUS:
        //     return {
        //         ...state,
        //         tables: state.tables.map(table => action.payload.find(change => change.id === table.id) || table)
        //     };
        case INCREASE_RESERVATION_ARRIVED_PERSONS:
            return {
                ...state,
                reservations: state.reservations.map(reservation => action.payload.find(change => change.id === reservation.id) || reservation)
            };
        case DECREASE_RESERVATION_ARRIVED_PERSONS:
            return {
                ...state,
                reservations: state.reservations.map(reservation => action.payload.find(change => change.id === reservation.id) || reservation)
            };

        case INCREASE_HUB_RESERVATION_ARRIVED_PERSONS:
            return {
                ...state,
                hub_reservations: state.hub_reservations.map(hub_reservation => action.payload.find(change => change.id === hub_reservation.id) || hub_reservation)
            };
        case DECREASE_HUB_RESERVATION_ARRIVED_PERSONS:
            return {
                ...state,
                hub_reservations: state.hub_reservations.map(hub_reservation => action.payload.find(change => change.id === hub_reservation.id) || hub_reservation)
            };
        case CANCEL_HUB_RESERVATION:
            const cancelled_hub_reservation = [action.payload]
            return {
                ...state,
                // hub_reservations: state.hub_reservations.map(hub_reservation => action.payload.find(change => change.id === hub_reservation.id) || hub_reservation)
                hub_reservations: state.hub_reservations.map(reservation => cancelled_hub_reservation.find(change => change.id === reservation.id) || reservation)
            };
        case USER_CANCEL_HUB_RESERVATION:
            const cancelled_reservation = [action.payload]
            return {
                ...state,
                hub_reservations: state.hub_reservations.map(reservation => cancelled_reservation.find(change => change.id === reservation.id) || reservation)
            };


        case ACCEPT_HUB_RESERVATION:
            const accepted_reservation = [action.payload]
            return {
                ...state,
                pending_hub_reservations: state.pending_hub_reservations.map(hub_reservation => accepted_reservation.find(change => change.id === hub_reservation.id) || hub_reservation),
                hub_reservations: state.hub_reservations.map(hub_reservation => accepted_reservation.find(change => change.id === hub_reservation.id) || hub_reservation),
            };

        case DECLINE_HUB_RESERVATION:
            const declined_reservation = [action.payload]
            return {
                ...state,
                pending_hub_reservations: state.pending_hub_reservations.map(hub_reservation => declined_reservation.find(change => change.id === hub_reservation.id) || hub_reservation)
            };

        case UPDATE_HUB_RESERVATION_NOTE:
            const new_hub_reservation = [action.payload]
            return {
                ...state,
                hub_reservations: state.hub_reservations.map(hub_reservation => new_hub_reservation.find(change => change.id === hub_reservation.id) || hub_reservation)
            };


        case USER_CREATE_NEW_RESERVATION:
            return {
                ...state,
                hub_reservations: [action.payload, ...state.hub_reservations]
            };

        case GET_USER_HUB_RESERVATIONS:
            localStorage.setItem('urhrsrtns', JSON.stringify(action.payload));
            return {
                ...state,
                hub_reservations: action.payload
            };
        case USER_HUB_RESERVATIONS_LOCALY_LOADED:
            return {
                ...state,
                hub_reservations: JSON.parse(localStorage.getItem('urhrsrtns'))
            };
        case GET_HUB_RESERVATION_SHARES:
            return {
                ...state,
                hub_reservation_shares: action.payload
            };
        case GET_ALL_HUB_RESERVATIONS_CANCELLED:
            localStorage.setItem('ahrsvscnltns', JSON.stringify(action.payload));
            return {
                ...state,
                all_hub_cancellations: action.payload
            };
        case ALL_HUB_RESERVATIONS_CANCELLED_LOCALY_LOADED:
            return {
                ...state,
                all_hub_cancellations: JSON.parse(localStorage.getItem('ahrsvscnltns'))
            };

        case GET_DATES_RESERVATION_HOURS:
            return {
                ...state,
                reservation_hours: action.payload
            };

        default:
            return state;
    }
}