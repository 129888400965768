import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    getHubWorkingDays,
    hubWorkingDayClose,
    setWorkingDayHours,
    uploadHubLogo,
    uploadHubCoverPhoto,
    uploadHubPromoPhoto,
    setHubName,
    setHubCategory,
    setHubMapIcon,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
    setHubReservations,
    setHubAppointments,
    setHubOnlineShop,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    selectHubReservationHours,
    selectHubAppointmentHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
} from '../../../store/actions/hub'
import { acceptHubsTerms } from '../../../store/actions/auth'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';

import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import moment from "moment";
import hub from '../../../store/reducers/hub';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class SetupPage extends Component {

    state = {
        finishedCategoryName: false,
        category: "",
        map_icon: "",
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitHubCategory() {
        this.setState({
            finishedCategoryName: true,
        })
        const category = this.state.category
        if (category !== "") {
            const id = this.props.hub[0].id
            const place_type = this.props.hub[0].place_type
            const hub = { id, category, place_type }
            this.props.setHubCategory(hub)
        }
    }

    selectCategory(category) {
        this.setState({
            map_icon: category
        })
    }

    submitHubMapIcon() {
        const map_icon = this.state.map_icon
        if (map_icon !== "") {
            const id = this.props.hub[0].id
            const hub = { id, map_icon }
            this.props.setHubMapIcon(hub)
        }
        this.setState({
            redirect: true,
        })
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/information' }} />
        }
        let categories = []
        let hub_category = ""
        let hub_icon = ""
        const hub = this.props.hub

        if (hub.length > 0) {
            if (hub[0].place_type === "Hospitality") {
                categories = [
                    "restaurant",
                    "coffee shop",
                    "lodging",
                    "bar",
                    "night club",
                    // "cafe",
                    "food",
                    // 'drinks',
                    'casino',
                    "meal takeaway"]
            }
            if (hub[0].place_type === "Shops") {
                categories = [
                    "clothing store",
                    "electronics store",
                    "shoe store",
                    "supermarket",
                    "jewelry store",
                    "liquor store",
                    "pet store",
                    "shopping mall",
                    "convenience store",
                    "gas station",
                    "home goods store",
                    "hardware store",
                    "pharmacy",
                    "florist",
                    "furniture store",
                    "bicycle store",
                    "book store",
                    "car dealer",
                    "bakery"]
            }
            if (hub[0].place_type === "Services") {
                categories = [
                    "beauty salon",
                    "hair care",
                    "spa",
                    "doctor",
                    "dentist",
                    "lawyer",
                    "electrician",
                    "accounting",
                    "bank",
                    "gym",
                    "veterinary care",
                    // "movie rental", 
                    // "meal delivery", 
                    "insurance agency",
                    "travel agency",
                    "parking",
                    "painter",
                    "car rental",
                    "physiotherapist",
                    // "roofing contractor", 
                    "real estate agency",
                    "plumber",
                    "car repair",
                    "car wash",
                    // "embassy", 
                    // "local government office", 
                    "laundry",
                    "locksmith"]
            }
            if (hub[0].place_type === "Other") {
                categories = [
                    "cinema",
                    "minifootball",
                    "sports",
                    "bowling alley",
                    "art gallery",
                    "museum",
                    "aquarium",
                    "amusement park",
                    "zoo",
                    "campground",
                    "library",
                    "rv park",
                    // "tourist attraction",
                    "university"]
            }

            hub_category = hub[0].category
            hub_icon = hub[0].map_icon

            if (this.state.category !== "") {
                hub_category = this.state.category
            }

            if (this.state.map_icon !== "") {
                hub_icon = this.state.map_icon
            }
        }

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const categorySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        return (
            <Fragment>

                <>
                    {this.props.hub.map(hub => (
                        <>
                            <HubDesktopNavbar data={{ sellected: "" }} />
                            <div>
                                <div class="whole-setup-page-page-layout">
                                    <OfflineLabel />

                                    <>

                                        <div class="setup-page-welcome-title">
                                            <Link to="/hub-homepage" class="setup-page-back-box">
                                                <div class="setup-page-back-icon-box">
                                                    <img
                                                        class="setup-page-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </Link>
                                            Set Category
                                        </div>

                                        <>
                                            {this.state.finishedCategoryName ?
                                                <>
                                                    <div class="setup-page-map_iconslider">
                                                        <Slider {...categorySettings} >
                                                            {categories.map(category => (
                                                                <>
                                                                    {hub_icon === category ?
                                                                        <div
                                                                            class="setup-page-map_iconframe"
                                                                            onClick={() => this.selectCategory(category)}
                                                                        >
                                                                            <>
                                                                                {category === "restaurant" ?
                                                                                    <img
                                                                                        class="setup-page-map_icon-img"
                                                                                        src={require("../../../assets/map/svg/restaurant-blue.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    : category === "coffee shop" ?
                                                                                        <img
                                                                                            class="setup-page-map_icon-img"
                                                                                            src={require("../../../assets/map/svg/cafe-blue.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                        : category === "bar" ?
                                                                                            <img
                                                                                                class="setup-page-map_icon-img"
                                                                                                src={require("../../../assets/map/svg/bar-blue.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                            : category === "night club" ?
                                                                                                <img
                                                                                                    class="setup-page-map_icon-img"
                                                                                                    src={require("../../../assets/map/svg/club-blue.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                                : category === "food" ?
                                                                                                    <img
                                                                                                        class="setup-page-map_icon-img"
                                                                                                        src={require("../../../assets/map/svg/food-blue.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    : category === "casino" ?
                                                                                                        <img
                                                                                                            class="setup-page-map_icon-img"
                                                                                                            src={require("../../../assets/map/svg/casino-blue.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        : category === "meal takeaway" ?
                                                                                                            <img
                                                                                                                class="setup-page-map_icon-img"
                                                                                                                src={require("../../../assets/map/svg/takeaway-blue.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                            : category === "clothing store" ?
                                                                                                                <img
                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                    src={require("../../../assets/map/svg/clothestore-blue.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                : category === "electronics store" ?
                                                                                                                    <img
                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                        src={require("../../../assets/map/svg/electronicsstore-blue.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "shoe store" ?
                                                                                                                        <img
                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                            src={require("../../../assets/map/svg/shoestore-blue.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "supermarket" ?

                                                                                                                            <img
                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                src={require("../../../assets/map/svg/supermarket-blue.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "jewelry store" ?
                                                                                                                                <img
                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/jewelry-blue.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "liquor store" ?
                                                                                                                                    <img
                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/liquorstore-blue.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "pet store" ?
                                                                                                                                        <img
                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/petstore-blue.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "shopping mall" ?
                                                                                                                                            <img
                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/mall-blue.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "convenience store" ?
                                                                                                                                                <img
                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/conviniencestore-blue.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "gas station" ?
                                                                                                                                                    <img
                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/gasstation-blue.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "home goods store" ?

                                                                                                                                                        <img
                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/homegoodsstore-blue.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "hardware store" ?
                                                                                                                                                            <img
                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/hardwarestore-blue.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "pharmacy" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/pharmacy-blue.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "florist" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/florist-blue.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "furniture store" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/furniturestore-blue.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "bicycle store" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/bicyclestore-blue.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "book store" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/bookshop-blue.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "car dealer" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/cardealer-blue.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "bakery" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/bakery-blue.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "beauty salon" ?
                                                                                                                                                                                            <img
                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/beautysalon-blue.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "hair care" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/hairsalon-blue.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "spa" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/spa-blue.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "doctor" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/doctor-blue.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "dentist" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/dentist-blue.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "lawyer" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lawyer-blue.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "electrician" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/electrician-blue.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "accounting" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/accountant-blue.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "laundry" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/laundry-blue.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "bank" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/bank-blue.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "gym" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/gym-blue.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "veterinary care" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/veterany-blue.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "insurance agency" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/insurance-blue.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "travel agency" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/travel-blue.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "parking" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/parking-blue.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "painter" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/painter-blue.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "car rental" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rentcar-blue.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "physiotherapist" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/physiotherapist-blue.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "real estate agency" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/realestateagent-blue.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    : category === "plumber" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/plumber-blue.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "car repair" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/carrepair-blue.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "car wash" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carwash-blue.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                : category === "locksmith" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/locksmith-blue.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "cinema" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/cinema-blue.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "lodging" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/lodging-blue.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "minifootball" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/minifootball-blue.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "sports" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/sports-blue.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/bowling-blue.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/artgallery-blue.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "museum" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/museum-blue.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/aquarium-blue.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/amusementpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/zoo-blue.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "campground" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/campground-blue.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                : category === "library" ?
                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/library-blue.svg")}
                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                    : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/rvpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                        : category === "university" ?
                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/university-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                            />

                                                                                                                                                                                                                                                                                                                                            :
                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/place-blue.svg")}
                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                            />
                                                                                }


                                                                            </>
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            class="setup-page-map_iconframe"
                                                                            onClick={() => this.selectCategory(category)}
                                                                        >
                                                                            <>
                                                                                {category === "restaurant" ?
                                                                                    <img
                                                                                        class="setup-page-map_icon-img"
                                                                                        src={require("../../../assets/map/svg/restaurant-red.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    : category === "coffee shop" ?
                                                                                        <img
                                                                                            class="setup-page-map_icon-img"
                                                                                            src={require("../../../assets/map/svg/cafe-red.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                        : category === "bar" ?
                                                                                            <img
                                                                                                class="setup-page-map_icon-img"
                                                                                                src={require("../../../assets/map/svg/bar-red.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                            : category === "night club" ?
                                                                                                <img
                                                                                                    class="setup-page-map_icon-img"
                                                                                                    src={require("../../../assets/map/svg/club-red.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                                : category === "food" ?
                                                                                                    <img
                                                                                                        class="setup-page-map_icon-img"
                                                                                                        src={require("../../../assets/map/svg/food-red.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    : category === "casino" ?
                                                                                                        <img
                                                                                                            class="setup-page-map_icon-img"
                                                                                                            src={require("../../../assets/map/svg/casino-red.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        : category === "meal takeaway" ?
                                                                                                            <img
                                                                                                                class="setup-page-map_icon-img"
                                                                                                                src={require("../../../assets/map/svg/takeaway-red.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                            : category === "clothing store" ?
                                                                                                                <img
                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                    src={require("../../../assets/map/svg/clothestore-red.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                : category === "electronics store" ?
                                                                                                                    <img
                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                        src={require("../../../assets/map/svg/electronicsstore-red.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "shoe store" ?
                                                                                                                        <img
                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                            src={require("../../../assets/map/svg/shoestore-red.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "supermarket" ?

                                                                                                                            <img
                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                src={require("../../../assets/map/svg/supermarket-red.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "jewelry store" ?
                                                                                                                                <img
                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/jewelry-red.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "liquor store" ?
                                                                                                                                    <img
                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/liquorstore-red.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "pet store" ?
                                                                                                                                        <img
                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/petstore-red.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "shopping mall" ?
                                                                                                                                            <img
                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/mall-red.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "convenience store" ?
                                                                                                                                                <img
                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/conviniencestore-red.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "gas station" ?
                                                                                                                                                    <img
                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/gasstation-red.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "home goods store" ?

                                                                                                                                                        <img
                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/homegoodsstore-red.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "hardware store" ?
                                                                                                                                                            <img
                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/hardwarestore-red.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "pharmacy" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/pharmacy-red.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "florist" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/florist-red.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "furniture store" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/furniturestore-red.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "bicycle store" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/bicyclestore-red.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "book store" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/bookshop-red.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "car dealer" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/cardealer-red.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "bakery" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/bakery-red.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "beauty salon" ?
                                                                                                                                                                                            <img
                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/beautysalon-red.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "hair care" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/hairsalon-red.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "spa" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/spa-red.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "doctor" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/doctor-red.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "dentist" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/dentist-red.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "lawyer" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lawyer-red.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "electrician" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/electrician-red.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "accounting" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/accountant-red.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "laundry" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/laundry-red.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "bank" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/bank-red.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "gym" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/gym-red.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "veterinary care" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/veterany-red.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "insurance agency" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/insurance-red.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "travel agency" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/travel-red.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "parking" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/parking-red.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "painter" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/painter-red.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "car rental" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rentcar-red.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "physiotherapist" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/physiotherapist-red.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "real estate agency" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/realestateagent-red.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    : category === "plumber" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/plumber-red.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "car repair" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/carrepair-red.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "car wash" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carwash-red.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                : category === "locksmith" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/locksmith-red.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "cinema" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/cinema-red.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "lodging" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/lodging-red.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "minifootball" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/minifootball-red.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "sports" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/sports-red.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/bowling-red.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/artgallery-red.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "museum" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/museum-red.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/aquarium-red.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/amusementpark-red.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/zoo-red.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "campground" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/campground-red.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                : category === "library" ?
                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/library-red.svg")}
                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                                    : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/rvpark-red.svg")}
                                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                                        : category === "university" ?
                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/university-red.svg")}
                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                            />

                                                                                                                                                                                                                                                                                                                                            :
                                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/place-red.svg")}
                                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                                            />
                                                                                }


                                                                            </>
                                                                        </div>
                                                                    }
                                                                    <div class="setup-page-map_iconname">
                                                                        {category}
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </Slider>
                                                    </div>
                                                    <div onClick={() => this.submitHubMapIcon(false)} class="setup-page-info-done">
                                                        Done
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div class="setup-page-info-text">
                                                        Type the category of your place.
                                                    </div>

                                                    <div class="setup-page-info-input-box">
                                                        <input
                                                            class='setup-page-info-input-field'
                                                            name="category"
                                                            onChange={this.onChange}
                                                            placeholder="Category..."
                                                            value={hub_category}
                                                        />
                                                    </div>
                                                    <div onClick={() => this.submitHubCategory(false)} class="setup-page-info-done">
                                                        Done
                                                    </div>
                                                </>
                                            }
                                        </>

                                    </>
                                </div>
                            </div>

                            <Link to="/information"
                                //  onClick={() => this.acceptHubsTerms()} 
                                class="setup-page-next-btn">
                                Done
                            </Link>


                        </>
                    ))}
                </>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    profile: state.profile.profile,
    working_days: state.hub.working_days,
    catalogs: state.hub.catalogs,
    new_catalog: state.hub.new_catalog,
    menu_pages: state.hub.menu_pages,
    reservation_hours: state.hub.reservation_hours,
    appointment_hours: state.hub.appointment_hours,

});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    getHubWorkingDays,
    acceptHubsTerms,
    hubWorkingDayClose,
    setWorkingDayHours,
    uploadHubLogo,
    uploadHubCoverPhoto,
    uploadHubPromoPhoto,
    setHubName,
    setHubCategory,
    setHubMapIcon,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
    setHubReservations,
    setHubAppointments,
    setHubOnlineShop,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    selectHubReservationHours,
    selectHubAppointmentHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
})(SetupPage);