import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';



export class SnapMemory extends Component {

    state = {
        new_memory: "",
        memory_photo: null,
        memory_submited: false,
    }


    snapMemorySubmit = (e, id) => {
        e.preventDefault();
        this.setState({
            new_memory: e.target.files[0],
            memory_photo: URL.createObjectURL(e.target.files[0]),
            memory_submited: true
        })
    };

    render() {
        if (this.state.memory_submited) {
            return <Redirect to={{ pathname: '/post-memory', state: { new_memory: this.state.new_memory, memory_photo: this.state.memory_photo, hub: "", lat: "", lng: "", name: "" } }} />
        }
        return (
            <Fragment>
                <div
                    onTouchStart
                    onClick={() => this.fileInput.click()}
                    class={this.props.data.ambassandor ? "profile-top-two-btns-left" : "profile-top-two-btns-full"}>
                    Post Memory
                </div>
                <input
                    style={{ display: 'none' }}
                    accept="image/png, image/jpeg"
                    type="file"
                    name="memory_photo"
                    onChange={
                        this.snapMemorySubmit
                    }
                    ref={fileInput => this.fileInput = fileInput}
                />
                {/* <div class="profile-snap-memory-layout">
                    <div class="profile-share-memory-text">
                        Share Memory
                    </div>
                    <img
                        onClick={() => this.fileInput.click()}
                        class="profile-snap-memory-size"
                        src={require("../../../assets/profile/svg/snap-memory.svg")}
                        alt=""
                    />

                    <input
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                        type="file"
                        name="memory_photo"
                        onChange={
                            this.snapMemorySubmit
                        }
                        ref={fileInput => this.fileInput = fileInput}
                    />
                </div> */}
            </Fragment >
        )
    }
}

export default (SnapMemory);