import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    uploadHubPromoPhoto,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';



export class SetPromoPhoto extends Component {

    state = {
        promo_photo: "",
        promo_photo_file: null,
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onPromoPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            promo_photo: URL.createObjectURL(e.target.files[0]),
            promo_photo_file: e.target.files[0]
        })
        setTimeout(() => {
            this.updatePromoPhoto()
        }, 4000);
    };

    updatePromoPhoto = () => {
        const id = this.props.hub[0].id
        const photo = this.state.promo_photo_file
        const hub = { id, photo }
        this.props.uploadHubPromoPhoto(hub)
        // hub.cover_photo = new_cover_photo
        // hub.logo = ""
        // hub.promo_photo = ""
        // this.props.updateHub(hub)
    }


    render() {

        const hub = this.props.hub
        let promo_photo = ""
        if (hub.length > 0) {
            promo_photo = hub[0].promo_photo
        }

        if (this.state.promo_photo !== "") {
            promo_photo = this.state.promo_photo
        }

        return (
            <Fragment>

                {this.props.hub.map(hub => (
                    <>
                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />

                                <>

                                    <div class="setup-page-welcome-title">
                                        <Link to="/hub-homepage" class="setup-page-back-box">
                                            <div class="setup-page-back-icon-box">
                                                <img
                                                    class="setup-page-back-icon"
                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        Setup Promo Photo
                                    </div>

                                    <>
                                        <div className='setup-page-hubs-swipe-box' >

                                            <div class="setup-page-hubs-swipe-card">
                                                <div
                                                    // onClick={() => this.promoInput.click()}
                                                    style={{ backgroundImage: 'url(' + promo_photo + ')' }}
                                                    class='setup-page-hubs-swipe-square'
                                                >

                                                    <div onClick={() => this.promoInput.click()} class="setup-page-set-promo-photo">
                                                        Set Photo
                                                    </div>

                                                    <input
                                                        style={{ display: 'none' }}
                                                        accept="image/png, image/jpeg"
                                                        type="file"
                                                        name="photo"
                                                        onChange={this.onPromoPhotoSubmit}
                                                        ref={promoInput => this.promoInput = promoInput}
                                                    />

                                                    {/* <iframe
                                                                                        src={hub.map_url}
                                                                                        class="setup-page-hubs-map-frame"
                                                                                        aria-hidden="false"
                                                                                        tabindex="0"
                                                                                    /> */}

                                                    <div class="setup-page-hubs-logo-box">
                                                        <img
                                                            class="setup-page-hubs-logo-img"
                                                            src={hub.logo}
                                                            alt=""
                                                        />
                                                    </div>


                                                    <div class="setup-page-hubs-username">
                                                        {hub.name}
                                                    </div>

                                                    <div class="setup-page-hubs-black-cover-box"> </div>


                                                    <div class="setup-page-hubs-memories-box-empty">
                                                        <div class="row ">
                                                            <div class="setup-page-hubs-empty-photos-box">
                                                                <img
                                                                    class="setup-page-hubs-empty-photos-icon"
                                                                    src={require("../../../assets/layout/homepage svg/no-post.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div class="setup-page-hubs-no-memories-yet">
                                                                No Memories yet
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                </>
                            </div>
                        </div>

                        <Link to="/information"
                            //  onClick={() => this.acceptHubsTerms()} 
                            class="setup-page-next-btn">
                            Done
                        </Link>
                    </>
                ))}

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    uploadHubPromoPhoto,
})(SetPromoPhoto);