import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';


export class SetSocialMedia extends Component {

    state = {

        wifiAnswer: false,
        telephoneAnswer: false,
        websiteAnswer: false,
        facebookAnswer: false,
        instagramAnswer: false,

        wifiMiniSection: true,
        wifi_code: "",
        telephoneMiniSection: true,
        telephone: "",
        has_telephone: false,
        websiteMiniSection: true,
        website: "",
        has_website: false,
        facebookMiniSection: true,
        facebook: "",
        has_facebook: false,
        instagramMiniSection: true,
        instagram: "",
        has_instagram: false,
    };


    componentWillMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

    finishInstagramSection() {
        const id = this.props.hub[0].id
        const has_instagram = this.state.has_instagram
        const instagram_url = ""
        const hub = { id, has_instagram, instagram_url }
        this.props.setHubInstagram(hub)
        this.setState({
            redirect: true
        })
    }

    answerWifiMiniSection() {
        this.setState({
            wifiAnswer: true,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedWifiMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: true,
            websiteMiniSection: false,
            facebookMiniSection: false,
            instagramMiniSection: false,
        })
        const wifi_code = this.state.wifi_code
        if (wifi_code !== this.props.hub[0].wifi_code && this.state.wifi_code !== "") {
            const id = this.props.hub[0].id
            const hub = { id, wifi_code }
            this.props.setHubWifiCode(hub)
        }

    }

    finishedTelephoneMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: true,
            facebookMiniSection: false,
            instagramMiniSection: false,
        })
        if (this.state.has_telephone && this.state.telephone !== "") {
            const id = this.props.hub[0].id
            const has_telephone = this.state.has_telephone
            const telephone = this.state.telephone
            const hub = { id, has_telephone, telephone }
            this.props.setHubTelephone(hub)
        }
        if (!this.state.has_telephone) {
            const id = this.props.hub[0].id
            const has_telephone = this.state.has_telephone
            const telephone = ""
            const hub = { id, has_telephone, telephone }
            this.props.setHubTelephone(hub)
        }
    }

    answerTelephoneMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: true,
            has_telephone: true,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedWebsiteMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: false,
            facebookMiniSection: true,
            instagramMiniSection: false,
        })
        if (this.state.has_website && this.state.website !== "") {
            const id = this.props.hub[0].id
            const has_website = this.state.has_website
            const website = this.state.website
            const hub = { id, has_website, website }
            this.props.setHubWebsite(hub)
        }
        if (!this.state.has_website) {
            const id = this.props.hub[0].id
            const has_website = this.state.has_website
            const website = ""
            const hub = { id, has_website, website }
            this.props.setHubWebsite(hub)
        }
    }

    answerWebsiteMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: true,
            has_website: true,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedFacebookMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: false,
            facebookMiniSection: false,
            instagramMiniSection: true,
        })
        if (this.state.has_facebook && this.state.facebook !== "") {
            const id = this.props.hub[0].id
            const has_facebook = this.state.has_facebook
            const facebook_url = this.state.facebook
            const hub = { id, has_facebook, facebook_url }
            this.props.setHubFacebook(hub)
        }
        if (!this.state.has_telephone) {
            const id = this.props.hub[0].id
            const has_facebook = this.state.has_facebook
            const facebook_url = ""
            const hub = { id, has_facebook, facebook_url }
            this.props.setHubFacebook(hub)
        }
    }

    answerFacebookMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: true,
            has_facebook: true,
            instagramAnswer: false,
        })
    }

    answerInstagramMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: true,
            has_instagram: true,
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/information' }} />
        }

        const defaultOptions = {
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        const screen_width = window.screen.width


        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const tagSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const categorySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const catalogs = this.props.catalogs
        return (
            <Fragment>

                {this.props.hub.map(hub => (
                    <>
                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />

                                <>

                                    <div class="setup-page-welcome-title">
                                        <Link to="/hub-homepage" class="setup-page-back-box">
                                            <div class="setup-page-back-icon-box">
                                                <img
                                                    class="setup-page-back-icon"
                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        Set Social Media
                                    </div>


                                    <>
                                        {this.state.wifiMiniSection ?
                                            <>
                                                {!this.state.wifiAnswer ?
                                                    <>
                                                        <div class="setup-page-reservation-question-text">
                                                            Do you have Wifi Code?
                                                        </div>

                                                        <div class="setup-page-reservation-question-box">
                                                            <div onClick={() => this.finishedWifiMiniSection(false)} class="setup-page-reservation-question-no">
                                                                No
                                                            </div>
                                                            <div onClick={() => this.answerWifiMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                Yes
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div class="setup-page-info-text">
                                                            Type your Wifi Code.
                                                        </div>

                                                        <div class="setup-page-info-input-box">
                                                            <input
                                                                class='setup-page-info-input-field'
                                                                name="wifi_code"
                                                                onChange={this.onChange}
                                                                placeholder="Wifi Code..."
                                                                value={this.state.wifi_code === "" ? hub.wifi_code : this.state.wifi_code}
                                                            />
                                                        </div>
                                                        <div onClick={() => this.finishedWifiMiniSection(false)} class="setup-page-info-done">
                                                            Done
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            : this.state.telephoneMiniSection ?
                                                <>
                                                    {!this.state.telephoneAnswer ?
                                                        <>
                                                            <div class="setup-page-reservation-question-text">
                                                                Do you have a contacting Phone?
                                                            </div>

                                                            <div class="setup-page-reservation-question-box">
                                                                <div onClick={() => this.finishedTelephoneMiniSection(false)} class="setup-page-reservation-question-no">
                                                                    No
                                                                </div>
                                                                <div onClick={() => this.answerTelephoneMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                    Yes
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div class="setup-page-info-text">
                                                                Type your Telephone Number.
                                                            </div>

                                                            <div class="setup-page-info-input-box">
                                                                <input
                                                                    class='setup-page-info-input-field'
                                                                    name="telephone"
                                                                    onChange={this.onChange}
                                                                    placeholder="Telephone Number..."
                                                                    value={this.state.telephone === "" ? hub.telephone : this.state.telephone}
                                                                />
                                                            </div>
                                                            <div onClick={() => this.finishedTelephoneMiniSection(false)} class="setup-page-info-done">
                                                                Done
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                                : this.state.websiteMiniSection ?
                                                    <>
                                                        {!this.state.websiteAnswer ?
                                                            <>
                                                                <div class="setup-page-reservation-question-text">
                                                                    Do you have a Website?
                                                                </div>

                                                                <div class="setup-page-reservation-question-box">
                                                                    <div onClick={() => this.finishedWebsiteMiniSection(false)} class="setup-page-reservation-question-no">
                                                                        No
                                                                    </div>
                                                                    <div onClick={() => this.answerWebsiteMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                        Yes
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div class="setup-page-info-text">
                                                                    Type your Website's URL.
                                                                </div>

                                                                <div class="setup-page-info-input-box">
                                                                    <input
                                                                        class='setup-page-info-input-field'
                                                                        name="website"
                                                                        onChange={this.onChange}
                                                                        placeholder="Example: https://hubst3r.com"
                                                                        value={this.state.website === "" ? hub.website : this.state.website}
                                                                    />
                                                                </div>
                                                                <div onClick={() => this.finishedWebsiteMiniSection(false)} class="setup-page-info-done">
                                                                    Done
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                    : this.state.facebookMiniSection ?
                                                        <>
                                                            {!this.state.facebookAnswer ?
                                                                <>
                                                                    <div class="setup-page-reservation-question-text">
                                                                        Do you have a Facebook account?
                                                                    </div>

                                                                    <div class="setup-page-reservation-question-box">
                                                                        <div onClick={() => this.finishedFacebookMiniSection(false)} class="setup-page-reservation-question-no">
                                                                            No
                                                                        </div>
                                                                        <div onClick={() => this.answerFacebookMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                            Yes
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div class="setup-page-info-text">
                                                                        Type your Facebook's Account URL.
                                                                    </div>

                                                                    <div class="setup-page-info-input-box">
                                                                        <input
                                                                            class='setup-page-info-input-field'
                                                                            name="facebook"
                                                                            onChange={this.onChange}
                                                                            placeholder="Example: https://facebook.com/hubst3r"
                                                                            value={this.state.facebook === "" ? hub.facebook_url : this.state.facebook}
                                                                        />
                                                                    </div>
                                                                    <div onClick={() => this.finishedFacebookMiniSection(false)} class="setup-page-info-done">
                                                                        Done
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                        : this.state.instagramMiniSection ?
                                                            <>
                                                                {!this.state.instagramAnswer ?
                                                                    <>
                                                                        <div class="setup-page-reservation-question-text">
                                                                            Do you have an Instagram account?
                                                                        </div>

                                                                        <div class="setup-page-reservation-question-box">
                                                                            <div onClick={() => this.finishInstagramSection(false)} class="setup-page-reservation-question-no">
                                                                                No
                                                                            </div>
                                                                            <div onClick={() => this.answerInstagramMiniSection(true)} class="setup-page-reservation-question-yes">
                                                                                Yes
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div class="setup-page-info-text">
                                                                            Type your Instagram's Account URL.
                                                                        </div>

                                                                        <div class="setup-page-info-input-box">
                                                                            <input
                                                                                class='setup-page-info-input-field'
                                                                                name="instagram"
                                                                                onChange={this.onChange}
                                                                                placeholder="Example: https://instagram.com/hubst3r"
                                                                                value={this.state.instagram === "" ? hub.instagram_url : this.state.instagram}
                                                                            />
                                                                        </div>
                                                                        <div onClick={() => this.finishInstagramSection(false)} class="setup-page-info-done">
                                                                            Done
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                            : null
                                        }

                                    </>

                                </>
                            </div>
                        </div>

                        <Link to="/information"
                            //  onClick={() => this.acceptHubsTerms()} 
                            class="setup-page-next-btn">
                            Done
                        </Link>


                    </>
                ))}

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
})(SetSocialMedia);