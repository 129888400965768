import axios from 'axios';
import { returnErrors, createAlert } from './alerts';
import {
    GET_USER_PROFILE,
    GET_OTHER_USER_PROFILE,
    USER_PROFILE_LOCALY_LOADED,
    GET_PROFILE_HUBS_ACCESS,
    GET_PROFILE_BUDDIES,
    GET_PROFILE_BUDDIES_LOCALLY_LOADED,
    GET_PROFILE_HUB_LIST,
    GET_PROFILE_HUB_LIST_LOCALLY_LOADED,
    GET_PROFILE_GAGS,
    GET_PROFILE_GAGS_LOCALY_LOADED,
    UPDATE_PROFILE,
    UPDATE_PROFILE_PICTURE,
    UPDATE_PROFILE_FULLNAME,
    SWITCH_TO_HUB_ACCOUNT,
    SWITCH_TO_PROFILE_ACCOUNT,
    SCAN_QR_CODE,
    LOADING_PAGE,
    GET_ADD_RESERVATION_PROFILE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION
} from './actionTypes';
import { tokenConfig } from './auth';


export const getMyProfile = () => (dispatch) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/my-profile/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_USER_PROFILE,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: USER_PROFILE_LOCALY_LOADED,
                });

            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getProfileHubsAccess = () => (dispatch) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/my-profile-hubs-access/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PROFILE_HUBS_ACCESS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getUserProfile = (id) => (dispatch) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${id}/user-profile/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_OTHER_USER_PROFILE,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) })
}


export const getProfileBuddies = () => (dispatch) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user-buddies/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PROFILE_BUDDIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: GET_PROFILE_BUDDIES_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const getProfileHubList = (id) => (dispatch) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${id}/user-hubs/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PROFILE_HUB_LIST,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: GET_PROFILE_HUB_LIST_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const getProfileGags = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user-gags/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_PROFILE_GAGS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: GET_PROFILE_GAGS_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const updateProfile = updated_profile => (dispatch, getstate) => {
    const profileData = new FormData();
    profileData.append("profile_picture", updated_profile.profile_picture);
    profileData.append("username", updated_profile.username);
    profileData.append("full_name", updated_profile.full_name);
    profileData.append("bio", updated_profile.bio);
    profileData.append("genter", updated_profile.genter);
    // profileData.append("birthday", updated_profile.birthday);
    profileData.append("accept_email_notifications", updated_profile.accept_email_notifications);
    profileData.append("appear_memory_suggestions", updated_profile.appear_memory_suggestions);
    // profileData.append("email", updated_profile.email);
    profileData.append("city", updated_profile.city);
    profileData.append("country", updated_profile.country);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/update-my-profile/`, profileData, tokenConfig())
        .then(res => {
            dispatch(createAlert({ updateProfile: 'Your Profile has been Updated!!' }));
            dispatch({
                type: UPDATE_PROFILE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const scanQrCode = (qr_code) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${qr_code}/qr-code-scan/`, tokenConfig())
        .then(res => {
            dispatch(createAlert({ qrScanBuddyRequest: 'You are Buddy request has been sent' }));
            dispatch({
                type: SCAN_QR_CODE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getAddReservationProfile = (query) => (dispatch, getstate) => {
    const corrected_query1 = query.replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_query2 = corrected_query1.replace(/\//g, 'k0l0slashgam1m3n01111');
    // const corrected_query2 = corrected_query1.replace(/\_/g, 'kolopavlagamimeni111');

    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/search-user-add-reservation/search/${corrected_query2}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_ADD_RESERVATION_PROFILE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const updateProfilePicture = updated_profile => (dispatch, getstate) => {
    const profileData = new FormData();
    profileData.append("profile_picture", updated_profile.profile_picture);
    profileData.append("username", updated_profile.username);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/update-my-profile-picture/`, profileData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_PROFILE_PICTURE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const updateProfileFullName = updated_profile => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/update-my-profile-fullname/`, updated_profile, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_PROFILE_FULLNAME,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const switchToHubAccount = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/switch-to-hub-account/${hub_id}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: SWITCH_TO_HUB_ACCOUNT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const switchToProfileAccount = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/switch-to-profile-account/`, tokenConfig())
        .then(res => {
            dispatch({
                type: SWITCH_TO_PROFILE_ACCOUNT,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

