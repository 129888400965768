import React, { useState, useRef, useEffect, ReactDOM } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "mapbox-gl/dist/mapbox-gl.css";


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;


function HubPageMap(props) {
    const map = useRef(null);
    const mapContainer = useRef();
    const buttonRef = useRef();
    if (props.data.zoom < 11) {
        for (const place of props.data.hubs) {
            const place_div = document.getElementById(`profile-marker-name-${place.id}`)
            if (place_div) {
                place_div.style.display = "none"
            }
        }
    } else {
        for (const place of props.data.hubs) {
            const place_div = document.getElementById(`profile-marker-name-${place.id}`)
            if (place_div) {
                place_div.style.display = "block"
            }
        }
    }


    useEffect(() => {
        // create the map and configure it
        // check out the API reference for more options
        // https://docs.mapbox.com/mapbox-gl-js/api/map/
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            // style: 'https://api.maptiler.com/maps/streets/style.json?key=get_your_own_OpIi9ZULNHzrESv6T2vL',            // style: "mapbox://styles/mapbox/outdoors-v11",
            style: "mapbox://styles/mapbox/streets-v11",
            // style: "mapbox://styles/mapbox/satellite-v9",
            // style: "mapbox://styles/mapbox/dark-v10",
            // style: "mapbox://styles/mapbox/light-v10",
            //         lat: 33.009168,
            //         lng: 34.688447
            center: [props.data.hubs[0].lng, props.data.hubs[0].lat],
            zoom: 13,
            // pitch: 60,
            // bearing: 80
        });

        let myProfile = {}
        let my_profile_array = []

        for (let i = 0; i < props.data.my_profile.length; i++) {
            const profile = {
                "type": "Feature",
                "properties": {
                    "user": props.data.my_profile[i].user,
                    "profile_picture": props.data.my_profile[i].profile_picture,
                    "lat": props.data.myMapCenterLocation.lat,
                    "lng": props.data.myMapCenterLocation.lng,
                    "id": props.data.my_profile[i].id,
                    "username": props.data.my_profile[i].username,
                },
                "geometry": {
                    "coordinates": [
                        props.data.myMapCenterLocation.lng,
                        props.data.myMapCenterLocation.lat,
                    ],
                    "type": "Point"
                },
                "id": props.data.my_profile[i].id
            }
            my_profile_array.push(profile)
        }

        myProfile = {
            "features": my_profile_array,
            "type": "FeatureCollection"
        }


        let hubs = {}
        let restaurant_hubs = {}
        let cafe_hubs = {}
        let lodging_hubs = {}
        let bar_hubs = {}
        let club_hubs = {}
        let food_hubs = {}
        let casino_hubs = {}
        let takeaway_hubs = {}
        let clothingstore_hubs = {}
        let electronicsstore_hubs = {}
        let shoestore_hubs = {}
        let supermarket_hubs = {}
        let jewelry_hubs = {}
        let liquorstore_hubs = {}
        let petstore_hubs = {}
        let mall_hubs = {}
        let conveniencestore_hubs = {}
        let gasstation_hubs = {}
        let homegoodsstore_hubs = {}
        let hardwarestore_hubs = {}
        let pharmacy_hubs = {}
        let pharmacy_overnight_hubs = {}
        let florist_hubs = {}
        let furniturestore_hubs = {}
        let bicyclestores_hubs = {}
        let bookstore_hubs = {}
        let cardealer_hubs = {}
        let bakery_hubs = {}
        let beautysalon_hubs = {}
        let haircare_hubs = {}
        let spa_hubs = {}
        let doctor_hubs = {}
        let dentist_hubs = {}
        let lawyer_hubs = {}
        let electrician_hubs = {}
        let accounting_hubs = {}
        let bank_hubs = {}
        let gym_hubs = {}
        let veterinary_hubs = {}
        let insuranceagency_hubs = {}
        let travelagency_hubs = {}
        let parking_hubs = {}
        let painter_hubs = {}
        let carrental_hubs = {}
        let physiotherapist_hubs = {}
        let realestateagency_hubs = {}
        let plumber_hubs = {}
        let carrepair_hubs = {}
        let carwash_hubs = {}
        let laundry_hubs = {}
        let locksmith_hubs = {}
        let cinema_hubs = {}
        let minifootball_hubs = {}
        let sports_hubs = {}
        let bowlingalley_hubs = {}
        let artgallery_hubs = {}
        let museum_hubs = {}
        let aquarium_hubs = {}
        let amusementpark_hubs = {}
        let zoo_hubs = {}
        let campground_hubs = {}
        let library_hubs = {}
        let rvpark_hubs = {}
        let university_hubs = {}


        let hubs_array = []
        let restaurant_array = []
        let cafe_array = []
        let lodging_array = []
        let bar_array = []
        let club_array = []
        let food_array = []
        let casino_array = []
        let takeaway_array = []
        let clothingstore_array = []
        let electronicsstore_array = []
        let shoestore_array = []
        let supermarket_array = []
        let jewelry_array = []
        let liquorstore_array = []
        let petstore_array = []
        let mall_array = []
        let conveniencestore_array = []
        let gasstation_array = []
        let homegoodsstore_array = []
        let hardwarestore_array = []
        let pharmacy_array = []
        let pharmacy_overnight_array = []
        let florist_array = []
        let furniturestore_array = []
        let bicyclestores_array = []
        let bookstore_array = []
        let cardealer_array = []
        let bakery_array = []
        let beautysalon_array = []
        let haircare_array = []
        let spa_array = []
        let doctor_array = []
        let dentist_array = []
        let lawyer_array = []
        let electrician_array = []
        let accounting_array = []
        let bank_array = []
        let gym_array = []
        let veterinary_array = []
        let insuranceagency_array = []
        let travelagency_array = []
        let parking_array = []
        let painter_array = []
        let carrental_array = []
        let physiotherapist_array = []
        let realestateagency_array = []
        let plumber_array = []
        let carrepair_array = []
        let carwash_array = []
        let laundry_array = []
        let locksmith_array = []
        let cinema_array = []
        let minifootball_array = []
        let sports_array = []
        let bowlingalley_array = []
        let artgallery_array = []
        let museum_array = []
        let aquarium_array = []
        let amusementpark_array = []
        let zoo_array = []
        let campground_array = []
        let library_array = []
        let rvpark_array = []
        let university_array = []

        for (let i = 0; i < props.data.hubs.length; i++) {
            const place = {
                "type": "Feature",
                "properties": {
                    "name": props.data.hubs[i].name,
                    "active_subscription": props.data.hubs[i].active_subscription,
                    "address": props.data.hubs[i].address,
                    "another_app_appointments": props.data.hubs[i].another_app_appointments,
                    "another_app_reservations": props.data.hubs[i].another_app_reservations,
                    "appointment_acceptance": props.data.hubs[i].appointment_acceptance,
                    "appointment_app_url": props.data.hubs[i].appointment_app_url,
                    "category": props.data.hubs[i].category,
                    "city": props.data.hubs[i].city,
                    "country": props.data.hubs[i].country,
                    "cover_photo": props.data.hubs[i].cover_photo,
                    "cover_photo_props": props.data.hubs[i].cover_photo_props,
                    "distance": props.data.hubs[i].distance,
                    "facebook_url": props.data.hubs[i].facebook_url,
                    "has_facebook": props.data.hubs[i].has_facebook,
                    "has_instagram": props.data.hubs[i].has_instagram,
                    "has_menu": props.data.hubs[i].has_menu,
                    "has_online_shop": props.data.hubs[i].has_online_shop,
                    "has_sales": props.data.hubs[i].has_sales,
                    "has_telephone": props.data.hubs[i].has_telephone,
                    "has_website": props.data.hubs[i].has_website,
                    "hubst3r_appointments": props.data.hubs[i].hubst3r_appointments,
                    "hubst3r_reservations": props.data.hubs[i].hubst3r_reservations,
                    "id": props.data.hubs[i].id,
                    "instagram_url": props.data.hubs[i].instagram_url,
                    "is_claimed": props.data.hubs[i].is_claimed,
                    "is_open_label": props.data.hubs[i].is_open_label,
                    "is_open_now": props.data.hubs[i].is_open_now,
                    "is_overnight": props.data.hubs[i].is_overnight,
                    "lat": props.data.hubs[i].lat,
                    "lng": props.data.hubs[i].lng,
                    "logo": props.data.hubs[i].logo,
                    "map_icon": props.data.hubs[i].map_icon,
                    "place_type": props.data.hubs[i].place_type,
                    "reservation_acceptance": props.data.hubs[i].reservation_acceptance,
                    "reservation_app_url": props.data.hubs[i].reservation_app_url,
                    "sales_text": props.data.hubs[i].sales_text,
                    "shop_url": props.data.hubs[i].shop_url,
                    "telephone": props.data.hubs[i].telephone,
                    "telephone_appointments": props.data.hubs[i].telephone_appointments,
                    "telephone_reservations": props.data.hubs[i].telephone_reservations,
                    "traffic": props.data.hubs[i].traffic,
                    "website": props.data.hubs[i].website,
                    "wifi_code": props.data.hubs[i].wifi_code,
                    "users_with_access": props.data.hubs[i].users_with_access,
                },
                "geometry": {
                    "coordinates": [
                        props.data.hubs[i].lng,
                        props.data.hubs[i].lat
                    ],
                    "type": "Point"
                },
                "id": props.data.hubs[i].id
            }


            if (props.data.hubs[i].map_icon === "restaurant") {
                restaurant_array.push(place)
            } else if (props.data.hubs[i].map_icon === "coffee shop") {
                cafe_array.push(place)
            } else if (props.data.hubs[i].map_icon === "lodging") {
                lodging_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bar") {
                bar_array.push(place)
            } else if (props.data.hubs[i].map_icon === "night club") {
                club_array.push(place)
            } else if (props.data.hubs[i].map_icon === "food") {
                food_array.push(place)
            } else if (props.data.hubs[i].map_icon === 'casino') {
                casino_array.push(place)
            } else if (props.data.hubs[i].map_icon === "meal takeaway") {
                takeaway_array.push(place)
            } else if (props.data.hubs[i].map_icon === "clothing store") {
                clothingstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "electronics store") {
                electronicsstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "shoe store") {
                shoestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "supermarket") {
                supermarket_array.push(place)
            } else if (props.data.hubs[i].map_icon === "jewelry store") {
                jewelry_array.push(place)
            } else if (props.data.hubs[i].map_icon === "liquor store") {
                liquorstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "pet store") {
                petstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "shopping mall") {
                mall_array.push(place)
            } else if (props.data.hubs[i].map_icon === "convenience store") {
                conveniencestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "gas station") {
                gasstation_array.push(place)
            } else if (props.data.hubs[i].map_icon === "home goods store") {
                homegoodsstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "hardware store") {
                hardwarestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "pharmacy") {
                pharmacy_array.push(place)
            } else if (props.data.hubs[i].map_icon === "pharmacy overnight") {
                pharmacy_overnight_array.push(place)
            } else if (props.data.hubs[i].map_icon === "florist") {
                florist_array.push(place)
            } else if (props.data.hubs[i].map_icon === "furniture store") {
                furniturestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bicycle store") {
                bicyclestores_array.push(place)
            } else if (props.data.hubs[i].map_icon === "book store") {
                bookstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car dealer") {
                cardealer_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bakery") {
                bakery_array.push(place)
            } else if (props.data.hubs[i].map_icon === "beauty salon") {
                beautysalon_array.push(place)
            } else if (props.data.hubs[i].map_icon === "hair care") {
                haircare_array.push(place)
            } else if (props.data.hubs[i].map_icon === "spa") {
                spa_array.push(place)
            } else if (props.data.hubs[i].map_icon === "doctor") {
                doctor_array.push(place)
            } else if (props.data.hubs[i].map_icon === "dentist") {
                dentist_array.push(place)
            } else if (props.data.hubs[i].map_icon === "lawyer") {
                lawyer_array.push(place)
            } else if (props.data.hubs[i].map_icon === "electrician") {
                electrician_array.push(place)
            } else if (props.data.hubs[i].map_icon === "accounting") {
                accounting_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bank") {
                bank_array.push(place)
            } else if (props.data.hubs[i].map_icon === "gym") {
                gym_array.push(place)
            } else if (props.data.hubs[i].map_icon === "veterinary care") {
                veterinary_array.push(place)
            } else if (props.data.hubs[i].map_icon === "insurance agency") {
                insuranceagency_array.push(place)
            } else if (props.data.hubs[i].map_icon === "travel agency") {
                travelagency_array.push(place)
            } else if (props.data.hubs[i].map_icon === "parking") {
                parking_array.push(place)
            } else if (props.data.hubs[i].map_icon === "painter") {
                painter_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car rental") {
                carrental_array.push(place)
            } else if (props.data.hubs[i].map_icon === "physiotherapist") {
                physiotherapist_array.push(place)
            } else if (props.data.hubs[i].map_icon === "real estate agency") {
                realestateagency_array.push(place)
            } else if (props.data.hubs[i].map_icon === "plumber") {
                plumber_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car repair") {
                carrepair_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car wash") {
                carwash_array.push(place)
            } else if (props.data.hubs[i].map_icon === "laundry") {
                laundry_array.push(place)
            } else if (props.data.hubs[i].map_icon === "locksmith") {
                locksmith_array.push(place)
            } else if (props.data.hubs[i].map_icon === "cinema") {
                cinema_array.push(place)
            } else if (props.data.hubs[i].map_icon === "minifootball") {
                minifootball_array.push(place)
            } else if (props.data.hubs[i].map_icon === "sports") {
                sports_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bowling alley") {
                bowlingalley_array.push(place)
            } else if (props.data.hubs[i].map_icon === "art gallery") {
                artgallery_array.push(place)
            } else if (props.data.hubs[i].map_icon === "museum") {
                museum_array.push(place)
            } else if (props.data.hubs[i].map_icon === "aquarium") {
                aquarium_array.push(place)
            } else if (props.data.hubs[i].map_icon === "amusement park") {
                amusementpark_array.push(place)
            } else if (props.data.hubs[i].map_icon === "zoo") {
                zoo_array.push(place)
            } else if (props.data.hubs[i].map_icon === "campground") {
                campground_array.push(place)
            } else if (props.data.hubs[i].map_icon === "library") {
                library_array.push(place)
            } else if (props.data.hubs[i].map_icon === "rv park") {
                rvpark_array.push(place)
            } else if (props.data.hubs[i].map_icon === "university") {
                university_array.push(place)
            } else {
                hubs_array.push(place)
            }
        }


        hubs = {
            "features": hubs_array,
            "type": "FeatureCollection"
        }

        restaurant_hubs = {
            "features": restaurant_array,
            "type": "FeatureCollection"
        }

        cafe_hubs = {
            "features": cafe_array,
            "type": "FeatureCollection"
        }
        lodging_hubs = {
            "features": lodging_array,
            "type": "FeatureCollection"
        }
        bar_hubs = {
            "features": bar_array,
            "type": "FeatureCollection"
        }
        club_hubs = {
            "features": club_array,
            "type": "FeatureCollection"
        }
        food_hubs = {
            "features": food_array,
            "type": "FeatureCollection"
        }
        casino_hubs = {
            "features": casino_array,
            "type": "FeatureCollection"
        }
        takeaway_hubs = {
            "features": takeaway_array,
            "type": "FeatureCollection"
        }
        clothingstore_hubs = {
            "features": clothingstore_array,
            "type": "FeatureCollection"
        }
        electronicsstore_hubs = {
            "features": electronicsstore_array,
            "type": "FeatureCollection"
        }
        shoestore_hubs = {
            "features": shoestore_array,
            "type": "FeatureCollection"
        }
        supermarket_hubs = {
            "features": supermarket_array,
            "type": "FeatureCollection"
        }
        jewelry_hubs = {
            "features": jewelry_array,
            "type": "FeatureCollection"
        }
        liquorstore_hubs = {
            "features": liquorstore_array,
            "type": "FeatureCollection"
        }
        petstore_hubs = {
            "features": petstore_array,
            "type": "FeatureCollection"
        }
        mall_hubs = {
            "features": mall_array,
            "type": "FeatureCollection"
        }
        conveniencestore_hubs = {
            "features": conveniencestore_array,
            "type": "FeatureCollection"
        }
        gasstation_hubs = {
            "features": gasstation_array,
            "type": "FeatureCollection"
        }
        homegoodsstore_hubs = {
            "features": homegoodsstore_array,
            "type": "FeatureCollection"
        }
        hardwarestore_hubs = {
            "features": hardwarestore_array,
            "type": "FeatureCollection"
        }
        pharmacy_hubs = {
            "features": pharmacy_array,
            "type": "FeatureCollection"
        }
        pharmacy_overnight_hubs = {
            "features": pharmacy_overnight_array,
            "type": "FeatureCollection"
        }
        florist_hubs = {
            "features": florist_array,
            "type": "FeatureCollection"
        }
        furniturestore_hubs = {
            "features": furniturestore_array,
            "type": "FeatureCollection"
        }
        bicyclestores_hubs = {
            "features": bicyclestores_array,
            "type": "FeatureCollection"
        }
        bookstore_hubs = {
            "features": bookstore_array,
            "type": "FeatureCollection"
        }
        cardealer_hubs = {
            "features": cardealer_array,
            "type": "FeatureCollection"
        }
        bakery_hubs = {
            "features": bakery_array,
            "type": "FeatureCollection"
        }
        beautysalon_hubs = {
            "features": beautysalon_array,
            "type": "FeatureCollection"
        }
        haircare_hubs = {
            "features": haircare_array,
            "type": "FeatureCollection"
        }
        spa_hubs = {
            "features": spa_array,
            "type": "FeatureCollection"
        }
        doctor_hubs = {
            "features": doctor_array,
            "type": "FeatureCollection"
        }
        dentist_hubs = {
            "features": dentist_array,
            "type": "FeatureCollection"
        }
        lawyer_hubs = {
            "features": lawyer_array,
            "type": "FeatureCollection"
        }
        electrician_hubs = {
            "features": electrician_array,
            "type": "FeatureCollection"
        }
        accounting_hubs = {
            "features": accounting_array,
            "type": "FeatureCollection"
        }
        bank_hubs = {
            "features": bank_array,
            "type": "FeatureCollection"
        }
        gym_hubs = {
            "features": gym_array,
            "type": "FeatureCollection"
        }
        veterinary_hubs = {
            "features": veterinary_array,
            "type": "FeatureCollection"
        }
        insuranceagency_hubs = {
            "features": insuranceagency_array,
            "type": "FeatureCollection"
        }
        travelagency_hubs = {
            "features": travelagency_array,
            "type": "FeatureCollection"
        }
        parking_hubs = {
            "features": parking_array,
            "type": "FeatureCollection"
        }
        painter_hubs = {
            "features": painter_array,
            "type": "FeatureCollection"
        }
        carrental_hubs = {
            "features": carrental_array,
            "type": "FeatureCollection"
        }
        physiotherapist_hubs = {
            "features": physiotherapist_array,
            "type": "FeatureCollection"
        }
        realestateagency_hubs = {
            "features": realestateagency_array,
            "type": "FeatureCollection"
        }
        plumber_hubs = {
            "features": plumber_array,
            "type": "FeatureCollection"
        }
        carrepair_hubs = {
            "features": carrepair_array,
            "type": "FeatureCollection"
        }
        carwash_hubs = {
            "features": carwash_array,
            "type": "FeatureCollection"
        }
        laundry_hubs = {
            "features": laundry_array,
            "type": "FeatureCollection"
        }
        locksmith_hubs = {
            "features": locksmith_array,
            "type": "FeatureCollection"
        }
        cinema_hubs = {
            "features": cinema_array,
            "type": "FeatureCollection"
        }
        minifootball_hubs = {
            "features": minifootball_array,
            "type": "FeatureCollection"
        }
        sports_hubs = {
            "features": sports_array,
            "type": "FeatureCollection"
        }
        bowlingalley_hubs = {
            "features": bowlingalley_array,
            "type": "FeatureCollection"
        }
        artgallery_hubs = {
            "features": artgallery_array,
            "type": "FeatureCollection"
        }
        museum_hubs = {
            "features": museum_array,
            "type": "FeatureCollection"
        }
        aquarium_hubs = {
            "features": aquarium_array,
            "type": "FeatureCollection"
        }
        amusementpark_hubs = {
            "features": amusementpark_array,
            "type": "FeatureCollection"
        }
        zoo_hubs = {
            "features": zoo_array,
            "type": "FeatureCollection"
        }
        campground_hubs = {
            "features": campground_array,
            "type": "FeatureCollection"
        }
        library_hubs = {
            "features": library_array,
            "type": "FeatureCollection"
        }
        rvpark_hubs = {
            "features": rvpark_array,
            "type": "FeatureCollection"
        }
        university_hubs = {
            "features": university_array,
            "type": "FeatureCollection"
        }

        function addMarkers() {
            for (const marker of myProfile.features) {
                const el = document.createElement('div');
                el.id = `profile-marker-${marker.properties.id}`;
                el.className = "marker-map-mi-marker-box";
                el.innerHTML += `<img class="marker-map-mi-marker-icon" src=${require('../../../assets/map/svg/my-location.svg')} /> <img class="marker-map-mi-marker-imade" src="${marker.properties.profile_picture}" />`;
                el.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`profile-marker-${marker.properties.id}`);
                });

                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }


            let all_markers = []
            /* For each feature in the GeoJSON object above: */
            for (const marker of hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-place-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);


                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }

            for (const marker of restaurant_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-restaurant-blue";


                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });

                const new_marker = new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);


                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });

                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of cafe_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-cafe-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });

                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });

                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bar_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-bar-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);


                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });

                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

            }

            for (const marker of club_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-club-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });

                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of food_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-food-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });

                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of casino_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-casino-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of takeaway_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-takeaway-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of clothingstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-clothestore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of electronicsstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-electronicsstore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of shoestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-shoestore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of supermarket_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-supermarket-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of jewelry_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-jewelry-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of liquorstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-liquorstore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of petstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-petstore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of mall_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-mall-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of conveniencestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-conviniencestore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of gasstation_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-gasstation-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of homegoodsstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-homegoodsstore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of hardwarestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-hardwarestore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of pharmacy_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-pharmacy-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of pharmacy_overnight_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-pharmacy-overnight-blue";
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of florist_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-florist-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of furniturestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-furniturestore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bicyclestores_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-bicyclestore-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bookstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-bookshop-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of cardealer_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-cardealer-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bakery_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-bakery-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of beautysalon_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-beautysalon-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of haircare_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-hairsalon-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of spa_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-spa-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of doctor_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-doctor-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of dentist_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-dentist-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of lawyer_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-lawer-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of electrician_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-electrician-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of accounting_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-accountant-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of laundry_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-laundry-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bank_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-bank-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of gym_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-gym-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of veterinary_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-veterany-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of insuranceagency_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-insurance-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of travelagency_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-travel-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of parking_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-parking-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of painter_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-painter-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of carrental_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-rentcar-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of physiotherapist_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-physiotherapist-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of realestateagency_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-realestateagent-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of plumber_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-plumber-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of carrepair_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-carrepair-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of carwash_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-carwash-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of locksmith_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;


                el.className = "marker-locksmith-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of cinema_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-cinema-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of lodging_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-lodging-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of minifootball_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-minifootball-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of sports_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-sports-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bowlingalley_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-bowling-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of artgallery_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-artgallery-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of museum_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-museum-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of aquarium_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-aquarium-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of amusementpark_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-amusementpark-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of zoo_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;


                el.className = "marker-zoo-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of campground_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-campground-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of library_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;


                el.className = "marker-library-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of rvpark_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-rvpark-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of university_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;

                el.className = "marker-university-blue";

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                el_name.className = "marker-map-plcae-marker-name";
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
        }

        function flyToStore(currentFeature) {
            map.current.flyTo({
                center: currentFeature.geometry.coordinates,
                zoom: 13
            });
            props.data.handleCenterChanged(currentFeature.properties.lat, currentFeature.properties.lng[1], 18)
        }

        function clickSeeMore() {
        }

        function createPopUp(currentFeature) {

        }


        map.current.on("load", () => {
            // add mapbox terrain dem source for 3d terrain rendering
            map.current.addSource("mapbox-dem", {
                type: "raster-dem",
                url: "mapbox://mapbox.mapbox-terrain-dem-v1",
                tileSize: 512,
                maxZoom: 16
            });
            map.current.setTerrain({ source: "mapbox-dem" });

            // avalanche paths source
            // example of how to add a custom tileset hosted on Mapbox
            // you can grab the url from the details page for any tileset
            // you have created in Mapbox studio
            // see https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#vector
            // map.current.addSource("avalanche-paths", {
            //     type: "vector",
            //     url: "mapbox://lcdesigns.arckuvnm"
            // });


            // snotel sites source
            // example of using a geojson source
            // data is hosted locally as part of the application
            // see https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson


            map.current.addLayer({
                id: 'all_hubs',
                // type: "geojson",
                type: 'fill',
                /* Add a GeoJSON source containing place coordinates and information. */
                source: {
                    type: 'geojson',
                    data: gasstation_hubs
                }
            });


            map.current.addSource("hubs", {
                type: "geojson",
                data: hubs
            });

            map.current.addSource("restaurant_hubs", {
                type: "geojson",
                data: restaurant_hubs
            });


            map.current.addLayer({
                id: "sky",
                type: "sky",
                paint: {
                    "sky-type": "atmosphere",
                    "sky-atmosphere-sun": [0.0, 90.0],
                    "sky-atmosphere-sun-intensity": 15
                }
            });

            // Insert the layer beneath any symbol layer.
            const layers = map.current.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === 'symbol' && layer.layout['text-field']
            ).id;

            // The 'building' layer in the Mapbox Streets
            // vector tileset contains building height data
            // from OpenStreetmap.current.



            addMarkers();

        });

        map.current.on('dragend', () => {
            const map_center = map.current.getCenter()
            const lat = map_center.lat
            const lng = map_center.lng
            const zoom = map.current.getZoom()
            props.data.handleCenterChanged(lat, lng, zoom)
            // props.getMapPlaces("show_all", map_center.lat, map_center.lng, 19)
        });

        // only want to work with the map after it has fully loaded
        // if you try to add sources and layers before the map has loaded
        // things will not work properly


        // cleanup function to remove map on unmount
        return () => map.current.remove();
    }, []);


    return <div ref={mapContainer}
        className="map-box-search-map-map"
    // style={{ width: "100vw", height: "30vh" }}
    />;
};

export default HubPageMap;
