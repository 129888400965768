import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { register, login, loginToken } from '../../../store/actions/auth';
import { createFirstTimeVisit, visitedUserRegistered } from '../../../store/actions/hubst3rActions';
import { createAlert } from '../../../store/actions/alerts'
import Modal from 'react-awesome-modal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import CookiesModal from '../../../components/user/layout/cookiesModal';
import '../../../assets/authentication/registration.css'
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';



export class Register extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        ageBox: false,
        termsBox: false,
        residenceBox: false,
        passwordModalVisible: false,
        isLoading: true,
        showSendAgain: false,
        just_registered: false,
        active_code: false,
        btnAppear: true,
        sellected_mode: "",
        cahnged_mode: false,
        loginCredentials: '',
        loginStarted: false
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false,
            })
        }, 1000);

        if (!JSON.parse(localStorage.getItem("frsttmvst"))) {
            localStorage.setItem('frsttmvst', JSON.stringify("True"));
            const browser = window.loadVersionBrowser().name;
            const full_browser = window.clientInformation.userAgent;
            const device_width = window.innerWidth
            const device_height = window.innerHeight
            fetch(`https://geolocation-db.com/json/`)
                .then(response => {
                    return response.json();
                }, "jsonp")
                .then(res => {
                    const ip = res.IPv4
                    const country = res.country_name
                    const city = res.city
                    const latitude = res.latitude
                    const longitude = res.longitude
                    const state = res.state
                    const from_nfc = false
                    const nfc_number = ""
                    const data = { from_nfc, nfc_number, browser, full_browser, device_width, device_height, ip, country, city, latitude, longitude, state }
                    this.props.createFirstTimeVisit(data)
                }).catch(err => {
                    console.log("Hiiiiiii")
                });
        }

        const previous_code_request = JSON.parse(localStorage.getItem("lgncrdtls"))
        if (previous_code_request) {
            const now = new Date()
            // compare the expiry time of the item with the current time
            if (now.getTime() > previous_code_request.expiry) {
                // If the 6 digit password is expired, delete the item from storage
                localStorage.removeItem("lgncrdtls")
            } else {
                this.setState({
                    active_code: true,
                })
            }
        }
    }

    onCredentialsSubmit = () => (e) => {
        e.preventDefault();
        const { username, email, ageBox, termsBox, residenceBox } = this.state;
        if (email === "") {
            this.props.createAlert({ noEmailGiven: "You haven't given an Email address!" });
        } else if (username === "") {
            this.props.createAlert({ noUsenameGiven: "You haven't given a Username!" });
        } else if (termsBox === false) {
            this.props.createAlert({ noTermsConfirmation: "You haven't declared that you accept our Terms, Data Policy and Cookies!" });
        } else if (!ageBox) {
            this.props.createAlert({ noAgeConfirmation: "You haven't declared that you are over 18!" });
        } else if (!residenceBox) {
            this.props.createAlert({ noAgeConfirmation: "You haven't declared that you are not from UK!" });
        } else {
            this.setState({
                ageBox: false,
                termsBox: false,
                residenceBox: false,
                isLoading: true,
                just_registered: true,
                btnAppear: false,
                loginStarted: true
            })
            this.openModal()
            this.props.register(username, email)
            setTimeout(() => {
                this.setState({
                    isLoading: false,
                    loginStarted: false
                })
            }, 3000);
            setTimeout(() => {
                this.setState({
                    showSendAgain: true,
                })
            }, 180000);

            const now = new Date()
            const after_ten_minutes = 578612
            const item = {
                value: username,
                expiry: now.getTime() + after_ten_minutes,
            }
            localStorage.setItem("lgncrdtls", JSON.stringify(item))
            localStorage.setItem("vstrgstr", JSON.stringify(true))
        }
        setTimeout(() => {
            this.setState({
                btnAppear: true,
            })
        }, 2000);
    };

    onResendSubmit = () => (e) => {
        this.setState({
            isLoading: true,
        })
        e.preventDefault();
        let { username } = this.state;
        const previous_code_request = JSON.parse(localStorage.getItem("lgncrdtls"))
        if (previous_code_request && username === "") {
            username = previous_code_request.value
        }

        if (username === "") {

            this.props.createAlert({ noCridentialsGiven: "You haven't given an Email address or a Username!" });
        } else {
            this.props.loginToken(username);
            setTimeout(() => {
                this.setState({
                    isLoading: false,
                })
            }, 5000);
            setTimeout(() => {
                this.setState({
                    showSendAgain: true,
                })
            }, 180000);
        }
    };

    onPressEnter = () => (e) => {
        if (e.charCode === 13) {
            this.setState({
                btnAppear: false,
            })
            e.preventDefault();
            const { username, email, ageBox, termsBox, residenceBox } = this.state;
            if (email === "") {
                this.props.createAlert({ noEmailGiven: "You haven't given an Email address!" });
            } else if (username === "") {
                this.props.createAlert({ noUsenameGiven: "You haven't given a Username!" });
            } else if (termsBox === false) {
                this.props.createAlert({ noTermsConfirmation: "You haven't declared that you accept our Terms, Data Policy and Cookies!" });
            } else if (!ageBox) {
                this.props.createAlert({ noAgeConfirmation: "You haven't declared that you are over 18!" });
            } else if (!residenceBox) {
                this.props.createAlert({ noAgeConfirmation: "You haven't declared that you are not from UK!" });
            } else {
                this.setState({
                    ageBox: false,
                    termsBox: false,
                    residenceBox: false,
                    loginStarted: true
                })
                // this.openModal()
                this.props.register(username, email)
            }
            setTimeout(() => {
                this.setState({
                    btnAppear: true,
                    loginStarted: false
                })
            }, 2000);
        }
    }

    submitCallbackToken = () => (e) => {
        e.preventDefault();
        let { username } = this.state;
        const { password } = this.state;
        const previous_code_request = JSON.parse(localStorage.getItem("lgncrdtls"))
        if (previous_code_request && username === "") {
            username = previous_code_request.value
        }
        if (password === "") {
            this.props.createAlert({ noCridentialsGiven: "You haven't given the 6 Digit code sent to your email!" });
        } else {
            this.openModal()
            this.props.login(username, password);
        }
    };

    onPressSendAgain = () => (e) => {
        this.setState({
            showSendAgain: false,
        })
        // // renew the keep the pop up open if there is an active code sent to the email
        const now = new Date()
        const after_ten_minutes = 578612
        const item = {
            value: this.state.username,
            expiry: now.getTime() + after_ten_minutes,
        }
        localStorage.setItem("lgncrdtls", JSON.stringify(item))
        setTimeout(() => {
            this.setState({
                showSendAgain: true,
            })
        }, 180000);
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    changeAgeBox = e => this.setState({ ageBox: !this.state.ageBox });

    changeTermsBox = e => this.setState({ termsBox: !this.state.termsBox });

    changeResidenceBox = e => this.setState({ residenceBox: !this.state.residenceBox });


    openModal() {
        this.setState({
            passwordModalVisible: true
        });
    }

    closeModal() {
        localStorage.removeItem("lgncrdtls")
        window.location.reload(false);
    }

    sellectMode(mode) {
        this.setState({
            ageBox: false,
            termsBox: false,
            residenceBox: false,
            sellected_mode: mode,
            cahnged_mode: true
        });
    }

    onLoginCredentialsSubmit = () => (e) => {
        this.setState({
            isLoading: true,
            btnAppear: false,
        })
        e.preventDefault();
        const { loginCredentials } = this.state;
        if (loginCredentials === "") {
            this.props.createAlert({ noCridentialsGiven: "You haven't given an Email address or a Username!" });
        } else {
            this.setState({
                loginStarted: true
            })
            setTimeout(() => {
                this.setState({
                    isLoading: false,
                    loginStarted: false
                })
            }, 3000);
            this.props.loginToken(loginCredentials);
            setTimeout(() => {

                if (this.props.correct_credentials) {
                    this.openModal()
                    // // keep the pop up open if there is an active code sent to the email
                    const now = new Date()
                    const after_ten_minutes = 578612
                    const item = {
                        value: loginCredentials,
                        expiry: now.getTime() + after_ten_minutes,
                    }
                    localStorage.setItem("lgncrdtls", JSON.stringify(item))
                }
            }, 5000);
            setTimeout(() => {
                this.setState({
                    showSendAgain: true,
                })
            }, 180000);
        }
        setTimeout(() => {
            this.setState({
                btnAppear: true,
            })
        }, 2000);
    };

    render() {
        const defaultOptions = {
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        if (this.props.isAuthenticated) {
            if (this.props.location.state) {
                return <Redirect to={{ pathname: '/', state: { ambassandor: this.props.location.state.ambassandor, hub_id: this.props.location.state.hub_id } }} />;
            } else {
                return <Redirect to={{ pathname: '/' }} />;
            }
        }
        const { username, email, password } = this.state;

        const browser = navigator.userAgent.search("Android")
        let apple_device = false
        if (browser === -1) {
            apple_device = true
        }

        let sellected_mode = "signup"
        if (this.state.cahnged_mode) {
            sellected_mode = this.state.sellected_mode
        }
        if (this.state.just_registered) {
            sellected_mode = "mail"
        }
        if (!this.state.just_registered) {
            if (this.state.active_code) {
                sellected_mode = "mail"
            }
        }

        if (this.props.correct_credentials) {
            sellected_mode = "mail"
        }

        if (this.props.incorect_redentials) {
            if (this.state.cahnged_mode) {
                sellected_mode = this.state.sellected_mode
            } else {
                sellected_mode = "signup"
            }
        }

        let loginStarted = this.state.loginStarted
        // let loginStarted = true
        // if (this.props.correct_credentials) {
        //     loginStarted = false
        // }
        // if (this.props.incorect_redentials) {
        //     loginStarted = false
        // }


        const previous_code_request = JSON.parse(localStorage.getItem("lgncrdtls"))


        return (
            <Fragment>
                <CookiesModal />
                <div class="register-map-box-layout">
                    <img
                        class="register-map-img-layout"
                        src={require("../../../assets/authentication/svg/map.jpeg")}
                        alt=""
                    />
                </div>

                <div class="register-center-box-layout">
                    {sellected_mode !== "mail" ?
                        <div class="register-center-top-two-btns">
                            <div
                                onClick={() => this.sellectMode("login")}
                                class={sellected_mode === "login" ? "register-center-top-two-btns-left-sellected" : "register-center-top-two-btns-left"}>
                                Log in
                            </div>
                            <div
                                onClick={() => this.sellectMode("signup")}
                                class={sellected_mode === "signup" ? "register-center-top-two-btns-right-selected" : "register-center-top-two-btns-right"}>
                                Sign up
                            </div>
                        </div>
                        :
                        null
                    }

                    {loginStarted ?
                        <div class="register-login-started-gif">
                            <Lottie options={defaultOptions} />
                        </div>
                        :
                        <>
                            {sellected_mode === "login" ?
                                <div
                                    // onKeyPress={this.onPressEnter()} 
                                    class="register-form">
                                    <div class="register-form-logo text-center">
                                        <div class="register-empty-spaxe-fix"></div>
                                        {apple_device ?
                                            <p class="register-dowlonlaod-ext"><b>Download Now By pressing<br /> "Add to Homescreen"<br />in Safari's Menu</b></p>
                                            :
                                            null
                                        }


                                    </div>

                                    {apple_device ?
                                        null
                                        :
                                        <div class="register-download-google">
                                            <a href="https://play.google.com/store/apps/details?id=com.app.hubst3r">
                                                <img
                                                    class="landing-page-logo-img"
                                                    src={require("../../../assets/ambassandors/svg/google-play.png")}
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                    }



                                    <p class="register-top-explain-text">Instert your Account's <b>Email or Username</b>. You will receive a one time 6 Digit Password at your Email</p>

                                    <div class="form-group">
                                        <input
                                            type="text"
                                            onChange={this.onChange}
                                            value={this.state.loginCredentials}
                                            class="login-form-control"
                                            name="loginCredentials"
                                            placeholder="Enter your username or your email"
                                        // required
                                        />
                                    </div>

                                    <br />

                                    <div class="form-group">
                                        <div
                                            onTouchStart
                                            onClick={this.onLoginCredentialsSubmit()}
                                            class="register-signup-btn-hubst3r"
                                        >
                                            Log in
                                        </div>
                                    </div>

                                    <br />

                                    {/* <div class="text-center register-account-have">
                                        You don't have an account? &nbsp;
                                        <a href="#">
                                            Sign up now
                                        </a>
                                    </div>
                                    <div class="text-center login-account-have">
                                        You own a Business that wants to be in Hubst3r? &nbsp;
                                        <Link to="/hub-contact-us">
                                            <a href="#">
                                                Yes
                                            </a>
                                        </Link>
                                    </div> */}

                                    <div class="row login-legal-position">
                                        <div class="col-xl-12 text-center mt-2">
                                            <Link to="/terms-of-use">
                                                <a class="link2" href="#">Terms</a>
                                            </Link>
                                            <Link to="/privacy-policy">
                                                <a class="link2" href="#">Privacy</a>
                                            </Link>
                                            <Link to="/cookies-policy">
                                                <a class="link2" href="#">Cookies</a>
                                            </Link>
                                            <br />

                                            <a class="link2" href="http://about.hubst3r.com/">About</a>

                                            <Link to="/licenses">
                                                <a class="link2" href="#">Licenses</a>
                                            </Link>
                                        </div>
                                        <br /><br />
                                        <br /><br />
                                    </div>
                                </div>
                                : sellected_mode === "signup" ?
                                    <div
                                        // onKeyPress={this.onPressEnter()} 
                                        class="register-form">
                                        <div class="register-form-logo text-center">
                                            <div class="register-empty-spaxe-fix"></div>
                                            {apple_device ?
                                                <p class="register-dowlonlaod-ext"><b>Download Now By pressing<br /> "Add to Homescreen"<br />in Safari's Menu</b></p>
                                                :
                                                null
                                            }

                                            {/* <img
                                class="hubster-login-logo-size"
                                src={require("../../../assets/authentication/svg/Logo.png")}
                                alt=""
                            /> */}
                                        </div>

                                        {apple_device ?
                                            null
                                            :
                                            <div class="register-download-google">
                                                <a href="https://play.google.com/store/apps/details?id=com.app.hubst3r">
                                                    <img
                                                        class="landing-page-logo-img"
                                                        src={require("../../../assets/ambassandors/svg/google-play.png")}
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        }

                                        <p class="register-top-explain-text">After you Sign up you will receive a one time 6 Digit Password at your Email</p>

                                        <div class="form-group">
                                            <input
                                                type="text"
                                                onChange={this.onChange}
                                                value={username}
                                                class="register-form-control"
                                                name="username"
                                                placeholder="Enter your username"
                                                maxlength="16"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <input
                                                type="email"
                                                onChange={this.onChange}
                                                value={email}
                                                class="register-form-control"
                                                name="email"
                                                placeholder="Enter your email"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label style={{ marginTop: "20px" }} class="row register-checkbox-inline">
                                                <input style={{ width: "30px" }} type="checkbox" required="required" onClick={this.changeTermsBox} />&nbsp;&nbsp;

                                                <div class="register-page-terms-width">

                                                    By checking this box you accept the &nbsp;
                                                    <Link to="/terms-of-use"><a class="" href="#">Terms of Use</a></Link>,&nbsp;
                                                    <Link to="/privacy-policy"><a class="" href="#">Privacy Policy</a></Link>,&nbsp;
                                                    <Link to="/cookies-policy"><a class="" href="#">Cookies Policy</a></Link>&nbsp;
                                                    and other terms and policies.
                                                </div>

                                            </label>

                                        </div>
                                        <div class="form-group">
                                            <label class="row register-checkbox-inline">
                                                <input style={{ width: "30px" }} type="checkbox" required="required" onClick={this.changeAgeBox} />&nbsp;&nbsp;
                                                <span style={{ width: "80%" }}>
                                                    I confirm that I am over 18 years old.
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label class="row register-checkbox-inline">
                                                <input style={{ width: "30px" }} type="checkbox" required="required" onClick={this.changeResidenceBox} />&nbsp;&nbsp;
                                                <span style={{ width: "80%" }}>
                                                    I confirm that I am not a UK resident.
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <div
                                                onTouchStart
                                                onClick={this.onCredentialsSubmit()}
                                                class="register-signup-btn-hubst3r"
                                            >
                                                Sign up
                                            </div>
                                        </div>
                                        {/* <div class="text-center register-account-have">
                                            Already have an account? &nbsp;
                                            <Link to="/login">
                                                <a href="#">
                                                    Login now
                                                </a>
                                            </Link>
                                        </div>
                                        <div class="text-center login-account-have">
                                            You own a Business that wants to be in Hubst3r? &nbsp;
                                            <Link to="/hub-contact-us">
                                                <a href="#">
                                                    Yes
                                                </a>
                                            </Link>
                                        </div> */}

                                        <div class="row login-legal-position">
                                            <div class="col-xl-12 text-center mt-2">
                                                <Link to="/terms-of-use">
                                                    <a class="link2" href="#">Terms</a>
                                                </Link>
                                                <Link to="/privacy-policy">
                                                    <a class="link2" href="#">Privacy</a>
                                                </Link>
                                                <Link to="/cookies-policy">
                                                    <a class="link2" href="#">Cookies</a>
                                                </Link>
                                                <br />

                                                <a class="link2" href="http://about.hubst3r.com/">About</a>

                                                <Link to="/licenses">
                                                    <a class="link2" href="#">Licenses</a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    : sellected_mode === "mail" ?

                                        <div
                                            // onKeyPress={this.onPressEnter()} 
                                            class="register-form">
                                            <div class="register-form-logo text-center">

                                                <br /><br /><br />

                                                <p class="register-top-explain-mail-text">
                                                    Insert the one time 6 Digit Password you received at your Email
                                                </p>

                                                <p class="register-top-explain-text">
                                                    If you haven't received an Email please check in your Spam
                                                </p>
                                                <br />
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        onChange={this.onChange}
                                                        value={password}
                                                        class="register-form-control"
                                                        name="password"
                                                        placeholder="Enter the 6-digit Code here"
                                                        maxlength="6"
                                                    />
                                                </div>
                                                <br /><br />

                                                <div class="form-group">
                                                    <div
                                                        onTouchStart
                                                        onClick={this.submitCallbackToken()}
                                                        class="register-signup-btn-hubst3r"
                                                    >
                                                        Continue
                                                    </div>
                                                </div>
                                                <br /><br />
                                                {this.state.showSendAgain ?
                                                    <div class="text-center register-account-have">
                                                        You haven't receive an Email? <br />

                                                        <a onClick={this.onResendSubmit()} href="#">
                                                            Send Again
                                                        </a>

                                                    </div>
                                                    :
                                                    <center>
                                                        <div class="register-mail-spinner-layout">
                                                            <img
                                                                class="register-mail-spinner-size"
                                                                src={require("../../../assets/authentication/svg/mail-spinner.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </center>
                                                }

                                                <br />
                                                <div class="text-center login-account-have">
                                                    Was there a mistake? <br />

                                                    <a onClick={() => this.closeModal()} href="#">
                                                        Restart
                                                    </a>

                                                </div>

                                            </div>
                                        </div>
                                        :
                                        null

                            }
                        </>
                    }
                </div>

                {/* 
                <div>
                    <div class="register-whole-page-layout">
                        <CookiesModal />
                        {this.props.correct_credentials ?
                            <Modal visible={this.state.passwordModalVisible} width="100" height="100" effect="fadeInUp">
                                <div class="authentication-password-box">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                        </div>
                                        <div class="authentication-box-6">
                                            <p class="register-modal-top-text">Enter the 6-digit Code <u><b>ALREADY</b></u> sent to your <u><b>EMAIL. </b></u><u><b>Check also your Spam</b></u></p>
                                            <div class="">
                                                <div class="form-group">
                                                    <input
                                                        maxlength="6"
                                                        type="text"
                                                        onChange={this.onChange}
                                                        value={password}
                                                        class="modal-password-field"
                                                        name="password"
                                                        placeholder="Enter the Code here"
                                                    // required
                                                    />
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center mt-4 reginter-continue-button-center">
                                                    <div
                                                        onTouchStart
                                                        onClick={this.submitCallbackToken()}
                                                        class="register-modal-btn-success">
                                                        Continue
                                                    </div>
                                                </div>
                                                <br />
                                                {this.state.showSendAgain ?
                                                    <center onClick={this.onPressSendAgain()}>
                                                        <a
                                                            onClick={this.onResendSubmit()}
                                                            class="send-again-a" href="#"
                                                        >
                                                            Send again
                                                        </a>
                                                    </center>
                                                    :
                                                    <center>
                                                        <div class="register-mail-spinner-layout">
                                                            <img
                                                                class="register-mail-spinner-size"
                                                                src={require("../../../assets/authentication/svg/mail-spinner.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </center>
                                                }

                                                <center class="restart-link-register">
                                                    <a
                                                        onClick={() => this.closeModal()}
                                                        class="send-again-a" href="#">
                                                        Restart
                                                    </a>
                                                </center>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            :
                            null
                        }
                        <div class="form-color">
                            <div class="register-form-header text-center">
                                <Link to="/login">
                                    <div class="register-back-button">
                                        <img
                                            class="register-back-icon-size"
                                            src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <img
                                    class="hubst3r-login-word-size"
                                    src={require("../../../assets/authentication/svg/Logo-h.svg")}
                                    alt=""
                                />
                            </div>

                            <div class="register-signup-form">
                                <div onKeyPress={this.onPressEnter()} class="register-form">
                                    <div class="register-form-logo text-center">
                                        {apple_device ?
                                            <p class="hint-text"><b>Download Now By pressing<br /> "Add to Homescreen"<br />in Safari's Menu</b></p>
                                            :
                                            null
                                        }
                                        <img
                                            class="hubster-login-logo-size"
                                            src={require("../../../assets/authentication/svg/Logo.png")}
                                            alt=""
                                        />
                                    </div>

                                    {apple_device ?
                                        null
                                        :
                                        <div class="register-download-google">
                                            <a href="https://play.google.com/store/apps/details?id=com.app.hubst3r">
                                                <img
                                                    class="landing-page-logo-img"
                                                    src={require("../../../assets/ambassandors/svg/google-play.png")}
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                    }


                                    <h2>Registration</h2>
                                    <p class="hint-text">Register to enjoy the most memorable Experiences with your Buddies 🥳</p>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            onChange={this.onChange}
                                            value={username}
                                            class="register-form-control"
                                            name="username"
                                            placeholder="Enter your username"
                                            // required
                                            maxlength="16"
                                        // autoFocus
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            onChange={this.onChange}
                                            value={email}
                                            class="register-form-control"
                                            name="email"
                                            placeholder="Enter your email"
                                        // required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label style={{ marginTop: "20px" }} class="row register-checkbox-inline">
                                            <input style={{ width: "30px" }} type="checkbox" required="required" onClick={this.changeTermsBox} />&nbsp;&nbsp;
                                            
                                            <div class="register-page-terms-width">
                                                
                                                By checking this box you accept the &nbsp;
                                                <Link to="/terms-of-use"><a class="" href="#">Terms of Use</a></Link>,&nbsp;
                                                <Link to="/privacy-policy"><a class="" href="#">Privacy Policy</a></Link>,&nbsp;
                                                <Link to="/cookies-policy"><a class="" href="#">Cookies Policy</a></Link>&nbsp;
                                                and other terms and policies.
                                            </div>
                                            
                                        </label>

                                    </div>
                                    <div class="form-group">
                                        <label class="row register-checkbox-inline">
                                            <input style={{ width: "30px" }} type="checkbox" required="required" onClick={this.changeAgeBox} />&nbsp;&nbsp;
                                            <span style={{ width: "80%" }}>
                                                I confirm that I am over 18 years old.
                                            </span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label class="row register-checkbox-inline">
                                            <input style={{ width: "30px" }} type="checkbox" required="required" onClick={this.changeResidenceBox} />&nbsp;&nbsp;
                                            <span style={{ width: "80%" }}>
                                                I confirm that I am not a UK resident.
                                            </span>
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        {this.state.btnAppear ?
                                            <div
                                                onTouchStart
                                                onClick={this.onCredentialsSubmit()}
                                                class="register-signup-btn-hubst3r"
                                            >
                                                Register
                                            </div>
                                            :
                                            <div
                                                onTouchStart
                                                class="register-signup-btn-hubst3r"
                                            >
                                                Register
                                            </div>
                                        }
                                    </div>
                                    <div class="text-center register-account-have">
                                        Already have an account? &nbsp;
                                        <Link to="/login">
                                            <a href="#">
                                                Login now
                                            </a>
                                        </Link>
                                    </div>

                                    <div class="row login-legal-position">
                                        <div class="col-xl-12 text-center mt-2">
                                            <Link to="/terms-of-use">
                                                <a class="link2" href="#">Terms</a>
                                            </Link>
                                            <Link to="/privacy-policy">
                                                <a class="link2" href="#">Privacy</a>
                                            </Link>
                                            <Link to="/cookies-policy">
                                                <a class="link2" href="#">Cookies</a>
                                            </Link>
                                            <br />
                                            
                                            <a class="link2" href="http://about.hubst3r.com/">About</a>
                                            
                                            <Link to="/licenses">
                                                <a class="link2" href="#">Licenses</a>
                                            </Link>
                                        </div>
                                        <br /><br />
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    correct_credentials: state.auth.correct_credentials,
    incorect_redentials: state.auth.incorect_redentials,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    register,
    login,
    loginToken,
    createAlert,
    createFirstTimeVisit,
})(Register);