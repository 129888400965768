import React, { Component, Fragment } from 'react';
import "../../../assets/layout/homepage.css"


export class DescriptionModal extends Component {

    closeModal = () => {
        this.props.data.closeModal()
    }


    render() {
        return (
            <Fragment>
                <div class="homepage-description-box">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="descrition-modal-box">
                            <div class="description-modal-title">Description:</div>

                            <img
                                onClick={this.closeModal}
                                class="times-modal-size"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <br />
                            <div class="homepage-hub-description-text blue-color">{this.props.data.description}</div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (DescriptionModal);