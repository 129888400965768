import React, { Fragment, lazy, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { Link as ScrollLink } from 'react-scroll';
import HubBottomNavbar from '../../../components/hub/layout/hubBottomNavabar';
import HubMobileTraffic from '../../../components/hub/layout/hubMobileTraffic';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import HubDesktopTraffic from '../../../components/hub/layout/hubDesktopTraffic';
import TablesModal from '../../../components/hub/booked-reservations/TablesModal';
import AllTablesModal from '../../../components/hub/booked-reservations/AllTablesModal';
import SellectedTableModal from '../../../components/hub/booked-reservations/SellectedTableModal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
import AreYouSure from '../../../components/user/layout/AreYouSure'
import { getAddReservationProfile } from '../../../store/actions/profile'
import { getHubPage } from '../../../store/actions/hub'
import { getHubPendingNotificationsStatus, getHubCancellationsNotificationsStatus } from '../../../store/actions/notification'
import {
    updateHubReservationNote,
    acceptHubReservationRequest,
    declineHubReservationRequest,
    getBookedReservations,
    getAllCancelledReservations,
    getDatesReservations,
    getDatesHubReservations,
    updateHubReservationTable,
    getDatesCancellations,
    getHubFreeTables,
    getHubAllTables,
    createTable,
    updateReservationTable,
    updateReservationNote,
    setTableStatus,
    // increaseReservationArrivedPersons,
    // decreaseReservationArrivedPersons,
    reservationNotAttended,
    createNewReservation,
    increaseHubReservationArrivedPersons,
    decreaseHubReservationArrivedPersons,
    cancelHubReservation,
    getAllFreeTables,
    onEmptyTableReservation,
    onEmptyTableHubReservation,
    requestReservation,
    increaseTablePersons,
    decreaseTablePersons,
    setReservationTable,
    setHubReservationTable,
    setTablePersons,
} from '../../../store/actions/reservation'
import { createAlert } from '../../../store/actions/alerts'
import { AddReservation } from '../../../components/hub/booked-reservations/AddReservation';
import Modal from 'react-awesome-modal';
//datepicker
import moment from "moment-timezone";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/booked_page/booked-page.css"
// import { AddReservation } from '../../../components/hub/booked-reservations/AddReservation';
import { ReservationsListModal } from '../../../components/hub/booked-reservations/ReservationsListModal';
import { CreateTableModal } from '../../../components/hub/booked-reservations/CreateTableModal';
import { PersonsTableModal } from '../../../components/hub/booked-reservations/PersonsTableModal';
// import ReservationScanner from '../../../components/hub/booked-reservations/ReservationScanner';
const ReservationScanner = lazy(() => import('../../../components/hub/booked-reservations/ReservationScanner'))



export class ApprovedReservationsPage extends Component {
    state = {
        photo: null,
        logo: "",
        note: "-",
        hub_reservation_note: "-",
        table_id: "",
        table_number: "",
        show_tables: "",
        date: new Date(),
        openTab: [],
        notAttended: [],
        tablesModalVisible: false,
        allTablesModalVisible: false,
        areYouSureModalNotAttended: false,
        reservation_search: "",
        attended_tab: [],
        not_attended_tab: [],
        hub_attended_tab: [],
        hub_not_attended_tab: [],
        cancelled_hub_reservations: [],
        reservation_from: "",
        sellected_table: {},
        sellected_table_number: "",
        table_sellected_option: "",
        personsTableModalVisible: false,
        tablesTab: false,
        listTab: true,
        ordersTab: false,
        sellectTableModalVisible: false,
        addReservationModalVisible: false,
        reservationsListModalVisible: false,
        createTableModalVisible: false,
        reservationsScannerModalVisible: false,
        openQrScanner: false,
        table: {},
        changing_note: ""
    }

    componentDidMount() {
        // make date only numbers
        const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getDatesHubReservations(date_id)

        this.props.getHubPage();
        // this.props.getDatesReservations(date_id)
        // this.props.getDatesCancellations(date_id)
        // this.props.getAllCancelledReservations()
        // this.props.getHubAllTables(date_id)
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });

    }

    clickTextBox(id) {
        this.setState({
            changing_note: id
        });
    }

    changeModeOpenTab = (id, note) => (e) => {
        e.preventDefault();
        const meeting_tab = [...this.state.openTab]
        this.setState({
            note: "-"
        });
        if ((meeting_tab.indexOf(id)) !== -1) {
            const new_remove = meeting_tab.filter(f => f !== id)
            this.setState({ openTab: new_remove })
        }
        else {
            if (meeting_tab.length === 0) {
                meeting_tab.push(id)
                this.setState({ openTab: meeting_tab, note: note })
            }
            else {
                meeting_tab.pop()
                meeting_tab.push(id)
                this.setState({ openTab: meeting_tab })
            }
        }
    }




    reservationNotAttended = (id, reservation_table, meeting) => (e) => {
        const reservation_id = id
        const status = "Reservation not Attended"
        const table = reservation_table
        const note = this.state.note
        if (table === "") {
            const answer = { status, note, table, meeting };
            this.props.reservationNotAttended(answer, reservation_id);
            this.addToReservationNotAttended(reservation_id)
        } else {
            const answer = { status, note, table, meeting };
            this.props.reservationNotAttended(answer, reservation_id);
            this.addToReservationNotAttended(reservation_id)

        }
    }

    onCancelHubReservation = (hub_reservation_id, user) => (e) => {
        const status = "Cancelled by Hub"
        const answer = { status, user, hub_reservation_id };
        this.props.cancelHubReservation(answer, hub_reservation_id);

        // const cancelled_hub_reservations_list = this.state.cancelled_hub_reservations
        // cancelled_hub_reservations_list.push(hub_reservation_id)
        // this.setState({ cancelled_hub_reservations: cancelled_hub_reservations_list })
    }

    addToReservationNotAttended(id) {
        const attended_tab = [...this.state.notAttended]
        attended_tab.push(id)
        this.setState({ notAttended: attended_tab })
    }

    updateDateField(date) {
        this.setState({ date: date })
        // make date only numbers
        const correct_datetime = moment(date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getDatesReservations(date_id)
        this.props.getDatesCancellations(date_id)
        this.props.getDatesHubReservations(date_id)

        this.props.getHubAllTables(date_id)
    }

    changeModeTableTab = (reservation_id) => (e) => {
        const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubAllTables(date_id)
        e.preventDefault();
        this.setState({
            show_tables: reservation_id,
            table_id: "",
            table_number: ""
        })
    }

    handleTableChange(table_id, table_number) {
        this.setState({
            table_id: table_id,
            table_number: table_number,
        })
        const id = table_id
        const number = table_number
        const reservation_id = this.state.show_tables
        const table = { id, number, reservation_id };
        this.props.updateReservationTable(table, reservation_id)
        // re-request the reservations to change the sellected table in the ui
        setTimeout(() => {
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getDatesReservations(date_id)
        }, 200);
    }

    handleHubReservationTableChange(table_id, table_number) {
        this.setState({
            table_id: table_id,
            table_number: table_number,
        })
        const id = table_id
        const number = table_number
        const hub_reservation_id = this.state.show_tables
        const table = { id, number, hub_reservation_id };
        this.props.updateHubReservationTable(table, hub_reservation_id)
        // re-request the reservations to change the sellected table in the ui
        setTimeout(() => {
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getDatesHubReservations(date_id)
        }, 200);
    }

    setReservationTable(table_id, table_number, reservation_id) {
        const id = reservation_id
        const table = table_id
        const reservation = { id, table, table_number };
        this.props.setReservationTable(reservation, reservation_id)
    }

    setHubReservationTable(table_id, table_number, hub_reservation_id) {
        const id = hub_reservation_id
        const table = table_id
        const hub_reservation = { id, table, table_number };
        this.props.setHubReservationTable(hub_reservation, hub_reservation_id)
    }

    openAllTablesModal() {
        const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubAllTables(date_id)
        this.setState({
            allTablesModalVisible: true,
        });
    }

    // openAddReservationModal() {
    //     this.setState({
    //         addReservationModalVisible: true,
    //     });
    //     const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
    //     const date1 = correct_datetime.replace(/[^\d.-]/g, '');
    //     const date2 = date1.split('.').join("");
    //     const date_id = date2.split('-').join("");
    //     this.props.getHubAllTables(date_id)
    // }

    openAreYouSureModalNotAttended() {
        this.setState({
            areYouSureModalNotAttended: true,
        });
    }

    openPersonsTableModal(table_id, table_number, table) {
        this.setState({
            personsTableModalVisible: true,
            sellected_table: table_id,
            sellected_table_number: table_number,
            table: table
        });
    }



    updateAllTablesDateField(date_id) {
        this.props.getHubAllTables(date_id)
    }

    getDatesTables(date_id) {
        this.props.getHubAllTables(date_id)
    }

    changeReservationNote = (reservation_id) => (e) => {
        const note = this.state.note
        const data = { note, reservation_id }
        this.props.updateReservationNote(data, reservation_id)
        setTimeout(() => {
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getDatesReservations(date_id)
            this.setState({
                note: "-"
            });
        }, 200);
    }


    changeHubReservationNote = (hub_reservation_id) => (e) => {
        const note = this.state.hub_reservation_note
        const id = hub_reservation_id
        const data = { note, id }
        this.props.updateHubReservationNote(data, hub_reservation_id)
        setTimeout(() => {
            this.setState({
                hub_reservation_note: "-"
            });
        }, 400);
    }


    openTablesModal(table_id, reservation_from) {
        this.setState({
            tablesModalVisible: true,
            sellected_table: table_id,
            reservation_from: reservation_from,
        });
    }

    openCreateTableModal() {
        this.setState({
            createTableModalVisible: true,
        });
    }

    closeModal() {
        this.setState({
            tablesModalVisible: false,
            allTablesModalVisible: false,
            areYouSureModalNotAttended: false,
            sellectTableModalVisible: false,
            addReservationModalVisible: false,
            reservationsListModalVisible: false,
            createTableModalVisible: false,
            personsTableModalVisible: false,
            reservationsScannerModalVisible: false,
            openQrScanner: false,
            show_tables: "",
            sellected_table: {},
            sellected_table_number: "",
            reservation_from: "",
            table_sellected_option: "",
        });
        setTimeout(() => {
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getDatesReservations(date_id)
            this.props.getDatesHubReservations(date_id)
            this.props.getHubAllTables(date_id)
        }, 2500);
    }

    increaseTablePersons = (table_id, persons_now) => (e) => {
        this.props.increaseTablePersons(table_id)
    }

    decreaseTablePersons = (table_id, persons_now) => (e) => {
        if (persons_now !== 0) {
            this.props.decreaseTablePersons(table_id)
        }
    }

    increaseHubReservationArrivedPersons = (reservation_id, reservation_persons, attended_persons) => (e) => {
        e.preventDefault();
        const attended_tab = this.state.hub_attended_tab
        const not_attended_tab = this.state.hub_not_attended_tab
        const up_number = attended_tab.filter(f => f === reservation_id)
        const down_number = not_attended_tab.filter(f => f === reservation_id)
        const total_attendand = attended_persons + up_number.length + down_number.length
        if (reservation_persons > total_attendand) {
            if ((not_attended_tab.indexOf(reservation_id)) !== -1) {
                const new_remove = not_attended_tab.filter(f => f !== reservation_id)
                this.setState({ hub_not_attended_tab: new_remove })
            } else {
                attended_tab.push(reservation_id)
                this.setState({ hub_attended_tab: attended_tab })
            }
        }
        this.props.increaseHubReservationArrivedPersons(reservation_id)
    }


    decreaseHubReservationArrivedPersons = (reservation_id, reservation_persons, attended_persons) => (e) => {
        e.preventDefault();
        const attended_tab = this.state.hub_attended_tab
        const not_attended_tab = this.state.hub_not_attended_tab
        const down_number = not_attended_tab.filter(f => f === reservation_id).length
        const up_number = attended_tab.filter(f => f === reservation_id).length
        if ((attended_persons + up_number + down_number - 1) > -1) {
            if ((attended_tab.indexOf(reservation_id)) !== -1) {
                const new_remove = attended_tab.filter(f => f !== reservation_id)
                this.setState({ hub_attended_tab: new_remove })
            } else {
                not_attended_tab.push(reservation_id)
                this.setState({ hub_not_attended_tab: not_attended_tab })
            }
        }
        this.props.decreaseHubReservationArrivedPersons(reservation_id)
    }

    emptyReservationTable = (reservation_id) => (e) => {
        e.preventDefault();
        this.props.onEmptyTableReservation(reservation_id)
    }

    emptyHubReservationTable = (hub_reservation_id) => (e) => {
        e.preventDefault();
        this.props.onEmptyTableHubReservation(hub_reservation_id)
    }

    openSellectedTableModal(table_id, table_number, table_sellected_option) {
        this.setState({
            sellectTableModalVisible: true,
            sellected_table: table_id,
            sellected_table_number: table_number,
            table_sellected_option: table_sellected_option
            // reservation_from: reservation_from,
        });
    }

    openAddReservationModal() {
        this.setState({
            addReservationModalVisible: true,
            sellectTableModalVisible: false,
            reservationsListModalVisible: false,
        });
    }

    openChooseReservationListModal() {
        this.setState({
            reservationsListModalVisible: true,
            addReservationModalVisible: false,
            sellectTableModalVisible: false,
        });
    }

    openReservationScannerModal() {
        this.setState({
            reservationsScannerModalVisible: true,
            openQrScanner: true,
        });
    }

    changeTablesMode = () => {
        this.setState({
            tablesTab: true,
            listTab: false,
            ordersTab: false,
        })
        // this.props.getApprovedMemories();
    }

    changeListMode = () => {
        this.setState({
            tablesTab: false,
            listTab: true,
            ordersTab: false,
        })
        // this.props.getUnreviewedMemories();
    }

    changeOrdersMode = () => {
        this.setState({
            tablesTab: false,
            listTab: false,
            ordersTab: true,
        })
        // this.props.getDeclinedMemories();
    }


    refreshPage() {
        const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubAllTables(date_id)
        this.props.getHubPage();
        this.props.getDatesReservations(date_id)
        this.props.getDatesCancellations(date_id)
        this.props.getDatesHubReservations(date_id)
        this.props.getAllCancelledReservations()
    }

    acceptHubReservationRequest = (id) => (e) => {
        const reservation_id = id
        const status = "Pending"
        const note = this.state.note
        const answer = { id, note, status };
        this.props.acceptHubReservationRequest(answer, reservation_id);
    }

    declineHubReservationRequest = (id) => (e) => {
        const reservation_id = id
        const status = "Declined by the Hub"
        const note = this.state.note
        const answer = { id, note, status };
        this.props.declineHubReservationRequest(answer, reservation_id);
    }

    handleBack() {
        this.props.history.goBack()
    }

    render() {
        // datepicker button
        const ExampleCustomInput = ({ value, onClick }) => (
            <strong onClick={onClick}>
                {value}
            </strong>
        );
        // 
        const today = new Date();
        const today_date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
        const sellected_day = this.state.date;
        const sellected_date = sellected_day.getDate() + "-" + (sellected_day.getMonth() + 1) + "-" + sellected_day.getFullYear();
        // let reservations = this.props.reservations.filter(reservation => reservation.reservation_name.includes(this.state.reservation_search))
        let hub_reservations = this.props.hub_reservations

        if (this.state.reservation_search !== "") {
            hub_reservations = hub_reservations.filter(hub_reservation => hub_reservation.reservation_name.includes(this.state.reservation_search))
        }

        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "pending-reservations-lottie-appear" : "pending-reservations-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}

                <HubDesktopNavbar data={{ sellected: "booked" }} />
                {/* <div class={this.props.isLoading ? "pending-reservations-section-disappear" : null}> */}
                <div>
                    <div class="whole-hub-reservations-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row color-7">
                                {/* <HubMobileTraffic data={{ hub: this.props.hub }} /> */}



                                <div class="col-xl-12 body-right-7  hide-main-item ">
                                    {this.props.hub.map(hub => (
                                        <>
                                            <div class="hub-mobile-traffic-mobile-navbar">
                                                <Link to="/hub-homepage">
                                                    <div class="back-button-hub-mobile-traffic-box">
                                                        <img
                                                            class="hub-mobile-traffic-back-button"

                                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                </Link>
                                                {hub.name}
                                            </div>

                                            <div class="hub-booked-page-position">
                                                <div class="pending-hub_reservation-section-title">
                                                    Reservations
                                                </div>

                                                <div class="hub-booked-day-box">
                                                    <DatePicker
                                                        selected={this.state.date}
                                                        onChange={this.updateDateField.bind(this)}
                                                        // minDate={new Date()}
                                                        locale="GTM+2"
                                                        name="date"
                                                        dateFormat="dd/MM/yyyy"
                                                        todayButton="Press for Today"
                                                        customInput={<ExampleCustomInput />}
                                                        withPortal
                                                    />
                                                </div>

                                                <center>
                                                    <div class="hub-table-item mt-5">
                                                        <div class="">

                                                            {this.props.hub_reservations.length === 0 ?

                                                                <div class="text-center bokked_reservation_empty-image">

                                                                    <div class="pending-hub_reservation_empty-icon">
                                                                        <img
                                                                            class="pending-hub_reservationempty-created"
                                                                            src={require("../../../assets/booked_page/svg/no-reservations.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    <div>
                                                                        <p class="text-blue-empty-pending-hub">
                                                                            No Reservations
                                                                        </p>
                                                                        <div class="text-small-empty-pending-hub">
                                                                            When you'll have a reservation at
                                                                            <DatePicker
                                                                                selected={this.state.date}
                                                                                onChange={this.updateDateField.bind(this)}
                                                                                // minDate={new Date()}
                                                                                locale="GTM+2"
                                                                                name="date"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                customInput={<ExampleCustomInput />}
                                                                                withPortal
                                                                            />
                                                                            it'll appear here
                                                                        </div>
                                                                    </div>
                                                                    <div class="pending-hub_reservation_gdpr-links">
                                                                        <HubGdprLinks />
                                                                    </div>
                                                                </div>

                                                                :
                                                                <>

                                                                    {hub_reservations.map(hub_reservation => (

                                                                        <>

                                                                            {hub_reservation.from_hub ?
                                                                                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 pending-hub-reservation-item-col">
                                                                                    <div class="pending-hub-reservation-item-of-table ">
                                                                                        <div class="row">

                                                                                            <div class="hub-reservation-reservation-name-top">
                                                                                                {hub_reservation.reservation_name}
                                                                                            </div>

                                                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w texttext-left">


                                                                                                <div class="pending-hub_reservation-meeting-info">
                                                                                                    {/* <div>
                                                                                                        Requested by:
                                                                                                    </div> */}
                                                                                                    <br />
                                                                                                    <div>
                                                                                                        Phone Number:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Day:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Date:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        At:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Persons:
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="pending-hub_reservation-meeting-data">
                                                                                                    <br />
                                                                                                    {/* <div>
                                                                                                        <b>{hub_reservation.reservation_name}</b>
                                                                                                    </div> */}
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.telephone}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.day}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.date}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.time}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.persons}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <br />
                                                                                            <div class="pending-hub_reservation-note-box">
                                                                                                <div class="pending-hub_reservation-message-title">
                                                                                                    Note to me
                                                                                                </div>
                                                                                                <div
                                                                                                    onClick={() => this.clickTextBox(hub_reservation.id)}
                                                                                                    class="pending-hub_reservation-message">
                                                                                                    {this.state.hub_reservation_note === "-" ?
                                                                                                        <textarea
                                                                                                            name="hub_reservation_note"
                                                                                                            onChange={this.onChange.bind(this)}
                                                                                                            value={hub_reservation.note}
                                                                                                            placeholder="Note to me..."
                                                                                                            class="form-control"
                                                                                                            id="exampleFormControlTextarea1"
                                                                                                            rows="3"
                                                                                                        />
                                                                                                        : this.state.changing_note === hub_reservation.id ?
                                                                                                            <textarea
                                                                                                                name="hub_reservation_note"
                                                                                                                onChange={this.onChange.bind(this)}
                                                                                                                value={this.state.hub_reservation_note}
                                                                                                                placeholder="Note to me..."
                                                                                                                class="form-control"
                                                                                                                id="exampleFormControlTextarea1"
                                                                                                                rows="3"
                                                                                                            />
                                                                                                            :
                                                                                                            <textarea
                                                                                                                name="hub_reservation_note"
                                                                                                                onChange={this.onChange.bind(this)}
                                                                                                                value={hub_reservation.note}
                                                                                                                placeholder="Note to me..."
                                                                                                                class="form-control"
                                                                                                                id="exampleFormControlTextarea1"
                                                                                                                rows="3"
                                                                                                            />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.state.hub_reservation_note === "-" ?
                                                                                                null
                                                                                                : this.state.hub_reservation_note !== hub_reservation.note && this.state.changing_note === hub_reservation.id ?
                                                                                                    <div
                                                                                                        onClick={this.changeHubReservationNote(hub_reservation.id)}
                                                                                                        class="approved-reservations-save-button">
                                                                                                        Save
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                            {hub_reservation.status === "Pending" ?
                                                                                                <div class="pending-hub_reservation-buttons">
                                                                                                    <div
                                                                                                        onClick={this.onCancelHubReservation(hub_reservation.id)}
                                                                                                        // onClick={this.onCancelHubReservation(hub_reservation.id, hub_reservation.user)}
                                                                                                        class="pending-hub_reservation-btn-not-attended">
                                                                                                        Cancel Reservation
                                                                                                    </div>
                                                                                                </div>
                                                                                                : hub_reservation.status === "Cancelled by Hub" ?
                                                                                                    <div class="pending-hub_reservation-buttons">
                                                                                                        <div class="pending-hub_reservation-btn-cancelled">
                                                                                                            Cancelled
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            <br />
                                                                                            <br /><br />

                                                                                        </div>
                                                                                    </div>
                                                                                    <br />
                                                                                    <br />
                                                                                </div>

                                                                                :
                                                                                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 pending-hub-reservation-item-col">
                                                                                    <div class="pending-hub-reservation-item-of-table ">
                                                                                        <div class="row">
                                                                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w text-center">
                                                                                                {hub_reservation.reservation_user_photo ?
                                                                                                    <img
                                                                                                        class="pending-hub-reservation-m-t"
                                                                                                        src={hub_reservation.reservation_user_photo}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    :
                                                                                                    <img
                                                                                                        class="pending-hub-reservation-m-t"
                                                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                }
                                                                                                <br />
                                                                                                {/* <strong class="pending-hub-reservation-d-b pending-hub-reservation-creator-name-color">{hub_reservation.hub_name}</strong> */}
                                                                                            </div>

                                                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w texttext-left">
                                                                                                <div class="pending-hub_reservation-meeting-info">
                                                                                                    <div>
                                                                                                        Requested by:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Phone Number:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Day:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Date:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        At:
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        Persons:
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="pending-hub_reservation-meeting-data">
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.creator}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.telephone}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.day}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.date}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.time}</b>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <b>{hub_reservation.persons}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="pending-hub_reservation-note-box">
                                                                                                <div class="pending-hub_reservation-message-title">
                                                                                                    Message from the client
                                                                                                </div>
                                                                                                {hub_reservation.reservation_request_note ?
                                                                                                    <div class="pending-hub_reservation-message">
                                                                                                        {hub_reservation.reservation_request_note}
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div class="pending-hub_reservation-message">
                                                                                                        -
                                                                                                    </div>
                                                                                                }
                                                                                            </div>

                                                                                            <div class="pending-hub_reservation-note-box">
                                                                                                <div class="pending-hub_reservation-message-title">
                                                                                                    Note to me
                                                                                                </div>
                                                                                                <div
                                                                                                    onClick={() => this.clickTextBox(hub_reservation.id)}
                                                                                                    class="pending-hub_reservation-message">
                                                                                                    {this.state.hub_reservation_note === "-" ?
                                                                                                        <textarea
                                                                                                            name="hub_reservation_note"
                                                                                                            onChange={this.onChange.bind(this)}
                                                                                                            value={hub_reservation.note}
                                                                                                            placeholder="Note to me..."
                                                                                                            class="form-control"
                                                                                                            id="exampleFormControlTextarea1"
                                                                                                            rows="3"
                                                                                                        />
                                                                                                        : this.state.changing_note === hub_reservation.id ?
                                                                                                            <textarea
                                                                                                                name="hub_reservation_note"
                                                                                                                onChange={this.onChange.bind(this)}
                                                                                                                value={this.state.hub_reservation_note}
                                                                                                                placeholder="Note to me..."
                                                                                                                class="form-control"
                                                                                                                id="exampleFormControlTextarea1"
                                                                                                                rows="3"
                                                                                                            />
                                                                                                            :
                                                                                                            <textarea
                                                                                                                name="hub_reservation_note"
                                                                                                                onChange={this.onChange.bind(this)}
                                                                                                                value={hub_reservation.note}
                                                                                                                placeholder="Note to me..."
                                                                                                                class="form-control"
                                                                                                                id="exampleFormControlTextarea1"
                                                                                                                rows="3"
                                                                                                            />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.state.hub_reservation_note === "-" ?
                                                                                                null
                                                                                                : this.state.hub_reservation_note !== hub_reservation.note && this.state.changing_note === hub_reservation.id ?
                                                                                                    <div
                                                                                                        onClick={this.changeHubReservationNote(hub_reservation.id)}
                                                                                                        class="approved-reservations-save-button">
                                                                                                        Save
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }


                                                                                            <div class="pending-hub_reservation-meeting-status">
                                                                                                {hub_reservation.status === "Requested" ?
                                                                                                    <div class="pending-hub_reservation-meeting-requested">
                                                                                                        Reservetion Requested
                                                                                                    </div>
                                                                                                    :
                                                                                                    hub_reservation.status === "Pending" ?
                                                                                                        <div class="pending-hub_reservation-meeting-approved">
                                                                                                            Reservation Approved
                                                                                                        </div>

                                                                                                        : hub_reservation.status === "Cancelled by User" ?
                                                                                                            <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                                Cancelled by {hub_reservation.creator}
                                                                                                            </div>
                                                                                                            : hub_reservation.status === "Cancelled by Hub" ?
                                                                                                                <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                                    Cancelled by the Hub
                                                                                                                </div>
                                                                                                                : hub_reservation.status === "Declined by the Hub" ?
                                                                                                                    <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                                        Declined by the Hub
                                                                                                                    </div>
                                                                                                                    : hub_reservation.status === "Updated by User" ?
                                                                                                                        <div class="pending-hub_reservation-meeting-requested">
                                                                                                                            Update Requested
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                }
                                                                                            </div>
                                                                                            {hub_reservation.status === "Requested" ?
                                                                                                <div class="pending-hub_reservation-buttons">
                                                                                                    <div
                                                                                                        onClick={this.acceptHubReservationRequest(hub_reservation.id)}
                                                                                                        class="pending-hub_reservation-btn-accept">
                                                                                                        Accept
                                                                                                    </div>

                                                                                                    <div
                                                                                                        onClick={this.declineHubReservationRequest(hub_reservation.id)}
                                                                                                        class="pending-hub_reservation-btn-decline">
                                                                                                        Decline
                                                                                                    </div>
                                                                                                </div>
                                                                                                : hub_reservation.status === "Pending" ?
                                                                                                    <div class="pending-hub_reservation-buttons">
                                                                                                        <div
                                                                                                            onClick={this.onCancelHubReservation(hub_reservation.id, hub_reservation.user)}
                                                                                                            class="pending-hub_reservation-btn-not-attended">
                                                                                                            Cancel Reservation
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : hub_reservation.status === "Declined by the Hub" ?
                                                                                                        <div class="pending-hub_reservation-buttons">
                                                                                                            <div class="pending-hub_reservation-btn-answerd">
                                                                                                                Declined
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : hub_reservation.status === "Updated by User" ?
                                                                                                            <div class="pending-hub_reservation-buttons">
                                                                                                                <div class="pending-hub_reservation-btn-accept">
                                                                                                                    Accept
                                                                                                                </div>
                                                                                                                <div class="pending-hub_reservation-btn-decline">
                                                                                                                    Decline
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                            }
                                                                                            <br />
                                                                                            <br /><br />

                                                                                        </div>
                                                                                    </div>
                                                                                    <br />
                                                                                    <br />
                                                                                </div>
                                                                            }
                                                                        </>

                                                                    ))}
                                                                </>

                                                            }



                                                        </div>
                                                    </div>
                                                </center>

                                            </div>
                                        </>
                                    ))}
                                </div>

                            </div >
                        </div >

                    </div >

                </div >

                {/* <HubBottomNavbar data={{ sellected: "booked" }} /> */}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hub: state.hub.hub,
    reservations: state.reservation.reservations,
    hub_reservations: state.reservation.hub_reservations,
    cancellations: state.reservation.cancellations,
    numbers: state.reservation.numbers,
    tables: state.reservation.tables,
    free_tables: state.reservation.free_tables,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    user_profile: state.profile.user_profile,
});

export default connect(mapStateToProps, {
    getHubPage,
    updateReservationNote,
    reservationNotAttended,
    createTable,
    getAllCancelledReservations,
    getDatesCancellations,
    getBookedReservations,
    getDatesReservations,
    getHubFreeTables,
    getHubAllTables,
    updateReservationTable,
    getHubPendingNotificationsStatus,
    getHubCancellationsNotificationsStatus,
    acceptHubReservationRequest,
    declineHubReservationRequest,
    // increaseReservationArrivedPersons,
    // decreaseReservationArrivedPersons,
    getAddReservationProfile,
    createNewReservation,
    createAlert,
    getDatesHubReservations,
    updateHubReservationTable,
    increaseHubReservationArrivedPersons,
    decreaseHubReservationArrivedPersons,
    cancelHubReservation,
    getAllFreeTables,
    onEmptyTableReservation,
    onEmptyTableHubReservation,
    increaseTablePersons,
    decreaseTablePersons,
    setTableStatus,
    setReservationTable,
    setHubReservationTable,
    setTablePersons,
    updateHubReservationNote
})(ApprovedReservationsPage);




// <Fragment>
// <div class={this.props.isLoading ? "pending-reservations-lottie-appear" : "pending-reservations-lottie-dissappear"}>
//     <Hubst3rLottie />
// </div>
// <HubDesktopNavbar data={{ sellected: "booked" }} />
// <div class={this.props.isLoading ? "pending-reservations-section-disappear" : null}>
//     <Modal visible={this.state.tablesModalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
//         <TablesModal
//             data={{
//                 reservation_from: "",
//                 tables: this.props.tables,
//                 sellected_table: this.state.sellected_table,
//                 closeModal: this.closeModal.bind(this),
//                 handleTableChange: this.handleTableChange.bind(this),
//                 handleHubReservationTableChange: this.handleHubReservationTableChange.bind(this),
//                 reservation_from: this.state.reservation_from,
//             }} />
//     </Modal>
//     <Modal visible={this.state.allTablesModalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
//         <AllTablesModal
//             getAllFreeTables={this.props.getAllFreeTables}
//             data={{
//                 tables: this.props.tables,
//                 free_tables: this.props.free_tables,
//                 sellected_table: this.state.sellected_table,
//                 closeModal: this.closeModal.bind(this),
//                 sellected_date: this.state.date,
//                 updateAllTablesDateField: this.updateAllTablesDateField.bind(this),
//             }}
//         />
//     </Modal>
//     <Modal visible={this.state.addReservationModalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
//         <AddReservation
//             data={{
//                 user_profile: this.props.user_profile,
//                 tables: this.props.tables,
//                 sellected_table: this.state.sellected_table,
//                 closeModal: this.closeModal.bind(this),
//                 sellected_date: this.state.date,
//                 getDatesTables: this.getDatesTables.bind(this),
//                 submitAddNewReservation: this.submitAddNewReservation.bind(this),
//             }}
//             getAddReservationProfile={this.props.getAddReservationProfile}
//             createNewReservation={this.props.createNewReservation}
//             createAlert={this.props.createAlert}
//         />
//     </Modal>

//     <div class="whole-hub-reservations-page-layout">
//         <OfflineLabel />
//         <div class="container-fluid">
//             <div class="row color-7">
//                 <HubMobileTraffic data={{ hub: this.props.hub }} />
//                 <div class="col-xl-12 body-right-7  hide-main-item ">
//                     <HubDesktopTraffic data={{ hub: this.props.hub }} />

//                     <div class="hub-box">
//                         <div class="">
//                             <div class="col-xl-3 col-lg-2 col-md-1 col-sm-1"></div>
//                             <div class="col-xl-6 col-lg-8 col-md-10 col-sm-10 pending-numbers-postition">
//                                 <div class="row row-postition">
//                                     <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-3 text-center hub-box-container">
//                                         <div class="pending-hub-box-item">
//                                             <img class="reservation-icons-size" src={require("../../../assets/booked_page/svg/Group 818.svg")} alt="" />
//                                             <br />
//                                             <div class="hub-number">
//                                                 <strong>{this.props.reservations.length + this.props.hub_reservations.length}</strong>
//                                             </div>
//                                             <h6 class="hub-metrics-text">Reservations</h6>
//                                         </div>
//                                         <div class="hub-small-text">
//                                             <h6 class="hub-metrics-text">Reservations</h6>
//                                         </div>
//                                     </div>
//                                     <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-3 text-center hub-box-container">
//                                         <div class="pending-hub-box-item">
//                                             <img class="reservation-icons-size" src={require("../../../assets/booked_page/svg/Profile.svg")} alt="" />
//                                             <br />
//                                             <div class="hub-number">
//                                                 <strong>{total_reservation_persons}</strong>
//                                             </div>
//                                             <h6 class="hub-metrics-text">Persons</h6>
//                                         </div>
//                                         <div class="hub-small-text">
//                                             <h6 class="hub-metrics-text">Persons</h6>
//                                         </div>
//                                     </div>
//                                     <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-3 text-center hub-box-container">
//                                         <div class="pending-hub-box-item">
//                                             <img class="reservation-icons-size" src={require("../../../assets/booked_page/svg/cancellations.svg")} alt="" />
//                                             <br />
//                                             <div class="hub-number">
//                                                 <strong>{this.props.cancellations.length}</strong>
//                                             </div>
//                                             <h6 class="hub-metrics-text">Cancelations</h6>
//                                         </div>
//                                         <div class="hub-small-text">
//                                             <h6 class="hub-metrics-text">Cancelations</h6>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div class="col-xl-3 col-lg-2 col-md-1 col-sm-1"></div>
//                         </div>
//                     </div>
//                     <br /><br />
//                     <div class="text-center">
//                         <h2><strong class="text-blue">Booked Reservations</strong></h2>
//                     </div>

//                     <div class="hub-today-box text-center">
//                         <DatePicker
//                             selected={this.state.date}
//                             onChange={this.updateDateField.bind(this)}
//                             // minDate={new Date()}
//                             locale="GTM+2"
//                             name="date"
//                             dateFormat="dd/MM/yyyy"
//                             customInput={<ExampleCustomInput />}
//                             withPortal
//                         />
//                     </div>

//                     <div class="booked-tables-icon-position">
//                         <span>
//                             <div
//                                 onClick={() => this.openAllTablesModal()}
//                                 class="hub-table-round-buttons hub-tables-round-button-unpressed">
//                                 <img
//                                     class="all-reservation-tables-icon"
//                                     src={require("../../../assets/booked_page/svg/tables-icon.svg")}
//                                     alt=""
//                                 />
//                             </div>
//                         </span>
//                         <h5 class="all-tables-text">
//                             Tables of<br />the date
//                         </h5>
//                     </div>

//                     <div class="new-reservation-icon-position">
//                         <span>
//                             <div
//                                 onClick={() => this.openAddReservationModal()}
//                                 class="hub-table-round-buttons hub-tables-round-button-unpressed">
//                                 <img
//                                     class="all-reservation-tables-icon"
//                                     src={require("../../../assets/booked_page/svg/add-reservation.svg")}
//                                     alt=""
//                                 />
//                             </div>
//                         </span>
//                         <div class="new-reservation-text-box">
//                             <h5 class="new-reservation-text text-center">
//                                 Create new<br />Reservation
//                             </h5>
//                         </div>
//                     </div>


//                     <div class="total-arrived-persons-position">
//                         <div class="total-arrived-box-item">
//                             <h6 class="hub-metrics-text">Total Persons</h6>
//                             <div class="hub-number">
//                                 <strong>{total_persons_arrived}</strong>
//                             </div>
//                             <h6 class="hub-metrics-text">in the Hub</h6>

//                         </div>
//                     </div>



//                     <center>
//                         <br />
//                         <br />    <br />
//                         <br />
//                         {/* <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}> */}
//                         <div className="booked-reservations-search-box">
//                             <div className="reservations-search-top">
//                                 <div className="form-inline w-100">
//                                     <img class="reservations-input-icon-search" src={require("../../../assets/gags/svg/Icon.svg")} alt="" />
//                                     <input
//                                         className="form-control mr-sm-2 w-100 py-4 reservations-search-form-text"
//                                         type="text"
//                                         name="reservation_search"
//                                         onChange={this.onChange}
//                                         placeholder="Search reservation"
//                                         value={this.state.user_search}
//                                         aria-label="Search"
//                                         id="field-to-show"
//                                     />

//                                 </div>
//                             </div>
//                         </div>
//                         {/* </ScrollLink> */}
//                         <br />

//                         <div class="hub-table-item mt-5">
//                             <div class="">
//                                 {reservations.map(reservation => (
//                                     <div class="col-xl-12 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
//                                         <div class="hub-item-of-table">
//                                             <div class="row">
//                                                 {today_date === sellected_date ?
//                                                     <>
//                                                         <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
//                                                             <div class="booked-attending-persons-box">
//                                                                 <div class="booked-attending-arrow-circles">
//                                                                     <img
//                                                                         onClick={this.increaseReservationArrivedPersons(reservation.id, reservation.persons, reservation.persons_arrived)}
//                                                                         class="up-toggle1 booked-attending-arrows"
//                                                                         src={require("../../../assets/booked_page/svg/attended-up.svg")}
//                                                                         alt=""
//                                                                     />
//                                                                 </div>
//                                                                 <div class="attending-sellect-box">
//                                                                     <div class="attending-sellect-persons-number">
//                                                                         {reservation.persons_arrived}
//                                                                     </div>
//                                                                 </div>
//                                                                 <div class="booked-attending-arrow-circles">
//                                                                     <img
//                                                                         onClick={this.decreaseReservationArrivedPersons(reservation.id, reservation.persons, reservation.persons_arrived)}
//                                                                         class="toggle1 booked-attending-arrows"
//                                                                         src={require("../../../assets/booked_page/svg/attended-down.svg")}
//                                                                         alt=""
//                                                                     />
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div class="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2">
//                                                             <div class="hub-left-img">
//                                                                 {reservation.reservation_user_photo ?
//                                                                     <img
//                                                                         class="creator-image-layout-attending"
//                                                                         src={reservation.reservation_user_photo}
//                                                                         alt=""
//                                                                     />
//                                                                     :
//                                                                     <img
//                                                                         class="creator-image-layout-attending"
//                                                                         src={require("../../../assets/authentication/svg/account.svg")}
//                                                                         alt=""
//                                                                     />
//                                                                 }
//                                                                 <br />
//                                                                 <strong class="booked-reservation-name">{reservation.reservation_name}</strong>
//                                                             </div>
//                                                         </div>
//                                                     </>
//                                                     :
//                                                     <div class="col-xl-5 col-lg-4 col-md-4 col-sm-4 col-4">
//                                                         <div class="hub-left-img">
//                                                             {reservation.reservation_user_photo ?
//                                                                 <img
//                                                                     class="creator-image-layout"
//                                                                     src={reservation.reservation_user_photo}
//                                                                     alt=""
//                                                                 />
//                                                                 :
//                                                                 <img
//                                                                     class="creator-image-layout"
//                                                                     src={require("../../../assets/authentication/svg/account.svg")}
//                                                                     alt=""
//                                                                 />
//                                                             }
//                                                             <br />
//                                                             <strong class="hub-metrics-text">{reservation.reservation_name}</strong>
//                                                         </div>
//                                                     </div>
//                                                 }

//                                                 <div class="col-xl-7 col-lg-8 col-md-8 col-sm-8 col-8 all-row">
//                                                     <div class="hub-first-row hub-first-row-blue">
//                                                         <strong class="reservations-date-day-position">{reservation.day}</strong>
//                                                         <span class="booked-page-time-position">{reservation.time}</span>
//                                                         <br />
//                                                         <strong class="reservations-date-day-position">{reservation.date}</strong>
//                                                     </div>
//                                                     <div class="hub-second-row my-3">
//                                                         <img class="table-persons-icons-size" src={require("../../../assets/booked_page/svg/Public Icon blue.svg")} alt="" />
//                                                         <strong class="hub-metrics-text">Persons</strong>
//                                                         <p class="hub-four">{reservation.persons}</p>
//                                                     </div>
//                                                     <div class="hub-third-row">

//                                                         <span>
//                                                             <img class="table-persons-icons-size" src={require("../../../assets/booked_page/svg/Group 813 blue.svg")} alt="" />
//                                                             <strong class="hub-metrics-text">Table</strong>
//                                                             <div class="hub-group-sellected" onClick={() => this.openTablesModal(reservation.table, "user")}>
//                                                                 <div
//                                                                     onClick={this.changeModeTableTab(reservation.id)}
//                                                                     class="reservations-table-number-round-button"
//                                                                 >
//                                                                     {reservation.table_number}
//                                                                 </div>
//                                                             </div>
//                                                         </span>
//                                                     </div>
//                                                 </div>
//                                                 {this.state.openTab.includes(reservation.id) ?
//                                                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//                                                         <div class="hub-arrow-down1 p-3 text-left">
//                                                             <strong class="hub-metrics-text">Note for me: </strong>
//                                                             <div class="form-group">
//                                                                 <textarea
//                                                                     placeholder={reservation.note}
//                                                                     name="note"
//                                                                     onChange={this.onChange.bind(this)}
//                                                                     value={this.state.note === "-" ? reservation.note : this.state.note}
//                                                                     class="form-control"
//                                                                     id="exampleFormControlTextarea1"
//                                                                     rows="3"
//                                                                 >
//                                                                 </textarea>
//                                                                 {this.state.note === "-" ?
//                                                                     null
//                                                                     :
//                                                                     this.state.note !== reservation.note ?
//                                                                         <div
//                                                                             onClick={this.changeReservationNote(reservation.id)}
//                                                                             class="approved-reservations-save-button">
//                                                                             Save
//                                                                         </div>
//                                                                         :
//                                                                         null
//                                                                 }
//                                                             </div>
//                                                             <div class="hub-second-row hub-drinks-row my-3  text-left">
//                                                                 <strong class="hub-metrics-text">Reservation For: </strong>
//                                                                 <span class="hub-reservation-activity-text">{reservation.activities}</span>
//                                                             </div>
//                                                             <div class="btn-row row text-center">
//                                                                 <div
//                                                                     // onClick={this.addToReservationNotAttended(reservation.id)}
//                                                                     class="col-xl-12 text-center red-btn"
//                                                                 >
//                                                                     {this.state.notAttended.includes(reservation.id) ?
//                                                                         <button
//                                                                             class="btn"
//                                                                         >Not Attended
//                                                                         </button>
//                                                                         : reservation.status === "Reservation not Attended" ?
//                                                                             <button
//                                                                                 class="btn"
//                                                                             >Not Attended
//                                                                             </button>
//                                                                             : reservation.status === "Happened" ?
//                                                                                 <button
//                                                                                     class="btn"
//                                                                                 >Happened
//                                                                                 </button>
//                                                                                 :
//                                                                                 <>
//                                                                                     <Modal visible={this.state.areYouSureModalNotAttended} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
//                                                                                         <AreYouSure
//                                                                                             data={{
//                                                                                                 sellected: "reservation-not-attended",
//                                                                                                 functionToRun: this.reservationNotAttended(reservation.id, reservation.table, reservation.meeting).bind(this),
//                                                                                                 closeModal: this.closeModal.bind(this)
//                                                                                             }}
//                                                                                         />
//                                                                                     </Modal>
//                                                                                     {today_date === sellected_date ?
//                                                                                         <>
//                                                                                             {reservation.persons_arrived ?
//                                                                                                 <div class="booked-cancel-hub-reservation-layout">
//                                                                                                     <div
//                                                                                                         class="booked-done-hub-reservation"
//                                                                                                         onClick={this.emptyReservationTable(reservation.id)}
//                                                                                                     >
//                                                                                                         Table Empty
//                                                                                                     </div>
//                                                                                                 </div>
//                                                                                                 :
//                                                                                                 <div class="booked-cancel-hub-reservation-layout">
//                                                                                                     <div
//                                                                                                         class="booked-cancel-hub-reservation"
//                                                                                                         onClick={() => this.openAreYouSureModalNotAttended()}                                                                                                                    >
//                                                                                                         Not Attended
//                                                                                                     </div>
//                                                                                                 </div>
//                                                                                             }
//                                                                                         </>
//                                                                                         :
//                                                                                         null
//                                                                                     }
//                                                                                 </>
//                                                                     }
//                                                                 </div>
//                                                             </div>
//                                                             <div class="col-xl-12 text-center" onClick={this.changeModeTableTab(reservation.id)}>
//                                                                 <img
//                                                                     onClick={this.changeModeOpenTab(reservation.id)}
//                                                                     class="up-toggle1"
//                                                                     src={require("../../../assets/meeting/svg/Path 625-2.svg")}
//                                                                     alt="" />
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                     :
//                                                     <div class="hub-arrow-down m-auto">
//                                                         <img
//                                                             onClick={this.changeModeOpenTab(reservation.id, reservation.note)}
//                                                             class="toggle1"
//                                                             src={require("../../../assets/booked_page/svg/arrow down blue.svg")}
//                                                             alt="" />
//                                                     </div>
//                                                 }
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))}


//                                 {hub_reservations.map(reservation => (
//                                     <>
//                                         {this.state.cancelled_hub_reservations.includes(reservation.id) ?
//                                             null
//                                             :
//                                             <div class="col-xl-12 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
//                                                 <div class="hub-item-of-table">
//                                                     <div class="row">
//                                                         {today_date === sellected_date ?
//                                                             <>
//                                                                 <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
//                                                                     <div class="booked-attending-persons-box">
//                                                                         <div class="booked-attending-arrow-circles">
//                                                                             <img
//                                                                                 onClick={this.increaseHubReservationArrivedPersons(reservation.id, reservation.persons, reservation.persons_arrived)}
//                                                                                 class="up-toggle1 booked-attending-arrows"
//                                                                                 src={require("../../../assets/booked_page/svg/attended-up.svg")}
//                                                                                 alt=""
//                                                                             />
//                                                                         </div>
//                                                                         <div class="attending-sellect-box">
//                                                                             <div class="attending-sellect-persons-number">
//                                                                                 {reservation.persons_arrived}
//                                                                             </div>
//                                                                         </div>
//                                                                         <div class="booked-attending-arrow-circles">
//                                                                             <img
//                                                                                 onClick={this.decreaseHubReservationArrivedPersons(reservation.id, reservation.persons, reservation.persons_arrived)}
//                                                                                 class="toggle1 booked-attending-arrows"
//                                                                                 src={require("../../../assets/booked_page/svg/attended-down.svg")}
//                                                                                 alt=""
//                                                                             />
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                                 <div class="col-xl-3 col-lg-2 col-md-2 col-sm-2 col-2">
//                                                                     <div class="hub-left-img">
//                                                                         {reservation.reservation_user_photo ?
//                                                                             <img
//                                                                                 class="creator-image-layout-attending"
//                                                                                 src={reservation.reservation_user_photo}
//                                                                                 alt=""
//                                                                             />
//                                                                             :
//                                                                             <img
//                                                                                 class="creator-image-layout-attending"
//                                                                                 src={require("../../../assets/authentication/svg/account.svg")}
//                                                                                 alt=""
//                                                                             />
//                                                                         }
//                                                                         <br />
//                                                                         <div class="booked-reservation-name-position text-center">
//                                                                             <strong class="booked-reservation-name">{reservation.reservation_name}</strong>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </>
//                                                             :
//                                                             <div class="col-xl-5 col-lg-4 col-md-4 col-sm-4 col-4">
//                                                                 <div class="hub-left-img">
//                                                                     {reservation.reservation_user_photo ?
//                                                                         <img
//                                                                             class="creator-image-layout"
//                                                                             src={reservation.reservation_user_photo}
//                                                                             alt=""
//                                                                         />
//                                                                         :
//                                                                         <img
//                                                                             class="creator-image-layout"
//                                                                             src={require("../../../assets/authentication/svg/account.svg")}
//                                                                             alt=""
//                                                                         />
//                                                                     }
//                                                                     <br />
//                                                                     <strong class="hub-metrics-text">{reservation.reservation_name}</strong>
//                                                                 </div>
//                                                             </div>
//                                                         }

//                                                         <div class="col-xl-7 col-lg-8 col-md-8 col-sm-8 col-8 all-row">
//                                                             <div class="hub-first-row hub-first-row-grey">
//                                                                 <strong class="reservations-date-day-position">{reservation.day}</strong>
//                                                                 <span class="booked-page-time-position">{reservation.time}</span>
//                                                                 <br />
//                                                                 <strong class="reservations-date-day-position">{reservation.date}</strong>
//                                                             </div>
//                                                             <div class="hub-second-row my-3">
//                                                                 <img class="table-persons-icons-size" src={require("../../../assets/booked_page/svg/Public Icon grey.svg")} alt="" />
//                                                                 <strong class="hub-metrics-text">Persons</strong>
//                                                                 <p class="hub-four">{reservation.persons}</p>
//                                                             </div>
//                                                             <div class="hub-third-row">
//                                                                 <span>
//                                                                     <img class="table-persons-icons-size" src={require("../../../assets/booked_page/svg/Group 813 grey.svg")} alt="" />
//                                                                     <strong class="hub-metrics-text">Table</strong>
//                                                                     <div class="hub-group-sellected" onClick={() => this.openTablesModal(reservation.table, "hub")}>
//                                                                         <div
//                                                                             onClick={this.changeModeTableTab(reservation.id)}
//                                                                             class="reservations-table-number-round-button"
//                                                                         >
//                                                                             {reservation.table_number}
//                                                                         </div>
//                                                                     </div>
//                                                                 </span>
//                                                             </div>

//                                                         </div>
//                                                         {reservation.note ?
//                                                             <div class="hub-reservation-note">
//                                                                 <p class="text-blue-note">
//                                                                     <br />
//                                                                     {reservation.note}
//                                                                 </p>
//                                                             </div>
//                                                             :
//                                                             null
//                                                         }

//                                                         {today_date === sellected_date ?
//                                                             <>
//                                                                 {reservation.persons_arrived ?
//                                                                     <div class="booked-cancel-hub-reservation-layout">
//                                                                         <div
//                                                                             class="booked-done-hub-reservation"
//                                                                             onClick={this.emptyHubReservationTable(reservation.id)}
//                                                                         >
//                                                                             Table Empty
//                                                                         </div>
//                                                                     </div>
//                                                                     :
//                                                                     <div class="booked-cancel-hub-reservation-layout">
//                                                                         <div
//                                                                             class="booked-cancel-hub-reservation"
//                                                                             onClick={this.onCancelHubReservation(reservation.id, reservation.user)}                                                                                                                 >
//                                                                             Not Attended
//                                                                         </div>
//                                                                     </div>
//                                                                 }
//                                                             </>
//                                                             :
//                                                             <div class="booked-cancel-hub-reservation-layout">
//                                                                 <div
//                                                                     class="booked-cancel-hub-reservation"
//                                                                     onClick={this.onCancelHubReservation(reservation.id, reservation.user)}                                                                                                                 >
//                                                                     Cancel
//                                                                 </div>
//                                                             </div>
//                                                         }



//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         }
//                                     </>
//                                 ))}

//                                 {cancellations.map(cancellation => (
//                                     <div class="col-xl-12 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
//                                         <div class="hub-item-of-table">
//                                             <div class="row">
//                                                 <div class="col-xl-5 col-lg-4 col-md-4 col-sm-4 col-4">
//                                                     <div class="hub-left-img">
//                                                         {cancellation.reservation_user_photo ?
//                                                             <img
//                                                                 class="creator-image-layout"
//                                                                 src={cancellation.reservation_user_photo}
//                                                                 alt=""
//                                                             />
//                                                             :
//                                                             <img
//                                                                 class="creator-image-layout"
//                                                                 src={require("../../../assets/authentication/svg/account.svg")}
//                                                                 alt=""
//                                                             />
//                                                         }
//                                                         <br />
//                                                         <strong class="hub-metrics-text">{cancellation.reservation_name}</strong>                                                                    </div>
//                                                 </div>
//                                                 <div class="col-xl-7 col-lg-8 col-md-8 col-sm-8 col-8 all-row">
//                                                     <div class="hub-first-row hub-first-row-red">
//                                                         <strong class="reservations-date-day-position">{cancellation.day}</strong>
//                                                         <span class="booked-page-time-position">{cancellation.time}</span>
//                                                         <br />
//                                                         <strong class="reservations-date-day-position">{cancellation.date}</strong>
//                                                     </div>


//                                                     <div class="hub-second-row my-3">
//                                                         <img class="table-persons-icons-size" src={require("../../../assets/booked_page/svg/Public Icon.svg")} alt="" />
//                                                         <strong class="hub-metrics-text">Persons</strong>
//                                                         <p class="hub-four">{cancellation.persons}</p>
//                                                     </div>
//                                                     <div class="hub-third-row my-3">
//                                                         <img class="table-persons-icons-size" src={require("../../../assets/booked_page/svg/Group 813.svg")} alt="" />
//                                                         <strong class="text-blue">Table</strong>
//                                                         <p class="hub-four">{cancellation.table}</p>
//                                                     </div>
//                                                 </div>
//                                                 {this.state.openTab.includes(cancellation.id) ?
//                                                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//                                                         <div class="hub-arrow-down1 p-3 text-left">
//                                                             <strong class="hub-metrics-text">Note for me: </strong>
//                                                             <div class="form-group">
//                                                                 <textarea
//                                                                     class="form-control"
//                                                                     id="exampleFormControlTextarea1"
//                                                                     rows="3"
//                                                                     placeholder="Note:"
//                                                                 >
//                                                                     {cancellation.note}
//                                                                 </textarea>
//                                                             </div>
//                                                             <div class="hub-second-row hub-drinks-row my-3  text-left">
//                                                                 <strong class="hub-metrics-text">Reservation For: </strong>
//                                                                 <span class="hub-reservation-activity-text">{cancellation.activities}</span>
//                                                             </div>
//                                                             <div class="col-xl-12 text-center" onClick={this.changeModeTableTab(cancellation.id)}>
//                                                                 <img
//                                                                     onClick={this.changeModeOpenTab(cancellation.id)}
//                                                                     class="up-toggle1"
//                                                                     src={require("../../../assets/meeting/svg/Path 625.svg")}
//                                                                     alt="" />
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     :
//                                                     <div class="hub-arrow-down m-auto">
//                                                         <img
//                                                             onClick={this.changeModeOpenTab(cancellation.id)}
//                                                             class="toggle1"
//                                                             src={require("../../../assets/booked_page/svg/arrow down violet.svg")}
//                                                             alt="" />
//                                                     </div>
//                                                 }
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))}
//                                 <br /><br /><br /><br />
//                                 <HubGdprLinks />
//                                 <br /><br />
//                             </div>
//                         </div>
//                     </center>
//                     <br />
//                     <br />
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// <HubBottomNavbar data={{ sellected: "booked" }} />
// </Fragment >
