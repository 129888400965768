import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import {
    getHubMemories,
    createMemoryReaction,
    deleteReaction,
    createMemoryView,
    getMemoryTags,
    clearMemoryTags,
} from '../../../store/actions/memory';
import { getUserHubPage, getHubWorkingDays, getHubActivities } from '../../../store/actions/hub';
import { getNotificationsStatus } from '../../../store/actions/notification';
import { requestAppointment } from '../../../store/actions/appointment';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import DescriptionModal from '../../../components/user/homepage/DescriptionModal';
import MapModal from '../../../components/user/homepage/MapModal';
import ShowMoreModal from '../../../components/user/hub-page/showMoreModal';
import EnjoyHereModal from '../../../components/user/hub-page/enjoyHereModal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import WifiModal from '../../../components/user/meeting/WifiModal';
import { getMyProfile } from '../../../store/actions/profile';
import "../../../assets/hub/hub-page/hub-page.css";
import Modal from 'react-awesome-modal';
import Stories from 'react-insta-stories';
import moment from "moment-timezone";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HubPageMap from '../../../components/user/hub-page/HubPageMap';

export class HubPage extends Component {
    state = {
        workingModalVisible: false,
        descriptionModalVisible: false,
        mapModalVisible: false,
        showMoreModalVisible: false,
        enjoyHereModalVisible: false,
        wifiModalVisible: false,
        new_memory: "",
        memory_photo: null,
        memory_submited: false,
        hub_id: "",
        lat: "",
        lng: "",
        hub_name: "",
        requestAppointmentModal: false,
        appointment_request_note: "-",
        telephone: "-",
        showstories: false,
        one_person: true,
        memories_user_id: "",
        story_memory_id: "",
        story_index: 0,
        is_story_paused: null,
        mapCenter: {
            lat: 0,
            lng: 0
        },
        myMapCenterLocation: {
            lat: 0,
            lng: 0
        },
        zoom: 13,
    };


    componentDidMount() {

        const props = this.props
        const page_props = this
        window.navigator.geolocation.getCurrentPosition(position => {
            this.setState({
                mapCenter: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                myMapCenterLocation: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                lastRequestedCenter: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                is_rendered: true
            })
            // this.props.getMapPlaces("show_all", position.coords.latitude, position.coords.longitude, 19);
        }, function (error) {
            fetch(`https://geolocation-db.com/json/`)
                .then(response => {
                    return response.json();
                }, "jsonp")
                .then(res => {
                    page_props.setState({
                        mapCenter: {
                            lat: res.latitude,
                            lng: res.longitude
                        },
                        myMapCenterLocation: {
                            lat: res.latitude,
                            lng: res.longitude
                        },
                        lastRequestedCenter: {
                            lat: res.latitude,
                            lng: res.longitude
                        },
                        is_rendered: true
                    })
                }).catch(err => {
                    console.log(err)
                });
        })




        this.props.getUserHubPage(this.props.location.state.hub_id);
        this.props.getHubMemories(this.props.location.state.hub_id);
        this.props.getMyProfile();
        // this.props.getHubActivities(this.props.location.state.hub_id);
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

    openDescriptionModal(description) {
        this.setState({
            descriptionModalVisible: true,
            description: description
        });
    }

    openMapModal(map_url) {
        this.setState({
            mapModalVisible: true,
            map_url: map_url
        });
    }

    openShowMoreModal() {
        this.setState({
            showMoreModalVisible: true,
        });
    }

    openEnjoyHereModal() {
        this.setState({
            enjoyHereModalVisible: true,
        });
    }

    modalWorkingVisible() {
        this.setState({
            workingModalVisible: true
        });
        this.props.getHubWorkingDays(this.props.location.state.hub_id)
    }

    closeModal() {
        this.props.clearMemoryTags()
        this.setState({
            descriptionModalVisible: false,
            mapModalVisible: false,
            workingModalVisible: false,
            showMoreModalVisible: false,
            enjoyHereModalVisible: false,
            wifiModalVisible: false,
            requestAppointmentModal: false,
        });
    }

    openWifiModal(hub_name, wifi_code) {
        this.setState({
            wifiModalVisible: true,
            hub_name: hub_name,
            wifi_code: wifi_code
        });
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    snapMemorySubmit = (hub_id, lat, lng, hub_name) => (e) => {
        e.preventDefault();
        this.setState({
            new_memory: e.target.files[0],
            memory_photo: URL.createObjectURL(e.target.files[0]),
            memory_submited: true,
            hub_id: hub_id,
            lat: lat,
            lng: lng,
            hub_name: hub_name
        })
    };

    openRequestAppointmentModal() {
        this.setState({
            requestAppointmentModal: !this.state.requestAppointmentModal
        })
    }


    userRequestAppointment() {

        let telephone = this.state.telephone
        const appointment_request_note = this.state.appointment_request_note
        const hub_id = this.props.hub[0].id
        if (telephone === "-") {
            this.props.createAlert({ noTimeSelected: "You haven't given a phone number to the Hub!" });
        } else {
            const appointment = { hub_id, telephone, appointment_request_note }
            this.props.requestAppointment(appointment)
            this.setState({
                requestAppointmentModal: false
            })
        }
    }

    closeModal() {
        this.props.clearMemoryTags()
        this.setState({
            descriptionModalVisible: false,
            mapModalVisible: false,
            workingModalVisible: false,
            showMoreModalVisible: false,
            enjoyHereModalVisible: false,
            wifiModalVisible: false,
            requestAppointmentModal: false,
            showstories: false,
            one_person: true,
            memories_user_id: "",
            story_memory_id: "",
        });
    }

    openStoriesModal(user, one_person, story_memory_id) {
        this.setState({
            showstories: true,
            one_person: one_person,
            memories_user_id: user,
            story_memory_id: story_memory_id
        });
    }

    onTouchStart(touchStartEvent) {
        this.setState({
            is_story_paused: true,
            start_swipe_x: touchStartEvent.targetTouches[0].clientX,
            start_swipe_y: touchStartEvent.targetTouches[0].clientY,
        })
    }


    onTouchMove(touchMoveEvent) {
        this.setState({
            swipe_extend_x: touchMoveEvent.targetTouches[0].clientX,
            swipe_extend_y: touchMoveEvent.targetTouches[0].clientY
        })
    }


    onTouchEnd() {
        this.setState({
            is_story_paused: false,
            // storyShowMore: false,
        })
        if (this.state.swipe_extend_x > 100 || this.state.swipe_extend_y > 100) {
            if (this.state.start_swipe_x - this.state.swipe_extend_x < -50) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {
                    const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index - 1
                    if (new_index > -1) {
                        if (this.props.memories.length > new_index) {
                            this.setState({
                                next_user_memory: false,
                                memories_user_id: this.props.memories[new_index].user,
                                story_index: 0,
                            });

                            setTimeout(() => {
                                this.setState({
                                    next_user_memory: true,
                                });
                            }, 100);
                        }
                    } else {
                        this.closeModal()
                    }
                    this.closeStoryShowMore()
                }
            }


            if (this.state.start_swipe_x - this.state.swipe_extend_x > 100) {
                if (this.state.one_person) {
                    this.closeModal()
                } else {

                    const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
                    const new_index = index + 1

                    if (this.props.memories.length > new_index) {
                        this.setState({
                            next_user_memory: false,
                            memories_user_id: this.props.memories[new_index].user,
                            story_index: 0,
                        });

                        setTimeout(() => {
                            this.setState({
                                next_user_memory: true,
                            });
                        }, 100);
                    } else {
                        this.closeModal()
                    }
                }
            }

            if (this.state.start_swipe_y - this.state.swipe_extend_y < -100) {
                if (this.state.storyShowMore) {
                    this.props.clearMemoryTags()
                    this.setState({
                        storyShowMore: false,
                        is_story_paused: false,
                    })
                } else {
                    this.closeModal()
                }

            }

            if (this.state.start_swipe_y - this.state.swipe_extend_y > 100) {
                this.props.getMemoryTags(this.state.story_memory_id)
                this.setState({
                    storyShowMore: true,
                    isStoryPaused: true,
                    is_story_paused: true,
                })
            }
        } else {
            const half_width = window.screen.width / 2
            if (this.state.start_swipe_x < half_width) {
                const memory_stories = this.props.memories
                const new_index = this.state.story_index - 1
                if (new_index > -1) {
                    this.setState({
                        story_memory_id: memory_stories[new_index].id
                    })
                }
            } else {
                const memory_stories = this.props.memories
                const new_index = this.state.story_index + 1
                if (memory_stories.length === new_index) {
                    this.setState({
                        showstories: false,
                    })
                    this.setState({
                        modalVisible: false,
                        memorySettingsModalVisible: false,
                        hubAccessModal: false,
                        showstories: false,
                        one_person: true,
                        memories_user_id: "",
                        story_memory_id: "",
                    });

                }
                if (memory_stories.length !== new_index) {
                    this.setState({
                        story_memory_id: memory_stories[new_index].id
                    })
                }

            }
            this.closeStoryShowMore()
        }

        this.setState({
            start_swipe_x: "",
            start_swipe_y: "",
            swipe_extend_x: "",
            swipe_extend_y: "",
        })
    }

    openStoryShowMore(memory_id) {
        this.props.getMemoryTags(this.state.story_memory_id)
        this.setState({
            storyShowMore: true,
            memory_show_more_id: memory_id,
            is_story_paused: true,
        });
    }

    storyStarted = (e, story_data) => {
        // if (id) {
        this.setState({
            // displayed_memory_id: id.id,
            story_index: e,
            is_story_paused: false,
        })

        setTimeout(() => {
            if (story_data) {
                const my_user = this.props.profile[0].user
                if (my_user !== story_data.memory_user) {
                    const memory = story_data.memory_id
                    const view = { memory }
                    this.props.createMemoryView(view)
                }
            }
        }, 100);

        // }
    }

    userStoriesEnded = e => {
        this.setState({
            story_index: 0,
            is_story_paused: false,
        })
        if (this.state.one_person) {
            this.closeModal()
            this.setState({
                streetViewModal: false
            })
        } else {
            const index = this.props.memories.findIndex(x => x.user === this.state.memories_user_id);
            const new_index = index + 1


            if (this.props.memories.length > new_index) {
                this.setState({
                    next_user_memory: false,
                    memories_user_id: this.props.memories[new_index].user
                });

                setTimeout(() => {
                    this.setState({
                        next_user_memory: true,
                    });
                }, 100);
            } else {
                this.closeModal()
                this.setState({
                    streetViewModal: false
                })
            }
        }
    }

    closeStoryShowMore() {
        this.props.clearMemoryTags()
        this.setState({
            storyShowMore: false,
            is_story_paused: false,
        })
    }

    updateReactionSelection(selected_reaction, memory_id) {
        this.setState({
            reaction_sellected: true,
            reaction: selected_reaction

        })
        const reacted_memory_id = memory_id
        const reaction_status = selected_reaction
        const reaction = { reaction_status, reacted_memory_id }
        this.props.createMemoryReaction(reaction)
        setTimeout(() => {
            if (JSON.parse(localStorage.getItem('ur'))) {
                this.props.getHubMemories(this.props.location.state.hub_id);
            }
        }, 600);

        setTimeout(() => {
            this.setState({
                reaction_sellected: false,
                show_reaction: true,
            })
        }, 1000);
    }

    deleteMikaImpression(memory_id) {

        this.props.deleteReaction(memory_id)
        setTimeout(() => {
            if (JSON.parse(localStorage.getItem('ur'))) {
                this.props.getHubMemories(this.props.location.state.hub_id);
            }
        }, 600);
    }

    handleCenterChanged(lat, lng, zoom) {
        this.setState({
            zoom: zoom,
            mapCenter: {
                lat: lat,
                lng: lng
            }
        })
    };


    render() {
        if (this.state.memory_submited) {
            return <Redirect to={{ pathname: '/post-memory', state: { new_memory: this.state.new_memory, memory_photo: this.state.memory_photo, hub: this.state.hub_id, lat: this.state.lat, lng: this.state.lng, name: this.state.hub_name } }} />
        }

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const memoryTagsSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };


        const { auth } = this.props

        let hub_telephone = "tel:"
        if (this.props.hub[0]) {
            hub_telephone = hub_telephone + this.props.hub[0].telephone
        }

        const screen_width = window.screen.width
        const screen_height = window.screen.height

        let stories = []
        let rendred = false
        const sellected_user_memories = this.props.memories
        let memories_displayed = []

        if (sellected_user_memories.length > 0) {
            const config = {
                delta: 10,
            }
            rendred = true
            sellected_user_memories.map(memory => {
                memories_displayed.push(memory.id)
                let time = moment(memory.timestamp).fromNow()
                const is_story_paused = this.state.is_story_paused
                const story =
                {
                    duration: 10000,
                    memory_user: memory.user,
                    memory_id: memory.id,
                    content: ({ action, isPaused }) => {

                        const pauseStory = () => { action("pause") }
                        if (is_story_paused) {
                            action("pause")
                        }
                        return (
                            <>
                                <div style={{ background: 'pink' }}>
                                    <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                        <div class="hyb-page-story-profile-pic-box">
                                            <img
                                                class="hyb-page-story-profile-pic"
                                                src={memory.user_profile_picture}
                                                alt=""
                                            />
                                        </div>
                                    </Link>
                                    <div class="hyb-page-story-top-text">
                                        {/* <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}> */}
                                        <div class="hyb-page-story-top-heading">
                                            <b>{memory.user_username}</b>
                                            {memory.has_tags ?
                                                <span class="hyb-page-story-top-heading-tags">
                                                    {memory.tags.length === 2 ?
                                                        <>
                                                            &nbsp;with&nbsp; <b> {memory.tagged_user_username} </b>
                                                        </>
                                                        :
                                                        <>
                                                            {memory.tagged_user_username.length > 14 && memory.user_username.length > 14 ?

                                                                <span
                                                                    class="hyb-page-story-top-heading-tags"
                                                                // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                >
                                                                    &nbsp;and&nbsp;
                                                                    <b>{memory.tags.length - 1}&nbsp;others</b>
                                                                </span>

                                                                :
                                                                <span class="hyb-page-story-top-heading-tags">
                                                                    &nbsp;with&nbsp;<b>
                                                                        {memory.tagged_user_username}
                                                                    </b>
                                                                    {/* <br /> */}
                                                                    &nbsp;and&nbsp;
                                                                    <span
                                                                        class="hyb-page-story-top-heading-tags"
                                                                    // onClick={() => this.openTagsModal(memory.id, memory.hub_name, memory.user)}
                                                                    >
                                                                        <b>{memory.tags.length - 2}&nbsp;others</b>
                                                                    </span>
                                                                </span>
                                                            }
                                                        </>
                                                    }

                                                </span>
                                                :
                                                null
                                            }
                                        </div>
                                        {/* </Link> */}
                                        <div class="hyb-page-story-top-subheading1">
                                            {memory.hub_name}
                                        </div>
                                        <div class="hyb-page-story-top-subheading">
                                            {moment(memory.timestamp).fromNow()}
                                        </div>
                                    </div>


                                    <div class="hyb-page-story-photo">
                                        <img class="hyb-page-story-photo-size" src={memory.photo} />
                                    </div>

                                    {this.state.reaction_sellected ?
                                        <>
                                            {this.state.reaction === "love" ?
                                                <div class="memory-reaction-mika-animation elementToFadeInAndOut">
                                                    <img
                                                        class="memory-mika-animation-size"
                                                        // src={require("../../../assets/layout/mika/mika-love-animation.gif")}
                                                        src={require("../../../assets/profile/svg/gif-heart.gif")}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </>
                        );
                    },
                    url: memory.photo,
                }
                stories.push(story)
            })
        }

        const index = sellected_user_memories.findIndex(x => x.id === this.state.story_memory_id);

        return (
            <Fragment>

                {this.state.showstories ?
                    <div class="hyb-page-story-all-black">

                    </div>
                    :
                    null
                }

                {rendred ?
                    <>
                        {this.state.showstories ?
                            <Modal visible={this.state.showstories} width="100%" height="100%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                <>
                                    <div class="hyb-page-story-index">

                                        <div onClick={() => this.closeModal()} class="hyb-page-story-times-box">
                                            <img
                                                onClick={() => this.closeModal()}
                                                class="hyb-page-story-times-img"
                                                src={require("../../../assets/map/svg/story-times.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="hyb-page-memories-full-box"
                                        >


                                            <Stories
                                                preventDefault={true}
                                                stories={stories}
                                                defaultInterval={1500}
                                                width={screen_width}
                                                height={screen_height}
                                                currentIndex={index}
                                                // isPaused={True}
                                                onStoryStart={(e, id) => this.storyStarted(e, id)}
                                                onStoryEnd={e => this.storyStarted(e)}
                                                onAllStoriesEnd={e => this.userStoriesEnded(e)}
                                            />

                                        </div>

                                        <div
                                            onTouchStart={touchStartEvent => this.onTouchStart(touchStartEvent)}
                                            onTouchMove={touchMoveEvent => this.onTouchMove(touchMoveEvent)}
                                            onTouchEnd={() => this.onTouchEnd()}
                                            class="hyb-page-touches-invisible-box">

                                        </div>

                                        {this.state.storyShowMore ?
                                            <>
                                                {sellected_user_memories.length > 0 ?
                                                    <div class="hyb-page-show-more-modal" >


                                                        {sellected_user_memories[this.state.story_index].has_tags ?
                                                            <div class="map-search-show-more-tags-box">
                                                                <Slider {...memoryTagsSettings} >
                                                                    {this.props.tags.map(tag => (
                                                                        // <div class="map-search-show-more-tag-buddy-box">
                                                                        <Link class="map-search-show-more-tag-buddy-box" to={{ pathname: `/user-profile/${tag.user}/profile`, state: { user: tag.user } }}>
                                                                            <div class="map-search-show-more-tag-profile-box">
                                                                                {tag.profile_picture ?
                                                                                    < img
                                                                                        class="map-search-show-more-tag-profile-img"
                                                                                        src={tag.profile_picture}
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        class="map-search-show-more-tag-profile-img"
                                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div class="map-search-show-more-tag-username">
                                                                                {tag.username}
                                                                            </div>
                                                                        </Link>
                                                                    ))}
                                                                </Slider>
                                                            </div>
                                                            :
                                                            <>
                                                                {sellected_user_memories[this.state.story_index].user_profile_picture ?
                                                                    <div class="hyb-page-show-more-single-box">
                                                                        <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                                                            <div class="row hyb-page-show-more-single-row">
                                                                                <div class="hyb-page-show-more-image">
                                                                                    < img
                                                                                        class="hyb-page-show-more-img"
                                                                                        src={sellected_user_memories[this.state.story_index].user_profile_picture}
                                                                                    />
                                                                                </div>

                                                                                {sellected_user_memories[this.state.story_index].user_username}`s Profile

                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    <div class="hyb-page-show-more-single-box">
                                                                        <Link to={{ pathname: `/user-profile/${sellected_user_memories[this.state.story_index].user}/profile`, state: { user: sellected_user_memories[this.state.story_index].user } }}>
                                                                            {sellected_user_memories[this.state.story_index].user_username}`s Profile
                                                                        </Link>
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                        <Link to={{ pathname: '/memory-impressions', state: { memory: sellected_user_memories[this.state.story_index].id } }}>
                                                            <div class="hyb-page-show-more-single-box">
                                                                {sellected_user_memories[this.state.story_index].reactions_number} reactions
                                                            </div>
                                                        </Link>

                                                        <div onClick={() => this.closeStoryShowMore()} class="hyb-page-show-more-x-box">
                                                            x
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </>
                                            :
                                            <div onClick={() => this.openStoryShowMore()}
                                                class="hyb-page-story-swipe-up">
                                                ⌃<br />
                                                Swipe for More
                                            </div>
                                        }


                                        {sellected_user_memories.length > 0 ?
                                            <div class="hyb-page-story-bottom-row">
                                                {sellected_user_memories[this.state.story_index].memory_reaction !== null ?
                                                    <div onClick={() => this.deleteMikaImpression(sellected_user_memories[this.state.story_index].id)} class="hyb-page-story-reactions-xo">
                                                        < img
                                                            class="hyb-page-story-reactions-img"
                                                            src={require("../../../assets/layout/homepage svg/love-red.svg")}
                                                        />
                                                    </div>
                                                    :
                                                    <div onClick={() => this.updateReactionSelection("love", sellected_user_memories[this.state.story_index].id)} class="hyb-page-story-reactions-xo">
                                                        < img
                                                            class="hyb-page-story-reactions-img"
                                                            src={require("../../../assets/layout/homepage svg/white-love.svg")}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </>

                            </Modal>

                            :
                            null
                        }
                    </>
                    :
                    <></>
                }


                <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />

                {this.state.showstories ?
                    null
                    :
                    <>
                        <div>
                            <div class="hub-page-total-page-layout">
                                <OfflineLabel />
                                {this.props.hub.map(hub => (
                                    <div>
                                        {this.state.requestAppointmentModal ?
                                            <Modal visible={this.state.requestAppointmentModal} width="100%" height="70%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                                                        <div class="place-marker-modal-box">
                                                            <img
                                                                onClick={() => this.closeModal()}
                                                                class="hub-page-appointment-modal-times"
                                                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                                                alt=""
                                                            />

                                                            <div class="place-marker-modal-appointment-title">
                                                                Request an Appointment at {hub.name}
                                                            </div>


                                                            <div class="place-marker-modal-appointment-phone-text">
                                                                Insert your Mobile Phone Number
                                                            </div>
                                                            <div class="place-marker-modal-appointment-phone-input">
                                                                <input
                                                                    maxlength="18"
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="telephone"
                                                                    onChange={this.onChange}
                                                                    value={this.state.telephone === "-" ? null : this.state.telephone}
                                                                    placeholder="Mobile Phone Number..."
                                                                />

                                                            </div>

                                                            <div class="place-marker-modal-appointment-small-text">
                                                                Type any preferenses you have for the appointment<br />
                                                                (i.e. time, date, reason of the appointment)
                                                            </div>

                                                            <div class="place-marker-modal-appointment-textrarea">
                                                                <textarea
                                                                    class="form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    value={this.state.appointment_request_note === "-" ? null : this.state.appointment_request_note}
                                                                    placeholder="Message..."
                                                                    rows="5"
                                                                    name="appointment_request_note"
                                                                    onChange={this.onChange}
                                                                ></textarea>
                                                            </div>

                                                            <div onClick={() => this.userRequestAppointment()} class="place-marker-modal-appointment-request-btn">
                                                                Request Appointment
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>
                                            :
                                            null
                                        }

                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="hub-page-nav-color">
                                                    {/* <!-- Small header css start --> */}
                                                    <div class="row bg-linear text-center">


                                                        {/* {hub.wifi_code ?
                                                    <div
                                                        onClick={() => this.openWifiModal(hub.wifi_code)}
                                                        class="hub-page-wifi-position"
                                                    >
                                                        <span>
                                                            <div
                                                                class="hub-page-wifi-round-button hub-page-wifi-round-button-unpressed">
                                                                <img
                                                                    class="hub-page-wifi-icon"
                                                                    src={require("../../../assets/meeting/svg/wireless.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </span>
                                                        <h5 class="hub-page-wifi-text">
                                                            WiFi <br /> Code
                                                        </h5>
                                                    </div>
                                                    :
                                                    null
                                                } */}


                                                        <div id="page-scroll-up" class="hub-page-mobile-navbar">
                                                            <div onClick={() => this.handleBack()} class="back-button-hub-page-box">
                                                                <img
                                                                    class="hub-page-back-button"

                                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            {hub.name.length > 35 ?
                                                                <>
                                                                    {hub.name.slice(0, 33) + "..."}
                                                                </>
                                                                :
                                                                <>
                                                                    {hub.name}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>



                                                    <div class="hub-page-bg-blue">
                                                        <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 body-right">
                                                            <br />
                                                            <br />
                                                            <br />

                                                            <div class="hub-page-hub-category-box">
                                                                <div class="hub-page-hub-category-text">
                                                                    {hub.category}
                                                                </div>


                                                                <div class="row hub-page-all-contacts">

                                                                    {hub.has_instagram ?
                                                                        <div class="hub-page-contacts-box">
                                                                            <div class="hub-page-contacts-img-box">
                                                                                <img
                                                                                    class="hub-page-contacts-img"
                                                                                    src={require("../../../assets/ambassandors/svg/instagram.png")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    {hub.has_facebook ?
                                                                        <div class="hub-page-contacts-box">
                                                                            <div class="hub-page-contacts-img-box">
                                                                                <img
                                                                                    class="hub-page-contacts-img"
                                                                                    src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    {hub.has_telephone ?
                                                                        <div class="hub-page-contacts-box">
                                                                            <div class="hub-page-contacts-img-box" href={hub_telephone}>
                                                                                <a href={hub_telephone}>
                                                                                    <img
                                                                                        class="hub-page-contacts-img"
                                                                                        src={require("../../../assets/ambassandors/svg/phone.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                            {/* <a
                                                                            class="hub-page-hub-category-info-text"
                                                                            href={hub_telephone}
                                                                        >
                                                                            {hub.telephone}
                                                                        </a> */}
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {hub.has_website ?
                                                                        <div class="hub-page-contacts-box">
                                                                            <div class="hub-page-contacts-img-box">
                                                                                <img
                                                                                    class="hub-page-contacts-img"
                                                                                    src={require("../../../assets/ambassandors/svg/website.png")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            {/* <a
                                                                            class="hub-page-hub-category-info-text"
                                                                            href={hub.website}>
                                                                            Website
                                                                        </a> */}
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div class="row hub-page-proile-all-btns">
                                                                {!hub.reservation_acceptance && hub.active_subscription && hub.place_type === "Hospitality" || hub.place_type === "Other" ?
                                                                    <div class="hub-page-proile-btn-no-reservations">
                                                                        Does <b>not Accept</b> Reservations
                                                                    </div>
                                                                    : hub.reservation_acceptance && hub.active_subscription && hub.another_app_reservations && hub.place_type === "Hospitality" || hub.place_type === "Other" ?
                                                                        <>
                                                                            <a onTouchStart class="hub-page-proile-btn-resevaion" href={hub.reservation_app_url}>
                                                                                Reservation through another app
                                                                            </a>
                                                                        </>
                                                                        : hub.reservation_acceptance && hub.active_subscription && hub.telephone_reservations && hub.place_type === "Hospitality" || hub.place_type === "Other" ?
                                                                            <>
                                                                                <a onTouchStart class="hub-page-proile-btn-resevaion" href={hub_telephone}>
                                                                                    Call for Reservation
                                                                                </a>
                                                                            </>
                                                                            : hub.hubst3r_reservations && hub.reservation_acceptance && hub.place_type === "Hospitality" || hub.place_type === "Other" ?
                                                                                <Link onTouchStart class="hub-page-proile-btn-resevaion" to={{ pathname: '/hub/request-reservation', state: { hub: hub } }}>
                                                                                    Reservation through Hubst3r
                                                                                </Link>
                                                                                :
                                                                                null
                                                                }

                                                                {!hub.appointment_acceptance && hub.active_subscription && hub.place_type === "Services" ?
                                                                    <div class="place-marker-modal-no-reservations">
                                                                        Does <b>not Accept</b> Appointments
                                                                    </div>
                                                                    : hub.appointment_acceptance && hub.active_subscription && hub.another_app_appointments && hub.place_type === "Services" ?

                                                                        <a onTouchStart class="hub-page-proile-btn-resevaion" href={hub.appointment_app_url}>
                                                                            Reservation through another app
                                                                        </a>

                                                                        : hub.appointment_acceptance && hub.active_subscription && hub.telephone_appointments && hub.place_type === "Services" ?
                                                                            <a onTouchStart class="hub-page-proile-btn-resevaion" href={hub_telephone}>
                                                                                Call for Appointment
                                                                            </a>

                                                                            : hub.hubst3r_appointments && hub.appointment_acceptance && hub.place_type === "Services" ?
                                                                                <div onTouchStart class="hub-page-proile-btn-resevaion" onClick={() => this.openRequestAppointmentModal()}>
                                                                                    Appointment through Hubst3r
                                                                                </div>
                                                                                :
                                                                                null
                                                                }

                                                                {hub.has_online_shop && hub.place_type === "Shops" ?
                                                                    <a class="hub-page-proile-btn-shop-online" href={hub.shop_url}>
                                                                        Shop Online
                                                                    </a>

                                                                    :
                                                                    null
                                                                }

                                                            </div>

                                                            <div class="row hub-page-proile-all-btns2">
                                                                {hub.place_type === "Hospitality" && hub.has_menu ?
                                                                    <Link onTouchStart class="hub-page-proile-btn-catalof" to={{ pathname: `/menu-page-view/${hub.id}`, state: { hub: hub.id } }}>
                                                                        Menu
                                                                    </Link>
                                                                    : hub.has_menu ?
                                                                        <Link onTouchStart class="hub-page-proile-btn-catalof" to={{ pathname: `/menu-page-view/${hub.id}`, state: { hub: hub.id } }}>
                                                                            Catalog
                                                                        </Link>
                                                                        :
                                                                        null
                                                                }
                                                                {hub.has_delivery_apps ?
                                                                    <div class="hub-page-proile-btn-rder-nline">
                                                                        Order Online
                                                                    </div>
                                                                    :
                                                                    null
                                                                }


                                                            </div>

                                                            <div class="starbucks-item">
                                                                <div class="hub-page-all-info">
                                                                    <div class="starbucks">
                                                                        {/* <img
                                                                            src={hub.logo}
                                                                            height="60px"
                                                                            width="60px"
                                                                            alt="" /> */}


                                                                        {hub.logo ?
                                                                            <img
                                                                                src={hub.logo}
                                                                                height="60px"
                                                                                width="60px"
                                                                                alt="" />
                                                                            :
                                                                            <img
                                                                                src={require("../../../assets/memories/svg/location-pin.svg")}
                                                                                height="40px"
                                                                                width="40px"
                                                                                alt=""
                                                                            />
                                                                        }

                                                                    </div>
                                                                    {this.props.hub.length > 0 ?
                                                                        <div class="hub-page-map-box">
                                                                            <HubPageMap
                                                                                data={{
                                                                                    hubs: this.props.hub,
                                                                                    mapCenter: this.state.mapCenter,
                                                                                    zoom: this.state.zoom,
                                                                                    myMapCenterLocation: this.state.myMapCenterLocation,
                                                                                    handleCenterChanged: this.handleCenterChanged.bind(this),
                                                                                    my_profile: this.props.profile,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/* <div class="row all-hub-page-info">
                                                                {hub.active_subscription ?
                                                                    null
                                                                    :
                                                                    <div class="hub-page-unclaimed-text">
                                                                        <Link to="/hub-contact-us">
                                                                            Unclaimed Location
                                                                        </Link>
                                                                    </div>
                                                                }

                                                            </div> */}

                                                                </div>
                                                            </div>
                                                            <br />
                                                            {/* <div class="hub-page-memory-text">
                                                        <h2>Memories</h2>
                                                    </div> */}
                                                            <br />
                                                            {/* <div class="hub-page-all-memories">
                                                        <div class="row"> */}
                                                            {this.props.memories.length === 0 ?
                                                                <div class="">
                                                                    <p class="text-blue-empty-hub-memories">
                                                                        <br /><br /><br /><br /><br /><br />
                                                                    </p>
                                                                    <div class="hub-page-no-memories-image">
                                                                        <img
                                                                            onClick={() => this.fileInput.click()}
                                                                            class="hub-page-no-memories-img"
                                                                            src={require("../../../assets/hub/hub-page/svg/photo-icon.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    <p onClick={() => this.fileInput.click()}
                                                                        class="text-blue-share-hub-memories">
                                                                        <b>Share a Memory</b>
                                                                    </p>

                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        accept="image/png, image/jpeg"
                                                                        type="file"
                                                                        name="memory_photo"
                                                                        onChange={
                                                                            this.snapMemorySubmit(hub.id, hub.lat, hub.lng, hub.name)
                                                                        }
                                                                        ref={fileInput => this.fileInput = fileInput}
                                                                    />


                                                                    <p class="text-blue-empty-hub-memories">
                                                                        When you and other people<br /><b>share Memories</b> in <b>{hub.name}</b><br />they will appear here
                                                                    </p>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                                :


                                                                <div class="">

                                                                    <p class="text-blue-empty-hub-memories">
                                                                    </p>
                                                                    <div class="hub-page-no-memories-image">
                                                                        <img
                                                                            class="hub-page-no-memories-img-ivisible"
                                                                            src={require("../../../assets/layout/mika/wink.png")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    <div class="hub-page-post-memory">
                                                                        <img
                                                                            onClick={() => this.fileInput.click()}
                                                                            class="hub-page-no-memories-img"
                                                                            src={require("../../../assets/hub/hub-page/svg/photo-icon.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        accept="image/png, image/jpeg"
                                                                        type="file"
                                                                        name="memory_photo"
                                                                        onChange={
                                                                            this.snapMemorySubmit(hub.id, hub.lat, hub.lng, hub.name)
                                                                        }
                                                                        ref={fileInput => this.fileInput = fileInput}
                                                                    />
                                                                    <div class="hub-page-memories-title">
                                                                        Memories
                                                                    </div>
                                                                    <div class="row">
                                                                        {this.props.memories.map(memory => (
                                                                            < div onClick={() => this.openStoriesModal(memory.user, true, memory.id)} class="hub-memory-img-size-box" >
                                                                                {/* <Link to={{ pathname: '/memories', state: { memory: memory.id } }}> */}
                                                                                <img
                                                                                    class="hub-memory-img-size-full"
                                                                                    src={memory.photo}
                                                                                    alt=""
                                                                                />
                                                                                {/* </Link> */}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                </div>

                                                            }
                                                            {/* </div>
                                                    </div> */}

                                                            <div class="footer">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {this.state.requestAppointmentModal ?
                            null
                            :
                            <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                                <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />
                            </ScrollLink>
                        }
                    </>
                }
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hub: state.hub.hub,
    memories: state.memory.hub_memories,
    profile: state.profile.profile,
    working_days: state.hub.working_days,
    activities: state.hub.activities,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    tags: state.memory.tags,
});

export default connect(mapStateToProps, {
    getHubMemories,
    getUserHubPage,
    getHubWorkingDays,
    getHubActivities,
    getMyProfile,
    getNotificationsStatus,
    requestAppointment,
    createMemoryReaction,
    deleteReaction,
    createMemoryView,
    getMemoryTags,
    clearMemoryTags,
})(HubPage);