import { combineReducers } from 'redux';
import auth from './auth';
import authhub from './authhub';
import buddies from './buddies';
import hub from './hub';
import meeting from './meeting';
import memory from './memory';
import notification from './notification';
import profile from './profile';
import ambassandor from './ambassandor';
import reservation from './reservation';
import errors from './errors';
import alerts from './alerts'
import layout from './layout'
import hubst3rActions from './hubst3rActions'
import hubPersonel from './hubPersonel'
import chat from './chat'
import map from './map'
import appointment from './appointment'
import shops from './shops'
import scarlett from './scarlett'

export default combineReducers({
    errors,
    alerts,
    auth,
    authhub,
    buddies,
    hub,
    meeting,
    memory,
    notification,
    profile,
    ambassandor,
    reservation,
    appointment,
    layout,
    hubst3rActions,
    hubPersonel,
    chat,
    map,
    shops,
    scarlett,
});