import * as React from "react";
import { useEffect, useState } from "react";
import { render } from "react-dom";
import useRecorder from "./useRecorder";
import { ReactMic } from 'react-mic';
// import SpeechToText from './speechToText';
import "../../../assets/chat/chat-recorder.css"
import useSpeechToText from 'react-hook-speech-to-text';


function ChatRecorder(props) {
    const [playAudio, setPlayAudio] = useState(false);
    const [showWave, setShowWave] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [mp3Sound, setMp3Sound] = useState("");
    const [textMessage, setTextMessage] = useState("");
    const [textModal, setTextModal] = useState(false);
    const [recordingData, setRecordingData] = useState(false);
    const [localStream, setLocalStream] = useState(null);


    const startAudio = () => {
        setPlayAudio(true)
        setTimeout(() => {
            setPlayAudio(false)
        }, 500);
    }

    let [audioURL, audioFile, isMicRecording, startRecording, stopRecording] = useRecorder();


    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        crossBrowser: true,
        googleApiKey: "",
        useLegacyResults: false

    });

    const onData = (recordedBlob) => {
        // console.log('chunk of real-time data is: ', recordedBlob);
    }

    const onStop = (recordedBlob) => {
        // console.log('recordedBlob is: ', recordedBlob);
        setMp3Sound(recordedBlob.blob)
        props.data.setSoundFile(recordedBlob.blob)
        // console.log(new Audio(recordedBlob.url));

    }


    const pressStartRecording = () => {
        props.data.changeBackgroundColor()
        startRecording()
        setShowWave(true)
        setRecordingData(true)
        startSpeechToText()
    }

    // setTimeout(() => {
    //     // console.log(audioFile)
    //     props.data.setSoundFile(mp3Sound)
    // }, 2000);

    const pressStopRecording = () => {
        props.data.changeBackgroundColor()
        stopRecording()
        setShowWave(false)
        stopSpeechToText()
        let newMessage = ""
        setTimeout(() => {
            setTextModal(true)
        }, 1000);
        props.data.stopRecordingMedia()
    }

    const handleModal = () => {
        setShowModal(!showModal)
    }

    const sendMessageToBuddy = () => {
        const profile = props.data.profile[0]
        const sending_user = profile.user
        const recieving_user = props.data.selected_user
        const voice_message = mp3Sound
        const text_message = textMessage
        const message = { sending_user, recieving_user, voice_message, text_message }
        props.sendVoiceMessage(message)
        props.data.setConversationRedirect()
    }

    const pressOpenSendMessageModal = () => {
        props.data.openSendMessageModal(true)
    }

    const restartRecording = () => {
        window.location.reload(false)
    }

    const onChange = e => {
        if (e.target.value !== "") {
            setTextMessage(e.target.value)
            props.data.setTextMessageFile(e.target.value)

        } else {
            setTextMessage(" ")
            props.data.setTextMessageFile(" ")
        }
    }


    setTimeout(() => {
        if (props.data.changePage) {
            stopRecording()
            setShowWave(false)
            stopSpeechToText()
            props.data.stopRecordingMedia()
        }
    }, 100);





    setTimeout(() => {
        if (results.length > 0 && isMicRecording) {
            let newMessage = ""
            for (const element of results) {
                if (element === results[0]) {
                    newMessage = element.transcript.charAt(0).toUpperCase() + element.transcript.slice(1);
                } else {
                    newMessage = newMessage + "." + element.transcript.charAt(0).toUpperCase() + element.transcript.slice(1);
                }
            }
            setTextMessage(newMessage)
        }
    }, 500);


    return (
        <div className="chat-room-recorder-display">
            {playAudio ?
                <audio
                    src={require("../../../assets/chat/svg/microphon-tap.mp3")}
                    autoplay="true"
                />
                :
                null
            }


            {/* {textModal ? */}
            {recordingData ?
                <>
                    <div class="speech-to-text-bubble-triangle">
                        <img
                            class="speech-to-text-bubble-triangle-img"
                            src={require("../../../assets/chat/svg/chat-bubble.svg")}
                            alt=""
                        />

                    </div>
                    <div class="speech-to-text-box">
                        <div class="speech-to-text-title">
                            My Message
                        </div>
                        {textModal ?
                            <textarea
                                class="speech-to-text-input-box"
                                type="text"
                                // defaultValue={textMessage}
                                onChange={onChange}
                                name="text_message"
                            // placeholder="My Message"
                            >


                                {textMessage}
                            </textarea>
                            :
                            <ul class="speech-to-text-show-text">
                                {results.map((result) => (
                                    <li key={result.timestamp}>{result.transcript}</li>
                                ))}
                                {interimResult && <li>{interimResult}</li>}
                            </ul>

                        }
                    </div>

                    {/* <div class="speech-to-text-restart-btn">Restart</div>
                <div class="speech-to-text-send-btn">Send</div> */}
                </>
                :
                null
            }



            <ReactMic
                record={isMicRecording}
                className="chat-room-sound-wave"
                mimeType='audio/mp3'
                onStop={onStop}
                onData={onData}
                strokeColor="#1183ca"
                backgroundColor="192C2B"
            />



            <>
                {/* {textModal ?
                    null
                    : */}
                <>
                    {!isMicRecording ?
                        <div onClick={pressStartRecording} class="chat-room-start-recording">
                            {/* <div onClick={startSpeechToText}> */}
                            {/* stopSpeechToText : startSpeechToText */}
                            Start Recording
                            {/* </div> */}
                        </div>
                        :
                        <div onClick={pressStopRecording} class="chat-room-stop-recording">
                            {/* <div onClick={stopSpeechToText}> */}
                            Finish Recording
                            {/* </div> */}
                        </div>
                    }
                </>
                {/* } */}

                <div class="chat-room-recorder-start" >
                    <div class="chat-room-recorder-box">
                        <img
                            class="chat-room-microphone-img"
                            onClick={startAudio}
                            src={require("../../../assets/chat/svg/microphone-base.svg")}
                            alt=""
                        />
                    </div>
                    {/* </div> */}
                    {/* <div class="chat-room-recorder-microphone">
                    <img
                        class="chat-room-microphone-img"
                        onClick={startAudio}
                        src={require("../../../assets/chat/svg/microphone-base.svg")}
                        alt=""
                    /> */}
                    {/* <img
                        onClick={startAudio}
                        class="chat-room-microphone-img"
                        src={require("../../../assets/chat/svg/microphone.svg")}
                        alt=""
                    /> */}
                    {!isMicRecording ?
                        <div onClick={startRecording} class="chat-room-microphone-green-light"> </div>
                        :
                        <div onClick={stopRecording} class="chat-room-microphone-red-light"> </div>
                    }
                </div>
            </>
            {audioURL !== "" ?
                <>
                    <audio src={audioURL} class="chat-room-recorder-bar" controls />
                    {props.data.selected_user === "" ?
                        <div onClick={pressOpenSendMessageModal} class="chat-room-send-btn">
                            Send
                        </div>
                        :
                        <div onClick={sendMessageToBuddy} class="chat-room-send-btn">
                            Send
                        </div>
                    }

                </>
                :
                null
            }
            {/* {textModal ?
                <>
                    {audioURL !== "" ?
                        <>
                            <audio src={audioURL} class="chat-room-recorder-bar" controls />

                            {props.data.selected_user === "" ?
                                <div onClick={pressOpenSendMessageModal} class="chat-room-send-btn">
                                    Send
                                </div>
                                :
                                <div onClick={sendMessageToBuddy} class="chat-room-send-btn">
                                    Send
                                </div>
                            }
                            <div onClick={restartRecording} class="chat-room-restart-btn">
                                Restart
                            </div>
                        </>
                        :
                        null
                    }
                </>
                :
                null
            } */}




        </div>
    );
}


export default ChatRecorder