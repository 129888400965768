import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/map/place-marker-modal.css"


export class AddPlaceModal extends Component {

    state = {
        name: "-",
        hub_icon: "",
        lat: "",
        lng: "",
        nameMode: true,
        categoryMode: false,
        doneMode: false
    }

    componentDidMount() {
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }


    closeModal() {
        this.setState({
            requestAppointmentMode: false
        })
        this.props.data.closeModal()
    }


    finishNameMode() {
        this.setState({
            nameMode: false,
            categoryMode: true,
            doneMode: false
        })
    }

    backToNameMode() {
        this.setState({
            nameMode: true,
            categoryMode: false,
            doneMode: false
        })
    }

    selectCategory(category) {
        this.setState({
            hub_icon: category
        })
    }

    finishCategoryMode() {
        this.setState({
            nameMode: false,
            categoryMode: false,
            doneMode: false
        })
    }

    backToCategoryMode() {
        this.setState({
            nameMode: false,
            categoryMode: true,
            doneMode: false
        })
    }

    finishDoneMode() {
        const lat = this.props.data.lat
        const lng = this.props.data.lng
        const name = this.state.name
        const category = this.state.hub_icon
        const new_place = { name, lat, lng, category }
        this.props.createNewUserProspect(new_place)
        this.props.data.closeModal()
    }


    render() {

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const categorySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const categories = [
            "restaurant",
            "coffee shop",
            "lodging",
            "bar",
            "night club",
            // "cafe",
            "food",
            // 'drinks',
            'casino',
            "meal takeaway",
            "cinema",
            "minifootball",
            "sports",
            "bowling alley",
            "art gallery",
            "museum",
            "aquarium",
            "amusement park",
            "zoo",
            "campground",
            "library",
            "rv park",
            "university",
            "clothing store",
            "electronics store",
            "shoe store",
            "supermarket",
            "jewelry store",
            "liquor store",
            "pet store",
            "shopping mall",
            "convenience store",
            "gas station",
            "home goods store",
            "hardware store",
            "pharmacy",
            "florist",
            "furniture store",
            "bicycle store",
            "book store",
            "car dealer",
            "bakery",
            "beauty salon",
            "hair care",
            "spa",
            "doctor",
            "dentist",
            "lawyer",
            "electrician",
            "accounting",
            "bank",
            "gym",
            "veterinary care",
            "insurance agency",
            "travel agency",
            "parking",
            "painter",
            "car rental",
            "physiotherapist",
            "real estate agency",
            "plumber",
            "car repair",
            "car wash",
            "laundry",
            "locksmith"
        ]
        const hub_icon = this.state.hub_icon

        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="place-marker-modal-box">

                            <div onClick={() => this.closeModal()} class="map-search-acces-modal-times-box2">
                                <img
                                    class="map-search-acces-modal-times-img"
                                    src={require("../../../assets/layout/homepage svg/times.svg")}
                                    alt=""
                                />
                            </div>

                            <div class="place-marker-modal-appointment-title">
                                Add a new Place on the Map
                            </div>


                            {this.state.nameMode ?
                                <>
                                    <div class="add-place-modal-place-name-text">
                                        What is the name of the Place?
                                    </div>
                                    <div class="add-place-modal-place-name-input">
                                        <input
                                            maxlength="55"
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            onChange={this.onChange}
                                            value={this.state.name === "-" ? null : this.state.name}
                                            placeholder="Place's Name..."
                                        />

                                    </div>
                                    {this.state.name === "-" ?
                                        <div class="add-place-modal-nextt-btn-grey">
                                            Next
                                        </div>
                                        :
                                        <div
                                            onClick={() => this.finishNameMode()}
                                            class="add-place-modal-nextt-btn-blue">
                                            Next
                                        </div>
                                    }
                                </>
                                : this.state.categoryMode ?
                                    <>
                                        <div class="add-place-modal-place-name2-text">
                                            What icon would you choose for this place?
                                        </div>

                                        <div class="add-place-modal-map_iconslider">
                                            <Slider {...categorySettings} >

                                                {categories.map(category => (
                                                    <>
                                                        {hub_icon === category ?
                                                            <div
                                                                class="add-place-modal-map_iconframe"
                                                                onClick={() => this.selectCategory(category)}
                                                            >
                                                                <>
                                                                    {category === "restaurant" ?
                                                                        <img
                                                                            class="setup-page-map_icon-img"
                                                                            src={require("../../../assets/map/svg/restaurant-blue.svg")}
                                                                            alt=""
                                                                        />
                                                                        : category === "coffee shop" ?
                                                                            <img
                                                                                class="setup-page-map_icon-img"
                                                                                src={require("../../../assets/map/svg/cafe-blue.svg")}
                                                                                alt=""
                                                                            />
                                                                            : category === "bar" ?
                                                                                <img
                                                                                    class="setup-page-map_icon-img"
                                                                                    src={require("../../../assets/map/svg/bar-blue.svg")}
                                                                                    alt=""
                                                                                />
                                                                                : category === "night club" ?
                                                                                    <img
                                                                                        class="setup-page-map_icon-img"
                                                                                        src={require("../../../assets/map/svg/club-blue.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    : category === "food" ?
                                                                                        <img
                                                                                            class="setup-page-map_icon-img"
                                                                                            src={require("../../../assets/map/svg/food-blue.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                        : category === "casino" ?
                                                                                            <img
                                                                                                class="setup-page-map_icon-img"
                                                                                                src={require("../../../assets/map/svg/casino-blue.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                            : category === "meal takeaway" ?
                                                                                                <img
                                                                                                    class="setup-page-map_icon-img"
                                                                                                    src={require("../../../assets/map/svg/takeaway-blue.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                                : category === "clothing store" ?
                                                                                                    <img
                                                                                                        class="setup-page-map_icon-img"
                                                                                                        src={require("../../../assets/map/svg/clothestore-blue.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    : category === "electronics store" ?
                                                                                                        <img
                                                                                                            class="setup-page-map_icon-img"
                                                                                                            src={require("../../../assets/map/svg/electronicsstore-blue.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        : category === "shoe store" ?
                                                                                                            <img
                                                                                                                class="setup-page-map_icon-img"
                                                                                                                src={require("../../../assets/map/svg/shoestore-blue.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                            : category === "supermarket" ?

                                                                                                                <img
                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                    src={require("../../../assets/map/svg/supermarket-blue.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                : category === "jewelry store" ?
                                                                                                                    <img
                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                        src={require("../../../assets/map/svg/jewelry-blue.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "liquor store" ?
                                                                                                                        <img
                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                            src={require("../../../assets/map/svg/liquorstore-blue.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "pet store" ?
                                                                                                                            <img
                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                src={require("../../../assets/map/svg/petstore-blue.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "shopping mall" ?
                                                                                                                                <img
                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/mall-blue.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "convenience store" ?
                                                                                                                                    <img
                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/conviniencestore-blue.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "gas station" ?
                                                                                                                                        <img
                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/gasstation-blue.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "home goods store" ?

                                                                                                                                            <img
                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/homegoodsstore-blue.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "hardware store" ?
                                                                                                                                                <img
                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/hardwarestore-blue.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "pharmacy" ?
                                                                                                                                                    <img
                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/pharmacy-blue.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "florist" ?
                                                                                                                                                        <img
                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/florist-blue.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "furniture store" ?
                                                                                                                                                            <img
                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/furniturestore-blue.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "bicycle store" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/bicyclestore-blue.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "book store" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/bookshop-blue.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "car dealer" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/cardealer-blue.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "bakery" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/bakery-blue.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "beauty salon" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/beautysalon-blue.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "hair care" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/hairsalon-blue.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "spa" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/spa-blue.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "doctor" ?
                                                                                                                                                                                            <img
                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/doctor-blue.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "dentist" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/dentist-blue.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "lawyer" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/lawyer-blue.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "electrician" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/electrician-blue.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "accounting" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/accountant-blue.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "laundry" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/laundry-blue.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "bank" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/bank-blue.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "gym" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/gym-blue.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "veterinary care" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/veterany-blue.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "insurance agency" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/insurance-blue.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "travel agency" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/travel-blue.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "parking" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/parking-blue.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "painter" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/painter-blue.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "car rental" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/rentcar-blue.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "physiotherapist" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/physiotherapist-blue.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "real estate agency" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/realestateagent-blue.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "plumber" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/plumber-blue.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "car repair" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carrepair-blue.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "car wash" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/carwash-blue.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />

                                                                                                                                                                                                                                                                    : category === "locksmith" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/locksmith-blue.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "cinema" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/cinema-blue.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "lodging" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lodging-blue.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : category === "minifootball" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/minifootball-blue.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "sports" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/sports-blue.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/bowling-blue.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/artgallery-blue.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "museum" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/museum-blue.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/aquarium-blue.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/amusementpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/zoo-blue.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                : category === "campground" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/campground-blue.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "library" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/library-blue.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rvpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "university" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/university-blue.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                                                                :
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/place-blue.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                    }


                                                                </>
                                                            </div>
                                                            :
                                                            <div
                                                                class="add-place-modal-map_iconframe"
                                                                onClick={() => this.selectCategory(category)}
                                                            >
                                                                <>
                                                                    {category === "restaurant" ?
                                                                        <img
                                                                            class="setup-page-map_icon-img"
                                                                            src={require("../../../assets/map/svg/restaurant-red.svg")}
                                                                            alt=""
                                                                        />
                                                                        : category === "coffee shop" ?
                                                                            <img
                                                                                class="setup-page-map_icon-img"
                                                                                src={require("../../../assets/map/svg/cafe-red.svg")}
                                                                                alt=""
                                                                            />
                                                                            : category === "bar" ?
                                                                                <img
                                                                                    class="setup-page-map_icon-img"
                                                                                    src={require("../../../assets/map/svg/bar-red.svg")}
                                                                                    alt=""
                                                                                />
                                                                                : category === "night club" ?
                                                                                    <img
                                                                                        class="setup-page-map_icon-img"
                                                                                        src={require("../../../assets/map/svg/club-red.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    : category === "food" ?
                                                                                        <img
                                                                                            class="setup-page-map_icon-img"
                                                                                            src={require("../../../assets/map/svg/food-red.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                        : category === "casino" ?
                                                                                            <img
                                                                                                class="setup-page-map_icon-img"
                                                                                                src={require("../../../assets/map/svg/casino-red.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                            : category === "meal takeaway" ?
                                                                                                <img
                                                                                                    class="setup-page-map_icon-img"
                                                                                                    src={require("../../../assets/map/svg/takeaway-red.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                                : category === "clothing store" ?
                                                                                                    <img
                                                                                                        class="setup-page-map_icon-img"
                                                                                                        src={require("../../../assets/map/svg/clothestore-red.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    : category === "electronics store" ?
                                                                                                        <img
                                                                                                            class="setup-page-map_icon-img"
                                                                                                            src={require("../../../assets/map/svg/electronicsstore-red.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        : category === "shoe store" ?
                                                                                                            <img
                                                                                                                class="setup-page-map_icon-img"
                                                                                                                src={require("../../../assets/map/svg/shoestore-red.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                            : category === "supermarket" ?

                                                                                                                <img
                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                    src={require("../../../assets/map/svg/supermarket-red.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                : category === "jewelry store" ?
                                                                                                                    <img
                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                        src={require("../../../assets/map/svg/jewelry-red.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "liquor store" ?
                                                                                                                        <img
                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                            src={require("../../../assets/map/svg/liquorstore-red.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "pet store" ?
                                                                                                                            <img
                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                src={require("../../../assets/map/svg/petstore-red.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "shopping mall" ?
                                                                                                                                <img
                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/mall-red.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "convenience store" ?
                                                                                                                                    <img
                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/conviniencestore-red.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "gas station" ?
                                                                                                                                        <img
                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/gasstation-red.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "home goods store" ?

                                                                                                                                            <img
                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/homegoodsstore-red.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "hardware store" ?
                                                                                                                                                <img
                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/hardwarestore-red.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "pharmacy" ?
                                                                                                                                                    <img
                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/pharmacy-red.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "florist" ?
                                                                                                                                                        <img
                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/florist-red.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "furniture store" ?
                                                                                                                                                            <img
                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/furniturestore-red.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "bicycle store" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/bicyclestore-red.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "book store" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/bookshop-red.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "car dealer" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/cardealer-red.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "bakery" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/bakery-red.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "beauty salon" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/beautysalon-red.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "hair care" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/hairsalon-red.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "spa" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/spa-red.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "doctor" ?
                                                                                                                                                                                            <img
                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/doctor-red.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "dentist" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/dentist-red.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "lawyer" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/lawyer-red.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "electrician" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/electrician-red.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "accounting" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/accountant-red.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "laundry" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/laundry-red.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "bank" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/bank-red.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "gym" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/gym-red.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "veterinary care" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/veterany-red.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "insurance agency" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/insurance-red.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "travel agency" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/travel-red.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "parking" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/parking-red.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "painter" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/painter-red.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "car rental" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/rentcar-red.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "physiotherapist" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/physiotherapist-red.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "real estate agency" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/realestateagent-red.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "plumber" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/plumber-red.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "car repair" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/carrepair-red.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "car wash" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/carwash-red.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />

                                                                                                                                                                                                                                                                    : category === "locksmith" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/locksmith-red.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                        : category === "cinema" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/cinema-red.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "lodging" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/lodging-red.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : category === "minifootball" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/minifootball-red.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "sports" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/sports-red.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/bowling-red.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/artgallery-red.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "museum" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/museum-red.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/aquarium-red.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/amusementpark-red.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/zoo-red.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                : category === "campground" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/campground-red.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "library" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/library-red.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/rvpark-red.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "university" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/university-red.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />

                                                                                                                                                                                                                                                                                                                                :
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/place-red.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                    }


                                                                </>
                                                            </div>
                                                        }
                                                        <div class="add-place-modal-map_iconname">
                                                            {category}
                                                        </div>
                                                    </>
                                                ))}
                                            </Slider>
                                        </div>





                                        <div onClick={() => this.backToNameMode()} class="add-place-modal-backk-btn-blue">
                                            Back
                                        </div>

                                        {this.state.category === "" ?
                                            <div onClick={() => this.finishCategoryMode()} class="add-place-modal-nextt-btn-blue">
                                                Skip
                                            </div>
                                            :
                                            <div onClick={() => this.finishCategoryMode()} class="add-place-modal-nextt-btn-blue">
                                                Next
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        <div class="add-place-modal-place-name3-text">
                                            Is everything you choose correct?
                                        </div>

                                        <div class="add-place-modal-place-fila-name">
                                            {this.state.name}
                                        </div>
                                        {this.state.hub_icon !== "" ?

                                            <>
                                                {categories.map(category => (
                                                    <>
                                                        {hub_icon === category ?
                                                            <>
                                                                <div class="add-place-modal-finaml-map_iconframe" >
                                                                    <>
                                                                        {category === "restaurant" ?
                                                                            <img
                                                                                class="setup-page-map_icon-img"
                                                                                src={require("../../../assets/map/svg/restaurant-blue.svg")}
                                                                                alt=""
                                                                            />
                                                                            : category === "coffee shop" ?
                                                                                <img
                                                                                    class="setup-page-map_icon-img"
                                                                                    src={require("../../../assets/map/svg/cafe-blue.svg")}
                                                                                    alt=""
                                                                                />
                                                                                : category === "bar" ?
                                                                                    <img
                                                                                        class="setup-page-map_icon-img"
                                                                                        src={require("../../../assets/map/svg/bar-blue.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                    : category === "night club" ?
                                                                                        <img
                                                                                            class="setup-page-map_icon-img"
                                                                                            src={require("../../../assets/map/svg/club-blue.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                        : category === "food" ?
                                                                                            <img
                                                                                                class="setup-page-map_icon-img"
                                                                                                src={require("../../../assets/map/svg/food-blue.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                            : category === "casino" ?
                                                                                                <img
                                                                                                    class="setup-page-map_icon-img"
                                                                                                    src={require("../../../assets/map/svg/casino-blue.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                                : category === "meal takeaway" ?
                                                                                                    <img
                                                                                                        class="setup-page-map_icon-img"
                                                                                                        src={require("../../../assets/map/svg/takeaway-blue.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    : category === "clothing store" ?
                                                                                                        <img
                                                                                                            class="setup-page-map_icon-img"
                                                                                                            src={require("../../../assets/map/svg/clothestore-blue.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        : category === "electronics store" ?
                                                                                                            <img
                                                                                                                class="setup-page-map_icon-img"
                                                                                                                src={require("../../../assets/map/svg/electronicsstore-blue.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                            : category === "shoe store" ?
                                                                                                                <img
                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                    src={require("../../../assets/map/svg/shoestore-blue.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                                : category === "supermarket" ?

                                                                                                                    <img
                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                        src={require("../../../assets/map/svg/supermarket-blue.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                    : category === "jewelry store" ?
                                                                                                                        <img
                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                            src={require("../../../assets/map/svg/jewelry-blue.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                        : category === "liquor store" ?
                                                                                                                            <img
                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                src={require("../../../assets/map/svg/liquorstore-blue.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                            : category === "pet store" ?
                                                                                                                                <img
                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                    src={require("../../../assets/map/svg/petstore-blue.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                                : category === "shopping mall" ?
                                                                                                                                    <img
                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                        src={require("../../../assets/map/svg/mall-blue.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                    : category === "convenience store" ?
                                                                                                                                        <img
                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                            src={require("../../../assets/map/svg/conviniencestore-blue.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                        : category === "gas station" ?
                                                                                                                                            <img
                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                src={require("../../../assets/map/svg/gasstation-blue.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                            : category === "home goods store" ?

                                                                                                                                                <img
                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                    src={require("../../../assets/map/svg/homegoodsstore-blue.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                                : category === "hardware store" ?
                                                                                                                                                    <img
                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                        src={require("../../../assets/map/svg/hardwarestore-blue.svg")}
                                                                                                                                                        alt=""
                                                                                                                                                    />
                                                                                                                                                    : category === "pharmacy" ?
                                                                                                                                                        <img
                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                            src={require("../../../assets/map/svg/pharmacy-blue.svg")}
                                                                                                                                                            alt=""
                                                                                                                                                        />
                                                                                                                                                        : category === "florist" ?
                                                                                                                                                            <img
                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                src={require("../../../assets/map/svg/florist-blue.svg")}
                                                                                                                                                                alt=""
                                                                                                                                                            />
                                                                                                                                                            : category === "furniture store" ?
                                                                                                                                                                <img
                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                    src={require("../../../assets/map/svg/furniturestore-blue.svg")}
                                                                                                                                                                    alt=""
                                                                                                                                                                />
                                                                                                                                                                : category === "bicycle store" ?
                                                                                                                                                                    <img
                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                        src={require("../../../assets/map/svg/bicyclestore-blue.svg")}
                                                                                                                                                                        alt=""
                                                                                                                                                                    />
                                                                                                                                                                    : category === "book store" ?
                                                                                                                                                                        <img
                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                            src={require("../../../assets/map/svg/bookshop-blue.svg")}
                                                                                                                                                                            alt=""
                                                                                                                                                                        />
                                                                                                                                                                        : category === "car dealer" ?
                                                                                                                                                                            <img
                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                src={require("../../../assets/map/svg/cardealer-blue.svg")}
                                                                                                                                                                                alt=""
                                                                                                                                                                            />
                                                                                                                                                                            : category === "bakery" ?
                                                                                                                                                                                <img
                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                    src={require("../../../assets/map/svg/bakery-blue.svg")}
                                                                                                                                                                                    alt=""
                                                                                                                                                                                />
                                                                                                                                                                                : category === "beauty salon" ?
                                                                                                                                                                                    <img
                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                        src={require("../../../assets/map/svg/beautysalon-blue.svg")}
                                                                                                                                                                                        alt=""
                                                                                                                                                                                    />
                                                                                                                                                                                    : category === "hair care" ?
                                                                                                                                                                                        <img
                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                            src={require("../../../assets/map/svg/hairsalon-blue.svg")}
                                                                                                                                                                                            alt=""
                                                                                                                                                                                        />
                                                                                                                                                                                        : category === "spa" ?
                                                                                                                                                                                            <img
                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                src={require("../../../assets/map/svg/spa-blue.svg")}
                                                                                                                                                                                                alt=""
                                                                                                                                                                                            />
                                                                                                                                                                                            : category === "doctor" ?
                                                                                                                                                                                                <img
                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                    src={require("../../../assets/map/svg/doctor-blue.svg")}
                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                />
                                                                                                                                                                                                : category === "dentist" ?
                                                                                                                                                                                                    <img
                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                        src={require("../../../assets/map/svg/dentist-blue.svg")}
                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                    />
                                                                                                                                                                                                    : category === "lawyer" ?
                                                                                                                                                                                                        <img
                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                            src={require("../../../assets/map/svg/lawyer-blue.svg")}
                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                        />
                                                                                                                                                                                                        : category === "electrician" ?
                                                                                                                                                                                                            <img
                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                src={require("../../../assets/map/svg/electrician-blue.svg")}
                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                            />
                                                                                                                                                                                                            : category === "accounting" ?
                                                                                                                                                                                                                <img
                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                    src={require("../../../assets/map/svg/accountant-blue.svg")}
                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                />
                                                                                                                                                                                                                : category === "laundry" ?
                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                        src={require("../../../assets/map/svg/laundry-blue.svg")}
                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    : category === "bank" ?
                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                            src={require("../../../assets/map/svg/bank-blue.svg")}
                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        : category === "gym" ?
                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                src={require("../../../assets/map/svg/gym-blue.svg")}
                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                            : category === "veterinary care" ?
                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/veterany-blue.svg")}
                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                : category === "insurance agency" ?
                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/insurance-blue.svg")}
                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                    : category === "travel agency" ?
                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/travel-blue.svg")}
                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        : category === "parking" ?
                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/parking-blue.svg")}
                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                            : category === "painter" ?
                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/painter-blue.svg")}
                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                : category === "car rental" ?
                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/rentcar-blue.svg")}
                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                    : category === "physiotherapist" ?
                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/physiotherapist-blue.svg")}
                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                        : category === "real estate agency" ?
                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/realestateagent-blue.svg")}
                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                            : category === "plumber" ?
                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/plumber-blue.svg")}
                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                : category === "car repair" ?
                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/carrepair-blue.svg")}
                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                    : category === "car wash" ?
                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/carwash-blue.svg")}
                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                        />

                                                                                                                                                                                                                                                                        : category === "locksmith" ?
                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/locksmith-blue.svg")}
                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                            : category === "cinema" ?
                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/cinema-blue.svg")}
                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                : category === "lodging" ?
                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/lodging-blue.svg")}
                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                    : category === "minifootball" ?
                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/minifootball-blue.svg")}
                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                        : category === "sports" ?
                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/sports-blue.svg")}
                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                            : category === "bowling alley" ?
                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/bowling-blue.svg")}
                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                : category === "art gallery" ?
                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/artgallery-blue.svg")}
                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                    : category === "museum" ?
                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/museum-blue.svg")}
                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                        : category === "aquarium" ?
                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/aquarium-blue.svg")}
                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                            : category === "amusement park" ?
                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/amusementpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                : category === "zoo" ?
                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/zoo-blue.svg")}
                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                                                    : category === "campground" ?
                                                                                                                                                                                                                                                                                                                        <img
                                                                                                                                                                                                                                                                                                                            class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                            src={require("../../../assets/map/svg/campground-blue.svg")}
                                                                                                                                                                                                                                                                                                                            alt=""
                                                                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                                                                        : category === "library" ?
                                                                                                                                                                                                                                                                                                                            <img
                                                                                                                                                                                                                                                                                                                                class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                src={require("../../../assets/map/svg/library-blue.svg")}
                                                                                                                                                                                                                                                                                                                                alt=""
                                                                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                                                                            : category === "rv park" ?
                                                                                                                                                                                                                                                                                                                                <img
                                                                                                                                                                                                                                                                                                                                    class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                    src={require("../../../assets/map/svg/rvpark-blue.svg")}
                                                                                                                                                                                                                                                                                                                                    alt=""
                                                                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                                                                                : category === "university" ?
                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/university-blue.svg")}
                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                    />

                                                                                                                                                                                                                                                                                                                                    :
                                                                                                                                                                                                                                                                                                                                    <img
                                                                                                                                                                                                                                                                                                                                        class="setup-page-map_icon-img"
                                                                                                                                                                                                                                                                                                                                        src={require("../../../assets/map/svg/place-blue.svg")}
                                                                                                                                                                                                                                                                                                                                        alt=""
                                                                                                                                                                                                                                                                                                                                    />
                                                                        }


                                                                    </>
                                                                </div>

                                                                <div class="add-place-modal-map_iconname">
                                                                    {category}
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                ))}
                                            </>

                                            :
                                            null
                                        }

                                        <div onClick={() => this.backToCategoryMode()} class="add-place-modal-backk-btn-blue">
                                            Back
                                        </div>

                                        <div onClick={() => this.finishDoneMode()} class="add-place-modal-nextt-btn-blue">
                                            Done
                                        </div>

                                    </>
                            }
                        </div>
                    </div>
                </div>


            </Fragment >
        )
    }
}


export default (AddPlaceModal);