import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { setHubTraffic, updateHub } from '../../../store/actions/hub'
import "../../../assets/hub/hub-layout/desktopTraffic.css"


export class HubDesktopTraffic extends Component {
    state = {
        traffic: "",
        logo_photo: null,
        logo: "",
    }

    setTrafficToLow = () => (e) => {
        const traffic = "Low"
        const id = this.props.data.hub[0].id
        const answer = { id, traffic };
        this.setState({ traffic: traffic })
        this.props.setHubTraffic(answer, id);
    }

    setTrafficToMedium = () => (e) => {
        const traffic = "Medium"
        const id = this.props.data.hub[0].id
        const answer = { id, traffic };
        this.setState({ traffic: traffic })
        this.props.setHubTraffic(answer, id);
    }

    setTrafficToHigh = () => (e) => {
        const traffic = "High"
        const id = this.props.data.hub[0].id
        const answer = { id, traffic };
        this.setState({ traffic: traffic })
        this.props.setHubTraffic(answer, id);
    }

    onLogoSubmit = (e) => {
        this.setState({
            logo: e.target.files[0],
            logo_photo: URL.createObjectURL(e.target.files[0]),
        })
        setTimeout(() => {
            this.updateLogo()
        }, 4000);
    };

    updateLogo = () => {
        const hub = this.props.data.hub[0]
        const new_logo = this.state.logo
        hub.logo = new_logo
        hub.cover_photo = ""
        hub.promo_photo = ""
        this.props.updateHub(hub)
    }

    render() {
        return (

            <Fragment>
                {this.props.data.hub.map(hub => (
                    <div class="desktop-traffic-layout">
                        <div class="top-logo-section-12 mt-5">
                            <div class="row">

                                <div class="col-xl-3 col-lg-3 hub-starbucks-logo">
                                    {this.state.logo_photo ?

                                        <img
                                            class="hub-logo-size-big"
                                            src={this.state.logo_photo}
                                            onClick={() => this.fileInput.click()}
                                            alt=""
                                        />
                                        :
                                        <img
                                            class="hub-logo-size-big"
                                            src={hub.logo}
                                            onClick={() => this.fileInput.click()}
                                            alt=""
                                        />
                                    }
                                    <input
                                        style={{ display: 'none' }}
                                        accept="image/png, image/jpeg"
                                        type="file"
                                        name="logo_photo"
                                        onChange={
                                            this.onLogoSubmit
                                        }
                                        ref={fileInput => this.fileInput = fileInput}
                                    />
                                    <img class="l-camera1" src={require("../../../assets/hub/hub-info/svg/add-photo.svg")} alt="" />
                                </div>
                                <div class="col-xl-6 col-lg-6 text-center">
                                    {this.state.traffic ?
                                        <div>
                                            <img
                                                onClick={this.setTrafficToLow()}
                                                class={this.state.traffic === "Low" ? "hub-grp" : "hub-grp-small"}
                                                src={require("../../../assets/booked_page/svg/Group 814.svg")}
                                                alt="" />
                                            <img
                                                onClick={this.setTrafficToMedium()}
                                                class={this.state.traffic === "Medium" ? "hub-grp" : "hub-grp-small"}
                                                src={require("../../../assets/booked_page/svg/Group 773.svg")}
                                                alt="" />
                                            <img
                                                onClick={this.setTrafficToHigh()}
                                                class={this.state.traffic === "High" ? "hub-grp" : "hub-grp-small"}
                                                src={require("../../../assets/booked_page/svg/Group 815.svg")}
                                                alt="" />
                                        </div>
                                        :
                                        <div>
                                            <img
                                                onClick={this.setTrafficToLow()}
                                                class={hub.traffic === "Low" ? "hub-grp" : "hub-grp-small"}
                                                src={require("../../../assets/booked_page/svg/Group 814.svg")}
                                                alt="" />
                                            <img
                                                onClick={this.setTrafficToMedium()}
                                                class={hub.traffic === "Medium" ? "hub-grp" : "hub-grp-small"}
                                                src={require("../../../assets/booked_page/svg/Group 773.svg")}
                                                alt="" />
                                            <img
                                                onClick={this.setTrafficToHigh()}
                                                class={hub.traffic === "High" ? "hub-grp" : "hub-grp-small"}
                                                src={require("../../../assets/booked_page/svg/Group 815.svg")}
                                                alt="" />
                                        </div>
                                    }

                                </div>
                                <div class="col-xl-4 col-lg-3"></div>
                            </div>
                        </div>
                    </div>
                ))}
            </Fragment>

        )
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setHubTraffic, updateHub })(HubDesktopTraffic);