import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import axios from 'axios';
import { returnErrors, createAlert } from './alerts';
import {
    GET_SHOP_PRODUCTS,
    GET_SHOP_SHIPPING_CLASSES,
    CHECK_SHOP_OWNER_STATUS,
    GET_SHOP_CATEGORIES,
    GET_ALL_ATRIBUTES,
    GET_ALL_ATRIBUTES_CHOISES,
    UPLOAD_PRODUCT_IMAGE,
    GET_ALL_PRODUCT_VARIATIONS,
    CLEAR_SHOP_FIELDS,
    GET_SHOPS_QR_CODE_PRODUCTS,
    GET_SHOPS_QR_CODE_PRODUCT_VARIATIONS,
    REMOVE_PRODUCT_FROM_QR,
    INSERT_PRODUCT_TO_QR,
    GET_SHOPS_BARCODE_PRODUCTS,
    GET_SHOPS_BARCODE_PRODUCT_VARIATIONS,
    CLEAR_BARCODE_DATA,
    CHECKED_BARCODE_PRODUCTS,
    GET_SHOP_QR_CODES,
    GET_SHOP_QR_CODES_PRODUCTS,
    GET_USER_PRODUCTS,
    GET_MAGAZINE_PRODUCTS,
    REMOVE_PRODUCT_FROM_MAGAZINE,
    INSERT_PRODUCT_TO_MAGAZINE,
    GET_SHOPS_MAGAZINE_PRODUCT_VARIATIONS,
    GET_SHOPS_MAGAZINE_PRODUCTS,
    GET_SHOP_MAGAZINE_PRODUCTS,
    GET_USER_MAGAZINE_PRODUCTS,
    GET_MAGAZINE_COVER,
    SHOP_LOGOUT_SUCCESS,
    FETCHING_CATEGORIES,
    GET_SHOP_ALL_CHILD_CATEGORIES,
    REMOVE_SHOP_ALL_CHILD_CATEGORIES,
    GET_SHOP_BULK_PRODUCTS,
    NEW_CATEGORY_CREATED
} from './actionTypes';
import { tokenConfig } from './auth'; 


let api
if (JSON.parse(localStorage.getItem('shp'))){
    if (JSON.parse(localStorage.getItem('shp')).length>0){
        const shops = JSON.parse(localStorage.getItem('shp'))
        const woocommerce_url_name = shops[0].woocommerce_url
        const consumer_key_name = shops[0].shop_consumer_key_name
        const consumer_secret_name = shops[0].shop_consumer_secret_name


        const one = woocommerce_url_name
        const two = process.env[consumer_key_name]
        const thr = process.env[consumer_secret_name]
        const cor_thr = thr.replace(/^"(.*)"$/, '$1')
        const corrected_thr = String(cor_thr.replace(/^'(.*)'$/, '$1'))

        api = new WooCommerceRestApi({
            url: one,
            consumerKey: two,
            consumerSecret: corrected_thr,
            version: "wc/v3"
        });
        
    }
} 


export const createNewShopCategory = (new_category) => (dispatch, getstate) => {
    const data = {
        name: new_category.name,
        image: new_category.image,
        parent: new_category.parent
    };
    console.log(data)
    api.post("products/categories", data)
    .then((res) => {
        dispatch({
            type: NEW_CATEGORY_CREATED,
            payload: res.data
        });
    })
    .catch((error) => {
        console.log(error.response.data);
    });
}


export const checkShopOnwerStatus = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user-shops-access/`, tokenConfig())
        .then(res => {
            dispatch({
                type: CHECK_SHOP_OWNER_STATUS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getShopProducts = (category_id) => (dispatch, getstate) => {
    api.get("products", {
        per_page: 60, // 20 products per page
        category: category_id
    })
        .then((response) => {
            dispatch({
                type: GET_SHOP_PRODUCTS,
                payload: response.data
            })
        })
        .catch((error) => {
            console.log("Response Status:", error.response.status);
            console.log("Response Headers:", error.response.headers);
            console.log("Response Data:", error.response.data);
        })
        .finally(() => {
            // Always executed.
        })
    
}


export const getShopShippingClasses = () => (dispatch, getstate) => {
    api.get("products/shipping_classes").then((response) => {
            dispatch({
                type: GET_SHOP_SHIPPING_CLASSES,
                payload: response.data
            })
        })
        .catch((error) => {
            console.log(error.response.data);
    });
}

export const getShopCategories = (shop_category_id) => (dispatch, getstate) => {
    dispatch({
        type: FETCHING_CATEGORIES,
    })
    api.get("products/categories", { per_page: 40 })
        .then((response) => {
            dispatch({
                type: GET_SHOP_CATEGORIES,
                payload: response.data
            })
        })
        .catch((error) => {
            console.log(error.response.data);
        });
}

export const getShopAllChildCategories = (shop_category_ids) => (dispatch, getstate) => {
    for (let i = 0; i < shop_category_ids.length; i++) {
        api.get("products/categories", { parent: shop_category_ids[i], per_page: 80 })
        .then((response) => {
            // console.log(response.data)
            for (let i = 0; i < response.data.length; i++) {
                dispatch({
                    type: GET_SHOP_ALL_CHILD_CATEGORIES,
                    payload: response.data[i].id
                })
            }
        })
        .catch((error) => {
            console.log(error.response.data);
        });     
    }
}

export const removeShopAllChildCategories = (shop_category_ids) => (dispatch, getstate) => {
    for (let i = 0; i < shop_category_ids.length; i++) {
        api.get("products/categories", { parent: shop_category_ids[i], per_page: 80 })
        .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                dispatch({
                    type: REMOVE_SHOP_ALL_CHILD_CATEGORIES,
                    payload: response.data[i].id
                })
            }
        })
        .catch((error) => {
            console.log(error.response.data);
        });     
    }
}

export const getAllAtributes = () => (dispatch, getstate) => {
    api.get("products/attributes")
        .then((response) => {
            dispatch({
                type: GET_ALL_ATRIBUTES,
                payload: response.data
            })
        })
        .catch((error) => {
            console.log(error.response.data);
        });
}

export const getAtributesChoises = (attribute_id) => (dispatch, getstate) => {
    api.get(`products/attributes/${attribute_id}/terms`)
        .then((response) => {
            dispatch({
                type: GET_ALL_ATRIBUTES_CHOISES,
                payload: response.data
            })
        })
        .catch((error) => {
            console.log(error.response.data);
        });
}



export const createNewProductImage = (product_image) => (dispatch, getstate) => {
    const pageData = new FormData();
    pageData.append("photo", product_image.photo_file);
    pageData.append("shop", 1);


    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/shops/create-product-image/`, pageData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPLOAD_PRODUCT_IMAGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const createNewShopProduct = (new_product) => (dispatch, getstate) => {
    const data = {
        name: new_product.name,
        type: new_product.type,
        sku: new_product.sku,
        shipping_class:new_product.shipping_class,
        shipping_class_id:new_product.shipping_class_id,
        regular_price: new_product.regular_price,
        sale_price: new_product.sale_price,
        description: new_product.description,
        short_description: new_product.barcode,
        categories: new_product.final_categories,
        images: new_product.gallery,
        attributes: new_product.attributes,
        manage_stock: new_product.manage_stock,
        stock_quantity: parseInt(new_product.stock_quantity),
    };


    api.post("products", data)
        .then((response) => {
            console.log(response.data)
            if (response.data.type === "variable" && response.data.attributes.length > 0) {
                let choise = ""
                let variable_data = {}
                let categories_ids = ""
                for (let i = 0; i < new_product.final_categories.length; i++) {
                    categories_ids = new_product.final_categories[i].id + "," + categories_ids
                }
                setTimeout(() => {
                    for (let i = 0; i < response.data.attributes[0].options.length; i++) {
                        
                        choise = response.data.attributes[0].options[i]
                        variable_data = {
                            regular_price: new_product.regular_price,
                            sale_price: new_product.sale_price,
                            manage_stock: true,
                            stock_quantity: new_product.variables_stock_quantity[choise],
                            image: {
                                id: response.data.images[0].id
                            },
                            description: categories_ids.toString(),
                            attributes: [
                                {
                                    id: response.data.attributes[0].id,
                                    option: choise
                                }
                            ]
                        } 
                        

                        api.post(`products/${response.data.id}/variations`, variable_data)
                            .then((response) => {
                                console.log(response.data);
                            })
                            .catch((error) => {
                                console.log(error.response.data);
                            });
                    }
                }, 1000);
        }})
        .catch((error) => {
            console.log(error.response.data);
        });
}


export const getAllProductsVariations = (product_id) => (dispatch, getstate) => {
    api.get(`products/${product_id}/variations`)
        .then((response) => {
            dispatch({
                type: GET_ALL_PRODUCT_VARIATIONS,
                payload: response.data
            })
        })
        .catch((error) => {
            console.log(error.response.data);
        });
}


export const updateShopProduct = (product_id, data) => (dispatch, getstate) => {
    api.put(`products/${product_id}`, data)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error.response.data);
        });
}


export const updateProductVariation = (product_id, variation_id, data) => (dispatch, getstate) => {
    api.put(`products/${product_id}/variations/${variation_id}/`, data)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error.response.data);
        });
}



export const getAllHubst3rShoppingCategories = () => (dispatch, getstate) => {
    // api.get("products/categories", {
    //     per_page: 40, // 20 products per page
    // })
    //     .then((response) => {
    //         console.log(response.data);
    //     })
    //     .catch((error) => {
    //         console.log(error.response.data);
    //     });
}

export const clearShopFields = () => (dispatch, getstate) => {
    dispatch({ type: CLEAR_SHOP_FIELDS, });
}


export const getQrCodeProducts = (qr_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-shops-qr-code-products/${qr_id}/`, tokenConfig())
        .then(res => {
            let qrProducts = []
            let hubst3r_id = ""
            for (let i = 0; i < res.data.length; i++) {
                hubst3r_id = res.data[i].hubst3r_shopping_id
                qrProducts.push(hubst3r_id)
            }
            if (qrProducts.length>0){
                api.get("products", {
                        include: qrProducts
                    })
                    .then((response) => {
                        // console.log(response.data);
                        for (let i = 0; i < response.data.length; i++) {
                            if (response.data[i].type === "variable"){
                                api.get(`products/${response.data[i].id}/variations`)
                                    .then((response) => {
                                        // console.log(response.data);
                                        dispatch({
                                            type: GET_SHOPS_QR_CODE_PRODUCT_VARIATIONS,
                                            payload: response.data
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error.response.data);
                                    });
                            }
                        }
                        dispatch({
                            type: GET_SHOPS_QR_CODE_PRODUCTS,
                            payload: response.data
                        });
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });                
            }
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getShopQrCodes = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-shops-qr-codes/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SHOP_QR_CODES,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getShopQrCodesProducts = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-shops-qr-products/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SHOP_QR_CODES_PRODUCTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const removeProductFromQr = (qrCode) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/remove-product-from-qr/`, qrCode, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_PRODUCT_FROM_QR,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const insertProductToQr = (qrCode) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/insert-product-to-qr/`, qrCode, tokenConfig())
        .then(res => {
            dispatch({
                type: INSERT_PRODUCT_TO_QR,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getBarcodeProducts = (barcode) => (dispatch, getstate) => {
    console.log(barcode)
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-shops-barcode-products/${barcode}/`, tokenConfig())
        .then(res => {
            let qrProducts = []
            let hubst3r_id = ""           
            
            for (let i = 0; i < res.data.length; i++) {
                hubst3r_id = res.data[i].hubst3r_shopping_id
                qrProducts.push(hubst3r_id)
            }
            if (qrProducts.length>0){
                api.get("products", {
                        include: qrProducts
                    })
                    .then((response) => {
                        dispatch({
                            type: GET_SHOPS_BARCODE_PRODUCTS,
                            payload: response.data
                        });
                        dispatch({
                            type: CHECKED_BARCODE_PRODUCTS,
                            payload: res.data
                        }); 
                        // console.log(response.data);
                        for (let i = 0; i < response.data.length; i++) {
                            if (response.data[i].type === "variable"){
                                api.get(`products/${response.data[i].id}/variations`)
                                    .then((response) => {
                                        // console.log(response.data);
                                        dispatch({
                                            type: GET_SHOPS_BARCODE_PRODUCT_VARIATIONS,
                                            payload: response.data
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error.response.data);
                                    });
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });                
            } else {
                dispatch({
                    type: CHECKED_BARCODE_PRODUCTS,
                    payload: res.data
                });                    
            }
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getUserProducts = () => (dispatch, getstate) => {
    api.get("products", {
        per_page: 100, // 20 products per page
    })
        .then((response) => {
            dispatch({
                type: GET_USER_PRODUCTS,
                payload: response.data
            })
        })
        .catch((error) => {
            console.log("Response Status:", error.response.status);
            console.log("Response Headers:", error.response.headers);
            console.log("Response Data:", error.response.data);
        })
        .finally(() => {
            // Always executed.
        })
}

export const clearBarcodeData = () => (dispatch, getstate) => {
    dispatch({ type: CLEAR_BARCODE_DATA, });
}


export const getMagazineCover = (shop_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-magazine-cover/${shop_id}/`, tokenConfig())
    .then(res => {
        dispatch({
            type: GET_MAGAZINE_COVER,
            payload: res.data
        });
    }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getShopMagazineProducts = (shop_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-shops-magazine-products/${shop_id}/`, tokenConfig())
    .then(res => {
        let magazineProducts = []
        let hubst3r_id = ""
        console.log(res.data)
        for (let i = 0; i < res.data.length; i++) {
            hubst3r_id = res.data[i].hubst3r_shopping_id
            magazineProducts.push(hubst3r_id)
        }
        console.log(magazineProducts)

        if (magazineProducts.length>0){
            api.get("products", {
                    per_page: 30,
                    include: magazineProducts
                })
                .then((response) => {
                    // console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].type === "variable"){
                            api.get(`products/${response.data[i].id}/variations`)
                                .then((response) => {
                                    dispatch({
                                        type: GET_SHOPS_MAGAZINE_PRODUCT_VARIATIONS,
                                        payload: response.data
                                    });
                                })
                                .catch((error) => {
                                    console.log(error.response.data);
                                });
                        }
                    }
                    console.log(response.data)
                    dispatch({
                        type: GET_USER_MAGAZINE_PRODUCTS,
                        payload: response.data
                    });
                })
                .catch((error) => {
                    console.log(error.response.data);
                });                
        }
    }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));


    // api.get("products", {
    //     per_page: 100, // 20 products per page
    // })
    //     .then((response) => {
    //         dispatch({
    //             type: GET_MAGAZINE_PRODUCTS,
    //             payload: response.data
    //         })
    //     })
    //     .catch((error) => {
    //         console.log("Response Status:", error.response.status);
    //         console.log("Response Headers:", error.response.headers);
    //         console.log("Response Data:", error.response.data);
    //     })
    //     .finally(() => {
    //         // Always executed.
    //     })
}

export const getShopPageMagazineProducts = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-shop-magazine-products/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SHOP_MAGAZINE_PRODUCTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const removeProductFromMagazine = (magazine_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/remove-product-from-magazine/`, magazine_id, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_PRODUCT_FROM_MAGAZINE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const insertProductToMagazine = (magazine_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/insert-product-to-magazine/`, magazine_id, tokenConfig())
        .then(res => {
            dispatch({
                type: INSERT_PRODUCT_TO_MAGAZINE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const logoutShopAccess = (logout_shop) => (dispatch) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/logout-from-shop-access/`, logout_shop, tokenConfig())
        .then(res => {
            localStorage.removeItem("shp");
            dispatch({
                type: SHOP_LOGOUT_SUCCESS
            });
        }).catch(err => { console.log(err)});
}


export const getShopBulkProducts = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-shop-bulk-products/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SHOP_BULK_PRODUCTS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const createNewBulkProductCategory = (category) => (dispatch, getstate) => {
    const pageData = new FormData();
    pageData.append("name", category.name);
    pageData.append("image", category.image);


    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-shop-bulk-products-category/`, pageData, tokenConfig())
        .then(res => {
               console.log(res.data)
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}