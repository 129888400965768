import React, { useState, useRef } from 'react'
import TinderCard from 'react-tinder-card'


function SwipeBuddiesFull(props) {
    const db = props.data.users

    db.sort(function (a, b) {
        return a.id - b.id;
    });

    const [timesRequested, setTimesRequested] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(db.length - 1)
    const [lastDirection, setLastDirection] = useState()

    const [nextPressed, setNextPressed] = useState(false)
    const [previousPressed, setPreviousPressed] = useState(false)
    const [profilePressed, setProfilePressed] = useState(false)
    const [addPressed, setAddPressed] = useState(false)
    const [cancelPressed, setCancelPressed] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState("")
    const [selectedUser, setSelectedUser] = useState(db[db.length - 1].user)
    const [pressedOpacity, setPressedOpacity] = useState(0)
    const [dataRequested, setDataRequested] = useState(false)

    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex)


    const updateCurrentIndex = (val) => {
        setCurrentIndex(val)
        currentIndexRef.current = val
    }

    const canGoBack = currentIndex < db.length - 1

    const canSwipe = currentIndex >= 0

    // set last direction and decrease current index
    const swiped = (direction, swiped_user, index) => {
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 80);
        setSelectedIndex("")
        setLastDirection(direction)
        updateCurrentIndex(index - 1)
        if (direction === "right") {
            props.data.updateBuddyrequestList(swiped_user)
            props.data.buddyRequestSubmit(swiped_user)
        }
        if (direction === "up") {
            window.location = `/user-profile/${swiped_user}/profile`;
        }
    }

    const outOfFrame = (name, idx) => {
        // console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
        // handle the case in which go back is pressed before card goes outOfFrame
        currentIndexRef.current >= idx && db[idx].current.restoreCard()
        if (currentIndexRef.current > -1) {
            setSelectedUser(db[currentIndexRef.current].user)
        } else {
            setSelectedUser("")
        }

        if (currentIndexRef.current === -1) {
            props.data.getNewBuddies()
            setDataRequested(true)
            setTimeout(() => {
                setDataRequested(false)
                setCurrentIndex(db.length - 1)
                setSelectedUser(db[db.length - 1].user)
            }, 2000);
            setTimeout(() => {
                setCurrentIndex(db.length - 1)
                setSelectedUser(db[db.length - 1].user)
            }, 2500);
        }

        // TODO: when quickly swipe and restore multiple times the same card,
        // it happens multiple outOfFrame events are queued and the card disappear
        // during latest swipes. Only the last outOfFrame event should be considered valid
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 80);
    }

    const swipe = async (dir, swiped_user) => {
        correctLayout(dir)
        if (canSwipe && currentIndex < db.length) {
            await db[currentIndex].current.swipe(dir) // Swipe the card!
            setSelectedIndex("")
            if (currentIndexRef.current > -1) {
                setSelectedUser(db[currentIndexRef.current].user)
            } else {
                setSelectedUser("")
            }

        } else if (currentIndex === -1) {
            await db[db.length - 1].current.swipe(dir) // Swipe the card!
            setSelectedIndex("")
            if (currentIndexRef.current > -1) {
                setSelectedUser(db[currentIndexRef.current].user)
            } else {
                setSelectedUser("")
            }

        }
        // console.log(currentIndex < db.length)
        // console.log(currentIndex)
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 80);
    }

    // increase current index and show card
    const goBack = async () => {
        setPreviousPressed(true)
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
            setPreviousPressed(false)
        }, 50);
        if (!canGoBack) return
        if (currentIndex !== -1) {
            setSelectedIndex("")
            const newIndex = currentIndex + 1
            if (currentIndexRef.current > -1) {
                setSelectedUser(db[newIndex].user)
            } else {
                setSelectedUser("")
            }
            updateCurrentIndex(newIndex)
            await db[newIndex].current.restoreCard()
        }
    }

    const swipeRequirementFulfilled = (dir, user, index) => {
        setSelectedIndex(index)
        correctLayout(dir)
        // console.log(dir)
    }



    let swipeRequirementUnfulfilled = (dir, user, index) => {
        correctLayout(dir)
        setSelectedIndex(index)

        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }, 50);
    }

    let correctLayout = (dir) => {
        if (dir === "left") {
            setNextPressed(true)
            setAddPressed(false)
            setProfilePressed(false)
            setCancelPressed(false)
        } else if (dir === "right") {
            setAddPressed(true)
            setNextPressed(false)
            setProfilePressed(false)
            setCancelPressed(false)
        } else if (dir === "up") {
            setProfilePressed(true)
            setNextPressed(false)
            setAddPressed(false)
            setCancelPressed(false)
        }
    }

    const cancelBuddyRequest = async (user) => {
        setCancelPressed(true)
        setTimeout(() => {
            setProfilePressed(false)
            setNextPressed(false)
            setCancelPressed(false)
            setCancelPressed(false)
            setPreviousPressed(false)
        }, 100);
        props.data.updateBuddyrequestList(user)
        props.data.cancelBuddyRequest(user)
    }

    return (
        <>
            {dataRequested ?
                <div class="search-swipe-buddies-waiting-box">
                    <div class="search-swipe-buddies-waiting-img">
                        <img
                            class="search-swipe-buddies-waiting-profile"
                            src={props.data.profile_picture}
                            alt=""
                        />
                    </div>
                </div>
                :
                <>
                    {db.map((character, index) => (
                        <>
                            <TinderCard
                                ref={db[index]}
                                className='swipe-buddies-swipe-box'
                                key={character.user}
                                swipeRequirementType='position'
                                swipeThreshold="15"
                                onSwipe={(dir) => swiped(dir, character.user, index)}
                                onCardLeftScreen={() => outOfFrame(character.user, index)}
                                preventSwipe="down"
                                onSwipeRequirementFulfilled={(dir) => swipeRequirementFulfilled(dir, character.user, index, pressedOpacity)}
                                onSwipeRequirementUnfulfilled={() => swipeRequirementUnfulfilled()}
                            >

                                <div class="swipe-buddies-swipe-card">
                                    <div
                                        style={{ backgroundImage: 'url(' + character.profile_picture + ')' }}
                                        class='swipe-buddies-swipe-square'
                                    >

                                        <div class="swipe-buddies-username">
                                            {character.username}
                                        </div>

                                        <div class="swipe-buddies-black-cover-box"> </div>

                                        {character.user_memory_1 || character.user_memory_2 || character.user_memory_3 ?
                                            <div class="swipe-buddies-memories-box">
                                                <div class="row ">
                                                    {character.user_memory_1 !== null ?
                                                        <div class="swipe-buddies-memory-box-1">
                                                            <img
                                                                class="swipe-buddies-memory-size"
                                                                src={character.user_memory_1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        :
                                                        <div class="swipe-buddies-memory-box-1">
                                                            <div class="swipe-buddies-memory-empty">
                                                            </div>
                                                        </div>
                                                    }
                                                    {character.user_memory_2 !== null ?
                                                        <div class="swipe-buddies-memory-box-2">
                                                            <img
                                                                class="swipe-buddies-memory-size"
                                                                src={character.user_memory_2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        :
                                                        <div class="swipe-buddies-memory-box-2">
                                                            <div class="swipe-buddies-memory-empty">
                                                            </div>
                                                        </div>
                                                    }
                                                    {character.user_memory_3 !== null ?
                                                        <div class="swipe-buddies-memory-box-3">
                                                            <img
                                                                class="swipe-buddies-memory-size"
                                                                src={character.user_memory_3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        :
                                                        <div class="swipe-buddies-memory-box-3">
                                                            <div class="swipe-buddies-memory-empty">
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div class="swipe-buddies-memories-box-empty">
                                                <div class="row ">
                                                    <div class="swipe-buddies-empty-photos-box">
                                                        <img
                                                            class="swipe-buddies-empty-photos-icon"
                                                            src={require("../../../assets/layout/homepage svg/no-post.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div class="swipe-buddies-no-memories-yet">
                                                        No Memories yet
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>

                                {nextPressed && selectedIndex === index ?
                                    <div class="swipe-buddies-visit-next-position">


                                        <img
                                            class="swipe-buddies-visit-icon-img"
                                            src={require("../../../assets/buddies/svg/swipe-visit-next.svg")}
                                            alt=""
                                        />
                                    </div>
                                    :
                                    null
                                }

                                {profilePressed && selectedIndex === index ?
                                    <div class="swipe-buddies-visit-profile-position">
                                        <img
                                            class="swipe-buddies-visit-icon-img"
                                            src={require("../../../assets/buddies/svg/swipe-visit-profile.svg")}
                                            alt=""
                                        />
                                    </div>
                                    :
                                    null
                                }

                                {addPressed && selectedIndex === index ?
                                    <div class="swipe-buddies-visit-add-position">
                                        <img
                                            class="swipe-buddies-choise-icon-img"
                                            src={require("../../../assets/buddies/svg/swipe-visit-add.svg")}
                                            alt=""
                                        />
                                    </div>
                                    :
                                    null
                                }

                            </TinderCard>
                            {character.user === selectedUser ?
                                <div class="swipe-buddies-all-choises-btns">
                                    <div class="row swipe-buddies-all-choises-row">

                                        <div class="swipe-buddies-choise-box">
                                            <div class="swipe-buddies-choise-icon-box-small">
                                                {previousPressed ?
                                                    <img
                                                        onClick={() => goBack()}
                                                        class="swipe-buddies-choise-icon-img"
                                                        src={require("../../../assets/buddies/svg/swipe-previous-pressed.svg")}
                                                        alt=""
                                                    />
                                                    :
                                                    <img
                                                        onClick={() => goBack()}
                                                        class="swipe-buddies-choise-icon-img"
                                                        src={require("../../../assets/buddies/svg/swipe-previous.svg")}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div onClick={() => swipe('left', selectedUser)} class="swipe-buddies-choise-box">
                                            <div class="swipe-buddies-choise-icon-box">
                                                {nextPressed ?
                                                    <img
                                                        class="swipe-buddies-choise-icon-img"
                                                        src={require("../../../assets/buddies/svg/swipe-next-pressed.svg")}
                                                        alt=""
                                                    />
                                                    :
                                                    <img
                                                        class="swipe-buddies-choise-icon-img"
                                                        src={require("../../../assets/buddies/svg/swipe-next.svg")}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                        </div>


                                        {props.data.send_buddyrequests.includes(selectedUser) ?
                                            <div onClick={() => cancelBuddyRequest(selectedUser)} class="swipe-buddies-choise-box">
                                                <div class="swipe-buddies-choise-icon-box">
                                                    {cancelPressed ?
                                                        <img
                                                            class="swipe-buddies-choise-icon-img"
                                                            src={require("../../../assets/buddies/svg/swipe-cancel-pressed.svg")}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="swipe-buddies-choise-icon-img"
                                                            src={require("../../../assets/buddies/svg/swipe-cancel.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div onClick={() => swipe('right', selectedUser)} class="swipe-buddies-choise-box">
                                                <div class="swipe-buddies-choise-icon-box">
                                                    {addPressed ?
                                                        <img
                                                            class="swipe-buddies-choise-icon-img"
                                                            src={require("../../../assets/buddies/svg/swipe-add-pressed.svg")}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="swipe-buddies-choise-icon-img"
                                                            src={require("../../../assets/buddies/svg/swipe-add.svg")}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div onClick={() => swipe('up', selectedUser)} class="swipe-buddies-choise-box">
                                            <div class="swipe-buddies-choise-icon-box-small">
                                                {profilePressed ?
                                                    <img
                                                        class="swipe-buddies-choise-icon-img"
                                                        src={require("../../../assets/buddies/svg/swipe-profile-pressed.svg")}
                                                        alt=""
                                                    />
                                                    :
                                                    <img
                                                        class="swipe-buddies-choise-icon-img"
                                                        src={require("../../../assets/buddies/svg/swipe-profile.svg")}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </>
                    ))}
                </>
            }



        </>

    )
}

export default SwipeBuddiesFull