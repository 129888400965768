import React, { Fragment, lazy, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    setSales,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';


export class SetGasPrices extends Component {

    state = {
        has_sales: false,
        sales_text: "",
        haveSales: false,
        redirect: false,
        box1: "Unleaded 95+",
        box1_change: false,
        box2: "",
        box2_change: false,
        box3: "Unleaded 98+",
        box3_change: false,
        box4: "",
        box4_change: false,
        box5: "Unleaded 100",
        box5_change: false,
        box6: "",
        box6_change: false,
        box7: "EuroDiesel",
        box7_change: false,
        box8: "",
        box8_change: false,
        box9: "Heating Diesel",
        box9_change: false,
        box10: "",
        box10_change: false,
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => {
        const change_box = [e.target.name] + '_change'
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === "box1") {
            this.setState({
                box1_change: true
            })
        } else if (e.target.name === "box2") {
            this.setState({
                box2_change: true
            })
        } else if (e.target.name === "box3") {
            this.setState({
                box3_change: true
            })
        } else if (e.target.name === "box4") {
            this.setState({
                box4_change: true
            })
        } else if (e.target.name === "box5") {
            this.setState({
                box5_change: true
            })
        } else if (e.target.name === "box6") {
            this.setState({
                box6_change: true
            })
        } else if (e.target.name === "box7") {
            this.setState({
                box7_change: true
            })
        } else if (e.target.name === "box8") {
            this.setState({
                box8_change: true
            })
        } else if (e.target.name === "box9") {
            this.setState({
                box9_change: true
            })
        } else if (e.target.name === "box10") {
            this.setState({
                box10_change: true
            })
        }



    }

    answerHaveSales() {
        this.setState({
            haveSales: true,
        })
    }


    finishedSales() {

        let row1 = []
        let row2 = []
        let row3 = []
        let row4 = []
        let row5 = []

        let box1 = ""
        let box2 = ""
        let box3 = ""
        let box4 = ""
        let box5 = ""
        let box6 = ""
        let box7 = ""
        let box8 = ""
        let box9 = ""
        let box10 = ""

        const prices_array = this.props.hub[0].sales_text.split(",,,,");
        if (prices_array.length === 1) {
            row1 = prices_array[0].split(": ");
            box1 = row1[0]
            box2 = row1[1]
            box3 = ""
            box4 = ""
            box5 = ""
            box6 = ""
            box7 = ""
            box8 = ""
            box9 = ""
            box10 = ""
        }
        if (prices_array.length === 2) {
            row1 = prices_array[0].split(": ");
            row2 = prices_array[1].split(": ");
            box1 = row1[0]
            box2 = row1[1]
            box3 = row2[0]
            box4 = row2[1]
            box5 = ""
            box6 = ""
            box7 = ""
            box8 = ""
            box9 = ""
            box10 = ""
        }
        if (prices_array.length === 3) {
            row1 = prices_array[0].split(": ");
            row2 = prices_array[1].split(": ");
            row3 = prices_array[2].split(": ");
            box1 = row1[0]
            box2 = row1[1]
            box3 = row2[0]
            box4 = row2[1]
            box5 = row3[0]
            box6 = row3[1]
            box7 = ""
            box8 = ""
            box9 = ""
            box10 = ""
        }
        if (prices_array.length === 4) {
            row1 = prices_array[0].split(": ");
            row2 = prices_array[1].split(": ");
            row3 = prices_array[2].split(": ");
            row4 = prices_array[3].split(": ");
            box1 = row1[0]
            box2 = row1[1]
            box3 = row2[0]
            box4 = row2[1]
            box5 = row3[0]
            box6 = row3[1]
            box7 = row4[0]
            box8 = row4[1]
            box9 = ""
            box10 = ""
        }
        if (prices_array.length === 5) {
            row1 = prices_array[0].split(": ");
            row2 = prices_array[1].split(": ");
            row3 = prices_array[2].split(": ");
            row4 = prices_array[3].split(": ");
            row5 = prices_array[4].split(": ");
            box1 = row1[0]
            box2 = row1[1]
            box3 = row2[0]
            box4 = row2[1]
            box5 = row3[0]
            box6 = row3[1]
            box7 = row4[0]
            box8 = row4[1]
            box9 = row5[0]
            box10 = row5[1]
        }

        if (this.state.box1_change) {
            box1 = this.state.box1
        }
        if (this.state.box2_change) {
            box2 = this.state.box2
        }
        if (this.state.box3_change) {
            box3 = this.state.box3
        }
        if (this.state.box4_change) {
            box4 = this.state.box4
        }
        if (this.state.box5_change) {
            box5 = this.state.box5
        }
        if (this.state.box6_change) {
            box6 = this.state.box6
        }
        if (this.state.box7_change) {
            box7 = this.state.box7
        }
        if (this.state.box8_change) {
            box8 = this.state.box8
        }
        if (this.state.box9_change) {
            box9 = this.state.box9
        }
        if (this.state.box10_change) {
            box10 = this.state.box10
        }





        const has_sales = true





        row1 = box1 + ": " + box2
        row2 = box3 + ": " + box4
        row3 = box5 + ": " + box6
        row4 = box7 + ": " + box8
        row5 = box9 + ": " + box10

        let sales_text = ""
        if (box2 !== "") {
            sales_text = row1
        }
        if (box4 !== "") {
            sales_text = sales_text + ",,,," + row2
        }
        if (box6 !== "") {
            sales_text = sales_text + ",,,," + row3
        }
        if (box8 !== "") {
            sales_text = sales_text + ",,,," + row4
        }
        if (box10 !== "") {
            sales_text = sales_text + ",,,," + row5
        }

        const id = this.props.hub[0].id
        const hub = { id, has_sales, sales_text }
        this.props.setSales(hub)
        this.setState({
            redirect: true,
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/hub-homepage' }} />
        }

        let sales_text = ""
        const hub = this.props.hub

        if (hub.length > 0) {
            sales_text = hub[0].sales_text
        }

        if (this.state.sales_text !== "") {
            sales_text = this.state.sales_text
        }

        let box1 = this.state.box1
        let box2 = this.state.box2
        let box3 = this.state.box3
        let box4 = this.state.box4
        let box5 = this.state.box5
        let box6 = this.state.box6
        let box7 = this.state.box7
        let box8 = this.state.box8
        let box9 = this.state.box9
        let box10 = this.state.box10
        let prices_array = []
        let row1 = []
        let row2 = []
        let row3 = []
        let row4 = []
        let row5 = []

        if (this.props.hub.length > 0) {
            prices_array = this.props.hub[0].sales_text.split(",,,,");
            if (prices_array.length === 1) {
                row1 = prices_array[0].split(": ");
                box1 = row1[0]
                box2 = row1[1]
                box3 = ""
                box4 = ""
                box5 = ""
                box6 = ""
                box7 = ""
                box8 = ""
                box9 = ""
                box10 = ""
            }
            if (prices_array.length === 2) {
                row1 = prices_array[0].split(": ");
                row2 = prices_array[1].split(": ");
                box1 = row1[0]
                box2 = row1[1]
                box3 = row2[0]
                box4 = row2[1]
                box5 = ""
                box6 = ""
                box7 = ""
                box8 = ""
                box9 = ""
                box10 = ""
            }
            if (prices_array.length === 3) {
                row1 = prices_array[0].split(": ");
                row2 = prices_array[1].split(": ");
                row3 = prices_array[2].split(": ");
                box1 = row1[0]
                box2 = row1[1]
                box3 = row2[0]
                box4 = row2[1]
                box5 = row3[0]
                box6 = row3[1]
                box7 = ""
                box8 = ""
                box9 = ""
                box10 = ""
            }
            if (prices_array.length === 4) {
                row1 = prices_array[0].split(": ");
                row2 = prices_array[1].split(": ");
                row3 = prices_array[2].split(": ");
                row4 = prices_array[3].split(": ");
                box1 = row1[0]
                box2 = row1[1]
                box3 = row2[0]
                box4 = row2[1]
                box5 = row3[0]
                box6 = row3[1]
                box7 = row4[0]
                box8 = row4[1]
                box9 = ""
                box10 = ""
            }
            if (prices_array.length === 5) {
                row1 = prices_array[0].split(": ");
                row2 = prices_array[1].split(": ");
                row3 = prices_array[2].split(": ");
                row4 = prices_array[3].split(": ");
                row5 = prices_array[4].split(": ");
                box1 = row1[0]
                box2 = row1[1]
                box3 = row2[0]
                box4 = row2[1]
                box5 = row3[0]
                box6 = row3[1]
                box7 = row4[0]
                box8 = row4[1]
                box9 = row5[0]
                box10 = row5[1]
            }
        }

        if (this.state.box1_change) {
            box1 = this.state.box1
        }
        if (this.state.box2_change) {
            box2 = this.state.box2
        }
        if (this.state.box3_change) {
            box3 = this.state.box3
        }
        if (this.state.box4_change) {
            box4 = this.state.box4
        }
        if (this.state.box5_change) {
            box5 = this.state.box5
        }
        if (this.state.box6_change) {
            box6 = this.state.box6
        }
        if (this.state.box7_change) {
            box7 = this.state.box7
        }
        if (this.state.box8_change) {
            box8 = this.state.box8
        }
        if (this.state.box9_change) {
            box9 = this.state.box9
        }
        if (this.state.box10_change) {
            box10 = this.state.box10
        }
        return (
            <Fragment>
                <>
                    {this.props.hub.map(hub => (
                        <>
                            <HubDesktopNavbar data={{ sellected: "" }} />
                            <div>
                                <div class="whole-setup-page-page-layout">
                                    <OfflineLabel />

                                    <>

                                        <div class="setup-page-welcome-title">
                                            <Link to="/hub-homepage" class="setup-page-back-box">
                                                <div class="setup-page-back-icon-box">
                                                    <img
                                                        class="setup-page-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </Link>
                                            Set Prices
                                        </div>

                                        <div class="row set-gas-page-row">
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Gas type</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box1"
                                                    onChange={this.onChange}
                                                    placeholder="i.e. Unleaded 95+, Eurodiesel"
                                                    value={box1}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Price</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box2"
                                                    onChange={this.onChange}
                                                    placeholder="Price..."
                                                    value={box2}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Gas type</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box3"
                                                    onChange={this.onChange}
                                                    placeholder="i.e. Unleaded 95+, Eurodiesel"
                                                    value={box3}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Price</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box4"
                                                    onChange={this.onChange}
                                                    placeholder="Price..."
                                                    value={box4}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Gas type</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box5"
                                                    onChange={this.onChange}
                                                    placeholder="i.e. Unleaded 95+, Eurodiesel"
                                                    value={box5}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Price</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box6"
                                                    onChange={this.onChange}
                                                    placeholder="Price..."
                                                    value={box6}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Gas type</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box7"
                                                    onChange={this.onChange}
                                                    placeholder="i.e. Unleaded 95+, Eurodiesel"
                                                    value={box7}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Price</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box8"
                                                    onChange={this.onChange}
                                                    placeholder="Price..."
                                                    value={box8}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Gas type</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box9"
                                                    onChange={this.onChange}
                                                    placeholder="i.e. Unleaded 95+, Eurodiesel"
                                                    value={box9}
                                                />
                                            </div>
                                            <div class="set-gas-page-info-input-box">
                                                <div class="set-gas-page-title-text">Price</div>
                                                <input
                                                    class='set-gas-page-info-input-field'
                                                    name="box10"
                                                    onChange={this.onChange}
                                                    placeholder="Price..."
                                                    value={box10}
                                                />
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>

                            <div onClick={() => this.finishedSales()} class="setup-page-next-btn">
                                Done
                            </div>

                        </>
                    ))}
                </>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    setSales,
})(SetGasPrices);