import React, { Component, Fragment } from 'react';
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/booked_page/tableModal.css"



export class TablesModal extends Component {
    state = {
        table_id: "",
        table_number: ""
    };

    closeModal = () => {
        this.props.data.closeModal()
        if (this.props.data.table_id === "") {
            this.setState({
                table_id: "",
                table_number: ""
            })
        }
        else {
            this.setState({
                table_id: this.props.data.table_id,
                table_number: this.props.data.table_number
            })
        }
    }

    updateSellectedTable = (table_id, table_number) => (e) => {
        this.setState({
            table_id: table_id,
            table_number: table_number
        })
    }

    uploadReservationsTable = (e) => {
        if (this.props.data.reservation_from === "user") {
            this.props.data.handleTableChange(this.state.table_id, this.state.table_number)
        } else {
            this.props.data.handleHubReservationTableChange(this.state.table_id, this.state.table_number)
        }
        this.setState({
            table_id: "",
            table_number: ""
        })
        this.props.data.closeModal()
    }

    render() {
        const settings = {
            dots: true,
            infinite_value: false,
            slidesToShow: 4,
            // slidesToScroll: 2,
            // speed: 500,
            swipeToSlide: true,
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />, 
        };
        let sellected_table = ""
        if (this.state.table_id === "") {
            sellected_table = this.props.data.sellected_table
        }
        return (
            <Fragment>
                <div class="tables-modal-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="tables-modal-modal-box">

                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="tables-modal-cancle-btn">
                                        <img class="tables-modal-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row">
                                    <div class="col-xl-12 text-left">
                                        <h4><strong class="tables-modal-title">Sellect Reservation's Table</strong></h4>
                                    </div>
                                </div>
                                <div class="tables-modal-slider-layout">
                                    <Slider {...settings} >
                                        {this.props.data.tables.map(table => (
                                            <div key={table.id}>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
                                                    <div
                                                        onClick={this.updateSellectedTable(table.id, table.number)}
                                                        class="tables-modal-modal-frame"
                                                    >
                                                        <div class="all-tables-modal-table-number">Table {table.number}</div>
                                                        <img
                                                            class=
                                                            {this.state.table_id === table.id ?
                                                                "tables-modal-sellected-logo"
                                                                : sellected_table === table.id ?
                                                                    "tables-modal-sellected-logo"
                                                                    :
                                                                    "tables-modal-modal-logo"
                                                            }
                                                            src={require("../../../assets/booked_page/svg/table.svg")}
                                                            alt=""
                                                        />
                                                        {table.todays_reservations !== "" ?
                                                            < div class="tables-modal-table-reservation">Reserved at:<br /><b>{table.todays_reservations}</b></div>
                                                            :
                                                            <div class="tables-modal-table-reservation">No Reservations</div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>

                                <div class="row">
                                    <div class="col-xl-12">
                                        <div
                                            onClick={this.uploadReservationsTable}
                                            class="btn tables-modal-btn-danger"
                                        >
                                            Sellect
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (TablesModal);