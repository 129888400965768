import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class MemoryImpression extends Component {


    render() {
        const { notification } = this.props.data
        return (
            <Fragment>
                <Link to={{ pathname: '/memories', state: { memory: notification.related_memory_id } }}>
                    <div class="row single-item-9">
                        <div class="notification-related-photo-position">
                            {notification.related_picture ?
                                <img class="related-picture-layout" src={notification.related_picture} alt="" />
                                :
                                <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                            }
                        </div>
                        <div class="notification-information-section">
                            <div class="notification-text-position">
                                <p class="text-blue-9 notification-text-layout">
                                    <strong>{notification.related_name}</strong>&nbsp;
                                    {notification.related_memory_reaction === "1" ?
                                        <>
                                            and {notification.related_memory_reaction} other&nbsp;
                                        </>
                                        : notification.related_memory_reaction !== "0" ?
                                            <>
                                                and {notification.related_memory_reaction} others&nbsp;
                                            </>
                                            :
                                            null
                                    }
                                    likes your Memory!!!

                                </p>
                            </div>
                        </div>
                        <div class="notification-related-icon-layout text-center">
                            <img
                                class="impression-notification-mika-img-cover"
                                src={notification.related_memory_photo}
                            // src={require("../../../assets/layout/homepage svg/love-red.svg")}
                            />
                            <img
                                class="impression-notification-mika-img"
                                src={notification.related_memory_photo}
                            // src={require("../../../assets/layout/homepage svg/love-red.svg")}
                            />
                        </div>
                    </div>
                </Link>
            </Fragment >
        )
    }
}


export default (MemoryImpression);