import React from 'react';
import TimePicker from 'react-times';

// use material theme
import 'react-times/css/material/default.css';
import "../../../assets/arrange-meeting/timepicker.css";

class TimePickerComp extends React.Component {


    updateSellectedHour = (hour) => (e) => {
        this.props.data.sellectedHourMode(hour)
    }

    updateSellectedMinute = (minute) => (e) => {
        this.props.data.sellectedMinuteMode(minute)
        setTimeout(() => {
            this.props.data.closeModal()
        }, 500);

    }


    render() {
        return (
            <span >
                {this.props.data.selected_hour ?
                    <span>
                        <div class="arrange-all-hiden-hour">
                            <div
                                onClick={this.updateSellectedMinute("00")}
                                class="select-hour-invisible-12"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("05")}
                                class="select-hour-invisible-13"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("10")}
                                class="select-hour-invisible-14"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("15")}
                                class="select-hour-invisible-15"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("20")}
                                class="select-hour-invisible-16"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("25")}
                                class="select-hour-invisible-17"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("30")}
                                class="select-hour-invisible-18"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("35")}
                                class="select-hour-invisible-19"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("40")}
                                class="select-hour-invisible-20"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("45")}
                                class="select-hour-invisible-21"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("50")}
                                class="select-hour-invisible-22"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedMinute("55")}
                                class="select-hour-invisible-23"
                            >
                            </div>

                        </div>
                        {this.props.data.minute === "" ?
                            <div class="arrange-timepicker-component-size">
                                <img
                                    class="arrange-timepicker-clock-layout"
                                    src={require("../../../assets/arrange-meeting/timepicker/minute-empty.svg")}
                                    alt=""
                                />
                            </div>
                            : this.props.data.minute === "00" ?
                                <div class="arrange-timepicker-component-size">
                                    <img
                                        class="arrange-timepicker-clock-layout"
                                        src={require("../../../assets/arrange-meeting/timepicker/minute-00.svg")}
                                        alt=""
                                    />
                                </div>
                                : this.props.data.minute === "05" ?
                                    <div class="arrange-timepicker-component-size">
                                        <img
                                            class="arrange-timepicker-clock-layout"
                                            src={require("../../../assets/arrange-meeting/timepicker/minute-05.svg")}
                                            alt=""
                                        />
                                    </div>
                                    : this.props.data.minute === "10" ?
                                        <div class="arrange-timepicker-component-size">
                                            <img
                                                class="arrange-timepicker-clock-layout"
                                                src={require("../../../assets/arrange-meeting/timepicker/minute-10.svg")}
                                                alt=""
                                            />
                                        </div>
                                        : this.props.data.minute === "15" ?
                                            <div class="arrange-timepicker-component-size">
                                                <img
                                                    class="arrange-timepicker-clock-layout"
                                                    src={require("../../../assets/arrange-meeting/timepicker/minute-15.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            : this.props.data.minute === "20" ?
                                                <div class="arrange-timepicker-component-size">
                                                    <img
                                                        class="arrange-timepicker-clock-layout"
                                                        src={require("../../../assets/arrange-meeting/timepicker/minute-20.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                : this.props.data.minute === "25" ?
                                                    <div class="arrange-timepicker-component-size">
                                                        <img
                                                            class="arrange-timepicker-clock-layout"
                                                            src={require("../../../assets/arrange-meeting/timepicker/minute-25.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    : this.props.data.minute === "30" ?
                                                        <div class="arrange-timepicker-component-size">
                                                            <img
                                                                class="arrange-timepicker-clock-layout"
                                                                src={require("../../../assets/arrange-meeting/timepicker/minute-30.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        : this.props.data.minute === "35" ?
                                                            <div class="arrange-timepicker-component-size">
                                                                <img
                                                                    class="arrange-timepicker-clock-layout"
                                                                    src={require("../../../assets/arrange-meeting/timepicker/minute-35.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            : this.props.data.minute === "40" ?
                                                                <div class="arrange-timepicker-component-size">
                                                                    <img
                                                                        class="arrange-timepicker-clock-layout"
                                                                        src={require("../../../assets/arrange-meeting/timepicker/minute-40.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                : this.props.data.minute === "45" ?
                                                                    <div class="arrange-timepicker-component-size">
                                                                        <img
                                                                            class="arrange-timepicker-clock-layout"
                                                                            src={require("../../../assets/arrange-meeting/timepicker/minute-45.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    : this.props.data.minute === "50" ?
                                                                        <div class="arrange-timepicker-component-size">
                                                                            <img
                                                                                class="arrange-timepicker-clock-layout"
                                                                                src={require("../../../assets/arrange-meeting/timepicker/minute-50.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        : this.props.data.minute === "55" ?
                                                                            <div class="arrange-timepicker-component-size">
                                                                                <img
                                                                                    class="arrange-timepicker-clock-layout"
                                                                                    src={require("../../../assets/arrange-meeting/timepicker/minute-55.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            :
                                                                            null
                        }
                    </span>
                    :
                    <span>
                        <div class="arrange-all-hiden-hour">
                            <div
                                onClick={this.updateSellectedHour("01")}
                                class="select-hour-invisible-1"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("02")}
                                class="select-hour-invisible-2"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("03")}
                                class="select-hour-invisible-3"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("04")}
                                class="select-hour-invisible-4"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("05")}
                                class="select-hour-invisible-5"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("06")}
                                class="select-hour-invisible-6"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("07")}
                                class="select-hour-invisible-7"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("08")}
                                class="select-hour-invisible-8"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("09")}
                                class="select-hour-invisible-9"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("10")}
                                class="select-hour-invisible-10"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("11")}
                                class="select-hour-invisible-11"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("12")}
                                class="select-hour-invisible-12"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("13")}
                                class="select-hour-invisible-13"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("14")}
                                class="select-hour-invisible-14"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("15")}
                                class="select-hour-invisible-15"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("16")}
                                class="select-hour-invisible-16"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("17")}
                                class="select-hour-invisible-17"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("18")}
                                class="select-hour-invisible-18"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("19")}
                                class="select-hour-invisible-19"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("20")}
                                class="select-hour-invisible-20"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("21")}
                                class="select-hour-invisible-21"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("22")}
                                class="select-hour-invisible-22"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("23")}
                                class="select-hour-invisible-23"
                            >
                            </div>
                            <div
                                onClick={this.updateSellectedHour("00")}
                                class="select-hour-invisible-00"
                            >
                            </div>
                        </div>
                        {this.props.data.hour === "" ?
                            <div class="arrange-timepicker-component-size">
                                <img
                                    class="arrange-timepicker-clock-layout"
                                    src={require("../../../assets/arrange-meeting/timepicker/hours-empty.svg")}
                                    alt=""
                                />
                            </div>
                            : this.props.data.hour === "01" ?
                                <div class="arrange-timepicker-component-size">
                                    <img
                                        class="arrange-timepicker-clock-layout"
                                        src={require("../../../assets/arrange-meeting/timepicker/hour-1.svg")}
                                        alt=""
                                    />
                                </div>
                                : this.props.data.hour === "02" ?
                                    <div class="arrange-timepicker-component-size">
                                        <img
                                            class="arrange-timepicker-clock-layout"
                                            src={require("../../../assets/arrange-meeting/timepicker/hour-2.svg")}
                                            alt=""
                                        />
                                    </div>
                                    : this.props.data.hour === "03" ?
                                        <div class="arrange-timepicker-component-size">
                                            <img
                                                class="arrange-timepicker-clock-layout"
                                                src={require("../../../assets/arrange-meeting/timepicker/hour-3.svg")}
                                                alt=""
                                            />
                                        </div>
                                        : this.props.data.hour === "04" ?
                                            <div class="arrange-timepicker-component-size">
                                                <img
                                                    class="arrange-timepicker-clock-layout"
                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-4.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            : this.props.data.hour === "05" ?
                                                <div class="arrange-timepicker-component-size">
                                                    <img
                                                        class="arrange-timepicker-clock-layout"
                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-5.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                : this.props.data.hour === "06" ?
                                                    <div class="arrange-timepicker-component-size">
                                                        <img
                                                            class="arrange-timepicker-clock-layout"
                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-6.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    : this.props.data.hour === "07" ?
                                                        <div class="arrange-timepicker-component-size">
                                                            <img
                                                                class="arrange-timepicker-clock-layout"
                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-7.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        : this.props.data.hour === "08" ?
                                                            <div class="arrange-timepicker-component-size">
                                                                <img
                                                                    class="arrange-timepicker-clock-layout"
                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-8.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            : this.props.data.hour === "09" ?
                                                                <div class="arrange-timepicker-component-size">
                                                                    <img

                                                                        class="arrange-timepicker-clock-layout"
                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-9.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                : this.props.data.hour === "10" ?
                                                                    <div class="arrange-timepicker-component-size">
                                                                        <img

                                                                            class="arrange-timepicker-clock-layout"
                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-10.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    : this.props.data.hour === "11" ?
                                                                        <div class="arrange-timepicker-component-size">
                                                                            <img

                                                                                class="arrange-timepicker-clock-layout"
                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-11.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        : this.props.data.hour === "12" ?
                                                                            <div class="arrange-timepicker-component-size">
                                                                                <img

                                                                                    class="arrange-timepicker-clock-layout"
                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-12.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            : this.props.data.hour === "13" ?
                                                                                <div class="arrange-timepicker-component-size">
                                                                                    <img

                                                                                        class="arrange-timepicker-clock-layout"
                                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-13.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                : this.props.data.hour === "14" ?
                                                                                    <div class="arrange-timepicker-component-size">
                                                                                        <img

                                                                                            class="arrange-timepicker-clock-layout"
                                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-14.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    : this.props.data.hour === "15" ?
                                                                                        <div class="arrange-timepicker-component-size">
                                                                                            <img

                                                                                                class="arrange-timepicker-clock-layout"
                                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-15.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        : this.props.data.hour === "16" ?
                                                                                            <div class="arrange-timepicker-component-size">
                                                                                                <img

                                                                                                    class="arrange-timepicker-clock-layout"
                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-16.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            : this.props.data.hour === "17" ?
                                                                                                <div class="arrange-timepicker-component-size">
                                                                                                    <img

                                                                                                        class="arrange-timepicker-clock-layout"
                                                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-17.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                : this.props.data.hour === "18" ?
                                                                                                    <div class="arrange-timepicker-component-size">
                                                                                                        <img

                                                                                                            class="arrange-timepicker-clock-layout"
                                                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-18.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    : this.props.data.hour === "19" ?
                                                                                                        <div class="arrange-timepicker-component-size">
                                                                                                            <img

                                                                                                                class="arrange-timepicker-clock-layout"
                                                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-19.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        : this.props.data.hour === "20" ?
                                                                                                            <div class="arrange-timepicker-component-size">
                                                                                                                <img

                                                                                                                    class="arrange-timepicker-clock-layout"
                                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-20.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            : this.props.data.hour === "21" ?
                                                                                                                <div class="arrange-timepicker-component-size">
                                                                                                                    <img

                                                                                                                        class="arrange-timepicker-clock-layout"
                                                                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-21.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </div>
                                                                                                                : this.props.data.hour === "22" ?
                                                                                                                    <div class="arrange-timepicker-component-size">
                                                                                                                        <img

                                                                                                                            class="arrange-timepicker-clock-layout"
                                                                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-22.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    : this.props.data.hour === "23" ?
                                                                                                                        <div class="arrange-timepicker-component-size">
                                                                                                                            <img

                                                                                                                                class="arrange-timepicker-clock-layout"
                                                                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-23.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        : this.props.data.hour === "00" ?
                                                                                                                            <div class="arrange-timepicker-component-size">
                                                                                                                                <img

                                                                                                                                    class="arrange-timepicker-clock-layout"
                                                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-24.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            null
                        }
                    </span>
                }
            </span>

        )
    }
}

export default TimePickerComp;
