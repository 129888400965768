
import axios from 'axios';
import { createAlert, returnErrors } from './alerts';
import {
    GET_CONVERSATIONS,
    CREATE_NEW_MESSAGE,
    CONVERSATIONS_LOCALY_LOADED,
    GET_THE_CONVERSATION,
    GET_CONVERSATION_MESSAGES,
    LISTENED_THE_MESSAGE,
    UPDATE_CONVERSATION_BACKGROUND,
    UPDATE_CONVERSATION_MY_MESSAGES_COLOR,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    CLEAR_MESSAGES
} from './actionTypes';
import { tokenConfig } from './auth';


export const getMyConersations = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-my-conversations/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_CONVERSATIONS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: CONVERSATIONS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const getTheConversation = (user_id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${user_id}/get-the-conversation/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_THE_CONVERSATION,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getConversationMessages = (user_id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${user_id}/get-conversations-messages/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_CONVERSATION_MESSAGES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const sendVoiceMessage = (message) => (dispatch, getstate) => {
    const messageData = new FormData();
    messageData.append("sending_user", message.sending_user);
    messageData.append("recieving_user", message.recieving_user)
    messageData.append("voice_message", message.voice_message)
    messageData.append("text_message", message.text_message)
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/send-voice-message/`, messageData, tokenConfig())
        .then(res => {
            // dispatch(createAlert({ uploadMemory: 'Your Message has been sent' }));
            dispatch({
                type: CREATE_NEW_MESSAGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const sendMessageWithImage = (message) => (dispatch, getstate) => {
    const messageData = new FormData();
    messageData.append("sending_user", message.sending_user);
    messageData.append("recieving_user", message.recieving_user)
    messageData.append("voice_message", message.voice_message)
    messageData.append("text_message", message.text_message)
    messageData.append("message_file", message.message_file)
    messageData.append("has_file", message.has_file)
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/send-message-with-image/`, messageData, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_NEW_MESSAGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const setMessageToListened = (message) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/listened-voice-message/`, message, tokenConfig())
        .then(res => {
            dispatch({
                type: LISTENED_THE_MESSAGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const setConversationBackground = updated_conversation => (dispatch, getstate) => {
    const conversationData = new FormData();
    conversationData.append("background_image", updated_conversation.background_image);
    conversationData.append("id", updated_conversation.id);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/update-conversation-background-picture/`, conversationData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_CONVERSATION_BACKGROUND,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const setConversationMyColor = updated_conversation => (dispatch, getstate) => {
    // const conversationData = new FormData();
    // conversationData.append("background_image", updated_conversation.background_image);
    // conversationData.append("id", updated_conversation.id);
    // conversationData.append("person_1_color", updated_conversation.person_1_color);
    // conversationData.append("person_1_color", updated_conversation.person_1_color);

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/update-conversation-my-messages-color/`, updated_conversation, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_CONVERSATION_MY_MESSAGES_COLOR,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const clearStateMessages = updated_conversation => (dispatch, getstate) => {
    dispatch({ type: CLEAR_MESSAGES, });
}
