import React, { Component, Fragment } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/booked_page/tables-persons.css"

export class PersonsTableModal extends Component {
    state = {
        selected_persons: ""
    }

    closeModal = () => {
        this.setState({
            selected_persons: ""
        })
        this.props.data.closeModal()
    }


    changeTablesPersons = (selected_persons) => (e) => {
        this.setState({
            selected_persons: selected_persons
        })
        const table = this.props.data.table
        // const id = this.props.data.sellected_table
        table.persons_now = selected_persons
        // const persons = selected_persons
        // const table = { id, persons }
        this.props.setTablePersons(table)
        this.props.data.closeModal()

        // setTimeout(() => {
        //     const correct_datetime = moment(new Date()).tz("Europe/Athens").format("YYYY-MM-DD")
        //     const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        //     const date2 = date1.split('.').join("");
        //     const date_id = date2.split('-').join("");
        //     this.props.getHubAllTables(date_id)
        //     this.setState({
        //         date: new Date(),
        //         date_changed: false,
        //         freeTablesMode: false,
        //         addReservationModalVisible: false,
        //         table_sellected_option: "",
        //         addReservationMode: false,
        //         ReservationsListModalVisible: false,
        //     })
        // }, 500);
    }


    render() {
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
        };

        const persons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]
        return (
            <Fragment>
                <div class="table-persons-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="table-persons-modal-box">
                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="table-persons-cancle-btn">
                                        <img class="table-persons-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row">
                                    <div class="text-left">
                                        <h4><strong class="table-persons-title">Table No. {this.props.data.sellected_table_number}</strong></h4>
                                    </div>
                                </div>
                                <div class="table-persons-slider-layout">
                                    <Slider {...settings} >
                                        <div
                                            onClick={this.changeTablesPersons(0)}
                                            class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 table-persons-item"
                                        >
                                            <div
                                                class="table-persons-round-buttons table-persons-round-button-unpressed">
                                                {/* <img
                                                    class="table-persons-icon"
                                                    src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                    alt=""
                                                /> */}
                                            </div>
                                            <div class="table-persons-choise-text">
                                                <div class="table-persons-table-reservation ">
                                                    <b>Empty</b>
                                                </div>
                                            </div>
                                        </div>
                                        {persons.map(person => (
                                            <div
                                                onClick={this.changeTablesPersons(person)}
                                                class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 table-persons-item"
                                            >
                                                <div
                                                    class="table-persons-round-buttons table-persons-round-button-unpressed">
                                                    <img
                                                        class="table-persons-icon"
                                                        src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="table-persons-choise-text">
                                                    <div class="table-persons-table-reservation ">
                                                        <b>{person} Persons</b>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>



                </div>
            </Fragment >
        )
    }
}

export default (PersonsTableModal);