import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    getHubWorkingDays,
    hubWorkingDayClose,
    setWorkingDayHours,
    uploadHubLogo,
    uploadHubCoverPhoto,
    uploadHubPromoPhoto,
    setHubName,
    setHubCategory,
    setHubMapIcon,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
    setHubReservations,
    setHubAppointments,
    setHubOnlineShop,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    selectHubReservationHours,
    selectHubAppointmentHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
} from '../../../store/actions/hub'
import { acceptHubsTerms } from '../../../store/actions/auth'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';

import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import moment from "moment";
import hub from '../../../store/reducers/hub';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class SetupPage extends Component {

    state = {
        halfHourBox: false,
        quarterHourBox: false,
        specificHourBox: false,

        place_type: "",

        cover_photo: "",
        photo: null,

        logo_photo: "",
        logo_photo_file: null,

        promo_photo: "",
        promo_photo_file: null,

        name: '',
        opening_hour: "",
        closing_hour: "",
        changing_day_id: "",
        changing_opening: "",
        day_close_change: "",
        shop_url: "",

        logoNameSection: true,
        workingHoursSection: false,
        placeProvideSection: false,

        acceptReservationSection: false,
        acceptingReservations: false,
        throughReservations: "",
        reservation_app_url: "",



        reservationHoursMode: false,
        appointmentHoursMode: false,

        onlineShopSection: false,
        haveOnlineShop: false,


        acceptAppointmentSection: false,
        acceptingAppointments: false,
        throughAppointments: "",
        appointment_app_url: "",

        categorySection: false,
        mapSection: false,
        promoSection: false,
        socialSection: false,
        menuSection: false,


        wifiAnswer: false,
        telephoneAnswer: false,
        websiteAnswer: false,
        facebookAnswer: false,
        instagramAnswer: false,

        wifiMiniSection: true,
        wifi_code: "",
        telephoneMiniSection: true,
        telephone: "",
        has_telephone: false,
        websiteMiniSection: true,
        website: "",
        has_website: false,
        facebookMiniSection: true,
        facebook: "",
        has_facebook: false,
        instagramMiniSection: true,
        instagram: "",
        has_instagram: false,
        menu: null,

        finishedCategoryName: false,
        category: "",
        map_icon: "",
        catalogView: true,
        catalog_view_name: "",
        catalogNameView: false,
        catalog_name: "",
        catalog_id: "",
        viewMenuPages: false,
        uploading_pages: false,
        done_setup: false,
        redirect: false,
        specificHourMode: false,
        specificAppointmentHourMode: false,
        hour: "18",
        minutes: "00",
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
        this.props.getHubWorkingDays(hub_id);
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === "opening_hour" && e.target.value.length === 5) {
            const id = this.state.changing_day_id
            const opening_hour = e.target.value
            const closing_hour = ""
            const day = { id, opening_hour, closing_hour }
            this.props.setWorkingDayHours(day)
        }
        if (e.target.name === "closing_hour" && e.target.value.length === 5) {
            const id = this.state.changing_day_id
            const opening_hour = ""
            const closing_hour = e.target.value
            const day = { id, opening_hour, closing_hour }
            this.props.setWorkingDayHours(day)
        }
    }

    addCategory(category) {
        const my_categories_list = [...this.state.my_categories]
        if ((my_categories_list.indexOf(category)) !== -1) {
            const new_remove = my_categories_list.filter(f => f !== category)
            this.setState({ my_categories: new_remove })
        } else {
            my_categories_list.push(category)
            this.setState({ my_categories: my_categories_list })
        }
    }

    clickOnCloseChange(day_id) {
        this.setState({
            day_close_change: day_id,
        })
        const id = day_id
        const is_close = false
        const day = { id, is_close }
        this.props.hubWorkingDayClose(day)
    }

    clickOnDayClose(day_id) {
        const id = day_id
        const is_close = true
        const day = { id, is_close }
        this.props.hubWorkingDayClose(day)
    }

    clickOnDayClock(day, hour_type) {
        this.setState({
            changing_day_id: day.id,
            changing_opening: hour_type
        })

    }

    handleTimeValueChange = (value, day) => {
        console.log("value" + value);
        console.log(day)
    };

    goToLogoNameSection() {
        this.setState({
            logoNameSection: true,
            workingHoursSection: false,
            placeProvideSection: false,
            categorySection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    goToWorkingHoursSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: true,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        if (this.state.name !== "") {
            const id = this.props.hub[0].id
            const name = this.state.name
            const hub = { id, name }
            this.props.setHubName(hub)
        }
    }

    goToProvideSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: true,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            acceptingReservations: false,
            acceptingAppointments: false,
            haveOnlineShop: false,
            throughReservations: "",
            throughAppointments: "",
            reservationHoursMode: false
        })
    }


    goToReservationSection(place_type) {
        this.setState({
            place_type: place_type,
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: true,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    answerReservationAcceptance(answer) {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = answer
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const reservation_app_url = this.state.reservation_app_url
        const hub = { id, appointment_acceptance, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }


    answerReservationAcceptanceHubst3r() {
        this.setState({
            reservationHoursMode: true
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = true
        const reservation_app_url = ""
        const hub = { id, appointment_acceptance, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }

    finishReservationHours() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
            reservationHoursMode: false
        })
    }

    answerAppointmentAcceptanceHubst3r() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const has_online_shop = false
        const reservation_acceptance = false
        const hubst3r_appointments = true
        const appointment_app_url = ""
        const hub = { id, appointment_acceptance, reservation_acceptance, has_online_shop, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
    }

    finishedOtherResercations() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        const id = this.props.hub[0].id
        const reservation_acceptance = true
        const has_online_shop = false
        const appointment_acceptance = false
        const hubst3r_reservations = false
        const reservation_app_url = this.state.reservation_app_url
        const hub = { id, appointment_acceptance, reservation_acceptance, has_online_shop, hubst3r_reservations, reservation_app_url }
        this.props.setHubReservations(hub)
    }

    finishedOtherAppointment() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        const id = this.props.hub[0].id
        const appointment_acceptance = true
        const hubst3r_appointments = false
        const appointment_app_url = this.state.appointment_app_url
        const hub = { id, appointment_acceptance, hubst3r_appointments, appointment_app_url }
        this.props.setHubAppointments(hub)
    }

    answerAcceptingReservations() {
        this.setState({
            acceptingReservations: true,
        })
    }

    answerAcceptingAppointments() {
        this.setState({
            acceptingAppointments: true,
        })
    }

    answerHaveOnlineShop() {
        this.setState({
            haveOnlineShop: true,
        })
    }

    answerThroughReservation(answer) {
        this.setState({
            throughReservations: answer,
        })
    }

    goToAppointementSection(place_type) {
        this.setState({
            place_type: place_type,
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: true,
            onlineShopSection: false,
            categorySection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    goToOnlineShopSection(place_type) {
        this.setState({
            place_type: place_type,
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: true,
            categorySection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    answerThroughAppointment(answer) {
        this.setState({
            throughAppointments: answer,
        })
    }

    answerAppointmentAcceptance(answer) {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    answerOnlineShop(answer) {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    finishedOnlineShop() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        const id = this.props.hub[0].id
        const place_type = this.state.place_type
        const has_online_shop = true
        const reservation_acceptance = false
        const appointment_acceptance = false
        const shop_url = this.state.shop_url
        const hub = { id, place_type, appointment_acceptance, reservation_acceptance, has_online_shop, shop_url }
        this.props.setHubOnlineShop(hub)
    }

    goToCategorySection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: true,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    goToMapSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: true,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    submitHubCategory() {
        this.setState({
            finishedCategoryName: true,
        })
        const category = this.state.category
        if (category !== "") {
            const id = this.props.hub[0].id
            const place_type = this.state.place_type
            const hub = { id, category, place_type }
            this.props.setHubCategory(hub)
        }
    }

    selectCategory(category) {
        this.setState({
            map_icon: category
        })
    }

    submitHubMapIcon() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: true,
            promoSection: false,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
        const map_icon = this.state.map_icon
        if (map_icon !== "") {
            const id = this.props.hub[0].id
            const hub = { id, map_icon }
            this.props.setHubMapIcon(hub)
        }
    }


    goToPromoSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: true,
            socialSection: false,
            menuSection: false,
            finishedCategoryName: false,
        })
    }

    goToSocialSection() {
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: true,

            wifiMiniSection: true,
            wifi_code: "",
            telephoneMiniSection: false,
            telephone: "",
            has_telephone: false,
            websiteMiniSection: false,
            website: "",
            has_website: false,
            facebookMiniSection: false,
            facebook: "",
            has_facebook: false,
            instagramMiniSection: false,
            instagram: "",
            has_instagram: false,
            finishedCategoryName: false,
        })
    }

    goToMenuSection() {
        const hub_id = this.props.hub[0].id
        this.props.getHubCatalogs(hub_id)
        this.setState({
            logoNameSection: false,
            workingHoursSection: false,
            placeProvideSection: false,
            acceptReservationSection: false,
            acceptAppointmentSection: false,
            onlineShopSection: false,
            categorySection: false,
            mapSection: false,
            promoSection: false,
            socialSection: false,
            menuSection: true,
            catalogView: true,
            finishedCategoryName: false,
        })
        if (this.state.has_instagram && this.state.instagram !== "") {
            const id = this.props.hub[0].id
            const has_instagram = this.state.has_instagram
            const instagram_url = this.state.instagram
            const hub = { id, has_instagram, instagram_url }
            this.props.setHubInstagram(hub)
        }
        if (!this.state.has_instagram) {
            const id = this.props.hub[0].id
            const has_instagram = this.state.has_instagram
            const instagram_url = ""
            const hub = { id, has_instagram, instagram_url }
            this.props.setHubInstagram(hub)
        }
    }


    answerWifiMiniSection() {
        this.setState({
            wifiAnswer: true,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedWifiMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: true,
            websiteMiniSection: false,
            facebookMiniSection: false,
            instagramMiniSection: false,
        })
        const id = this.props.hub[0].id
        const wifi_code = this.state.wifi_code
        if (wifi_code !== this.props.hub[0].wifi_code && this.state.wifi_code !== "") {
            const id = this.props.hub[0].id
            const hub = { id, wifi_code }
            this.props.setHubWifiCode(hub)
        }

    }

    finishedTelephoneMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: true,
            facebookMiniSection: false,
            instagramMiniSection: false,
        })
        if (this.state.has_telephone && this.state.telephone !== "") {
            const id = this.props.hub[0].id
            const has_telephone = this.state.has_telephone
            const telephone = this.state.telephone
            const hub = { id, has_telephone, telephone }
            this.props.setHubTelephone(hub)
        }
        if (!this.state.has_telephone) {
            const id = this.props.hub[0].id
            const has_telephone = this.state.has_telephone
            const telephone = ""
            const hub = { id, has_telephone, telephone }
            this.props.setHubTelephone(hub)
        }
    }

    answerTelephoneMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: true,
            has_telephone: true,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedWebsiteMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: false,
            facebookMiniSection: true,
            instagramMiniSection: false,
        })
        if (this.state.has_website && this.state.website !== "") {
            const id = this.props.hub[0].id
            const has_website = this.state.has_website
            const website = this.state.website
            const hub = { id, has_website, website }
            this.props.setHubWebsite(hub)
        }
        if (!this.state.has_website) {
            const id = this.props.hub[0].id
            const has_website = this.state.has_website
            const website = ""
            const hub = { id, has_website, website }
            this.props.setHubWebsite(hub)
        }
    }

    answerWebsiteMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: true,
            has_website: true,
            facebookAnswer: false,
            instagramAnswer: false,
        })
    }

    finishedFacebookMiniSection() {
        this.setState({
            wifiMiniSection: false,
            telephoneMiniSection: false,
            websiteMiniSection: false,
            facebookMiniSection: false,
            instagramMiniSection: true,
        })
        if (this.state.has_facebook && this.state.facebook !== "") {
            const id = this.props.hub[0].id
            const has_facebook = this.state.has_facebook
            const facebook_url = this.state.facebook
            const hub = { id, has_facebook, facebook_url }
            this.props.setHubFacebook(hub)
        }
        if (!this.state.has_telephone) {
            const id = this.props.hub[0].id
            const has_facebook = this.state.has_facebook
            const facebook_url = ""
            const hub = { id, has_facebook, facebook_url }
            this.props.setHubFacebook(hub)
        }
    }

    answerFacebookMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: true,
            has_facebook: true,
            instagramAnswer: false,
        })
    }

    answerInstagramMiniSection() {
        this.setState({
            wifiAnswer: false,
            telephoneAnswer: false,
            websiteAnswer: false,
            facebookAnswer: false,
            instagramAnswer: true,
            has_instagram: true,
        })
    }


    onCoverPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            photo: URL.createObjectURL(e.target.files[0]),
            cover_photo: e.target.files[0]
        })
        setTimeout(() => {
            this.updateCoverPhoto()
        }, 4000);
    };

    updateCoverPhoto = () => {
        const id = this.props.hub[0].id
        const cover_photo = this.state.cover_photo
        const hub = { id, cover_photo }
        this.props.uploadHubCoverPhoto(hub)
        // hub.cover_photo = new_cover_photo
        // hub.logo = ""
        // hub.promo_photo = ""
        // this.props.updateHub(hub)
    }

    onPromoPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            promo_photo: URL.createObjectURL(e.target.files[0]),
            promo_photo_file: e.target.files[0]
        })
        setTimeout(() => {
            this.updatePromoPhoto()
        }, 4000);
    };

    updatePromoPhoto = () => {
        const id = this.props.hub[0].id
        const photo = this.state.promo_photo_file
        const hub = { id, photo }
        this.props.uploadHubPromoPhoto(hub)
        // hub.cover_photo = new_cover_photo
        // hub.logo = ""
        // hub.promo_photo = ""
        // this.props.updateHub(hub)
    }

    onLogoPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            logo_photo: URL.createObjectURL(e.target.files[0]),
            logo_photo_file: e.target.files[0]
        })
        setTimeout(() => {
            this.updateLogoPhoto()
        }, 4000);
    };

    updateLogoPhoto = () => {
        const id = this.props.hub[0].id
        const logo = this.state.logo_photo_file
        const hub = { id, logo }
        this.props.uploadHubLogo(hub)
    }

    changeCatalogView() {
        this.setState({
            catalogView: !this.state.catalogView,
            catalogNameView: false,
        })
    }


    setCatalogName() {
        this.setState({
            catalogNameView: !this.state.catalogNameView,
            catalog_name: "",
        })
    }

    createNewCatalog() {
        const hub = this.props.hub[0].id
        const name = this.state.catalog_name

        if (name === "") {
            this.props.createAlert({ noInvitationsSent: "You haven't given a name to the catalog!" });

        } else {
            const catalog = { hub, name }
            this.props.createHubCataloge(catalog)
            this.setState({
                catalogView: !this.state.catalogView,
            })
        }

        // this.setState({
        //     catalog_name: "",
        //     catalogNameView: false,
        // })
    }


    onMenuPagesUpload = (e) => {
        this.setState({
            uploading_pages: true
        })

        const hub = this.props.hub[0].id
        let photo = ""
        let page = 0
        let menu_page = {}
        let container_id = ""

        if (this.state.catalog_id !== "") {
            container_id = this.state.catalog_id
        } else {
            container_id = this.props.new_catalog.id
        }
        for (let i = 0; i < e.target.files.length; i++) {
            photo = e.target.files[i]
            page = i + 1
            menu_page = { hub, photo, page, container_id }
            this.props.createNewMenuPage(menu_page)

        }

        setTimeout(() => {
            const hub_id = this.props.hub[0].id
            this.props.getHubCatalogs(hub_id)
        }, 5000);



        setTimeout(() => {
            this.setState({
                uploading_pages: false,
                catalogView: true,
                catalogNameView: false,
                viewMenuPages: false,
            })
        }, 3000);


        // const cataloge_file = e.target.files[0]
        // const cataloge = { hub, cataloge_file }
    }



    openCatalogPagesView(catalog_id, catalog_name) {
        if (catalog_id !== "") {
            this.props.getHubMenuPages(catalog_id)
        } else {

        }
        this.setState({
            viewMenuPages: true,
            catalogView: !this.state.catalogView,
            catalog_view_name: catalog_name
        })
    }

    closeCatalogPagesView() {
        this.setState({
            catalogView: true,
            viewMenuPages: false,
        })
    }


    acceptHubsTerms() {
        this.setState({
            done_setup: true
        })

        this.props.acceptHubsTerms()
        setTimeout(() => {
            this.setState({
                redirect: true
            })
        }, 500);
    }

    answerReservationHours(choise) {
        const id = this.props.hub[0].id
        const reservation_hours_choise = choise
        const hub = { id, reservation_hours_choise }
        this.props.selectHubReservationHours(hub)
        if (choise === "specific") {
            this.setState({
                specificHourMode: true
            })
        } else {
            this.setState({
                logoNameSection: false,
                workingHoursSection: false,
                placeProvideSection: false,
                acceptReservationSection: false,
                acceptAppointmentSection: false,
                onlineShopSection: false,
                categorySection: true,
                mapSection: false,
                promoSection: false,
                socialSection: false,
                menuSection: false,
                finishedCategoryName: false,
            })
        }
    }

    answerAppointmentHours(choise) {
        const id = this.props.hub[0].id
        const appointment_hours_choise = choise
        const hub = { id, appointment_hours_choise }
        this.props.selectHubAppointmentHours(hub)
        if (choise === "specific") {
            this.setState({
                specificAppointmentHourMode: true
            })
        } else {
            this.setState({
                logoNameSection: false,
                workingHoursSection: false,
                placeProvideSection: false,
                acceptReservationSection: false,
                acceptAppointmentSection: false,
                onlineShopSection: false,
                categorySection: true,
                mapSection: false,
                promoSection: false,
                socialSection: false,
                menuSection: false,
                finishedCategoryName: false,
            })
        }
    }

    addNewSpecificHour() {
        let hour = this.state.hour
        let minutes = this.state.minutes
        const time = hour + ":" + minutes

        if (hour > 23 && hour > -1) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Hour" });
        } else if (minutes > 59) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Minutes" });
        } else {
            const specific_time = { time }
            this.props.createNewSpecificReservationHour(specific_time)
        }
    }

    addNewSpecificAppointmentHour() {
        let hour = this.state.hour
        let minutes = this.state.minutes
        const time = hour + ":" + minutes

        if (hour > 23 && hour > -1) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Hour" });
        } else if (minutes > 59) {
            this.props.createAlert({ noInvitationsSent: "You have provided a wrong Minutes" });
        } else {
            const specific_time = { time }
            this.props.createNewSpecificAppointmentHour(specific_time)
        }
    }


    removeSpecificHour(id, time) {
        const specific_time = { id, time }
        this.props.removeSpecificReservationHour(specific_time)
    }

    removeSpecificAppointmentHour(id, time) {
        const specific_time = { id, time }
        this.props.removeSpecificAppointmentHour(specific_time)
    }

    removeCatalog(id) {
        const hub = this.props.hub[0].id
        const is_active = false
        const catalog = { id, hub, is_active }
        this.props.removeHubCataloge(catalog)
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/hub-homepage' }} />
        }

        const defaultOptions = {
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        let categories = []
        if (this.state.place_type === "Hospitality") {
            categories = [
                "restaurant",
                "coffee shop",
                "bar",
                "night club",
                // "cafe",
                "food",
                // 'drinks',
                'casino',
                "meal takeaway"]
        }
        if (this.state.place_type === "Shops") {
            categories = [
                "clothing store",
                "electronics store",
                "shoe store",
                "supermarket",
                "jewelry store",
                "liquor store",
                "pet store",
                "shopping mall",
                "convenience store",
                "gas station",
                "home goods store",
                "hardware store",
                "pharmacy",
                "florist",
                "furniture store",
                "bicycle store",
                "book store",
                "car dealer",
                "bakery"]
        }
        if (this.state.place_type === "Services") {
            categories = [
                "beauty salon",
                "hair care",
                "spa",
                "doctor",
                "dentist",
                "lawyer",
                "electrician",
                "accounting",
                "bank",
                "gym",
                "veterinary care",
                // "movie rental", 
                // "meal delivery", 
                "insurance agency",
                "travel agency",
                "parking",
                "painter",
                "car rental",
                "physiotherapist",
                // "roofing contractor", 
                "real estate agency",
                "plumber",
                "car repair",
                "car wash",
                // "lodging", 
                // "embassy", 
                // "local government office", 
                "laundry",
                "locksmith"]
        }
        if (this.state.place_type === "Other") {
            categories = [
                "cinema",
                "minifootball",
                "sports",
                "bowling alley",
                "art gallery",
                "museum",
                "aquarium",
                "amusement park",
                "zoo",
                "campground",
                "library",
                "rv park",
                // "tourist attraction",
                "university"]
        }

        const screen_width = window.screen.width
        const menu_file = this.state.menu
        const hub = this.props.hub
        let promo_photo = ""
        if (hub.length > 0) {
            promo_photo = hub[0].promo_photo
        }

        if (this.state.promo_photo !== "") {
            promo_photo = this.state.promo_photo
        }

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const tagSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const categorySettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };


        const catalogs = this.props.catalogs
        return (
            <Fragment>
                {this.props.hub.map(hub => (
                    <>
                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />
                                <>
                                    <div class="setup-page-welcome-title">
                                        <Link to="/hub-homepage" class="setup-page-back-box">
                                            <div class="setup-page-back-icon-box">
                                                <img
                                                    class="setup-page-back-icon"
                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        Set Working Hours
                                    </div>

                                    <>
                                        <div class="setup-page-logo-text">
                                            Is the Working Hours right?
                                        </div>
                                        <div class="setup-page-all-days-layout">
                                            {this.props.working_days.map(day => (
                                                <>
                                                    <div class="row setup-page-day-row">
                                                        <div class="setup-page-day-layout">
                                                            {day.day}
                                                        </div>
                                                        {day.is_close ?
                                                            // {day.index === 1 ?
                                                            <>
                                                                <div class="setup-page-day-is_close">
                                                                    Close
                                                                </div>
                                                                <div onClick={() => this.clickOnCloseChange(day.id)} class="setup-page-day-change_close">
                                                                    change
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div onClick={() => this.clickOnDayClock(day, "opening")}>
                                                                    {this.state.changing_day_id === day.id && this.state.changing_opening === "opening" ?
                                                                        <input
                                                                            class='setup-page-name-input-field-opening'
                                                                            name="opening_hour"
                                                                            onChange={this.onChange}
                                                                            value={this.state.opening_hour === "" ? day.opening_hour : this.state.opening_hour}
                                                                        />
                                                                        :
                                                                        <div class="setup-page-opening-btn">
                                                                            {day.opening_hour}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div onClick={() => this.clickOnDayClock(day, "closing")}>
                                                                    {this.state.changing_day_id === day.id && this.state.changing_opening === "closing" ?
                                                                        <input
                                                                            class='setup-page-name-input-field-closing'
                                                                            name="closing_hour"
                                                                            onChange={this.onChange}
                                                                            value={this.state.closing_hour === "" ? day.closing_hour : this.state.closing_hour}
                                                                        />
                                                                        :
                                                                        <div class="setup-page-closing-btn">
                                                                            {day.closing_hour}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div onClick={() => this.clickOnDayClose(day.id)} class="setup-page-day-close">
                                                                    close
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </>

                                </>
                            </div>
                        </div>

                        <Link to="/information"
                            //  onClick={() => this.acceptHubsTerms()} 
                            class="setup-page-next-btn">
                            Done
                        </Link>


                    </>
                ))}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    working_days: state.hub.working_days,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    getHubWorkingDays,
    hubWorkingDayClose,
    setWorkingDayHours,
})(SetupPage);