import React, { Fragment, lazy, Component } from 'react';
import Lottie from 'react-lottie';
import "../../../assets/layout/lotties/LottieStyle.css"
import animationData from '../../../assets/layout/lotties/HubsterLottie.json';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    getHubWorkingDays,
    hubWorkingDayClose,
    setWorkingDayHours,
    uploadHubLogo,
    uploadHubCoverPhoto,
    uploadHubPromoPhoto,
    setHubName,
    setHubCategory,
    setHubMapIcon,
    setHubWifiCode,
    setHubTelephone,
    setHubWebsite,
    setHubFacebook,
    setHubInstagram,
    setHubReservations,
    setHubAppointments,
    setHubOnlineShop,
    getHubCatalogs,
    createHubCataloge,
    removeHubCataloge,
    createNewMenuPage,
    getHubMenuPages,
    selectHubReservationHours,
    selectHubAppointmentHours,
    createNewSpecificReservationHour,
    removeSpecificReservationHour,
    createNewSpecificAppointmentHour,
    removeSpecificAppointmentHour,
} from '../../../store/actions/hub'
import { acceptHubsTerms } from '../../../store/actions/auth'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';

import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import moment from "moment";
import hub from '../../../store/reducers/hub';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class SetMapPhoto extends Component {

    state = {
        cover_photo: "",
        photo: null,
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onCoverPhotoSubmit = (e) => {
        e.preventDefault();
        this.setState({
            photo: URL.createObjectURL(e.target.files[0]),
            cover_photo: e.target.files[0]
        })
        setTimeout(() => {
            this.updateCoverPhoto()
        }, 4000);
    };

    updateCoverPhoto = () => {
        const id = this.props.hub[0].id
        const cover_photo = this.state.cover_photo
        const hub = { id, cover_photo }
        this.props.uploadHubCoverPhoto(hub)
    }

    render() {

        const screen_width = window.screen.width

        return (
            <Fragment>
                {this.props.hub.map(hub => (
                    <>
                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />



                                <div class="setup-page-welcome-title">
                                    <Link to="/hub-homepage" class="setup-page-back-box">
                                        <div class="setup-page-back-icon-box">
                                            <img
                                                class="setup-page-back-icon"
                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </Link>
                                    Setup Map Photo
                                </div>

                                <>

                                    <iframe
                                        src={hub.map_url}
                                        class="setup-page-map-layout-sml"
                                        aria-hidden="false"
                                        tabindex="0"
                                    />

                                    <div
                                        style={{ width: screen_width + "px", height: screen_width / 2.3 + "px" }}
                                        class="setup-page-place-show-bubble"
                                    >
                                        <div
                                            style={{ width: screen_width + "px", height: screen_width / 3 + "px" }}
                                            class="setup-page-promo-photo-box"
                                        >




                                            {this.state.photo ?
                                                <img
                                                    class="setup-page--promo-photo-img"
                                                    src={this.state.photo}
                                                    onClick={() => this.fileInput.click()}
                                                    alt=""
                                                />
                                                :
                                                <>
                                                    {hub.cover_photo !== "" ?

                                                        <img
                                                            class="setup-page--promo-photo-img"
                                                            onClick={() => this.fileInput.click()}
                                                            src={hub.cover_photo}
                                                            alt=""
                                                        />
                                                        :
                                                        <img
                                                            class="setup-page--promo-photo-img"
                                                            onClick={() => this.fileInput.click()}
                                                            alt=""
                                                        />
                                                    }
                                                </>
                                            }

                                            {!this.state.photo ?
                                                <div onClick={() => this.fileInput.click()} class="setup-page-set-cover-photo">
                                                    Set Photo
                                                </div>
                                                :
                                                null
                                            }



                                            <input
                                                style={{ display: 'none' }}
                                                accept="image/png, image/jpeg"
                                                type="file"
                                                name="photo"
                                                onChange={this.onCoverPhotoSubmit}
                                                ref={fileInput => this.fileInput = fileInput}
                                            />




                                            <div class="setup-page-bubble-closer-name">
                                                {hub.name}

                                            </div>
                                            <div class="setup-page-bubble-closer-category">
                                                {hub.category}
                                            </div>

                                            {/* {hub.is_open_label === "" ?
                                                                                <>
                                                                                    {hub.is_open ?
                                                                                        <div class="setup-page-bubble-closer-is-open">
                                                                                            <div class="setup-page-bubble-closer-green-bubble"></div>
                                                                                            open
                                                                                        </div>
                                                                                        :
                                                                                        <div class="setup-page-bubble-closer-is-closed">
                                                                                            <div class="setup-page-bubble-closer-red-bubble"></div>
                                                                                            closed
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <div class="setup-page-bubble-closer-has-label">
                                                                                    <div class="setup-page-bubble-closer-yellow-bubble"></div>
                                                                                    {hub.is_open_label}
                                                                                </div>
                                                                            } */}

                                        </div>
                                        <div
                                            style={{ width: screen_width + "px", height: screen_width / 9.857 + "px" }}
                                            class="setup-page-bubble-bottom-text">



                                            {/* {this.state.show_places_bubble ?
                                                                                <div
                                                                                    onClick={() => this.changeShowPlaceBubble()}
                                                                                    class="setup-page-top-down-arrow">
                                                                                    <img
                                                                                        class="setup-page-top-down-arrow-icon"
                                                                                        src={require("../../../assets/map/svg/up-arrow.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                null
                                                                            } */}

                                        </div>
                                    </div>
                                </>

                            </div>
                        </div>


                        <Link to="/information"
                            //  onClick={() => this.acceptHubsTerms()} 
                            class="setup-page-next-btn">
                            Done
                        </Link>


                    </>
                ))}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    uploadHubCoverPhoto,
})(SetMapPhoto);