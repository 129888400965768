import React, { Component, Fragment } from 'react';
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/booked_page/reservationsListModal.css"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

export class ReservationsListModal extends Component {
    state = {
        date: new Date(),
        sellected_reservation: "",
        sellected_hub__reservation: ""
    }

    closeModal = () => {
        this.props.data.closeModal()
    }

    updateDateField(date) {
        this.setState({
            date: date,
        })
        // make date only numbers
        const correct_datetime = moment(date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getDatesReservations(date_id)
        this.props.getDatesHubReservations(date_id)
        // this.props.data.getDatesTables(date_id)
    }


    setReservationTable = (reservation_id) => {
        this.setState({
            sellected_reservation: reservation_id,
        })
        const id = reservation_id
        const table = this.props.data.sellected_table.id
        const table_number = this.props.data.sellected_table_number
        const reservation = { id, table, table_number }
        this.props.data.setReservationTable(reservation, id)
        setTimeout(() => {
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getDatesReservations(date_id)
            this.props.getDatesHubReservations(date_id)
            this.props.getHubAllTables(date_id)
            this.props.data.closeModal()
            this.setState({
                sellected_reservation: "",
            })
        }, 500);
    }

    setHubReservationTable = (hub_reservation_id) => {
        this.setState({
            sellected_hub_reservation: hub_reservation_id,
        })
        const id = hub_reservation_id
        const table = this.props.data.sellected_table.id
        const table_number = this.props.data.sellected_table_number
        const hub_reservation = { id, table, table_number }
        this.props.data.setHubReservationTable(hub_reservation)
        setTimeout(() => {
            const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
            const date1 = correct_datetime.replace(/[^\d.-]/g, '');
            const date2 = date1.split('.').join("");
            const date_id = date2.split('-').join("");
            this.props.getDatesReservations(date_id)
            this.props.getDatesHubReservations(date_id)
            this.props.getHubAllTables(date_id)
            this.props.data.closeModal()
            this.setState({
                sellected_hub_reservation: "",
            })
        }, 500);


    }

    render() {
        const ExampleCustomInput = ({ value, onClick }) => (
            <div onClick={onClick} class="reservation-list-datepicker">
                <strong onClick={onClick}>
                    {value}
                </strong>
            </div>
        );
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 3,
            vertical: true,
            verticalSwiping: true,
            // swipeToSlide: true,
        };
        let date = this.state.date
        const reservations = this.props.data.reservations.filter(reservation => reservation.table_number === "")
        const hub_reservations = this.props.data.hub_reservations.filter(hub_reservation => hub_reservation.table_number === "")
        return (
            <Fragment>
                <div class="reservation-list-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="reservation-list-modal-box">
                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="reservation-list-cancle-btn">
                                        <img class="reservation-list-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="text-left reservation-list-title-box">
                                    <h4><strong class="reservation-list-title">Sellect Reservation for Table {this.props.data.sellected_table_number} at:</strong></h4>
                                </div>
                                <DatePicker
                                    onChange={this.updateDateField.bind(this)}
                                    selected={this.state.date}
                                    name="date"
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<ExampleCustomInput />}
                                    minDate={new Date()}
                                />
                            </div>

                            <div class="reservation-list-slider">
                                <Slider {...settings} >
                                    {reservations.map(reservation => (
                                        <div class="reservation-list-item" >
                                            <div class="row reservation-list-single-item">
                                                <div class="reservation-list-user-info">
                                                    {reservation.reservation_user_photo ?
                                                        <img class="reservation-list-user-picture" src={reservation.reservation_user_photo} alt="" />
                                                        :
                                                        <img class="reservation-list-user-picture" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                    }
                                                </div>
                                                <div class="reservation-list-info">
                                                    <div class="reservation-list-info-layout">
                                                        <p class="reservation-list-text">Name: {reservation.reservation_name}</p>
                                                        <p class="reservation-list-text">Persons: {reservation.persons}</p>
                                                        <p class="reservation-list-text">Time: {reservation.time}</p>
                                                    </div>
                                                </div>
                                                <div class="reservation-list-btn">
                                                    {this.state.sellected_reservation === reservation.id ?
                                                        <div class="reservation-list-btn-sellected">
                                                            Selected
                                                        </div>
                                                        :
                                                        <div onClick={() => this.setReservationTable(reservation.id)} class="reservation-list-btn-sellect">
                                                            Select
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {hub_reservations.map(hub_reservation => (
                                        <div class="reservation-list-item" >
                                            <div class="row reservation-list-single-item">
                                                <div class="reservation-list-user-info">
                                                    {hub_reservation.reservation_user_photo ?
                                                        <img class="reservation-list-user-picture" src={hub_reservation.reservation_user_photo} alt="" />
                                                        :
                                                        <img class="reservation-list-user-picture" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                    }
                                                </div>
                                                <div class="reservation-list-info">
                                                    <div class="reservation-list-info-layout">
                                                        <p class="reservation-list-text">Name: {hub_reservation.reservation_name}</p>
                                                        <p class="reservation-list-text">Persons: {hub_reservation.persons}</p>
                                                        <p class="reservation-list-text">Time: {hub_reservation.time}</p>
                                                    </div>
                                                </div>
                                                <div class="reservation-list-btn">
                                                    {this.state.sellected_hub_reservation === hub_reservation.id ?
                                                        <div class="reservation-list-btn-sellected">
                                                            Selected
                                                        </div>
                                                        :
                                                        <div onClick={() => this.setHubReservationTable(hub_reservation.id)} class="reservation-list-btn-sellect">
                                                            Select
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (ReservationsListModal);