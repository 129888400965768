import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/map/place-marker-modal.css"


export class PlaceMarkerModal extends Component {

    state = {
        isPlaying: false,
        requestAppointmentMode: false,
        appointment_request_note: "-",
        telephone: "-",
        new_memory: "",
        memory_photo: null,
        memory_submited: false,
        hub_id: "",
        lat: "",
        lng: "",
        hub_name: "",
    }

    componentDidMount() {
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }


    closeModal() {
        this.setState({
            requestAppointmentMode: false
        })
        this.props.data.closeModal()

    }

    snapMemorySubmit = (hub_id, lat, lng, hub_name) => (e) => {
        e.preventDefault();
        this.setState({
            new_memory: e.target.files[0],
            memory_photo: URL.createObjectURL(e.target.files[0]),
            memory_submited: true,
            hub_id: hub_id,
            lat: lat,
            lng: lng,
            hub_name: hub_name
        })
    };

    openRequestAppointmentMode() {
        this.setState({
            requestAppointmentMode: !this.state.requestAppointmentMode
        })
    }

    userRequestAppointment() {
        this.setState({
            requestAppointmentMode: !this.state.requestAppointmentMode
        })
        let telephone = this.state.telephone
        const appointment_request_note = this.state.appointment_request_note
        const hub_id = this.props.data.hub.id
        if (telephone === "-") {
            this.props.createAlert({ noTimeSelected: "You haven't given a phone number to the Hub!" });
        } else {
            const appointment = { hub_id, telephone, appointment_request_note }
            this.props.requestAppointment(appointment)
        }
    }



    render() {

        if (this.state.memory_submited) {
            return <Redirect to={{ pathname: '/post-memory', state: { new_memory: this.state.new_memory, memory_photo: this.state.memory_photo, hub: this.state.hub_id, lat: this.state.lat, lng: this.state.lng, name: this.state.hub_name } }} />
        }

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const memories = this.props.data.memories
        const hub = this.props.data.hub

        let telephone = "tel:"
        let is_open_text = ""


        if (hub.has_telephone) {
            telephone = telephone + hub.telephone
        }

        if (hub.is_open_label !== "") {
            is_open_text = hub.is_open_label
        } else {
            if (hub.is_open_now) {
                is_open_text = "open"
            } else {
                is_open_text = "closed"
            }
        }

        return (
            <Fragment>
                <>
                    {this.state.requestAppointmentMode ?
                        <div class="">
                            <div class="row">
                                <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                                <div class="place-marker-modal-box">

                                    <div onClick={() => this.openRequestAppointmentMode()} class="place-marker-modal-back-button-box">
                                        <img
                                            class="hub-page-back-button"
                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                            alt=""
                                        />
                                    </div>

                                    <div class="place-marker-modal-appointment-title">
                                        Request an Appointment at {hub.name}
                                    </div>


                                    <div class="place-marker-modal-appointment-phone-text">
                                        Insert your Mobile Phone Number
                                    </div>
                                    <div class="place-marker-modal-appointment-phone-input">
                                        <input
                                            maxlength="18"
                                            className="form-control"
                                            type="number"
                                            name="telephone"
                                            onChange={this.onChange}
                                            value={this.state.telephone === "-" ? null : this.state.telephone}
                                            placeholder="Mobile Phone Number..."
                                        />

                                    </div>

                                    <div class="place-marker-modal-appointment-small-text">
                                        Type any preferenses you have for the appointment<br />
                                        (i.e. time, date, reason of the appointment)
                                    </div>

                                    <div class="place-marker-modal-appointment-textrarea">
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            value={this.state.appointment_request_note === "-" ? null : this.state.appointment_request_note}
                                            placeholder="Message..."
                                            rows="5"
                                            name="appointment_request_note"
                                            onChange={this.onChange}
                                        ></textarea>
                                    </div>

                                    <div onClick={() => this.userRequestAppointment()} class="place-marker-modal-appointment-request-btn">
                                        Request Appointment
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="">
                            <div class="row">
                                <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                                <div class="place-marker-modal-box">


                                    {hub.is_open_label !== "" ?
                                        <div class="place-marker-modal-closer-yellow-bubble"></div>
                                        : hub.is_open_now ?
                                            <div class="place-marker-modal-closer-green-bubble"></div>
                                            :
                                            <div class="place-marker-modal-closer-red-bubble"></div>
                                    }

                                    <div onClick={() => this.closeModal()} class="map-search-place-modal-times-box">
                                        <img
                                            class="map-search-place-modal-times-igg"
                                            src={require("../../../assets/layout/homepage svg/times.svg")}
                                            alt=""
                                        />
                                    </div>



                                    <div class="place-marker-modal-buddy-name">
                                        {hub.name.length > 35 ?
                                            <>
                                                {hub.name.slice(0, 33) + "..."}
                                            </>
                                            :
                                            <>
                                                {hub.name}
                                            </>
                                        }
                                    </div>

                                    <div class="place-marker-modal-all-info">
                                        {/* <div class="row"> */}
                                        {hub.address}<br />
                                        {hub.category}<br />
                                        {hub.wifi_code ?
                                            <>
                                                wifi: {hub.wifi_code}<br />
                                            </>
                                            :
                                            null
                                        }
                                        {hub.is_open_label !== "" ?
                                            <b>{hub.is_open_label}</b>
                                            : is_open_text === "open" ?
                                                <b>open</b>
                                                :
                                                <b>closed</b>
                                        }

                                    </div>
                                    {/* <div onClick={() => this.closeModal()} class="place-marker-modal-times-box">
                                <img
                                    onClick={() => this.closeModal()}
                                    class="place-marker-modal-times"
                                    src={require("../../../assets/layout/homepage svg/times.svg")}
                                    alt=""
                                />
                            </div> */}
                                    <div class="place-marker-modal-all-social">
                                        <div class="row">
                                            {hub.has_facebook ?
                                                <a class="place-marker-modal-social-box" href={hub.facebook_url}>
                                                    <img
                                                        class="place-marker-modal-social-img"
                                                        src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                                :
                                                null
                                            }
                                            {hub.has_instagram ?
                                                <a class="place-marker-modal-social-box" href={hub.instagram_url}>
                                                    <img
                                                        class="place-marker-modal-social-img"
                                                        src={require("../../../assets/ambassandors/svg/instagram.png")}
                                                        alt=""
                                                    />
                                                </a>

                                                :
                                                null
                                            }
                                            {hub.has_telephone ?
                                                <a class="place-marker-modal-social-box" href={telephone}>
                                                    <img
                                                        class="place-marker-modal-social-img"
                                                        src={require("../../../assets/ambassandors/svg/phone.svg")}
                                                        alt=""
                                                    />
                                                </a>
                                                :
                                                null
                                            }
                                            {hub.has_website ?
                                                <a class="place-marker-modal-social-box" href={hub.website}>
                                                    <img
                                                        class="place-marker-modal-social-img"
                                                        src={require("../../../assets/ambassandors/svg/website.png")}
                                                        alt=""
                                                    />
                                                </a>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div class="place-marker-modal-title-hr">
                                        <hr />
                                    </div>

                                    <div class="row place-marker-modal-all-btns">
                                        {/* <div class=""> */}
                                        <Link onTouchStart class="place-marker-modal-btn-visit" to={{ pathname: '/hub', state: { hub_id: hub.id } }}>
                                            See Profile
                                        </Link>

                                        <div onClick={() => this.fileInput.click()} onTouchStart class="place-marker-modal-btn-post-memory">
                                            Post Memory
                                        </div>

                                        <input
                                            style={{ display: 'none' }}
                                            accept="image/png, image/jpeg"
                                            type="file"
                                            name="memory_photo"
                                            onChange={
                                                this.snapMemorySubmit(hub.id, hub.lat, hub.lng, hub.name)
                                            }
                                            ref={fileInput => this.fileInput = fileInput}
                                        />

                                        {hub.place_type === "Hospitality" && hub.has_menu ?
                                            <Link onTouchStart class="place-marker-modal-btn-menu" to={{ pathname: `/menu-page-view/${hub.id}`, state: { hub: hub.id } }}>
                                                Menu
                                            </Link>
                                            : hub.has_menu ?
                                                <Link onTouchStart class="place-marker-modal-btn-menu" to={{ pathname: `/menu-page-view/${hub.id}`, state: { hub: hub.id } }}>
                                                    Catalog
                                                </Link>
                                                :
                                                null
                                        }
                                        {/* {hub.has_delivery_apps ?
                                        <div class="place-marker-modal-btn-order">
                                            Order Online
                                        </div>
                                        :
                                        null
                                    } */}
                                    </div>


                                    <div class="row place-marker-modal-all-btns-2">
                                        {!hub.reservation_acceptance && hub.active_subscription && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                            <div class="place-marker-modal-no-reservations">
                                                Does <b>not Accept</b> Reservations
                                            </div>
                                            : hub.reservation_acceptance && hub.active_subscription && hub.another_app_reservations && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                                <>
                                                    {hub.map_icon === "cinema" ?
                                                        <a onTouchStart class="place-marker-modal-btn-reservation" href={hub.reservation_app_url}>
                                                            Get me to<br />buy Tickets
                                                            {/* {hub.reservation_app_url} */}
                                                        </a>
                                                        :
                                                        <a onTouchStart class="place-marker-modal-btn-reservation" href={hub.reservation_app_url}>
                                                            Get me to<br />request a Reservation
                                                            {/* {hub.reservation_app_url} */}
                                                        </a>
                                                    }
                                                </>
                                                : hub.reservation_acceptance && hub.active_subscription && hub.telephone_reservations && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                                    <>
                                                        {hub.map_icon === "cinema" ?
                                                            <a onTouchStart class="place-marker-modal-btn-reservation" href={telephone}>
                                                                Call to<br />buy Tickets
                                                                {/* {hub.reservation_app_url} */}
                                                            </a>
                                                            :
                                                            <a onTouchStart class="place-marker-modal-btn-reservation" href={hub.reservation_app_url}>
                                                                Get me to<br />request a Reservation
                                                                {/* {hub.reservation_app_url} */}
                                                            </a>
                                                        }

                                                    </>
                                                    : hub.hubst3r_reservations && hub.reservation_acceptance && (hub.place_type === "Hospitality" || hub.place_type === "Other") ?
                                                        <Link onTouchStart class="place-marker-modal-btn-reservation" to={{ pathname: '/hub/request-reservation', state: { hub: hub } }}>
                                                            Reservation through<br />Hubst3r
                                                        </Link>
                                                        :
                                                        null
                                        }


                                        {!hub.appointment_acceptance && hub.active_subscription && hub.place_type === "Services" ?
                                            <div class="place-marker-modal-no-reservations">
                                                Does <b>not Accept</b> Appointments
                                            </div>
                                            : hub.appointment_acceptance && hub.active_subscription && hub.another_app_appointments && hub.place_type === "Services" ?

                                                <a onTouchStart class="place-marker-modal-btn-reservation" href={hub.appointment_app_url}>
                                                    Get me to<br />request an Appointment
                                                    {/* Appointment through<br />{hub.appointment_app_url} */}
                                                </a>

                                                : hub.appointment_acceptance && hub.active_subscription && hub.telephone_appointments && hub.place_type === "Services" ?
                                                    <a onTouchStart class="place-marker-modal-btn-reservation" href={telephone}>
                                                        Call for Appointment
                                                    </a>

                                                    : hub.hubst3r_appointments && hub.appointment_acceptance && hub.place_type === "Services" ?
                                                        <div onTouchStart class="place-marker-modal-btn-reservation" onClick={() => this.openRequestAppointmentMode()}>
                                                            Appointment through<br />Hubst3r
                                                        </div>
                                                        :
                                                        null
                                        }

                                        {hub.has_online_shop && hub.place_type === "Shops" ?
                                            <a onTouchStart class="place-marker-modal-btn-shop" href={hub.shop_url}>
                                                Shop Online
                                            </a>
                                            :
                                            null
                                        }
                                    </div>




                                    {memories.length === 0 ?
                                        <div class="place-marker-modal-no-memories">
                                            <div class="place-marker-modal-no-memories-image">
                                                <img
                                                    class="place-marker-modal-no-memories-img"
                                                    src={require("../../../assets/hub/hub-page/svg/photo-icon.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <p class="place-marker-modal-no-memories-text">
                                                When you and other people<br /><b>share Memories</b> in <b>{hub.name}</b><br />they will appear here
                                            </p>
                                        </div>
                                        :
                                        <div class="place-marker-modal-all-choises">
                                            <div class="place-marker-modal-slider-layout">
                                                <Slider {...settings} >
                                                    {memories.map(memory => (
                                                        <div onClick={() => this.props.data.openStoriesModal(memory.user, true, false, "", "", true)} class="place-marker-modal-select-budies-frame">
                                                            <div class="place-marker-modal-buddies-card">
                                                                <div
                                                                    style={{ backgroundImage: 'url(' + memory.photo + ')' }}
                                                                    class='place-marker-modal-buddies-card-square'
                                                                >
                                                                    <div class="place-marker-modal-buddies-username">
                                                                        {memory.user_username}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </Slider>

                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>

                        </div>
                    }
                </>

            </Fragment >
        )
    }
}


export default (PlaceMarkerModal);