import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {
    getMemoryUploadHubList,
    createHubProspect,
    createNewUserProspect,
    searchHubs,
    createdHubMarker,
    getNewCreatedHub,

} from '../../../store/actions/hub'
import {
    getMapPlaces,
    createdFetchedHubsMarkers
} from '../../../store/actions/map';
import { getMyProfile } from '../../../store/actions/profile';

import { uploadMemory, uploadNoHubMemory } from '../../../store/actions/memory'
import { createAlert } from '../../../store/actions/alerts'

import { getBuddies, searchBuddy } from '../../../store/actions/buddies';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/memories/find-place.css"
import PlaceMap from '../../../components/user/memory/PlaceMap';
import Modal from 'react-awesome-modal';
import AddPlaceModal from '../../../components/user/map/AddPlaceModal';



export class FindPlace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // for google map places autocomplete
            address: '',
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            mapCenter: {
                lat: 0,
                lng: 0
            },
            myMapCenterLocation: {
                lat: 0,
                lng: 0
            },
            zoom: 18,
            selected_location: [],
            tags: [],
            hub_id: "0",
            redirect: false,
            is_rendered: false,
            show_places_bubble: true,
            hubSelectedModal: true,
            hub_name: "",
            add_new_place_icon_modal: false,
            new_place_lat: "",
            new_place_lng: "",

            selected_lat: "",
            selected_lng: "",
            selected_hub_name: "",
            selected_hub_id: "",
            selected_map_center: false,
            customPlaceNameModal: false,
            name_caption: "-"
        };
    }

    componentDidMount() {
        const country = JSON.parse(localStorage.getItem('cntr'))
        this.props.getMyProfile();

        this.props.getBuddies(JSON.parse(localStorage.getItem('ur')).id);
        const props = this.props
        if (this.props.location.state.lat !== "") {
            this.setState({
                mapCenter: {
                    lat: this.props.location.state.lat,
                    lng: this.props.location.state.lng,
                    is_rendered: true
                },
            })
        } else {
            const props = this.props
            const page_props = this
            window.navigator.geolocation.getCurrentPosition(position => {
                this.setState({
                    mapCenter: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    myMapCenterLocation: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    lastRequestedCenter: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    is_rendered: true
                })
                this.props.getMapPlaces("show_all", position.coords.latitude, position.coords.longitude, 19);
            }, function (error) {
                fetch(`https://geolocation-db.com/json/`)
                    .then(response => {
                        return response.json();
                    }, "jsonp")
                    .then(res => {
                        page_props.setState({
                            mapCenter: {
                                lat: res.latitude,
                                lng: res.longitude
                            },
                            myMapCenterLocation: {
                                lat: res.latitude,
                                lng: res.longitude
                            },
                            lastRequestedCenter: {
                                lat: res.latitude,
                                lng: res.longitude
                            },
                            is_rendered: true
                        })
                    }).catch(err => {
                        console.log(err)
                    });

                props.getMapPlaces("show_all", 'show_all', 'show_all', 19);
            })
        }
    }


    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }


    handleCenterChanged(lat, lng, zoom) {
        const last_request_position = this.state.lastRequestedCenter
        this.setState({
            zoom: zoom,
            mapCenter: {
                lat: lat,
                lng: lng
            }
        })
        const R = 3958.8; // Radius of the Earth in miles
        const rlat1 = last_request_position.lat * (Math.PI / 180); // Convert degrees to radians
        const rlat2 = lat * (Math.PI / 180); // Convert degrees to radians
        const difflat = rlat2 - rlat1; // Radian difference (latitudes)
        const difflon = (lng - last_request_position.lng) * (Math.PI / 180); // Radian difference (longitudes)

        const miles_distance = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));

        const km_distanes = miles_distance * 1.6;
        if (km_distanes > 50) {
            this.setState({
                lastRequestedCenter: {
                    lat: lat,
                    lng: lng
                },
            })
            this.props.getMapPlaces("show_all", lat, lng, 19)
        }
        this.setState({
            selected_lat: "",
            selected_lng: "",
            selected_hub_name: "",
            selected_hub_id: "",
        })
    };

    selectedPhotoLocation(lat, lng, hub_name, hub_id) {
        this.setState({
            selected_lat: lat,
            selected_lng: lng,
            selected_hub_name: hub_name,
            selected_hub_id: hub_id,
        })
    }

    handleSelectPlace(place) {
        let source_id = place.id
        let lat = place.geometry.coordinates[1]
        let lng = place.geometry.coordinates[0]
        let name = place.text
        let full_name = place.place_name
        let address = ""
        let category = ""
        let country_short = ""
        let postal_code = ""
        let place_name_array = full_name.split(", ");
        let country = place_name_array[place_name_array.length - 1]
        let city = ""
        if (place.properties.address) {
            address = place.properties.address
        }
        if (place.properties.category) {
            category = place.properties.category
        }

        if (place_name_array.length === 1) {
            city = ""
        } else if (place_name_array.length === 2) {
            city = place_name_array[0]
        } else if (place_name_array.length === 3) {
            city = place_name_array[1]
        } else {
            city = place_name_array[place_name_array.length - 3] + ", " + place_name_array[place_name_array.length - 2]
        }

        const place_context = place.context
        if (place_context.length > 0) {
            for (const context of place_context) {
                const context_id = context.id
                if (context_id.includes("country")) {
                    country_short = context.short_code
                    country = context.text
                }
                if (context_id.includes("postcode")) {
                    postal_code = context.text
                }
            }
        }

        const prospect = {
            name,
            address,
            city,
            country,
            country_short,
            postal_code,
            lat,
            lng,
            source_id,
            category,
            full_name
        }

        this.props.createHubProspect(prospect)
        // setTimeout(() => {
        //     this.props.getMapPlaces("show_all", this.state.mapCenter.lat, this.state.mapCenter.lng, this.state.zoom)
        // }, 10000);
    };

    onBuddiesChange = e => {
        if (e.target.name === "user_search") {
            if (e.target.value === "") {
                this.setState({ [e.target.name]: e.target.value })
                this.props.getBuddies(JSON.parse(localStorage.getItem('ur')).id);
            } else {
                const query = e.target.value
                if (query.length > 2) {
                    if (query.toLowerCase() !== "ad") {
                        this.props.searchBuddy(query)
                    }
                }
                this.setState({ [e.target.name]: e.target.value })
            }
        }
        this.setState({ [e.target.name]: e.target.value })
    }


    handleChange = address => {

        if (this.state.selected_location.length > 0) {
            this.setState({
                selected_location: [],
                hub_id: ""
            });
        }

        if (address === "") {
            this.setState({ address });
            const country = JSON.parse(localStorage.getItem('cntr'))
            this.props.getMemoryUploadHubList(country);
        } else if (address.length > 6) {
            this.setState({ address });
        } else {
            const query = address
            if (query.length > 2) {
                if (query.toLowerCase() !== "ad") {
                    this.props.searchHubs(query)
                }
            }
            this.setState({ address });
        };
    }


    handleHubSelect() {
        this.setState({
            hub_id: this.state.selected_hub_id,
            hub_name: this.state.selected_hub_name,
            mapCenter: {
                lat: this.state.selected_hub_lat,
                lng: this.state.selected_hub_lng
            },
            hubSelectedModal: true,
        });
    }

    handleCenterMapSelect() {
        this.setState({
            hub_id: 0,
            selected_hub_lat: this.state.mapCenter.lat,
            selected_hub_lng: this.state.mapCenter.lng,
            selected_map_center: true,
            hubSelectedModal: true,
        });
    }

    closeHandleHubSelected() {
        this.setState({
            hub_id: "0",
            selected_location: [],
            hub_name: "",
            hubSelectedModal: false,
            selected_map_center: false
        });
    }


    updateUserTagList = (id) => (e) => {
        e.preventDefault();
        const tag_list = [...this.state.tags]
        if ((tag_list.indexOf(id)) !== -1) {
            const new_remove = tag_list.filter(f => f !== id)
            this.setState({ tags: new_remove })
        } else {
            tag_list.push(id)
            this.setState({ tags: tag_list })
        }
    }

    postUploadedMemory = () => {
        // const photo = this.props.location.state.new_memory
        let video = null
        let photo = null
        const memory_type = this.props.location.state.new_memory.type
        if (memory_type.includes("video")) {
            video = this.props.location.state.new_memory
        } else {
            photo = this.props.location.state.new_memory
        }


        let hub_id = this.state.hub_id
        let hub_name = ""
        if (this.state.selected_hub_name === "") {
            hub_name = this.state.name_caption
        } else {
            hub_name = this.state.selected_hub_name
        }
        let source_id = ""
        const selected_hub = this.props.location.state.hub
        if (selected_hub) {
            hub_id = this.props.location.state.hub
        }

        if (hub_id === "") {
            source_id = this.state.selected_location[0].place_id
            hub_id = 0
        }

        const raw_tags_list = this.state.tags
        const tags_list = raw_tags_list.toString();


        if (photo === null && video === null) {
            this.props.createAlert({ noMemoryUploaded: "You haven't Uploaded a Memory!" });
        } else {
            setTimeout(() => {
                if (this.state.selected_hub_id || this.props.location.state.hub !== "") {
                    const memory = { video, photo, hub_id, tags_list, source_id };
                    this.props.uploadMemory(memory)
                } else {
                    const lat = this.state.mapCenter.lat
                    const lng = this.state.mapCenter.lng
                    const place_memory = { video, photo, hub_name, tags_list, lat, lng };
                    this.props.uploadNoHubMemory(place_memory)
                }
                this.setState({
                    hub_id: "",
                    memory: null,
                    hubsModal: false,
                    hub_search: "",
                    carouselLayout: true,
                    selectHubMode: true,
                    tagProfilesMode: false,
                    tags: [],
                    redirect: true
                });
            }, 500);
        }
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    openNewPlaceIconModal() {
        this.setState({
            add_new_place_icon_modal: true,
        })
    }

    closeNewPlaceIconModal() {
        this.setState({
            add_new_place_icon_modal: false,
        })
    }

    openAddPlaceModal(lng, lat) {
        this.setState({
            addPlaceModal: true,
            new_place_lat: lat,
            new_place_lng: lng,
        });
    }

    closeModal() {
        this.setState({
            addPlaceModal: false,
            add_new_place_icon_modal: false,
            customPlaceNameModal: false
        })
    }

    openNameOfThePlacModal() {
        this.setState({
            customPlaceNameModal: true,
        });
    }

    render() {
        if (this.props.location.state.memory_photo === null) {
            return <Redirect to="/" />
        }

        if (this.state.redirect) {
            return <Redirect to="/" />
        }

        const ArrowNext = (props) => (
            <>
            </>
        );

        const ArrowPrev = (props) => (
            <>
            </>
        );

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        const tagSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };


        let hub_id = this.state.hub_id
        const selected_hub = this.props.location.state.hub
        if (selected_hub !== "") {
            hub_id = this.props.location.state.hub
        }

        let hub_name = ""
        if (this.props.location.state.name !== "") {
            hub_name = this.props.location.state.name
        } else {
            if (this.state.selected_hub_id) {
                hub_name = this.state.selected_hub_name
            } else {
                hub_name = this.state.name_caption
            }
        }

        let map_places = this.props.map_places

        const all_places = map_places.sort(function (a, b) {
            return a.distance - b.distance
        })

        const mapCenterNow = this.state.mapCenter

        let places_distances = []
        all_places.map(place => {
            const R = 3958.8; // Radius of the Earth in miles
            const rlat1 = mapCenterNow.lat * (Math.PI / 180); // Convert degrees to radians
            const rlat2 = place.lat * (Math.PI / 180); // Convert degrees to radians
            const difflat = rlat2 - rlat1; // Radian difference (latitudes)
            const difflon = (place.lng - mapCenterNow.lng) * (Math.PI / 180); // Radian difference (longitudes)
            const miles_distance = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
            const km_distanes = miles_distance * 1.6;
            const place_id = place.id
            const distance_instance = { place_id, km_distanes }
            places_distances.push(distance_instance)
        })



        const sorted_distances = places_distances.sort(function (a, b) {
            return a.km_distanes - b.km_distanes
        })


        let closer_place_array = []
        if (sorted_distances.length > 0) {
            closer_place_array = all_places.filter(function (place) {
                return place.id === sorted_distances[0].place_id
            });
        }

        const closer_place = closer_place_array[0]

        let cover_photo = ""
        let closer_name = ""
        let closer_category = ""
        let closer_cover_props = ""
        let closer_is_open = false
        let closer_lat = ""
        let closer_lng = ""
        let is_open_label = "-"
        let show_places_bubble = false
        let has_sales = false
        let sales_text = ""
        let active_subscription = false
        let is_claimed = false
        let sales_text_gas = []

        if (closer_place) {
            cover_photo = closer_place.cover_photo
            closer_name = closer_place.name
            closer_category = closer_place.category
            closer_is_open = closer_place.is_open_now
            closer_lat = closer_place.lat
            closer_lng = closer_place.lng
            is_open_label = closer_place.is_open_label
            show_places_bubble = this.state.show_places_bubble
            has_sales = closer_place.has_sales
            sales_text = closer_place.sales_text
            active_subscription = closer_place.active_subscription
            is_claimed = closer_place.is_claimed
            closer_cover_props = closer_place.cover_photo_props
        }

        const profile = this.props.profile

        return (
            <div class="find-place-whole-page-layout">

                <div>
                    {hub_name === "" || hub_name === "-" ?
                        <div class="find-place-presellected-hub">
                            <div onClick={() => this.handleBack()} class="back-button-find-place-high-box">
                                <img
                                    class="find-place-back-button"
                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                    alt=""
                                />
                            </div>
                            Select the Place you Snapped the Memory
                        </div>
                        :
                        <div class="find-place-presellected-hub">
                            <div onClick={() => this.handleBack()} class="back-button-find-place-box">
                                <img
                                    class="find-place-back-button"

                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                    alt=""
                                />
                            </div>
                            {hub_name}
                        </div>
                    }

                    {/* {this.state.add_new_place_icon_modal ?
                        <div class="find-place-add-place-explaining-txr">
                            Press on the Map the location of the Place
                        </div>
                        :
                        null
                    } */}

                    {this.state.addPlaceModal || this.state.add_new_place_icon_modal || this.state.customPlaceNameModal ?
                        null
                        :
                        <>
                            {this.state.selected_location.length === 0 && hub_id === "0" ?
                                <div className="map-search-center-marker-box" >
                                    <img
                                        class="map-search-center-marker-icon"
                                        src={require("../../../assets/map/svg/center.svg")}
                                        alt=""
                                    />
                                </div>
                                :
                                null
                            }
                        </>
                    }


                    {this.state.addPlaceModal ?
                        <Modal visible={this.state.addPlaceModal} width="100%" height="40%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                            <AddPlaceModal
                                data={{
                                    lat: this.state.new_place_lat,
                                    lng: this.state.new_place_lng,
                                    closeModal: this.closeModal.bind(this),
                                }}
                                createNewUserProspect={this.props.createNewUserProspect}
                            />
                        </Modal>
                        :
                        null
                    }


                    {this.state.customPlaceNameModal ?
                        <Modal visible={this.state.customPlaceNameModal} width="100%" height="40%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                            <div class="">
                                <div class="row">
                                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                                    <div class="place-marker-modal-box">

                                        <div onClick={() => this.closeModal()} class="map-search-acces-modal-times-box2">
                                            <img
                                                class="map-search-acces-modal-times-img"
                                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                                alt=""
                                            />
                                        </div>

                                        <>
                                            <div class="add-place-modal-place-name-text">
                                                What caption do you want your Buddies to see for this Place?
                                            </div>
                                            <br /> <br /> <br />
                                            <div class="add-place-modal-place-name-input">
                                                <input
                                                    maxlength="55"
                                                    className="form-control"
                                                    type="text"
                                                    name="name_caption"
                                                    onChange={this.onChange}
                                                    value={this.state.name_caption === "-" ? null : this.state.name_caption}
                                                    placeholder="Place's Caption..."
                                                />

                                            </div>
                                            {this.state.name === "-" ?
                                                <div class="add-place-modal-nextt-btn-grey">
                                                    Next
                                                </div>
                                                :
                                                <div
                                                    onClick={() => this.handleCenterMapSelect()}
                                                    class="add-place-modal-nextt-btn-blue">
                                                    Next
                                                </div>
                                            }
                                        </>

                                    </div>
                                </div>
                            </div>


                        </Modal>
                        :
                        null
                    }


                    {this.state.is_rendered && closer_place && this.props.profile.length > 0 ?
                        <PlaceMap
                            data={{
                                hubs: this.props.map_places,
                                new_hub_created: this.props.new_hub_created,
                                new_prospect_id: this.props.new_prospect_id,
                                new_created_hub: this.props.new_created_hub,
                                memories: this.props.map_memories,
                                mapCenter: this.state.mapCenter,
                                myMapCenterLocation: this.state.myMapCenterLocation,
                                zoom: this.state.zoom,
                                handleCenterChanged: this.handleCenterChanged.bind(this),
                                handleSelectPlace: this.handleSelectPlace.bind(this),
                                my_profile: this.props.profile,
                                closer_place: closer_place,
                                add_new_place_icon_modal: this.state.add_new_place_icon_modal,
                                closeNewPlaceIconModal: this.closeNewPlaceIconModal.bind(this),
                                openAddPlaceModal: this.openAddPlaceModal.bind(this),
                                selectedPhotoLocation: this.selectedPhotoLocation.bind(this),
                                new_hubs_fetched: this.props.new_hubs_fetched,
                                new_fetched_hubs: this.props.new_fetched_hubs,
                            }}
                            getMapPlaces={this.props.getMapPlaces}
                            getNewCreatedHub={this.props.getNewCreatedHub}
                            createdHubMarker={this.props.createdHubMarker}
                            createdFetchedHubsMarkers={this.props.createdFetchedHubsMarkers}
                        />
                        :
                        null
                    }

                    {this.state.customPlaceNameModal || this.state.addPlaceModal || this.state.add_new_place_icon_modal || this.state.selected_map_center ?
                        null
                        :
                        <>
                            {this.state.selected_location.length === 0 && hub_id === "0" ?
                                <>
                                    {this.state.selected_hub_name ?
                                        <>
                                            <div
                                                onClick={() => this.handleHubSelect()}
                                                class="find-plcae-select-place-map-btn"
                                            >
                                                Select {this.state.selected_hub_name.length > 20 ?
                                                    <>
                                                        {this.state.selected_hub_name.slice(0, 20) + "..."}
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.selected_hub_name}
                                                    </>
                                                }

                                            </div>
                                        </>
                                        :
                                        <div
                                            onClick={() => this.openNameOfThePlacModal()}
                                            class="find-plcae-select-map-center">
                                            Select the Center of the Map
                                        </div>
                                    }
                                </>
                                :
                                null
                            }
                        </>

                    }

                    {this.state.selected_location.length === 0 && hub_id === "0" ?
                        null
                        :
                        <Modal visible={this.state.hubSelectedModal} width="100%" height="100%" effect="fadeInUp">
                            <>
                                {selected_hub !== "" ?
                                    <div class="find-place-presellected-hub">
                                        <div onClick={() => this.handleBack()} class="back-button-find-place-high-box">
                                            <img
                                                class="find-place-back-button"
                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                        {hub_name}
                                    </div>
                                    :
                                    <div class="find-place-presellected-hub">
                                        <div onClick={() => this.closeHandleHubSelected()} class="back-button-find-place-high-box">
                                            <img
                                                class="find-place-back-button"
                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                        {hub_name}
                                    </div>
                                }


                                <div class="find-place-snapped-photo-layout">
                                    <img class="find-place-snapped-photo-size" src={this.props.location.state.memory_photo} />
                                </div>

                                <div class="find-place-buddies-question">
                                    Was there anyone of your Buddies with you?
                                </div>

                                <input
                                    class='find-place-buddies-search-input'
                                    type="search"
                                    name="user_search"
                                    onChange={this.onBuddiesChange}
                                    placeholder="Search Buddies..."
                                    value={this.state.user_search}
                                    aria-label="Search"
                                />
                                <div class="search-buddy-input-icon-box">
                                    <img class="find-place-buddies-input-icon-search" src={require("../../../assets/gags/svg/Icon.svg")} alt="" />
                                </div>

                                <div class="find-place-select-budies-tags">
                                    <Slider {...tagSettings} >
                                        {this.props.buddies.map(buddy => (
                                            <div class="find-place-select-budies-frame">
                                                {buddy.profile_picture ?
                                                    <div class="find-place-buddies-card">
                                                        <div
                                                            style={{ backgroundImage: 'url(' + buddy.profile_picture + ')' }}
                                                            class='find-place-buddies-card-square'
                                                        >
                                                            {this.state.tags.includes(buddy.id) ?
                                                                <div onClick={this.updateUserTagList(buddy.id)} class="find-place-buddies-tagged">
                                                                    Tagged
                                                                </div>
                                                                :
                                                                <div onClick={this.updateUserTagList(buddy.id)} class="find-place-buddies-tag">
                                                                    Tag
                                                                </div>
                                                            }

                                                            <div class="find-place-buddies-username">
                                                                {/* elenacharalambous */}
                                                                {buddy.username}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div class="find-place-buddies-card">
                                                        <div
                                                            style={{ backgroundImage: 'url(' + require("../../../assets/authentication/svg/account-white.svg") + ')' }}
                                                            class='find-place-buddies-card-square'
                                                        >
                                                            {this.state.tags.includes(buddy.id) ?
                                                                <div onClick={this.updateUserTagList(buddy.id)} class="find-place-buddies-tagged">
                                                                    Tagged
                                                                </div>
                                                                :
                                                                <div onClick={this.updateUserTagList(buddy.id)} class="find-place-buddies-tag">
                                                                    Tag
                                                                </div>
                                                            }
                                                            <div class="find-place-buddies-username">
                                                                {buddy.username}
                                                            </div>
                                                            {/* <div class="swipe-buddies-black-cover-box"> </div> */}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))}

                                    </Slider>
                                    {this.state.selected_location.length === 0 && hub_id === "0" ?
                                        null
                                        :
                                        <div

                                            onClick={() => this.postUploadedMemory()}
                                            class="find-place-post-memory"
                                        >
                                            Post
                                        </div>
                                    }
                                </div>
                            </>
                        </Modal>
                    }

                </div >
            </div>
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hubs: state.hub.hubs,
    buddies: state.buddies.buddies,
    noInternet: state.layout.noInternet,
    in_locations: state.hubst3rActions.in_locations,
    profile: state.profile.profile,
    new_hub_created: state.hub.new_hub_created,
    new_created_hub: state.hub.new_created_hub,
    new_prospect_id: state.hub.new_prospect_id,
    map_places: state.map.map_places,
    new_hubs_fetched: state.map.new_hubs_fetched,
    new_fetched_hubs: state.map.new_fetched_hubs,
});

export default (connect(mapStateToProps, {
    getBuddies,
    searchBuddy,
    getMemoryUploadHubList,
    uploadMemory,
    uploadNoHubMemory,
    createAlert,
    searchHubs,
    createHubProspect,
    createdHubMarker,
    getNewCreatedHub,
    getMyProfile,
    getMapPlaces,
    createNewUserProspect,
    createdFetchedHubsMarkers
})(FindPlace))