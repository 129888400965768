import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class BuddyAccepted extends Component {

    state = {}

    componentDidMount() { }


    render() {
        const { notification } = this.props.data
        return (
            <Fragment>
                <Link to={{ pathname: `/user-profile/${notification.related_user_id}/profile`, state: { user: notification.related_user_id } }}>
                    <div class="row single-item-9">
                        <div class="notification-related-photo-position">
                            {notification.related_picture ?
                                <img class="related-picture-layout" src={notification.related_picture} alt="" />
                                :
                                <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                            }

                        </div>
                        {/* <span class="notification-section-icon-layout">
                        <img class="notification-section-icon" src={require("../../../assets/notifications/svg/Buddies Icon-g.svg")} alt="" />
                    </span> */}
                        <div class="notification-information-section">
                            <div class="notification-text-position">
                                <p class="text-blue-9 notification-text-layout"><strong>{notification.related_name}</strong> accepted your Buddy Request. Check the profile out!!!</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </Fragment >
        )
    }
}


export default (BuddyAccepted);