import { useEffect, useState } from "react";
import chatRecorder from "./chatRecorder";

const useRecorder = () => {
    const [audioURL, setAudioURL] = useState("");
    const [audioFile, setAudioFile] = useState("");
    const [isMicRecording, setisMicRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);


    // 
    const [timer, setTimer] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [milisecs, setMilisecs] = useState(0);
    // 

    useEffect(() => {
        // Lazily obtain recorder first time we're recording.
        if (recorder === null) {
            if (isMicRecording) {
                requestRecorder().then(setRecorder, console.error);
            }
            return;
        }

        // Manage recorder state.
        if (isMicRecording) {
            recorder.start();
            // this.timer = setInterval(this.countDown, 100);
        } else {
            recorder.stop();
            // recorder.stream.stop();
        }

        // Obtain the audio when ready.
        const handleData = e => {
            setAudioURL(URL.createObjectURL(e.data));
            setAudioFile(e.data);
            // console.log(e.data)
            // console.log(e.data)
            // saveFileToSend(e.data)
        };
        // console.log(e.data)
        recorder.addEventListener("dataavailable", handleData);
        return () => recorder.removeEventListener("dataavailable", handleData);
    }, [recorder, isMicRecording]);

    const startRecording = () => {
        setAudioURL("");
        setisMicRecording(true);
        // 
        // startTimer();
        // this.startTimer();
        //

    };

    const startTimer = () => {
        // if (this.timer === 0 && this.state.seconds > 0) {
        setTimer(setInterval(countDown, 1000))
        // }
    }


    const countDown = () => {
        // Remove one second, set state so a re-render happens.
        setMilisecs(milisecs + 1000);
        // milisecondsToTime(miliseconds)
    }


    const milisecondsToTime = (milisecs) => {
        let secs = milisecs / 1000;
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        setHours(hours)
        setMinutes(minutes)
        setSeconds(seconds)
        setMilisecs(milisecs)
        // let obj = {
        //     h: hours,
        //     m: minutes,
        //     s: seconds,
        //     ms: milisecs
        // };
        // return obj;
    }


    const stopRecording = () => {
        setisMicRecording(false);
    };

    return [audioURL, audioFile, isMicRecording, startRecording, stopRecording];
};

async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream);
}
export default useRecorder;
