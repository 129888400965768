import {
    CHECK_SHOP_OWNER_STATUS,
    GET_SHOP_PRODUCTS,
    GET_SHOP_CATEGORIES,
    GET_ALL_ATRIBUTES,
    GET_ALL_ATRIBUTES_CHOISES,
    UPLOAD_PRODUCT_IMAGE,
    GET_ALL_PRODUCT_VARIATIONS,
    CLEAR_SHOP_FIELDS,
    GET_SHOPS_QR_CODE_PRODUCTS,
    GET_SHOPS_QR_CODE_PRODUCT_VARIATIONS,
    REMOVE_PRODUCT_FROM_QR,
    GET_SHOPS_BARCODE_PRODUCTS,
    GET_SHOPS_BARCODE_PRODUCT_VARIATIONS,
    CLEAR_BARCODE_DATA,
    CHECKED_BARCODE_PRODUCTS,
    GET_SHOP_QR_CODES,
    GET_SHOP_QR_CODES_PRODUCTS,
    GET_USER_PRODUCTS,
    GET_MAGAZINE_PRODUCTS,
    GET_SHOPS_MAGAZINE_PRODUCT_VARIATIONS,
    GET_SHOPS_MAGAZINE_PRODUCTS,
    GET_SHOP_MAGAZINE_PRODUCTS,
    GET_USER_MAGAZINE_PRODUCTS,
    GET_MAGAZINE_COVER,
    FETCHING_CATEGORIES,
    GET_SHOP_SHIPPING_CLASSES,
    GET_SHOP_ALL_CHILD_CATEGORIES,
    REMOVE_SHOP_ALL_CHILD_CATEGORIES,
    GET_SHOP_BULK_PRODUCTS,
    NEW_CATEGORY_CREATED,
    SHOP_LOGOUT_SUCCESS
} from '../actions/actionTypes';
import { updateObject } from "../utility";


const initialState = {
    shops: [],
    products: [],
    categories: [],
    attributes: [],
    attributes_choises: [],
    product_images: [],
    product_variations: [],
    qr_products:[],
    qr_products_variations:[],
    barcode_products:[],
    barcode_products_variations:[],
    received_barcode_data: false,
    qr_codes:[],
    all_qr_products:[],
    user_products:[],
    magazine_products:[],
    magazine_products_variations:[],
    shop_magazine_products:[],
    magazine_cover:[],
    loading_categories:false,
    shipping_classes:[],
    all_child_categories:[],
    bulk_products:[],
    new_category_created:[],
}

export default function (state = initialState, action) {
    switch (action.type) {

        case SHOP_LOGOUT_SUCCESS:
            return {
                ...state,
                products: [],
                product_images: [],
                product_variations: [],
                qr_products:[],
                qr_products_variations:[],
                all_child_categories:[],
            };
        case CLEAR_SHOP_FIELDS:
            return {
                ...state,
                products: [],
                product_images: [],
                product_variations: [],
                qr_products:[],
                qr_products_variations:[],
                all_child_categories:[],
            };
 
        case FETCHING_CATEGORIES:
            return {
                ...state,
                loading_categories:true,
            };
             

        case CLEAR_BARCODE_DATA:
            return {
                ...state,
                barcode_products:[],
                barcode_products_variations:[],
                received_barcode_data: false,
            };
        case CHECK_SHOP_OWNER_STATUS:
            localStorage.setItem('shp', JSON.stringify(action.payload));
            return {
                ...state,
                shops: action.payload
            };

        case GET_SHOP_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
                loading_categories:false,
            };

        case GET_SHOP_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };
        case GET_SHOP_SHIPPING_CLASSES:
            return {
                ...state,
                shipping_classes: action.payload
            };
            
        case UPLOAD_PRODUCT_IMAGE:
            return {
                ...state,
                product_images: [...state.product_images, action.payload.photo]
            };
        case GET_SHOP_ALL_CHILD_CATEGORIES:
            return {
                ...state,
                all_child_categories: [...state.all_child_categories.concat(action.payload)]                
            };
        case REMOVE_SHOP_ALL_CHILD_CATEGORIES:
            return {
                ...state,
                all_child_categories: state.all_child_categories.filter(category_id => category_id !== action.payload)
            };
            
        case GET_ALL_ATRIBUTES:
            return {
                ...state,
                attributes: action.payload
            };
        case GET_ALL_ATRIBUTES_CHOISES:
            return {
                ...state,
                attributes_choises: action.payload
            };
        case GET_ALL_PRODUCT_VARIATIONS:
            return {
                ...state,
                product_variations: action.payload
            };
        case GET_SHOPS_QR_CODE_PRODUCTS:
            return {
                ...state,
                qr_products: action.payload
            };
        case GET_SHOPS_QR_CODE_PRODUCT_VARIATIONS:
            return {
                ...state,
                qr_products_variations: [...state.qr_products_variations.concat(action.payload)]
            };
        
        case GET_SHOPS_BARCODE_PRODUCTS:
            return {
                ...state,
                barcode_products: action.payload,
            };
        case GET_SHOPS_BARCODE_PRODUCT_VARIATIONS:
            return {
                ...state,
                barcode_products_variations: action.payload,
            };
        case CHECKED_BARCODE_PRODUCTS:
            return {
                ...state,
                received_barcode_data: true
            };
              
        case GET_SHOP_QR_CODES:
            return {
                ...state,
                qr_codes: action.payload,
            };
        case GET_SHOP_QR_CODES_PRODUCTS:
            return {
                ...state,
                all_qr_products: action.payload,
            };
        
        case GET_USER_PRODUCTS:
            return {
                ...state,
                user_products: action.payload,
            };
        case GET_USER_MAGAZINE_PRODUCTS:
            return {
                ...state,
                magazine_products: action.payload,
            };
        case GET_SHOPS_MAGAZINE_PRODUCT_VARIATIONS:
            return {
                ...state,
                magazine_products_variations: action.payload,
            };
        case GET_SHOP_MAGAZINE_PRODUCTS:
            return {
                ...state,
                shop_magazine_products: action.payload,
            };
        case GET_MAGAZINE_COVER:
            return {
                ...state,
                magazine_cover: action.payload,
            };
            
        case GET_SHOP_BULK_PRODUCTS:
            return {
                ...state,
                bulk_products: action.payload,
            }; 
        case NEW_CATEGORY_CREATED:   
            return {
                ...state,
                new_category_created: action.payload,
            };        
        default:
            return state;
    }
}