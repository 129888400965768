import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';


export class SplashTab extends Component {


    state = {
        pressed_social: "",
    }


    pressSocial = (social) => {
        this.setState({
            pressed_social: social
        })
        setTimeout(() => {
            this.setState({
                pressed_social: ""
            })
        }, 200);
    }

    render() {
        const ambassandor = this.props.data.ambassandor
        const profile = this.props.data.profile
        let arleady_logged_in = false
        if (JSON.parse(localStorage.getItem('ur'))) {
            arleady_logged_in = true
        }


        let telephone = "tel:"
        if (this.props.data.ambassandor) {
            telephone = telephone + this.props.data.ambassandor.mobile_number
        }

        let slides = []
        let instagram_slide = <></>
        let facebook_slide = <></>
        let tiktok_slide = <></>
        let twitter_slide = <></>
        let website_slide = <></>
        if (ambassandor) {
            instagram_slide =
                <>
                    {ambassandor.has_instagram ?
                        <div class="ambassandor-carousal-carouserl-box">
                            <a href={ambassandor.instagram_redirect}>
                                <div class="ambassandor-carousal-expaling-title">
                                    Press to go to {profile.username}'s<br />Instagram profile
                                </div>


                                <div class="ambassandor-carousal-img-box">
                                    <img
                                        class="ambassandor-carousal-img"
                                        src={require("../../../assets/ambassandors/svg/instagram.svg")}
                                        alt=""
                                    />
                                </div>


                                <div class="ambassandor-carousal-sugget-username">
                                    {ambassandor.instagram_username}
                                </div>
                            </a>
                        </div>
                        :
                        null
                    }
                </>


            facebook_slide =
                <>
                    {ambassandor.has_facebook ?
                        <div class="ambassandor-carousal-carouserl-box">
                            <a href={ambassandor.facebook_redirect}>
                                <div class="ambassandor-carousal-expaling-title">
                                    Press to go to {profile.username}'s<br />Facebook profile
                                </div>
                                <div class="ambassandor-carousal-img-box">
                                    <img
                                        class="ambassandor-carousal-img"
                                        src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                        alt=""
                                    />
                                </div>
                                {/* </Link> */}
                                <div class="ambassandor-carousal-sugget-username">
                                    {ambassandor.facebook_username}
                                </div>
                            </a>
                        </div>
                        :
                        null
                    }
                </>

            tiktok_slide =
                <>
                    {ambassandor.has_titok ?
                        <div class="ambassandor-carousal-carouserl-box">
                            <a href={ambassandor.tiktok_redirect}>
                                <div class="ambassandor-carousal-expaling-title">
                                    Press to go to {profile.username}'s<br />TikTok profile
                                </div>
                                <div class="ambassandor-carousal-img-box">
                                    <img
                                        class="ambassandor-carousal-img"
                                        src={require("../../../assets/ambassandors/svg/tiktok.svg")}
                                        alt=""
                                    />
                                </div>
                                {/* </Link> */}
                                <div class="ambassandor-carousal-sugget-username">
                                    {ambassandor.tiktok_username}
                                </div>
                            </a>
                        </div>
                        :
                        null
                    }
                </>

            twitter_slide =
                <>
                    {ambassandor.has_twitter ?
                        <div class="ambassandor-carousal-carouserl-box">
                            <a href={ambassandor.twitter_redirect}>
                                <div class="ambassandor-carousal-expaling-title">
                                    Press to go to {profile.username}'s<br />Twitter profile
                                </div>
                                <div class="ambassandor-carousal-img-box">
                                    <img
                                        class="ambassandor-carousal-img"
                                        src={require("../../../assets/ambassandors/svg/twitter.svg")}
                                        alt=""
                                    />
                                </div>
                                {/* </Link> */}
                                <div class="ambassandor-carousal-sugget-username">
                                    {ambassandor.twitter_username}
                                </div>
                            </a>
                        </div>
                        :
                        null
                    }
                </>

            website_slide =
                <>
                    {ambassandor.has_website ?
                        <div class="ambassandor-carousal-carouserl-box">
                            <a href={ambassandor.website}>
                                <div class="ambassandor-carousal-expaling-title">
                                    Press to go to {profile.username}'s<br />Website
                                </div>
                                <div class="ambassandor-carousal-img-box">
                                    <img
                                        class="ambassandor-carousal-img"
                                        src={require("../../../assets/ambassandors/svg/website.png")}
                                        alt=""
                                    />
                                </div>
                                {/* </Link> */}
                                <div class="ambassandor-carousal-sugget-username">
                                    {ambassandor.website}
                                </div>
                            </a>
                        </div>
                        :
                        null
                    }
                </>

            slides = [
                <>
                    <div class="ambassandor-carousal-carouserl-box">
                        <a href={ambassandor.website}>
                            <div class="ambassandor-carousal-expaling-title">
                                Swipe left to find {profile.username}'s<br />Social Media
                            </div>
                            <div class="ambassandor-carousal-img-box-hubst3r">
                                <img
                                    class="ambassandor-carousal-img"
                                    src={require("../../../assets/ambassandors/svg/logo.png")}
                                    alt=""
                                />
                            </div>
                            {arleady_logged_in ?
                                <>
                                    {profile.is_buddy === null && !this.props.data.send_buddyrequests.includes(profile.user) ?
                                        <>
                                            <div class="ambassandor-carousal-request-explaining">
                                                {ambassandor.name} is on Hubst3r as {profile.username} Request to be Buddies
                                            </div>
                                            <div
                                                onClick={this.props.data.updateBuddyrequestList(profile.user)}
                                                class="ambassandor-carousal-request-btn"
                                            >
                                                Request
                                            </div>
                                        </>
                                        : profile.is_buddy === "buddies" ?
                                            <>
                                                <div class="ambassandor-carousal-request-explaining">
                                                    {ambassandor.name} is on Hubst3r as {profile.username} and you are already Buddies
                                                </div>
                                                <Link to="/hub/request-reservation">
                                                    <div class="ambassandor-carousal-join-btn">
                                                        Go out together
                                                    </div>
                                                </Link>
                                            </>
                                            : this.props.data.send_buddyrequests.includes(profile.user) ?
                                                <div class="ambassandor-carousal-request-explaining">
                                                    {ambassandor.name} is on Hubst3r as {profile.username} Request to be Buddies
                                                </div>
                                                :
                                                <div class="ambassandor-carousal-request-explaining">
                                                    {ambassandor.name} is on Hubst3r as {profile.username}
                                                </div>
                                    }
                                </>
                                :
                                <>

                                    <div class="ambassandor-carousal-welcome-cart">
                                        Welcome to Hubst3r
                                    </div>
                                    <div class="ambassandor-carousal-request-explaining">
                                        {ambassandor.name} is on Hubst3r as {profile.username} Request to be Buddies
                                    </div>
                                    <div class="ambassandor-carousal-join-btn">
                                        Join Hubst3r Now
                                    </div>
                                </>
                            }


                        </a>
                    </div>

                </>
            ]


            if (ambassandor.has_instagram) {
                slides.push(
                    instagram_slide
                )
            }

            if (ambassandor.has_facebook) {
                slides.push(
                    facebook_slide
                )
            }

            if (ambassandor.has_tiktok) {
                slides.push(
                    tiktok_slide
                )
            }

            if (ambassandor.has_twitter) {
                slides.push(
                    twitter_slide
                )
            }

            if (ambassandor.has_website) {
                slides.push(
                    website_slide
                )
            }
        }

        return (
            <Fragment>
                {ambassandor ?
                    <div class="prifile-all-social-media">
                        <div class="prifile-social-media-explainer">
                            Welcome to Hubst3r!<br />Click on the Social Media go there
                        </div>

                        <div class="row">

                            {ambassandor.has_instagram ?

                                <div onClick={() => this.pressSocial("Instagram")} class="prifile-social-media-box">
                                    <a href={ambassandor.instagram_redirect}>
                                        <div class={this.state.pressed_social === "Instagram" ? "prifile-social-media-btn-pressed" : "prifile-social-media-btn"}>
                                            <img
                                                class="prifile-social-media-img"
                                                src={require("../../../assets/ambassandors/svg/instagram.png")}
                                                alt=""
                                            />
                                        </div>

                                        <div class="prifile-social-media-name">
                                            Instagram
                                        </div>
                                    </a>
                                </div>

                                :
                                null
                            }

                            {ambassandor.has_facebook ?
                                <div onClick={() => this.pressSocial("Facebook")} class="prifile-social-media-box">
                                    <a href={ambassandor.facebook_redirect}>
                                        <div class={this.state.pressed_social === "Facebook" ? "prifile-social-media-btn-pressed" : "prifile-social-media-btn"}>
                                            <img
                                                class="prifile-social-media-img"
                                                src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div class="prifile-social-media-name">
                                            Facebook
                                        </div>
                                    </a>
                                </div>
                                :
                                null
                            }

                            {ambassandor.has_tiktok ?
                                <div onClick={() => this.pressSocial("Tiktok")} class="prifile-social-media-box">
                                    <a href={ambassandor.tiktok_redirect}>
                                        <div class={this.state.pressed_social === "Tiktok" ? "prifile-social-media-btn-pressed" : "prifile-social-media-btn"}>
                                            <img
                                                class="prifile-social-media-img"
                                                src={require("../../../assets/ambassandors/svg/tiktok.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div class="prifile-social-media-name">
                                            TikTok
                                        </div>
                                    </a>
                                </div>
                                :
                                null
                            }

                            {ambassandor.has_twitter ?
                                <div onClick={() => this.pressSocial("Twitter")} class="prifile-social-media-box">
                                    <a href={ambassandor.twitter_redirect}>
                                        <div class={this.state.pressed_social === "Twitter" ? "prifile-social-media-btn-pressed" : "prifile-social-media-btn"}>
                                            <img
                                                class="prifile-social-media-img"
                                                src={require("../../../assets/ambassandors/svg/twitter.svg")}
                                                alt=""
                                            />
                                        </div>
                                        <div class="prifile-social-media-name">
                                            Twitter
                                        </div>
                                    </a>
                                </div>
                                :
                                null
                            }

                            {ambassandor.has_website ?
                                <div onClick={() => this.pressSocial("Website")} class="prifile-social-media-box">
                                    <a href={ambassandor.website}>
                                        <div class={this.state.pressed_social === "Website" ? "prifile-social-media-btn-pressed" : "prifile-social-media-btn"}>
                                            <img
                                                class="prifile-social-media-img"
                                                src={require("../../../assets/ambassandors/svg/website.png")}
                                                alt=""
                                            />
                                        </div>
                                        <div class="prifile-social-media-name">
                                            Website
                                        </div>
                                    </a>
                                </div>
                                :
                                null
                            }

                            {ambassandor.has_mobile_number ?
                                <div onClick={() => this.pressSocial("Phone")} class="prifile-social-media-box">
                                    <a href={telephone}>
                                        <div class={this.state.pressed_social === "Phone" ? "prifile-social-media-btn-pressed" : "prifile-social-media-btn"}>
                                            <img
                                                class="prifile-social-media-img"
                                                src={require("../../../assets/ambassandors/svg/phone2.png")}
                                                alt=""
                                            />
                                        </div>
                                        <div class="prifile-social-media-name">
                                            Phone
                                        </div>
                                    </a>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                    :
                    null
                }

            </Fragment >
        )
    }
}

export default (SplashTab);