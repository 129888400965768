import React from 'react';
import "../../../assets/meeting/say-reason-decline-modal.css"



class SayDelineReasonModal extends React.Component {
    state = {
        reason: ""
    };

    closeModal = () => {
        this.props.data.closeModal()
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
        this.props.data.setDeclineReason(e.target.value)

    }

    onReasonSubmit = (e) => {
        // setTimeout(() => {
        this.props.data.declineInvitation()
        this.props.data.closeModal()
        // }, 500);
    }

    render() {
        return (
            <div class="">
                <div class="row">
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                    <div class="reason-modal-box">
                        <div class="row text-right">
                            <div class="col-xl-12 text-right">
                                <div
                                    onClick={() => this.closeModal()}
                                    class="reason-modal-times-frame">
                                    <img class="reason-modal-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="text-left">
                            <h4>
                                <strong class="reason-modal-hub-name">
                                    Say something to your Buddies
                                </strong>
                            </h4>
                        </div>
                        <div class="reason-modal-hub-reason-position">
                            <h4>
                                <strong class="reason-modal-hub-reason">
                                    <textarea
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        placeholder="Write something to your Buddies..."
                                        rows="2"
                                        name="reason"
                                        onChange={this.onChange}
                                        maxlength="150"
                                    ></textarea>
                                </strong>
                            </h4>
                        </div>
                        <div onClick={this.onReasonSubmit} class="reason-modal-not-going">
                            Not Going
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                    </div>
                </div>
            </div>
        )
    }
}


export default (SayDelineReasonModal);