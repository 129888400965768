import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import "../../../assets/ambassandors/edit-social-modal.css"


export class EditSocialModal extends Component {

    state = {
        username: "",
        url: "",
    }

    closeModal = () => {
        this.props.data.closeModal()
        this.setState({
            username: "",
            url: "",
        })
        const ambassandor = this.props.data.ambassandor[0]
        if (this.props.data.social === "Instagram") {
            if (this.state.username !== "") {
                ambassandor.instagram_username = this.state.username
            }
            if (this.state.url !== "") {
                ambassandor.instagram_redirect = this.state.url
            }
        }

        if (this.props.data.social === "Facebook") {
            if (this.state.username !== "") {
                ambassandor.facebook_username = this.state.username
            }
            if (this.state.url !== "") {
                ambassandor.facebook_redirect = this.state.url
            }
        }

        if (this.props.data.social === "TikTok") {
            if (this.state.username !== "") {
                ambassandor.tiktok_username = this.state.username
            }
            if (this.state.url !== "") {
                ambassandor.tiktok_redirect = this.state.url
            }
        }

        if (this.props.data.social === "Twitter") {
            if (this.state.username !== "") {
                ambassandor.twitter_username = this.state.username
            }
            if (this.state.url !== "") {
                ambassandor.twitter_redirect = this.state.url
            }
        }


        if (this.props.data.social === "Phone") {
            if (this.state.username !== "") {
                ambassandor.mobile_number = this.state.username
            }
        }

        if (this.props.data.social === "Website") {
            if (this.state.username !== "") {
                ambassandor.website = this.state.username
            }
        }

        this.props.updateUserAmbassandor(ambassandor)

    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }); }


    render() {
        let ambassandor = []
        let username = ""
        let url = ""
        let example_username = ""
        let example_url = ""
        if (this.props.data.ambassandor[0]) {
            ambassandor = this.props.data.ambassandor[0]
            if (this.props.data.social === "Instagram") {
                username = ambassandor.instagram_username
                url = ambassandor.instagram_redirect
                example_username = "example: kleitos.a"
                example_url = "example: https:www.instagram.com/kleitos.a/"
            }

            if (this.props.data.social === "Facebook") {
                username = ambassandor.facebook_username
                url = ambassandor.facebook_redirect
                example_username = "example: Kleitos Alexandrou"
                example_url = "example: https:www.facebook.com/kleitos.alexandrou/"
            }

            if (this.props.data.social === "TikTok") {
                username = ambassandor.tiktok_username
                url = ambassandor.tiktok_redirect
                example_username = "example: kleitosalexandrou"
                example_url = "example: https://www.tiktok.com/@kleitosalexandrou"

            }

            if (this.props.data.social === "Twitter") {
                username = ambassandor.twitter_username
                url = ambassandor.twitter_redirect
                example_username = "example: kleitos14"
                example_url = "example: https://twitter.com/kleitos14"
            }

            if (this.props.data.social === "Phone") {
                username = ambassandor.mobile_number
                example_username = "example: 0035799123456"
            }

            if (this.props.data.social === "Website") {
                username = ambassandor.website
                example_username = "example: https://www.hubst3r.com/"
            }
        }

        return (
            <Fragment> 
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="edit-social-box">
                            <img
                                onClick={() => this.closeModal()}
                                class="edit-social-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <div class="edit-social-buddy-name">
                                {this.props.data.social}
                            </div>
                            <div class="edit-social-title-hr">
                                <hr />
                            </div>

                            <div class="edit-social-all-choises">

                                {this.props.data.social === "Website" ?
                                    <div class="edit-social-username">
                                        Your Wesite URL
                                    </div>
                                    : this.props.data.social === "Phone" ?
                                        <div class="edit-social-username">
                                            Your Phone Number
                                        </div>
                                        :
                                        <div class="edit-social-username">
                                            Username
                                        </div>
                                }
                                <input
                                    placeholder={example_username}
                                    class="edit-social-username-input"
                                    value={username === "" || username === null || username === "null" || username === 0 || username === "0" ? this.state.username : username}
                                    onChange={this.onChange.bind(this)}
                                    name="username"
                                />

                                {this.props.data.social === "Website" ?
                                    null
                                    : this.props.data.social === "Phone" ?
                                        null
                                        :
                                        <>
                                            <div class="edit-social-url">
                                                URL
                                            </div>

                                            <input
                                                placeholder={example_url}
                                                class="edit-social-url-input"
                                                value={url === "" || url === null || url === "null" ? this.state.url : url}
                                                onChange={this.onChange.bind(this)}
                                                name="url"
                                            />
                                        </>
                                }

                            </div>
                            <div
                                onClick={() => this.closeModal()}
                                class="edit-social-social-finish"
                            >
                                Finished
                            </div>


                        </div>
                    </div>

                </div>
            </Fragment >
        )
    }
}


export default (EditSocialModal);