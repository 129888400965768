import React, { Component, Fragment } from 'react';
import "../../../assets/booked_page/createTableModal.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class CreateTableModal extends Component {
    state = {
        number: "",
        persons_for: "",
    }

    closeModal = () => {
        this.props.data.closeModal()
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    updateSellectedPersons(persons) {
        this.setState({
            persons_for: persons
        })
    }

    onSubmitTable = e => {
        const time = this.state.hour + ':' + this.state.minute
        const date = this.state.date
        const persons = this.state.sellected_person
        let table = this.props.data.sellected_table

        if (table = "") {
            table = "None"
        }
        const number = this.state.number
        const persons_for = this.state.persons_for
        if (number === "") {
            this.props.createAlert({ noTableSelected: "You haven't Sellected a name or number for the table!" });
        } else {
            if (persons_for === "") {
                this.props.createAlert({ noTableSelected: "You haven't Sellected for how many persons is the table!" });
            } else {
                const table = { number, persons_for }
                this.props.createTable(table)
                setTimeout(() => {
                    this.props.data.refreshPage()
                }, 200);
                this.closeModal()

            }
        }
    }

    render() {
        const persons = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
        };
        return (
            <Fragment>
                <div class="create-table-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="create-table-modal-box">
                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="create-table-cancle-btn">
                                        <img class="create-table-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div class="create-table-title-box">
                                <img
                                    class="create-table-title-icon"
                                    src={require("../../../assets/booked_page/svg/table-black-icon.svg")}
                                    alt=""
                                />
                                <h4><strong class="create-table-title-text">Create a  Table</strong></h4>
                            </div>

                            <div class="create-table-all-fields">
                                <center>
                                    <h4><strong class="create-table-title-text">
                                        Insert Table Number
                                         {/* or Name  */}
                                    </strong></h4>
                                    <div className="create-table-search-user-box">
                                        <div className="add-reservation-search-top">
                                            <div className="form-inline w-100">
                                                <input
                                                    maxlength="3"
                                                    className="form-control mr-sm-2 w-100 py-4 add-reservations-search-form-text"
                                                    type="text"
                                                    name="number"
                                                    onChange={this.onChange}
                                                    placeholder="Insert Table Number or Name"
                                                    value={this.state.number}
                                                    id="field-to-show"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </center>
                            </div>
                            <div class="create-table-all-fields">
                                <center>
                                    <h4><strong class="create-table-title-text">Sellect for how many persons the Table is for </strong></h4>
                                    <Slider {...settings} >
                                        {persons.map(persons => (
                                            <div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
                                                    <div
                                                        onClick={() => this.updateSellectedPersons(persons)}
                                                        class="create-table-modal-frame"
                                                    >
                                                        {persons === 2 ?
                                                            <img
                                                                class={this.state.persons_for === persons ?
                                                                    "create-table-sellected-logo"
                                                                    :
                                                                    "create-table-modal-logo"
                                                                }
                                                                src={require("../../../assets/booked_page/svg/sellect-persons-2.svg")}
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                class={this.state.persons_for === persons ?
                                                                    "create-table-sellected-logo"
                                                                    :
                                                                    "create-table-modal-logo"
                                                                }
                                                                src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                        <div class="create-table-table-number">{persons}<br />Persons</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>

                                </center>
                            </div>

                            <div
                                onClick={this.onSubmitTable}
                                class="create-table-btn-danger"
                            >
                                Create
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (CreateTableModal);