import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import GdprLinks from '../../../components/user/layout/gdprLinks';
import CookiesModal from '../../../components/user/layout/cookiesModal';
import "../../../assets/ambassandors/landingPage.css";
import { createFirstTimeVisit } from '../../../store/actions/hubst3rActions';




export class LandingPage extends Component {

    state = {
        isLoading: true,
        sellecte_language: "Greek"
    };

    componentDidMount() {
        // console.log(this.props.match.params.nfcID)
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 2800);



        if (!JSON.parse(localStorage.getItem("frsttmvst"))) {
            localStorage.setItem('frsttmvst', JSON.stringify("True"));
            const browser = window.loadVersionBrowser().name;
            const full_browser = window.clientInformation.userAgent;
            const device_width = window.innerWidth
            const device_height = window.innerHeight
            fetch(`https://geolocation-db.com/json/`)
                .then(response => {
                    return response.json();
                }, "jsonp")
                .then(res => {
                    const ip = res.IPv4
                    const country = res.country_name
                    const city = res.city
                    const latitude = res.latitude
                    const longitude = res.longitude
                    const state = res.state
                    const from_nfc = true
                    const nfc_number = this.props.match.params.nfcID
                    const data = { from_nfc, nfc_number, browser, full_browser, device_width, device_height, ip, country, city, latitude, longitude, state }
                    this.props.createFirstTimeVisit(data)
                }).catch(err => {
                    console.log("Hiiiiiii")
                });
        }


    }

    setLanguage(language) {
        this.setState({
            sellecte_language: language
        });
    }


    render() {
        return (
            <Fragment>
                {/* <div class={this.state.isLoading ? "landing-page-lottie-appear" : "landing-page-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <CookiesModal />
                <div class="landing-all-page-display">
                    <div>
                        {/* <div class={this.state.isLoading ? "landing-page-section-disappear" : null}> */}
                        <div class={this.state.is_greek ? "landing-page-whole-page-layout-el" : "landing-page-whole-page-layout"}>
                            <OfflineLabel />
                            <div class="landing-page-header-section">
                                <div class="row">

                                    <div class="landing-page-top-logo">
                                        <Link to="/">
                                            <img
                                                class="landing-page-logo-img"
                                                src={require("../../../assets/ambassandors/svg/logo.png")}
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <div class="landing-page-top-logo-name">
                                        <Link to="/">
                                            <img
                                                class="landing-page-logo-name-img"
                                                src={require("../../../assets/ambassandors/svg/logo-name.png")}
                                                alt=""
                                            />
                                        </Link>
                                    </div>

                                    <Link to="/">
                                        {this.state.sellecte_language === "Greek" ?
                                            <div class="landing-page-sigup-btn">
                                                Δωρεάν<br />Εγγραφή
                                            </div>
                                            : this.state.sellecte_language === "English" ?
                                                <div class="landing-page-sigup-btn">
                                                    Register<br />for Free
                                                </div>
                                                : this.state.sellecte_language === "Russian" ?
                                                    <div class="landing-page-sigup-btn">
                                                        Бесплатная<br />регистрация
                                                    </div>
                                                    :
                                                    null
                                        }
                                    </Link>
                                </div>
                            </div>

                            <div class="">
                                <div class="row landing-page-flag-section">
                                    <div class="landing-page-flag"> </div>
                                    <div class="landing-page-flag"> </div>
                                    <div class="landing-page-flag"> </div>
                                    <div class="landing-page-flag"> </div>
                                    <div class="landing-page-flag">
                                        <img
                                            onClick={() => this.setLanguage("Greek")}
                                            class={this.state.sellecte_language === "Greek" ? "landing-page-flag-img-slc" : "landing-page-flag-img"}
                                            src={require("../../../assets/ambassandors/svg/el.png")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="landing-page-flag"> </div>

                                    <div class="landing-page-flag">
                                        <img
                                            onClick={() => this.setLanguage("English")}
                                            class={this.state.sellecte_language === "English" ? "landing-page-flag-img-slc" : "landing-page-flag-img"}
                                            src={require("../../../assets/ambassandors/svg/uk.jpg")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="landing-page-flag"> </div>

                                    <div class="landing-page-flag-rs">
                                        <img
                                            onClick={() => this.setLanguage("Russian")}
                                            class={this.state.sellecte_language === "Russian" ? "landing-page-flag-img-slc" : "landing-page-flag-img"}
                                            src={require("../../../assets/ambassandors/svg/russia.png")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="landing-page-flag"> </div>
                                    <div class="landing-page-flag"> </div>
                                    <div class="landing-page-flag">  </div>
                                </div>
                            </div>
                            <div class="landing-page-download-google">
                                <a href="https://play.google.com/store/apps/details?id=com.app.hubst3r">
                                    <img
                                        class="landing-page-logo-img"
                                        src={require("../../../assets/ambassandors/svg/google-play.png")}
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div class="landing-page-download-huawei">
                                <a href="https://appgallery.huawei.com/#/app/C104177697">
                                    <img
                                        class="landing-page-logo-img"
                                        src={require("../../../assets/ambassandors/svg/app-gallery.jpg")}
                                        alt=""
                                    />
                                </a>
                            </div>
                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-title-visite">
                                    ή επισκεφθείτε το
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-title-visite">
                                        or visit
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-title-visite">
                                            или посетите
                                        </div>
                                        :
                                        null
                            }
                            <Link to="/">
                                <div class="landing-page-site">
                                    <u>www.hubst3r.com</u>
                                </div>
                            </Link>


                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-title">
                                    Ο προσωπικός σου σύμβουλος σχετικά με οτιδήποτε χρειάζεται να γνωρίζεις σχετικά με την νυχτερινή και οχι μόνο διασκέδαση
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-title">
                                        Your personal insider for everything you need to know about Nightlife
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-title">
                                            Ваш личный инсайдер для всего, что вам нужно знать о ночной жизни
                                        </div>
                                        :
                                        null
                            }
                            {/* {this.state.is_greek ?
                                <div class="landing-page-subtitle">
                                    Συνδεθείτε με κόσμο, προσκελέστε τους να βγείτε, μάθετε οτιδίποτε χρειάζεται να γνωρίζετε σχετικά με το μέρος που επιλέξατε να πάτε, μοιραστείτε τις αναμνήσεις σας με τον υπόλοιπο κόσμο!
                                </div>
                                :
                                <div class="landing-page-subtitle">
                                    Connect with people, invite them to go out, get to see everything about the place you will go, share your memories with the rest of the world!
                                </div>
                            } */}

                            <br /><br /><br /><br />




                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-down-title">
                                    Τι μου προσφέρει το Hubst3r
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-down-title">
                                        How Hubst3r works
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-down-title">
                                            Как работает Hubst3r
                                        </div>
                                        :
                                        null
                            }

                            <div class="landing-page-eplaining-number">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/number1.png")}
                                    alt=""
                                />
                            </div>
                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-eplaining-title">
                                    Συνδεθείτε με κόσμο
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-eplaining-title">
                                        Connect with people
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-eplaining-title">
                                            Общайтесь с людьми
                                        </div>
                                        :
                                        null
                            }

                            <div class="landing-page-eplaining-image-lg">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/connect.jpg")}
                                    alt=""
                                />
                            </div>


                            <div class="landing-page-eplaining-number">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/number2.png")}
                                    alt=""
                                />
                            </div>
                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-eplaining-title">
                                    Δείτε πως διασκεδάζει η υπόλοιπη πόλη
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-eplaining-title">
                                        Check out how the rest of the city is having fun
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-eplaining-title">
                                            Посмотрите, как развлекается остальная часть города
                                        </div>
                                        :
                                        null
                            }
                            <div class="landing-page-eplaining-image-lg">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/city.jpg")}
                                    alt=""
                                />
                            </div>


                            <div class="landing-page-eplaining-number">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/number3.png")}
                                    alt=""
                                />
                            </div>
                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-eplaining-title">
                                    Προσκαλέστε κάποιον για να βγείτε
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-eplaining-title">
                                        Invite someone to go out
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-eplaining-title">
                                            Пригласите кого-нибудь выйти
                                        </div>
                                        :
                                        null
                            }


                            <div class="landing-page-eplaining-image-lg">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/invite.jpg")}
                                    alt=""
                                />
                            </div>

                            <div class="landing-page-eplaining-number">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/number4.png")}
                                    alt=""
                                />
                            </div>
                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-eplaining-title">
                                    Μοιραστείτε τις αναμνήσεις σας με τον υπόλοιπο κόσμο
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-eplaining-title">
                                        Share your Memories with others
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-eplaining-title">
                                            Поделитесь своими воспоминаниями с другими
                                        </div>
                                        :
                                        null
                            }

                            <div class="landing-page-eplaining-image-lg">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/selfi.jpg")}
                                    alt=""
                                />
                            </div>


                            <div class="landing-page-eplaining-number">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/number5.png")}
                                    alt=""
                                />
                            </div>
                            {this.state.sellecte_language === "Greek" ?
                                <div class="landing-page-eplaining-title">
                                    Κλέψτε τις εντυπώσεις
                                </div>
                                : this.state.sellecte_language === "English" ?
                                    <div class="landing-page-eplaining-title">
                                        Impress the world
                                    </div>
                                    : this.state.sellecte_language === "Russian" ?
                                        <div class="landing-page-eplaining-title">
                                            Произвести впечатление на мир
                                        </div>
                                        :
                                        null
                            }
                            <div class="landing-page-eplaining-image-lg">
                                <img
                                    class="landing-page-logo-img"
                                    src={require("../../../assets/ambassandors/svg/love.jpg")}
                                    alt=""
                                />
                            </div>

                            <br /><br /><br /><br />


                            <div class="col-xl-12 text-center mt-2">
                                <Link to="/terms-of-use">
                                    <a class="link2" href="http://www.hubst3r.com/terms-of-use">Terms</a>
                                </Link>
                                <Link to="/privacy-policy">
                                    <a class="link2" href="http://www.hubst3r.com/privacy-policy">Privacy</a>
                                </Link>
                                <Link to="/cookies-policy">
                                    <a class="link2" href="http://www.hubst3r.com/cookies-policy">Cookies</a>
                                </Link>
                                <br />
                                {/* <Link> */}
                                <a href="http://about.hubst3r.com/" class="link2" >About</a>
                                {/* </Link> */}
                                <Link to="/licenses">
                                    <a class="link2" href="http://www.hubst3r.com/licenses/">Licenses</a>
                                </Link>
                            </div>
                            <br /><br /><br /><br />


                        </div>
                    </div>
                </div>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, { createFirstTimeVisit })(LandingPage);