import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { getBuddies, getSearchUsers, answerBuddyRequest, cancelBuddyRequest, sendBuddyRequest, searchUsers } from '../../../store/actions/buddies'
import { getMyProfile } from '../../../store/actions/profile';
import { getNotificationsStatus } from '../../../store/actions/notification'
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import '../../../assets/buddies/searchBuddies.css'


export class ShowProfileBuddies extends Component {

    state = {
        user_search: "",
        send_buddyrequests: [],
        answered_request: [],
        cancelled_requests: [],
    };

    componentDidMount() {
        this.props.getBuddies(this.props.match.params.profileID);
        // this.props.getSearchUsers();
        this.props.getMyProfile();
    }

    onChange = e => {
        // if (e.target.value === "") {
        //     this.setState({ [e.target.name]: e.target.value })
        //     this.props.getSearchUsers(JSON.parse(localStorage.getItem('ur')).id);
        // } else {
        //     const query = e.target.value
        //     if (query.toLowerCase() !== "ad") {
        //         this.props.searchUsers(query)
        //     }
        //     this.setState({ [e.target.name]: e.target.value })
        // }
    }

    buddyRequestSubmit = (user) => (e) => {
        e.preventDefault();
        const sending_user = this.props.profile[0].user
        const recieving_user = user
        const status = "Send"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.sendBuddyRequest(buddyrequest);
    }

    cancelBuddyRequest = (user) => (e) => {
        e.preventDefault();
        const sending_user = this.props.profile[0].user
        const recieving_user = user
        const status = "Cancelled"
        const buddyrequest = { sending_user, recieving_user, status };
        this.props.cancelBuddyRequest(buddyrequest);
    }

    updateBuddyrequestList = (user) => (e) => {
        e.preventDefault();
        const old_list = [...this.state.send_buddyrequests]
        if ((old_list.indexOf(user)) !== -1) {
            const new_remove = old_list.filter(f => f !== user)
            this.setState({ send_buddyrequests: new_remove })
        }
        else {
            old_list.push(user)
            this.setState({ send_buddyrequests: old_list })
        }
    }

    updateCancelledRequestsList = (user) => (e) => {
        e.preventDefault();
        const old_list = [...this.state.cancelled_requests]
        if ((old_list.indexOf(user)) !== -1) {
            const new_remove = old_list.filter(f => f !== user)
            this.setState({ cancelled_requests: new_remove })
        }
        else {
            old_list.push(user)
            this.setState({ cancelled_requests: old_list })
        }
    }

    updateAnsweredRequest(user) {
        // e.preventDefault();
        const old_list = [...this.state.answered_request]
        old_list.push(user)
        this.setState({ answered_request: old_list })
    }

    acceptBuddyRequest = (user) => (e) => {
        this.updateAnsweredRequest(user)
        const sending_user = user
        const recieving_user = this.props.profile[0].user
        const status = "Accepted"
        const answer = { sending_user, recieving_user, status };
        this.props.answerBuddyRequest(answer);
    }

    declineBuddyRequest = (user) => (e) => {
        this.updateAnsweredRequest(user)
        const sending_user = user
        const recieving_user = this.props.profile[0].user
        const status = "Declined"
        const answer = { sending_user, recieving_user, status };
        this.props.answerBuddyRequest(answer);
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {
        let profile = []
        if (profile) {
            profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        }
        let my_username = ""
        if (this.props.profile.length !== 0) {
            my_username = this.props.profile[0].username
        }

        const profile_username = this.props.location.state.profile_username
        let username = profile_username
        if (my_username === profile_username) {
            username = "My"
        }

        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "search-users-lottie-appear" : "search-users-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}

                {/* <div class={this.props.isLoading ? "search-users-section-disappear" : null}> */}
                <div>
                    <div class="search-buddies-whole-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row color-10">
                                <DesktopNavbar data={{ profile: this.props.profile, sellected: "", notifications_status: this.props.notifications_status }} />
                                {/* <!-- small screen header start --> */}

                                <div id="page-scroll-up" class="buddies-page-mobile-navbar">
                                    <div onClick={() => this.handleBack()} class="back-button-buddies-page-box">
                                        <img
                                            class="buddies-page-back-button"

                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                            alt=""
                                        />
                                    </div>
                                    {username === "My" ?
                                        <strong>{username} Buddies</strong>
                                        :
                                        <strong>{username}'s Buddies</strong>
                                    }

                                </div>

                                {/* <div class="container-fluid small-header-container-10">
                                    <div class="small-header-10">
                                        <div class="row">
                                            <div class="">
                                                <img
                                                    class="show-profile-buddies-back-button"
                                                    onClick={this.handleBack}
                                                    src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="col-lg-12 text-center text-white-10 sm-header-text">
                                                <div class="title-font-layout">
                                                    {username === "My" ?
                                                        <strong>{username} Buddies</strong>
                                                        :
                                                        <strong>{username}'s Buddies</strong>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <!-- small screen header end --> */}

                                <div class="col-xl-10 col-lg-12 body-right-10 pb-5">
                                    <div class="row">
                                        <div class="col-xl-12 text-center hide-h3">
                                            <h3 class="text-blue-search-users my-3">
                                                <strong>
                                                    {username === "My" ?
                                                        <strong>{username} Buddies</strong>
                                                        :
                                                        <strong>{username}'s Buddies</strong>
                                                    }
                                                </strong>
                                            </h3>
                                        </div>
                                    </div>
                                    {/* <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                        <div className="search-buddies-search-box">
                                            <div className="search-top">
                                                <div className="form-inline w-100">
                                                    <img class="search-buddy-input-icon-search" src={require("../../../assets/gags/svg/Icon.svg")} alt="" />
                                                    <input
                                                        className="form-control mr-sm-2 w-100 py-4 search-form-text"
                                                        type="text"
                                                        name="user_search"
                                                        onChange={this.onChange}
                                                        placeholder="Search"
                                                        value={this.state.user_search}
                                                        aria-label="Search"
                                                        id="field-to-show"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </ScrollLink> */}
                                    <div class="search-buddies-row-position">
                                        <div>
                                            {this.props.buddies.map(user => (
                                                <div class="single-item-buddy-field">
                                                    <div class="item1-10">
                                                        <div class="row">
                                                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                                                                <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                                                                    {user.profile_picture ?
                                                                        <img class="search-user-picture-layout" src={user.profile_picture} alt="" />
                                                                        :
                                                                        <img class="search-user-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                                    }
                                                                </Link>
                                                            </div>

                                                            <Link to={{ pathname: `/user-profile/${user.user}/profile`, state: { user: user.user } }}>
                                                                <div class="profil-buddies-all-info">
                                                                    <div class="search-buddies-info-username">
                                                                        {user.username}
                                                                    </div>
                                                                    <div class="search-buddies-info-fullname">
                                                                        {user.full_name}
                                                                    </div>

                                                                </div>
                                                            </Link>

                                                            {user.user === profile.user ?
                                                                null
                                                                : user.is_buddy === "buddies" ?
                                                                    null
                                                                    : this.state.answered_request.includes(user.user) ?
                                                                        <div class="">
                                                                            <div class="search-buddy-requested-btn">
                                                                                Answered
                                                                            </div>
                                                                        </div>
                                                                        : user.is_buddy === "Unanswered" ?
                                                                            <>
                                                                                <div
                                                                                    onTouchStart
                                                                                    onClick={this.acceptBuddyRequest(user.user)}
                                                                                    class="search-buddies-btn-approve"
                                                                                >
                                                                                    Accept
                                                                                </div>
                                                                                <div
                                                                                    onTouchStart
                                                                                    onClick={this.declineBuddyRequest(user.user)}
                                                                                    class="search-buddies-btn-decline"
                                                                                >
                                                                                    Decline
                                                                                </div>
                                                                            </>
                                                                            : this.state.cancelled_requests.includes(user.user) ?
                                                                                <div
                                                                                    onTouchStart
                                                                                    onClick={this.updateBuddyrequestList(user.user)}
                                                                                    class="search-buddy-request-btn">
                                                                                    <span onClick={this.updateCancelledRequestsList(user.user)}>
                                                                                        <span onClick={this.buddyRequestSubmit(user.user)} >
                                                                                            Request
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                                : user.is_buddy === "Requested" ?
                                                                                    <div
                                                                                        onTouchStart
                                                                                        onClick={this.updateCancelledRequestsList(user.user)}
                                                                                        class="">
                                                                                        <div
                                                                                            onClick={this.cancelBuddyRequest(user.user)}
                                                                                            class="search-buddy-requested-btn"
                                                                                        >
                                                                                            Requested
                                                                                        </div>
                                                                                    </div>
                                                                                    : this.state.send_buddyrequests.includes(user.user) ?
                                                                                        <div
                                                                                            onTouchStart
                                                                                            onClick={this.updateBuddyrequestList(user.user)}
                                                                                            class="">
                                                                                            <div
                                                                                                onClick={this.cancelBuddyRequest(user.user)}
                                                                                                class="search-buddy-requested-btn"
                                                                                            >
                                                                                                Requested
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div
                                                                                            onTouchStart
                                                                                            onClick={this.updateBuddyrequestList(user.user)}
                                                                                            class="">
                                                                                            <div
                                                                                                onClick={this.buddyRequestSubmit(user.user)}
                                                                                                class="search-buddy-request-btn"
                                                                                            >
                                                                                                Request
                                                                                            </div>
                                                                                        </div>
                                                            }

                                                            {/* 
                                                            <div class="search-all-buttons-layout">
                                                                {user.user === profile.user ?
                                                                    null
                                                                    : user.is_buddy === "buddies" ?
                                                                        null
                                                                        : this.state.answered_request.includes(user.user) ?
                                                                            <div class="">
                                                                                <div class="btn-answered-search-buddies">
                                                                                    Answered
                                                                                </div>
                                                                            </div>
                                                                            : user.is_buddy === "Unanswered" ?
                                                                                <div
                                                                                    class="accept-decline-buttons-position">
                                                                                    <div
                                                                                        onClick={this.acceptBuddyRequest(user.user)}
                                                                                        class="user-search-btn-approve"
                                                                                    >
                                                                                        Accept
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={this.declineBuddyRequest(user.user)}
                                                                                        class="user-search-btn-decline"
                                                                                    >
                                                                                        Decline
                                                                                    </div>
                                                                                </div>
                                                                                : this.state.cancelled_requests.includes(user.user) ?
                                                                                    <div
                                                                                        onClick={this.updateBuddyrequestList(user.user)}
                                                                                        class="">
                                                                                        <span onClick={this.updateCancelledRequestsList(user.user)}>
                                                                                            <div
                                                                                                onClick={this.buddyRequestSubmit(user.user)}
                                                                                                class="btn btn-info-search-buddies"
                                                                                            >
                                                                                                Add
                                                                                            </div>
                                                                                        </span>

                                                                                    </div>
                                                                                    : user.is_buddy === "Requested" ?
                                                                                        <div
                                                                                            onClick={this.updateCancelledRequestsList(user.user)}
                                                                                            class="">
                                                                                            <div
                                                                                                onClick={this.cancelBuddyRequest(user.user)}
                                                                                                class="btn btn-cancel-search-buddies"
                                                                                            >
                                                                                                Cancel
                                                                                            </div>
                                                                                        </div>
                                                                                        : this.state.send_buddyrequests.includes(user.user) ?
                                                                                            <div
                                                                                                onClick={this.updateBuddyrequestList(user.user)}
                                                                                                class="">
                                                                                                <div
                                                                                                    onClick={this.cancelBuddyRequest(user.user)}
                                                                                                    class="btn btn-cancel-search-buddies"
                                                                                                >
                                                                                                    Cancel
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div
                                                                                                onClick={this.updateBuddyrequestList(user.user)}
                                                                                                class="">
                                                                                                <div
                                                                                                    onClick={this.buddyRequestSubmit(user.user)}
                                                                                                    class="btn btn-info-search-buddies"
                                                                                                >
                                                                                                    Add
                                                                                                </div>
                                                                                            </div>
                                                                }
                                                            </div>
                                                         */}

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <br /><br /><br /><br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />
                </ScrollLink>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profile.profile,
    auth: state.auth,
    buddies: state.buddies.buddies,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { getSearchUsers, answerBuddyRequest, cancelBuddyRequest, sendBuddyRequest, searchUsers, getNotificationsStatus, getMyProfile, getBuddies })(ShowProfileBuddies);