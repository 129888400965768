import React, { Fragment, lazy, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    setSales,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';


export class SetupPage extends Component {

    state = {
        has_sales: false,
        sales_text: "",
        haveSales: false,
        redirect: false,
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    answerHaveSales() {
        this.setState({
            haveSales: true,
        })
    }


    answerSales() {
        const id = this.props.hub[0].id
        const has_sales = false
        const sales_text = this.state.sales_text
        const hub = { id, has_sales, sales_text }
        this.props.setSales(hub)
        this.setState({
            redirect: true,
        })
    }

    finishedSales() {
        const id = this.props.hub[0].id
        const has_sales = true
        const sales_text = this.state.sales_text
        const hub = { id, has_sales, sales_text }
        this.props.setSales(hub)
        this.setState({
            redirect: true,
        })
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/hub-homepage' }} />
        }

        let sales_text = ""
        const hub = this.props.hub

        if (hub.length > 0) {
            sales_text = hub[0].sales_text
        }

        if (this.state.sales_text !== "") {
            sales_text = this.state.sales_text
        }

        return (
            <Fragment>

                <>
                    {this.props.hub.map(hub => (
                        <>
                            <HubDesktopNavbar data={{ sellected: "" }} />
                            <div>
                                <div class="whole-setup-page-page-layout">
                                    <OfflineLabel />

                                    <>

                                        <div class="setup-page-welcome-title">
                                            <Link to="/hub-homepage" class="setup-page-back-box">
                                                <div class="setup-page-back-icon-box">
                                                    <img
                                                        class="setup-page-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </Link>
                                            Setup Online Shop
                                        </div>

                                        <>
                                            {this.state.haveSales ?
                                                <>
                                                    <div class="setup-page-info-text">
                                                        Type your Offer?
                                                    </div>
                                                    <div class="setup-page-info-input-box">
                                                        <input
                                                            class='setup-page-name-input-field'
                                                            name="sales_text"
                                                            onChange={this.onChange}
                                                            placeholder="Offer..."
                                                            value={sales_text}
                                                        />
                                                    </div>
                                                    <div onClick={() => this.finishedSales()} class="setup-page-info-done">
                                                        Done
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div class="setup-page-reservation-question-text">
                                                        Do you have Sales Offers?
                                                    </div>

                                                    <div class="setup-page-reservation-question-box">
                                                        <div onClick={() => this.answerSales(false)} class="setup-page-reservation-question-no">
                                                            No
                                                        </div>
                                                        <div onClick={() => this.answerHaveSales(true)} class="setup-page-reservation-question-yes">
                                                            Yes
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    </>
                                </div>
                            </div>
                            <div onClick={() => this.finishedSales()} class="setup-page-next-btn">
                                Done
                            </div>

                        </>
                    ))}
                </>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    setSales,
})(SetupPage);