import axios from "axios";
import { returnErrors } from "./alerts";
import { tokenConfig } from './auth';
import {
    SUBMIT_HUB_CONTACT_FORM,

    // NOT USED CURRENTLY
    HUB_USER_LOADED,
    HUB_USER_LOADING,
    HUB_AUTH_ERROR,
    HUB_LOGIN_SUCCESS,
    HUB_LOGIN_FAIL,
    HUB_LOGOUT_SUCCESS,
    HUB_REGISTER_SUCCESS,
    HUB_REGISTER_FAIL
} from "./actionTypes";


export const submitHubContactForm = (contactForm) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/new-hub/contact-us/`, contactForm, tokenConfig())
        .then(res => {
            dispatch({
                type: SUBMIT_HUB_CONTACT_FORM,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};



//****** NOT USED CURRENTLY


// load HUB user

export const loadHubUser = () => (dispatch, getState) => {

    // dispatch({ type: HUB_USER_LOADING });

    // axios
    //     .get("${process.env.REACT_APP_HOST_IP_ADDRESS}/api/auth/hub-user", tokenConfig(getState))
    //     .then(res => {
    //         dispatch({
    //             type: HUB_USER_LOADED,
    //             payload: res.data
    //         });
    //     }).catch(err => {
    //         dispatch(returnErrors(err.response.data, err.response.status));
    //         dispatch({
    //             type: HUB_AUTH_ERROR
    //         });
    //     });
};

//login HUB MEMBER

export const hubLogin = (username, password) => dispatch => {

    // const config = {
    //     headers: {
    //         "Content-Type": "application/json"
    //     }
    // };

    // const body = JSON.stringify({ username, password });

    // axios
    //     .post("${process.env.REACT_APP_HOST_IP_ADDRESS}/api/auth/hub-login", body, config)
    //     .then(res => {
    //         dispatch({
    //             type: HUB_LOGIN_SUCCESS,
    //             payload: res.data
    //         });
    //     }).catch(err => {
    //         dispatch(returnErrors(err.response.data, err.response.status));
    //         dispatch({
    //             type: HUB_LOGIN_FAIL
    //         });
    //     });
};

//REGISTER staff

export const registerStaff = ({ username, email, password, is_hub_staff, hub_id }) => dispatch => {

    // const config = {
    //     headers: {
    //         "Content-Type": "application/json"
    //     }
    // };


    // const body = JSON.stringify({ username, email, password, is_hub_staff, hub_id });

    // axios
    //     .post("${process.env.REACT_APP_HOST_IP_ADDRESS}/api/auth/staff-register", body, config)
    //     .then(res => {
    //         dispatch({
    //             type: HUB_REGISTER_SUCCESS,
    //             payload: res.data
    //         });
    //     }).catch(err => {
    //         dispatch(returnErrors(err.response.data, err.response.status));
    //         dispatch({
    //             type: HUB_REGISTER_FAIL
    //         });
    //     });
};

//REGISTER manager

export const registerManager = ({ username, email, password, hub }) => dispatch => {
    // const config = {
    //     headers: {
    //         "Content-Type": "application/json"
    //     }
    // };


    // const body = JSON.stringify({ username, email, password, hub });

    // axios
    //     .post("${process.env.REACT_APP_HOST_IP_ADDRESS}/api/auth/manager-register", body, config)
    //     .then(res => {
    //         dispatch({
    //             type: HUB_REGISTER_SUCCESS,
    //             payload: res.data
    //         });
    //     }).catch(err => {
    //         dispatch(returnErrors(err.response.data, err.response.status));
    //         dispatch({
    //             type: HUB_REGISTER_FAIL
    //         });
    //     });
};


//REGISTER staff

export const registerOwner = ({ username, email, password, hub }) => dispatch => {
    // const config = {
    //     headers: {
    //         "Content-Type": "application/json"
    //     }
    // };


    // const body = JSON.stringify({ username, email, password, hub });

    // axios
    //     .post("${process.env.REACT_APP_HOST_IP_ADDRESS}/api/auth/owner-register", body, config)
    //     .then(res => {
    //         dispatch({
    //             type: HUB_REGISTER_SUCCESS,
    //             payload: res.data
    //         });
    //     }).catch(err => {
    //         dispatch(returnErrors(err.response.data, err.response.status));
    //         dispatch({
    //             type: HUB_REGISTER_FAIL
    //         });
    //     });
};


//logout hub user

export const logout = () => (dispatch, getState) => {

    // axios
    //     .post("${process.env.REACT_APP_HOST_IP_ADDRESS}/api/auth/hub-logout/", null, tokenConfig(getState))
    //     .then(res => {
    //         dispatch({
    //             type: HUB_LOGOUT_SUCCESS
    //         });
    //     }).catch(err => {
    //         dispatch(returnErrors(err.response.data, err.response.status));
    //     });
};