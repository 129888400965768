import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import "../../../assets/profile/hubs-access-modal.css"
import { Link, Redirect } from 'react-router-dom';
import Slider from 'react-slick';


export class HubAccessModal extends Component {

    state = {
    }

    closeModal = () => {
        this.props.data.closeModal()
    }

    switchToHubAccount(hub_id) {
        this.props.switchToHubAccount(hub_id)

    }

    render() {
        if (this.props.data.hub_redirect) {
            return <Redirect to="/hub-homepage" />
        }
        // const buddy = this.props.data.buddy

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 3,
            vertical: true,
            verticalSwiping: true,
            swipeToSlide: true,
        };

        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="hubs-access-box">
                            <img
                                onClick={() => this.closeModal()}
                                class="hubs-access-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <div class="hubs-access-buddy-name">
                                {/* {buddy.username} */}
                                Switch to:
                            </div>
                            <div class="hubs-access-title-hr">
                                <hr />
                            </div>

                            <div class="hubs-access-all-choises">
                                <hr />
                                <Slider {...settings}>
                                    {this.props.data.hubs_access.map(hub => (
                                        <>
                                            <div
                                                onClick={() => this.switchToHubAccount(hub.id)}
                                                class="hubs-access-choise"
                                            >
                                                {/* <Link to="/hub-homepage"> */}
                                                <div class="row">
                                                    <div class="hubs-access-logo-box">
                                                        <img
                                                            class="hubs-access-logo-img"
                                                            src={hub.logo}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div class="hubs-access-choise-text">
                                                        {hub.name}
                                                    </div>
                                                </div>
                                                {/* </Link> */}
                                            </div>
                                            <hr />
                                        </>
                                    ))}
                                </Slider>
                            </div>



                        </div>
                    </div>

                </div>
            </Fragment >
        )
    }
}


export default (HubAccessModal);