import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getGagMembers, deleteGag } from '../../../store/actions/buddies'
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/gags/gag-modal.css";


export class GagModal extends Component {
    state = {
        membersModal: false,
    };


    componentDidMount() {
        this.props.getGagMembers(this.props.data.gag_id);
    }

    deleteSellectedGag = () => (e) => {
        // this.props.deleteGag(this.props.data.gag_id)
        this.closeModal()
    }

    closeModal = () => {
        this.props.data.closeModal()
    }

    render() {
        const members = this.props.members
        let infinite_value = false
        if (members.length > 3) {
            infinite_value = true
        } else {
            infinite_value = false
        }

        const settings = {
            dots: true,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
        };

        return (
            <Fragment>
                <div class="profile-gag-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="profile-gag-modal-box">

                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="modal-cancle-btn">
                                        <img class="gag-modal-times-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row">
                                    <div class="col-xl-12 text-left">
                                        <h4><strong class="text-blue-10">Name:    {this.props.data.gag_name}</strong></h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="row col-xl-12 text-left">
                                        <h4 class="text-blue-10"><strong>Persons: {this.props.data.gag_persons}&nbsp;including you</strong></h4>
                                    </div>
                                </div>
                                <div class="profile-gag-slider-layout">
                                    <Slider {...settings} >
                                        {this.props.members.map(member => (
                                            <div key={member.id}>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
                                                    <div class="post-hub-modal-frame" >
                                                        <Link to={{ pathname: `/user-profile/${member.user}/profile`, state: { user: member.user } }}>

                                                            {member.profile_picture ?
                                                                <img
                                                                    class="hub-modal-member-photo"
                                                                    src={member.profile_picture}
                                                                    alt=""
                                                                />
                                                                :
                                                                <img
                                                                    class="hub-modal-member-photo"
                                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>

                                <div class="row">
                                    <div
                                        onClick={this.deleteSellectedGag()}
                                        class="col-xl-12"
                                    >
                                        <div
                                            onClick={this.props.deleteGag.bind(this, this.props.data.gag_id)}
                                            class="btn profile-gag-btn-danger"
                                        >
                                            Delete
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    members: state.buddies.members,
});

export default connect(mapStateToProps, { getGagMembers, deleteGag })(GagModal);