import axios from 'axios';
import { returnErrors, createAlert } from './alerts';
import {
    GET_MAP_NEARBY_PLACES,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    GET_MAP_MEMORIES,
    GET_MAP_USER_WITH_MEMORIES,
    USER_SET_MAP_PHOTO,
    SET_MY_MAP_LAYOUT,
    HUB_FETCHED_MARKERS_CREATED,
} from './actionTypes';
import { tokenConfig } from './auth';


export const getMapPlaces = (country, lat, lng, zoom) => (dispatch, getstate) => {
    const corrected_lat = String(lat).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_lng = String(lng).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${country}/${corrected_lat}/${corrected_lng}/${zoom}/get-map-places/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MAP_NEARBY_PLACES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                // dispatch({
                //     type: MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED,
                // });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}



export const getMapMemories = (user) => (dispatch, getstate) => {
    // const corrected_lat = String(lat).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    // const corrected_lng = String(lng).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-map-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MAP_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                // dispatch({
                //     type: MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED,
                // });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const getMapUserWithMemories = (country, lat, lng, zoom) => (dispatch, getstate) => {
    const corrected_lat = String(lat).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_lng = String(lng).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-map-user-with-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MAP_USER_WITH_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                // dispatch({
                //     type: MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED,
                // });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const userChangeHubCoverPhoto = (hub) => (dispatch, getstate) => {
    const hubData = new FormData();
    hubData.append("id", hub.id);
    hubData.append("cover_photo", hub.cover_photo);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user-set-map-photo/`, hubData, tokenConfig())
        .then(res => {
            dispatch({
                type: USER_SET_MAP_PHOTO,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const setMyMapLayout = (profile) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-my-map-layout/`, profile, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_MY_MAP_LAYOUT,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const createdFetchedHubsMarkers = () => (dispatch, getstate) => {
    dispatch({
        type: HUB_FETCHED_MARKERS_CREATED,
    });
}