import React, { Fragment, lazy, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    setHubOnlineShop,
} from '../../../store/actions/hub'
import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';


export class SetupPage extends Component {

    state = {
        shop_url: "",
        onlineShopSection: false,
        haveOnlineShop: false,
        redirect: false,
    };


    componentDidMount() {
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        this.props.getHubPage();
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    answerHaveOnlineShop() {
        this.setState({
            haveOnlineShop: true,
        })
    }


    answerOnlineShop() {
        const id = this.props.hub[0].id
        const has_online_shop = false
        const reservation_acceptance = false
        const appointment_acceptance = false
        const shop_url = this.state.shop_url
        const hub = { id, appointment_acceptance, reservation_acceptance, has_online_shop, shop_url }
        this.props.setHubOnlineShop(hub)
        this.setState({
            redirect: true,
        })
    }

    finishedOnlineShop() {

        const id = this.props.hub[0].id
        const place_type = this.state.place_type
        const has_online_shop = true
        const reservation_acceptance = false
        const appointment_acceptance = false
        const shop_url = this.state.shop_url
        const hub = { id, place_type, appointment_acceptance, reservation_acceptance, has_online_shop, shop_url }
        this.props.setHubOnlineShop(hub)
        this.setState({
            redirect: true,
        })
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/hub-homepage' }} />
        }

        return (
            <Fragment>

                <>
                    {this.props.hub.map(hub => (
                        <>
                            <HubDesktopNavbar data={{ sellected: "" }} />
                            <div>
                                <div class="whole-setup-page-page-layout">
                                    <OfflineLabel />

                                    <>

                                        <div class="setup-page-welcome-title">
                                            <Link to="/hub-homepage" class="setup-page-back-box">
                                                <div class="setup-page-back-icon-box">
                                                    <img
                                                        class="setup-page-back-icon"
                                                        src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </Link>
                                            Setup Online Shop
                                        </div>

                                        <>
                                            {this.state.haveOnlineShop ?
                                                <>
                                                    <div class="setup-page-info-text">
                                                        Type the url to your Online Shop Page?
                                                    </div>
                                                    <div class="setup-page-info-input-box">
                                                        <input
                                                            class='setup-page-name-input-field'
                                                            name="shop_url"
                                                            onChange={this.onChange}
                                                            placeholder="Online Shop URL..."
                                                            value={this.state.shop_url === "" ? hub.shop_url : this.state.shop_url}
                                                        />
                                                    </div>
                                                    <div onClick={() => this.finishedOnlineShop()} class="setup-page-info-done">
                                                        Done
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div class="setup-page-reservation-question-text">
                                                        Do you have an Online Shop?
                                                    </div>

                                                    <div class="setup-page-reservation-question-box">
                                                        <div onClick={() => this.answerOnlineShop(false)} class="setup-page-reservation-question-no">
                                                            No
                                                        </div>
                                                        <div onClick={() => this.answerHaveOnlineShop(true)} class="setup-page-reservation-question-yes">
                                                            Yes
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>




                                    </>
                                </div>
                            </div>

                            <Link to="/hub-homepage"
                                //  onClick={() => this.acceptHubsTerms()} 
                                class="setup-page-next-btn">
                                Done
                            </Link>

                        </>
                    ))}
                </>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    setHubOnlineShop,
})(SetupPage);