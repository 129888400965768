import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import HubBottomNavbar from '../../../components/hub/layout/hubBottomNavabar';
import HubMobileTraffic from '../../../components/hub/layout/hubMobileTraffic';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import HubDesktopTraffic from '../../../components/hub/layout/hubDesktopTraffic';
import TablesModal from '../../../components/hub/booked-reservations/TablesModal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
import HubsTermsModal from '../../../components/hub/layout/HubsTermsModal';
import AreYouSure from '../../../components/user/layout/AreYouSure'
import { getHubPage } from '../../../store/actions/hub'
import {
    getPendingAppointments,
    acceptAppointment,
    declineAppointment,
    getHubDaysAppointmentHours,
    getDatesAppointments,

} from '../../../store/actions/appointment'
import { createAlert } from '../../../store/actions/alerts'
// dropdown
// import Select from 'react-select'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/booked_page/booked-page.css"
// popups
import Modal from 'react-awesome-modal';
import WheelPicker from 'react-simple-wheel-picker';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";



export class PendingAppointments extends Component {

    state = {

        areYouSureModalDeclineReservation: false,
        requestsDeclined: [],
        requestsAccepted: [],
        requestes_answered: [],
        cancellationsTab: false,
        answered_reservations: [],
        acceptAppointmentModal: false,
        appointment_note: '-',
        appointment_name: '-',
        telephone: "-",
        hub: '',
        date: new Date(),
        changeDate: new Date(),
        changeDateUpdated: false,
        date_updated: false,
        redirect: false,
        show_datepicker: false,
        hour: '',
        minute: '',
        time: '',
        time_updated: false,
        hour_sellected: false,
        dateMode: true,
        timeMode: false,
        noteMode: false,
        hub_name: "",
        hub_logo: "",
        hub_id: "",
        timeModalVisible: true,
        pre_selected: false,
        redirect: false,
        selected_time: "",
        show_appointment_more: false,
        selected_hour: "",
        new_appointment_note: "",
        addAppointmentModal: false,
        appointment_date: "",
        appointment_time: "",
        note: "",
        sellected_appointment_id: "",
        sellected_appointment_name: "",
    }

    componentWillMount() {
        this.props.getHubPage();
        this.props.getPendingAppointments();
        // this.props.getHubFreeTables()
        // this.props.getHubCancellationsNotificationsStatus()
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        // make date only numbers
        const correct_datetime = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubDaysAppointmentHours(hub_id, date_id)
        this.props.getDatesAppointments(date_id)
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }); }


    handleBack() {
        this.props.history.goBack()
    }

    openAcceptAppointmentModal(appointment_id, appointment_name) {
        this.setState({
            acceptAppointmentModal: true,
            sellected_appointment_id: appointment_id,
            sellected_appointment_name: appointment_name,
        })
    }

    closeModal() {
        this.setState({
            acceptAppointmentModal: false,
            sellected_appointment_id: "",
            sellected_appointment_name: "",
            time: "",
            date: new Date(),
        })
    }

    updateChangeDateField(day) {
        this.setState({
            date: day,
            time: ""
        })
        const hub_id = JSON.parse(localStorage.getItem('ur')).hub_id
        // make date only numbers
        const correct_datetime = moment(day).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubDaysAppointmentHours(hub_id, date_id)
        this.props.getDatesAppointments(date_id)
    }


    handleOnChange(time) {
        this.setState({
            selected_time: time
        })
    }


    acceptAppointmentRequest = (id) => (e) => {
        const id = this.state.sellected_appointment_id
        const status = "Pending"
        const note = this.state.note
        const date = this.state.date
        const time = this.state.time
        const answer = { id, note, status, date, time };
        const new_answered_reservations = [...this.state.answered_reservations]
        new_answered_reservations.push(id)
        this.setState({ answered_reservations: new_answered_reservations, note: "" })
        this.props.acceptAppointment(answer, id);
        this.setState({
            acceptAppointmentModal: false,
            sellected_appointment_id: "",
            sellected_appointment_name: "",
            time: "",
            date: new Date(),
        })
    }

    declineAppointmentRequest = (id) => (e) => {
        const appointment_id = id
        const status = "Declined by the Hub"
        const note = this.state.note
        const answer = { id, note, status };
        const new_answered_reservations = [...this.state.answered_reservations]
        new_answered_reservations.push(id)
        this.setState({ answered_reservations: new_answered_reservations })
        this.props.declineAppointment(answer, appointment_id);
    }

    updateAnsweredRequestsList(id) {
        setTimeout(() => {
            const requestes_answered_list = [...this.state.requestes_answered]
            requestes_answered_list.push(id)
            this.setState({ requestes_answered: requestes_answered_list })
        }, 1500);
    }

    changeChangeTimeMode() {
        this.setState({
            changeTimeMode: !this.state.changeTimeMode,
        })
    }

    changeAppointmentHour() {
        this.setState({
            time: this.state.selected_time.value,
            changeTimeMode: !this.state.changeTimeMode,
        })
    }

    render() {

        const ExampleCustomInput = ({ value, onClick }) => (
            <strong onClick={onClick}>
                {value}
            </strong>
        );

        let selected_appointment = []

        if (this.state.selected_hour !== "") {
            selected_appointment = this.props.appointments.filter(appointment => appointment.time === this.state.selected_hour)[0]
        }

        const data = this.props.appointment_hours

        return (
            <Fragment>

                <HubDesktopNavbar data={{ sellected: "pending" }} />


                {this.state.acceptAppointmentModal ?
                    <Modal visible={this.state.acceptAppointmentModal} width="100%" height="45%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                        <div class="approved-appointments-modal-box">

                            {this.state.changeTimeMode ?
                                <>

                                    <div onClick={() => this.changeChangeTimeMode()} class="approved-appointments-timewheel-back-arrow">
                                        <img class="approved-appointments-timewheel-arrow-img" src={require("../../../assets/layout/homepage svg/back arrow.svg")} alt="" />
                                    </div>

                                    <div class="approved-appointments-timewheel-title-text">
                                        Hours for Appointments at:<br />
                                        <DatePicker
                                            selected={this.state.date}
                                            onChange={this.updateChangeDateField.bind(this)}
                                            // minDate={new Date()}
                                            locale="GTM+2"
                                            name="date"
                                            dateFormat="dd/MM/yyyy"
                                            todayButton="Press for Today"
                                            customInput={<ExampleCustomInput />}
                                        // withPortal
                                        />
                                    </div>

                                    <div class="approved-appointments-timewheel-arrow-left-box">
                                        <img
                                            class="approved-appointments-timewheel-arrow-img"
                                            src={require("../../../assets/hub/hub-homepage/svg/arrow-left.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="approved-appointments-timewheel-arrow-right-box">
                                        <img
                                            class="approved-appointments-timewheel-arrow-img"
                                            src={require("../../../assets/hub/hub-homepage/svg/arrow-right.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <div class="approved-appointments-timewheel-box">
                                        {data.length !== 0 ?
                                            <WheelPicker
                                                data={data}
                                                onChange={this.handleOnChange.bind(this)}
                                                height={200}
                                                width={180}
                                                itemHeight={60}
                                                selectedID={data[0].id}
                                                color="#000"
                                                activeColor="white"
                                                backgroundColor="white"
                                            />
                                            :
                                            null
                                        }
                                    </div>

                                    {this.props.appointment_hours.map(hour => (
                                        <>
                                            {hour.value === this.state.selected_time.value ?
                                                <div class="approved-appointments-timewheel-text">
                                                    Appointments at <b>{hour.value}</b>:<br />
                                                    <b>{hour.appointment}</b>
                                                </div>
                                                :
                                                null
                                            }
                                            {hour.appointment ?
                                                null
                                                : hour.value === this.state.selected_time.value ?
                                                    <div onClick={() => this.changeAppointmentHour()} class="approved-appointments-timewheel-change-btn">
                                                        Set at {this.state.selected_time.value}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </>
                                    ))}
                                </>
                                :
                                <>
                                    <div class="approved-appointments-cancel-modal-title">
                                        Set a date and a time for {this.state.sellected_appointment_name}'s Appointment
                                    </div>

                                    <img
                                        onClick={() => this.closeModal()}
                                        class="approved-appointments-cancel-modal-times"
                                        src={require("../../../assets/layout/homepage svg/times.svg")}
                                        alt=""
                                    />


                                    <div class="pending-appointments-edit-modal-date">
                                        <DatePicker
                                            selected={this.state.date}
                                            onChange={this.updateChangeDateField.bind(this)}
                                            // minDate={new Date()}
                                            locale="GTM+2"
                                            name="date"
                                            dateFormat="dd/MM/yyyy"
                                            todayButton="Press for Today"
                                            customInput={<ExampleCustomInput />}
                                        // withPortal
                                        />
                                    </div>

                                    {this.state.time === "" ?
                                        <div onClick={() => this.changeChangeTimeMode()} class="pending-appointments-edit-modal-time">
                                            Time
                                        </div>
                                        :
                                        <div onClick={() => this.changeChangeTimeMode()} class="pending-appointments-edit-modal-time">
                                            {this.state.time}
                                        </div>
                                    }


                                    {this.state.time !== '' ?

                                        <div onClick={this.acceptAppointmentRequest()} class="approved-appointments-pending-modal-accept">
                                            Set Appointment
                                        </div>
                                        :
                                        null
                                    }
                                </>
                            }
                        </div>

                    </Modal>
                    :
                    null
                }

                <div>
                    <div class="whole-hub-reservations-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row color-7">

                                <div class="col-xl-12 body-right-7  hide-main-item ">


                                    <div class="pending-appointments-all-appointments-layout">
                                        {this.props.hub.map(hub => (
                                            <>
                                                <div class="hub-mobile-traffic-mobile-navbar">
                                                    <Link to="/hub-homepage">
                                                        <div class="back-button-hub-mobile-traffic-box">
                                                            <img
                                                                class="hub-mobile-traffic-back-button"

                                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    Requests
                                                </div>


                                                {this.props.requested_appointments.length === 0 ?
                                                    <div class="text-center pending-hub_reservation_empty-image">

                                                        <div class="pending-hub_reservation_empty-icon">
                                                            <img
                                                                class="pending-hub_reservationempty-created"
                                                                src={require("../../../assets/booked_page/svg/no-requests.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <p class="text-blue-empty-pending-hub">
                                                                No Requests
                                                            </p>
                                                            <p class="text-small-empty-pending-hub">
                                                                When you'll have a request about an appointment, it'll appear here
                                                            </p>
                                                        </div>
                                                        <div class="pending-hub_reservation_gdpr-links">
                                                            <HubGdprLinks />
                                                        </div>
                                                    </div>
                                                    :

                                                    <>
                                                        <center>
                                                            <div class="hub-table-item mt-5">
                                                                <div class="hub-pending-section-title-box">
                                                                    {/* <h2><strong class="text-blue">Requests</strong></h2> */}
                                                                </div>
                                                                <br />

                                                                {this.props.requested_appointments.map(appointment => (

                                                                    <>

                                                                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 pending-hub-reservation-item-col">
                                                                            <div class="pending-hub-reservation-item-of-table ">
                                                                                <div class="row">
                                                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w text-center">
                                                                                        {appointment.reservation_user_photo ?
                                                                                            <img
                                                                                                class="pending-hub-reservation-m-t"
                                                                                                src={appointment.reservation_user_photo}
                                                                                                alt=""
                                                                                            />
                                                                                            :
                                                                                            <img
                                                                                                class="pending-hub-reservation-m-t"
                                                                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        }
                                                                                        <br />
                                                                                    </div>

                                                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w texttext-left">
                                                                                        <div class="pending-hub_reservation-meeting-info">
                                                                                            <br />
                                                                                            <div>
                                                                                                Requested by:
                                                                                            </div>
                                                                                            <div>
                                                                                                Phone Number:
                                                                                            </div>

                                                                                        </div>

                                                                                        <div class="pending-hub_reservation-meeting-data">
                                                                                            <br />
                                                                                            <div>
                                                                                                <b>{appointment.appointment_name}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{appointment.telephone}</b>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="pending-hub_reservation-note-box">
                                                                                        <div class="pending-hub_reservation-message-title">
                                                                                            Message from the client
                                                                                        </div>
                                                                                        {appointment.appointment_request_note ?
                                                                                            <div class="pending-hub_reservation-message">
                                                                                                {appointment.appointment_request_note}
                                                                                            </div>
                                                                                            :
                                                                                            <div class="pending-hub_reservation-message">
                                                                                                -
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {appointment.status === "Requested" ?
                                                                                        <div class="pending-hub_reservation-note-box">
                                                                                            <div class="pending-hub_reservation-message-title">
                                                                                                Note to me
                                                                                            </div>
                                                                                            <div class="pending-hub_reservation-message">
                                                                                                <textarea
                                                                                                    name="note"
                                                                                                    onChange={this.onChange}
                                                                                                    value={this.state.note}
                                                                                                    placeholder="Note to me..."
                                                                                                    class="form-control"
                                                                                                    id="exampleFormControlTextarea1"
                                                                                                    rows="3"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }


                                                                                    <div class="pending-hub_reservation-meeting-status">
                                                                                        {appointment.status === "Requested" ?
                                                                                            <div class="pending-hub_reservation-meeting-requested">
                                                                                                Appointment Requested
                                                                                            </div>
                                                                                            :
                                                                                            appointment.status === "Pending" ?
                                                                                                <div class="pending-hub_reservation-meeting-approved">
                                                                                                    Appointment Approved
                                                                                                </div>

                                                                                                : appointment.status === "Cancelled by User" ?
                                                                                                    <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                        Cancelled by {appointment.appointment_name}
                                                                                                    </div>
                                                                                                    : appointment.status === "Cancelled by Hub" ?
                                                                                                        <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                            Cancelled by you
                                                                                                        </div>
                                                                                                        : appointment.status === "Declined by the Hub" ?
                                                                                                            <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                                Declined by you
                                                                                                            </div>
                                                                                                            : appointment.status === "Updated by User" ?
                                                                                                                <div class="pending-hub_reservation-meeting-requested">
                                                                                                                    Update Requested
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                    {this.state.answered_reservations.includes(appointment.id) ?
                                                                                        <div class="pending-hub_reservation-buttons">
                                                                                            <div class="pending-hub_reservation-btn-answerd">
                                                                                                Answered
                                                                                            </div>
                                                                                        </div>
                                                                                        : appointment.status === "Requested" ?
                                                                                            <div class="pending-hub_reservation-buttons">
                                                                                                <div
                                                                                                    onClick={() => this.openAcceptAppointmentModal(appointment.id, appointment.appointment_name)}
                                                                                                    // onClick={this.acceptAppointmentRequest(appointment.id)}
                                                                                                    class="pending-hub_reservation-btn-accept">
                                                                                                    Accept
                                                                                                </div>

                                                                                                <div
                                                                                                    onClick={this.declineAppointmentRequest(appointment.id)}
                                                                                                    class="pending-hub_reservation-btn-decline">
                                                                                                    Decline
                                                                                                </div>
                                                                                            </div>
                                                                                            : appointment.status === "Pending" ?
                                                                                                <div class="pending-hub_reservation-buttons">
                                                                                                    <div class="pending-hub_reservation-btn-answerd">
                                                                                                        Accepted
                                                                                                    </div>
                                                                                                </div>
                                                                                                : appointment.status === "Declined by the Hub" ?
                                                                                                    <div class="pending-hub_reservation-buttons">
                                                                                                        <div class="pending-hub_reservation-btn-answerd">
                                                                                                            Declined
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : appointment.status === "Updated by User" ?
                                                                                                        <div class="pending-hub_reservation-buttons">
                                                                                                            <div class="pending-hub_reservation-btn-accept">
                                                                                                                Accept
                                                                                                            </div>
                                                                                                            <div class="pending-hub_reservation-btn-decline">
                                                                                                                Decline
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                    }
                                                                                    <br />
                                                                                    <br /><br />

                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <br />
                                                                        </div>
                                                                    </>
                                                                ))}








                                                                <br /><br /><br /><br />
                                                                <HubGdprLinks />
                                                                <br /><br />
                                                                {/* </div> */}


                                                                {/* } */}
                                                            </div>
                                                        </center>
                                                        <br />
                                                        <br />
                                                    </>

                                                }
                                            </>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <HubBottomNavbar data={{ sellected: "pending" }} /> */}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    hub: state.hub.hub,
    requested_appointments: state.appointment.requested_appointments,
    appointment_hours: state.appointment.appointment_hours,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    appointment_hours: state.appointment.appointment_hours,
    appointments: state.appointment.appointments,
});

export default connect(mapStateToProps, {
    getHubPage,
    getPendingAppointments,
    acceptAppointment,
    declineAppointment,
    createAlert,
    getHubDaysAppointmentHours,
    getDatesAppointments,
})(PendingAppointments);