import { createAlert, returnErrors } from '../actions/alerts';
import {
    GET_ENTERTAINEMENT_JOBS,
    GET_HUB_PERSONEL,
    START_EMPLOYEE_SHIFT,
    END_EMPLOYEE_SHIFT,
    CREATE_HUB_EMPLOYEE,
    GET_HUB_PERSONEL_SHIFTS,
    REMOVE_PERSONEL_ACCESS,
    CHANGE_EMPLOYEE_ACCESS,
    REMOVE_EMPLOYEE_ACCESS,
    GET_MY_EMPLOYEE_ACCESS
} from '../actions/actionTypes';

const initialState = {
    entertainement_jobs: [],
    employees: [],
    shifts: [],
    my_employement_access: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ENTERTAINEMENT_JOBS:
            return {
                ...state,
                entertainement_jobs: action.payload
            };
        case GET_HUB_PERSONEL:
            return {
                ...state,
                employees: action.payload
            };
        case START_EMPLOYEE_SHIFT:
            const updated_employee = [action.payload]
            return {
                ...state,
                employees: state.employees.map(employee => updated_employee.find(change => change.id === employee.id) || employee)
            };
        case END_EMPLOYEE_SHIFT:
            const new_updated_employee = [action.payload]
            return {
                ...state,
                employees: state.employees.map(employee => new_updated_employee.find(change => change.id === employee.id) || employee)
            };
        case CREATE_HUB_EMPLOYEE:
            if (action.payload.name === null) {
                createAlert({ noTableSelected: "The User you sellected is already your Employee!" });
            } else {
                return {
                    ...state,
                    employees: [action.payload, ...state.employees]
                };
            }
        case GET_HUB_PERSONEL_SHIFTS:
            return {
                ...state,
                shifts: action.payload
            };
        case REMOVE_PERSONEL_ACCESS:
            return {
                ...state,
                employees: state.employees.filter(employee => employee.id !== action.payload.id),
            };

        case GET_MY_EMPLOYEE_ACCESS:
            return {
                ...state,
                my_employement_access: action.payload,
            };


        // CHANGE_EMPLOYEE_ACCESS, REMOVE_EMPLOYEE_ACCESS

        default:
            return state;
    }
}