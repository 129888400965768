import axios from "axios";
import { tokenConfig } from './auth';
import { returnErrors, createAlert } from './alerts';

import {
    GET_AMBASSANDOR_PROFILE,
    GET_COMPANY_AMBASSANDOR_PROFILE,
    UPDATE_HUB_AMBASSANDOR,
    LOADING_PAGE,
    PAGE_LOADED,
    GET_HUB_JOBS,
    UPDATE_HUB_JOB_ACTIVATION,
    CREATE_AMBASSANDOR_HUB_JOB,
    DELETE_HUB_JOB,
    GET_USER_AMBASSANDOR,
    UPDATE_USER_AMBASSANDOR,
    CHECK_USER_IS_AMBASSANDOR
} from "./actionTypes";


export const getAmbasaddorsProfile = (profile_id) => dispatch => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-ambassandor-profile/${profile_id}/`)
        .then(res => {
            dispatch({
                type: GET_AMBASSANDOR_PROFILE,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 2500);
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
};

export const getCompanyAmbasaddorsProfile = (company_profile_id) => dispatch => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-profile-ambassandor-profile/${company_profile_id}/`)
        .then(res => {
            dispatch({
                type: GET_COMPANY_AMBASSANDOR_PROFILE,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 2500);
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
};


export const getUserAmbasaddorsProfile = () => dispatch => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-user-ambassandor/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_USER_AMBASSANDOR,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 2500);
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
};

export const updateUserAmbassandor = (ambassandor) => (dispatch, getstate) => {

    const ambassandorData = new FormData();
    // ambassandorData.append("ambassandor_profile_picture", ambassandor.ambassandor_profile_picture);
    // ambassandorData.append("ambassandor_cover_photo", ambassandor.ambassandor_cover_photo);
    ambassandorData.append("instagram_username", ambassandor.instagram_username);
    ambassandorData.append("instagram_redirect", ambassandor.instagram_redirect);
    ambassandorData.append("facebook_username", ambassandor.facebook_username);
    ambassandorData.append("facebook_redirect", ambassandor.facebook_redirect);
    ambassandorData.append("tiktok_username", ambassandor.tiktok_username);
    ambassandorData.append("tiktok_redirect", ambassandor.tiktok_redirect);
    ambassandorData.append("twitter_username", ambassandor.twitter_username);
    ambassandorData.append("twitter_redirect", ambassandor.twitter_redirect);
    ambassandorData.append("website", ambassandor.website);
    ambassandorData.append("mobile_number", ambassandor.mobile_number);
    // ambassandorData.append("has_email", ambassandor.has_email);
    // ambassandorData.append("email", ambassandor.email);
    ambassandorData.append("has_instagram", ambassandor.has_instagram);
    ambassandorData.append("has_facebook", ambassandor.has_facebook);
    ambassandorData.append("has_website", ambassandor.has_website);
    ambassandorData.append("has_tiktok", ambassandor.has_tiktok);
    ambassandorData.append("has_twitter", ambassandor.has_twitter);
    ambassandorData.append("has_mobile_number", ambassandor.has_mobile_number);

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user/update-ambassandor/`, ambassandorData, tokenConfig())
        .then(res => {
            // dispatch(createAlert({ updateHub: 'The info has been Updated!!' }));
            dispatch({
                type: UPDATE_USER_AMBASSANDOR,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const getUserAmbasaddorsHubJobs = (ambassandor_id) => dispatch => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-user-ambassandor-hub-jobs/${ambassandor_id}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_JOBS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 2500);
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
};



export const updateHubAmbassandor = (ambassandor) => (dispatch, getstate) => {
    const id = JSON.parse(localStorage.getItem('ur')).hub_id
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${id}/update-ambassandor/`, ambassandor, tokenConfig())
        .then(res => {
            dispatch(createAlert({ updateHub: 'The info has been Updated!!' }));

            dispatch({
                type: UPDATE_HUB_AMBASSANDOR,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getAmbasaddorsHubJobs = () => dispatch => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-ambassandor-hub-jobs/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_JOBS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 2500);
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        });
};

export const createAmbassandorHubjob = (hubjob) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/create-ambassandor-hubjob/`, hubjob, tokenConfig())
        .then(res => {
            dispatch(createAlert({ updateHub: 'The Job has been Created!!' }));

            dispatch({
                type: CREATE_AMBASSANDOR_HUB_JOB,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const changeHubJobActivation = (hubjob) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/change-ambassandor-hubjob-active-status/`, hubjob, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_HUB_JOB_ACTIVATION,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};



export const deleteAmbassandorHubjob = id => (dispatch, getstate) => {
    axios.delete(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/delete-hub-job/${id}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DELETE_HUB_JOB,
                payload: id
            });
        }).catch(err => console.log(err));
}


export const checkUserIsAmbassandor = (user) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user/${user}/is-ambassandor/`, tokenConfig())
        .then(res => {
            dispatch({
                type: CHECK_USER_IS_AMBASSANDOR,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};