import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { register } from '../../../store/actions/auth';
import { createAlert } from '../../../store/actions/alerts'
import Modal from 'react-awesome-modal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import CookiesModal from '../../../components/user/layout/cookiesModal';
import '../../../assets/hub/hub-authentication/hub-login.css'

export class HubLogin extends Component {
    state = {
        credentials: '',
        password: '',
        modalVisible: false,
        isLoading: true
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 1000);
    }

    onCredentialsSubmit = e => {
        e.preventDefault();
        const { credentials } = this.state;
        if (credentials === "") {
            this.props.createAlert({ noCridentialsGiven: "You haven't given an Email address or a Username!" });
        } else {
            this.openModal()
        }
        // const newUser = {
        //     username,
        //     email
        // }
        // this.props.register(newUser);
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    openModal() {
        this.setState({
            modalVisible: true
        });
    }

    closeModal() {
        this.setState({
            modalVisible: false
        });
    }


    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        const { credentials, password } = this.state;
        return (
            <Fragment>
                <div class={this.state.isLoading ? "user-register-lottie-appear" : "user-register-lottie-dissappear"}>
                    <div class="register-lottie-position">
                        <Hubst3rLottie />
                    </div>

                </div>


                <div class={this.state.isLoading ? "user-register-section-disappear" : null}>
                    <CookiesModal />
                    <Modal visible={this.state.modalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div class="authentication-password-box">
                            <div class="row">
                                <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                </div>
                                <div class="col-lg-6 col-md-10 col-sm-8 col-8 c-box-6">
                                    <p class="hub-login-modal-top-text">Enter the 6 Digit Password sent to your email</p>
                                    <div class="">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                onChange={this.onChange}
                                                value={password}
                                                class="modal-password-field"
                                                name="password"
                                                placeholder="Enter the password"
                                                required
                                            />
                                        </div>
                                        <br />
                                        <center>
                                            <a class="send-again-a" href="#">
                                                Send again
                                            </a>
                                        </center>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-center mt-4">
                                            <div
                                                onClick={() => this.closeModal()}
                                                class="btn hub-login-modal-btn-success">
                                                Continue
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <div class="hub-login-form-color">
                        <div class="hub-login-form-header text-center">
                            <Link to="/register">
                                <div class="register-back-button">
                                    <img
                                        class="register-back-icon-size"
                                        src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                        alt=""
                                    />
                                </div>
                            </Link>
                            <img
                                class="hubst3r-login-word-size"
                                src={require("../../../assets/authentication/svg/Logo-h.svg")}
                                alt=""
                            />
                        </div>

                        <div class="hub-login-signup-form">
                            <form action="/examples/actions/confirmation.php" method="post">
                                <div class="hub-login-form-logo text-center">
                                    <img
                                        class="hubster-login-logo-size"
                                        src={require("../../../assets/authentication/svg/Logo.svg")}
                                        alt=""
                                    />
                                </div>
                                <h2>Sign In</h2>
                                <p class="hint-text hub-login-top-text">Login to manage your Hub</p>

                                <div class="form-group">
                                    <input
                                        type="text"
                                        onChange={this.onChange}
                                        value={credentials}
                                        class="hub-login-form-control"
                                        name="credentials"
                                        placeholder="Enter your username or your email"
                                        required
                                    />
                                </div>

                                <div class="form-group">
                                    <button
                                        onClick={this.onCredentialsSubmit}
                                        // type="submit"
                                        class="btn btn-success btn-lg btn-block"
                                    >
                                        Log in
                                    </button>
                                </div>
                                <div class="text-center hub-login-account-have">
                                    Don't have register to the Hub yet? &nbsp;
                                    <Link to="/hub-register">
                                        <a href="#">
                                            Register Now
                                        </a>
                                    </Link>
                                </div>
                                <div class="text-center hub-login-account-have">
                                    You are not Member of a Hub? &nbsp;
                                    <Link to="/register">
                                        <a href="#">
                                            Login as a User
                                        </a>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { register, createAlert })(HubLogin);