import React, { Fragment, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import GdprLinks from '../../../components/user/layout/gdprLinks';
import { getAmbasaddorsProfile, getUserAmbasaddorsHubJobs } from '../../../store/actions/ambassandor';
import CookiesModal from '../../../components/user/layout/cookiesModal';
import "../../../assets/ambassandors/ambassandors.css";
import ShowUserJobModal from '../../../components/user/ambassandor/ShowUserJobModal';
import Modal from 'react-awesome-modal';
import { createFirstTimeVisit, visitedUserRegistered } from '../../../store/actions/hubst3rActions';



export class Ambassadors extends Component {

    state = {
        isLoading: true,
        showJobModalVisible: false,
    };

    componentDidMount() {
        this.props.getUserAmbasaddorsHubJobs(this.props.match.params.profileID)
        this.props.getAmbasaddorsProfile(this.props.match.params.profileID)
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 2800);


        if (!JSON.parse(localStorage.getItem("frsttmvst"))) {
            localStorage.setItem('frsttmvst', JSON.stringify("True"));
            const browser = window.loadVersionBrowser().name;
            const full_browser = window.clientInformation.userAgent;
            const device_width = window.innerWidth
            const device_height = window.innerHeight
            fetch(`https://geolocation-db.com/json/`)
                .then(response => {
                    return response.json();
                }, "jsonp")
                .then(res => {
                    const ip = res.IPv4
                    const country = res.country_name
                    const city = res.city
                    const latitude = res.latitude
                    const longitude = res.longitude
                    const state = res.state
                    const from_nfc = true
                    const nfc_number = this.props.match.params.profileID
                    const data = { from_nfc, nfc_number, browser, full_browser, device_width, device_height, ip, country, city, latitude, longitude, state }
                    this.props.createFirstTimeVisit(data)
                })

        }
    }


    openShowHubJobsModal(job) {
        this.setState({
            showJobModalVisible: true,
            job: job
        });
    }

    closeModal() {
        this.setState({
            showJobModalVisible: false,
            job: {}
        });
    }


    render() {
        let arleady_logged_in = false
        if (JSON.parse(localStorage.getItem('ur'))) {
            arleady_logged_in = true
        }

        let ambassandor = this.props.ambassandor
        if (ambassandor[0]) {
            ambassandor = ambassandor[0]
            return <Redirect to={{ pathname: `/user-profile/${ambassandor.user}/splash`, state: { user: ambassandor.user } }} />;
        }

        return (
            <Fragment>
                {this.state.job ?
                    <Modal visible={this.state.showJobModalVisible} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                        <ShowUserJobModal
                            data={{
                                job: this.state.job,
                                closeModal: this.closeModal.bind(this)
                            }}
                        />
                    </Modal>
                    :
                    null
                }
                {/* <div class={this.state.isLoading ? "ambassandors-page-lottie-appear" : "ambassandors-page-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <CookiesModal />
                <div class="ambassandor-all-page-display">
                    {/* <div class={this.state.isLoading ? "ambassandors-page-section-disappear" : null}> */}
                    <div>
                        <div class="ambassandors-page-whole-page-layout">
                            <OfflineLabel />
                            <div class="color-4">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="container-fluid small-header">
                                            <div class="row py-4">
                                                <div class="ambassandor-top-bar-logo">
                                                    {/* {arleady_logged_in ? */}
                                                    <Link to="/">
                                                        <img src={require("../../../assets/layout/homepage svg/Logo.svg")} alt="" />
                                                    </Link>
                                                    {/* :
                                                        <img src={require("../../../assets/layout/homepage svg/Logo.svg")} alt="" />
                                                    } */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ambassandors-body-right col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            {this.props.ambassandor.map(ambassandor => (
                                                <>
                                                    <div>
                                                        <div class="ambassandor-profile-cover-photo">
                                                            {ambassandor.ambassandor_cover_photo ?
                                                                <img
                                                                    class="ambassandor-profile-cover-img"
                                                                    src={ambassandor.ambassandor_cover_photo}
                                                                    alt=""
                                                                />
                                                                :
                                                                <Link to="/register">
                                                                    <img
                                                                        class="ambassandor-profile-cover-img"
                                                                        src={require("../../../assets/ambassandors/svg/cover.png")}
                                                                        alt=""
                                                                    />
                                                                </Link>
                                                            }
                                                        </div>

                                                        <div class="ambassandor-profile-profile-photo">
                                                            <img
                                                                class="ambassandor-profile-profile-img"
                                                                src={ambassandor.ambassandor_profile_picture}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="ambassandor-profile-username-layout">
                                                            <h2 class="ambassandor-profile-username">
                                                                {ambassandor.name}
                                                            </h2>
                                                        </div>
                                                    </div>



                                                    <div class="row ambassandor-profile-social-media">

                                                        <div class="ambassandor-profile-app">
                                                            {arleady_logged_in ?
                                                                <>
                                                                    {ambassandor.is_hub ?
                                                                        <Link to={{ pathname: '/hub', state: { hub_id: ambassandor.hub } }}>
                                                                            <img
                                                                                class="ambassandor-app-icon-size"
                                                                                src={require("../../../assets/ambassandors/svg/hubst3r.png")}
                                                                                alt=""
                                                                            />
                                                                            <p class="ambassandor-profile-app-username">{ambassandor.hubst3r_username}</p>
                                                                        </Link>
                                                                        :
                                                                        <Link to={{ pathname: `/user-profile/${ambassandor.user}/profile` }}>
                                                                            <img
                                                                                class="ambassandor-app-icon-size"
                                                                                src={require("../../../assets/ambassandors/svg/hubst3r.png")}
                                                                                alt=""
                                                                            />
                                                                            <p class="ambassandor-profile-app-username">{ambassandor.hubst3r_username}</p>
                                                                        </Link>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {ambassandor.is_hub ?
                                                                        <Link to={{ pathname: '/register', state: { hub_id: ambassandor.hub, ambassandor: "" } }} >
                                                                            <img
                                                                                class="ambassandor-app-icon-size"
                                                                                src={require("../../../assets/ambassandors/svg/hubst3r.png")}
                                                                                alt=""
                                                                            />
                                                                            <p class="ambassandor-profile-app-username">{ambassandor.hubst3r_username}</p>
                                                                        </Link>
                                                                        :
                                                                        <Link to={{ pathname: '/register', state: { hub_id: "", ambassandor: ambassandor.user } }} >
                                                                            <img
                                                                                class="ambassandor-app-icon-size"
                                                                                src={require("../../../assets/ambassandors/svg/hubst3r.png")}
                                                                                alt=""
                                                                            />
                                                                            <p class="ambassandor-profile-app-username">{ambassandor.hubst3r_username}</p>
                                                                        </Link>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                        {ambassandor.has_instagram ?
                                                            <div class="ambassandor-profile-app">
                                                                <a href={ambassandor.instagram_redirect}>
                                                                    <img
                                                                        class="ambassandor-app-icon-size-insta"
                                                                        src={require("../../../assets/ambassandors/svg/instagram.svg")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="ambassandor-profile-app-username">{ambassandor.instagram_username}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_menu ?
                                                            <div class="ambassandor-profile-app">
                                                                <a href={ambassandor.menu_url}>
                                                                    <img
                                                                        class="ambassandor-app-icon-size-insta"
                                                                        src={require("../../../assets/ambassandors/svg/menu.png")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="ambassandor-profile-app-username">Menu</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_website ?
                                                            <div class="ambassandor-profile-app">
                                                                <a href={ambassandor.website}>
                                                                    <img
                                                                        class="ambassandor-app-icon-size-insta"
                                                                        src={require("../../../assets/ambassandors/svg/website.png")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="ambassandor-profile-app-username">Website</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_tiktok ?
                                                            <div class="ambassandor-profile-app">
                                                                <a href={ambassandor.tiktok_redirect}>
                                                                    <img
                                                                        class="ambassandor-app-icon-size-facebook"
                                                                        src={require("../../../assets/ambassandors/svg/tiktok.svg")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="ambassandor-profile-app-username">{ambassandor.tiktok_username}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_facebook ?
                                                            <div class="ambassandor-profile-app">
                                                                <a href={ambassandor.facebook_redirect}>
                                                                    <img
                                                                        class="ambassandor-app-icon-size-facebook"
                                                                        src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <p class="ambassandor-profile-app-username">{ambassandor.facebook_username}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_mobile_number ?
                                                            <div class="ambassandor-profile-app">
                                                                {/* <a href={ambassandor.facebook_redirect}> */}
                                                                <img
                                                                    class="ambassandor-app-icon-size-facebook"
                                                                    src={require("../../../assets/ambassandors/svg/phone.png")}
                                                                    alt=""
                                                                />
                                                                {/* </a> */}
                                                                <p class="ambassandor-profile-app-username">{ambassandor.mobile_number}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {ambassandor.has_email ?
                                                            <div class="ambassandor-profile-app">
                                                                {/* <a href={ambassandor.facebook_redirect}> */}
                                                                <img
                                                                    class="ambassandor-app-icon-size-facebook"
                                                                    src={require("../../../assets/ambassandors/svg/emial.svg")}
                                                                    alt=""
                                                                />
                                                                {/* </a> */}
                                                                <p class="ambassandor-profile-app-username">{ambassandor.email}</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>

                                                    <br /><br /><br />
                                                    <div class="ambassandor-profile-hiring-position">
                                                        {ambassandor.hiring ?
                                                            <>
                                                                <div class="ambassandor-we-are-hiring">
                                                                    <img
                                                                        class="ambassandor-hiring-icon-size"
                                                                        src={require("../../../assets/ambassandors/svg/hiring.png")}
                                                                        alt=""
                                                                    />
                                                                    <h2 class="ambassandor-profile-username">
                                                                        What are we looking for?
                                                                    </h2>
                                                                    <div>
                                                                        {this.props.jobs.map(job => (
                                                                            <div class="row hub-job-item">
                                                                                <h2
                                                                                    onClick={() => this.openShowHubJobsModal(job)}
                                                                                    class="hub-job-name">
                                                                                    {job.name}
                                                                                </h2>
                                                                                <div
                                                                                    onClick={() => this.openShowHubJobsModal(job)}
                                                                                    class="hub-job-item-show-more">
                                                                                    See More
                                                                                </div>

                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>

                                                </>
                                            ))}

                                            <div class="ambassandor-gdpr-place-open">
                                                <GdprLinks />
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    jobs: state.ambassandor.jobs,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    ambassandor: state.ambassandor.ambassandor
});

export default connect(mapStateToProps, { getAmbasaddorsProfile, getUserAmbasaddorsHubJobs, createFirstTimeVisit })(Ambassadors);