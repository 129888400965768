import React, { Fragment, Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import 'react-times/css/material/default.css';
import { createAlert } from '../../../store/actions/alerts';
import TimePickerComp from '../../../components/user/meeting/TimePicker';
//datepicker
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// Invitation Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getBuddies, searchBuddy, getGags, getGagMembers, deleteGag } from '../../../store/actions/buddies';
import { getArrangeMeetingHub } from '../../../store/actions/hub';
import { createMeeting } from '../../../store/actions/meeting';
import { getNotificationsStatus } from '../../../store/actions/notification';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import Modal from 'react-awesome-modal';
import GagModal from '../../../components/user/gag/GagModal'
import moment from "moment-timezone";
import "../../../assets/arrange-meeting/arrange-page.css";


export class ArrangePage extends Component {

    state = {
        // activities: '',
        buddy_search: '',
        buddies_note: '-',
        hub: '',
        date: new Date(),
        invitations: ["0"],
        gag_invitations: ["0"],
        gagModal: false,
        gag_name: "",
        gag_id: "",
        gag_persons: "",
        redirect: false,
        show_datepicker: false,
        timeModalVisible: false,
        selected_hour: false,
        hour: '',
        minute: '',
        time: '19:00',
    };

    componentDidMount() {
        const id = JSON.parse(localStorage.getItem('ur')).id
        this.props.getBuddies(id);
        this.props.getGags()
        this.props.getArrangeMeetingHub(this.props.location.state.hub.id);
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    updateInvitationList = (id) => (e) => {
        e.preventDefault();
        const invitation_list = [...this.state.invitations]
        if ((invitation_list.indexOf(id)) !== -1) {
            const new_remove = invitation_list.filter(f => f !== id)
            this.setState({ invitations: new_remove })
        } else {
            invitation_list.push(id)
            this.setState({ invitations: invitation_list })
        }
    }

    updateGagInvitationList = (id) => (e) => {
        e.preventDefault();
        const gag_invitation_list = [...this.state.gag_invitations]
        if ((gag_invitation_list.indexOf(id)) !== -1) {
            const new_remove = gag_invitation_list.filter(f => f !== id)
            this.setState({ gag_invitations: new_remove })
        } else {
            gag_invitation_list.push(id)
            this.setState({ gag_invitations: gag_invitation_list })
        }
    }

    updateDateField(day) {
        this.setState({
            show_datepicker: false,
            date: day,
        })
    }

    onChange = e => {
        if (e.target.value === "") {
            this.setState({ [e.target.name]: e.target.value })
            this.props.getBuddies(JSON.parse(localStorage.getItem('ur')).id);
        } else {
            if (e.target.name === "buddy_search") {
                const query = e.target.value
                if (query.toLowerCase() !== "ad") {
                    this.props.searchBuddy(query)
                }
            }
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onMeetingSubmit = e => {
        e.preventDefault();
        const { buddies_note } = this.state;
        const creator = JSON.parse(localStorage.getItem('ur')).username
        const hub_name = this.props.location.state.hub.name
        const invitations_list = this.state.invitations
        const invitations = invitations_list.toString();
        const gag_invitations_list = this.state.gag_invitations
        const gag_invitations = gag_invitations_list.toString();
        const time = this.state.time
        // const date = this.state.date
        const date = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")

        const meeting = { buddies_note, hub_name, creator, invitations, gag_invitations, time, date };
        if ((invitations === "" && gag_invitations === "") || (invitations === "0" && gag_invitations === "0")) {
            this.props.createAlert({ noInvitationsSent: "You haven't Invite Anyone!" });
        } else if (time === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Select time!" });
        } else {
            this.props.createMeeting(meeting);
            setTimeout(() => {
                this.setState({
                    buddies_note: "-",
                    hub_name: "",
                    invitations: [],
                    gag_invitations: [],
                    time: "",
                    date: new Date(),
                    redirect: true
                });
            }, 500);
        }

    };

    openModal = (name, id, persons) => (e) => {
        this.setState({
            gag_name: name,
            gag_id: id,
            gag_persons: persons,
            gagModal: true
        });
        this.props.getGagMembers(id);
    }

    setDatepickerMode = () => (e) => {
        this.setState({
            show_datepicker: true
        });
    }

    openTimeModal() {
        this.setState({
            selected_hour: false,
            timeModalVisible: true,
        });
    }

    closeModal() {
        this.setState({
            gagModal: false,
            timeModalVisible: false,
        });
    }

    sellectedHourMode(hour) {
        let minute = this.state.minute
        if (minute === "") {
            minute = "00"
        }
        const time = hour + ':' + minute
        this.setState({
            hour: hour,
            time: time
        });
        setTimeout(() => {
            this.setState({
                selected_hour: true,
            });
        }, 500);
    }

    sellectedMinuteMode(minute) {
        const time = this.state.hour + ':' + minute
        this.setState({
            minute: minute,
            time: time
        });
        setTimeout(() => {
            this.setState({
                selected_hour: false,
            });
        }, 700);
    }

    backToHours = e => {
        this.setState({
            selected_hour: false,
        });
    }


    // updateTimeField(time) {
    //     this.closeModal()
    // }


    render() {
        if (this.state.redirect) {
            return <Redirect to="/meetings" />;
        }
        const { buddies_note } = this.state;

        // datepicker button
        const ExampleCustomInput = ({ value, onClick }) => (
            <div className="arrange-date-button-customize" onClick={onClick}>
                {value}
            </div>
        );

        // carousel arrows
        const ArrowNext = (props) => (
            <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-right-position">
                <div
                    style={{ display: "none" }}
                    class="arrow arrow-right"
                >
                    <img src={require("../../../assets/arrange-meeting/svg/next.svg")} alt="" />
                </div>
            </div>
        );

        const ArrowPrev = (props) => (
            <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-left-position">
                <div
                    style={{ display: "none" }}
                // class="arrow arrow-left"
                >
                    <img src={require("../../../assets/arrange-meeting/svg/Icon.svg")} alt="" />
                </div>
            </div>
        );
        const buddies = this.props.buddies
        let infinite_value = false
        if (buddies.length > 3) {
            infinite_value = true
        } else {
            infinite_value = false
        }


        const settingsForeverAlone = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 2,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 1000,
            cssEase: "linear",
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };

        //invitation carousel settings
        const settings = {
            dots: false,
            infinite: infinite_value,
            slidesToShow: 3,
            swipeToSlide: true,
            // slidesToScroll: 1,
            nextArrow: <ArrowNext />,
            prevArrow: <ArrowPrev />,
        };
        const { auth } = this.props

        return (
            < Fragment >
                {/* <div class={this.props.isLoading ? "arrange-page-lottie-appear" : "arrange-page-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <DesktopNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />


                {/* <div class={this.props.isLoading ? "arrange-page-section-disappear" : null}> */}
                <div>
                    <div class="arrange-page-whole-page-layout">
                        <OfflineLabel />
                        <div class="color-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="container-fluid small-header">
                                        <div class="row py-4">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-4 top-back-4">
                                                <img
                                                    onClick={this.handleBack}
                                                    src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-sm-8 col-8 upper-text-4">
                                                <h2>Arrange at {this.props.location.state.hub.name}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="body-right-4 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <div class="arrange-invite-your-buddies-text">
                                            <div class="text-top text-center blue pt-5 pb-3">
                                                <h3>Arrange at {this.props.location.state.hub.name}</h3>
                                            </div>
                                            <div class="text-center">
                                                <strong class="blue">Invite your buddies to go out!!</strong>
                                            </div>
                                        </div>

                                        <Modal visible={this.state.timeModalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                                                    <div class="arrange-page-timepicker-box">
                                                        <div class="timepicker-title">Choose the Hour</div>
                                                        <img
                                                            onClick={() => this.closeModal()}
                                                            class="arrange-timepicker-times-modal-size"
                                                            src={require("../../../assets/layout/homepage svg/times.svg")}
                                                            alt=""
                                                        />
                                                        {/* <div class="arrange-page-time-box">
                                                            <div class="arrange-page-time-number">
                                                                {this.state.hour ?
                                                                    <strong onClick={this.backToHours}>{this.state.hour}</strong>
                                                                    :
                                                                    <strong>00</strong>
                                                                }
                                                                <strong>  :  </strong>
                                                                {this.state.minute ?
                                                                    <strong>{this.state.minute}</strong>
                                                                    :
                                                                    <strong>00</strong>
                                                                }
                                                            </div>
                                                        </div> */}
                                                        {this.props.location.state.hub.hub_rules ?
                                                            <div class="arrange-page-hubs-rules">
                                                                <u><b>Must Know:</b></u>&nbsp;
                                                                {this.props.location.state.hub.hub_rules}
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                        <div class="arrange-timepicker-layout">
                                                            <center>
                                                                <TimePickerComp
                                                                    data={{
                                                                        selected_hour: this.state.selected_hour,
                                                                        hour: this.state.hour,
                                                                        minute: this.state.minute,
                                                                        time: this.state.time,
                                                                        sellectedMinuteMode: this.sellectedMinuteMode.bind(this),
                                                                        sellectedHourMode: this.sellectedHourMode.bind(this),
                                                                        closeModal: this.closeModal.bind(this),
                                                                    }}
                                                                />
                                                            </center>
                                                        </div>
                                                        {this.state.selected_hour ?
                                                            <span>
                                                                <div class="timepicker-back-button-size">
                                                                    <div
                                                                        onClick={this.backToHours}
                                                                        class="timepicker-back-button">
                                                                        Back to Hours
                                                                    </div>
                                                                </div>
                                                                <div class="timepicker-subtitle-minutes"><u>Press ON the Minutes you want to Select!</u></div>
                                                            </span>
                                                            :
                                                            <div class="timepicker-subtitle-hour"><u>Press ON the Hour you want to Select!</u></div>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </Modal>

                                        <div>
                                            {this.props.buddies.length === 0 ?
                                                <div class="arrange-no-buddies-mika-layout">
                                                    <Link to='/search-buddies'>
                                                        <div class="arrange-no-buddies-image">
                                                            <img
                                                                class="arrange-no-buddies-created"
                                                                src={require("../../../assets/layout/mika/dont-know-grey.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p class="arrange-no-buddies-text">
                                                            You don't have any Buddies to invite.
                                                            <br />
                                                            <b>Add your Buddies Now</b>

                                                        </p>
                                                    </Link>
                                                </div>
                                                :
                                                <div>
                                                    <Modal visible={this.state.gagModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                                                        <GagModal
                                                            deleteGag={this.props.deleteGag}
                                                            data={{ gag_id: this.state.gag_id, gag_name: this.state.gag_name, gag_persons: this.state.gag_persons, closeModal: this.closeModal.bind(this) }}
                                                        />
                                                    </Modal>
                                                    <div class="row py-3">
                                                        <div class="plus-text-4 text-left py-2">
                                                            <Link to="/gag">
                                                                <div class="plus-icon-4">
                                                                    <img class="arrange-create-gang-icon" src={require("../../../assets/arrange-meeting/svg/Icon-plus.svg")} alt="" />
                                                                </div>
                                                                <span class="blue">&nbsp;Create a new Gang</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div class="arrange-page-buddies-slider" >


                                                        <Slider {...settings} >
                                                            {this.props.gags.map(gag => (
                                                                <div key={gag.id}>
                                                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 text-center item-div-4" >
                                                                        <div class="item-img-4">
                                                                            {/* <Link to={{ pathname: '/update-gag', state: { gag: gag } }}> */}
                                                                            <img
                                                                                class="arrange-buddy-img"
                                                                                onClick={this.openModal(gag.name, gag.id, gag.persons)}
                                                                                src={require("../../../assets/arrange-meeting/svg/Icon-p.svg")}
                                                                                alt=""
                                                                            />
                                                                            {/* </Link> */}
                                                                        </div>
                                                                        <p class="item-text-4 blue pt-1">{gag.name}</p>
                                                                        {this.state.gag_invitations.includes(gag.id) ?
                                                                            <div>
                                                                                <div
                                                                                    onClick={this.updateGagInvitationList(gag.id)}
                                                                                    class="btn btn-danger-arrange-page">Unselect</div>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <div
                                                                                    onClick={this.updateGagInvitationList(gag.id)}
                                                                                    class="btn btn-success-arrange-page">Select</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {this.props.buddies.map(buddy => (

                                                                <div key={buddy.id}>
                                                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 text-center item-div-4" >
                                                                        <div class="item-img-4">
                                                                            <Link to={{ pathname: `/user-profile/${buddy.user}/profile` }}>
                                                                                {buddy.profile_picture ?
                                                                                    <img class="arrange-buddy-img" src={buddy.profile_picture} alt="" />
                                                                                    :
                                                                                    <img class="arrange-buddy-img" src={require("../../../assets/authentication/svg/account.svg")} />
                                                                                }
                                                                            </Link>
                                                                            {/* <img
                                                                            class="arrange-buddy-img"
                                                                            src={require("../../../assets/arrange-meeting/svg/Icon-p.svg")}
                                                                            alt=""
                                                                        /> */}
                                                                        </div>
                                                                        <p class="item-text-4-username blue pt-1">{buddy.username}</p>
                                                                        {this.state.invitations.includes(buddy.id) ?
                                                                            <div>
                                                                                <div
                                                                                    onClick={this.updateInvitationList(buddy.id)}
                                                                                    class="btn btn-danger-arrange-page">Unselect</div>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <div
                                                                                    onClick={this.updateInvitationList(buddy.id)}
                                                                                    class="btn btn-success-arrange-page">Select</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            ))}
                                                        </Slider>


                                                    </div>
                                                    <br />
                                                </div>
                                            }
                                            <div class="row py-5 bottom-part-4">
                                                <div class="col-xl-3"></div>
                                                <div class="col-xl-6 col-lg-12 big-box-4 pb-3">
                                                    <div class="starbucks-4">
                                                        {this.props.location.state.hub.logo ?
                                                            <img
                                                                src={this.props.location.state.hub.logo}
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                src={require("../../../assets/hub/hub-page/svg/location-red.png")}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>

                                                    <div class="box-4">
                                                        <div class="btn btn-primary-arrange-page mr-3 box-4-btn">
                                                            {this.state.show_datepicker ?
                                                                <DatePicker
                                                                    selected={this.state.date}
                                                                    todayButton="Press for Today"
                                                                    onChange={this.updateDateField.bind(this)}
                                                                    minDate={new Date()}
                                                                    name="date"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    customInput={<ExampleCustomInput />}
                                                                    withPortal
                                                                    inline
                                                                />
                                                                :
                                                                <span onClick={this.setDatepickerMode()}>
                                                                    <DatePicker
                                                                        selected={this.state.date}
                                                                        customInput={<ExampleCustomInput />}
                                                                    />
                                                                </span>
                                                            }
                                                        </div>
                                                        <div
                                                            onClick={() => this.openTimeModal()}
                                                            class="btn btn-primary-arrange-page ml-3 box-4-btn1"
                                                        >
                                                            {this.state.time}
                                                        </div>
                                                    </div>

                                                    <div class="arrange-buddies-note-section">
                                                        <div class="form-group mt-4">
                                                            <textarea
                                                                class="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                placeholder={buddies_note === "-" ? "Write something to your Buddies..." : { buddies_note }}
                                                                rows="2"
                                                                name="buddies_note"
                                                                onChange={this.onChange}
                                                            ></textarea>
                                                        </div>
                                                        {/* {this.props.location.state.hub.reservation_acceptance ?
                                                            <label class="arrange-reservation-available-text" for="exampleFormControlTextarea1">
                                                                <strong >Once you are ready, you will be able to request a Reservation at the Moments of Magic Page!</strong>
                                                            </label>
                                                            :
                                                            <label class="text-center arrange-reservation-available-text" for="exampleFormControlTextarea1">Reservations not available to request!</label>
                                                        } */}

                                                    </div>

                                                    <div
                                                        class="btn btn-info item-btn-4 arrange-btn-4"
                                                        onClick={this.onMeetingSubmit}
                                                    >
                                                        Send Invitations
                                                    </div>
                                                </div>
                                                <div class="col-xl-3"></div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    buddies: state.buddies.buddies,
    hub: state.hub.hub,
    gags: state.buddies.gags,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { getBuddies, getGags, searchBuddy, getArrangeMeetingHub, createMeeting, createAlert, getNotificationsStatus, getGagMembers, deleteGag })(ArrangePage);