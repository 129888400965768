export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_TOKEN_SUCCESS = "LOGIN_TOKEN_SUCCESS";
export const CHECK_DASHBOARD_ACCESS = "CHECK_DASHBOARD_ACCESS";

export const LOADING_PAGE = "LOADING_PAGE";
export const PAGE_LOADED = "PAGE_LOADED";
export const NO_INTERNET_CONNECTION = "NO_INTERNET_CONNECTION";

export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOCALY_LOADED = "USER_LOCALY_LOADED"
export const ACCEPT_HUBS_TERMS = "ACCEPT_HUBS_TERMS"

export const SUBMIT_HUB_CONTACT_FORM = "SUBMIT_HUB_CONTACT_FORM";
// NOT USED CURRENTLY
export const HUB_USER_LOADING = "HUB_USER_LOADING";
export const HUB_USER_LOADED = "HUB_USER_LOADED";
export const HUB_AUTH_ERROR = "HUB_AUTH_ERROR";
export const HUB_LOGIN_SUCCESS = "HUB_LOGIN_SUCCESS";
export const HUB_LOGIN_FAIL = "HUB_LOGIN_FAIL";
export const HUB_LOGOUT_SUCCESS = "HUB_LOGOUT_SUCCESS";
export const HUB_REGISTER_SUCCESS = "HUB_REGISTER_SUCCESS";
export const HUB_REGISTER_FAIL = "HUB_REGISTER_FAIL";
// END NOT USED

export const GET_SEARCH_USERS = "GET_SEARCH_USERS";
export const SEARCH_USERS_LOCALLY_LOADED = "SEARCH_USERS_LOCALLY_LOADED";
export const GET_SEARCHED_USERS_QUERY = "GET_SEARCHED_USERS_QUERY";
export const GET_BUDDIES = "GET_BUDDIES";
export const BUDDIES_LOCALLY_LOADED = "BUDDIES_LOCALLY_LOADED";
export const GET_SEARCHED_BUDDY_QUERY = "GET_SEARCHED_BUDDY_QUERY";
export const GET_GAGS = "GET_GAGS";
export const GET_GAG_MEMBERS = "GET_GAG_MEMBERS";
export const DELETE_GAG = "DELETE_GAG";
export const GAGS_LOCALLY_LOADED = "GAGS_LOCALLY_LOADED";
export const CREATE_GAG = "CREATE_GAG";
export const CREATE_BUDDY_REQUEST = "CREATE_BUDDY_REQUEST";
export const ANSWER_BUDDY_REQUEST = "ANSWER_BUDDY_REQUEST";
export const CANCEL_BUDDY_REQUEST = "CANCEL_BUDDY_REQUEST";
export const REMOVE_BUDDY = "REMOVE_BUDDY";
export const CLEAR_SEARCH_USERS = "CLEAR_SEARCH_USERS";



export const GET_REQUEST_RESERVATION_HUBS = "GET_REQUEST_RESERVATION_HUBS";
export const GET_REGISTER_ALL_HUBS = "GET_REGISTER_ALL_HUBS";
export const LOAD_HOMEPAGE_ALL_HUBS = "LOAD_HOMEPAGE_ALL_HUBS";
export const GET_HOMEPAGE_ALL_HUBS = "GET_HOMEPAGE_ALL_HUBS";
export const GET_HOMEPAGE_SELLECTED_HUBS = "GET_HOMEPAGE_SELLECTED_HUBS";
export const HUBS_LOCALY_LOADED = "HUBS_LOCALY_LOADED";
export const GET_SELLECTED_HUB = "GET_SELLECTED_HUB";
export const GET_SEARCHED_HUBS = "GET_SEARCHED_HUBS";
export const GET_ARRANGE_MEETING_HUB = "GET_ARRANGE_MEETING_HUB";
export const GET_MEMORY_UPLOAD_HUB_LIST = "GET_HOMEPAGE_ALL_HUBS";
export const MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED = "MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED";
export const GET_USER_HUB_LIST = "GET_USER_HUB_LIST";
export const USER_HUB_LIST_LOCALLY_LOADED = "USER_HUB_LIST_LOCALLY_LOADED";
export const GET_HUB_PAGE = "GET_HUB_PAGE";
export const HUB_PAGE_LOCALY_LOADED = "HUB_PAGE_LOCALY_LOADED";
export const UPDATE_HUB = "UPDATE_HUB";
export const SET_HUB_TRAFFIC = "SET_HUB_TRAFFIC";
export const GET_HUB_ACTIVITIES = "GET_HUB_ACTIVITIES";
export const HUB_ACTIVITIES_LOCALY_LOADED = "HUB_ACTIVITIES_LOCALY_LOADED";
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const REMOVE_ACTIVITY = "REMOVE_ACTIVITY";
export const GET_HUB_WORKING_DAYS = "GET_HUB_WORKING_DAYS";
export const WORKING_DAYS_LOCALY_LOADED = "WORKING_DAYS_LOCALY_LOADED";
export const UPDATE_WORKING_DAY = "UPDATE_WORKING_DAY";
export const GET_HUB_METRICS = "GET_HUB_METRICS";
export const HUBS_METRICS_LOCALY_LOADED = "HUBS_METRICS_LOCALY_LOADED";
export const ACTIVATE_HUB_ACCOUNT = "ACTIVATE_HUB_ACCOUNT";
export const UPLOAD_HUB_PROMO_PHOTO = "UPLOAD_HUB_PROMO_PHOTO";
export const CREATE_PROMO_REACTION = "CREATE_PROMO_REACTION";
export const FULL_SCREEN_PROMO = "FULL_SCREEN_PROMO";
export const GET_SELECT_HUB_LIST = "GET_SELECT_HUB_LIST";
export const GET_SELECT_HUB_LIST_LOCALLY_LOADED = "GET_SELECT_HUB_LIST_LOCALLY_LOADED";
export const GET_HUB_AMBASSANDORS_PROFILE = "GET_HUB_AMBASSANDORS_PROFILE";
export const CREATE_HUB_PROSPECT = "CREATE_HUB_PROSPECT";
export const GET_NEW_CREATED_HUB = "GET_NEW_CREATED_HUB";
export const HUB_MARKER_CREATED = "HUB_MARKER_CREATED";
export const HUB_FETCHED_MARKERS_CREATED = "HUB_FETCHED_MARKERS_CREATED";


export const CREATE_SUGGESTION_HUB_PROSPECT = "CREATE_SUGGESTION_HUB_PROSPECT";
export const CREATE_NEW_MAP_PLACE = "CREATE_NEW_MAP_PLACE";
export const USER_SET_MAP_PHOTO = "USER_SET_MAP_PHOTO";
export const CHANGE_HUB_IS_OVERNIGHT = "CHANGE_HUB_IS_OVERNIGHT";
export const GET_HUB_MENU_QR = "GET_HUB_MENU_QR";
export const CREATE_HUB_MENU_QR = "CREATE_HUB_MENU_QR";
export const CHANGE_HUB_CATALOG_INDEX = "CHANGE_HUB_CATALOG_INDEX";


export const GET_PENDING_MEETINGS = "GET_PENDING_MEETINGS";
export const PENDING_MEETINGS_LOCALLY_LOADED = "PENDING_MEETINGS_LOCALLY_LOADED";
export const GET_ACCEPTED_MEETINGS = "GET_ACCEPTED_MEETINGS";
export const GET_DECLINED_MEETINGS = "GET_DECLINED_MEETINGS";
export const GET_CANCELLED_MEETINGS = "GET_CANCELLED_MEETINGS";
export const CANCELLED_MEETINGS_LOCALLY_LOADED = "CANCELLED_MEETINGS_LOCALLY_LOADED";
export const GET_RESERVATION_MEETINGS = "GET_RESERVATION_MEETINGS";
export const RESERVATION_MEETINGS_LOCALLY_LOADED = "RESERVATION_MEETINGS_LOCALLY_LOADED";
export const GET_MEETING_INVITATIONS = "GET_MEETING_INVITATIONS";
export const CANCEL_MEETING = "CANCEL_MEETING";
export const CANCEL_RESERVATION_MEETING = "CANCEL_RESERVATION_MEETING";
export const CREATE_MEETING = "CREATE_MEETING";
export const UPDATE_MEETING = "UPDATE_MEETING";
export const ANSWER_INVITATION = "ANSWER_INVITATION";
export const CREATE_INVITATION = "CREATE_INVITATION";


export const GET_DATES_RESERVATION_HOURS = "GET_DATES_RESERVATION_HOURS";
export const ACCEPT_HUB_RESERVATION = "ACCEPT_HUB_RESERVATION";
export const DECLINE_HUB_RESERVATION = "DECLINE_HUB_RESERVATION";
export const GET_PENDING_HUB_RESERVATIONS = "GET_PENDING_HUB_RESERVATIONS";
export const PENDING_HUB_RESERVATIONS_LOCALY_LOADED = "PENDING_HUB_RESERVATIONS_LOCALY_LOADED";
export const USER_CREATE_NEW_RESERVATION = "USER_CREATE_NEW_RESERVATION";
export const REQUEST_RESERVATION = "REQUEST_RESERVATION";
export const GET_PENDING_RESERVATIONS = "GET_PENDING_RESERVATIONS";
export const PENDING_RESERVATIONS_LOCALY_LOADED = "PENDING_RESERVATIONS_LOCALY_LOADED";
export const GET_BOOKED_RESERVATIONS = "GET_BOOKED_RESERVATIONS";
export const BOOKED_RESERVATIONS_LOCALY_LOADED = "BOOKED_RESERVATIONS_LOCALY_LOADED";
export const GET_ALL_CANCELLED_RESERVATIONS = "GET_ALL_CANCELLED_RESERVATIONS";
export const CANCELLED_RESERVATIONS_LOCALY_LOADED = "CANCELLED_RESERVATIONS_LOCALY_LOADED";
export const GET_DATES_BOOKED_RESERVATIONS = "GET_DATES_BOOKED_RESERVATIONS";
export const GET_DATES_BOOKED_RESERVATIONS_CANCELLED = "GET_DATES_BOOKED_RESERVATIONS_CANCELLED";
export const ANSWER_RESERVATION = "ANSWER_RESERVATION";
export const GET_HUB_FREE_TABLES = "GET_HUB_FREE_TABLES";
export const GET_HUB_ALL_FREE_TABLES = "GET_HUB_ALL_FREE_TABLES";
export const UPDATE_RESERVATION_TABLE = "UPDATE_RESERVATION_TABLE";
export const EMPTY_RESERVATION_TABLE = "EMPTY_RESERVATION_TABLE";
export const EMPTY_HUB_RESERVATION_TABLE = "EMPTY_HUB_RESERVATION_TABLE";
export const UPDATE_RESERVATION_NOTE = "UPDATE_RESERVATION_NOTE";
export const INCREASE_RESERVATION_ARRIVED_PERSONS = "INCREASE_RESERVATION_ARRIVED_PERSONS";
export const DECREASE_RESERVATION_ARRIVED_PERSONS = "DECREASE_RESERVATION_ARRIVED_PERSONS";
export const GET_ADD_RESERVATION_PROFILE = "GET_ADD_RESERVATION_PROFILE";
export const HUB_CREATE_NEW_RESERVATION = "HUB_CREATE_NEW_RESERVATION";
export const GET_DATES_BOOKED_HUB_RESERVATIONS = "GET_DATES_BOOKED_HUB_RESERVATIONS";
export const INCREASE_HUB_RESERVATION_ARRIVED_PERSONS = "INCREASE_HUB_RESERVATION_ARRIVED_PERSONS";
export const DECREASE_HUB_RESERVATION_ARRIVED_PERSONS = "DECREASE_HUB_RESERVATION_ARRIVED_PERSONS";
export const UPDATE_HUB_RESERVATION_TABLE = "UPDATE_HUB_RESERVATION_TABLE";
export const CANCEL_HUB_RESERVATION = "CANCEL_HUB_RESERVATION";
export const GET_USER_HUB_RESERVATIONS = "GET_USER_HUB_RESERVATIONS";
export const USER_HUB_RESERVATIONS_LOCALY_LOADED = "USER_HUB_RESERVATIONS_LOCALY_LOADED";
export const GET_HUB_RESERVATION_SHARES = "GET_HUB_RESERVATION_SHARES";
export const SHARE_HUB_RESERVATION = "SHARE_HUB_RESERVATION";
export const USER_CANCEL_HUB_RESERVATION = "USER_CANCEL_HUB_RESERVATION";
export const GET_ALL_HUB_RESERVATIONS_CANCELLED = "GET_ALL_HUB_RESERVATIONS_CANCELLED";
export const ALL_HUB_RESERVATIONS_CANCELLED_LOCALY_LOADED = "ALL_HUB_RESERVATIONS_CANCELLED_LOCALY_LOADED";
export const INCREASE_TABLE_PERSONS = "INCREASE_TABLE_PERSONS";
export const DECREASE_TABLE_PERSONS = "DECREASE_TABLE_PERSONS";
export const CREATE_TABLE = "CREATE_TABLE";
export const SET_TABLE_STATUS = "SET_TABLE_STATUS";
export const SET_HUB_RESERVATION_TABLE = "SET_HUB_RESERVATION_TABLE";
export const SET_RESERVATION_TABLE = "SET_RESERVATION_TABLE";
export const SET_TABLE_PERSONS = "SET_TABLE_PERSONS";
export const UPDATE_HUB_RESERVATION_NOTE = "UPDATE_HUB_RESERVATION_NOTE";
export const WORKING_DAY_TO_CLOSE = "WORKING_DAY_TO_CLOSE";
export const SET_WORKING_DAY_HOURS = "SET_WORKING_DAY_HOURS";
export const UPLOAD_HUB_LOGO = "UPLOAD_HUB_LOGO";
export const UPLOAD_HUB_COVER_PHOTO = "UPLOAD_HUB_COVER_PHOTO";
export const SET_HUB_NAME = "SET_HUB_NAME";
export const SET_HUB_CATEGORY = "SET_HUB_CATEGORY";
export const SET_HUB_MAP_ICON = "SET_HUB_MAP_ICON";

export const SET_HUB_WFI_CODE = "SET_HUB_WFI_CODE";
export const SET_HUB_TELEPHONE = "SET_HUB_TELEPHONE";
export const SET_HUB_WEBSITE = "SET_HUB_WEBSITE";
export const SET_HUB_FACEBOOK = "SET_HUB_FACEBOOK";
export const SET_HUB_INSTAGRAM = "SET_HUB_INSTAGRAM";
export const SET_HUB_RESERATIONS = "SET_HUB_RESERATIONS";
export const SET_HUB_APPOINTMENTS = "SET_HUB_APPOINTMENTS";
export const SET_HUB_ONLINE_SHOP = "SET_HUB_ONLINE_SHOP";
export const GET_HUB_CATALOGS = "GET_HUB_CATALOGS";
export const UPLOAD_HUB_CATALOGE = "UPLOAD_HUB_CATALOGE";
export const REMOVE_HUB_CATALOGE = "REMOVE_HUB_CATALOGE";
export const SET_HUB_RESERATION_HOURS = "SET_HUB_RESERATION_HOURS";
export const SET_HUB_APPOINTMENT_HOURS = "SET_HUB_APPOINTMENT_HOURS";
export const CREATE_NEW_RESERATION_HOUR = "CREATE_NEW_RESERATION_HOUR";
export const REMOVE_RESERATION_HOUR = "REMOVE_RESERATION_HOUR";
export const CREATE_NEW_APPOINTMENT_HOUR = "CREATE_NEW_APPOINTMENT_HOUR";
export const REMOVE_APPOINTMENT_HOUR = "REMOVE_APPOINTMENT_HOUR";
export const SET_HUB_SALES = "SET_HUB_SALES";
export const GET_HUB_USERS_WITH_ACCESS = "GET_HUB_USERS_WITH_ACCESS";



export const GET_HOMEPAGE_MEMORIES = "GET_HOMEPAGE_MEMORIES";
export const MEMORIES_LOCALY_LOADED = "MEMORIES_LOCALY_LOADED";
export const GET_HOMEPAGE_SUGGESTED_MEMORIES = "GET_HOMEPAGE_SUGGESTED_MEMORIES";
export const SUGGESTED_MEMORIES_LOCALY_LOADED = "SUGGESTED_MEMORIES_LOCALY_LOADED";
export const GET_MEMORY_TAGS = "GET_MEMORY_TAGS";
export const GET_HOMEPAGE_PROMOS = "GET_HOMEPAGE_PROMOS";
export const PROMOS_LOCALY_LOADED = "PROMOS_LOCALY_LOADED";
export const GET_FULLSCREEN_MEMORY = "GET_FULLSCREEN_MEMORY";
export const GET_MEMORY_IMPRESSIONS = "GET_MEMORY_IMPRESSIONS";
export const GET_HUB_MEMORIES = "GET_HUB_MEMORIES";
export const GET_USER_MEMORIES = "GET_USER_MEMORIES";
export const GET_MY_MEMORIES = "GET_MY_MEMORIES";
export const MY_MEMORIES_LOCALY_LOADED = "MY_MEMORIES_LOCALY_LOADED";
export const USER_MEMORIES_LOCALY_LOADED = "USER_MEMORIES_LOCALY_LOADED";
export const UPLOAD_MEMORY = "UPLOAD_MEMORY";
export const GET_HUB_APPROVED_MEMORIES = "GET_HUB_APPROVED_MEMORIES";
export const APPROVED_MEMORIES_LOCALY_LOADED = "APPROVED_MEMORIES_LOCALY_LOADED";
export const GET_HUB_DECLINED_MEMORIES = "GET_HUB_DECLINED_MEMORIES";
export const DECLINED_MEMORIES_LOCALY_LOADED = "DECLINED_MEMORIES_LOCALY_LOADED";
export const GET_HUB_UNREVIEWED_MEMORIES = "GET_HUB_UNREVIEWED_MEMORIES";
export const UNREVIEWS_MEMORIES_LOCALY_LOADED = "UNREVIEWS_MEMORIES_LOCALY_LOADED";
export const REVIEW_MEMORY = "REVIEW_MEMORY";
export const CREATE_MEMORY_REACTION = "CREATE_MEMORY_REACTION";
export const DELETE_REACTION = "DELETE_REACTION";
export const DELETE_MEMORY = "DELETE_MEMORY";
export const ADD_BUDDY_TAG = "ADD_BUDDY_TAG";
export const REMOVE_BUDDY_TAG = "REMOVE_BUDDY_TAG";
export const REMOVE_MY_TAG = "REMOVE_MY_TAG";
export const ACCEPT_MEMORY_TO_PROFILE = "ACCEPT_MEMORY_TO_PROFILE";
export const DECLINE_MEMORY_TO_PROFILE = "DECLINE_MEMORY_TO_PROFILE";
export const CREATE_MEMORY_VIEW = "CREATE_MEMORY_VIEW";
export const CLEAR_HUB_MEMORIES = "CLEAR_HUB_MEMORIES";
export const CLEAR_MEMORY_TAGS = "CLEAR_MEMORY_TAGS";


export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const NOTIFICATIONS_LOCALY_LOADED = "NOTIFICATIONS_LOCALY_LOADED";
export const GET_NOTIFICATIONS_STATUS = "GET_NOTIFICATIONS_STATUS";
export const GET_HUB_PENDING_NOTIFICATIONS_STATUS = "GET_HUB_PENDING_NOTIFICATIONS_STATUS";
export const GET_HUB_CANCELLATIONS_NOTIFICATIONS_STATUS = "GET_HUB_CANCELLATIONS_NOTIFICATIONS_STATUS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const SEND_PUSH_NOTIFICATION_INFO = "SEND_PUSH_NOTIFICATION_INFO";
export const GET_EMAIL_NOTIFICATIONS_STATUS = "GET_EMAIL_NOTIFICATIONS_STATUS";
export const CHANGE_EMAIL_NOTIFICATIONS_STATUS = "CHANGE_EMAIL_NOTIFICATIONS_STATUS";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_PROFILE_HUBS_ACCESS = "GET_PROFILE_HUBS_ACCESS";
export const GET_OTHER_USER_PROFILE = "GET_OTHER_USER_PROFILE";
export const USER_PROFILE_LOCALY_LOADED = "USER_PROFILE_LOCALY_LOADED";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const UPDATE_PROFILE_FULLNAME = "UPDATE_PROFILE_FULLNAME";
export const GET_PROFILE_BUDDIES = "GET_PROFILE_BUDDIES";
export const GET_PROFILE_BUDDIES_LOCALLY_LOADED = "GET_PROFILE_BUDDIES_LOCALLY_LOADED";
export const GET_PROFILE_HUB_LIST = "GET_PROFILE_HUB_LIST";
export const GET_PROFILE_HUB_LIST_LOCALLY_LOADED = "GET_PROFILE_HUB_LIST_LOCALLY_LOADED";
export const GET_PROFILE_GAGS = "GET_PROFILE_GAGS";
export const GET_PROFILE_GAGS_LOCALY_LOADED = "GET_PROFILE_GAGS_LOCALY_LOADED";
export const SCAN_QR_CODE = "SCAN_QR_CODE";
export const SWITCH_TO_HUB_ACCOUNT = "SWITCH_TO_HUB_ACCOUNT";
export const SWITCH_TO_PROFILE_ACCOUNT = "SWITCH_TO_PROFILE_ACCOUNT";
export const SET_MY_MAP_LAYOUT = "SET_MY_MAP_LAYOUT";

export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";

export const CREATE_ALERT = "CREATE_ALERT";
export const GET_ERRORS = "GET_ERRORS";

export const CLEAR_BUDDIES = "CLEAR_BUDDIES";
export const CLEAR_HUBS = "CLEAR_HUBS";
export const CLEAR_MEETING = "CLEAR_MEMORY";
export const CLEAR_MEMORY = "CLEAR_MEMORY";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_RESERVATION = "CLEAR_RESERVATION";


export const GET_AMBASSANDOR_PROFILE = "GET_AMBASSANDOR_PROFILE";
export const GET_COMPANY_AMBASSANDOR_PROFILE = "GET_COMPANY_AMBASSANDOR_PROFILE";

export const UPDATE_HUB_AMBASSANDOR = "UPDATE_HUB_AMBASSANDOR";
export const GET_HUB_JOBS = "GET_HUB_JOBS";
export const CREATE_HUB_JOB = "CREATE_HUB_JOB";
export const UPDATE_HUB_JOB_ACTIVATION = "UPDATE_HUB_JOB_ACTIVATION";
export const CREATE_AMBASSANDOR_HUB_JOB = "CREATE_AMBASSANDOR_HUB_JOB";
export const DELETE_HUB_JOB = "DELETE_HUB_JOB";
export const GET_USER_AMBASSANDOR = "GET_USER_AMBASSANDOR";
export const UPDATE_USER_AMBASSANDOR = "UPDATE_USER_AMBASSANDOR";
export const CHECK_USER_IS_AMBASSANDOR = "CHECK_USER_IS_AMBASSANDOR";

// hubst3r dashboard actions
export const GET_COMPANY_STATS = "GET_COMPANY_STATS";
export const CREATE_NEW_HUB = "CREATE_NEW_HUB";
export const CREATE_NEW_PROSPECT = "CREATE_NEW_PROSPECT";
export const CONVERT_USER_TO_HUB_STAFF = "CONVERT_USER_TO_HUB_STAFF";
export const DASHBOARD_GET_ALL_HUBS = "DASHBOARD_GET_ALL_HUBS";
export const DASHBOARD_GET_ALL_PROSPECTS = "DASHBOARD_GET_ALL_PROSPECTS";
export const DASHBOARD_GET_ALL_FREEZE_PROSPECTS = "DASHBOARD_GET_ALL_FREEZE_PROSPECTS";
export const DASHBOARD_GET_ALL_SALES_COMMUNICATIONS = "DASHBOARD_GET_ALL_SALES_COMMUNICATIONS";
export const DASHBOARD_GET_ALL_SUPPORT_COMMUNICATIONS = "DASHBOARD_GET_ALL_SUPPORT_COMMUNICATIONS";
export const CREATE_NEW_SALES_COMMUNICATION = "CREATE_NEW_SALES_COMMUNICATION";
export const CREATE_NEW_SUPPORT_COMMUNICATION = "CREATE_NEW_SUPPORT_COMMUNICATION";
export const DASHBOARD_GET_ALL_TEAM_TASKS = "DASHBOARD_GET_ALL_TEAM_TASKS";
export const DASHBOARD_GET_TEAM_QUORTER_GOALS = "DASHBOARD_GET_TEAM_QUORTER_GOALS";
export const CREATE_NEW_VISIT = "CREATE_NEW_VISIT";
export const VISITED_USER_REGISTERED = "VISITED_USER_REGISTERED";
export const CREATE_ACTIVE_USER_VISIT = "CREATE_ACTIVE_USER_VISIT";
export const DASHBOARD_GET_LOCATIONS = "DASHBOARD_GET_LOCATIONS";
export const CREATE_HUB_AUTO = "CREATE_HUB_AUTO";
export const GET_HUBS_WITH_DATA = "GET_HUBS_WITH_DATA";
export const DEACTIVATE_GOOGLE_HUBS = "DEACTIVATE_GOOGLE_HUBS";
export const ACTIVATE_GOOGLE_HUBS = "ACTIVATE_GOOGLE_HUBS";

// hub personel
export const GET_ENTERTAINEMENT_JOBS = "GET_ENTERTAINEMENT_JOBS";
export const GET_HUB_PERSONEL = "GET_HUB_PERSONEL";
export const CREATE_HUB_EMPLOYEE = "CREATE_HUB_EMPLOYEE";
export const START_EMPLOYEE_SHIFT = "START_EMPLOYEE_SHIFT";
export const END_EMPLOYEE_SHIFT = "END_EMPLOYEE_SHIFT";
export const GET_HUB_PERSONEL_SHIFTS = "GET_HUB_PERSONEL_SHIFTS";
export const REMOVE_PERSONEL_ACCESS = "REMOVE_PERSONEL_ACCESS";
export const SEARCHING_USER = "SEARCHING_USER";
export const FINISH_SEARCHING = "FINISH_SEARCHING";
export const CHANGE_EMPLOYEE_ACCESS = "CHANGE_EMPLOYEE_ACCESS";
export const REMOVE_EMPLOYEE_ACCESS = "REMOVE_EMPLOYEE_ACCESS";
export const GET_MY_EMPLOYEE_ACCESS = "GET_MY_EMPLOYEE_ACCESS";



export const GET_HUB_MENU_PAGES = "GET_HUB_MENU_PAGES";
export const HUB_MENU_PAGES_LOCALY_LOADED = "HUB_MENU_PAGES_LOCALY_LOADED";
export const GET_USER_HUB_CATALOGS = "GET_USER_HUB_CATALOGS";
export const SET_MENU_PAGE_DESCRIPTION = "SET_MENU_PAGE_DESCRIPTION";
export const SET_MENU_PAGE_PRICE = "SET_MENU_PAGE_PRICE";


export const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const CONVERSATIONS_LOCALY_LOADED = "CONVERSATIONS_LOCALY_LOADED";
export const CREATE_NEW_MESSAGE = "CREATE_NEW_MESSAGE";
export const GET_THE_CONVERSATION = "GET_THE_CONVERSATION";
export const GET_CONVERSATION_MESSAGES = "GET_CONVERSATION_MESSAGES";
export const LISTENED_THE_MESSAGE = "LISTENED_THE_MESSAGE";
export const UPDATE_CONVERSATION_BACKGROUND = "UPDATE_CONVERSATION_BACKGROUND";
export const UPDATE_CONVERSATION_MY_MESSAGES_COLOR = "UPDATE_CONVERSATION_MY_MESSAGES_COLOR";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";



export const GET_MAP_NEARBY_PLACES = "GET_MAP_NEARBY_PLACES";
export const GET_MAP_MEMORIES = "GET_MAP_MEMORIES";
export const GET_MAP_USER_WITH_MEMORIES = "GET_MAP_USER_WITH_MEMORIES";
export const UPLOADING_MEMORY = "UPLOADING_MEMORY";
export const FINISH_UPLOADING_MEMORY = "FINISH_UPLOADING_MEMORY";


export const GET_DATES_APPOINTMENT_HOURS = "GET_DATES_APPOINTMENT_HOURS";
export const HUB_CREATE_NEW_APPOINTMENT = "HUB_CREATE_NEW_APPOINTMENT";
export const GET_DATES_APPOINTMENTS = "GET_DATES_APPOINTMENTS";
export const UPDATE_APPOINTMENT_NOTE = "UPDATE_APPOINTMENT_NOTE";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const GET_PENDING_APPOINTMENTS = "GET_PENDING_APPOINTMENTS";
export const ANSWER_APPOINTMENT = "ANSWER_APPOINTMENT";
export const REQUEST_APPOINTMENT = "REQUEST_APPOINTMENT";
export const GET_USER_APPOINTMENTS = "GET_USER_APPOINTMENTS";
export const USER_CANCEL_APPOINTMENT = "USER_CANCEL_APPOINTMENT";



export const CLEAR_SHOP_FIELDS = "CLEAR_SHOP_FIELDS";
export const CHECK_SHOP_OWNER_STATUS = "CHECK_SHOP_OWNER_STATUS";
export const GET_SHOP_PRODUCTS = "GET_SHOP_PRODUCTS";
export const GET_SHOP_CATEGORIES = "GET_SHOP_CATEGORIES";
export const UPLOAD_PRODUCT_IMAGE = "UPLOAD_PRODUCT_IMAGE";
export const CREATE_NEW_SHOP_PRODUCT = "CREATE_NEW_SHOP_PRODUCT";
export const UPDATE_SHOP_PRODUCT = "CREATE_NEW_SHOP_PRODUCT";
export const GET_ALL_ATRIBUTES = "GET_ALL_ATRIBUTES";
export const GET_ALL_ATRIBUTES_CHOISES = "GET_ALL_ATRIBUTES_CHOISES";
export const GET_ALL_PRODUCT_VARIATIONS = "GET_ALL_PRODUCT_VARIATIONS";
export const GET_SHOPS_QR_CODE_PRODUCTS = "GET_SHOPS_QR_CODE_PRODUCTS";
export const GET_SHOPS_QR_CODE_PRODUCT_VARIATIONS = "GET_SHOPS_QR_CODE_PRODUCT_VARIATIONS";
export const GET_SHOP_QR_CODES = "GET_SHOP_QR_CODES";
export const GET_SHOP_QR_CODES_PRODUCTS = "GET_SHOP_QR_CODES_PRODUCTS";
export const REMOVE_PRODUCT_FROM_QR = "REMOVE_PRODUCT_FROM_QR";
export const INSERT_PRODUCT_TO_QR = "INSERT_PRODUCT_TO_QR";
export const GET_SHOPS_BARCODE_PRODUCTS = "GET_SHOPS_BARCODE_PRODUCTS";
export const GET_SHOPS_BARCODE_PRODUCT_VARIATIONS = "GET_SHOPS_BARCODE_PRODUCT_VARIATIONS";
export const CLEAR_BARCODE_DATA = "CLEAR_BARCODE_DATA";
export const CHECKED_BARCODE_PRODUCTS = "CHECKED_BARCODE_PRODUCTS";
export const GET_SHOP_BULK_PRODUCTS = "GET_SHOP_BULK_PRODUCTS";


export const GET_USER_PRODUCTS = "GET_USER_PRODUCTS";
export const GET_MAGAZINE_PRODUCTS = "GET_MAGAZINE_PRODUCTS";

export const GET_SHOPS_MAGAZINE_PRODUCT_VARIATIONS = "GET_MAGAZINE_PRODUCTS";
export const GET_SHOPS_MAGAZINE_PRODUCTS = "GET_MAGAZINE_PRODUCTS";
export const GET_SHOP_MAGAZINE_PRODUCTS = "GET_SHOP_MAGAZINE_PRODUCTS";
export const REMOVE_PRODUCT_FROM_MAGAZINE = "REMOVE_PRODUCT_FROM_MAGAZINE";
export const INSERT_PRODUCT_TO_MAGAZINE = "INSERT_PRODUCT_TO_MAGAZINE";
export const GET_USER_MAGAZINE_PRODUCTS = "GET_USER_MAGAZINE_PRODUCTS";
export const GET_MAGAZINE_COVER = "GET_MAGAZINE_COVER";
export const SHOP_LOGOUT_SUCCESS = "SHOP_LOGOUT_SUCCESS";
export const FETCHING_CATEGORIES = "FETCHING_CATEGORIES";
export const GET_SHOP_SHIPPING_CLASSES = "GET_SHOP_SHIPPING_CLASSES";
export const GET_SHOP_ALL_CHILD_CATEGORIES = "GET_SHOP_ALL_CHILD_CATEGORIES";
export const REMOVE_SHOP_ALL_CHILD_CATEGORIES = "REMOVE_SHOP_ALL_CHILD_CATEGORIES";
export const NEW_CATEGORY_CREATED = "NEW_CATEGORY_CREATED";




export const GET_SCARLETT_ANSWER = "GET_SCARLETT_ANSWER";
export const GET_SCARLETT_SESSION_ALL_MESSAGES = "GET_SCARLETT_SESSION_ALL_MESSAGES";
export const CREATE_MESSAGE_WITH_SCARLETT = "CREATE_MESSAGE_WITH_SCARLETT";



