import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import HubBottomNavbar from '../../../components/hub/layout/hubBottomNavabar';
import HubMobileTraffic from '../../../components/hub/layout/hubMobileTraffic';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import HubDesktopTraffic from '../../../components/hub/layout/hubDesktopTraffic';
import TablesModal from '../../../components/hub/booked-reservations/TablesModal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
import HubsTermsModal from '../../../components/hub/layout/HubsTermsModal';
import AreYouSure from '../../../components/user/layout/AreYouSure'
import { getHubPage } from '../../../store/actions/hub'
import {
    updateHubReservationNote,
    acceptHubReservationRequest,
    declineHubReservationRequest,
    getPendingReservations,
    getPendingHubReservations,
    getHubAllCancelledHubReservations,
    getAllCancelledReservations,
    acceptReservation,
    declineReservation,
    getHubFreeTables
} from '../../../store/actions/reservation'
import { getHubPendingNotificationsStatus, getHubCancellationsNotificationsStatus, registerPushNotificationDevice } from '../../../store/actions/notification'
import { createAlert } from '../../../store/actions/alerts'
// dropdown
// import Select from 'react-select'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/booked_page/booked-page.css"
// popups
import Modal from 'react-awesome-modal';

export class PendingReservationsPage extends Component {

    state = {
        photo: null,
        logo: "",
        note: "-",
        reservation_table: "",
        table_id: "",
        table_number: "",
        tablesModalVisible: false,
        areYouSureModalDeclineReservation: false,
        show_tables: [],
        openTab: [],
        requestsDeclined: [],
        requestsAccepted: [],
        requestes_answered: [],
        cancellationsTab: false,
        answered_reservations: [],

    }

    componentWillMount() {
        this.props.getHubPage();
        this.props.getPendingHubReservations()

        // this.props.getPendingReservations();
        // this.props.getHubFreeTables()
        // this.props.getHubCancellationsNotificationsStatus()
    }

    changeCancellationsTabMode = () => {
        const newTabMode = !this.state.cancellationsTab
        this.setState({
            cancellationsTab: newTabMode
        })
        if (newTabMode) {
            this.props.getHubAllCancelledHubReservations()
            this.props.getAllCancelledReservations()
        }
    }

    handleHubReservationTableChange(table_id, table_number) {
        console.log("hiiii")
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });


    acceptReservation = (id, reservation_table, meeting) => (e) => {
        const reservation_id = id
        const status = "Upcoming"
        const table = this.state.table_id
        const note = this.state.note
        const answer = { note, status, table, meeting };
        // this.props.handleReservation(answer, reservation_id);
        this.props.acceptReservation(answer, reservation_id);
        const accepted_tab = [...this.state.requestsAccepted]
        accepted_tab.push(id)
        this.setState({
            show_tables: [],
            table_id: "",
            table_number: "",
            requestsAccepted: accepted_tab
        })
        this.updateAnsweredRequestsList(id)
        // window.location.reload(false);
    }

    declineReservation = (id, reservation_table, meeting) => (e) => {
        const reservation_id = id
        const status = "Declined"
        const note = this.state.note
        const table = reservation_table
        const answer = { status, note, table, meeting };
        this.props.declineReservation(answer, reservation_id);
        // this.props.handleReservation(answer, reservation_id);
        const declined_tab = [...this.state.requestsDeclined]
        declined_tab.push(id)
        this.setState({ requestsDeclined: declined_tab })
        this.updateAnsweredRequestsList(id)
    }

    updateAnsweredRequestsList(id) {
        setTimeout(() => {
            const requestes_answered_list = [...this.state.requestes_answered]
            requestes_answered_list.push(id)
            this.setState({ requestes_answered: requestes_answered_list })
        }, 1500);
    }

    changeModeOpenTab = (id) => (e) => {
        e.preventDefault();
        const meeting_tab = [...this.state.openTab]

        if ((meeting_tab.indexOf(id)) !== -1) {
            const new_remove = meeting_tab.filter(f => f !== id)
            this.setState({ openTab: new_remove })
        }
        else {
            if (meeting_tab.length === 0) {
                meeting_tab.push(id)
                this.setState({ openTab: meeting_tab })
            }
            else {
                meeting_tab.pop()
                meeting_tab.push(id)
                this.setState({ openTab: meeting_tab })
            }
        }
    }

    changeModeTableTab = (reservation_id) => (e) => {
        this.props.getHubFreeTables(reservation_id)
        e.preventDefault();
        const tables_tab = [...this.state.show_tables]
        if ((tables_tab.indexOf(reservation_id)) !== -1) {
            const new_remove = tables_tab.filter(f => f !== reservation_id)
            this.setState({ show_tables: new_remove })
        }
        else {
            if (tables_tab.length === 0) {
                tables_tab.push(reservation_id)
                this.setState({
                    show_tables: tables_tab,
                    table_id: "",
                    table_number: ""
                })
            }
            else {
                tables_tab.pop()
                tables_tab.push(reservation_id)
                this.setState({
                    show_tables: tables_tab,
                    table_id: "",
                    table_number: ""
                })
            }
        }
    }

    sellectTable(table_id, table_number) {
        this.setState({
            table_id: table_id,
            table_number: table_number,
        })
    }


    handleTableChange(table_id, table_number) {
        this.setState({
            table_id: table_id,
            table_number: table_number,
        })
    }

    openTablesModal(reservation_id) {
        this.setState({
            tablesModalVisible: true,
            reservation_table: reservation_id
        });
    }

    openAreYouSureModalDeclineReservation() {
        this.setState({
            areYouSureModalDeclineReservation: true,
        });
    }

    closeModal() {
        this.setState({
            areYouSureModalDeclineReservation: false,
            tablesModalVisible: false,
        });
    }

    handleBack() {
        this.props.history.goBack()
    }


    acceptHubReservationRequest = (id) => (e) => {
        const reservation_id = id
        const status = "Pending"
        const note = this.state.note
        const answer = { id, note, status };
        const new_answered_reservations = [...this.state.answered_reservations]
        new_answered_reservations.push(id)
        this.setState({ answered_reservations: new_answered_reservations })
        this.props.acceptHubReservationRequest(answer, reservation_id);
    }

    declineHubReservationRequest = (id) => (e) => {
        const reservation_id = id
        const status = "Declined by the Hub"
        const note = this.state.note
        const answer = { id, note, status };
        const new_answered_reservations = [...this.state.answered_reservations]
        new_answered_reservations.push(id)
        this.setState({ answered_reservations: new_answered_reservations })
        this.props.declineHubReservationRequest(answer, reservation_id);
    }


    render() {
        // const hub = JSON.parse(localStorage.getItem('hbpg'))
        let new_cancellations_notifications = ""
        let cancellations_notification_status = this.props.cancellations_notifications_status[0]
        if (cancellations_notification_status) {
            new_cancellations_notifications = cancellations_notification_status["new_notifications"]
        }
        // calculate the remaining persons for reservation
        const pending_persons = this.props.reservations.reduce((total, currentValue) => total = total + currentValue.persons, 0);
        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "pending-reservations-lottie-appear" : "pending-reservations-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <HubDesktopNavbar data={{ sellected: "pending" }} />

                {/* <div class={this.props.isLoading ? "pending-reservations-section-disappear" : null}> */}
                <div>
                    <Modal visible={this.state.tablesModalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <TablesModal
                            data={{
                                reservation_from: "user",
                                tables: this.props.tables,
                                table_id: this.state.table_id,
                                table_number: this.state.table_number,
                                closeModal: this.closeModal.bind(this),
                                handleTableChange: this.handleTableChange.bind(this),
                                handleHubReservationTableChange: this.handleHubReservationTableChange.bind(this),
                            }}
                        />
                    </Modal>
                    <div class="whole-hub-reservations-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row color-7">


                                {/* <HubMobileTraffic data={{ hub: this.props.hub }} /> */}
                                <div class="col-xl-12 body-right-7  hide-main-item ">
                                    {/* <HubDesktopTraffic data={{ hub: this.props.hub }} /> */}
                                    <div class="hub-box">
                                        <div class="">
                                            <div class="col-xl-3 col-lg-2 col-md-1 col-sm-1"></div>
                                            <div class="col-xl-6 col-lg-8 col-md-10 col-sm-10 pending-numbers-postition">
                                                {this.props.hub.map(hub => (
                                                    <>


                                                    </>
                                                ))}
                                            </div>
                                            <div class="col-xl-3 col-lg-2 col-md-1 col-sm-1"></div>
                                        </div>
                                    </div>

                                    {this.props.hub.map(hub => (
                                        <>
                                            <div class="hub-mobile-traffic-mobile-navbar">
                                                <Link to="/hub-homepage">
                                                    <div class="back-button-hub-mobile-traffic-box">
                                                        <img
                                                            class="hub-mobile-traffic-back-button"

                                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                </Link>
                                                {hub.name}
                                            </div>

                                            <div class="text-center">
                                                <h2><strong class="text-blue">Requests</strong></h2>
                                            </div>
                                            <br />
                                            {this.props.hub_reservations.length === 0 ?
                                                <div class="text-center pending-hub_reservation_empty-image">

                                                    <div class="pending-hub_reservation_empty-icon">
                                                        <img
                                                            class="pending-hub_reservationempty-created"
                                                            src={require("../../../assets/booked_page/svg/no-requests.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <p class="text-blue-empty-pending-hub">
                                                            No Requests
                                                        </p>
                                                        <p class="text-small-empty-pending-hub">
                                                            When you'll have a request about a reservation, it'll appear here
                                                        </p>
                                                    </div>
                                                    <div class="pending-hub_reservation_gdpr-links">
                                                        <HubGdprLinks />
                                                    </div>
                                                </div>
                                                :

                                                <>
                                                    <center>
                                                        <div class="hub-table-item mt-5">
                                                            <div class="hub-pending-section-title-box">
                                                                {/* <h2><strong class="text-blue">Requests</strong></h2> */}
                                                            </div>
                                                            <br />

                                                            {this.props.hub_reservations.map(hub_reservation => (

                                                                <>

                                                                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 pending-hub-reservation-item-col">
                                                                        <div class="pending-hub-reservation-item-of-table ">
                                                                            <div class="row">
                                                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w text-center">
                                                                                    {hub_reservation.reservation_user_photo ?
                                                                                        <img
                                                                                            class="pending-hub-reservation-m-t"
                                                                                            src={hub_reservation.reservation_user_photo}
                                                                                            alt=""
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            class="pending-hub-reservation-m-t"
                                                                                            src={require("../../../assets/authentication/svg/account.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    }
                                                                                    <br />
                                                                                    {/* <strong class="pending-hub-reservation-d-b pending-hub-reservation-creator-name-color">{hub_reservation.hub_name}</strong> */}
                                                                                </div>

                                                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w texttext-left">
                                                                                    <div class="pending-hub_reservation-meeting-info">
                                                                                        <div>
                                                                                            Requested by:
                                                                                        </div>
                                                                                        <div>
                                                                                            Phone Number:
                                                                                        </div>
                                                                                        <div>
                                                                                            Day:
                                                                                        </div>
                                                                                        <div>
                                                                                            Date:
                                                                                        </div>
                                                                                        <div>
                                                                                            At:
                                                                                        </div>
                                                                                        <div>
                                                                                            Persons:
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="pending-hub_reservation-meeting-data">
                                                                                        <div>
                                                                                            <b>{hub_reservation.creator}</b>
                                                                                        </div>
                                                                                        <div>
                                                                                            <b>{hub_reservation.telephone}</b>
                                                                                        </div>
                                                                                        <div>
                                                                                            <b>{hub_reservation.day}</b>
                                                                                        </div>
                                                                                        <div>
                                                                                            <b>{hub_reservation.date}</b>
                                                                                        </div>
                                                                                        <div>
                                                                                            <b>{hub_reservation.time}</b>
                                                                                        </div>
                                                                                        <div>
                                                                                            <b>{hub_reservation.persons}</b>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="pending-hub_reservation-note-box">
                                                                                    <div class="pending-hub_reservation-message-title">
                                                                                        Message from the client
                                                                                    </div>
                                                                                    {hub_reservation.reservation_request_note ?
                                                                                        <div class="pending-hub_reservation-message">
                                                                                            {hub_reservation.reservation_request_note}
                                                                                        </div>
                                                                                        :
                                                                                        <div class="pending-hub_reservation-message">
                                                                                            -
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                <div class="pending-hub_reservation-note-box">
                                                                                    <div class="pending-hub_reservation-message-title">
                                                                                        Note to me
                                                                                    </div>
                                                                                    <div class="pending-hub_reservation-message">
                                                                                        {this.state.note === "-" ?
                                                                                            <textarea
                                                                                                name="note"
                                                                                                onChange={this.onChange}
                                                                                                value={hub_reservation.note}
                                                                                                placeholder="Note to me..."
                                                                                                class="form-control"
                                                                                                id="exampleFormControlTextarea1"
                                                                                                rows="3"
                                                                                            />
                                                                                            :
                                                                                            <textarea
                                                                                                name="note"
                                                                                                onChange={this.onChange}
                                                                                                value={this.state.note}
                                                                                                placeholder="Note to me..."
                                                                                                class="form-control"
                                                                                                id="exampleFormControlTextarea1"
                                                                                                rows="3"
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                </div>


                                                                                <div class="pending-hub_reservation-meeting-status">
                                                                                    {hub_reservation.status === "Requested" ?
                                                                                        <div class="pending-hub_reservation-meeting-requested">
                                                                                            Reservetion Requested
                                                                                        </div>
                                                                                        :
                                                                                        hub_reservation.status === "Pending" ?
                                                                                            <div class="pending-hub_reservation-meeting-approved">
                                                                                                Reservation Approved
                                                                                            </div>

                                                                                            : hub_reservation.status === "Cancelled by User" ?
                                                                                                <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                    Cancelled by {hub_reservation.creator}
                                                                                                </div>
                                                                                                : hub_reservation.status === "Cancelled by Hub" ?
                                                                                                    <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                        Cancelled by the Hub
                                                                                                    </div>
                                                                                                    : hub_reservation.status === "Declined by the Hub" ?
                                                                                                        <div class="pending-hub_reservation-meeting-cancelled">
                                                                                                            Declined by the Hub
                                                                                                        </div>
                                                                                                        : hub_reservation.status === "Updated by User" ?
                                                                                                            <div class="pending-hub_reservation-meeting-requested">
                                                                                                                Update Requested
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                    }
                                                                                </div>
                                                                                {this.state.answered_reservations.includes(hub_reservation.id) ?
                                                                                    <div class="pending-hub_reservation-buttons">
                                                                                        <div class="pending-hub_reservation-btn-answerd">
                                                                                            Answered
                                                                                        </div>
                                                                                    </div>
                                                                                    : hub_reservation.status === "Requested" ?
                                                                                        <div class="pending-hub_reservation-buttons">
                                                                                            <div
                                                                                                onClick={this.acceptHubReservationRequest(hub_reservation.id)}
                                                                                                class="pending-hub_reservation-btn-accept">
                                                                                                Accept
                                                                                            </div>

                                                                                            <div
                                                                                                onClick={this.declineHubReservationRequest(hub_reservation.id)}
                                                                                                class="pending-hub_reservation-btn-decline">
                                                                                                Decline
                                                                                            </div>
                                                                                        </div>
                                                                                        : hub_reservation.status === "Pending" ?
                                                                                            <div class="pending-hub_reservation-buttons">
                                                                                                <div class="pending-hub_reservation-btn-answerd">
                                                                                                    Accepted
                                                                                                </div>
                                                                                            </div>
                                                                                            : hub_reservation.status === "Declined by the Hub" ?
                                                                                                <div class="pending-hub_reservation-buttons">
                                                                                                    <div class="pending-hub_reservation-btn-answerd">
                                                                                                        Declined
                                                                                                    </div>
                                                                                                </div>
                                                                                                : hub_reservation.status === "Updated by User" ?
                                                                                                    <div class="pending-hub_reservation-buttons">
                                                                                                        <div class="pending-hub_reservation-btn-accept">
                                                                                                            Accept
                                                                                                        </div>
                                                                                                        <div class="pending-hub_reservation-btn-decline">
                                                                                                            Decline
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                }
                                                                                <br />
                                                                                <br /><br />

                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <br />
                                                                    </div>
                                                                </>
                                                            ))}








                                                            <br /><br /><br /><br />
                                                            <HubGdprLinks />
                                                            <br /><br />
                                                            {/* </div> */}


                                                            {/* } */}
                                                        </div>
                                                    </center>
                                                    <br />
                                                    <br />
                                                </>

                                            }
                                        </>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <HubBottomNavbar data={{ sellected: "pending" }} /> */}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    hub: state.hub.hub,
    hub_reservations: state.reservation.pending_hub_reservations,
    reservations: state.reservation.reservations,
    all_cancellations: state.reservation.all_cancellations,
    all_hub_cancellations: state.reservation.all_hub_cancellations,
    cancellations_notifications_status: state.notification.cancellations_notifications_status,
    tables: state.reservation.tables,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    getHubPage,
    updateHubReservationNote,
    acceptHubReservationRequest,
    declineHubReservationRequest,
    getPendingHubReservations,
    getPendingReservations,
    getAllCancelledReservations,
    acceptReservation,
    declineReservation,
    getHubFreeTables,
    getHubPendingNotificationsStatus,
    getHubCancellationsNotificationsStatus,
    getHubAllCancelledHubReservations,
    createAlert
})(PendingReservationsPage);