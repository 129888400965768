import React, { Component, Fragment } from 'react';
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/booked_page/add-reservation.css"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";


export class AddReservation extends Component {
    state = {
        date: new Date(),
        note: "",
        date_changed: false,
        user_search: "",
        sellected_person: "",
        sellected_table: "",
        hour: "21",
        minute: "00",
        table_id: "",
        table_number: "",
        selected_hour: false,
        hour_timepicker: false,
        reservation_name: "",
        no_hubst3r_user: false,
        has_hubst3r_user: false,
        selectTimeDateMode: true,
        selectPersonsMode: false,
        selectReservationNameMode: false,
        selectCreateMode: false,
        selectTableMode: false,
        searched_user: false
    }

    openSellectTimeModal = () => {
        this.setState({
            hour_timepicker: true
        });
    }

    closeSellectTimeModal = () => {
        this.setState({
            hour_timepicker: false
        });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    searchUsersProfile = () => (e) => {
        const query = this.state.user_search
        this.props.getAddReservationProfile(query)
        setTimeout(() => {
            this.setState({
                searched_user: true
            })
        }, 1000);
    }

    closeModal() {
        this.props.data.closeModal()
        this.setState({
            date: new Date(),
            date_changed: false,
            user_search: "",
            sellected_person: "",
            hour: "21",
            minute: "00",
            selected_hour: false,
            hour_timepicker: false,
            reservation_name: "",
            selectPersonsMode: false,
            selectTimeDateMode: true,
            selectReservationNameMode: false,
            selectCreateMode: false,
        })
        this.props.getAddReservationProfile("0")
    }

    updateDateField(date) {
        this.setState({
            date: date,
            date_changed: true
        })
    }


    updateSellectedPersons(persons) {
        this.setState({
            sellected_person: persons
        })
    }

    updateSellectedTable(table) {
        this.setState({
            sellected_table: table
        })
    }


    handleback = () => {
        this.setState({
            editWorkingHour: false,
            dayToEdit: "",
            valueToEdit: "",
            timeToCorrect: "",
            hours_minutes_index: "1",
        })
    }

    updateSellectedHour = (hour) => (e) => {
        this.setState({
            hour: hour,
            selected_hour: true
        });
    }

    updateSellectedMinute = (minute) => (e) => {
        this.setState({
            minute: minute,
            selected_hour: false,
            hour_timepicker: false
        });
        const time = this.state.hour + ':' + minute
        this.setState({
            timeToCorrect: time,
        });
        // setTimeout(() => {
        //     this.props.data.closeModal()
        // }, 500);
    }

    backToHours = e => {
        this.setState({
            selected_hour: false,
        });
    }


    onSubmitReservation = e => {
        const time = this.state.hour + ':' + this.state.minute
        const date = this.state.date
        const persons = this.state.sellected_person
        let table = this.props.data.sellected_table

        if (table.id) {
            table = table.id
        } else if (this.state.sellected_table !== "") {
            table = this.state.sellected_table
        } else {
            table = null
        }
        let reservation_name = this.state.reservation_name
        let user = this.props.data.user_profile[0]
        if (user) {
            user = user.user
        } else {
            user = ""
        }
        let note = this.state.note
        if (persons === "") {
            this.props.createAlert({ noTableSelected: "You haven't Sellected for how many persons!" });
        } else {
            if (user === "") {
                if (reservation_name === "") {
                    this.props.createAlert({ noTableSelected: "You haven't Sellected a name for the Reservation!" });
                } else {
                    if (reservation_name === "") {
                        reservation_name = "none"
                    }
                    if (note === "") {
                        note = "none"
                    }
                    const reservation = { time, date, table, user, note, persons, reservation_name }
                    this.props.createNewReservation(reservation)
                    this.setState({
                        date: new Date(),
                        note: "",
                        date_changed: false,
                        user_search: "",
                        sellected_person: "",
                        table_id: "",
                        table_number: "",
                        hour: "21",
                        minute: "00",
                        selected_hour: false,
                        hour_timepicker: false,
                        reservation_name: "",
                        no_hubst3r_user: false,
                        selectPersonsMode: false,
                        selectTimeDateMode: true,
                        selectReservationNameMode: false,
                        selectCreateMode: false,
                    })
                    // this.props.data.submitAddNewReservation()
                    this.props.getAddReservationProfile("0")
                    this.props.data.refreshPage()
                    this.closeModal()
                }
            }
            else {
                if (reservation_name === "") {
                    reservation_name = "none"
                }
                if (note === "") {
                    note = "none"
                }
                const reservation = { time, date, table, user, note, persons, reservation_name }
                this.props.createNewReservation(reservation)
                this.setState({
                    date: new Date(),
                    note: "",
                    date_changed: false,
                    user_search: "",
                    sellected_person: "",
                    hour: "21",
                    minute: "00",
                    table_id: "",
                    table_number: "",
                    selected_hour: false,
                    hour_timepicker: false,
                    reservation_name: "",
                    no_hubst3r_user: false,
                    selectPersonsMode: false,
                    selectTimeDateMode: true,
                    selectReservationNameMode: false,
                    selectCreateMode: false,
                })
                // this.props.data.submitAddNewReservation()
                this.props.getAddReservationProfile("0")
                this.closeModal()
            }
        }
    }

    noHubst3rUser = e => {
        this.setState({
            no_hubst3r_user: true,
            has_hubst3r_user: false
        })
    }

    hasHubst3rUser = e => {
        this.setState({
            no_hubst3r_user: false,
            has_hubst3r_user: true
        })
    }

    changeToSelectTimeDateMode() {
        this.setState({
            selectPersonsMode: false,
            selectTimeDateMode: true,
            selectReservationNameMode: false,
            selectTableMode: false,
            selectCreateMode: false,
        });
    }

    changeToSelectPersonsMode() {
        this.setState({
            selectPersonsMode: true,
            selectTimeDateMode: false,
            selectReservationNameMode: false,
            selectTableMode: false,
            selectCreateMode: false,
        });
    }

    changeToSelectReservationNameMode(position) {
        let have_table = false
        if (this.props.data.sellected_table.id) {
            have_table = true
        }
        if (position === "back") {
            have_table = true
        }
        if (have_table) {
            this.setState({
                selectPersonsMode: false,
                selectTimeDateMode: false,
                selectReservationNameMode: true,
                selectTableMode: false,
                selectCreateMode: false,
            });
        } else {
            this.setState({
                selectPersonsMode: false,
                selectTimeDateMode: false,
                selectReservationNameMode: false,
                selectTableMode: true,
                selectCreateMode: false,
            });
        }
    }


    changeToCreateMode(position) {
        let have_table = false
        if (this.props.data.sellected_table.id) {
            have_table = true
        } else {
            if (this.state.have_sellected_table) {
                have_table = true
            }
        }
        if (position === "true") {
            have_table = true
        }
        if (have_table) {
            this.setState({
                selectPersonsMode: false,
                selectTimeDateMode: false,
                selectReservationNameMode: false,
                selectTableMode: false,
                selectCreateMode: true,
            });
        } else {
            this.setState({
                selectPersonsMode: false,
                selectTimeDateMode: false,
                selectReservationNameMode: false,
                selectTableMode: true,
                selectCreateMode: false,
            });
        }
    }

    render() {
        const ExampleCustomInput = ({ value, onClick }) => (
            <div onClick={onClick} class="create-reservation-datepicker">
                <strong onClick={onClick}>
                    {value}
                </strong>
            </div>
        );
        let date = this.state.date
        if (!this.state.date_changed) {
            date = this.props.data.sellected_date
        }
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 5,
            swipeToSlide: true,
        };
        const persons = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]
        const search_user_profile = this.props.data.user_profile[0]

        let have_table = false
        if (this.props.data.sellected_table.id) {
            have_table = true
        }


        return (
            <Fragment>
                {this.state.hour_timepicker ?
                    <div class="create-reservation-time-modal">
                        <div>
                            <div
                                onClick={this.closeSellectTimeModal}
                                class="create-reservation-modal-back-arrow">
                                <img src={require("../../../assets/layout/homepage svg/back arrow.svg")} alt="" />
                            </div>
                            <div class="create-reservation-time-title">Sellect Reservation's hour</div>

                            <div class="create-reservation-edit-box">
                                <div class="create-reservation-edit-number">
                                    {this.state.hour ?
                                        <strong onClick={this.backToHours}>{this.state.hour}</strong>
                                        :
                                        <strong>00</strong>
                                    }
                                    <strong>  :  </strong>
                                    {this.state.minute ?
                                        <strong>{this.state.minute}</strong>
                                        :
                                        <strong>00</strong>
                                    }
                                </div>
                            </div>
                            <span>
                                {this.state.selected_hour ?
                                    <span>
                                        <div class="create-reservation-all-hiden-hour">
                                            <div
                                                onClick={this.updateSellectedMinute("00")}
                                                class="create-reservation-hour-invisible-12"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("05")}
                                                class="create-reservation-hour-invisible-13"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("10")}
                                                class="create-reservation-hour-invisible-14"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("15")}
                                                class="create-reservation-hour-invisible-15"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("20")}
                                                class="create-reservation-hour-invisible-16"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("25")}
                                                class="create-reservation-hour-invisible-17"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("30")}
                                                class="create-reservation-hour-invisible-18"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("35")}
                                                class="create-reservation-hour-invisible-19"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("40")}
                                                class="create-reservation-hour-invisible-20"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("45")}
                                                class="create-reservation-hour-invisible-21"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("50")}
                                                class="create-reservation-hour-invisible-22"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedMinute("55")}
                                                class="create-reservation-hour-invisible-23"
                                            >
                                            </div>

                                        </div>
                                        {this.state.minute === "" ?
                                            <div class="create-reservation-timepicker-size">
                                                <img
                                                    class="create-reservation-timepicker-clock-layout"
                                                    src={require("../../../assets/arrange-meeting/timepicker/minute-empty.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            : this.state.minute === "00" ?
                                                <div class="create-reservation-timepicker-size">
                                                    <img
                                                        class="create-reservation-timepicker-clock-layout time-00-correction"
                                                        src={require("../../../assets/arrange-meeting/timepicker/minute-00.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                : this.state.minute === "05" ?
                                                    <div class="create-reservation-timepicker-size">
                                                        <img
                                                            class="create-reservation-timepicker-clock-layout"
                                                            src={require("../../../assets/arrange-meeting/timepicker/minute-05.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    : this.state.minute === "10" ?
                                                        <div class="create-reservation-timepicker-size">
                                                            <img
                                                                class="create-reservation-timepicker-clock-layout"
                                                                src={require("../../../assets/arrange-meeting/timepicker/minute-10.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        : this.state.minute === "15" ?
                                                            <div class="create-reservation-timepicker-size">
                                                                <img
                                                                    class="create-reservation-timepicker-clock-layout"
                                                                    src={require("../../../assets/arrange-meeting/timepicker/minute-15.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            : this.state.minute === "20" ?
                                                                <div class="create-reservation-timepicker-size">
                                                                    <img
                                                                        class="create-reservation-timepicker-clock-layout"
                                                                        src={require("../../../assets/arrange-meeting/timepicker/minute-20.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                : this.state.minute === "25" ?
                                                                    <div class="create-reservation-timepicker-size">
                                                                        <img
                                                                            class="create-reservation-timepicker-clock-layout"
                                                                            src={require("../../../assets/arrange-meeting/timepicker/minute-25.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    : this.state.minute === "30" ?
                                                                        <div class="create-reservation-timepicker-size">
                                                                            <img
                                                                                class="create-reservation-timepicker-clock-layout"
                                                                                src={require("../../../assets/arrange-meeting/timepicker/minute-30.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        : this.state.minute === "35" ?
                                                                            <div class="create-reservation-timepicker-size">
                                                                                <img
                                                                                    class="create-reservation-timepicker-clock-layout"
                                                                                    src={require("../../../assets/arrange-meeting/timepicker/minute-35.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            : this.state.minute === "40" ?
                                                                                <div class="create-reservation-timepicker-size">
                                                                                    <img
                                                                                        class="create-reservation-timepicker-clock-layout"
                                                                                        src={require("../../../assets/arrange-meeting/timepicker/minute-40.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                : this.state.minute === "45" ?
                                                                                    <div class="create-reservation-timepicker-size">
                                                                                        <img
                                                                                            class="create-reservation-timepicker-clock-layout"
                                                                                            src={require("../../../assets/arrange-meeting/timepicker/minute-45.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    : this.state.minute === "50" ?
                                                                                        <div class="create-reservation-timepicker-size">
                                                                                            <img
                                                                                                class="create-reservation-timepicker-clock-layout"
                                                                                                src={require("../../../assets/arrange-meeting/timepicker/minute-50.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        : this.state.minute === "55" ?
                                                                                            <div class="create-reservation-timepicker-size">
                                                                                                <img
                                                                                                    class="create-reservation-timepicker-clock-layout"
                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/minute-55.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            null
                                        }
                                    </span>
                                    :
                                    <span>
                                        <div class="create-reservation-all-hiden-hour">
                                            <div
                                                onClick={this.updateSellectedHour("01")}
                                                class="create-reservation-hour-invisible-1"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("02")}
                                                class="create-reservation-hour-invisible-2"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("03")}
                                                class="create-reservation-hour-invisible-3"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("04")}
                                                class="create-reservation-hour-invisible-4"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("05")}
                                                class="create-reservation-hour-invisible-5"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("06")}
                                                class="create-reservation-hour-invisible-6"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("07")}
                                                class="create-reservation-hour-invisible-7"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("08")}
                                                class="create-reservation-hour-invisible-8"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("09")}
                                                class="create-reservation-hour-invisible-9"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("10")}
                                                class="create-reservation-hour-invisible-10"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("11")}
                                                class="create-reservation-hour-invisible-11"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("12")}
                                                class="create-reservation-hour-invisible-12"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("13")}
                                                class="create-reservation-hour-invisible-13"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("14")}
                                                class="create-reservation-hour-invisible-14"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("15")}
                                                class="create-reservation-hour-invisible-15"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("16")}
                                                class="create-reservation-hour-invisible-16"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("17")}
                                                class="create-reservation-hour-invisible-17"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("18")}
                                                class="create-reservation-hour-invisible-18"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("19")}
                                                class="create-reservation-hour-invisible-19"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("20")}
                                                class="create-reservation-hour-invisible-20"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("21")}
                                                class="create-reservation-hour-invisible-21"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("22")}
                                                class="create-reservation-hour-invisible-22"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("23")}
                                                class="create-reservation-hour-invisible-23"
                                            >
                                            </div>
                                            <div
                                                onClick={this.updateSellectedHour("00")}
                                                class="create-reservation-hour-invisible-00"
                                            >
                                            </div>
                                        </div>
                                        {this.state.hour === "" ?
                                            <div class="create-reservation-timepicker-size">
                                                <img
                                                    class="create-reservation-timepicker-clock-layout"
                                                    src={require("../../../assets/arrange-meeting/timepicker/hours-empty.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            : this.state.hour === "01" ?
                                                <div class="create-reservation-timepicker-size">
                                                    <img
                                                        class="create-reservation-timepicker-clock-layout"
                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-1.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                : this.state.hour === "02" ?
                                                    <div class="create-reservation-timepicker-size">
                                                        <img
                                                            class="create-reservation-timepicker-clock-layout"
                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-2.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    : this.state.hour === "03" ?
                                                        <div class="create-reservation-timepicker-size">
                                                            <img
                                                                class="create-reservation-timepicker-clock-layout"
                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-3.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        : this.state.hour === "04" ?
                                                            <div class="create-reservation-timepicker-size">
                                                                <img
                                                                    class="create-reservation-timepicker-clock-layout"
                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-4.svg")}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            : this.state.hour === "05" ?
                                                                <div class="create-reservation-timepicker-size">
                                                                    <img
                                                                        class="create-reservation-timepicker-clock-layout"
                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-5.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                : this.state.hour === "06" ?
                                                                    <div class="create-reservation-timepicker-size">
                                                                        <img
                                                                            class="create-reservation-timepicker-clock-layout"
                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-6.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    : this.state.hour === "07" ?
                                                                        <div class="create-reservation-timepicker-size">
                                                                            <img
                                                                                class="create-reservation-timepicker-clock-layout"
                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-7.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        : this.state.hour === "08" ?
                                                                            <div class="create-reservation-timepicker-size">
                                                                                <img
                                                                                    class="create-reservation-timepicker-clock-layout"
                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-8.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            : this.state.hour === "09" ?
                                                                                <div class="create-reservation-timepicker-size">
                                                                                    <img

                                                                                        class="create-reservation-timepicker-clock-layout"
                                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-9.svg")}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                : this.state.hour === "10" ?
                                                                                    <div class="create-reservation-timepicker-size">
                                                                                        <img

                                                                                            class="create-reservation-timepicker-clock-layout"
                                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-10.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    : this.state.hour === "11" ?
                                                                                        <div class="create-reservation-timepicker-size">
                                                                                            <img

                                                                                                class="create-reservation-timepicker-clock-layout"
                                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-11.svg")}
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        : this.state.hour === "12" ?
                                                                                            <div class="create-reservation-timepicker-size">
                                                                                                <img

                                                                                                    class="create-reservation-timepicker-clock-layout"
                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-12.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            : this.state.hour === "13" ?
                                                                                                <div class="create-reservation-timepicker-size">
                                                                                                    <img

                                                                                                        class="create-reservation-timepicker-clock-layout"
                                                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-13.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                                : this.state.hour === "14" ?
                                                                                                    <div class="create-reservation-timepicker-size">
                                                                                                        <img

                                                                                                            class="create-reservation-timepicker-clock-layout"
                                                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-14.svg")}
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    : this.state.hour === "15" ?
                                                                                                        <div class="create-reservation-timepicker-size">
                                                                                                            <img

                                                                                                                class="create-reservation-timepicker-clock-layout"
                                                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-15.svg")}
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                        : this.state.hour === "16" ?
                                                                                                            <div class="create-reservation-timepicker-size">
                                                                                                                <img

                                                                                                                    class="create-reservation-timepicker-clock-layout"
                                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-16.svg")}
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                            : this.state.hour === "17" ?
                                                                                                                <div class="create-reservation-timepicker-size">
                                                                                                                    <img

                                                                                                                        class="create-reservation-timepicker-clock-layout"
                                                                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-17.svg")}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </div>
                                                                                                                : this.state.hour === "18" ?
                                                                                                                    <div class="create-reservation-timepicker-size">
                                                                                                                        <img

                                                                                                                            class="create-reservation-timepicker-clock-layout"
                                                                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-18.svg")}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    : this.state.hour === "19" ?
                                                                                                                        <div class="create-reservation-timepicker-size">
                                                                                                                            <img

                                                                                                                                class="create-reservation-timepicker-clock-layout"
                                                                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-19.svg")}
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        : this.state.hour === "20" ?
                                                                                                                            <div class="create-reservation-timepicker-size">
                                                                                                                                <img

                                                                                                                                    class="create-reservation-timepicker-clock-layout"
                                                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-20.svg")}
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            : this.state.hour === "21" ?
                                                                                                                                <div class="create-reservation-timepicker-size">
                                                                                                                                    <img

                                                                                                                                        class="create-reservation-timepicker-clock-layout"
                                                                                                                                        src={require("../../../assets/arrange-meeting/timepicker/hour-21.svg")}
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                : this.state.hour === "22" ?
                                                                                                                                    <div class="create-reservation-timepicker-size">
                                                                                                                                        <img

                                                                                                                                            class="create-reservation-timepicker-clock-layout"
                                                                                                                                            src={require("../../../assets/arrange-meeting/timepicker/hour-22.svg")}
                                                                                                                                            alt=""
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                    : this.state.hour === "23" ?
                                                                                                                                        <div class="create-reservation-timepicker-size">
                                                                                                                                            <img

                                                                                                                                                class="create-reservation-timepicker-clock-layout"
                                                                                                                                                src={require("../../../assets/arrange-meeting/timepicker/hour-23.svg")}
                                                                                                                                                alt=""
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        : this.state.hour === "00" ?
                                                                                                                                            <div class="create-reservation-timepicker-size">
                                                                                                                                                <img

                                                                                                                                                    class="create-reservation-timepicker-clock-layout"
                                                                                                                                                    src={require("../../../assets/arrange-meeting/timepicker/hour-24.svg")}
                                                                                                                                                    alt=""
                                                                                                                                                />
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            null
                                        }
                                    </span>
                                }
                            </span>
                        </div>
                    </div>
                    :
                    <div class="create-reservation-modal-modal-position">
                        <div class="row">
                            <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                            <div class="create-reservation-modal-modal-box">
                                <div class="row text-right">
                                    <div class="col-xl-12 text-right">
                                        <div
                                            onClick={() => this.closeModal()}
                                            class="create-reservation-modal-cancle-btn">
                                            <img class="create-reservation-modal-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="create-reservation-time-date">
                                    <div class="row ">
                                        <div class="text-left">
                                            <h4>
                                                <strong class="create-reservation-modal-title">Create a Reservation
                                                </strong>
                                            </h4>
                                        </div>
                                        {this.state.selectTimeDateMode ?
                                            <>
                                                <div class="create-reservation-datebox">
                                                    <div class="text-center">
                                                        <h4><strong class="create-reservation-section-title">Date</strong></h4>
                                                    </div>
                                                    <DatePicker
                                                        onChange={this.updateDateField.bind(this)}
                                                        selected={this.state.date}
                                                        name="date"
                                                        dateFormat="dd/MM/yyyy"
                                                        customInput={<ExampleCustomInput />}
                                                        minDate={new Date()}

                                                    />
                                                </div>

                                                <div class="create-reservation-timebox-position">
                                                    <div class="text-center">
                                                        <h4><strong class="create-reservation-section-title">Time</strong></h4>
                                                    </div>
                                                    <div
                                                        class="create-reservation-time-box"
                                                        onClick={this.openSellectTimeModal}
                                                    >
                                                        <div class="">
                                                            {this.state.hour ?
                                                                <strong onClick={this.backToHours}>{this.state.hour}</strong>
                                                                :
                                                                <strong>00</strong>
                                                            }
                                                            <strong>  :  </strong>
                                                            {this.state.minute ?
                                                                <strong>{this.state.minute}</strong>
                                                                :
                                                                <strong>00</strong>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => this.changeToSelectPersonsMode()}
                                                    class="create-reservation-next-icon-1">
                                                    <img
                                                        src={require("../../../assets/layout/homepage svg/next arrow.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                    <br />

                                    {this.state.selectPersonsMode ?
                                        <>
                                            <div
                                                onClick={() => this.changeToSelectTimeDateMode()}
                                                class="create-reservation-back-icon-1">
                                                <img
                                                    src={require("../../../assets/layout/homepage svg/back arrow.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="create-reservation-modal-slider-layout">
                                                <div class="text-left">
                                                    <h4><strong class="create-reservation-section-title">Persons</strong></h4>
                                                </div>
                                                <Slider {...settings} >
                                                    {persons.map(persons => (
                                                        <div>
                                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
                                                                <div
                                                                    onClick={() => this.updateSellectedPersons(persons)}
                                                                    class="create-reservation-modal-modal-frame"
                                                                >
                                                                    {persons === 2 ?
                                                                        <img
                                                                            class={this.state.sellected_person === persons ?
                                                                                "create-reservation-sellected-logo"
                                                                                :
                                                                                "create-reservation-modal-modal-logo"
                                                                            }
                                                                            src={require("../../../assets/booked_page/svg/sellect-persons-2.svg")}
                                                                            alt=""
                                                                        />
                                                                        :
                                                                        <img
                                                                            class={this.state.sellected_person === persons ?
                                                                                "create-reservation-sellected-logo"
                                                                                :
                                                                                "create-reservation-modal-modal-logo"
                                                                            }
                                                                            src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                            alt=""
                                                                        />
                                                                    }
                                                                    <div class="create-reservation-modal-table-reservation">{persons}<br />Persons</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                            <div
                                                onClick={() => this.changeToSelectReservationNameMode("back")}
                                                class="create-reservation-next-icon-1">
                                                <img
                                                    src={require("../../../assets/layout/homepage svg/next arrow.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                    {this.state.selectReservationNameMode ?
                                        <>
                                            <div
                                                onClick={() => this.changeToSelectPersonsMode()}
                                                class="create-reservation-back-icon-1">
                                                <img
                                                    src={require("../../../assets/layout/homepage svg/back arrow.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            {this.state.has_hubst3r_user ?
                                                <>
                                                    <div class="create-reservation-hubst3r-user-search">
                                                        <div class="row create-reservation-hubst3r-user-serarch">
                                                            <div class="text-center create-reservation-top-tiltle">
                                                                <h4><strong class="create-reservation-search-section-title">Search User</strong></h4>
                                                            </div>


                                                            <span class="">
                                                                <div className="create-reservation-search-user-box">
                                                                    <input
                                                                        className="form-control mr-sm-2 w-100 py-4 add-reservations-search-form-text"
                                                                        type="text"
                                                                        name="user_search"
                                                                        onChange={this.onChange}
                                                                        placeholder="Search Hubst3r User"
                                                                        value={this.state.user_search}
                                                                        aria-label="Search"
                                                                        id="field-to-show"
                                                                    />
                                                                </div>
                                                                {this.state.user_search === "" ?
                                                                    null
                                                                    :
                                                                    <div class="create-reservation-search-profile-button">
                                                                        <div
                                                                            onClick={this.searchUsersProfile()}
                                                                            class="search-profile-round-button"
                                                                        >
                                                                            <img class="add-reservations-input-icon-search search-profile-icon" src={require("../../../assets/gags/svg/Icon.svg")} alt="" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </span>



                                                        </div>
                                                        {this.state.searched_user ?
                                                            <>
                                                                {search_user_profile ?
                                                                    <div className="create-reservation-user-profile">
                                                                        {search_user_profile.profile_picture ?
                                                                            <img
                                                                                class="create-reservation-user-profile"
                                                                                src={search_user_profile.profile_picture}
                                                                                alt=""
                                                                            />
                                                                            :
                                                                            <img
                                                                                class="create-reservation-user-profile"
                                                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div class="create-reservation-user-not-found">
                                                                        <p class="create-reservation-user-not-found-text">
                                                                            User Not Found
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </>
                                                            : null
                                                        }
                                                    </div>
                                                    <br />
                                                    <div
                                                        onClick={this.noHubst3rUser}
                                                        class="create-reservation-btn-no-user"
                                                    >
                                                        Don't Have Hubst3r
                                                    </div>
                                                </>
                                                : this.state.no_hubst3r_user ?
                                                    <>
                                                        <div className="create-reservation-search-box">
                                                            <div className="create-reservation-search-top">
                                                                <div className="form-inline w-100">
                                                                    <input
                                                                        className="form-control mr-sm-2 w-100 py-4 add-reservations-search-form-text"
                                                                        type="text"
                                                                        maxlength="15"
                                                                        name="reservation_name"
                                                                        onChange={this.onChange}
                                                                        placeholder="Reservation's Name"
                                                                        value={this.state.reservation_name}
                                                                        aria-label="reservation_name"
                                                                        id="field-to-show"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div
                                                            onClick={this.hasHubst3rUser}
                                                            class="create-reservation-btn-no-user"
                                                        >
                                                            Has Hubst3r
                                                        </div>
                                                    </>
                                                    :
                                                    <div class="row create-reservation-name-choises">
                                                        <div class="text-left">
                                                            <h4><strong class="create-reservation-section-title">Reservations Name:</strong></h4>
                                                        </div>
                                                        <div
                                                            onClick={this.hasHubst3rUser}
                                                            class="create-reservation-btn-hubst3r-user"
                                                        >
                                                            Has Hubst3r
                                                        </div>

                                                        <div
                                                            onClick={this.noHubst3rUser}
                                                            class="create-reservation-btn-no-hubst3r-user"
                                                        >
                                                            Don't Have Hubst3r
                                                        </div>
                                                    </div>
                                            }
                                            <div
                                                onClick={() => this.changeToCreateMode()}
                                                class="create-reservation-next-icon-1">
                                                <img
                                                    src={require("../../../assets/layout/homepage svg/next arrow.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                    {!have_table ?
                                        <>
                                            {this.state.selectTableMode ?
                                                <>
                                                    <div
                                                        onClick={() => this.changeToSelectReservationNameMode("back")}
                                                        class="create-reservation-back-icon-1">
                                                        <img
                                                            src={require("../../../assets/layout/homepage svg/back arrow.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div class="create-reservation-modal-slider-layout">
                                                        <div class="text-left">
                                                            <h4><strong class="create-reservation-section-title">Table</strong></h4>
                                                        </div>

                                                        <Slider {...settings} >
                                                            {this.props.data.all_tables.map(table => (
                                                                <div>
                                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center item-div-4" >
                                                                        <div
                                                                            onClick={() => this.updateSellectedTable(table.id)}
                                                                            class="create-reservation-modal-modal-frame"
                                                                        >

                                                                            <img
                                                                                class={this.state.sellected_table === table.id ?
                                                                                    "create-reservation-sellected-logo"
                                                                                    :
                                                                                    "create-reservation-modal-modal-logo"
                                                                                }
                                                                                src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                                alt=""
                                                                            />

                                                                            <div class="create-reservation-modal-table-reservation">Table<br />{table.number}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Slider>
                                                    </div>
                                                    <div
                                                        onClick={() => this.changeToCreateMode("true")}
                                                        class="create-reservation-next-icon-1">
                                                        <img
                                                            src={require("../../../assets/layout/homepage svg/next arrow.svg")}
                                                            alt=""
                                                        />
                                                    </div>
                                                </>

                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }

                                    {this.state.selectCreateMode ?
                                        <>
                                            <div
                                                onClick={() => this.changeToSelectReservationNameMode()}
                                                class="create-reservation-back-icon-1">
                                                <img
                                                    src={require("../../../assets/layout/homepage svg/back arrow.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="create-reservation-note">
                                                <textarea
                                                    placeholder="Write a note about the reservation for me"
                                                    name="note"
                                                    onChange={this.onChange}
                                                    value={this.state.note}
                                                    class="create-reservation-textbox"
                                                    id="exampleFormControlTextarea1"
                                                    rows="6"
                                                >
                                                </textarea>
                                            </div>
                                            <div
                                                onClick={this.onSubmitReservation}
                                                class="btn-danger-create-reservation">
                                                Create
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                </div >
                            </div >
                            <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                            </div>
                        </div >
                    </div >
                }
            </Fragment >
        )
    }
}

export default (AddReservation);