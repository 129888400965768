import React, { Fragment, lazy, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {
    getHubPage,
    getHubUsersWithAccess,
} from '../../../store/actions/hub'
import {
    getAddReservationProfile,
    getMyProfile
} from '../../../store/actions/profile'
import {
    createHubEmployee,
    changeHubEmployeeAccess,
    removeHubEmployeeAccess,
} from '../../../store/actions/hubPersonel'

import "../../../assets/hub/setup/place-setup-page.css"
import { createAlert } from '../../../store/actions/alerts';
import Modal from 'react-awesome-modal';
import hub from '../../../store/reducers/hub';


export class SetupPage extends Component {

    state = {
        addAccessModal: false,
        search_user: "",
        searched: false,
        change_search: false,
        user_position: "",
        editAccessModal: false,
        editing_profile: {},
    };


    componentDidMount() {
        this.props.getHubPage();
        this.props.getHubUsersWithAccess();
        this.props.getMyProfile();
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value, change_search: false })
    }

    openAddUserAceesModal() {
        this.setState({
            addAccessModal: true
        })
    }

    openEditUserAceesModal(profile) {
        this.setState({
            editAccessModal: true,
            editing_profile: profile,
        })
    }

    closeModal() {
        this.setState({
            addAccessModal: false,
            search_user: "",
            searched: false,
            change_search: false,
            user_position: "",
            editAccessModal: false,
            editing_profile: {},
        })
    }

    searchUserForAccess() {
        this.setState({
            searched: true,
            change_search: true
        })
        const query = this.state.search_user
        if (query.length > 1) {
            this.props.getAddReservationProfile(query)
        }
    }

    giveUserAccess() {
        const user = this.props.user_profile[0].user
        const name = this.state.user_position
        const employee = { name, user }
        this.props.createHubEmployee(employee)
        setTimeout(() => {
            this.props.getHubUsersWithAccess();
        }, 2000);
        this.closeModal()
    }

    changeAdminAccess(is_admin) {
        const user = this.state.editing_profile.user
        const employee = { user, is_admin }
        this.props.changeHubEmployeeAccess(employee)
        this.closeModal()
        setTimeout(() => {
            this.props.getHubUsersWithAccess();
        }, 2000);
    }

    removeAdminAccess() {
        const user = this.state.editing_profile.user
        const is_active = false
        const employee = { user, is_active }
        this.props.removeHubEmployeeAccess(employee)
        this.closeModal()
        setTimeout(() => {
            this.props.getHubUsersWithAccess();
        }, 2000);
    }


    render() {
        let my_profile = []
        if (this.props.profile.length > 0) {
            my_profile = this.props.profile[0]
        }
        return (
            <Fragment>

                {this.props.hub.map(hub => (
                    <>
                        {this.state.addAccessModal ?
                            <Modal visible={this.state.addAccessModal} width="100%" height="50%" effect="fadeInUp" onClickAway={() => this.closeModal()} >

                                <div class="setup-page-acces-add-modal-box">

                                    <div class="setup-page-acces-add-modal-titel">
                                        Give User Access to <br /><b>{hub.name}</b>
                                    </div>
                                    {this.props.user_profile.length > 0 && this.state.searched ?
                                        <>
                                            {this.props.user_profile.map(profile => (
                                                <>
                                                    <div class="setup-page-acces-add-profile-pix">
                                                        <img
                                                            class="setup-page-acces-add-profile-pix-img"
                                                            src={profile.profile_picture}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div class="setup-page-acces-add-username">
                                                        {profile.username}
                                                    </div>
                                                </>
                                            ))}
                                            <div class="setup-page-acces-add-modal-expalining-txt2">
                                                Insert the Position that the User has
                                            </div>
                                            <input
                                                placeholder="User's Position..."
                                                class="setup-page-acces-add-modal-position-input"
                                                value={this.state.user_position}
                                                onChange={this.onChange.bind(this)}
                                                name="user_position"
                                            />
                                            <div onClick={() => this.giveUserAccess()} class="setup-page-acces-add-modal-finif">
                                                Give Access
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div class="setup-page-acces-add-modal-expalining-txt">
                                                Insert the Full Username of the User you want to give access to and then Search
                                            </div>
                                            <input
                                                placeholder="Username..."
                                                class="setup-page-acces-add-modal-input"
                                                value={this.state.search_user}
                                                onChange={this.onChange.bind(this)}
                                                name="search_user"
                                            />
                                            {this.props.searching ?
                                                null
                                                :
                                                <>
                                                    {this.state.searched && this.props.user_profile.length === 0 && this.state.change_search ?
                                                        <div class="setup-page-acces-add-modal-nouser">No User was found with this Username</div>
                                                        :
                                                        <div onClick={() => this.searchUserForAccess()} class="setup-page-acces-add-modal-search-btn">
                                                            <img
                                                                class="setup-page-acces-add-modal-search-img"
                                                                src={require("../../../assets/gags/svg/Icon.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </Modal>
                            :
                            null
                        }

                        {this.state.editAccessModal ?
                            <Modal visible={this.state.editAccessModal} width="100%" height="50%" effect="fadeInUp" onClickAway={() => this.closeModal()} >

                                <div class="setup-page-acces-add-modal-box">

                                    <div class="setup-page-acces-add-modal-titel">
                                        Change {this.state.editing_profile.username} Access status
                                    </div>

                                    <div onClick={() => this.closeModal()} class="setup-page-acces-modal-times-box">
                                        <img
                                            class="setup-page-acces-modal-times-img"
                                            src={require("../../../assets/layout/homepage svg/times.svg")}
                                            alt=""
                                        />
                                    </div>

                                    <div class="setup-page-acces-add-profile-pix2">
                                        {this.state.editing_profile.profile_picture ?
                                            <img
                                                class="setup-page-acces-add-profile-pix-img"
                                                src={this.state.editing_profile.profile_picture}
                                                alt=""
                                            />
                                            :
                                            <img
                                                class="setup-page-users-with-acces-profile-img"
                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                alt=""
                                            />
                                        }
                                    </div>
                                    <div class="setup-page-acces-add-username">
                                        {this.state.editing_profile.username}
                                        {this.state.editing_profile.is_admin ?
                                            <span class="setup-page-users-with-acces-admin"> (Admin)</span>
                                            :
                                            null
                                        }
                                    </div>

                                    <div onClick={() => this.removeAdminAccess()} class="setup-page-acces-remove-access">
                                        Remove Shop<br />Access
                                    </div>
                                    {this.state.editing_profile.is_admin ?
                                        <div onClick={() => this.changeAdminAccess(false)} class="setup-page-acces-remove-admin">
                                            Remove Admin<br />Access
                                        </div>
                                        :
                                        <div onClick={() => this.changeAdminAccess(true)} class="setup-page-acces-give-admin">
                                            Give Admin<br />Access
                                        </div>
                                    }


                                </div>
                            </Modal>
                            :
                            null
                        }

                        <HubDesktopNavbar data={{ sellected: "" }} />
                        <div>
                            <div class="whole-setup-page-page-layout">
                                <OfflineLabel />
                                <>
                                    <div class="setup-page-welcome-title">
                                        <Link to="/information" class="setup-page-back-box">
                                            <div class="setup-page-back-icon-box">
                                                <img
                                                    class="setup-page-back-icon"
                                                    src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        Set Users Access

                                    </div>

                                    <>
                                        <div class="setup-page-logo-text">
                                            Users with Access
                                        </div>


                                        <div class="setup-page-all-users-with-acces">
                                            {this.props.users_with_access.map(user => (
                                                <div class="row setup-page-users-with-acces-box">

                                                    <div class="setup-page-users-with-acces-profile-box">
                                                        {user.profile_picture ?
                                                            <img
                                                                class="setup-page-users-with-acces-profile-img"
                                                                src={user.profile_picture}
                                                                alt=""
                                                            />
                                                            :
                                                            <img
                                                                class="setup-page-users-with-acces-profile-img"
                                                                src={require("../../../assets/authentication/svg/account.svg")}
                                                                alt=""
                                                            />
                                                        }

                                                    </div>
                                                    <div class="setup-page-users-with-acces-txt">
                                                        <div class="setup-page-users-with-acces-username">
                                                            {user.username}
                                                            {user.is_admin ?
                                                                <span class="setup-page-users-with-acces-admin"> (Admin)</span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div class="setup-page-users-with-acces-position">
                                                            {user.name}
                                                        </div>
                                                    </div>
                                                    {my_profile.user !== user.user ?
                                                        <div onClick={() => this.openEditUserAceesModal(user)} class="setup-page-users-with-acces-change-box">
                                                            <img
                                                                class="setup-page-users-with-acces-change-img"
                                                                src={require("../../../assets/hub/setup/svg/edit.svg")}
                                                                alt=""
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            ))}
                                            <div onClick={() => this.openAddUserAceesModal()} class="setup-page-users-with-acces-box-add">
                                                + Give Another User Access
                                            </div>
                                        </div>
                                    </>

                                </>
                            </div>
                        </div>



                    </>
                ))}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    users_with_access: state.hub.users_with_access,
    user_profile: state.profile.user_profile,
    searching: state.hub.searching,
    profile: state.profile.profile,
});

export default connect(mapStateToProps, {
    createAlert,
    getHubPage,
    getHubUsersWithAccess,
    getAddReservationProfile,
    createHubEmployee,
    changeHubEmployeeAccess,
    removeHubEmployeeAccess,
    getMyProfile,
})(SetupPage);