import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import '../../../assets/ambassandors/setSplash.css'
import {
    updateUserAmbassandor,
    getUserAmbasaddorsProfile
} from '../../../store/actions/ambassandor';
import { getMyProfile } from '../../../store/actions/profile';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { EditSocialModal } from '../../../components/user/ambassandor/EditSocialModal';
import Modal from 'react-awesome-modal';



export class UserSplash extends Component {

    state = {
        social: "",
        editSocialModal: false,
        selected: [],
        spalsh_epxlainer: false
    };

    componentDidMount() {
        this.props.getUserAmbasaddorsProfile()
        this.props.getMyProfile();
        setTimeout(() => {
            this.setState({
                spalsh_epxlainer: true
            })
        }, 2000);
    }


    dropped = (e) => {
        this.setState({
            social: e.sourceElem.firstElementChild.id
        })
        e.preventDefault();
        const selected_list = this.state.selected
        const selected_id = e.sourceElem.firstElementChild.id
        if ((selected_list.indexOf(selected_id)) !== -1) {
            const new_remove = selected_list.filter(f => f !== selected_id)
            this.setState({ selected: new_remove })
        } else {
            selected_list.push(selected_id)
            this.setState({ selected: selected_list })
        }

        const ambassandor = this.props.ambassandor[0]


        if (e.sourceElem.firstElementChild.id === "Instagram") {
            ambassandor.has_instagram = true
            this.props.updateUserAmbassandor(ambassandor)
            if (!ambassandor.instagram_username || !ambassandor.instagram_redirect || !ambassandor.instagram_username === "null" || ambassandor.instagram_redirect === "null") {
                this.setState({
                    social: e.sourceElem.firstElementChild.id,
                })
                setTimeout(() => {
                    this.setState({
                        editSocialModal: true,
                    })
                }, 300);
            }
        }

        if (e.sourceElem.firstElementChild.id === "Facebook") {
            ambassandor.has_facebook = true
            this.props.updateUserAmbassandor(ambassandor)
            if (!ambassandor.facebook_username || !ambassandor.facebook_redirect || !ambassandor.facebook_username === "null" || ambassandor.facebook_redirect === "null") {
                this.setState({
                    social: e.sourceElem.firstElementChild.id,
                })
                setTimeout(() => {
                    this.setState({
                        editSocialModal: true,
                    })
                }, 300);
            }
        }

        if (e.sourceElem.firstElementChild.id === "TikTok") {
            ambassandor.has_tiktok = true
            this.props.updateUserAmbassandor(ambassandor)
            if (!ambassandor.tiktok_username || !ambassandor.tiktok_redirect || !ambassandor.tiktok_username === "null" || ambassandor.tiktok_redirect === "null") {
                this.setState({
                    social: e.sourceElem.firstElementChild.id,
                })
                setTimeout(() => {
                    this.setState({
                        editSocialModal: true,
                    })
                }, 300);
            }
        }

        if (e.sourceElem.firstElementChild.id === "Twitter") {
            ambassandor.has_twitter = true
            this.props.updateUserAmbassandor(ambassandor)
            if (!ambassandor.twitter_username || !ambassandor.twitter_redirect || !ambassandor.twitter_username === "null" || ambassandor.twitter_redirect === "null") {
                this.setState({
                    social: e.sourceElem.firstElementChild.id,
                })
                setTimeout(() => {
                    this.setState({
                        editSocialModal: true,
                    })
                }, 300);
            }
        }

        if (e.sourceElem.firstElementChild.id === "Website") {
            ambassandor.has_website = true
            this.props.updateUserAmbassandor(ambassandor)
            if (!ambassandor.website || !ambassandor.website || !ambassandor.website === "null" || ambassandor.website === "null") {
                this.setState({
                    social: e.sourceElem.firstElementChild.id,
                })
                setTimeout(() => {
                    this.setState({
                        editSocialModal: true,
                    })
                }, 300);
            }
        }

        if (e.sourceElem.firstElementChild.id === "Phone") {
            ambassandor.has_mobile_number = true
            this.props.updateUserAmbassandor(ambassandor)
            if (!ambassandor.mobile_number || !ambassandor.mobile_number || !ambassandor.mobile_number === "null" || ambassandor.mobile_number === "null" || ambassandor.mobile_number === "0" || ambassandor.mobile_number === 0) {
                this.setState({
                    social: e.sourceElem.firstElementChild.id,
                })
                setTimeout(() => {
                    this.setState({
                        editSocialModal: true,
                    })
                }, 300);
            }
        }


        // e.containerElem.style.visibility = 'hidden';
    }

    removeDropped(selected_id) {
        const selected_list = this.state.selected
        if ((selected_list.indexOf(selected_id)) !== -1) {
            const new_remove = selected_list.filter(f => f !== selected_id)
            this.setState({ selected: new_remove })
        }

        const ambassandor = this.props.ambassandor[0]

        if (selected_id === "Instagram") {
            ambassandor.has_instagram = false
            this.props.updateUserAmbassandor(ambassandor)
        }
        if (selected_id === "Facebook") {
            ambassandor.has_facebook = false
            this.props.updateUserAmbassandor(ambassandor)
        }
        if (selected_id === "TikTok") {
            ambassandor.has_tiktok = false
            this.props.updateUserAmbassandor(ambassandor)
        }
        if (selected_id === "Twitter") {
            ambassandor.has_twitter = false
            this.props.updateUserAmbassandor(ambassandor)
        }
        if (selected_id === "Website") {
            ambassandor.has_website = false
            this.props.updateUserAmbassandor(ambassandor)
        }
        if (selected_id === "Phone") {
            ambassandor.has_mobile_number = false
            this.props.updateUserAmbassandor(ambassandor)
        }
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    openEditSocialModal(social) {
        this.setState({
            social: social,
            editSocialModal: true,
        })
    }

    closeModal = () => {
        this.setState({
            editSocialModal: false,
            // social: "",
        })
    }


    render() {
        let has_instagram = false
        let has_facebook = false
        let has_tiktok = false
        let has_twitter = false
        let has_phone = false
        let has_website = false


        let ambassandor = this.props.ambassandor
        if (ambassandor[0]) {
            ambassandor = this.props.ambassandor[0]
            if (ambassandor.has_instagram) {
                has_instagram = true
            }
            if (ambassandor.has_facebook) {
                has_facebook = true
            }
            if (ambassandor.has_tiktok) {
                has_tiktok = true
            }
            if (ambassandor.has_twitter) {
                has_twitter = true
            }
            if (ambassandor.has_mobile_number) {
                has_phone = true
            }
            if (ambassandor.has_website) {
                has_website = true
            }
        }

        if (this.state.selected.includes("Instagram")) {
            has_instagram = true
        }

        if (this.state.selected.includes("Facebook")) {
            has_facebook = true
        }

        if (this.state.selected.includes("TikTok")) {
            has_tiktok = true
        }

        if (this.state.selected.includes("Twitter")) {
            has_twitter = true
        }

        if (this.state.selected.includes("Phone")) {
            has_phone = true
        }

        if (this.state.selected.includes("Website")) {
            has_website = true
        }



        return (
            <Fragment>

                {this.state.editSocialModal ?
                    <Modal visible={this.state.editSocialModal} width="10" height="10" effect="fadeInUp">
                        <EditSocialModal
                            data={{
                                closeModal: this.closeModal.bind(this),
                                social: this.state.social,
                                ambassandor: this.props.ambassandor
                            }}
                            updateUserAmbassandor={this.props.updateUserAmbassandor}
                        />
                    </Modal>
                    :
                    null
                }

                {this.props.ambassandor.map(ambassandor => (
                    <>
                        <div>

                            <div class="set-splash-whole-page-layout">
                                <OfflineLabel />

                                <div id="page-scroll-up" class="set-splashs-mobile-navbar">

                                    <div onClick={() => this.handleBack()} class="back-button-set-splash-box">
                                        <img
                                            class="set-splash-back-button"

                                            src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                            alt=""
                                        />
                                    </div>
                                    Splash

                                </div>


                            </div>

                            {!has_instagram ?
                                <div class="set-splash-drag-instagram">
                                    <DragDropContainer targetKey="foo" >
                                        <div class={this.state.spalsh_epxlainer ? "set-splash-drag-tremple" : null} id="Instagram" >
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/instagram.png")}
                                                alt=""
                                            />

                                        </div>
                                    </DragDropContainer>
                                </div>
                                :
                                <div onClick={() => this.openEditSocialModal("Instagram")} class="set-splash-drag-instagram set-splash-drag-text">
                                    <div class="set-splash-drag-name">
                                        Instagram
                                    </div>
                                    {ambassandor.instagram_username && ambassandor.instagram_username !== "null" ?
                                        <div class="set-splash-drag-username">
                                            {ambassandor.instagram_username}
                                        </div>
                                        :
                                        <div class="set-splash-drag-username">
                                            -
                                        </div>
                                    }

                                    <div class="set-splash-drag-success">
                                        {ambassandor.instagram_username && ambassandor.instagram_redirect && ambassandor.instagram_username !== "null" && ambassandor.instagram_redirect !== "null" ?
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                alt=""
                                            />
                                            :

                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/important.png")}
                                                alt=""
                                            />
                                        }
                                    </div>

                                    <div class="set-splash-drag-edit-btn">
                                        <img
                                            class="set-splash-drag-logo"
                                            src={require("../../../assets/ambassandors/svg/dots.svg")}
                                            alt=""
                                        />
                                    </div>


                                </div>
                            }


                            {!has_facebook ?
                                <div class="set-splash-drag-facebook">
                                    <DragDropContainer targetKey="foo" >
                                        <div class={this.state.spalsh_epxlainer ? "set-splash-drag-tremple" : null} id="Facebook">
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </DragDropContainer>
                                </div>
                                :
                                <div onClick={() => this.openEditSocialModal("Facebook")} class="set-splash-drag-facebook set-splash-drag-text set-splash-drag-text-left">
                                    <div class="set-splash-drag-name">
                                        Facebook
                                    </div>
                                    {ambassandor.facebook_username && ambassandor.facebook_username !== "null" ?
                                        <div class="set-splash-drag-username">
                                            {ambassandor.facebook_username}
                                            {/* Christodoulos Christodoulou */}
                                        </div>
                                        :
                                        <div class="set-splash-drag-username">
                                            -
                                        </div>
                                    }

                                    <div class="set-splash-drag-success">
                                        {ambassandor.facebook_username && ambassandor.facebook_redirect && ambassandor.facebook_username !== "null" && ambassandor.facebook_redirect !== "null" ?
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                alt=""
                                            />
                                            :

                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/important.png")}
                                                alt=""
                                            />
                                        }
                                    </div>

                                    <div class="set-splash-drag-edit-btn">
                                        <img
                                            class="set-splash-drag-logo"
                                            src={require("../../../assets/ambassandors/svg/dots.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }

                            {!has_tiktok ?
                                <div class="set-splash-drag-tiktok">
                                    <DragDropContainer targetKey="foo" >
                                        <div class={this.state.spalsh_epxlainer ? "set-splash-drag-tremple" : null} id="TikTok">
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/tiktok.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </DragDropContainer>
                                </div>
                                :
                                <div onClick={() => this.openEditSocialModal("TikTok")} class="set-splash-drag-tiktok set-splash-drag-text">
                                    <div class="set-splash-drag-name">
                                        TikTok
                                    </div>
                                    {ambassandor.tiktok_username && ambassandor.tiktok_username !== "null" ?
                                        <div class="set-splash-drag-username">
                                            {ambassandor.tiktok_username}
                                        </div>
                                        :
                                        <div class="set-splash-drag-username">
                                            -
                                        </div>
                                    }

                                    <div class="set-splash-drag-success">
                                        {ambassandor.tiktok_username && ambassandor.tiktok_redirect && ambassandor.tiktok_username !== "null" && ambassandor.tiktok_redirect !== "null" ?
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                alt=""
                                            />
                                            :

                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/important.png")}
                                                alt=""
                                            />
                                        }
                                    </div>

                                    <div class="set-splash-drag-edit-btn">
                                        <img
                                            class="set-splash-drag-logo"
                                            src={require("../../../assets/ambassandors/svg/dots.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }

                            {!has_twitter ?
                                <div class="set-splash-drag-twitter">
                                    <DragDropContainer targetKey="foo" >
                                        <div class={this.state.spalsh_epxlainer ? "set-splash-drag-tremple" : null} id="Twitter">
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/twitter.svg")}
                                                alt=""
                                            />
                                        </div>
                                    </DragDropContainer>
                                </div>
                                :
                                <div onClick={() => this.openEditSocialModal("Twitter")} class="set-splash-drag-twitter set-splash-drag-text">
                                    <div class="set-splash-drag-name">
                                        Twitter
                                    </div>
                                    {ambassandor.twitter_username && ambassandor.twitter_username !== "null" ?
                                        <div class="set-splash-drag-username">
                                            {ambassandor.twitter_username}
                                        </div>
                                        :
                                        <div class="set-splash-drag-username">
                                            -
                                        </div>
                                    }

                                    <div class="set-splash-drag-success">
                                        {ambassandor.twitter_username && ambassandor.twitter_redirect && ambassandor.twitter_username !== "null" && ambassandor.twitter_redirect !== "null" ?
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                alt=""
                                            />
                                            :

                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/important.png")}
                                                alt=""
                                            />
                                        }
                                    </div>

                                    <div class="set-splash-drag-edit-btn">
                                        <img
                                            class="set-splash-drag-logo"
                                            src={require("../../../assets/ambassandors/svg/dots.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }

                            {!has_phone ?
                                <div class="set-splash-drag-phone">
                                    <DragDropContainer targetKey="foo" >
                                        <div class={this.state.spalsh_epxlainer ? "set-splash-drag-tremple" : null} id="Phone">
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/phone2.png")}
                                                alt=""
                                            />
                                        </div>
                                    </DragDropContainer>
                                </div>
                                :
                                <div onClick={() => this.openEditSocialModal("Phone")} class="set-splash-drag-phone set-splash-drag-text">
                                    <div class="set-splash-drag-name">
                                        Phone
                                    </div>
                                    {ambassandor.mobile_number && ambassandor.mobile_number !== "null" ?
                                        <div class="set-splash-drag-username">
                                            {ambassandor.mobile_number}
                                        </div>
                                        :
                                        <div class="set-splash-drag-username">
                                            -
                                        </div>
                                    }

                                    <div class="set-splash-drag-success">
                                        {ambassandor.mobile_number && ambassandor.mobile_number !== "null" ?
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                alt=""
                                            />
                                            :

                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/important.png")}
                                                alt=""
                                            />
                                        }
                                    </div>

                                    <div class="set-splash-drag-edit-btn">
                                        <img
                                            class="set-splash-drag-logo"
                                            src={require("../../../assets/ambassandors/svg/dots.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            }

                            {!has_website ?
                                <div class="set-splash-drag-website">
                                    <DragDropContainer targetKey="foo" >
                                        <div class={this.state.spalsh_epxlainer ? "set-splash-drag-tremple" : null} id="Website">
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/website.png")}
                                                alt=""
                                            />
                                        </div>
                                    </DragDropContainer>
                                </div>
                                :

                                <div onClick={() => this.openEditSocialModal("Website")} class="set-splash-drag-website set-splash-drag-text set-splash-drag-text-left">
                                    <div class="set-splash-drag-name">
                                        Website
                                    </div>
                                    {ambassandor.website && ambassandor.website !== "null" ?
                                        null
                                        :
                                        <div class="set-splash-drag-username">
                                            -
                                        </div>
                                    }

                                    <div class="set-splash-drag-success">
                                        {ambassandor.website && ambassandor.website !== "null" ?
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                alt=""
                                            />
                                            :
                                            <img
                                                class="set-splash-drag-logo"
                                                src={require("../../../assets/ambassandors/svg/important.png")}
                                                alt=""
                                            />
                                        }
                                    </div>

                                    <div class="set-splash-drag-edit-btn">
                                        <img
                                            class="set-splash-drag-logo"
                                            src={require("../../../assets/ambassandors/svg/dots.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>

                            }
                            <DropTarget
                                targetKey="foo"
                                onHit={this.dropped}

                            >

                                <div class="set-splash--page-title">
                                    Select the Social Media you want to be on your Splash
                                </div>

                                {this.props.profile.map(profile => (

                                    <div class="bigg-design-circle">
                                        <div class="set-splash-drop-postition">

                                            {this.state.spalsh_epxlainer && !has_instagram && !has_facebook && !has_tiktok && !has_twitter && !has_phone && !has_website ?
                                                <div class="set-splash-drop-space-glow" >

                                                    <div class="set-splash-drop-expainer">
                                                        <img
                                                            class="set-splash-drop-explainer-img"
                                                            src={require("../../../assets/ambassandors/svg/drag.svg")}
                                                            alt=""
                                                        />
                                                    </div>

                                                    <div class="set-splash-drop-space-explain" >
                                                        <b>Drag & Drop</b> <br />the Social Media<br />you want to<br />Share here!
                                                    </div>

                                                </div>
                                                : !has_instagram && !has_facebook && !has_tiktok && !has_twitter && !has_phone && !has_website ?
                                                    <div class="set-splash-drop-space-initial" >
                                                        <div class="set-splash-hubst3r-logo">
                                                            <img
                                                                class="set-splash-hubst3r-logo-img"
                                                                src={require("../../../assets/ambassandors/svg/logo-no-back.png")}
                                                                alt=""
                                                            />
                                                            <img
                                                                class="set-splash-hubst3r-name-img"
                                                                src={require("../../../assets/ambassandors/svg/logo-name-just.png")}
                                                                alt=""
                                                            />

                                                        </div>
                                                    </div>

                                                    :
                                                    <div class="set-splash-drop-space-glow" >

                                                        <div class="set-splash-drop-nfc">
                                                            <img
                                                                class="set-splash-nfc-img"
                                                                src={require("../../../assets/ambassandors/svg/nfc.png")}
                                                                alt=""
                                                            />
                                                        </div>

                                                        <div class="set-splash-drop-space-text" >
                                                            <b>Activate the other persons NFC</b><br />
                                                            and Share your Social Media
                                                        </div>



                                                        <div class="set-splash-drop-instagram">

                                                            {has_instagram ?
                                                                <>
                                                                    <img
                                                                        onClick={() => this.removeDropped("Instagram")}
                                                                        class="set-splash-drop-logo"
                                                                        src={require("../../../assets/ambassandors/svg/instagram.png")}
                                                                        alt=""
                                                                    />
                                                                    <div class="set-splash-drop-minus">
                                                                        <img
                                                                            onClick={() => this.removeDropped("Instagram")}
                                                                            class="set-splash-drop-minus-img"
                                                                            src={require("../../../assets/ambassandors/svg/minus.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <img
                                                                    class="set-splash-drop-logo-blur"
                                                                    src={require("../../../assets/ambassandors/svg/instagram.png")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>

                                                        <div class="set-splash-drop-facebook">
                                                            {has_facebook ?
                                                                <>
                                                                    <img
                                                                        class="set-splash-drop-logo"
                                                                        onClick={() => this.removeDropped("Facebook")}
                                                                        src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                                        alt=""
                                                                    />
                                                                    <div class="set-splash-drop-minus">
                                                                        <img
                                                                            onClick={() => this.removeDropped("Facebook")}
                                                                            class="set-splash-drop-minus-img"
                                                                            src={require("../../../assets/ambassandors/svg/minus.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <img
                                                                    class="set-splash-drop-logo-blur"
                                                                    src={require("../../../assets/ambassandors/svg/facebook.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>

                                                        <div class="set-splash-drop-tiktok">
                                                            {has_tiktok ?
                                                                <>
                                                                    <img
                                                                        class="set-splash-drop-logo"
                                                                        onClick={() => this.removeDropped("TikTok")}
                                                                        src={require("../../../assets/ambassandors/svg/tiktok.svg")}
                                                                        alt=""
                                                                    />
                                                                    <div class="set-splash-drop-minus">
                                                                        <img
                                                                            onClick={() => this.removeDropped("titok")}
                                                                            class="set-splash-drop-minus-img"
                                                                            src={require("../../../assets/ambassandors/svg/minus.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <img
                                                                    class="set-splash-drop-logo-blur"
                                                                    src={require("../../../assets/ambassandors/svg/tiktok.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>

                                                        <div class="set-splash-drop-twitter">
                                                            {has_twitter ?
                                                                <>
                                                                    <img
                                                                        class="set-splash-drop-logo"
                                                                        onClick={() => this.removeDropped("Twitter")}
                                                                        src={require("../../../assets/ambassandors/svg/twitter.svg")}
                                                                        alt=""
                                                                    />
                                                                    <div class="set-splash-drop-minus">
                                                                        <img
                                                                            onClick={() => this.removeDropped("Twitter")}
                                                                            class="set-splash-drop-minus-img"
                                                                            src={require("../../../assets/ambassandors/svg/minus.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <img
                                                                    class="set-splash-drop-logo-blur"
                                                                    src={require("../../../assets/ambassandors/svg/twitter.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>

                                                        <div class="set-splash-drop-phone">
                                                            {has_phone ?
                                                                <>
                                                                    <img
                                                                        class="set-splash-drop-logo"
                                                                        onClick={() => this.removeDropped("Phone")}
                                                                        src={require("../../../assets/ambassandors/svg/phone2.png")}
                                                                        alt=""
                                                                    />
                                                                    <div class="set-splash-drop-minus">
                                                                        <img
                                                                            onClick={() => this.removeDropped("Phone")}
                                                                            class="set-splash-drop-minus-img"
                                                                            src={require("../../../assets/ambassandors/svg/minus.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <img
                                                                    class="set-splash-drop-logo-blur"
                                                                    src={require("../../../assets/ambassandors/svg/phone2.png")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>

                                                        <div class="set-splash-drop-website">
                                                            {has_website ?
                                                                <>
                                                                    <img
                                                                        class="set-splash-drop-logo"
                                                                        onClick={() => this.removeDropped("Website")}
                                                                        src={require("../../../assets/ambassandors/svg/website.png")}
                                                                        alt=""
                                                                    />
                                                                    <div class="set-splash-drop-minus">
                                                                        <img
                                                                            onClick={() => this.removeDropped("Website")}
                                                                            class="set-splash-drop-minus-img"
                                                                            src={require("../../../assets/ambassandors/svg/minus.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <img
                                                                    class="set-splash-drop-logo-blur"
                                                                    src={require("../../../assets/ambassandors/svg/website.png")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>

                                                        <div class="set-splash-drop-bottom-text">
                                                            Press to remove from Splash
                                                        </div>

                                                    </div>

                                            }
                                        </div>
                                    </div>
                                ))}
                            </DropTarget>
                        </div>
                        <Link to="/my-profile">
                            <>
                                <div onTouchStart class="set-splash-done-btn">
                                    Done
                                </div>
                            </>
                        </Link>

                    </>
                ))}

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    ambassandor: state.ambassandor.ambassandor,
    profile: state.profile.profile,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    updateUserAmbassandor,
    getMyProfile,
    getUserAmbasaddorsProfile,
})(UserSplash);







// import React, { Fragment, Component } from 'react';
// import { Link, Redirect } from 'react-router-dom';
// import { connect } from 'react-redux';
// import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
// import OfflineLabel from '../../../components/user/layout/offlineLabel';
// import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
// import BottomNavbar from '../../../components/user/layout/bottomNavbar';
// import '../../../assets/hub/hub-info/hub-splash.css'
// import {
//     updateUserAmbassandor,
//     getUserAmbasaddorsProfile
// } from '../../../store/actions/ambassandor';
// import { getMyProfile } from '../../../store/actions/profile';

// import { ChangeSplashProfile } from '../../../components/user/user-splash/ChangeSplashProfile';
// import { ChangeSplashCover } from '../../../components/user/user-splash/ChangeSplashCover';




// export class UserSplash extends Component {

//     state = {
//         has_instagram: false,
//         instagram_change: false,
//         instagram_username: "",
//         instagram_redirect: "",
//         has_facebook: false,
//         facebook_change: false,
//         facebook_username: "",
//         facebook_redirect: "",
//         website: "",
//         mobile_number: "",
//         email: "",
//         has_tiktok: false,
//         tiktok_change: false,
//         tiktok_username: "",
//         tiktok_redirect: "",
//         has_website: false,
//         website_change: false,
//         has_mobile_number: false,
//         mobile_number_change: false,
//         mobile_number: "",
//         has_email: false,
//         email_change: false,
//         email: "",
//         redirect: false
//     };

//     componentDidMount() {
//         this.props.getUserAmbasaddorsProfile()
//         this.props.getMyProfile();
//     }

//     onChange = e => this.setState({ [e.target.name]: e.target.value });


//     changeInstagram = (answer) => {
//         this.setState({
//             instagram_change: true,
//             has_instagram: answer,
//         })
//     }

//     changeFacebook = (answer) => {
//         this.setState({
//             facebook_change: true,
//             has_facebook: answer,
//         })
//     }

//     changeTiktok = (answer) => {
//         this.setState({
//             tiktok_change: true,
//             has_tiktok: answer,
//         })
//     }

//     changeEmail = (answer) => {
//         this.setState({
//             email_change: true,
//             has_email: answer,
//         })
//     }

//     changeMobile = (answer) => {
//         this.setState({
//             mobile_number_change: true,
//             has_mobile_number: answer,
//         })
//     }

//     changeWebsite = (answer) => {
//         this.setState({
//             website_change: true,
//             has_website: answer,
//         })
//     }

//     handleBack = () => {
//         this.props.history.goBack()
//     }

//     updateUserAmbassandorPage = (e) => {
//         const ambassandor = this.props.ambassandor[0]
//         ambassandor.ambassandor_profile_picture = ""
//         ambassandor.ambassandor_cover_photo = ""
//         if (this.state.instagram_username !== "") {
//             ambassandor.instagram_username = this.state.instagram_username
//         }
//         if (this.state.instagram_redirect !== "") {
//             ambassandor.instagram_redirect = this.state.instagram_redirect
//         }

//         if (this.state.facebook_username !== "") {
//             ambassandor.facebook_username = this.state.facebook_username
//         }

//         if (this.state.facebook_redirect !== "") {
//             ambassandor.facebook_redirect = this.state.facebook_redirect
//         }
//         if (this.state.tiktok_username !== "") {
//             ambassandor.tiktok_username = this.state.tiktok_username
//         }
//         if (this.state.tiktok_redirect !== "") {
//             ambassandor.tiktok_redirect = this.state.tiktok_redirect
//         }
//         if (this.state.website !== "") {
//             ambassandor.website = this.state.website
//         }
//         if (this.state.mobile_number !== "") {
//             ambassandor.mobile_number = this.state.mobile_number
//         }
//         if (this.state.email !== "") {
//             ambassandor.email = this.state.email
//         }
//         if (this.state.instagram_change) {
//             ambassandor.has_instagram = this.state.has_instagram
//         }
//         if (this.state.facebook_change) {
//             ambassandor.has_facebook = this.state.has_facebook
//         }
//         if (this.state.website_change) {
//             ambassandor.has_website = this.state.has_website
//         }
//         if (this.state.tiktok_change) {
//             ambassandor.has_tiktok = this.state.has_tiktok
//         }
//         if (this.state.mobile_number_change) {
//             ambassandor.has_mobile_number = this.state.has_mobile_number
//         }
//         if (this.state.email_change) {
//             ambassandor.has_email = this.state.has_email
//         }
//         this.props.updateUserAmbassandor(ambassandor)
//         this.setState({
//             redirect: true
//         })
//     }

//     onProfilePictureSubmit = (profile_picture) => {
//         const ambassandor = this.props.ambassandor[0]
//         ambassandor.ambassandor_profile_picture = profile_picture
//         ambassandor.ambassandor_cover_photo = ""
//         // console.log(ambassandor)
//         this.props.updateUserAmbassandor(ambassandor)
//     }

//     onCoverPhotoSubmit = (cover_photo) => {
//         const ambassandor = this.props.ambassandor[0]
//         ambassandor.ambassandor_profile_picture = ""
//         ambassandor.ambassandor_cover_photo = cover_photo
//         // console.log(ambassandor)
//         this.props.updateUserAmbassandor(ambassandor)
//     }

//     render() {
//         if (this.state.redirect) {
//             return <Redirect to="/my-profile" />
//         }
//         const ambassandor = this.props.ambassandor[0]
//         let has_facebook = false
//         let has_instagram = false
//         let has_website = false
//         let has_tiktok = false
//         let has_mobile_number = false
//         let has_email = false

//         if (ambassandor) {
//             // 2
//             has_facebook = ambassandor.has_facebook
//             if (this.state.facebook_change) {
//                 has_facebook = this.state.has_facebook
//             }
//             // 3
//             has_instagram = ambassandor.has_instagram
//             if (this.state.instagram_change) {
//                 has_instagram = this.state.has_instagram
//             }
//             // 5
//             has_website = ambassandor.has_website
//             if (this.state.website_change) {
//                 has_website = this.state.has_website
//             }
//             // 6
//             has_tiktok = ambassandor.has_tiktok
//             if (this.state.tiktok_change) {
//                 has_tiktok = this.state.has_tiktok
//             }
//             // 7
//             has_mobile_number = ambassandor.has_mobile_number
//             if (this.state.mobile_number_change) {
//                 has_mobile_number = this.state.has_mobile_number
//             }
//             // 8
//             has_email = ambassandor.has_email
//             if (this.state.email_change) {
//                 has_email = this.state.has_email
//             }
//         }
//         return (
//             <Fragment>

//             </Fragment >
//         )
//     }
// }

// const mapStateToProps = state => ({
//     ambassandor: state.ambassandor.ambassandor,
//     profile: state.profile.profile,
//     notifications_status: state.notification.notifications_status,
//     isLoading: state.layout.isLoading,
//     noInternet: state.layout.noInternet
// });

// export default connect(mapStateToProps, {
//     updateUserAmbassandor,
//     getMyProfile,
//     getUserAmbasaddorsProfile,
// })(UserSplash);







//                 // {this.props.ambassandor.map(ambassandor => (
//                 //     <div class="ambassandor-all-page-display">
//                 //         <div>
//                 //             <div class="ambassandors-page-whole-page-layout">
//                 //                 <OfflineLabel />
//                 //                 <div class="color-4">
//                 //                     <div class="container-fluid">
//                 //                         <div class="row">
//                 //                             <div class="container-fluid small-header">
//                 //                                 <div class="row py-4">
//                 //                                     <div class="">
//                 //                                         <img
//                 //                                             class="ambassandor-top-back-button"
//                 //                                             onClick={this.handleBack}
//                 //                                             src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
//                 //                                             alt=""
//                 //                                         />
//                 //                                     </div>
//                 //                                     <div class="ambassandor-top-bar-logo">
//                 //                                         <Link to="/">
//                 //                                             <img src={require("../../../assets/layout/homepage svg/Logo.svg")} alt="" />
//                 //                                         </Link>
//                 //                                     </div>
//                 //                                 </div>
//                 //                             </div>
//                 //                             <div class="ambassandors-body-right col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
//                 //                                 <>
//                 //                                     <div>
//                 //                                         <div class="ambassandor-profile-cover-photo">
//                 //                                             <ChangeSplashCover
//                 //                                                 data={{
//                 //                                                     ambassandor_cover_photo: ambassandor.ambassandor_cover_photo,
//                 //                                                     onCoverPhotoSubmit: this.onCoverPhotoSubmit.bind(this)
//                 //                                                 }}
//                 //                                             />

//                 //                                         </div>
//                 //                                         {this.props.profile.map(profile => (
//                 //                                             <div class="ambassandor-profile-profile-photo">
//                 //                                                 <img
//                 //                                                     src={profile.profile_picture}
//                 //                                                     class="ambassandor-profile-profile-img"
//                 //                                                     alt=""
//                 //                                                 />
//                 //                                                 {/* <ChangeSplashProfile
//                 //                                                 data={{
//                 //                                                     ambassandor_profile_picture: ambassandor.ambassandor_profile_picture,
//                 //                                                     onProfilePictureSubmit: this.onProfilePictureSubmit.bind(this)
//                 //                                                 }}
//                 //                                             /> */}
//                 //                                             </div>
//                 //                                         ))}
//                 //                                         <div class="ambassandor-profile-username-layout">
//                 //                                             <h2 class="ambassandor-profile-username">
//                 //                                                 {ambassandor.name}
//                 //                                             </h2>
//                 //                                         </div>
//                 //                                     </div>
//                 //                                     <hr />


//                 //                                     <div class="ambassandor-profile-hiring-question">
//                 //                                         Do you have an Instagram Account?
//                 //                                     </div>
//                 //                                     <br />
//                 //                                     <div class="row">
//                 //                                         <div
//                 //                                             onClick={() => this.changeInstagram(true)}
//                 //                                             class={has_instagram ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }
//                 //                                         >
//                 //                                             Yes
//                 //                                         </div>
//                 //                                         <div
//                 //                                             onClick={() => this.changeInstagram(false)}
//                 //                                             class={!has_instagram ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }                                                            >
//                 //                                             No
//                 //                                         </div>

//                 //                                     </div>
//                 //                                     {has_instagram ?
//                 //                                         <div class="ambassandor-we-are-hiring">
//                 //                                             <img
//                 //                                                 class="ambassandor-hiring-icon-size"
//                 //                                                 src={require("../../../assets/ambassandors/svg/instagram.svg")}
//                 //                                                 alt=""
//                 //                                             />
//                 //                                             <h2 class="ambassandor-profile-username">
//                 //                                                 Instagram Username
//                 //                                             </h2>
//                 //                                             <input
//                 //                                                 class="hub-info-input mb-3"
//                 //                                                 type="text"
//                 //                                                 defaultValue={ambassandor.instagram_username}
//                 //                                                 onChange={this.onChange}
//                 //                                                 name="instagram_username"
//                 //                                             />
//                 //                                             <h2 class="ambassandor-profile-username">
//                 //                                                 Instagram URL
//                 //                                             </h2>
//                 //                                             <input
//                 //                                                 class="hub-info-input mb-3"
//                 //                                                 type="text"
//                 //                                                 defaultValue={ambassandor.instagram_redirect}
//                 //                                                 onChange={this.onChange}
//                 //                                                 name="instagram_redirect"
//                 //                                             />
//                 //                                         </div>
//                 //                                         :
//                 //                                         null
//                 //                                     }

//                 //                                     <br /><br /><br /><br /><br /><br />
//                 //                                     <hr />



//                 //                                     <div class="ambassandor-profile-hiring-question">
//                 //                                         Do you have a Facebook Account?
//                 //                                     </div>
//                 //                                     <br />
//                 //                                     <div class="row">
//                 //                                         <div
//                 //                                             onClick={() => this.changeFacebook(true)}
//                 //                                             class={has_facebook ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }
//                 //                                         >
//                 //                                             Yes
//                 //                                         </div>
//                 //                                         <div
//                 //                                             onClick={() => this.changeFacebook(false)}
//                 //                                             class={!has_facebook ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }                                                            >
//                 //                                             No
//                 //                                         </div>

//                 //                                     </div>
//                 //                                     {has_facebook ?
//                 //                                         <div class="ambassandor-we-are-hiring">
//                 //                                             <img
//                 //                                                 class="ambassandor-hiring-icon-size"
//                 //                                                 src={require("../../../assets/ambassandors/svg/facebook.svg")}
//                 //                                                 alt=""
//                 //                                             />
//                 //                                             <div class="ambassandor-profile-username-layout">
//                 //                                                 <h2 class="ambassandor-profile-username">
//                 //                                                     Facebook Username
//                 //                                                 </h2>
//                 //                                                 <input
//                 //                                                     class="hub-info-input mb-3"
//                 //                                                     type="text"
//                 //                                                     defaultValue={ambassandor.facebook_username}
//                 //                                                     onChange={this.onChange}
//                 //                                                     name="facebook_username"
//                 //                                                 />
//                 //                                                 <h2 class="ambassandor-profile-username">
//                 //                                                     Facebook URL
//                 //                                                 </h2>
//                 //                                                 <input
//                 //                                                     class="hub-info-input mb-3"
//                 //                                                     type="text"
//                 //                                                     defaultValue={ambassandor.facebook_redirect}
//                 //                                                     onChange={this.onChange}
//                 //                                                     name="facebook_redirect"
//                 //                                                 />
//                 //                                             </div>
//                 //                                         </div>
//                 //                                         :
//                 //                                         null
//                 //                                     }

//                 //                                     <br /><br /><br /><br /><br /><br />
//                 //                                     <hr />


//                 //                                     <div class="ambassandor-profile-hiring-question">
//                 //                                         Do you have a TikTok Account?
//                 //                                     </div>
//                 //                                     <br />
//                 //                                     <div class="row">
//                 //                                         <div
//                 //                                             onClick={() => this.changeTiktok(true)}
//                 //                                             class={has_tiktok ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }
//                 //                                         >
//                 //                                             Yes
//                 //                                         </div>
//                 //                                         <div
//                 //                                             onClick={() => this.changeTiktok(false)}
//                 //                                             class={!has_tiktok ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }                                                            >
//                 //                                             No
//                 //                                         </div>

//                 //                                     </div>
//                 //                                     {has_tiktok ?
//                 //                                         <div class="ambassandor-we-are-hiring">
//                 //                                             <img
//                 //                                                 class="ambassandor-hiring-icon-size"
//                 //                                                 src={require("../../../assets/ambassandors/svg/tiktok.svg")}
//                 //                                                 alt=""
//                 //                                             />
//                 //                                             <div class="ambassandor-profile-username-layout">
//                 //                                                 <h2 class="ambassandor-profile-username">
//                 //                                                     TikTok Username
//                 //                                                 </h2>
//                 //                                                 <input
//                 //                                                     class="hub-info-input mb-3"
//                 //                                                     type="text"
//                 //                                                     defaultValue={ambassandor.tiktok_username}
//                 //                                                     onChange={this.onChange}
//                 //                                                     name="tiktok_username"
//                 //                                                 />
//                 //                                                 <h2 class="ambassandor-profile-username">
//                 //                                                     TikTok URL
//                 //                                                 </h2>
//                 //                                                 <input
//                 //                                                     class="hub-info-input mb-3"
//                 //                                                     type="text"
//                 //                                                     defaultValue={ambassandor.tiktok_redirect}
//                 //                                                     onChange={this.onChange}
//                 //                                                     name="tiktok_redirect"
//                 //                                                 />
//                 //                                             </div>
//                 //                                         </div>
//                 //                                         :
//                 //                                         null
//                 //                                     }

//                 //                                     <br /><br /><br /><br /><br /><br />
//                 //                                     <hr />



//                 //                                     <div class="ambassandor-profile-hiring-question">
//                 //                                         Do you have a Website?
//                 //                                     </div>
//                 //                                     <br />
//                 //                                     <div class="row">
//                 //                                         <div
//                 //                                             onClick={() => this.changeWebsite(true)}
//                 //                                             class={has_website ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }
//                 //                                         >
//                 //                                             Yes
//                 //                                         </div>
//                 //                                         <div
//                 //                                             onClick={() => this.changeWebsite(false)}
//                 //                                             class={!has_website ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }                                                            >
//                 //                                             No
//                 //                                         </div>
//                 //                                     </div>
//                 //                                     {has_website ?
//                 //                                         <div class="ambassandor-we-are-hiring">
//                 //                                             <img
//                 //                                                 class="ambassandor-hiring-icon-size"
//                 //                                                 src={require("../../../assets/ambassandors/svg/website.png")}
//                 //                                                 alt=""
//                 //                                             />
//                 //                                             <div class="ambassandor-profile-username-layout">
//                 //                                                 <h2 class="ambassandor-profile-username">
//                 //                                                     Website URL
//                 //                                                 </h2>
//                 //                                                 <input
//                 //                                                     class="hub-info-input mb-3"
//                 //                                                     type="text"
//                 //                                                     defaultValue={ambassandor.website}
//                 //                                                     onChange={this.onChange}
//                 //                                                     name="Website"
//                 //                                                 />
//                 //                                             </div>
//                 //                                         </div>
//                 //                                         :
//                 //                                         null
//                 //                                     }


//                 //                                     <br /><br /><br /><br /><br /><br />
//                 //                                     <hr />


//                 //                                     <div class="ambassandor-profile-hiring-question">
//                 //                                         Do you have a Phone Number you want to share?
//                 //                                     </div>
//                 //                                     <br />
//                 //                                     <div class="row">
//                 //                                         <div
//                 //                                             onClick={() => this.changeMobile(true)}
//                 //                                             class={has_mobile_number ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }
//                 //                                         >
//                 //                                             Yes
//                 //                                         </div>
//                 //                                         <div
//                 //                                             onClick={() => this.changeMobile(false)}
//                 //                                             class={!has_mobile_number ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }                                                            >
//                 //                                             No
//                 //                                         </div>
//                 //                                     </div>
//                 //                                     {has_mobile_number ?
//                 //                                         <div class="ambassandor-we-are-hiring">
//                 //                                             <img
//                 //                                                 class="ambassandor-hiring-icon-size"
//                 //                                                 src={require("../../../assets/ambassandors/svg/phone.png")}
//                 //                                                 alt=""
//                 //                                             />
//                 //                                             <div class="ambassandor-profile-username-layout">
//                 //                                                 <h2 class="ambassandor-profile-username">
//                 //                                                     Phone Number
//                 //                                                 </h2>
//                 //                                                 <input
//                 //                                                     class="hub-info-input mb-3"
//                 //                                                     type="text"
//                 //                                                     defaultValue={ambassandor.mobile_number}
//                 //                                                     onChange={this.onChange}
//                 //                                                     name="Phone"
//                 //                                                     maxlength="8"
//                 //                                                 />
//                 //                                             </div>
//                 //                                         </div>
//                 //                                         :
//                 //                                         null
//                 //                                     }



//                 //                                     <br /><br /><br /><br /><br /><br />
//                 //                                     <hr />

//                 //                                     <div class="ambassandor-profile-hiring-question">
//                 //                                         Do you have an Email you want to share?
//                 //                                     </div>
//                 //                                     <br />
//                 //                                     <div class="row">
//                 //                                         <div
//                 //                                             onClick={() => this.changeEmail(true)}
//                 //                                             class={has_email ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }
//                 //                                         >
//                 //                                             Yes
//                 //                                         </div>
//                 //                                         <div
//                 //                                             onClick={() => this.changeEmail(false)}
//                 //                                             class={!has_email ?
//                 //                                                 "ambassandor-profile-question-sellected"
//                 //                                                 :
//                 //                                                 "ambassandor-profile-question-not-sellected"
//                 //                                             }                                                            >
//                 //                                             No
//                 //                                         </div>
//                 //                                     </div>
//                 //                                     {has_email ?
//                 //                                         <div class="ambassandor-we-are-hiring">
//                 //                                             <img
//                 //                                                 class="ambassandor-hiring-icon-size"
//                 //                                                 src={require("../../../assets/ambassandors/svg/emial.svg")}
//                 //                                                 alt=""
//                 //                                             />
//                 //                                             <div class="ambassandor-profile-username-layout">
//                 //                                                 <h2 class="ambassandor-profile-username">
//                 //                                                     Email
//                 //                                                 </h2>
//                 //                                                 <input
//                 //                                                     class="hub-info-input mb-3"
//                 //                                                     type="text"
//                 //                                                     defaultValue={ambassandor.email}
//                 //                                                     onChange={this.onChange}
//                 //                                                     name="email"
//                 //                                                 />
//                 //                                             </div>
//                 //                                         </div>
//                 //                                         :
//                 //                                         null
//                 //                                     }
//                 //                                     <br /><br /><br />
//                 //                                     <hr />
//                 //                                     <div
//                 //                                         onClick={() => this.updateUserAmbassandorPage()}
//                 //                                         class="ambassandor-profile-save-button">
//                 //                                         Save
//                 //                                     </div>

//                 //                                 </>
//                 //                                 <div class="ambassandor-gdpr-place">
//                 //                                     <HubGdprLinks />
//                 //                                 </div>

//                 //                             </div>
//                 //                         </div>


//                 //                     </div>

//                 //                 </div>
//                 //             </div>
//                 //         </div>
//                 //     </div>
//                 // ))}

//                 // <BottomNavbar data={{ sellected: "", notifications_status: this.props.notifications_status }} />