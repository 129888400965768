import React from 'react';
import "../../../assets/hub/hub-info/hub-jobs-modal.css"



class ShowJobModal extends React.Component {
    state = {
        name: "",
        description: "",
        money: ""
    };

    closeModal = () => {
        this.props.data.closeModal()
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onDeleteJob = (e) => {
        const id = this.props.data.job.id
        this.props.deleteAmbassandorHubjob(id)
        this.props.data.closeModal()
    }

    render() {
        const job = this.props.data.job
        return (
            <div class="">
                <div class="row">
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                    <div class="create-job-box">
                        <div class="row text-right">
                            <div class="col-xl-12 text-right">
                                <div
                                    onClick={() => this.closeModal()}
                                    class="create-job-times-frame">
                                    <img class="create-job-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                            </div>
                        </div>
                        {/* <div class="text-left">
                            <h4>
                                <strong class="create-job-hub-name">
                                    How the Job appears
                                </strong>
                            </h4>
                        </div> */}

                        <p class="create-job-hub-section-title">Name of the Job</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-text-show">
                                    {job.name}
                                </strong>
                            </h4>
                        </div>

                        <p class="create-job-hub-section-title">Job Description</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-text-show">
                                    {job.description}
                                </strong>
                            </h4>
                        </div>

                        <p class="create-job-hub-section-title">Monthly Salary for the Job</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-text-show">
                                    {job.money}
                                </strong>
                            </h4>
                        </div>

                        <div onClick={this.onDeleteJob} class="create-job-delete-btn">
                            Delete
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                    </div>
                </div>
            </div>
        )
    }
}


export default (ShowJobModal);