import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const UserPrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            const user = JSON.parse(localStorage.getItem('ur'))
            const shops = JSON.parse(localStorage.getItem('shp'))


            if (user) {
                if (shops && shops.length > 0) {
                    return <Redirect to="/shop-categories" />
                } else {
                    if (user.hub_id === 0) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect to="/hub-homepage" />
                    }
                }
            } else {
                return <Redirect to="/register" />;
            }
        }}
    />
);

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(UserPrivateRoute);