import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/hub/hub-layout/desktopNavbar.css"


export class HubDesktopNavbar extends Component {

    render() {
        const sellected = this.props.data.sellected
        return (
            <Fragment>
                <div class="desktop-body-left-12 col-xl-2 col-lg-3" id="sticky-sidebar">
                    <img class="desktop-hub-logo" src={require("../../../assets/booked_page/svg/Logo.svg")} alt="" />

                    <div class="row hub-left-home-12 py-3">
                        {sellected === "pending" ?
                            <div class="col-xl-6 col-lg-6 left-icon-12 text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/booked_page/svg/hub-sellected-pending.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon-12 text-right">
                                <img src={require("../../../assets/booked_page/svg/Reservation Request.svg")} alt="" />
                            </div>
                        }
                        <Link to="/pending-reservations">
                            <div class="col-xl-8 col-lg-8 desktop-hub-left-text desktop-hub-text-white mt-1">
                                {sellected === "pending" ?
                                    <strong class="desktop-navbar-sellected">Reservation Requests</strong>
                                    :
                                    <strong>Reservation Requests</strong>
                                }
                            </div>
                        </Link>
                    </div>

                    <div class="row hub-left-home-12 py-3">
                        {sellected === "booked" ?
                            <div class="col-xl-6 col-lg-6 left-icon-12 text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/booked_page/svg/hub-sellected-reservations.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon-12 text-right">
                                <img src={require("../../../assets/booked_page/svg/Reservations white.svg")} alt="" />
                            </div>
                        }
                        <Link to="/approved-reservations">
                            <div class="col-xl-8 col-lg-8 desktop-hub-left-text desktop-hub-text-white mt-1">
                                {sellected === "booked" ?
                                    <strong class="desktop-navbar-sellected">Reservations</strong>
                                    :
                                    <strong>Reservations</strong>
                                }
                            </div>
                        </Link>
                    </div>

                    {/* <div class="row hub-left-home-12 py-3">
                        {sellected === "gallery" ?
                            <div class="col-xl-6 col-lg-6 left-icon-12 text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/booked_page/svg/hub-sellected-gallery.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon-12 text-right">
                                <img src={require("../../../assets/booked_page/svg/gallery.svg")} alt="" />
                            </div>
                        }
                        <Link to="/hub-memories">
                            <div class="col-xl-8 col-lg-8 desktop-hub-left-text desktop-hub-text-white mt-1">
                                {sellected === "gallery" ?
                                    <strong class="desktop-navbar-sellected">Gallery</strong>
                                    :
                                    <strong>Gallery</strong>
                                }
                            </div>
                        </Link>
                    </div> */}

                    {/* <div class="row hub-left-home-12 py-3">
                        {sellected === "analytics" ?
                            <div class="col-xl-6 col-lg-6 left-icon-12 text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/booked_page/svg/hub-sellected-analytics.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon-12 text-right">
                                <img src={require("../../../assets/booked_page/svg/Analytics-w.svg")} alt="" />
                            </div>
                        }
                        <Link to="/analytics">
                            <div class="col-xl-8 col-lg-8 desktop-hub-left-text desktop-hub-text-white mt-1">
                                {sellected === "analytics" ?
                                    <strong class="desktop-navbar-sellected">Analytics</strong>
                                    :
                                    <strong>Analytics</strong>
                                }
                            </div>
                        </Link>
                    </div> */}

                    <div class="row hub-left-home-12 py-3">
                        {sellected === "info" ?
                            <div class="col-xl-6 col-lg-6 left-icon-12 text-right user-desktop-navbar-sellected">
                                <img src={require("../../../assets/booked_page/svg/hub-sellected-info.svg")} alt="" />
                            </div>
                            :
                            <div class="col-xl-4 col-lg-4 left-icon-12 text-right">
                                <img src={require("../../../assets/booked_page/svg/information-w.svg")} alt="" />
                            </div>
                        }

                        <Link to="/information">
                            <div class="col-xl-8 col-lg-8 desktop-hub-left-text desktop-hub-text-white mt-1">
                                {sellected === "info" ?
                                    <strong class="desktop-navbar-sellected">Profile</strong>
                                    :
                                    <strong>Profile</strong>
                                }
                            </div>
                        </Link>
                    </div>
                </div>

            </Fragment >
        )
    }
}

export default (HubDesktopNavbar);