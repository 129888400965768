import {
    GET_HOMEPAGE_MEMORIES,
    MEMORIES_LOCALY_LOADED,
    GET_HOMEPAGE_SUGGESTED_MEMORIES,
    SUGGESTED_MEMORIES_LOCALY_LOADED,
    USER_MEMORIES_LOCALY_LOADED,
    GET_FULLSCREEN_MEMORY,
    GET_HUB_MEMORIES,
    GET_USER_MEMORIES,
    GET_MY_MEMORIES,
    MY_MEMORIES_LOCALY_LOADED,
    GET_HUB_APPROVED_MEMORIES,
    APPROVED_MEMORIES_LOCALY_LOADED,
    GET_HUB_DECLINED_MEMORIES,
    DECLINED_MEMORIES_LOCALY_LOADED,
    GET_HUB_UNREVIEWED_MEMORIES,
    UNREVIEWS_MEMORIES_LOCALY_LOADED,
    GET_MEMORY_IMPRESSIONS,
    CLEAR_MEMORY,
    GET_HOMEPAGE_PROMOS,
    PROMOS_LOCALY_LOADED,
    DELETE_MEMORY,
    DELETE_REACTION,
    UPLOAD_MEMORY,
    GET_MEMORY_TAGS,
    ADD_BUDDY_TAG,
    REMOVE_BUDDY_TAG,
    REMOVE_MY_TAG,
    ACCEPT_MEMORY_TO_PROFILE,
    DECLINE_MEMORY_TO_PROFILE,
    CLEAR_HUB_MEMORIES,
    CLEAR_MEMORY_TAGS,
} from '../actions/actionTypes';

const initialState = {
    memories: [],
    my_memories: [],
    suggested_memories: [],
    buddies_memories: [],
    hub_memories: [],
    unreviewed_memories: [],
    declined_memories: [],
    approved_memories: [],
    promos: [],
    tags: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_MEMORY:
            return {
                ...state,
                memories: [],
                hub_memories: []
            };
        case GET_HOMEPAGE_MEMORIES:
            localStorage.setItem('bdsmm', JSON.stringify(action.payload));
            return {
                ...state,
                buddies_memories: action.payload
            };

        case MEMORIES_LOCALY_LOADED:
            return {
                ...state,
                buddies_memories: JSON.parse(localStorage.getItem('bdsmm'))
            };
        case GET_HOMEPAGE_SUGGESTED_MEMORIES:
            localStorage.setItem('hmpgsgstdmmrs', JSON.stringify(action.payload));
            return {
                ...state,
                suggested_memories: action.payload
            };
        case SUGGESTED_MEMORIES_LOCALY_LOADED:
            return {
                ...state,
                suggested_memories: JSON.parse(localStorage.getItem('hmpgsgstdmmrs'))
            };
        case GET_MEMORY_TAGS:
            return {
                ...state,
                tags: action.payload
            };
        case GET_HOMEPAGE_PROMOS:
            localStorage.setItem('hmpgprm', JSON.stringify(action.payload));
            return {
                ...state,
                promos: action.payload
            };
        case PROMOS_LOCALY_LOADED:
            return {
                ...state,
                promos: JSON.parse(localStorage.getItem('hmpgprm'))
            };
        case GET_FULLSCREEN_MEMORY:
            return {
                ...state,
                memories: action.payload
            };
        case GET_MEMORY_IMPRESSIONS:
            return {
                ...state,
                impressions: action.payload
            };
        case UPLOAD_MEMORY:
            return {
                ...state,
                buddies_memories: [action.payload, ...state.buddies_memories]
            };
        case GET_HUB_MEMORIES:
            return {
                ...state,
                hub_memories: action.payload
            };
        case GET_USER_MEMORIES:
            localStorage.setItem('urmm', JSON.stringify(action.payload));
            return {
                ...state,
                memories: action.payload
            };
        case USER_MEMORIES_LOCALY_LOADED:
            return {
                ...state,
                memories: JSON.parse(localStorage.getItem('urmm'))
            };

        case GET_MY_MEMORIES:
            localStorage.setItem('mymmrs', JSON.stringify(action.payload));
            return {
                ...state,
                my_memories: action.payload
            };
        case MY_MEMORIES_LOCALY_LOADED:
            return {
                ...state,
                my_memories: JSON.parse(localStorage.getItem('mymmrs'))
            };
        case GET_HUB_APPROVED_MEMORIES:
            localStorage.setItem('hbapvdmm', JSON.stringify(action.payload));
            return {
                ...state,
                approved_memories: action.payload
            };
        case APPROVED_MEMORIES_LOCALY_LOADED:
            return {
                ...state,
                approved_memories: JSON.parse(localStorage.getItem('hbapvdmm'))
            };
        case GET_HUB_DECLINED_MEMORIES:
            localStorage.setItem('hbdcndmm', JSON.stringify(action.payload));
            return {
                ...state,
                declined_memories: action.payload
            };
        case DECLINED_MEMORIES_LOCALY_LOADED:
            return {
                ...state,
                declined_memories: JSON.parse(localStorage.getItem('hbdcndmm'))
            };
        case GET_HUB_UNREVIEWED_MEMORIES:
            localStorage.setItem('hburvdmm', JSON.stringify(action.payload));
            return {
                ...state,
                unreviewed_memories: action.payload
            };
        case UNREVIEWS_MEMORIES_LOCALY_LOADED:
            return {
                ...state,
                unreviewed_memories: JSON.parse(localStorage.getItem('hburvdmm'))
            };
        case DELETE_MEMORY:
            return {
                ...state,
                memories: state.memories.filter(memory => memory.id !== action.payload),
                my_memories: state.my_memories.filter(memory => memory.id !== action.payload),
            };
        case DELETE_REACTION:
            localStorage.setItem('bdsmm', JSON.stringify(action.payload));
            return {
                ...state,
                buddies_memories: action.payload
            };


        case ADD_BUDDY_TAG:
            return {
                ...state,
                tags: [...state.tags, action.payload]
            };

        case REMOVE_BUDDY_TAG:
            return {
                ...state,
                tags: state.tags.filter(tag => tag.id !== action.payload.id),
            };
        case REMOVE_MY_TAG:
            return {
                ...state,
                tags: state.tags.filter(tag => tag.id !== action.payload.id),
            };
        case CLEAR_HUB_MEMORIES:
            return {
                ...state,
                hub_memories: []
            };
        case CLEAR_MEMORY_TAGS:
            return {
                ...state,
                tags: []
            };

        default:
            return state;
    }
}