import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class MemoryTag extends Component {

    state = {
        request: ""
    };

    acceptMemoryToProfile = () => {
        const memory_id = this.props.data.notification.related_memory_id
        this.props.acceptMemoryToProfile(memory_id)
        this.setState({ request: "Accepted" })
    }

    declineMemoryToProfile = () => {
        const memory_id = this.props.data.notification.related_memory_id
        this.props.declineMemoryToProfile(memory_id)
        this.setState({ request: "Declined" })
    }


    render() {
        const { notification } = this.props.data
        return (
            <Fragment>

                <div class="row single-item-9">
                    <div class="notification-related-photo-position">
                        <Link to={{ pathname: `/user-profile/${notification.related_user_id}/profile`, state: { user: notification.related_user_id } }}>
                            {notification.related_picture ?
                                <img class="related-picture-layout" src={notification.related_picture} alt="" />
                                :
                                <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                            }
                        </Link>
                    </div>

                    <div class="notification-information-section">
                        <div class="notification-text-position">
                            <p class="text-blue-9 notification-text-layout">
                                <strong>{notification.related_name}</strong>&nbsp;
                                created a Memory with you!!!
                                <br />
                                {notification.tag_answered ?
                                    null
                                    :
                                    <>
                                        Would you like it to appear in your profile too?
                                    </>
                                }
                            </p>
                        </div>
                    </div>



                    <div class="notification-related-icon-layout text-center">
                        <img
                            class="impression-notification-mika-img-cover"
                            src={notification.related_memory_photo}
                        // src={require("../../../assets/layout/homepage svg/love-red.svg")}
                        />
                        <Link to={{ pathname: '/memories', state: { memory: notification.related_memory_id } }}>
                            <img
                                class="impression-notification-mika-img"
                                src={notification.related_memory_photo}
                            // src={require("../../../assets/layout/homepage svg/love-red.svg")}
                            />
                        </Link>
                    </div>

                    {notification.tag_answered ?
                        null
                        : this.state.request === "Accepted" ?
                            <div
                                class="btn-light-notifications-tag"
                            >
                                Accepted
                            </div>
                            : this.state.request === "Declined" ?
                                <div
                                    class="btn-light-notifications-tag"
                                >
                                    Declined
                                </div>
                                :
                                <>
                                    <div
                                        onClick={() => this.acceptMemoryToProfile()}
                                        class="notification-tag-green"
                                    >
                                        Yes
                                    </div>
                                    <div
                                        onClick={() => this.declineMemoryToProfile()}
                                        class="notification-tag-red"
                                    >
                                        No
                                    </div>
                                </>
                    }
                </div>

            </Fragment >
        )
    }
}


export default (MemoryTag);