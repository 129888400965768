import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { getMemoryUploadHubList } from '../../../store/actions/hub'
import { uploadMemory } from '../../../store/actions/memory'
import { createAlert } from '../../../store/actions/alerts'
import { searchHubs } from '../../../store/actions/hub'
import { getBuddies, searchBuddy } from '../../../store/actions/buddies';
// import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
// import EmojiPicker from '../../../components/user/memory/EmojiPicker';

// popups
import Modal from 'react-awesome-modal';
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/memories/memories.css"
// emoji picker
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { getLocationsAPI } from '../../../store/actions/hubst3rActions';



export class Memory extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            paused: false,
            muted: false,
            length: null,
            formattedLength: null,
            currentTime: null,
            formattedTime: null,
            volume: 0.5,


            hub_id: "",
            memory: null,
            redirect: false,
            hubsModal: false,
            hub_search: "",
            carouselLayout: true,
            selectHubMode: true,
            tagProfilesMode: false,
            tags: [],
            user_search: '',
        };
    }


    componentDidMount() {
        // const country = JSON.parse(localStorage.getItem('cntr'))
        // this.props.getMemoryUploadHubList(country);
    }

    openModal() {
        const selected_hub = this.props.location.state.hub
        if (selected_hub) {
            this.setState({
                hubsModal: true,
                selectHubMode: false,
                tagProfilesMode: true,
            });
        } else {
            this.setState({
                hubsModal: true,
                selectHubMode: true,
                tagProfilesMode: false,
            });
        }


    }

    onChange = e => {
        if (e.target.value === "") {
            this.setState({
                [e.target.name]: e.target.value,
                carouselLayout: true
            })
            const country = JSON.parse(localStorage.getItem('cntr'))
            this.props.getMemoryUploadHubList(country);
        } else {
            const query = e.target.value
            if (query.length > 2) {
                if (query.toLowerCase() !== "ad") {
                    this.props.searchHubs(query)
                    this.props.getLocationsAPI(query)
                }
            }
            this.setState({
                [e.target.name]: e.target.value,
                carouselLayout: false
            })
        }
    }

    onBuddiesChange = e => {
        if (e.target.name === "user_search") {
            if (e.target.value === "") {
                this.setState({ [e.target.name]: e.target.value })
                this.props.getBuddies(JSON.parse(localStorage.getItem('ur')).id);
            } else {
                const query = e.target.value
                if (query.length > 2) {
                    if (query.toLowerCase() !== "ad") {
                        this.props.searchBuddy(query)
                    }
                }
                this.setState({ [e.target.name]: e.target.value })
            }
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    closeModal() {
        this.setState({
            hubsModal: false,
            selectHubMode: true,
        });
    }

    changeTagProfileMode() {
        const id = JSON.parse(localStorage.getItem('ur')).id
        this.props.getBuddies(id);
        const hub_id = this.state.hub_id
        if (hub_id === "") {
            this.props.createAlert({ noHubForMemorySellected: "You haven't Sellected the Hub you are in!" });
        } else {
            this.setState({
                selectHubMode: false,
                tagProfilesMode: true,
            });

        }

    }

    handleHubChange = (id) => (e) => {
        this.setState({
            hub_id: id,
        })
    }


    updateUserTagList = (id) => (e) => {
        e.preventDefault();
        const tag_list = [...this.state.tags]
        if ((tag_list.indexOf(id)) !== -1) {
            const new_remove = tag_list.filter(f => f !== id)
            this.setState({ tags: new_remove })
        } else {
            tag_list.push(id)
            this.setState({ tags: tag_list })
        }
    }


    postUploadedMemory = () => {
        const video = null
        const photo = null
        const memory_type = this.props.location.state.new_memory.type
        if (memory_type.includes("video")) {
            video = this.props.location.state.new_memory
        } else {
            photo = this.props.location.state.new_memory
        }
        let hub_id = this.state.hub_id

        const selected_hub = this.props.location.state.hub
        if (selected_hub) {
            hub_id = this.props.location.state.hub
        }
        const raw_tags_list = this.state.tags
        const tags_list = raw_tags_list.toString();

        if (photo === null && video === null) {
            this.props.createAlert({ noMemoryUploaded: "You haven't Uploaded a Memory!" });
        } else {
            const memory = { video, photo, hub_id, tags_list };
            // console.log(memory)
            // this.props.uploadMemory(memory)
            // this.setState({
            //     hub_id: "",
            //     memory: null,
            //     hubsModal: false,
            //     hub_search: "",
            //     carouselLayout: true,
            //     selectHubMode: true,
            //     tagProfilesMode: false,
            //     tags: [],
            //     redirect: true
            // });
        }
    }


    handleBack = () => {
        this.props.history.goBack()
    }


    render() {
        const photo = this.props.location.state.new_memory

        if (this.props.location.state.memory_photo === null) {
            return <Redirect to="/" />
        }

        if (this.state.redirect) {
            return <Redirect to="/" />
        }
        // const ArrowNext = (props) => (
        //     <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-right-position">
        //         <div class="arrow arrow-right">
        //             <img src={require("../../../assets/arrange-meeting/svg/next.svg")} alt="" />
        //         </div>
        //     </div>
        // );

        // const ArrowPrev = (props) => (
        //     <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-left-position">
        //         <div class="arrow arrow-left">
        //             <img src={require("../../../assets/arrange-meeting/svg/Icon.svg")} alt="" />
        //         </div>
        //     </div>
        // );

        //hubs carousel settings

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
        };
        const hub_id = this.state.hub_id
        const { hubs } = this.props
        const { buddies } = this.props
        const selected_hub = this.props.location.state.hub

        // console.log(this.props.location.state.new_memory.type)
        const memory_type = this.props.location.state.new_memory.type
        let is_video = false
        if (memory_type.includes("video")) {
            is_video = true
        }
        return (
            < Fragment >
                < OfflineLabel />
                <span>
                    <div class="post-whole-page-backround" >
                        <div class="post-memory-out-layout">
                            {/* {is_video ?
                                <div class="snapped-photo-layout">
                                    <video
                                        class="snapped-photo-size"
                                        // src={require("../../../assets/layout/lotties/video.mp4")}
                                        src={this.props.location.state.memory_photo}
                                        // controls
                                        // type="video/mp4"
                                        // autoplay={true}
                                        autoPlay
                                        // muted
                                        loop
                                    >
                                    </video>
                                </div>
                                : */}
                            <div class="snapped-photo-layout">
                                <img class="snapped-photo-size" src={this.props.location.state.memory_photo} />
                            </div>
                            {/* } */}
                            <div class="snapped-photo-size-cover" ></div>

                            <div class="post-hubs-snapped-photo-box">
                                <Link to='/'>
                                    <img class="times-close-snapped-photo" src={require("../../../assets/layout/homepage svg/back arrow.svg")} alt="" />
                                </Link>
                            </div>

                            {this.state.hubsModal ?
                                null
                                :

                                <Link
                                    to={{
                                        pathname: '/find-place',
                                        state: {
                                            new_memory: this.props.location.state.new_memory,
                                            memory_photo: this.props.location.state.memory_photo,
                                            hub: this.props.location.state.hub,
                                            lat: this.props.location.state.lat,
                                            lng: this.props.location.state.lng,
                                            name: this.props.location.state.name,
                                        }
                                    }}
                                >
                                    < div
                                        // onClick={() => this.openModal()}
                                        class="post-hubs-next-btn"
                                    >
                                        Next
                                    </div>
                                </Link>
                            }
                        </div>
                    </div>
                </span >

            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hubs: state.hub.hubs,
    buddies: state.buddies.buddies,
    noInternet: state.layout.noInternet,
    in_locations: state.hubst3rActions.in_locations,
    // isLoading: state.layout.isLoading
});

export default connect(mapStateToProps, {
    getBuddies,
    searchBuddy,
    getMemoryUploadHubList,
    uploadMemory,
    createAlert,
    searchHubs,
    getLocationsAPI
})(Memory);