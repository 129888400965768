import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../store/actions/auth';
//datepicker
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


export class InfoTab extends Component {

    state = {
        username: "",
        full_name: "",
        bio: "",
        genter: "",
        birthday: new Date(),
        email: "",
        city: "",
        country: "",
        birthday_updated: false,

        isInUsernameEditMode: false,
        isInFullNameEditMode: false,
        isInBioEditMode: false,
        isInGenterEditMode: false,
        isInBirthadyEditMode: false,
        isInEmailEditMode: false,
        isInCityEditMode: false,
        isInCountryEditMode: false,

        logout: "false"
    }

    componentDidMount() {
        const { profile } = this.props.data
        const raw_birthday = profile[0].birthday
        const modified_birthday = new Date(raw_birthday)
        if (profile) {
            this.setState({ birthday: modified_birthday })
        }
    }


    onChange = e => this.setState({ [e.target.name]: e.target.value });

    changedUsernameEditMode = () => {
        this.setState({ isInUsernameEditMode: !this.state.isInUsernameEditMode })
    }

    updateUsernameValue = () => {
        this.setState({ isInUsernameEditMode: false })
        const profile = this.props.data.profile[0]
        let new_username = this.state.username
        if (new_username !== "") {
            new_username = this.state.username
        } else {
            new_username = profile.username
        }
        const profile_picture = ""
        profile.profile_picture = profile_picture
        profile.username = new_username
        this.props.updateProfile(profile)
    }

    changedFullNameEditMode = () => {
        this.setState({ isInFullNameEditMode: !this.state.isInFullNameEditMode })
    }

    updateFullNameValue = () => {
        this.setState({ isInFullNameEditMode: false })
        const profile = this.props.data.profile[0]
        let new_full_name = this.state.full_name
        if (new_full_name !== "") {
            new_full_name = this.state.full_name
        } else { new_full_name = profile.full_name }
        profile.full_name = new_full_name
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
    }

    changedBioEditMode = () => {
        this.setState({ isInBioEditMode: !this.state.isInBioEditMode })
    }

    updateBioValue = () => {
        this.setState({ isInBioEditMode: false })
        const profile = this.props.data.profile[0]
        let new_bio = this.state.bio
        if (new_bio !== "") {
            new_bio = this.state.bio
        } else { new_bio = profile.bio }
        profile.bio = new_bio
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
    }

    changedGenterEditMode = () => {
        this.setState({ isInGenterEditMode: !this.state.isInGenterEditMode })
    }

    updateGenterValue = () => {
        this.setState({ isInGenterEditMode: false })
        const profile = this.props.data.profile[0]
        let new_genter = this.state.genter
        if (new_genter !== "") {
            new_genter = this.state.genter
        } else { new_genter = profile.genter }
        profile.genter = new_genter
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
    }

    changedBirthdayEditMode = () => {
        this.setState({ isInBirthadyEditMode: !this.state.isInBirthadyEditMode })
    }

    updateBirthdayValue = (date) => {
        let birthday = { date }
        birthday = birthday.date
        this.setState({
            birthday: birthday,
            birthday_updated: true,
            isInBirthadyEditMode: false,
        })
        const profile = this.props.data.profile[0]
        const profile_picture = ""
        const username = profile.username
        const full_name = profile.full_name
        // const email = profile.email
        const bio = profile.bio
        const genter = profile.genter
        const city = profile.city
        const country = profile.country
        const updated_profile = { username, birthday, full_name, bio, genter, city, country, profile_picture }
        // profile.birthday = this.state.birthday
        // profile.profile_picture = profile_picture
        this.props.updateProfile(updated_profile)
    }

    changedEmailEditMode = () => {
        this.setState({ isInEmailEditMode: !this.state.isInEmailEditMode })
    }

    updateEmailValue = () => {
        this.setState({ isInEmailEditMode: false })
        const profile = this.props.data.profile[0]
        let new_email = this.state.email
        if (new_email !== "") {
            new_email = this.state.email
        } else { new_email = profile.email }
        profile.email = new_email
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
    }

    changedCityEditMode = () => {
        this.setState({ isInCityEditMode: !this.state.isInCityEditMode })
    }

    updateCityValue = () => {
        this.setState({ isInCityEditMode: false })
        const profile = this.props.data.profile[0]
        let new_city = this.state.city
        if (new_city !== "") {
            new_city = this.state.city
        } else { new_city = profile.city }
        profile.city = new_city
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
    }

    changedCountryEditMode = () => {
        this.setState({ isInCountryEditMode: !this.state.isInCountryEditMode })
    }

    updateCountryValue = () => {
        this.setState({ isInCountryEditMode: false })
        const profile = this.props.data.profile[0]
        let new_country = this.state.country
        if (new_country !== "") {
            new_country = this.state.country
        } else { new_country = profile.country }
        profile.country = new_country
        const profile_picture = ""
        profile.profile_picture = profile_picture
        this.props.updateProfile(profile)
    }


    logoutForUser() {
        this.props.logout()
        setTimeout(() => {
            // window.location.reload(false);
            this.setState({ logout: "true" })
        }, 1000);
    }

    render() {
        if (this.state.logout === "true") {
            return <Redirect to="/register" />;
        }

        // datepicker button
        const ExampleCustomInput = ({ value, onClick }) => (
            // <div className="btn profile-birthday-sellect-btn" onClick={onClick}>
            <div className="btn btn-info profile-birthday-sellect-btn" onClick={onClick}>
                {value}
            </div>
        );
        return (
            <Fragment>
                {this.props.data.profile.map(profile => (
                    <div class="white-box-profile">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="w-box3">
                                    <strong class="w-box1-s">Who am I?</strong>
                                    <br />
                                    <br />
                                    <div class="row my-3">
                                        {/* <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/username.svg")} alt="" />
                                        </div> */}
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-6 text-line-6-profile">
                                            <strong class="profile-info-title-position">Username</strong>
                                        </div>

                                        <div class="">
                                            <div class="profile-info-info profile-bio-text-layout">
                                                <strong>{profile.username}</strong>
                                            </div>
                                            <div class="">
                                                <div class="user-profile-pencil-icon"></div>
                                            </div>
                                        </div>
                                        {/* {this.state.isInUsernameEditMode ?
                                            <div class="hub-info-update-layout">
                                                <center>
                                                    <br />
                                                    <br />
                                                    <input
                                                        maxlength = "9"
                                                        class="hub-info-input mb-3"
                                                        type="text"
                                                        defaultValue={profile.username}
                                                        onChange={this.onChange}
                                                        name="username"
                                                        placeholder="New Username"
                                                    />
                                                    <br />
                                                    <button class="btn btn-sm btn-outline-success" style={{ margin: "10px" }} onClick={this.updateUsernameValue}>✔</button>
                                                    <button class="btn btn-sm btn-outline-danger" onClick={this.changedUsernameEditMode}>✘</button>
                                                </center>
                                            </div>
                                            :
                                            <div class="">
                                                <div class="profile-info-info">
                                                    <strong>{profile.username}</strong>
                                                </div>
                                                <div class="">
                                                    <div class="user-profile-pencil-icon"></div>
                                                    <img
                                                        onClick={this.changedUsernameEditMode}
                                                        class="user-profile-pencil-icon"
                                                        src={require("../../../assets/hub/hub-info/svg/Edit.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        } */}
                                    </div>

                                    <div class="row my-3">
                                        {/* <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/full-name.svg")} alt="" />
                                        </div> */}
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Full Name</strong>
                                        </div>


                                        {this.state.isInFullNameEditMode ?
                                            <div class="hub-info-update-layout">
                                                <center>
                                                    <br />
                                                    <br />
                                                    <input
                                                        maxlength="30"
                                                        class="hub-info-input mb-3 profile-all-input-size"
                                                        type="text"
                                                        defaultValue=
                                                        {profile.full_name === "null" ?
                                                            ""
                                                            : profile.full_name === null ?
                                                                ""
                                                                : profile.full_name === "" ?
                                                                    ""
                                                                    : profile.full_name === "undefined" ?
                                                                        ""
                                                                        :
                                                                        ""
                                                            // <strong>{profile.full_name}</strong>
                                                        }
                                                        onChange={this.onChange}
                                                        name="full_name"
                                                        placeholder=""

                                                    />
                                                    <br />
                                                    <button class="btn btn-sm btn-outline-success" style={{ margin: "10px" }} onClick={this.updateFullNameValue}>✔</button>
                                                    <button class="btn btn-sm btn-outline-danger" onClick={this.changedFullNameEditMode}>✘</button>
                                                </center>
                                            </div>
                                            :
                                            <div class="">
                                                <div class="profile-info-info profile-bio-text-layout">
                                                    {profile.full_name === "null" ?
                                                        <div>Insert Full Name</div>
                                                        : profile.full_name === null ?
                                                            <div>Insert Full Name</div>
                                                            : profile.full_name === "" ?
                                                                <div>Insert Full Name</div>
                                                                : profile.full_name === "undefined" ?
                                                                    <div>Insert Full Name</div>
                                                                    :
                                                                    <strong>{profile.full_name}</strong>
                                                    }

                                                </div>
                                                <div class="">
                                                    <img
                                                        onClick={this.changedFullNameEditMode}
                                                        class="user-profile-pencil-icon"
                                                        src={require("../../../assets/hub/hub-info/svg/Edit.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div class="row my-3">
                                        {/* <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/quote-Icon.svg")} alt="" />
                                        </div> */}
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Bio</strong>
                                        </div>

                                        {this.state.isInBioEditMode ?
                                            <div class="hub-info-update-layout">
                                                <center>
                                                    <br />
                                                    <br />
                                                    <input
                                                        maxlength="100"
                                                        class="hub-info-input mb-3 profile-all-input-size"
                                                        type="text"
                                                        defaultValue=
                                                        {profile.bio === "null" ?
                                                            ""
                                                            : profile.bio === null ?
                                                                ""
                                                                : profile.bio === "" ?
                                                                    ""
                                                                    : profile.bio === "undefined" ?
                                                                        ""
                                                                        :
                                                                        ""
                                                            // <strong>{JSON.stringify(profile.bio)}</strong>
                                                        }

                                                        onChange={this.onChange}
                                                        name="bio"
                                                        placeholder=""

                                                    />
                                                    <br />
                                                    <button class="btn btn-sm btn-outline-success" style={{ margin: "10px" }} onClick={this.updateBioValue}>✔</button>
                                                    <button class="btn btn-sm btn-outline-danger" onClick={this.changedBioEditMode}>✘</button>
                                                </center>
                                            </div>
                                            :
                                            <div class="">
                                                <div class="profile-info-info profile-bio-text-layout">
                                                    {profile.bio === "null" ?
                                                        <div>Insert a Bio</div>
                                                        : profile.bio === null ?
                                                            <div>Insert a Bio</div>
                                                            : profile.bio === "" ?
                                                                <div>Insert a Bio</div>
                                                                : profile.bio === "undefined" ?
                                                                    <div>Insert a Bio</div>
                                                                    :
                                                                    <div>{profile.bio}</div>
                                                    }
                                                </div>
                                                <div class="">
                                                    <img
                                                        onClick={this.changedBioEditMode}
                                                        class="user-profile-pencil-icon"
                                                        src={require("../../../assets/hub/hub-info/svg/Edit.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* 
                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets//profile/svg/genter.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Gender</strong>
                                        </div>

                                        {this.state.isInGenterEditMode ?
                                            <div class="hub-info-update-layout">
                                                <center>
                                                    <br />
                                                    <br />
                                                    <input
                                                        maxlength="20"
                                                        class="hub-info-input mb-3 profile-all-input-size"
                                                        type="text"
                                                        defaultValue=
                                                        {profile.genter === "null" ?
                                                            ""
                                                            : profile.genter === null ?
                                                                ""
                                                                : profile.genter === "" ?
                                                                    ""
                                                                    : profile.genter === "undefined" ?
                                                                        ""
                                                                        :
                                                                        ""
                                                            // <strong>{profile.genter}</strong>
                                                        }
                                                        onChange={this.onChange}
                                                        name="genter"
                                                    />
                                                    <br />
                                                    <button class="btn btn-sm btn-outline-success" style={{ margin: "10px" }} onClick={this.updateGenterValue}>✔</button>
                                                    <button class="btn btn-sm btn-outline-danger" onClick={this.changedGenterEditMode}>✘</button>
                                                </center>
                                            </div>
                                            :
                                            <div class="">
                                                <div class="profile-info-info profile-bio-text-layout">
                                                    {profile.genter === "null" ?
                                                        <div>Insert Gender</div>
                                                        : profile.genter === null ?
                                                            <div>Insert Gender</div>
                                                            : profile.genter === "" ?
                                                                <div>Insert Gender</div>
                                                                : profile.genter === "undefined" ?
                                                                    <div>Insert Gender</div>
                                                                    :
                                                                    <div>{profile.genter}</div>
                                                    }
                                                </div>
                                                <div class="">
                                                    <img
                                                        onClick={this.changedGenterEditMode}
                                                        class="user-profile-pencil-icon"
                                                        src={require("../../../assets/hub/hub-info/svg/Edit.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                  */}

                                    {/* <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/birthday.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Birthday</strong>
                                        </div>
                                        <div class="">
                                            <DatePicker
                                                onChange={this.updateBirthdayValue.bind(this)}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.birthday}
                                                name="date"
                                                dateFormat="dd/MM/yyyy"
                                                withPortal
                                                customInput={<ExampleCustomInput />}
                                            />                                      
                                              </div> */}

                                    {/* 
                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/location-red.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">City</strong>
                                        </div>
                                        {this.state.isInCityEditMode ?
                                            <div class="hub-info-update-layout">
                                                <center>
                                                    <br />
                                                    <br />
                                                    <input
                                                        maxlength="20"
                                                        class="hub-info-input mb-3 profile-all-input-size"
                                                        type="text"
                                                        defaultValue={profile.city === "null" ?
                                                            ""
                                                            : profile.city === null ?
                                                                ""
                                                                : profile.city === "" ?
                                                                    ""
                                                                    : profile.city === "undefined" ?
                                                                        ""
                                                                        :
                                                                        ""
                                                            // <strong>{profile.city}</strong>
                                                        }
                                                        // {profile.city}
                                                        onChange={this.onChange}
                                                        name="city"
                                                        placeholder=""
                                                    />
                                                    <br />
                                                    <button class="btn btn-sm btn-outline-success" style={{ margin: "10px" }} onClick={this.updateCityValue}>✔</button>
                                                    <button class="btn btn-sm btn-outline-danger" onClick={this.changedCityEditMode}>✘</button>
                                                </center>
                                            </div>
                                            :
                                            <div class="">
                                                <div class="profile-info-info profile-bio-text-layout">
                                                    {profile.city === "null" ?
                                                        <div>City you live</div>
                                                        : profile.city === null ?
                                                            <div>City you live</div>
                                                            : profile.city === "" ?
                                                                <div>City you live</div>
                                                                : profile.city === "undefined" ?
                                                                    <div>City you live</div>
                                                                    :
                                                                    <strong>{profile.city}</strong>
                                                    }
                                                </div>
                                                <div class="">
                                                    <img
                                                        onClick={this.changedCityEditMode}
                                                        class="user-profile-pencil-icon"
                                                        src={require("../../../assets/hub/hub-info/svg/Edit.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div> */}

                                    {/*                                   
                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/globe-solid.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Country</strong>
                                        </div>
                                        {this.state.isInCountryEditMode ?
                                            <div class="hub-info-update-layout">
                                                <center>
                                                    <br />
                                                    <br />
                                                    <input
                                                        maxlength="20"
                                                        class="hub-info-input mb-3 profile-all-input-size"
                                                        type="text"
                                                        defaultValue={profile.country === "null" ?
                                                            ""
                                                            : profile.country === null ?
                                                                ""
                                                                : profile.country === "" ?
                                                                    ""
                                                                    : profile.country === "undefined" ?
                                                                        ""
                                                                        :
                                                                        ""
                                                            // <strong>{profile.country}</strong>
                                                        }
                                                        onChange={this.onChange}
                                                        name="country"
                                                        placeholder=""
                                                    />
                                                    <br />
                                                    <button class="btn btn-sm btn-outline-success" style={{ margin: "10px" }} onClick={this.updateCountryValue}>✔</button>
                                                    <button class="btn btn-sm btn-outline-danger" onClick={this.changedCountryEditMode}>✘</button>
                                                </center>
                                            </div>
                                            :
                                            <div class="">
                                                <div class="profile-info-info profile-bio-text-layout">
                                                    {profile.country === "null" ?
                                                        <div>Country you live</div>
                                                        : profile.country === null ?
                                                            <div>Country you live</div>
                                                            : profile.country === "" ?
                                                                <div>Country you live</div>
                                                                : profile.country === "undefined" ?
                                                                    <div>Country you live</div>
                                                                    :
                                                                    <strong>{profile.country}</strong>
                                                    }
                                                </div>
                                                <div class="">
                                                    <img
                                                        onClick={this.changedCountryEditMode}
                                                        class="user-profile-pencil-icon"
                                                        src={require("../../../assets/hub/hub-info/svg/Edit.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                 */}
                                    <div onClick={() => this.logoutForUser()} class="profile-logout-button" >
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* <div
                    class="profile-logout-button"
                    // onClick={this.logoutUser}
                    onClick={this.logoutUser}
                > */}

                {/* Logout
                </div> */}
                <br /><br /><br /><br />
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { logout })(InfoTab);