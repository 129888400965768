import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createAlert } from '../../../store/actions/alerts'
import { submitHubContactForm } from '../../../store/actions/authhub'
import Modal from 'react-awesome-modal';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import CookiesModal from '../../../components/user/layout/cookiesModal';
import '../../../assets/hub/hub-authentication/hub-login.css'

export class CompanyContact extends Component {
    state = {
        name: '',
        phone: '',
        hubName: '',
        modalVisible: false,
        isLoading: true
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 1000);
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    openModal() {
        this.setState({
            modalVisible: true
        });
    }

    closeModal() {
        this.setState({
            modalVisible: false
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        const name = this.state.name;
        const phone = this.state.phone;
        const hub_name = this.state.hubName;
        const contactForm = { name, phone, hub_name }
        if (name === "") {
            this.props.createAlert({ noNameGiven: "You haven't given us a Name! How should we call you?" });
        } else if (phone === "") {
            this.props.createAlert({ noPhoneGiven: "You haven't given us a phone number! How should we come in contact with you?" });
        } else if (hub_name === "") {
            this.props.createAlert({ noHubNameGiven: "You haven't given us the name of your Shop! How should we know who you are" });
        } else {
            this.openModal()
            this.props.submitHubContactForm(contactForm);
            this.setState({
                name: '',
                phone: '',
                hubName: '',
                modalVisible: true
            });
        }
    }

    render() {
        const { name, phone, hubName } = this.state;
        return (
            <Fragment>
                <div class={this.state.isLoading ? "user-register-lottie-appear" : "user-register-lottie-dissappear"}>
                    <div class="register-lottie-position">
                        <Hubst3rLottie />
                    </div>

                </div>


                <div class={this.state.isLoading ? "user-register-section-disappear" : null}>
                    <CookiesModal />
                    <Modal visible={this.state.modalVisible} width="100" height="100" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div class="form-success-box">
                            <div class="form-success-title text-center">Information Submited</div>
                            <img
                                onClick={() => this.closeModal()}
                                class="form-success-times-modal"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <img
                                class="hub-form-sumbit-success"
                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                alt=""
                            />
                            <div class="form-success-subtitle">Your Request has been Submited and we will contact you as soon as possible!!</div>
                            <center>
                                <Link to="/register">
                                    <div class="btn form-success-button">
                                        Register Now
                                    </div>
                                </Link>
                            </center>
                        </div>
                    </Modal>
                    <div class="hub-login-form-color">
                        <div class="hub-login-form-header text-center">
                            <Link to="/register">
                                <div class="register-back-button">
                                    <img
                                        class="register-back-icon-size"
                                        src={require("../../../assets/arrange-meeting/svg/Path 83.svg")}
                                        alt=""
                                    />
                                </div>
                            </Link>
                            <img
                                class="hubst3r-login-word-size"
                                src={require("../../../assets/authentication/svg/Logo-h.svg")}
                                alt=""
                            />
                        </div>

                        <div class="hub-login-signup-form">
                            <form action="/examples/actions/confirmation.php" method="post">
                                <div class="hub-login-form-logo text-center">
                                    <img
                                        class="hubster-login-logo-size"
                                        src={require("../../../assets/authentication/svg/Logo.svg")}
                                        alt=""
                                    />
                                </div>
                                <h2>Contact Sales</h2>
                                <p class="hint-text hub-login-top-text">Submit the required fields and we will come in contact with you as soon as possible</p>

                                <div class="form-group">
                                    <input
                                        type="text"
                                        onChange={this.onChange}
                                        value={name}
                                        class="hub-login-form-control"
                                        name="name"
                                        placeholder="Enter your Name"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <input
                                        type="text"
                                        onChange={this.onChange}
                                        value={phone}
                                        class="hub-login-form-control"
                                        name="phone"
                                        placeholder="Enter a Phone Number"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <input
                                        type="text"
                                        onChange={this.onChange}
                                        value={hubName}
                                        class="hub-login-form-control"
                                        name="hubName"
                                        placeholder="Enter your Company's Name"
                                        required
                                    />
                                </div>

                                <br />
                                <div class="form-group">
                                    <div
                                        onClick={this.onFormSubmit}
                                        class="register-signup-btn-hubst3r"
                                    >
                                        Submit
                                    </div>
                                </div>
                                {/* <div class="form-group hub-contact-form-submit">
                                    <div
                                        onClick={this.onFormSubmit}
                                        class="btn btn-success btn-lg btn-block"
                                    >
                                        Submit
                                    </div>
                                </div> */}
                                <div class="text-center hub-login-account-have">
                                    Oops I shouldn't be here &nbsp;
                                    <Link to="/register">
                                        <a href="#">
                                            Go to Login
                                        </a>
                                    </Link>
                                </div>

                                <div class="row login-legal-position">
                                    <div class="col-xl-12 text-center mt-2">
                                        <Link to="/terms-of-use">
                                            <a class="link2" href="#">Terms</a>
                                        </Link>
                                        <Link to="/privacy-policy">
                                            <a class="link2" href="#">Privacy</a>
                                        </Link>
                                        <Link to="/cookies-policy">
                                            <a class="link2" href="#">Cookies</a>
                                        </Link>
                                        <br />
                                        {/* <Link to="/about"> */}
                                        <a class="link2" href="http://about.hubst3r.com/">About</a>
                                        {/* </Link> */}
                                        <Link to="/licenses">
                                            <a class="link2" href="#">Licenses</a>
                                        </Link>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, { createAlert, submitHubContactForm })(CompanyContact);