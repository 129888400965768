import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { setHubTraffic, updateHub } from '../../../store/actions/hub'
import "../../../assets/hub/hub-layout/mobileTraffic.css"




export class HubMobileTopNavbar extends Component {
    state = {
        traffic: "",
        logo_photo: null,
        logo: "",
    }


    setTrafficToLow = () => (e) => {
        const traffic = "Low"
        const id = this.props.data.hub[0].id
        const answer = { id, traffic };
        this.setState({ traffic: traffic })
        this.props.setHubTraffic(answer, id);
    }

    setTrafficToMedium = () => (e) => {
        const traffic = "Medium"
        const id = this.props.data.hub[0].id
        const answer = { id, traffic };
        this.setState({ traffic: traffic })
        this.props.setHubTraffic(answer);
    }

    setTrafficToHigh = () => (e) => {
        const traffic = "High"
        const id = this.props.data.hub[0].id
        const answer = { id, traffic };
        this.setState({ traffic: traffic })
        this.props.setHubTraffic(answer, id);
    }

    onLogoSubmit = (e) => {
        this.setState({
            logo: e.target.files[0],
            logo_photo: URL.createObjectURL(e.target.files[0]),
        })
        setTimeout(() => {
            this.updateLogo()
        }, 4000);
    };

    updateLogo = () => {
        const hub = this.props.data.hub[0]
        const new_logo = this.state.logo
        hub.logo = new_logo
        hub.cover_photo = ""
        hub.promo_photo = ""
        this.props.updateHub(hub)
    }

    handleBack() {
        this.props.history.goBack()
    }

    render() {
        // const hub = JSON.parse(localStorage.getItem('hbpg'))
        return (
            <Fragment>
                {this.props.data.hub.map(hub => (

                    <div class="container-fluid small-header-container-6">
                        <div class="small-header py-5">


                            <div class="hub-top-navbar-name">
                                {hub.name}
                            </div>


                        </div>
                    </div>
                ))}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setHubTraffic, updateHub })(HubMobileTopNavbar);