import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const Hubst3rPrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            const user = JSON.parse(localStorage.getItem('ur'))
            if (user) {
                if (auth.dashboard_access) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/" />
                }
            } else {
                return <Redirect to="/register" />;
            }
        }}
    />
);

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Hubst3rPrivateRoute);