import React from 'react';
import { SitePal } from 'sitepal-react';  
import { useState, useEffect } from "react";

const ScarlettSitepal = (props) => {

    const [sayText, setSayText] = useState(undefined)

    // const skarlettAnswering = () => {
    //     setSayText([props.scarlett_answer,4,1,3]);
    //     // setSayText(['Welcome to our text to speech API example']);
    // } 

    const chatgpdAnswer = JSON.parse(localStorage.getItem('chtgptanswer'))

    if (chatgpdAnswer){
        props.receivedChatAnswer(props.scarlett_answer)
        console.log(props.scarlett_answer)
        localStorage.setItem('chtgptanswer', JSON.stringify(false));
    } 
    
    // useEffect((props) => {
    //     setSayText([props.scarlett_answer,4,1,3]);
    //     localStorage.setItem('chtgptanswer', JSON.stringify(false));
    // }, [props.scarlett_answer])

    const clickOnScarlett = e => {
        window.sayText("You are too touchy",4,1,3);
    }

    return (
        <>
            <div onClick={clickOnScarlett} class="scalrett-invisible-sitepal-cover"></div>

            <div class="scarlett-sidepal-box">
                < SitePal 
                    embed='8687895,600,800,"",1,0,2756799,0,1,0,"Zf84tiAFQ5KOTC6qftxDjHipJzjI9hv6",0,1'

                    
                    // embed={'8687895,600,800,"",1,0,2756799,0,1,0,"TpL1PbKcEALHRi2lV9nTNUCltIBQHApD",0,1'} 
                    // embed={'8687895,680,414,"",1,0,2756799,0,1,0,"W3GfH8boAVMPHU7aPP8rUrputy5udY0T",0,0'}
                    
                    // 8687895,600,800,"",1,0,2756799,0,1,0,"D3rrjIYidBppESHab74tWdSn9x9Bsb3H",0,1
                    
                    // 8687895,736,414,"",1,0,2756799,0,1,0,"W3GfH8boAVMPHU7aPP8rUrputy5udY0T",0,0                    
                    sayText={sayText} 
                ></SitePal>
            </div>
        </>
        
    );
};
export default ScarlettSitepal;