import axios from 'axios';
import { returnErrors, createAlert } from './alerts';
import {
    GET_REQUEST_RESERVATION_HUBS,
    GET_REGISTER_ALL_HUBS,
    GET_HOMEPAGE_ALL_HUBS,
    GET_SELLECTED_HUB,
    HUBS_LOCALY_LOADED,
    ACTIVATE_HUB_ACCOUNT,
    GET_ARRANGE_MEETING_HUB,
    GET_MEMORY_UPLOAD_HUB_LIST,
    MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED,
    GET_HUB_PAGE,
    HUB_PAGE_LOCALY_LOADED,
    UPDATE_HUB,
    UPLOAD_HUB_PROMO_PHOTO,
    CREATE_NEW_MAP_PLACE,
    SET_HUB_TRAFFIC,
    GET_HUB_ACTIVITIES,
    HUB_ACTIVITIES_LOCALY_LOADED,
    CREATE_ACTIVITY,
    REMOVE_ACTIVITY,
    GET_HUB_WORKING_DAYS,
    WORKING_DAYS_LOCALY_LOADED,
    UPDATE_WORKING_DAY,
    GET_SEARCHED_HUBS,
    GET_HUB_METRICS,
    HUBS_METRICS_LOCALY_LOADED,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    LOAD_HOMEPAGE_ALL_HUBS,
    GET_HOMEPAGE_SELLECTED_HUBS,
    CREATE_PROMO_REACTION,
    FULL_SCREEN_PROMO,
    GET_HUB_AMBASSANDORS_PROFILE,
    GET_SELECT_HUB_LIST,
    GET_SELECT_HUB_LIST_LOCALLY_LOADED,
    GET_HUB_MENU_PAGES,
    GET_USER_HUB_CATALOGS,
    HUB_MENU_PAGES_LOCALY_LOADED,
    CREATE_HUB_PROSPECT,
    GET_NEW_CREATED_HUB,
    HUB_MARKER_CREATED,
    CREATE_SUGGESTION_HUB_PROSPECT,
    WORKING_DAY_TO_CLOSE,
    SET_WORKING_DAY_HOURS,
    UPLOAD_HUB_LOGO,
    UPLOAD_HUB_COVER_PHOTO,
    SET_HUB_NAME,
    SET_HUB_CATEGORY,
    SET_HUB_MAP_ICON,
    SET_HUB_WFI_CODE,
    SET_HUB_TELEPHONE,
    SET_HUB_WEBSITE,
    SET_HUB_FACEBOOK,
    SET_HUB_INSTAGRAM,
    SET_HUB_RESERATIONS,
    SET_HUB_APPOINTMENTS,
    SET_HUB_ONLINE_SHOP,
    GET_HUB_CATALOGS,
    UPLOAD_HUB_CATALOGE,
    REMOVE_HUB_CATALOGE,
    SET_HUB_RESERATION_HOURS,
    SET_HUB_APPOINTMENT_HOURS,
    CREATE_NEW_RESERATION_HOUR,
    REMOVE_RESERATION_HOUR,
    CREATE_NEW_APPOINTMENT_HOUR,
    REMOVE_APPOINTMENT_HOUR,
    SET_HUB_SALES,
    GET_HUB_USERS_WITH_ACCESS,
    SEARCHING_USER,
    FINISH_SEARCHING,
    SET_MENU_PAGE_DESCRIPTION,
    SET_MENU_PAGE_PRICE,
    CHANGE_HUB_IS_OVERNIGHT,
    GET_HUB_MENU_QR,
    CREATE_HUB_MENU_QR,
    CHANGE_HUB_CATALOG_INDEX,
} from './actionTypes';
import { tokenConfig } from './auth';



export const getRegisterHubList = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/register-hubs/`, { withCredentials: true }, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_REGISTER_ALL_HUBS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}



export const getRequestReservationHubs = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubs/request-reservation-hubs/`, tokenConfig())
        .then(res => {

            dispatch({
                type: GET_REQUEST_RESERVATION_HUBS,
                payload: res.data
            });

        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUBS_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getHomepageAllHubs = (country, city, category, offset) => (dispatch, getstate) => {
    if (city === null) {
        city = "show_all"
    }
    // dispatch({ type: LOADING_PAGE });
    dispatch({ type: LOAD_HOMEPAGE_ALL_HUBS });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubs/${country}/${city}/${category}/${offset}/homepage/`, tokenConfig())
        .then(res => {
            if (offset === 0) {
                dispatch({
                    type: GET_HOMEPAGE_SELLECTED_HUBS,
                    payload: res.data
                });
            } else {
                dispatch({
                    type: GET_HOMEPAGE_ALL_HUBS,
                    payload: res.data
                });
            }
            // setTimeout(() => {
            //     dispatch({ type: PAGE_LOADED });
            // }, 1500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUBS_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getSelectdHubList = (country, city, category, offset) => (dispatch, getstate) => {
    // dispatch({ type: LOADING_PAGE });
    dispatch({ type: LOAD_HOMEPAGE_ALL_HUBS });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubs/${country}/${city}/${category}/${offset}/arrange/`, tokenConfig())
        .then(res => {
            if (offset === 0) {
                dispatch({
                    type: GET_HOMEPAGE_SELLECTED_HUBS,
                    payload: res.data
                });
            } else {
                dispatch({
                    type: GET_HOMEPAGE_ALL_HUBS,
                    payload: res.data
                });
            }
            // setTimeout(() => {
            //     dispatch({ type: PAGE_LOADED });
            // }, 1500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUBS_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getSellectedHub = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubs/${hub_id}/sellect-hub/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SELLECTED_HUB,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getHubMetrics = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/hub-metrics/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_METRICS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUBS_METRICS_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const searchHubs = (query) => (dispatch, getstate) => {
    const corrected_query1 = query.replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_query2 = corrected_query1.replace(/\ /g, 'k0l0kenogam1m3n01111');
    const final_query = corrected_query2.replace(/\//g, 'k0l0slashgam1m3n01111');
    dispatch({ type: LOAD_HOMEPAGE_ALL_HUBS });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubs/search/${final_query}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SEARCHED_HUBS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const searchReservationHubs = (query) => (dispatch, getstate) => {
    const corrected_query1 = query.replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_query2 = corrected_query1.replace(/\ /g, 'k0l0kenogam1m3n01111');
    const final_query = corrected_query2.replace(/\//g, 'k0l0slashgam1m3n01111');
    dispatch({ type: LOAD_HOMEPAGE_ALL_HUBS });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hubs/search-reservation/${final_query}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_SEARCHED_HUBS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const getArrangeMeetingHub = (id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${id}/arrange/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_ARRANGE_MEETING_HUB,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getMemoryUploadHubList = (country, lat, lng) => (dispatch, getstate) => {
    const corrected_lat = String(lat).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    const corrected_lng = String(lng).replace(/\./g, 'k0l0t3l31agam1m3n1111');
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${country}/${corrected_lat}/${corrected_lng}/upload-memory-hubs/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MEMORY_UPLOAD_HUB_LIST,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const getUserHubPage = (id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/user/${id}/page/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_PAGE,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getHubPage = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/page/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_PAGE,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUB_PAGE_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const updateHub = (updated_hub) => (dispatch, getstate) => {
    const hubData = new FormData();
    hubData.append("id", updated_hub.id);
    hubData.append("logo", updated_hub.logo);
    hubData.append("cover_photo", updated_hub.cover_photo);
    hubData.append("promo_photo", updated_hub.promo_photo);
    hubData.append("minimum_charge", updated_hub.minimum_charge);
    hubData.append("wifi_code", updated_hub.wifi_code);
    hubData.append("telephone", updated_hub.telephone);
    hubData.append("website", updated_hub.website);
    hubData.append("facebook_url", updated_hub.facebook_url);
    hubData.append("instagram_url", updated_hub.instagram_url);
    hubData.append("maximum_reservation_hour", updated_hub.maximum_reservation_hour);
    hubData.append("cousine_closing_hour", updated_hub.cousine_closing_hour);
    hubData.append("minimum_age", updated_hub.minimum_age);
    hubData.append("description", updated_hub.description);
    hubData.append("hub_rules", updated_hub.hub_rules);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/update-hub/`, hubData, tokenConfig())
        .then(res => {
            dispatch(createAlert({ updateHub: 'The Hub info has been Updated!!' }));
            dispatch({
                type: UPDATE_HUB,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const setHubTraffic = (answer) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/traffic/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_TRAFFIC,
                payload: res.data
            });
        }).catch(err => console.log(err));
}

export const getHubForReservationRequest = (id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${id}/hub-reservation-request-info/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_PAGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getHubActivities = (id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${id}/activities/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_ACTIVITIES,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUB_ACTIVITIES_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const createActivity = (activity, id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${id}/activities/`, activity, tokenConfig())
        .then(res => {
            dispatch(createAlert({ createActivity: 'You have Created a new Activity for the Hub!!' }));
            dispatch({
                type: CREATE_ACTIVITY,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const removeActivity = (hub_id, id) => (dispatch, getstate) => {
    axios.delete(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${hub_id}/activities/${id}/`, tokenConfig())
        .then(res => {
            dispatch(createAlert({ removeActivity: 'The Activity has been Removed' }));
            dispatch({
                type: REMOVE_ACTIVITY,
                payload: id
            });
        }).catch(err => console.log(err));
}

export const getHubWorkingDays = (id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${id}/working-days/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_WORKING_DAYS,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: WORKING_DAYS_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}

export const updateWorkingDay = (working_day) => (dispatch, getstate) => {
    const id = JSON.parse(localStorage.getItem('ur')).hub_id
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${id}/working-days/`, working_day, tokenConfig())
        .then(res => {
            dispatch({
                type: UPDATE_WORKING_DAY,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};


export const activateHubAccount = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/activate-hub-account/`, tokenConfig())
        .then(res => {
            dispatch({
                type: ACTIVATE_HUB_ACCOUNT,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) });
}


export const createPromoReaction = (reaction, hub_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${hub_id}/create-promo-reaction/`, reaction, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_PROMO_REACTION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getFullScreenPromo = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_id}/fullscreen-promo/`, tokenConfig())
        .then(res => {
            dispatch({
                type: FULL_SCREEN_PROMO,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) });
}

export const getHubAmbasaddorsProfile = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${hub_id}/hub-ambassandors-profile/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_AMBASSANDORS_PROFILE,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) });
}



export const createHubProspect = (prospect) => (dispatch, getstate) => {

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-hub-prospect/`, prospect, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_HUB_PROSPECT,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const createNewUserProspect = (prospect) => (dispatch, getstate) => {
    const name = prospect.name
    const category = prospect.category

    // gia kapio logo lat kai lng einai anapoda
    const lng = prospect.lng
    const lat = prospect.lat
    let address = ""
    let city = ""
    let place = ""
    let region = ""
    let country = ""
    let country_short = ""
    let postal_code = ""

    const access_token = process.env.REACT_APP_MAPBOX_API_KEY
    const options = {
        method: 'GET',
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${lat},${lng}.json?types=poi&access_token=${access_token}`
        // url: `https://api.tiles.mapbox.com/v4/geocode/mapbox.places-v1/${lat},${lng}.json?access_token=${access_token}`,
    };

    axios.request(options).then(function (response) {
        setTimeout(() => {

            for (const context of response.data.features[0].context) {
                const context_id = context.id
                if (context_id.includes("province")) {
                    address = address + ", " + context.text
                }
                if (context_id.includes("city")) {
                    city = context.text
                    address = address + ", " + city
                }
                if (context_id.includes("place")) {
                    place = context.text
                    address = address + ", " + place
                }
                if (context_id.includes("region")) {
                    region = context.text
                    address = address + ", " + region
                }
                if (context_id.includes("country")) {
                    country_short = context.short_code
                    country = context.text
                    address = address + ", " + country
                }
                if (context_id.includes("postcode")) {
                    postal_code = context.text
                    address = address + ", " + postal_code

                }

            }
            const final_prospect = {
                name,
                category,
                lng,
                lat,
                address,
                city,
                country,
                country_short,
                postal_code,

            }

            axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-hubst3r-user-hub-prospect/`, final_prospect, tokenConfig())
                .then(res => {
                    dispatch({
                        type: CREATE_HUB_PROSPECT,
                        payload: res.data
                    });
                }).catch(err => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });


        }, 500);


        // dispatch({
        //     type: DASHBOARD_GET_LOCATIONS,
        //     payload: response.data
        // });

    }).catch(function (error) {
        console.error(error);
    });



    // axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-hub-prospect/`, prospect, tokenConfig())
    //     .then(res => {
    //         dispatch({
    //             type: CREATE_HUB_PROSPECT,
    //             payload: res.data
    //         });
    //     }).catch(err => {
    //         dispatch(returnErrors(err.response.data, err.response.status));
    //     });
}


export const getNewCreatedHub = (prospect_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${prospect_id}/get-new-created-hub/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_NEW_CREATED_HUB,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) });
}


export const createdHubMarker = () => (dispatch, getstate) => {
    dispatch({
        type: HUB_MARKER_CREATED,
    });
}




export const createSuggestionHubProspect = (prospect) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-suggestion-hub-prospect/`, prospect, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_SUGGESTION_HUB_PROSPECT,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}
// export const createNewMapPlace = (hub, prospect_id) => (dispatch, getstate) => {

//     axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${prospect_id}/create-new-map-place/`, hub, tokenConfig())
//         .then(res => {
//             dispatch({
//                 type: CREATE_NEW_MAP_PLACE,
//                 payload: res.data
//             });
//         }).catch(err => {
//             dispatch(returnErrors(err.response.data, err.response.status));
//         });
// }



export const hubWorkingDayClose = (day) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/working-day-to-close/`, day, tokenConfig())
        .then(res => {
            dispatch({
                type: WORKING_DAY_TO_CLOSE,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const setWorkingDayHours = (day) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-working-day-hours/`, day, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_WORKING_DAY_HOURS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const uploadHubLogo = (hub) => (dispatch, getstate) => {
    const hubData = new FormData();
    hubData.append("id", hub.id);
    hubData.append("logo", hub.logo);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/upload-hub-logo/`, hubData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPLOAD_HUB_LOGO,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const uploadHubCoverPhoto = (hub) => (dispatch, getstate) => {
    const hubData = new FormData();
    hubData.append("id", hub.id);
    hubData.append("cover_photo", hub.cover_photo);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/upload-hub-cover-photo/`, hubData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPLOAD_HUB_COVER_PHOTO,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const uploadHubPromoPhoto = (promo) => (dispatch, getstate) => {
    const hubData = new FormData();
    hubData.append("photo", promo.photo);
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-owner/upload-hub-promo-photo/`, hubData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPLOAD_HUB_PROMO_PHOTO,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const setHubName = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-name/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_NAME,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const setHubCategory = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-category/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_CATEGORY,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const setHubMapIcon = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-map-icon/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_MAP_ICON,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const setHubWifiCode = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-wifi-code/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_WFI_CODE,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const setHubTelephone = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-telephone/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_TELEPHONE,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const setHubWebsite = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-website/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_WEBSITE,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const setHubFacebook = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-facebook/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_FACEBOOK,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const setHubInstagram = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-instagram/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_INSTAGRAM,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const setHubReservations = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-reservations/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_RESERATIONS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const setHubAppointments = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-appointments/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_APPOINTMENTS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const setHubOnlineShop = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-online-shop/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_ONLINE_SHOP,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}



export const getHubCatalogs = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-hub-all-catalogs/${hub_id}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_CATALOGS,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) });
}



export const createHubCataloge = (cataloge) => (dispatch, getstate) => {
    const catalogeData = new FormData();
    catalogeData.append("hub", cataloge.hub);
    catalogeData.append("name", cataloge.name);

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-hub-catalog/`, catalogeData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPLOAD_HUB_CATALOGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const removeHubCataloge = (cataloge) => (dispatch, getstate) => {

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/remove-hub-catalog/`, cataloge, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_HUB_CATALOGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const createNewMenuPage = (menu_page) => (dispatch, getstate) => {
    const pageData = new FormData();
    pageData.append("hub", menu_page.hub);
    pageData.append("photo", menu_page.photo);
    pageData.append("page", menu_page.page);
    pageData.append("container_id", menu_page.container_id);

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-catalog-new-page/`, pageData, tokenConfig())
        .then(res => {
            dispatch({
                type: UPLOAD_HUB_CATALOGE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getHubMenuPages = (catalog_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-hub-menu-pages/${catalog_id}`, tokenConfig())
        .then(res => {

            dispatch({
                type: GET_HUB_MENU_PAGES,
                payload: res.data
            });

        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUB_MENU_PAGES_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const getHubFirstCatalogPages = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-hub-first-catalog-pages/${hub_id}`, tokenConfig())
        .then(res => {

            dispatch({
                type: GET_HUB_MENU_PAGES,
                payload: res.data
            });

        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: HUB_MENU_PAGES_LOCALY_LOADED,
                });
            }
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const selectHubReservationHours = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-reservation-hours/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_RESERATION_HOURS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

export const selectHubAppointmentHours = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-appointment-hours/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_APPOINTMENT_HOURS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const createNewSpecificReservationHour = (hour) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-new-reservation-hour/`, hour, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_NEW_RESERATION_HOUR,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const removeSpecificReservationHour = (hour) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/remove-reservation-hour/`, hour, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_RESERATION_HOUR,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const createNewSpecificAppointmentHour = (hour) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-new-appointment-hour/`, hour, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_NEW_APPOINTMENT_HOUR,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const removeSpecificAppointmentHour = (hour) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/remove-appointment-hour/`, hour, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_APPOINTMENT_HOUR,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const setSales = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-hub-sales/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_HUB_SALES,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const getHubUsersWithAccess = (hub_id) => (dispatch, getstate) => {
    dispatch({ type: SEARCHING_USER });

    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-hub-users-with-access/`, tokenConfig())
        .then(res => {

            dispatch({
                type: GET_HUB_USERS_WITH_ACCESS,
                payload: res.data
            });
            dispatch({ type: FINISH_SEARCHING });

        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
            }
            dispatch({ type: FINISH_SEARCHING });
            dispatch(returnErrors(err.response.data, err.response.status))
        });
}


export const setMenuPageDescription = (page) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-menu-page-description/`, page, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_MENU_PAGE_DESCRIPTION,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const setMenuPagePrice = (page) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/set-menu-page-price/`, page, tokenConfig())
        .then(res => {
            dispatch({
                type: SET_MENU_PAGE_PRICE,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const changeHubIsOvernight = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/change-hub-is-overnight/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: CHANGE_HUB_IS_OVERNIGHT,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const getHubMenuQr = (hub_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/get-hub-menu-qr/${hub_id}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_MENU_QR,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const createHubMenuQr = (hub) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/create-hub-menu-qr/`, hub, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_HUB_MENU_QR,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}


export const changeHubCatalogIndex = (catalog) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/change-hub-catalog-index/`, catalog, tokenConfig())
        .then(res => {
            dispatch({
                type: CHANGE_HUB_CATALOG_INDEX,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}