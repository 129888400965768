import React, { Component, Fragment } from 'react';
import Modal from 'react-awesome-modal';
import SayDelineReasonModal from '../meeting/SayDeclineReasonModal';

export class MeetingInvitation extends Component {

    state = {
        sayDeclineReasonModal: false,
        declineReason: "",
        answeredInvitations: []
    }

    updateInvitationsAnsweredList(id) {
        const invitations_answered = [...this.state.answeredInvitations]
        invitations_answered.push(id)
        this.setState({ answeredInvitations: invitations_answered })
    }

    acceptInvitation = (meeting_id, notification_id) => (e) => {
        const meeting = meeting_id
        const status = "Accepted"
        const reason = ""
        const answer = { status, meeting, reason };
        // const answer = { status, meeting };
        this.props.handleInvitation(answer);
        this.updateInvitationsAnsweredList(meeting_id)
        // this.props.deleteNotification(notification_id)
    }

    declineInvitation = (meeting_id, notification_id) => (e) => {
        const meeting = meeting_id
        const status = "Declined"
        const reason = this.state.declineReason
        const answer = { status, meeting, reason };
        // const answer = { status, meeting };
        this.props.handleInvitation(answer);
        this.updateInvitationsAnsweredList(meeting_id)
        // this.props.deleteNotification(notification_id)
    }

    setDeclineReason(reason) {
        this.setState({
            declineReason: reason
        })
    }

    openSayDeclineReasonModal() {
        this.setState({
            sayDeclineReasonModal: true,
        });
    }

    closeModal() {
        this.setState({
            sayDeclineReasonModal: false,
            declineReason: ""
        });
    }

    render() {
        const { notification } = this.props.data
        return (
            <Fragment>
                <Modal visible={this.state.sayDeclineReasonModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                    <SayDelineReasonModal
                        data={{
                            setDeclineReason: this.setDeclineReason.bind(this),
                            declineInvitation: this.declineInvitation(notification.meeting_id).bind(this, notification.meeting_id),
                            closeModal: this.closeModal.bind(this)
                        }}
                    />
                </Modal>
                <div class="row single-item-9">
                    <div class="notification-related-photo-position">
                        {notification.related_picture ?
                            <img class="related-picture-layout" src={notification.related_picture} alt="" />
                            :
                            <img class="related-picture-layout" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                        }

                    </div>
                    {/* <span class="notification-section-icon-layout">
                        <img class="notification-section-icon" src={require("../../../assets/notifications/svg/Meetings-b.svg")} alt="" />
                    </span> */}
                    <div class="notification-information-section">
                        <span class="row notification-meeting-info">
                            <p class="text-blue-9">{notification.invitation_date}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {notification.invitation_day}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {notification.invitation_time}</p>
                        </span>
                        <div class="notification-text-position">
                            <p class="text-blue-9 notification-text-layout"><strong>{notification.related_name}
                            </strong> Invited you to go out!!
                            </p>
                        </div>

                    </div>
                    <div class="notification-related-icon-layout text-center">
                        <img class="notification-related-icon-size" src={notification.hub_invitation_logo} alt="" />
                        <p class="text-blue-9 notification-text-layout">{notification.hub_invitation_name}</p>
                    </div>

                    <div class="row notification-buttons-layout">
                        {this.state.answeredInvitations.includes(notification.meeting_id) ?
                            <div class="btn-light-notifications">
                                Answered
                            </div>
                            :
                            <>
                                <div
                                    onClick={this.acceptInvitation(notification.meeting_id, notification.id)}
                                    class="btn-green-9"
                                >
                                    Accept
                                </div>
                                <div
                                    onClick={() => this.openSayDeclineReasonModal()}
                                    // onClick={this.declineInvitation(notification.meeting_id, notification.id)}
                                    class="btn-red-9"
                                >
                                    Decline
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Fragment >
        )
    }
}


export default (MeetingInvitation);