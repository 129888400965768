import axios from 'axios';
import { createAlert, returnErrors } from './alerts';
import {
    GET_HOMEPAGE_MEMORIES,
    GET_HOMEPAGE_SUGGESTED_MEMORIES,
    SUGGESTED_MEMORIES_LOCALY_LOADED,
    MEMORIES_LOCALY_LOADED,
    USER_MEMORIES_LOCALY_LOADED,
    GET_FULLSCREEN_MEMORY,
    GET_MEMORY_IMPRESSIONS,
    GET_HUB_MEMORIES,
    GET_USER_MEMORIES,
    GET_MY_MEMORIES,
    MY_MEMORIES_LOCALY_LOADED,
    GET_HUB_APPROVED_MEMORIES,
    APPROVED_MEMORIES_LOCALY_LOADED,
    GET_HUB_DECLINED_MEMORIES,
    DECLINED_MEMORIES_LOCALY_LOADED,
    GET_HUB_UNREVIEWED_MEMORIES,
    UNREVIEWS_MEMORIES_LOCALY_LOADED,
    CREATE_MEMORY_REACTION,
    GET_HOMEPAGE_PROMOS,
    PROMOS_LOCALY_LOADED,
    REVIEW_MEMORY,
    UPLOAD_MEMORY,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION,
    DELETE_MEMORY,
    DELETE_REACTION,
    GET_MEMORY_TAGS,
    ADD_BUDDY_TAG,
    REMOVE_BUDDY_TAG,
    REMOVE_MY_TAG,
    ACCEPT_MEMORY_TO_PROFILE,
    DECLINE_MEMORY_TO_PROFILE,
    UPLOADING_MEMORY,
    FINISH_UPLOADING_MEMORY,
    CREATE_MEMORY_VIEW,
    CLEAR_HUB_MEMORIES,
    CLEAR_MEMORY_TAGS,
} from './actionTypes';
import { tokenConfig } from './auth';


export const getHomepageMemories = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/homepage-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HOMEPAGE_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: MEMORIES_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const getHomepageSuggestedMemories = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/homepage-suggested-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HOMEPAGE_SUGGESTED_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: SUGGESTED_MEMORIES_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}

export const getMemoryTags = (memory_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user/${memory_id}/get-memory-tags/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MEMORY_TAGS,
                payload: res.data
            });
        }).catch(err => {
            if (!err.response) {
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const getHomepagePromos = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/homepage-promos/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HOMEPAGE_PROMOS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: PROMOS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}

export const getFullScreenMemory = (id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${id}/fullscreen-memory/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_FULLSCREEN_MEMORY,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getMemoryImpressions = (id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${id}/get-memory-impressions/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MEMORY_IMPRESSIONS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getMyMemories = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/my-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_MY_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: MY_MEMORIES_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        });
}

export const getUserMemories = (id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${id}/user-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_USER_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: USER_MEMORIES_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        });
}

export const getHubMemories = (hub_id) => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/${hub_id}/hub-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getApprovedMemories = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/hub-owner/approved-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_APPROVED_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: APPROVED_MEMORIES_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}

export const getDeclinedMemories = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/hub-owner/declined-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_DECLINED_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: DECLINED_MEMORIES_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}

export const getUnreviewedMemories = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub/hub-owner/unreviewed-memories/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_UNREVIEWED_MEMORIES,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: UNREVIEWS_MEMORIES_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const handleMemory = (answer, memory_id) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/${memory_id}/response/`, answer, tokenConfig())
        .then(res => {
            dispatch({
                type: REVIEW_MEMORY,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const uploadMemory = (memory) => (dispatch, getstate) => {
    dispatch({ type: UPLOADING_MEMORY });
    const memoryData = new FormData();
    memoryData.append("hub_id", memory.hub_id);
    memoryData.append("photo", memory.photo)
    memoryData.append("video", memory.video)
    memoryData.append("tags_list", memory.tags_list)
    memoryData.append("source_id", memory.source_id)

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/upload-memory/`, memoryData, tokenConfig())
        .then(res => {
            dispatch(createAlert({ uploadMemory: 'Your Memory has been Uploaded' }));
            dispatch({
                type: UPLOAD_MEMORY,
                payload: res.data
            });
            dispatch({ type: FINISH_UPLOADING_MEMORY });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const uploadNoHubMemory = (memory) => (dispatch, getstate) => {
    dispatch({ type: UPLOADING_MEMORY });
    const memoryData = new FormData();
    memoryData.append("hub_name", memory.hub_name);
    memoryData.append("lat", memory.lat);
    memoryData.append("lng", memory.lng);
    memoryData.append("photo", memory.photo)
    memoryData.append("video", memory.video)
    memoryData.append("tags_list", memory.tags_list)

    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/upload-no-hub-memory/`, memoryData, tokenConfig())
        .then(res => {
            dispatch(createAlert({ uploadMemory: 'Your Memory has been Uploaded' }));
            dispatch({
                type: UPLOAD_MEMORY,
                payload: res.data
            });
            dispatch({ type: FINISH_UPLOADING_MEMORY });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const createMemoryReaction = (reaction) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/create-reaction/`, reaction, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_MEMORY_REACTION,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const deleteMemory = id => (dispatch, getstate) => {
    axios.delete(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/upload-memory/${id}/`, tokenConfig())
        .then(res => {
            dispatch(createAlert({ cancelMeeting: 'The Memory has been Removed' }));
            dispatch({
                type: DELETE_MEMORY,
                payload: id
            });
        }).catch(err => console.log(err));
}

export const deleteReaction = id => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/user/${id}/delete-memory-impression/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DELETE_REACTION,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}


export const addTagedBuddy = (memory_id, tag) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/${memory_id}/tag-buddy/`, tag, tokenConfig())
        .then(res => {
            dispatch({
                type: ADD_BUDDY_TAG,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const removeTagedBuddy = (memory_id, tag) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/${memory_id}/untag-buddy/`, tag, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_BUDDY_TAG,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const removeMytag = (memory_id, tag) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/${memory_id}/remove-my-tag/`, tag, tokenConfig())
        .then(res => {
            dispatch({
                type: REMOVE_MY_TAG,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const acceptMemoryToProfile = (memory_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/${memory_id}/accept-memory-to-profile/`, tokenConfig())
        .then(res => {
            dispatch({
                type: ACCEPT_MEMORY_TO_PROFILE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const declineMemoryToProfile = (memory_id) => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/${memory_id}/decline-memory-to-profile/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DECLINE_MEMORY_TO_PROFILE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const createMemoryView = (reaction) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/memory/create-memory-view/`, reaction, tokenConfig())
        .then(res => {
            dispatch({
                type: CREATE_MEMORY_VIEW,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const clearStateHubMemories = () => (dispatch, getstate) => {
    dispatch({ type: CLEAR_HUB_MEMORIES, });
}


export const clearMemoryTags = () => (dispatch, getstate) => {
    dispatch({ type: CLEAR_MEMORY_TAGS, });
}

