import axios from 'axios';
import { returnErrors } from './alerts';
import {
    GET_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    NOTIFICATIONS_LOCALY_LOADED,
    GET_NOTIFICATIONS_STATUS,
    SEND_PUSH_NOTIFICATION_INFO,
    GET_EMAIL_NOTIFICATIONS_STATUS,
    CHANGE_EMAIL_NOTIFICATIONS_STATUS,
    GET_HUB_PENDING_NOTIFICATIONS_STATUS,
    GET_HUB_CANCELLATIONS_NOTIFICATIONS_STATUS,
    LOADING_PAGE,
    PAGE_LOADED,
    NO_INTERNET_CONNECTION
} from './actionTypes';
import { tokenConfig } from './auth';


export const getNotifications = () => (dispatch, getstate) => {
    dispatch({ type: LOADING_PAGE });
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/notifications/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: res.data
            });
            setTimeout(() => {
                dispatch({ type: PAGE_LOADED });
            }, 500);
        }).catch(err => {
            if (!err.response) {
                dispatch({ type: NO_INTERNET_CONNECTION });
                dispatch({
                    type: NOTIFICATIONS_LOCALY_LOADED,
                });
                dispatch(returnErrors(err.response.data, err.response.status))
            }
        })
}


export const getNotificationsStatus = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/notifications-status/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_NOTIFICATIONS_STATUS,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) })
}

export const getHubPendingNotificationsStatus = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-pending-notifications-status/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_PENDING_NOTIFICATIONS_STATUS,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) })
}

export const getHubCancellationsNotificationsStatus = () => (dispatch, getstate) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/hub-cancellations-notifications-status/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_HUB_CANCELLATIONS_NOTIFICATIONS_STATUS,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) })
}


export const deleteNotification = id => (dispatch, getstate) => {
    axios.delete(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/${id}/notifications/${id}/`, tokenConfig())
        .then(res => {
            dispatch({
                type: DELETE_NOTIFICATION,
                payload: id
            });
        }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const registerPushNotificationDevice = (data) => (dispatch, getstate) => {
    setTimeout(() => {
        dispatch({ type: PAGE_LOADED });
    }, 500);
    const registerNotifTokenURL = `${process.env.REACT_APP_HOST_IP_ADDRESS}/api/gcmdevice/`
    const browser = JSON.parse(localStorage.getItem('brsr'))
    const registration_id = JSON.parse(localStorage.getItem('pnd_tkn'))
    // -------------------- Push Notifications (WebPushDevice) --------------------
    // const data = localStorage.getItem('pnd')
    // if (browser !== "SAFARI") {
    //     axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/webpushdevice/`, data, tokenConfig())
    //         .then(res => {
    //             dispatch({
    //                 type: SEND_PUSH_NOTIFICATION_INFO,
    //                 payload: res.data
    //             });
    //         }).catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
    // }
    // ----------------------------------------------------------------------------
    if (registration_id) {
        // && browser !== "SAFARI"
        const payload = {
            registration_id: registration_id,
            cloud_message_type: 'FCM'
        }
        axios.post(registerNotifTokenURL, payload, tokenConfig())
            .then((response) => {
                console.log('Successfully saved notification token!')
                // console.log(response.data)
            })
            .catch((error) => {
                console.log('Error: could not save notification token')
                if (error.response) {
                    console.log(error.response.status)
                    // Most of the time a "this field must be unique" error will be returned,
                    // meaning that the token already exists in db, which is good.
                    if (error.response.data.registration_id) {
                        for (let err of error.response.data.registration_id) {
                            console.log(err)
                        }
                    } else {
                        console.log('No reason returned by backend')
                    }
                    // If the request could not be sent because of a network error for example
                } else if (error.request) {
                    console.log('A network error occurred.')
                    // For any other kind of error
                } else {
                    console.log(error.message)
                }
            })
    }
}

export const getUserNotificationAcceptance = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/email-notifications-status/`, tokenConfig())
        .then(res => {
            dispatch({
                type: GET_EMAIL_NOTIFICATIONS_STATUS,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) })
}

export const changeUserNotificationAcceptance = (data) => (dispatch, getstate) => {
    axios.post(`${process.env.REACT_APP_HOST_IP_ADDRESS}/api/email-notifications-status/`, data, tokenConfig())
        .then(res => {
            dispatch({
                type: CHANGE_EMAIL_NOTIFICATIONS_STATUS,
                payload: res.data
            });
        }).catch(err => { dispatch(returnErrors(err.response.data, err.response.status)) })
}