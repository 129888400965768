import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// gag and buddies Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// popups
import Modal from 'react-awesome-modal';
import GagModal from '../gag/GagModal';

export class BuddiesTab extends Component {
    state = {
        gagModal: false,
        gag_name: "",
        gag_id: "",
        gag_persons: ""
    };

    openModal = (name, id, persons) => (e) => {
        this.setState({
            gag_name: name,
            gag_id: id,
            gag_persons: persons,
            gagModal: true
        });
        this.props.getGagMembers(id);
    }

    closeModal() {
        this.setState({
            gagModal: false
        });
    }

    render() {
        // carousel arrows
        const ArrowNext = (props) => (
            <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-right-position">
                <div class="arrow arrow-right">
                    <img src={require("../../../assets/arrange-meeting/svg/next.svg")} alt="" />
                </div>
            </div>
        );

        const ArrowPrev = (props) => (
            <div onClick={props.onClick} class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 text-right arrow-left-position">
                <div class="arrow arrow-left">
                    <img src={require("../../../assets/arrange-meeting/svg/Icon.svg")} alt="" />
                </div>
            </div>
        );

        let infinite_value_buddies = false
        if (this.props.data.buddies.length > 3) {
            infinite_value_buddies = true
        }

        //invitation carousel settings
        const settings_buddies = {
            dots: false,
            infinite: infinite_value_buddies,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 1000,
            cssEase: "linear",
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />,
        };

        let infinite_value_gag = false
        if (this.props.data.gags.length > 3) {
            infinite_value_gag = true
        }

        const settings_gag = {
            dots: false,
            infinite: infinite_value_gag,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 1000,
            cssEase: "linear",
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />,
        };

        const settingsTrollSlider = {
            dots: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 1000,
            cssEase: "linear",
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />,
        };
        return (

            <Fragment>
                <Modal visible={this.state.gagModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                    <GagModal
                        deleteGag={this.props.deleteGag}
                        data={{ gag_id: this.state.gag_id, gag_name: this.state.gag_name, gag_persons: this.state.gag_persons, closeModal: this.closeModal.bind(this) }}
                    />
                </Modal>
                <div class="row sec-item-5">
                    <div class="col-xl-2"></div>
                    {this.props.data.buddies.length > 0 ?
                        <div class="col-xl-8 item-5">
                            <div class="row">
                                <div class="col-xl-12 text-left ml-3 item-text-5">
                                    <h3 class="blue">Gangs</h3>
                                    <h7 class="blue">Group your Buddies</h7>
                                </div>
                                <div class="col-xl-12">
                                    <Link to={{ pathname: '/gag' }}>
                                        <div class="blue-box-5">
                                            <img src={require("../../../assets/profile/svg/Icon-plus.svg")} alt="" />
                                            <span>Create a Gang</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div class="" >
                                <Slider {...settings_gag} >
                                    {this.props.data.gags.map(gag => (
                                        <div key={gag.id}>
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 text-center profile-item-div-4" >
                                                <div class="item-img-4">
                                                    <img
                                                        onClick={this.openModal(gag.name, gag.id, gag.persons)}
                                                        class="arrange-buddy-img"
                                                        src={require("../../../assets/arrange-meeting/svg/Icon-p.svg")}
                                                        alt=""
                                                    />
                                                </div>
                                                <p class="item-text-4 blue profile-sliders-names-size">{gag.name}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>

                        </div>
                        : null}
                    <div class="col-xl-2"></div>
                </div>

                <div class="row mb-5">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-8 item-5 mt-4">
                        <div class="">
                            <div class="row col-xl-12 text-left ml-3 item-text-5">
                                <h3 class="blue">Buddies</h3>
                                {this.props.data.buddies.length > 0 ?
                                    <Link to={{ pathname: '/show-profile-buddies', state: { profile_username: this.props.data.profile_username } }}>
                                        <div class="profile-show-all-buddies-section">
                                            <div class="profile-show-all-buddies-button">
                                                <img
                                                    class="profile-show-all-buddies-icon"
                                                    src={require("../../../assets/profile/svg/show-buddies.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="profile-show-all-buddies">
                                                Show all
                                            </div>
                                        </div>
                                    </Link>
                                    :
                                    null
                                }
                            </div>

                        </div>
                        {this.props.data.buddies.length === 0 ?
                            // <div class="profile-slider-size">
                            //     <br /><br /><br />
                            //     <Slider {...settingsTrollSlider} >
                            //         <div class="" >
                            //             <div class="item-img-4">
                            //                 <Link to='/'>
                            //                     <img
                            //                         class="arrange-forever-alone-img"
                            //                         src={require("../../../assets/layout/mika/forever_alone.png")}
                            //                         alt=""
                            //                     />
                            //                 </Link>
                            //             </div>
                            //         </div>
                            //         <div class="" >
                            //             <div class="item-img-4">
                            //                 <Link to='/'>
                            //                     <img
                            //                         class="arrange-forever-alone-img"
                            //                         src={require("../../../assets/layout/mika/forever_alone.png")}
                            //                         alt=""
                            //                     />
                            //                 </Link>
                            //             </div>
                            //         </div>
                            //         <div class="" >
                            //             <div class="item-img-4">
                            //                 <Link to='/'>
                            //                     <img
                            //                         class="arrange-forever-alone-img"
                            //                         src={require("../../../assets/layout/mika/forever_alone.png")}
                            //                         alt=""
                            //                     />
                            //                 </Link>
                            //             </div>
                            //         </div>
                            //         <div class="" >
                            //             <div class="item-img-4">
                            //                 <Link to='/'>
                            //                     <img
                            //                         class="arrange-forever-alone-img"
                            //                         src={require("../../../assets/layout/mika/../../../assets/layout/mika/forever_alone.png")}
                            //                         alt=""
                            //                     />
                            //                 </Link>
                            //             </div>
                            //         </div>
                            //     </Slider>
                            //     <br /><br />
                            //     <p class="item-text-4 blue pt-1">Don't be this guy. <br /> <Link to='/search-buddies'>Add your Buddies</Link></p>
                            //     <br /><br />
                            // </div>





                            <div class="profile-no-memories-mika-layout">
                                <Link to='/search-buddies'>
                                    <div class="profile-no-memories-image">
                                        <img
                                            class="profile-no-memories-created"
                                            src={require("../../../assets/layout/mika/dont-know.svg")}
                                            alt=""
                                        />
                                    </div>
                                    <p class="profile-no-memories-text">
                                        You don't have any Buddies.
                                        <br />
                                        <b>Add your Buddies Now</b>
                                    </p>
                                </Link>
                            </div>



                            :
                            <div class="" >
                                <Slider {...settings_buddies} >
                                    {this.props.data.buddies.map(buddy => (
                                        <div key={buddy.id}>
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 text-center profile-item-div-4" >
                                                <Link to={{ pathname: `/user-profile/${buddy.user}/profile` }}>
                                                    <div class="item-img-4">
                                                        {buddy.profile_picture ?

                                                            <img class="profile-buddy-img" src={buddy.profile_picture} alt="" />
                                                            :
                                                            <img class="profile-buddy-img" src={require("../../../assets/authentication/svg/account.svg")} />
                                                        }
                                                    </div>
                                                </Link>
                                                <p class="item-text-4 blue profile-sliders-names-size">{buddy.username}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        }
                    </div>
                    <div class="col-xl-2"></div>
                </div>
                <br /><br /><br />
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    buddies: state.buddies.members,
});

export default connect(mapStateToProps, {})(BuddiesTab);