import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "../../../assets/layout/offline.css"


export class OfflineLabel extends Component {
    render() {
        return (
            <Fragment>
                {this.props.noInternet ?
                    <div className="offline-label">
                        <center>
                            <strong>
                                You are Offline. Check your Connection !!!
                            </strong>
                        </center>
                    </div>
                    :
                    null
                }
            </Fragment >
        )
    }
}
const mapStateToProps = state => ({
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {})(OfflineLabel);


