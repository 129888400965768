import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import HubBottomNavbar from '../../../components/hub/layout/hubBottomNavabar';
import HubMobileTraffic from '../../../components/hub/layout/hubMobileTraffic';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import HubDesktopTraffic from '../../../components/hub/layout/hubDesktopTraffic';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import HubGdprLinks from '../../../components/hub/layout/hubgdprLinks';
import { getAddReservationProfile } from '../../../store/actions/profile'
import { getHubPage } from '../../../store/actions/hub'
import { getHubPendingNotificationsStatus, getHubCancellationsNotificationsStatus } from '../../../store/actions/notification'
import {
    createNewReservation
} from '../../../store/actions/reservation'
import { createAlert } from '../../../store/actions/alerts'
//datepicker
import moment from "moment-timezone";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import TimePickerComp from '../../../components/user/meeting/TimePicker';
import WheelPicker from 'react-simple-wheel-picker';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/arrange-meeting/arrange-page.css";
import { getHubDaysReservationHours } from '../../../store/actions/reservation';


export class AddReservation extends Component {
    state = {
        reservation_note: '-',
        telephone: "-",
        hub: '',
        date: new Date(),
        date_updated: false,
        redirect: false,
        show_datepicker: false,
        hour: '',
        minute: '',
        time: '',
        persons: '',
        time_updated: false,
        hour_sellected: false,
        dateMode: true,
        personsMode: false,
        timeMode: false,
        noteMode: false,
        hub_name: "",
        hub_logo: "",
        hub_id: "",
        timeModalVisible: true,
        pre_selected: false,
        reservation_name: "",
        redirect: false,
        selected_time: {}
    }

    componentDidMount() {
        this.props.getHubPage();
    }

    handleBack = () => {
        this.props.history.goBack()
    }


    selectDateMode(hub_name, hub_logo, hub_id) {
        this.setState({
            hubMode: false,
            dateMode: true,
            personsMode: false,
            timeMode: false,
            noteMode: false
        })
        if (hub_name !== "") {
            this.setState({
                hub_name: hub_name,
                hub_logo: hub_logo,
                hub_id: hub_id
            })
        }
    }

    selectDateModeTop() {
        this.setState({
            hubMode: false,
            dateMode: true,
            personsMode: false,
            timeMode: false,
            noteMode: false
        })
    }

    selectTimeMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            personsMode: false,
            timeMode: true,
            noteMode: false,
            timeModalVisible: true,

        })
    }

    selectNoteMode(person) {
        if (person) {
            this.setState({
                persons: person
            })
        }
        this.setState({
            hubMode: false,
            dateMode: false,
            personsMode: false,
            timeMode: false,
            noteMode: true
        })
    }

    selectPersonsMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            personsMode: true,
            timeMode: false,
            noteMode: false
        })
    }

    handleOnChange(time) {
        this.setState({
            selected_time: time
        })
    }

    updateDateField(day) {
        const hub_id = this.props.hub[0].id
        const correct_datetime = moment(day).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubDaysReservationHours(hub_id, date_id)
        this.setState({
            show_datepicker: false,
            date: day,
            date_updated: true,
            hubMode: false,
            dateMode: false,
            timeMode: true,
            timeModalVisible: true,
            noteMode: false,
            pre_selected: true
        })
    }

    updateReservatonHour = (options) => {
        const {
            hour,
            minute,
        } = options;
        if (this.state.hour_sellected) {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                time_updated: true,
                hubMode: false,
                dateMode: false,
                timeMode: false,
                hour_sellected: false,
                personsMode: true,
                noteMode: false
            });
        } else {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                hour_sellected: true,
            });
        }


    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    setDatepickerMode = () => (e) => {
        this.setState({
            show_datepicker: true
        });
    }

    openTimeModal() {
        this.setState({
            selected_hour: false,
            timeModalVisible: true,
        });
    }

    closeModal() {
        this.setState({
            timeModalVisible: false,
            hubMode: false,
            dateMode: false,
            personsMode: true,
            timeMode: false,
            noteMode: false
        });
    }

    sellectedHourMode(hour) {
        let minute = this.state.minute
        if (minute === "") {
            minute = "00"
        }
        const time = hour + ':' + minute
        this.setState({
            hour: hour,
            time: time

        });
        setTimeout(() => {
            this.setState({
                selected_hour: true,
            });
        }, 500);
    }

    sellectedMinuteMode(minute) {
        const time = this.state.hour + ':' + minute
        this.setState({
            minute: minute,
            time: time,

            hubMode: false,
            dateMode: false,
            timeMode: false,
            timeModalVisible: false,
            personsMode: true,
            noteMode: false

        });
        setTimeout(() => {
            this.setState({
                selected_hour: false,
            });
        }, 700);
    }

    backToHours = e => {
        this.setState({
            selected_hour: false,
        });
    }


    selectSpecificTime() {
        this.setState({
            time: this.state.selected_time.value,
            dateMode: false,
            timeMode: false,
            personsMode: true,
            noteMode: false
        })
    }


    onSubmitReservation = e => {
        const reservation_name = this.state.reservation_name
        const time = this.state.selected_time.value
        const persons = this.state.persons
        const telephone = this.state.telephone
        const note = this.state.reservation_note
        const date = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        // let user = this.props.data.user_profile[0]
        // if (user) {
        //     user = user.user
        // } else {
        //     user = ""
        // }

        const reservation = { time, date, persons, note, telephone, reservation_name };

        if (!this.state.date_updated) {
            this.props.createAlert({ noTimeSelected: "You haven't Select a Date!" });
        } else if (this.state.reservation_name === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Select a name for the reservation!" });
        } else if (time === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Select time!" });
        } else if (persons === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Select for how many persons is the reservation for!" });
        } else {
            this.props.createNewReservation(reservation)
            setTimeout(() => {
                this.setState({
                    reservation_note: '-',
                    telephone: "-",
                    hub: '',
                    date: new Date(),
                    date_updated: false,
                    redirect: false,
                    show_datepicker: false,
                    hour: '',
                    minute: '',
                    time: '',
                    persons: '',
                    time_updated: false,
                    hour_sellected: false,
                    hubMode: true,
                    dateMode: false,
                    personsMode: false,
                    timeMode: false,
                    noteMode: false,
                    hub_name: "",
                    hub_logo: "",
                    hub_id: "",
                    timeModalVisible: true,
                    pre_selected: false,
                    reservation_name: "",
                    redirect: true
                });
            }, 500);
        }
    }


    render() {

        const ExampleCustomInput = ({ value, onClick }) => (
            <div
            // onClick={onClick}
            >
                {value}
            </div>
        );

        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 2,
            swipeToSlide: true,
            // nextArrow: <ArrowNext />,
            // prevArrow: <ArrowPrev />,
        };
        const persons = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]



        if (this.state.redirect) {
            return <Redirect to="/hub-homepage" />;
        }
        const search_user_profile = this.props.user_profile[0]

        const data = this.props.reservation_hours

        return (


            < Fragment >
                {/* <div class={this.props.isLoading ? "arrange-page-lottie-appear" : "arrange-page-lottie-dissappear"}>
                        <Hubst3rLottie />
                    </div> */}
                <HubDesktopNavbar data={{ sellected: "" }} />

                {/* <div class={this.props.isLoading ? "arrange-page-section-disappear" : null}> */}
                <div>
                    <div class="arrange-page-whole-page-layout">
                        <OfflineLabel />
                        <div class="color-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="request-reservation-mobile-navbar">
                                        <div onClick={() => this.handleBack()} class="back-button-request-reservation-box">
                                            <img
                                                class="request-reservation-back-button"
                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                        Create Reservation
                                    </div>

                                    <div class="body-right-4 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">


                                        <div class="arrange-invite-your-buddies-text">
                                            <div class="text-top text-center blue pt-5 pb-3">
                                                <h3>
                                                    Create Reservation
                                                </h3>
                                            </div>
                                        </div>

                                        <div class="request-reservation-top">
                                            <div class="request-reservation-top-row">

                                                <div
                                                    onClick={() => this.selectDateModeTop()}
                                                    class="request-reservation-top-step">
                                                    <div class="request-reservation-top-title">
                                                        Date
                                                    </div>

                                                    <div
                                                        class={this.state.dateMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {this.state.date_updated ?
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                alt=""
                                                            />
                                                            :
                                                            1
                                                        }
                                                    </div>
                                                    {this.state.date_updated ?
                                                        <div class="request-reservation-top-text">
                                                            <DatePicker
                                                                selected={this.state.date}
                                                                dateFormat="dd/MM/yyyy"
                                                                customInput={<ExampleCustomInput />}
                                                                readOnly
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>



                                                {this.state.date_updated ?
                                                    <div
                                                        onClick={() => this.selectTimeMode()}
                                                        class="request-reservation-top-step">
                                                        <div class="request-reservation-top-title"  >
                                                            Time
                                                        </div>
                                                        <div
                                                            class={this.state.timeMode ?
                                                                "request-reservation-btn-circle-sellected"
                                                                :
                                                                "request-reservation-btn-circle"
                                                            }
                                                        >
                                                            {this.state.time === "" ?
                                                                2
                                                                :
                                                                <img
                                                                    class="request-reservation-btn-hub-logo"
                                                                    src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="request-reservation-top-text">{this.state.time}</div>
                                                    </div>
                                                    :
                                                    <div
                                                        class="request-reservation-top-step">
                                                        <div class="request-reservation-top-title"  >
                                                            Time
                                                        </div>
                                                        <div
                                                            class={this.state.timeMode ?
                                                                "request-reservation-btn-circle-sellected"
                                                                :
                                                                "request-reservation-btn-circle"
                                                            }
                                                        >
                                                            {this.state.time === "" ?
                                                                2
                                                                :
                                                                <img
                                                                    class="request-reservation-btn-hub-logo"
                                                                    src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="request-reservation-top-text">{this.state.time}</div>
                                                    </div>
                                                }


                                                {/* <div
                                                    onClick={() => this.selectTimeMode()}
                                                    class="request-reservation-top-step">
                                                    <div class="request-reservation-top-title"  >
                                                        Time
                                                    </div>
                                                    <div
                                                        class={this.state.timeMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {this.state.time === "" ?
                                                            2
                                                            :
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                    <div class="request-reservation-top-text">{this.state.time}</div>
                                                </div> */}
                                                <div
                                                    onClick={() => this.selectPersonsMode()}
                                                    class="request-reservation-top-step"
                                                >
                                                    <div class="request-reservation-top-title">
                                                        Persons
                                                    </div>
                                                    <div
                                                        class={this.state.personsMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {this.state.persons === "" ?
                                                            3
                                                            :
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                alt=""
                                                            />
                                                        }
                                                    </div>
                                                    <div class="request-reservation-top-text">{this.state.persons}</div>
                                                </div>

                                                <div
                                                    onClick={() => this.selectNoteMode()}
                                                    class="request-reservation-top-step"
                                                >
                                                    <div
                                                        class="request-reservation-top-title">
                                                        Details
                                                    </div>
                                                    <div
                                                        class={this.state.noteMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        4
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />



                                        <div>

                                            {this.state.timeMode ?

                                                <>
                                                    {data.length !== 0 ?
                                                        <>
                                                            <div class="request-reservation-timewheel-box">
                                                                {data.length !== 0 ?

                                                                    <>
                                                                        <div class="request-reservation-timewheel-arrow-left-box">
                                                                            <img
                                                                                class="request-reservation-timewheel-arrow-img"
                                                                                src={require("../../../assets/hub/hub-homepage/svg/arrow-left.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="request-reservation-timewheel-arrow-right-box">
                                                                            <img
                                                                                class="request-reservation-timewheel-arrow-img"
                                                                                src={require("../../../assets/hub/hub-homepage/svg/arrow-right.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <WheelPicker
                                                                            data={data}
                                                                            onChange={this.handleOnChange.bind(this)}
                                                                            height={350}
                                                                            width={180}
                                                                            itemHeight={60}
                                                                            selectedID={data[5].id}
                                                                            color="#000"
                                                                            activeColor="white"
                                                                            backgroundColor="grey"
                                                                        />


                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                            </div>

                                                            <div onTouchStart>
                                                                <div
                                                                    onClick={() => this.selectSpecificTime()}
                                                                    class="request-reservation-request-button"
                                                                >
                                                                    Select {this.state.selected_time.value}
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <div class="request-reservation-no-reservation-hours">
                                                            You have sellected to be Closed<br />on this day of the week
                                                        </div>
                                                    }
                                                </>

                                                :
                                                null
                                            }

                                            {this.state.dateMode ?
                                                <>
                                                    <div class="request-reservation-section-title">
                                                        Select the Date
                                                    </div>

                                                    <DatePicker
                                                        selected={this.state.date}
                                                        todayButton="Press for Today"
                                                        onChange={this.updateDateField.bind(this)}
                                                        minDate={new Date()}
                                                        name="date"
                                                        dateFormat="dd/MM/yyyy"
                                                        // customInput={<ExampleCustomInput />}
                                                        // withPortal
                                                        inline
                                                    />
                                                </>
                                                :
                                                null
                                            }

                                            {this.state.personsMode ?
                                                <>
                                                    <div class="request-reservation-section-title">
                                                        Select for how many persons
                                                    </div>
                                                    <div class="request-reservation-hub-carousel">

                                                        {this.state.persons === "" ?
                                                            <>
                                                                <Slider {...settings} >
                                                                    {persons.map(person => (
                                                                        <div
                                                                            onClick={() => this.selectNoteMode(person)}
                                                                            class="request-reservation-hub-carouse-item"
                                                                        >
                                                                            {this.state.persons === person ?
                                                                                <img
                                                                                    class="request-reservation-hub-logo-selected"
                                                                                    src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                                    alt=""
                                                                                />
                                                                                :
                                                                                <img
                                                                                    class="request-reservation-hub-logo"
                                                                                    src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                                    alt=""
                                                                                />
                                                                            }
                                                                            <p class="request-reservation-hub-name">{person} persons</p>
                                                                        </div>
                                                                    ))}
                                                                </Slider>
                                                            </>
                                                            :
                                                            <Slider {...settings} >
                                                                <div
                                                                    onClick={() => this.selectNoteMode(this.state.persons)}
                                                                    class="request-reservation-hub-carouse-item"
                                                                >
                                                                    <img
                                                                        class="request-reservation-hub-logo-selected"
                                                                        src={require("../../../assets/booked_page/svg/sellect-persons.svg")}
                                                                        alt=""
                                                                    />
                                                                    <p class="request-reservation-hub-name">{this.state.persons} persons</p>
                                                                </div>
                                                            </Slider>
                                                        }

                                                    </div>
                                                    <div class="request-reservation-section-title">
                                                        or
                                                    </div>

                                                    <div class="request-reservation-section-title">
                                                        Write for how many Persons
                                                    </div>

                                                    <div className="request-reservation-write-persons">
                                                        <input
                                                            className="request-reservation-write-persons form-control mr-sm-2 w-100 py-4"
                                                            type="number"
                                                            name="persons"
                                                            onChange={this.onChange}
                                                            placeholder="Persons..."
                                                            value={this.state.persons}
                                                        />
                                                    </div>

                                                    <div
                                                        onClick={() => this.selectNoteMode(this.state.persons)}
                                                        class="request-reservation-persons-next"
                                                    >
                                                        Next
                                                    </div>

                                                    <br /><br /><br /><br />
                                                </>
                                                :
                                                null
                                            }

                                            {this.state.noteMode ?
                                                <>

                                                    <div class="request-reservation-notes">
                                                        Insert Reservation Name
                                                    </div>
                                                    <div class="form-group mt-4">
                                                        <input
                                                            maxlength="22"
                                                            className="form-control"
                                                            type="text"
                                                            name="reservation_name"
                                                            onChange={this.onChange}
                                                            placeholder={this.state.reservation_name === "" ? "Reservation name..." : this.state.reservation_name}
                                                        />

                                                    </div>

                                                    <div class="request-reservation-notes">
                                                        Insert Mobile Phone Number
                                                    </div>
                                                    <div class="form-group mt-4">
                                                        <input
                                                            maxlength="18"
                                                            className="form-control"
                                                            type="number"
                                                            name="telephone"
                                                            onChange={this.onChange}
                                                            placeholder={this.state.telephone === "-" ? "Mobile Phone Number..." : this.state.telephone}
                                                        />

                                                    </div>


                                                    <div class="request-reservation-notes">
                                                        Write a note for the reservation
                                                    </div>
                                                    <div class="form-group mt-4">
                                                        <textarea
                                                            class="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            placeholder={this.state.reservation_note === "-" ? "Note..." : this.state.reservation_note}
                                                            rows="3"
                                                            name="reservation_note"
                                                            onChange={this.onChange}
                                                        ></textarea>
                                                    </div>

                                                    <div onTouchStart>
                                                        <div

                                                            onClick={() => this.onSubmitReservation()}
                                                            class="request-reservation-request-button"
                                                        >
                                                            Create Reservation
                                                        </div>
                                                    </div>
                                                    <br /><br /><br /><br /><br />
                                                    <br /><br /><br /><br /><br />
                                                    {/* </div> */}
                                                </>
                                                :
                                                null
                                            }



                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment >



        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    user_profile: state.profile.user_profile,
    reservation_hours: state.reservation.reservation_hours
});

export default connect(mapStateToProps, {
    getHubPage,
    createNewReservation,
    getAddReservationProfile,
    createAlert,
    getHubDaysReservationHours
})(AddReservation);