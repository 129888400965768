import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';


export class InfoTab extends Component {

    render() {
        return (

            <Fragment>
                {this.props.data.profile.map(profile => (
                    <div class="white-box-profile">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="w-box3">
                                    <strong class="w-box1-s">Who am I?</strong>
                                    <br />
                                    <br />

                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/full-name.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Username:</strong>
                                        </div>
                                        <div class="buddies-profile-data-position">
                                            <div class="">
                                                <strong>{profile.username}</strong>
                                            </div>
                                        </div>

                                    </div>
                                    <br />
                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/full-name.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Full Name:</strong>
                                        </div>
                                        <div class="buddies-profile-data-position">
                                            <div class="">
                                                {profile.full_name === "null" ?
                                                    <div></div>
                                                    : profile.full_name === null ?
                                                        <div></div>
                                                        : profile.full_name === "" ?
                                                            <div></div>
                                                            : profile.full_name === "undefined" ?
                                                                <div></div>
                                                                :
                                                                <strong>{profile.full_name}</strong>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/quote-Icon.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Bio:</strong>
                                        </div>
                                        <div class="buddies-profile-data-position">
                                            <div class="">
                                                {profile.bio === "null" ?
                                                    <div></div>
                                                    : profile.bio === null ?
                                                        <div></div>
                                                        : profile.bio === "" ?
                                                            <div></div>
                                                            : profile.bio === "undefined" ?
                                                                <div></div>
                                                                :
                                                                <div>{profile.bio}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets//profile/svg/genter.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Gender:</strong>
                                        </div>
                                        <div class="buddies-profile-data-position">
                                            <div class="">
                                                {profile.genter === "null" ?
                                                    <div></div>
                                                    : profile.genter === null ?
                                                        <div></div>
                                                        : profile.genter === "" ?
                                                            <div></div>
                                                            : profile.genter === "undefined" ?
                                                                <div></div>
                                                                :
                                                                <strong>{profile.genter}</strong>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/location-red.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">City:</strong>
                                        </div>
                                        <div class="buddies-profile-data-position">
                                            <div class="">
                                                {profile.city === "null" ?
                                                    <div></div>
                                                    : profile.city === null ?
                                                        <div></div>
                                                        : profile.city === "" ?
                                                            <div></div>
                                                            : profile.city === "undefined" ?
                                                                <div></div>
                                                                :
                                                                <strong>{profile.city}</strong>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row my-3">
                                        <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                            <img class="hub-info-icon" src={require("../../../assets/profile/svg/location-red.svg")} alt="" />
                                        </div>
                                        <div class="col-xl-3 col-md-3 col-sm-3 col-8 text-line-6-profile">
                                            <strong class="profile-info-title-position">Country:</strong>
                                        </div>
                                        <div class="buddies-profile-data-position">
                                            <div class="">
                                                {profile.country === "null" ?
                                                    <div></div>
                                                    : profile.country === null ?
                                                        <div></div>
                                                        : profile.country === "" ?
                                                            <div></div>
                                                            : profile.country === "undefined" ?
                                                                <div></div>
                                                                :
                                                                <strong>{profile.country}</strong>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <br /><br /><br /><br />
            </Fragment >
        )
    }
}

export default (InfoTab);