import {
    GET_REGISTER_ALL_HUBS,
    GET_HOMEPAGE_ALL_HUBS,
    HUBS_LOCALY_LOADED,
    GET_ARRANGE_MEETING_HUB,
    GET_REQUEST_RESERVATION_HUBS,
    GET_MEMORY_UPLOAD_HUB_LIST,
    MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED,
    GET_USER_HUB_LIST,
    USER_HUB_LIST_LOCALLY_LOADED,
    GET_HUB_PAGE,
    HUB_PAGE_LOCALY_LOADED,
    UPDATE_HUB,
    GET_HUB_ACTIVITIES,
    HUB_ACTIVITIES_LOCALY_LOADED,
    REMOVE_ACTIVITY,
    CREATE_ACTIVITY,
    GET_HUB_WORKING_DAYS,
    WORKING_DAYS_LOCALY_LOADED,
    UPDATE_WORKING_DAY,
    GET_SEARCHED_HUBS,
    GET_HUB_METRICS,
    HUBS_METRICS_LOCALY_LOADED,
    CLEAR_HUBS,
    ACTIVATE_HUB_ACCOUNT,
    GET_SELLECTED_HUB,
    LOAD_HOMEPAGE_ALL_HUBS,
    GET_HOMEPAGE_SELLECTED_HUBS,
    FULL_SCREEN_PROMO,
    GET_SELECT_HUB_LIST,
    GET_SELECT_HUB_LIST_LOCALLY_LOADED,
    GET_HUB_AMBASSANDORS_PROFILE,
    GET_HUB_MENU_PAGES,
    GET_USER_HUB_CATALOGS,
    HUB_MENU_PAGES_LOCALY_LOADED,
    CREATE_HUB_PROSPECT,
    WORKING_DAY_TO_CLOSE,
    SET_WORKING_DAY_HOURS,
    UPLOAD_HUB_LOGO,
    UPLOAD_HUB_PROMO_PHOTO,
    SET_HUB_NAME,
    SET_HUB_CATEGORY,
    SET_HUB_WFI_CODE,
    SET_HUB_TELEPHONE,
    SET_HUB_WEBSITE,
    SET_HUB_FACEBOOK,
    SET_HUB_INSTAGRAM,
    SET_HUB_RESERATIONS,
    SET_HUB_APPOINTMENTS,
    SET_HUB_ONLINE_SHOP,
    SET_HUB_MAP_ICON,
    GET_HUB_CATALOGS,
    UPLOAD_HUB_CATALOGE,
    REMOVE_HUB_CATALOGE,
    SET_HUB_RESERATION_HOURS,
    SET_HUB_APPOINTMENT_HOURS,
    CREATE_NEW_RESERATION_HOUR,
    REMOVE_RESERATION_HOUR,
    CREATE_NEW_APPOINTMENT_HOUR,
    REMOVE_APPOINTMENT_HOUR,
    SET_HUB_SALES,
    GET_HUB_USERS_WITH_ACCESS,
    SEARCHING_USER,
    FINISH_SEARCHING,
    SET_MENU_PAGE_DESCRIPTION,
    SET_MENU_PAGE_PRICE,
    CHANGE_HUB_IS_OVERNIGHT,
    GET_HUB_MENU_QR,
    HUB_MARKER_CREATED,
    GET_NEW_CREATED_HUB,
} from '../actions/actionTypes';
import { updateObject } from "../utility";


const initialState = {
    hubs: [],
    hub: [],
    hubs_loading: false,
    hubs_has_more: true,
    hubs_offset: 0,
    hubs_limit: 15,
    activities: [],
    working_days: [],
    metrics: [],
    sellected_hub: [],
    promo: [],
    ambassandor: [],
    menu_pages: [],
    new_prospect_id: [],
    catalogs: [],
    new_catalog: [],
    reservation_hours: [],
    appointment_hours: [],
    user_catalogs: [],
    user_menu_pages: [],
    users_with_access: [],
    searching: false,
    hub_menu_qr: [],
    new_hub_created: false,
    new_created_hub: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_HUBS:
            return {
                ...state,
                hubs: [],
                activities: [],
                working_days: [],
                metrics: []
            };
        case GET_REGISTER_ALL_HUBS:
            return {
                ...state,
                hubs: action.payload
            };
        case LOAD_HOMEPAGE_ALL_HUBS:
            return {
                ...state,
                hubs_loading: true,
            };
        case GET_HOMEPAGE_ALL_HUBS:
            const requested_hubs = action.payload
            // .reverse()
            localStorage.setItem('hbs', JSON.stringify(action.payload));
            if (action.payload.length === 0) {
                return {
                    ...state,
                    hubs: [...state.hubs, ...requested_hubs],
                    hubs_loading: false,
                    hubs_has_more: false,
                    hubs_offset: state.hubs_offset + state.hubs_limit,
                };
            } else {
                return {
                    ...state,
                    hubs: [...state.hubs, ...requested_hubs],
                    hubs_loading: false,
                    hubs_has_more: true,
                    hubs_offset: state.hubs_offset + state.hubs_limit,
                };
            }
        case GET_SELECT_HUB_LIST:
            localStorage.setItem('slcthbs', JSON.stringify(action.payload));
            return {
                ...state,
                hubs: action.payload,
            };
        case GET_REQUEST_RESERVATION_HUBS:
            localStorage.setItem('slcthbs', JSON.stringify(action.payload));
            return {
                ...state,
                hubs: action.payload,
            };

        case GET_SELECT_HUB_LIST_LOCALLY_LOADED:
            return {
                ...state,
                hubs: JSON.parse(localStorage.getItem('slcthbs'))
            };
        case GET_HOMEPAGE_SELLECTED_HUBS:
            return {
                ...state,
                hubs: action.payload,
                hubs_loading: false,
                hubs_has_more: false,
                hubs_offset: state.hubs_offset + state.hubs_limit,
            };
        case HUBS_LOCALY_LOADED:
            return {
                ...state,
                hubs: JSON.parse(localStorage.getItem('hbs'))
            };
        case GET_SELLECTED_HUB:
            return {
                ...state,
                sellected_hub: action.payload
            };
        case GET_HUB_METRICS:
            localStorage.setItem('hbmts', JSON.stringify(action.payload));
            return {
                ...state,
                metrics: action.payload
            };
        case HUBS_METRICS_LOCALY_LOADED:
            return {
                ...state,
                metrics: JSON.parse(localStorage.getItem('hbmts'))
            };
        case GET_SEARCHED_HUBS:
            return {
                ...state,
                hubs: action.payload,
                hubs_loading: false,
            };
        case GET_ARRANGE_MEETING_HUB:
            return {
                ...state,
                hubs: action.payload
            };
        case GET_MEMORY_UPLOAD_HUB_LIST:
            localStorage.setItem('mmhblt', JSON.stringify(action.payload));
            return {
                ...state,
                hubs: action.payload
            };
        case MEMORY_UPLOAD_HUB_LIST_LOCALLY_LOADED:
            return {
                ...state,
                hubs: JSON.parse(localStorage.getItem('mmhblt'))
            };
        case GET_USER_HUB_LIST:
            localStorage.setItem('uhbl', JSON.stringify(action.payload));
            return {
                ...state,
                hubs: action.payload
            };
        case USER_HUB_LIST_LOCALLY_LOADED:
            return {
                ...state,
                hubs: JSON.parse(localStorage.getItem('uhbl'))
            };
        case GET_HUB_PAGE:
            localStorage.setItem('hbpg', JSON.stringify(action.payload));
            return {
                ...state,
                hub: action.payload
            };
        case HUB_PAGE_LOCALY_LOADED:
            return updateObject(state, {
                hubs: JSON.parse(localStorage.getItem('hbpg'))
            });
        case GET_HUB_ACTIVITIES:
            localStorage.setItem('hbact', JSON.stringify(action.payload));
            return {
                ...state,
                activities: action.payload
            };
        case HUB_ACTIVITIES_LOCALY_LOADED:
            return {
                ...state,
                activities: JSON.parse(localStorage.getItem('hbact'))
            };
        case CREATE_ACTIVITY:
            return {
                ...state,
                activities: [...state.activities, action.payload]
            };
        case REMOVE_ACTIVITY:
            return {
                ...state,
                activities: state.activities.filter(activity => activity.id !== action.payload)
            };
        case GET_HUB_WORKING_DAYS:
            localStorage.setItem('hbwd', JSON.stringify(action.payload));
            return {
                ...state,
                working_days: action.payload
            };
        case WORKING_DAYS_LOCALY_LOADED:
            return {
                ...state,
                working_days: JSON.parse(localStorage.getItem('hbwd'))
            };
        case ACTIVATE_HUB_ACCOUNT:
            return {
                ...state,
                hubs: action.payload
            };
        case FULL_SCREEN_PROMO:
            return {
                ...state,
                promo: action.payload
            };
        case GET_HUB_AMBASSANDORS_PROFILE:
            return {
                ...state,
                ambassandor: action.payload
            };
        case GET_HUB_MENU_PAGES:
            localStorage.setItem('hbmnpgs', JSON.stringify(action.payload));
            return {
                ...state,
                menu_pages: action.payload
            };
        case HUB_MENU_PAGES_LOCALY_LOADED:
            return {
                ...state,
                menu_pages: JSON.parse(localStorage.getItem('hbmnpgs'))
            };
        case GET_USER_HUB_CATALOGS:
            localStorage.setItem('hbmnpgs', JSON.stringify(action.payload));
            return {
                ...state,
                user_catalogs: action.payload
            };
        case CREATE_HUB_PROSPECT:
            return {
                ...state,
                new_prospect_id: [action.payload],
                new_hub_created: true,
                new_created_hub: [],
            };
        case GET_NEW_CREATED_HUB:
            return {
                ...state,
                new_created_hub: [action.payload],
            };
        case HUB_MARKER_CREATED:
            return {
                ...state,
                new_hub_created: false
            };

        case WORKING_DAY_TO_CLOSE:
            const new_day = [action.payload]
            return {
                ...state,
                working_days: state.working_days.map(day => new_day.find(change => change.id === day.id) || day)
            };
        case SET_WORKING_DAY_HOURS:
            const corrected_day = [action.payload]
            return {
                ...state,
                working_days: state.working_days.map(day => corrected_day.find(change => change.id === day.id) || day)
            };
        case GET_HUB_CATALOGS:
            return {
                ...state,
                catalogs: action.payload.reverse()
            };
        case UPLOAD_HUB_CATALOGE:
            return {
                ...state,
                new_catalog: action.payload
            };

        case REMOVE_HUB_CATALOGE:
            return {
                ...state,
                catalogs: state.catalogs.filter(catalog => catalog.id !== action.payload.id),
            };

        case CREATE_NEW_RESERATION_HOUR:
            return {
                ...state,
                reservation_hours: [...state.reservation_hours, action.payload]
            };

        case REMOVE_RESERATION_HOUR:
            return {
                ...state,
                reservation_hours: state.reservation_hours.filter(reservation_hour => reservation_hour.id !== action.payload.id),
            };

        case CREATE_NEW_APPOINTMENT_HOUR:
            return {
                ...state,
                appointment_hours: [...state.appointment_hours, action.payload]
            };

        case REMOVE_APPOINTMENT_HOUR:
            return {
                ...state,
                appointment_hours: state.appointment_hours.filter(appointment_hour => appointment_hour.id !== action.payload.id),
            };
        case GET_HUB_USERS_WITH_ACCESS:
            return {
                ...state,
                users_with_access: action.payload
            };
        case SEARCHING_USER:
            return {
                ...state,
                searching: true
            };
        case FINISH_SEARCHING:
            return {
                ...state,
                searching: false
            };

        case CHANGE_HUB_IS_OVERNIGHT:
            const updated_pharmacy = [action.payload]
            return {
                ...state,
                hub: state.hub.map(hub => updated_pharmacy.find(change => change.id === hub.id) || hub)
            };
        // case SET_MENU_PAGE_DESCRIPTION:
        //     const corrected_page = [action.payload]
        //     return {
        //         ...state,
        //         menu_pages: state.working_days.map(page => corrected_page.find(change => change.id === page.id) || page)
        //     };
        // case SET_MENU_PAGE_PRICE:
        //     const new_page = [action.payload]
        //     return {
        //         ...state,
        //         menu_pages: state.working_days.map(page => new_page.find(change => change.id === page.id) || page)
        //     };

        case GET_HUB_MENU_QR:
            return {
                ...state,
                hub_menu_qr: action.payload
            };
        default:
            return state;
    }
}