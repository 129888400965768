import React from 'react';
import "../../../assets/hub/hub-info/hub-jobs-modal.css"



class ShowUserJobModal extends React.Component {

    closeModal = () => {
        this.props.data.closeModal()
    }

    render() {
        const job = this.props.data.job
        return (
            <div class="">
                <div class="row">
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                    <div class="create-job-box">
                        <div class="row text-right">
                            <div class="col-xl-12 text-right">
                                <div
                                    onClick={() => this.closeModal()}
                                    class="create-job-times-frame">
                                    <img class="create-job-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                </div>
                            </div>
                        </div>


                        <p class="create-job-hub-section-title">Name of the Job</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-text-show">
                                    {job.name}
                                </strong>
                            </h4>
                        </div>

                        <p class="create-job-hub-section-title">Job Description</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-text-show">
                                    {job.description}
                                </strong>
                            </h4>
                        </div>

                        <p class="create-job-hub-section-title">Monthly Salary for the Job</p>
                        <div class="create-job-hub-reason-position">
                            <h4>
                                <strong class="create-job-text-show">
                                    {job.money}
                                </strong>
                            </h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                    </div>
                </div>

            </div>

        )
    }
}


export default (ShowUserJobModal);