import React, { useState, useRef, useEffect, ReactDOM } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

// import SnotelSites from "./lcc_snotel_sites.json";
// import the mapbox styles
// alternatively can use a link tag in the head of public/index.html
// see https://docs.mapbox.com/mapbox-gl-js/api/
import "mapbox-gl/dist/mapbox-gl.css";
// import "./app.css";

// Grab the access token from your Mapbox account
// I typically like to store sensitive things like this
// in a .env file

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;


function PlaceMap(props) {
    const map = useRef(null);
    const mapContainer = useRef();
    const buttonRef = useRef();
    let closer_place = props.data.closer_place
    let [previous_closer_id, setPreviousCloserId] = useState()
    let [previous_closer_map_icon, setPreviousCloserMapIcon] = useState("")
    let [previous_closer_class, setPreviousCloserClass] = useState("")
    let [previous_closer_place, setPreviousCloserPlace] = useState()

    let [now_closer_id, setNowCloserId] = useState(closer_place.id)
    let [now_closer_place, setNowCloserPlace] = useState(closer_place)
    let [now_closer_class, setNowCloserClass] = useState("")
    const [new_hub_searched, setNewSearchedHub] = useState(props.data.new_created_hub)


    if (props.data.my_profile.length > 0) {
        setTimeout(() => {
            if (props.data.add_new_place_icon_modal) {
                const place_div = document.getElementById(`profile-marker-${props.data.my_profile[0].id}`)

                if (place_div) {
                    place_div.style.display = "none"
                }
            } else {
                const place_div = document.getElementById(`profile-marker-${props.data.my_profile[0].id}`)
                if (place_div) {
                    place_div.style.display = "block"
                }
            }
        }, 1000);
    }


    if (props.data.is_category_changed) {
        setTimeout(() => {
            if (props.data.sellected_categories.length === 0) {
                props.data.changeIsCategoryChanged()
                for (const place of props.data.hubs) {
                    const place_div = document.getElementById(`marker-${place.id}`)
                    place_div.style.display = "block"
                }
            } else {
                let map_places = []

                props.data.changeIsCategoryChanged()
                for (const place of props.data.hubs) {
                    const place_div = document.getElementById(`marker-${place.id}`)
                    place_div.style.display = "none"
                }
                for (const category of props.data.sellected_categories) {
                    let filtered_places = []
                    if (category === "food") {
                        const food_category = props.data.hubs.filter(place => place.map_icon === "food")
                        const retaurant_category = props.data.hubs.filter(place => place.map_icon === "restaurant")
                        filtered_places = food_category.concat(retaurant_category);
                    } else if (category === "bar") {
                        const food_category = props.data.hubs.filter(place => place.map_icon === "bar")
                        const retaurant_category = props.data.hubs.filter(place => place.map_icon === "night club")
                        filtered_places = food_category.concat(retaurant_category);
                    } else {
                        filtered_places = props.data.hubs.filter(place => category === place.map_icon)
                    }
                    map_places.push(...filtered_places)
                }

                for (const show_place of map_places) {
                    const show_place_div = document.getElementById(`marker-${show_place.id}`)
                    show_place_div.style.display = "block"
                }

                // for (const category of props.data.sellected_categories) {


                // }
            }
        }, 500);

    }

    if (closer_place.id !== now_closer_id) {
        setNowCloserPlace(closer_place)
        setNowCloserClass(document.getElementById(`marker-${now_closer_id}`).className)
        setNowCloserId(closer_place.id)
        setPreviousCloserId(now_closer_id)
        setPreviousCloserPlace(now_closer_place)
        setPreviousCloserClass(now_closer_class)
    }

    let closer_place_div = document.getElementById(`marker-${now_closer_id}`)
    let previeus_closer_place_div = document.getElementById(`marker-${previous_closer_id}`)
    let closer_place_name_div = document.getElementById(`profile-marker-name-${now_closer_id}`)
    let previeus_closer_place_name_div = document.getElementById(`profile-marker-name-${previous_closer_id}`)
    // if (closer_place_div && previeus_closer_place_div) {
    //     // if (props.data.zoom < 16) {
    //     //     closer_place_name_div.style.display = "block"
    //     // }
    //     if (now_closer_place.map_icon === "restaurant") {
    //         closer_place_div.className = "marker-restaurant-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "coffee shop") {
    //         closer_place_div.className = "marker-cafe-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "bar") {
    //         closer_place_div.className = "marker-bar-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "night club") {
    //         closer_place_div.className = "marker-club-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "food") {
    //         closer_place_div.className = "marker-food-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "shopping mall") {
    //         closer_place_div.className = "marker-mall-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "clothing store") {
    //         closer_place_div.className = "marker-clothestore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "shoe store") {
    //         closer_place_div.className = "marker-shoestore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "electronics store") {
    //         closer_place_div.className = "marker-electronicsstore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "supermarket") {
    //         closer_place_div.className = "marker-supermarket-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "beauty salon") {
    //         closer_place_div.className = "marker-beautysalon-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "hair care") {
    //         closer_place_div.className = "marker-hairsalon-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "cinema") {
    //         closer_place_div.className = "marker-cinema-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "lodging") {
    //         closer_place_div.className = "marker-lodging-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "minifootball") {
    //         closer_place_div.className = "marker-minifootball-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "spa") {
    //         closer_place_div.className = "marker-spa-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "doctor") {
    //         closer_place_div.className = "marker-doctor-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "dentist") {
    //         closer_place_div.className = "marker-dentist-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "lawyer") {
    //         closer_place_div.className = "marker-lawyer-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "sports") {
    //         closer_place_div.className = "marker-sports-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "bowling alley") {
    //         closer_place_div.className = "marker-bowling-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "art gallery") {
    //         closer_place_div.className = "marker-artgallery-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "museum") {
    //         closer_place_div.className = "marker-museum-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "aquarium") {
    //         closer_place_div.className = "marker-aquarium-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "amusement park") {
    //         closer_place_div.className = "marker-amusementpark-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "zoo") {
    //         closer_place_div.className = "marker-zoo-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "campground") {
    //         closer_place_div.className = "marker-campground-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "library") {
    //         closer_place_div.className = "marker-library-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "rv park") {
    //         closer_place_div.className = "marker-rvpark-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "university") {
    //         closer_place_div.className = "marker-university-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "jewelry store") {
    //         closer_place_div.className = "marker-jewelry-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "bakery") {
    //         closer_place_div.className = "marker-bakery-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "liquor store") {
    //         closer_place_div.className = "marker-liquorstore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "pet store") {
    //         closer_place_div.className = "marker-petstore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "convenience store") {
    //         closer_place_div.className = "marker-conviniencestore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "gas station") {
    //         closer_place_div.className = "marker-gasstation-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "home goods store") {
    //         closer_place_div.className = "marker-homegoodsstore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "hardware store") {
    //         closer_place_div.className = "marker-hardwarestore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "pharmacy") {
    //         closer_place_div.className = "marker-pharmacy-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "pharmacy overnight") {
    //         closer_place_div.className = "marker-pharmacy-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "florist") {
    //         closer_place_div.className = "marker-florist-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "furniture store") {
    //         closer_place_div.className = "marker-furniturestore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "bicycle store") {
    //         closer_place_div.className = "marker-bicyclestore-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "book store") {
    //         closer_place_div.className = "marker-bookshop-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "car dealer") {
    //         closer_place_div.className = "marker-cardealer-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "electrician") {
    //         closer_place_div.className = "marker-electrician-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "accounting") {
    //         closer_place_div.className = "marker-accountant-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "bank") {
    //         closer_place_div.className = "marker-bank-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "gym") {
    //         closer_place_div.className = "marker-gym-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "veterinary care") {
    //         closer_place_div.className = "marker-veterany-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "insurance agency") {
    //         closer_place_div.className = "marker-insurance-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "travel agency") {
    //         closer_place_div.className = "marker-travel-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "parking") {
    //         closer_place_div.className = "marker-parking-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "painter") {
    //         closer_place_div.className = "marker-painter-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "car rental") {
    //         closer_place_div.className = "marker-rentcar-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "physiotherapist") {
    //         closer_place_div.className = "marker-physiotherapist-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "real estate agency") {
    //         closer_place_div.className = "marker-realestateagent-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "plumber") {
    //         closer_place_div.className = "marker-plumber-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "car repair") {
    //         closer_place_div.className = "marker-carrepair-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "car wash") {
    //         closer_place_div.className = "marker-carwash-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "laundry") {
    //         closer_place_div.className = "marker-laundry-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "locksmith") {
    //         closer_place_div.className = "marker-locksmith-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "casino") {
    //         closer_place_div.className = "marker-casino-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (now_closer_place.map_icon === "meal takeaway") {
    //         closer_place_div.className = "marker-takeaway-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else {
    //         closer_place_div.className = "marker-place-blue mapboxgl-marker mapboxgl-marker-anchor-center"
    //     }
    // }

    // if (previous_closer_place) {
    //     // if (props.data.zoom < 16) {
    //     //     previeus_closer_place_name_div.style.display = "none"
    //     // }

    //     if (previous_closer_place.map_icon === "restaurant") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-restaurant-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-restaurant-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "coffee shop") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-cafe-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-cafe-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "bar") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-bar-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-bar-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "night club") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-club-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-club-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "food") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-food-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-food-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "shopping mall") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-mall-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-mall-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "clothing store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-clothestore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-clothestore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "shoe store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-shoestore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-shoestore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "electronics store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-electronicsstore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-electronicsstore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "supermarket") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-supermarket-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-supermarket-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "beauty salon") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-beautysalon-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-beautysalon-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "hair care") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-hairsalon-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-hairsalon-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "cinema") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-cinema-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-cinema-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "lodging") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-lodging-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-lodging-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "minifootball") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-minifootball-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-minifootball-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "spa") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-spa-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-spa-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "doctor") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-doctor-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-doctor-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "dentist") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-dentist-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-dentist-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "lawyer") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-lawyer-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-lawyer-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "sports") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-sports-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-sports-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "bowling alley") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-bowling-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-bowling-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "art gallery") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-artgallery-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-artgallery-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "museum") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-museum-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-museum-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "aquarium") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-aquarium-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-aquarium-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "amusement park") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-amusementpark-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-amusementpark-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "zoo") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-zoo-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-zoo-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "campground") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-campground-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-campground-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "library") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-library-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-library-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "rv park") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-rvpark-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-rvpark-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "university") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-university-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-university-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "jewelry store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-jewelry-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-jewelry-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "bakery") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-bakery-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-bakery-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "liquor store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-liquorstore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-liquorstore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "pet store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-petstore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-petstore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "convenience store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-conviniencestore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-conviniencestore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "gas station") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-gasstation-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-gasstation-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "home goods store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-homegoodsstore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-homegoodsstore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "hardware store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-hardwarestore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-hardwarestore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "pharmacy") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-pharmacy-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-pharmacy-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "pharmacy overnight") {
    //         previeus_closer_place_div.className = "marker-pharmacy-overnight-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //     } else if (previous_closer_place.map_icon === "florist") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-florist-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-florist-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "furniture store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-furniturestore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-furniturestore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "bicycle store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-bicyclestore-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-bicyclestore-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "book store") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-bookshop-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-bookshop-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "car dealer") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-cardealer-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-cardealer-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "electrician") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-electrician-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-electrician-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "accounting") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-accountant-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-accountant-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "bank") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-bank-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-bank-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "gym") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-gym-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-gym-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "veterinary care") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-veterany-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-veterany-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "insurance agency") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-insurance-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-insurance-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "travel agency") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-travel-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-travel-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "parking") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-parking-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-parking-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "painter") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-painter-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-painter-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "car rental") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-rentcar-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-rentcar-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "physiotherapist") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-physiotherapist-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-physiotherapist-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "real estate agency") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-realestateagent-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-realestateagent-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "plumber") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-plumber-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-plumber-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "car repair") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-carrepair-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-carrepair-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "car wash") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-carwash-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-carwash-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "laundry") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-laundry-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-laundry-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "locksmith") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-locksmith-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-locksmith-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "casino") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-casino-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-casino-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else if (previous_closer_place.map_icon === "meal takeaway") {
    //         if (previous_closer_place.active_subscription && previous_closer_place.users_with_access.length > 0) {
    //             previeus_closer_place_div.className = "marker-takeaway-active mapboxgl-marker mapboxgl-marker-anchor-center"
    //         } else {
    //             previeus_closer_place_div.className = "marker-takeaway-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //         }
    //     } else {
    //         previeus_closer_place_div.className = "marker-place-red mapboxgl-marker mapboxgl-marker-anchor-center"
    //     }
    // }


    if (props.data.zoom < 15) {
        for (const place of props.data.hubs) {
            const place_div = document.getElementById(`profile-marker-name-${place.id}`)
            if (place_div) {
                place_div.style.display = "none"
            }
        }
    } else {
        for (const place of props.data.hubs) {
            const place_div = document.getElementById(`profile-marker-name-${place.id}`)
            if (place_div) {
                place_div.style.display = "block"
            }
        }
    }


    const new_created_hub = props.new_created_hub


    useEffect(() => {
        // create the map and configure it
        // check out the API reference for more options
        // https://docs.mapbox.com/mapbox-gl-js/api/map/


        let map_style = "mapbox://styles/mapbox/dark-v10"

        if (JSON.parse(localStorage.getItem('map_layout'))) {
            map_style = JSON.parse(localStorage.getItem('map_layout'))
        }
        if (props.data.my_profile[0].map_layout) {
            map_style = props.data.my_profile[0].map_layout
        }


        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            // style: 'https://api.maptiler.com/maps/streets/style.json?key=get_your_own_OpIi9ZULNHzrESv6T2vL',            // style: "mapbox://styles/mapbox/outdoors-v11",
            // style: "mapbox://styles/mapbox/streets-v11",
            // style: "mapbox://styles/mapbox/satellite-v9",
            style: map_style,
            // style: "mapbox://styles/mapbox/light-v10",
            //         lat: 33.009168,
            //         lng: 34.688447
            center: [props.data.mapCenter.lng, props.data.mapCenter.lat],
            zoom: 18,
            pitch: 80,
            maxZoom: 22,
            minZoom: 13
        });

        function forwardGeocoder(query) {
            const matchingFeatures = [];

            for (const feature of hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of restaurant_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of cafe_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of bar_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of club_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of food_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of casino_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of takeaway_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of clothingstore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of electronicsstore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of shoestore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of supermarket_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of jewelry_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of liquorstore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of petstore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of mall_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of conveniencestore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of gasstation_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of homegoodsstore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of hardwarestore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of pharmacy_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of pharmacy_overnight_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of florist_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of furniturestore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of bicyclestores_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of bookstore_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of cardealer_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of bakery_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of beautysalon_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of haircare_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of spa_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of doctor_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of dentist_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of lawyer_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of electrician_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of accounting_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of laundry_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of bank_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of gym_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of veterinary_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of insuranceagency_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of travelagency_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of parking_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of painter_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of carrental_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of physiotherapist_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of realestateagency_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of plumber_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of carrepair_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of carwash_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of locksmith_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of cinema_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of lodging_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of minifootball_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of sports_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of bowlingalley_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of artgallery_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of museum_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of aquarium_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of amusementpark_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of zoo_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of campground_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of library_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of rvpark_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }
            for (const feature of university_hubs.features) {
                if (
                    feature.properties.name
                        .toLowerCase()
                        .includes(query.toLowerCase())
                ) {
                    // Add a tree emoji as a prefix for custom
                    // data results using carmen geojson format:
                    // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                    feature['place_name'] = `${feature.properties.name}`;
                    feature['center'] = feature.geometry.coordinates;
                    feature['place_type'] = feature.properties.category;
                    matchingFeatures.push(feature);
                }
            }

            return matchingFeatures;
        }


        const geocoder = new MapboxGeocoder({
            // Initialize the geocoder
            accessToken: mapboxgl.accessToken, // Set the access token
            mapboxgl: mapboxgl, // Set the mapbox-gl instance
            marker: false, // Do not use the default marker style
            placeholder: 'Search for places', // Placeholder text for the search bar
            position: "left",
            language: 'en-EN',
            localGeocoder: forwardGeocoder,
            proximity: {
                longitude: props.data.mapCenter.lng,
                latitude: props.data.mapCenter.lat
            }
        });

        map.current.addControl(geocoder);

        geocoder.on('result', (event) => {
            const place_id = event.result.id.toString()
            if (place_id.includes(".")) {
                props.data.handleSelectPlace(event.result)
            }
            props.data.handleCenterChanged(event.result.geometry.coordinates[1], event.result.geometry.coordinates[0], 18)

        });

        let myProfile = {}
        let my_profile_array = []

        for (let i = 0; i < props.data.my_profile.length; i++) {
            const profile = {
                "type": "Feature",
                "properties": {
                    "user": props.data.my_profile[i].user,
                    "profile_picture": props.data.my_profile[i].profile_picture,
                    "lat": props.data.myMapCenterLocation.lat,
                    "lng": props.data.myMapCenterLocation.lng,
                    "id": props.data.my_profile[i].id,
                    "username": props.data.my_profile[i].username,
                },
                "geometry": {
                    "coordinates": [
                        props.data.myMapCenterLocation.lng,
                        props.data.myMapCenterLocation.lat,
                    ],
                    "type": "Point"
                },
                "id": props.data.my_profile[i].id
            }
            my_profile_array.push(profile)
        }

        myProfile = {
            "features": my_profile_array,
            "type": "FeatureCollection"
        }


        let hubs = {}
        let restaurant_hubs = {}
        let cafe_hubs = {}
        let lodging_hubs = {}
        let bar_hubs = {}
        let club_hubs = {}
        let food_hubs = {}
        let casino_hubs = {}
        let takeaway_hubs = {}
        let clothingstore_hubs = {}
        let electronicsstore_hubs = {}
        let shoestore_hubs = {}
        let supermarket_hubs = {}
        let jewelry_hubs = {}
        let liquorstore_hubs = {}
        let petstore_hubs = {}
        let mall_hubs = {}
        let conveniencestore_hubs = {}
        let gasstation_hubs = {}
        let homegoodsstore_hubs = {}
        let hardwarestore_hubs = {}
        let pharmacy_hubs = {}
        let pharmacy_overnight_hubs = {}
        let florist_hubs = {}
        let furniturestore_hubs = {}
        let bicyclestores_hubs = {}
        let bookstore_hubs = {}
        let cardealer_hubs = {}
        let bakery_hubs = {}
        let beautysalon_hubs = {}
        let haircare_hubs = {}
        let spa_hubs = {}
        let doctor_hubs = {}
        let dentist_hubs = {}
        let lawyer_hubs = {}
        let electrician_hubs = {}
        let accounting_hubs = {}
        let bank_hubs = {}
        let gym_hubs = {}
        let veterinary_hubs = {}
        let insuranceagency_hubs = {}
        let travelagency_hubs = {}
        let parking_hubs = {}
        let painter_hubs = {}
        let carrental_hubs = {}
        let physiotherapist_hubs = {}
        let realestateagency_hubs = {}
        let plumber_hubs = {}
        let carrepair_hubs = {}
        let carwash_hubs = {}
        let laundry_hubs = {}
        let locksmith_hubs = {}
        let cinema_hubs = {}
        let minifootball_hubs = {}
        let sports_hubs = {}
        let bowlingalley_hubs = {}
        let artgallery_hubs = {}
        let museum_hubs = {}
        let aquarium_hubs = {}
        let amusementpark_hubs = {}
        let zoo_hubs = {}
        let campground_hubs = {}
        let library_hubs = {}
        let rvpark_hubs = {}
        let university_hubs = {}


        let hubs_array = []
        let restaurant_array = []
        let cafe_array = []
        let lodging_array = []
        let bar_array = []
        let club_array = []
        let food_array = []
        let casino_array = []
        let takeaway_array = []
        let clothingstore_array = []
        let electronicsstore_array = []
        let shoestore_array = []
        let supermarket_array = []
        let jewelry_array = []
        let liquorstore_array = []
        let petstore_array = []
        let mall_array = []
        let conveniencestore_array = []
        let gasstation_array = []
        let homegoodsstore_array = []
        let hardwarestore_array = []
        let pharmacy_array = []
        let pharmacy_overnight_array = []
        let florist_array = []
        let furniturestore_array = []
        let bicyclestores_array = []
        let bookstore_array = []
        let cardealer_array = []
        let bakery_array = []
        let beautysalon_array = []
        let haircare_array = []
        let spa_array = []
        let doctor_array = []
        let dentist_array = []
        let lawyer_array = []
        let electrician_array = []
        let accounting_array = []
        let bank_array = []
        let gym_array = []
        let veterinary_array = []
        let insuranceagency_array = []
        let travelagency_array = []
        let parking_array = []
        let painter_array = []
        let carrental_array = []
        let physiotherapist_array = []
        let realestateagency_array = []
        let plumber_array = []
        let carrepair_array = []
        let carwash_array = []
        let laundry_array = []
        let locksmith_array = []
        let cinema_array = []
        let minifootball_array = []
        let sports_array = []
        let bowlingalley_array = []
        let artgallery_array = []
        let museum_array = []
        let aquarium_array = []
        let amusementpark_array = []
        let zoo_array = []
        let campground_array = []
        let library_array = []
        let rvpark_array = []
        let university_array = []

        for (let i = 0; i < props.data.hubs.length; i++) {
            const place = {
                "type": "Feature",
                "properties": {
                    "name": props.data.hubs[i].name,
                    "active_subscription": props.data.hubs[i].active_subscription,
                    "address": props.data.hubs[i].address,
                    "another_app_appointments": props.data.hubs[i].another_app_appointments,
                    "another_app_reservations": props.data.hubs[i].another_app_reservations,
                    "appointment_acceptance": props.data.hubs[i].appointment_acceptance,
                    "appointment_app_url": props.data.hubs[i].appointment_app_url,
                    "category": props.data.hubs[i].category,
                    "city": props.data.hubs[i].city,
                    "country": props.data.hubs[i].country,
                    "cover_photo": props.data.hubs[i].cover_photo,
                    "cover_photo_props": props.data.hubs[i].cover_photo_props,
                    "distance": props.data.hubs[i].distance,
                    "facebook_url": props.data.hubs[i].facebook_url,
                    "has_facebook": props.data.hubs[i].has_facebook,
                    "has_instagram": props.data.hubs[i].has_instagram,
                    "has_menu": props.data.hubs[i].has_menu,
                    "has_online_shop": props.data.hubs[i].has_online_shop,
                    "has_sales": props.data.hubs[i].has_sales,
                    "has_telephone": props.data.hubs[i].has_telephone,
                    "has_website": props.data.hubs[i].has_website,
                    "hubst3r_appointments": props.data.hubs[i].hubst3r_appointments,
                    "hubst3r_reservations": props.data.hubs[i].hubst3r_reservations,
                    "id": props.data.hubs[i].id,
                    "instagram_url": props.data.hubs[i].instagram_url,
                    "is_claimed": props.data.hubs[i].is_claimed,
                    "is_open_label": props.data.hubs[i].is_open_label,
                    "is_open_now": props.data.hubs[i].is_open_now,
                    "is_overnight": props.data.hubs[i].is_overnight,
                    "lat": props.data.hubs[i].lat,
                    "lng": props.data.hubs[i].lng,
                    "logo": props.data.hubs[i].logo,
                    "map_icon": props.data.hubs[i].map_icon,
                    "place_type": props.data.hubs[i].place_type,
                    "reservation_acceptance": props.data.hubs[i].reservation_acceptance,
                    "reservation_app_url": props.data.hubs[i].reservation_app_url,
                    "sales_text": props.data.hubs[i].sales_text,
                    "shop_url": props.data.hubs[i].shop_url,
                    "telephone": props.data.hubs[i].telephone,
                    "telephone_appointments": props.data.hubs[i].telephone_appointments,
                    "telephone_reservations": props.data.hubs[i].telephone_reservations,
                    "traffic": props.data.hubs[i].traffic,
                    "website": props.data.hubs[i].website,
                    "wifi_code": props.data.hubs[i].wifi_code,
                    "users_with_access": props.data.hubs[i].users_with_access,
                },
                "geometry": {
                    "coordinates": [
                        props.data.hubs[i].lng,
                        props.data.hubs[i].lat
                    ],
                    "type": "Point"
                },
                "id": props.data.hubs[i].id
            }


            if (props.data.hubs[i].map_icon === "restaurant") {
                restaurant_array.push(place)
            } else if (props.data.hubs[i].map_icon === "coffee shop") {
                cafe_array.push(place)
            } else if (props.data.hubs[i].map_icon === "lodging") {
                lodging_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bar") {
                bar_array.push(place)
            } else if (props.data.hubs[i].map_icon === "night club") {
                club_array.push(place)
            } else if (props.data.hubs[i].map_icon === "food") {
                food_array.push(place)
            } else if (props.data.hubs[i].map_icon === 'casino') {
                casino_array.push(place)
            } else if (props.data.hubs[i].map_icon === "meal takeaway") {
                takeaway_array.push(place)
            } else if (props.data.hubs[i].map_icon === "clothing store") {
                clothingstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "electronics store") {
                electronicsstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "shoe store") {
                shoestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "supermarket") {
                supermarket_array.push(place)
            } else if (props.data.hubs[i].map_icon === "jewelry store") {
                jewelry_array.push(place)
            } else if (props.data.hubs[i].map_icon === "liquor store") {
                liquorstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "pet store") {
                petstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "shopping mall") {
                mall_array.push(place)
            } else if (props.data.hubs[i].map_icon === "convenience store") {
                conveniencestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "gas station") {
                gasstation_array.push(place)
            } else if (props.data.hubs[i].map_icon === "home goods store") {
                homegoodsstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "hardware store") {
                hardwarestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "pharmacy") {
                pharmacy_array.push(place)
            } else if (props.data.hubs[i].map_icon === "pharmacy overnight") {
                pharmacy_overnight_array.push(place)
            } else if (props.data.hubs[i].map_icon === "florist") {
                florist_array.push(place)
            } else if (props.data.hubs[i].map_icon === "furniture store") {
                furniturestore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bicycle store") {
                bicyclestores_array.push(place)
            } else if (props.data.hubs[i].map_icon === "book store") {
                bookstore_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car dealer") {
                cardealer_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bakery") {
                bakery_array.push(place)
            } else if (props.data.hubs[i].map_icon === "beauty salon") {
                beautysalon_array.push(place)
            } else if (props.data.hubs[i].map_icon === "hair care") {
                haircare_array.push(place)
            } else if (props.data.hubs[i].map_icon === "spa") {
                spa_array.push(place)
            } else if (props.data.hubs[i].map_icon === "doctor") {
                doctor_array.push(place)
            } else if (props.data.hubs[i].map_icon === "dentist") {
                dentist_array.push(place)
            } else if (props.data.hubs[i].map_icon === "lawyer") {
                lawyer_array.push(place)
            } else if (props.data.hubs[i].map_icon === "electrician") {
                electrician_array.push(place)
            } else if (props.data.hubs[i].map_icon === "accounting") {
                accounting_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bank") {
                bank_array.push(place)
            } else if (props.data.hubs[i].map_icon === "gym") {
                gym_array.push(place)
            } else if (props.data.hubs[i].map_icon === "veterinary care") {
                veterinary_array.push(place)
            } else if (props.data.hubs[i].map_icon === "insurance agency") {
                insuranceagency_array.push(place)
            } else if (props.data.hubs[i].map_icon === "travel agency") {
                travelagency_array.push(place)
            } else if (props.data.hubs[i].map_icon === "parking") {
                parking_array.push(place)
            } else if (props.data.hubs[i].map_icon === "painter") {
                painter_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car rental") {
                carrental_array.push(place)
            } else if (props.data.hubs[i].map_icon === "physiotherapist") {
                physiotherapist_array.push(place)
            } else if (props.data.hubs[i].map_icon === "real estate agency") {
                realestateagency_array.push(place)
            } else if (props.data.hubs[i].map_icon === "plumber") {
                plumber_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car repair") {
                carrepair_array.push(place)
            } else if (props.data.hubs[i].map_icon === "car wash") {
                carwash_array.push(place)
            } else if (props.data.hubs[i].map_icon === "laundry") {
                laundry_array.push(place)
            } else if (props.data.hubs[i].map_icon === "locksmith") {
                locksmith_array.push(place)
            } else if (props.data.hubs[i].map_icon === "cinema") {
                cinema_array.push(place)
            } else if (props.data.hubs[i].map_icon === "minifootball") {
                minifootball_array.push(place)
            } else if (props.data.hubs[i].map_icon === "sports") {
                sports_array.push(place)
            } else if (props.data.hubs[i].map_icon === "bowling alley") {
                bowlingalley_array.push(place)
            } else if (props.data.hubs[i].map_icon === "art gallery") {
                artgallery_array.push(place)
            } else if (props.data.hubs[i].map_icon === "museum") {
                museum_array.push(place)
            } else if (props.data.hubs[i].map_icon === "aquarium") {
                aquarium_array.push(place)
            } else if (props.data.hubs[i].map_icon === "amusement park") {
                amusementpark_array.push(place)
            } else if (props.data.hubs[i].map_icon === "zoo") {
                zoo_array.push(place)
            } else if (props.data.hubs[i].map_icon === "campground") {
                campground_array.push(place)
            } else if (props.data.hubs[i].map_icon === "library") {
                library_array.push(place)
            } else if (props.data.hubs[i].map_icon === "rv park") {
                rvpark_array.push(place)
            } else if (props.data.hubs[i].map_icon === "university") {
                university_array.push(place)
            } else {
                hubs_array.push(place)
            }
        }


        hubs = {
            "features": hubs_array,
            "type": "FeatureCollection"
        }

        restaurant_hubs = {
            "features": restaurant_array,
            "type": "FeatureCollection"
        }

        cafe_hubs = {
            "features": cafe_array,
            "type": "FeatureCollection"
        }
        lodging_hubs = {
            "features": lodging_array,
            "type": "FeatureCollection"
        }
        bar_hubs = {
            "features": bar_array,
            "type": "FeatureCollection"
        }
        club_hubs = {
            "features": club_array,
            "type": "FeatureCollection"
        }
        food_hubs = {
            "features": food_array,
            "type": "FeatureCollection"
        }
        casino_hubs = {
            "features": casino_array,
            "type": "FeatureCollection"
        }
        takeaway_hubs = {
            "features": takeaway_array,
            "type": "FeatureCollection"
        }
        clothingstore_hubs = {
            "features": clothingstore_array,
            "type": "FeatureCollection"
        }
        electronicsstore_hubs = {
            "features": electronicsstore_array,
            "type": "FeatureCollection"
        }
        shoestore_hubs = {
            "features": shoestore_array,
            "type": "FeatureCollection"
        }
        supermarket_hubs = {
            "features": supermarket_array,
            "type": "FeatureCollection"
        }
        jewelry_hubs = {
            "features": jewelry_array,
            "type": "FeatureCollection"
        }
        liquorstore_hubs = {
            "features": liquorstore_array,
            "type": "FeatureCollection"
        }
        petstore_hubs = {
            "features": petstore_array,
            "type": "FeatureCollection"
        }
        mall_hubs = {
            "features": mall_array,
            "type": "FeatureCollection"
        }
        conveniencestore_hubs = {
            "features": conveniencestore_array,
            "type": "FeatureCollection"
        }
        gasstation_hubs = {
            "features": gasstation_array,
            "type": "FeatureCollection"
        }
        homegoodsstore_hubs = {
            "features": homegoodsstore_array,
            "type": "FeatureCollection"
        }
        hardwarestore_hubs = {
            "features": hardwarestore_array,
            "type": "FeatureCollection"
        }
        pharmacy_hubs = {
            "features": pharmacy_array,
            "type": "FeatureCollection"
        }
        pharmacy_overnight_hubs = {
            "features": pharmacy_overnight_array,
            "type": "FeatureCollection"
        }
        florist_hubs = {
            "features": florist_array,
            "type": "FeatureCollection"
        }
        furniturestore_hubs = {
            "features": furniturestore_array,
            "type": "FeatureCollection"
        }
        bicyclestores_hubs = {
            "features": bicyclestores_array,
            "type": "FeatureCollection"
        }
        bookstore_hubs = {
            "features": bookstore_array,
            "type": "FeatureCollection"
        }
        cardealer_hubs = {
            "features": cardealer_array,
            "type": "FeatureCollection"
        }
        bakery_hubs = {
            "features": bakery_array,
            "type": "FeatureCollection"
        }
        beautysalon_hubs = {
            "features": beautysalon_array,
            "type": "FeatureCollection"
        }
        haircare_hubs = {
            "features": haircare_array,
            "type": "FeatureCollection"
        }
        spa_hubs = {
            "features": spa_array,
            "type": "FeatureCollection"
        }
        doctor_hubs = {
            "features": doctor_array,
            "type": "FeatureCollection"
        }
        dentist_hubs = {
            "features": dentist_array,
            "type": "FeatureCollection"
        }
        lawyer_hubs = {
            "features": lawyer_array,
            "type": "FeatureCollection"
        }
        electrician_hubs = {
            "features": electrician_array,
            "type": "FeatureCollection"
        }
        accounting_hubs = {
            "features": accounting_array,
            "type": "FeatureCollection"
        }
        bank_hubs = {
            "features": bank_array,
            "type": "FeatureCollection"
        }
        gym_hubs = {
            "features": gym_array,
            "type": "FeatureCollection"
        }
        veterinary_hubs = {
            "features": veterinary_array,
            "type": "FeatureCollection"
        }
        insuranceagency_hubs = {
            "features": insuranceagency_array,
            "type": "FeatureCollection"
        }
        travelagency_hubs = {
            "features": travelagency_array,
            "type": "FeatureCollection"
        }
        parking_hubs = {
            "features": parking_array,
            "type": "FeatureCollection"
        }
        painter_hubs = {
            "features": painter_array,
            "type": "FeatureCollection"
        }
        carrental_hubs = {
            "features": carrental_array,
            "type": "FeatureCollection"
        }
        physiotherapist_hubs = {
            "features": physiotherapist_array,
            "type": "FeatureCollection"
        }
        realestateagency_hubs = {
            "features": realestateagency_array,
            "type": "FeatureCollection"
        }
        plumber_hubs = {
            "features": plumber_array,
            "type": "FeatureCollection"
        }
        carrepair_hubs = {
            "features": carrepair_array,
            "type": "FeatureCollection"
        }
        carwash_hubs = {
            "features": carwash_array,
            "type": "FeatureCollection"
        }
        laundry_hubs = {
            "features": laundry_array,
            "type": "FeatureCollection"
        }
        locksmith_hubs = {
            "features": locksmith_array,
            "type": "FeatureCollection"
        }
        cinema_hubs = {
            "features": cinema_array,
            "type": "FeatureCollection"
        }
        minifootball_hubs = {
            "features": minifootball_array,
            "type": "FeatureCollection"
        }
        sports_hubs = {
            "features": sports_array,
            "type": "FeatureCollection"
        }
        bowlingalley_hubs = {
            "features": bowlingalley_array,
            "type": "FeatureCollection"
        }
        artgallery_hubs = {
            "features": artgallery_array,
            "type": "FeatureCollection"
        }
        museum_hubs = {
            "features": museum_array,
            "type": "FeatureCollection"
        }
        aquarium_hubs = {
            "features": aquarium_array,
            "type": "FeatureCollection"
        }
        amusementpark_hubs = {
            "features": amusementpark_array,
            "type": "FeatureCollection"
        }
        zoo_hubs = {
            "features": zoo_array,
            "type": "FeatureCollection"
        }
        campground_hubs = {
            "features": campground_array,
            "type": "FeatureCollection"
        }
        library_hubs = {
            "features": library_array,
            "type": "FeatureCollection"
        }
        rvpark_hubs = {
            "features": rvpark_array,
            "type": "FeatureCollection"
        }
        university_hubs = {
            "features": university_array,
            "type": "FeatureCollection"
        }

        function addMarkers() {
            for (const marker of myProfile.features) {
                /* Create a div element for the marker. */
                const el = document.createElement('div');
                /* Assign a unique `id` to the marker. */
                el.id = `profile-marker-${marker.properties.id}`;
                /* Assign the `marker` class to each marker for styling. */
                el.className = "marker-map-mi-marker-box";
                el.innerHTML += `<img class="marker-map-mi-marker-icon" src=${require('../../../assets/map/svg/my-location.svg')} /> <img class="marker-map-mi-marker-imade" src="${marker.properties.profile_picture}" />`;

                // var elem = document.createElement("img");
                // elem.setAttribute("src", `${marker.properties.user_profile_picture}`);
                // elem.setAttribute("height", "768");
                // elem.setAttribute("width", "1024");
                // elem.setAttribute("alt", "Flower");
                // el.appendChild("elem");
                /**
                 * Create a marker using the div element
                 * defined above and add it to the map.current.
                 **/


                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    // createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`profile-marker-${marker.properties.id}`);
                    // listing.classList.add('active');
                });

                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }


            let all_markers = []
            /* For each feature in the GeoJSON object above: */
            for (const marker of hubs.features) {
                /* Create a div element for the marker. */
                const el = document.createElement('div');
                /* Assign a unique `id` to the marker. */
                el.id = `marker-${marker.properties.id}`;
                /* Assign the `marker` class to each marker for styling. */

                el.className = "marker-place-red";
                /**
                 * Create a marker using the div element
                 * defined above and add it to the map.current.
                 **/

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });

                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of restaurant_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-restaurant-active";
                } else {
                    el.className = "marker-restaurant-red";
                }

                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });

                const new_marker = new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of cafe_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-cafe-active";
                } else {
                    el.className = "marker-cafe-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });

                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bar_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-bar-active";
                } else {
                    el.className = "marker-bar-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of club_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-club-active";
                } else {
                    el.className = "marker-club-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                // el_name.className = "find-place-marker-map-name";
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of food_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-food-active";
                } else {
                    el.className = "marker-food-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of casino_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-casino-active";
                } else {
                    el.className = "marker-casino-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of takeaway_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-takeaway-active";
                } else {
                    el.className = "marker-takeaway-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of clothingstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-clothestore-active";
                } else {
                    el.className = "marker-clothestore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of electronicsstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-electronicsstore-active";
                } else {
                    el.className = "marker-electronicsstore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of shoestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-shoestore-active";
                } else {
                    el.className = "marker-shoestore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of supermarket_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-supermarket-active";
                } else {
                    el.className = "marker-supermarket-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of jewelry_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-jewelry-active";
                } else {
                    el.className = "marker-jewelry-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of liquorstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-liquorstore-active";
                } else {
                    el.className = "marker-liquorstore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of petstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-petstore-active";
                } else {
                    el.className = "marker-petstore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of mall_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-mall-active";
                } else {
                    el.className = "marker-mall-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of conveniencestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-conviniencestore-active";
                } else {
                    el.className = "marker-conviniencestore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of gasstation_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-gasstation-active";
                } else {
                    el.className = "marker-gasstation-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of homegoodsstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-homegoodsstore-active";
                } else {
                    el.className = "marker-homegoodsstore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of hardwarestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-hardwarestore-active";
                } else {
                    el.className = "marker-hardwarestore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of pharmacy_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-pharmacy-active";
                } else {
                    el.className = "marker-pharmacy-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of pharmacy_overnight_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                el.className = "marker-pharmacy-overnight-red";
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of florist_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-florist-active";
                } else {
                    el.className = "marker-florist-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of furniturestore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-furniturestore-active";
                } else {
                    el.className = "marker-furniturestore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bicyclestores_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-bicyclestore-active";
                } else {
                    el.className = "marker-bicyclestore-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bookstore_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-bookshop-active";
                } else {
                    el.className = "marker-bookshop-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of cardealer_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-cardealer-active";
                } else {
                    el.className = "marker-cardealer-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bakery_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-bakery-active";
                } else {
                    el.className = "marker-bakery-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of beautysalon_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-beautysalon-active";
                } else {
                    el.className = "marker-beautysalon-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of haircare_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-hairsalon-active";
                } else {
                    el.className = "marker-hairsalon-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of spa_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-spa-active";
                } else {
                    el.className = "marker-spa-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of doctor_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-doctor-active";
                } else {
                    el.className = "marker-doctor-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of dentist_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-dentist-active";
                } else {
                    el.className = "marker-dentist-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of lawyer_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-lawer-active";
                } else {
                    el.className = "marker-lawer-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of electrician_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-electrician-active";
                } else {
                    el.className = "marker-electrician-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of accounting_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-accountant-active";
                } else {
                    el.className = "marker-accountant-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of laundry_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-laundry-active";
                } else {
                    el.className = "marker-laundry-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bank_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-bank-active";
                } else {
                    el.className = "marker-bank-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of gym_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-gym-active";
                } else {
                    el.className = "marker-gym-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of veterinary_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-veterany-active";
                } else {
                    el.className = "marker-veterany-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of insuranceagency_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-insurance-active";
                } else {
                    el.className = "marker-insurance-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of travelagency_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-travel-active";
                } else {
                    el.className = "marker-travel-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of parking_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-parking-active";
                } else {
                    el.className = "marker-parking-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of painter_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-painter-active";
                } else {
                    el.className = "marker-painter-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of carrental_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-rentcar-active";
                } else {
                    el.className = "marker-rentcar-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of physiotherapist_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-physiotherapist-active";
                } else {
                    el.className = "marker-physiotherapist-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of realestateagency_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-realestateagent-active";
                } else {
                    el.className = "marker-realestateagent-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of plumber_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-plumber-active";
                } else {
                    el.className = "marker-plumber-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of carrepair_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-carrepair-active";
                } else {
                    el.className = "marker-carrepair-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of carwash_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-carwash-active";
                } else {
                    el.className = "marker-carwash-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of locksmith_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-locksmith-active";
                } else {
                    el.className = "marker-locksmith-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of cinema_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-cinema-active";
                } else {
                    el.className = "marker-cinema-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of lodging_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-lodging-active";
                } else {
                    el.className = "marker-lodging-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of minifootball_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-minifootball-active";
                } else {
                    el.className = "marker-minifootball-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of sports_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-sports-active";
                } else {
                    el.className = "marker-sports-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of bowlingalley_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-bowling-active";
                } else {
                    el.className = "marker-bowling-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of artgallery_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-artgallery-active";
                } else {
                    el.className = "marker-artgallery-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of museum_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-museum-active";
                } else {
                    el.className = "marker-museum-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of aquarium_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-aquarium-active";
                } else {
                    el.className = "marker-aquarium-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of amusementpark_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-amusementpark-active";
                } else {
                    el.className = "marker-amusementpark-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of zoo_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-zoo-active";
                } else {
                    el.className = "marker-zoo-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of campground_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-campground-active";
                } else {
                    el.className = "marker-campground-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of library_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-library-active";
                } else {
                    el.className = "marker-library-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of rvpark_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-rvpark-active";
                } else {
                    el.className = "marker-rvpark-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
            for (const marker of university_hubs.features) {
                const el = document.createElement('div');
                el.id = `marker-${marker.properties.id}`;
                if (marker.properties.active_subscription && marker.properties.users_with_access.length > 0) {
                    el.className = "marker-university-active";
                } else {
                    el.className = "marker-university-red";
                }
                el.addEventListener('click', (e) => {
                    /* Fly to the point */
                    flyToStore(marker);
                    /* Close all other popups and display popup for clicked store */
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                    const listing = document.getElementById(`listing-${marker.properties.id}`);
                    // listing.classList.add('active');
                });
                new mapboxgl.Marker(el, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);

                const el_name = document.createElement('div');
                el_name.id = `profile-marker-name-${marker.properties.id}`;
                if (map_style === "mapbox://styles/mapbox/dark-v10") {
                    el_name.className = "find-place-marker-map-name";
                } else {
                    el_name.className = "map-search-marker-map-name-blabk";
                }
                el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                el_name.addEventListener('click', (e) => {
                    flyToStore(marker);
                    const activeItem = document.getElementsByClassName('active');
                    e.stopPropagation();
                    if (activeItem[0]) {
                        activeItem[0].classList.remove('active');
                    }
                });
                new mapboxgl.Marker(el_name, { offset: [0, 0] })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(map.current);
            }
        }

        function flyToStore(currentFeature) {
            map.current.flyTo({
                center: currentFeature.geometry.coordinates,
                zoom: 18
            });

            props.data.handleCenterChanged(currentFeature.properties.lat, currentFeature.properties.lng[1], 18)

            props.data.selectedPhotoLocation(currentFeature.properties.lat, currentFeature.properties.lng[1], currentFeature.properties.name, currentFeature.id)

        }

        function clickSeeMore() {
            console.log("hoiiiiii")
            // props.data.handleCenterChanged(currentFeature.properties.lat, currentFeature.properties.lng[1], 18)
        }

        function createPopUp(currentFeature) {
            // props.data.openPlaceModal(currentFeature.id)

            // const popUps = document.getElementsByClassName('mapboxgl-popup');
            // /** Check if there is already a popup on the map and if so, remove it */
            // if (popUps[0]) popUps[0].remove();

            // const popup = new mapboxgl.Popup({ closeOnClick: false })
            //     .setLngLat(currentFeature.geometry.coordinates)
            //     .setHTML(`<h12>${currentFeature.properties.id}</h12><h3>${currentFeature.properties.name}</h3><h4>${currentFeature.properties.address}</h4><button class="map-btn-popup-see-more" ref=${buttonRef.current}>See more...</button>`)
            //     .addTo(map.current);

            // const btn = document.getElementsByClassName("map-btn-popup-see-more")[0];
            // btn.addEventListener("click", (e) => {
            //     console.log(e.srcElement.offsetParent.firstElementChild.outerText);
            // });
        }


        map.current.on("load", () => {
            // add mapbox terrain dem source for 3d terrain rendering
            map.current.addSource("mapbox-dem", {
                type: "raster-dem",
                url: "mapbox://mapbox.mapbox-terrain-dem-v1",
                tileSize: 512,
                maxZoom: 16
            });
            map.current.setTerrain({ source: "mapbox-dem" });

            // avalanche paths source
            // example of how to add a custom tileset hosted on Mapbox
            // you can grab the url from the details page for any tileset
            // you have created in Mapbox studio
            // see https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#vector
            // map.current.addSource("avalanche-paths", {
            //     type: "vector",
            //     url: "mapbox://lcdesigns.arckuvnm"
            // });


            // snotel sites source
            // example of using a geojson source
            // data is hosted locally as part of the application
            // see https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson


            map.current.addLayer({
                id: 'all_hubs',
                // type: "geojson",
                type: 'fill',
                /* Add a GeoJSON source containing place coordinates and information. */
                source: {
                    type: 'geojson',
                    data: gasstation_hubs
                }
            });


            map.current.addSource("hubs", {
                type: "geojson",
                data: hubs
            });

            map.current.addSource("restaurant_hubs", {
                type: "geojson",
                data: restaurant_hubs
            });


            map.current.addLayer({
                id: "sky",
                type: "sky",
                paint: {
                    "sky-type": "atmosphere",
                    "sky-atmosphere-sun": [0.0, 90.0],
                    "sky-atmosphere-sun-intensity": 15
                }
            });

            // Insert the layer beneath any symbol layer.
            const layers = map.current.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === 'symbol' && layer.layout['text-field']
            ).id;

            // The 'building' layer in the Mapbox Streets
            // vector tileset contains building height data
            // from OpenStreetmap.current.
            map.current.addLayer(
                {
                    'id': 'add-3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                        'fill-extrusion-color': '#aaa',

                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        'fill-extrusion-height': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 0.6
                    }
                },
                labelLayerId
            )



            addMarkers();

        });

        // map.current.on('click', (e) => {

        //     // lngLat: Ja {lng: 32.999997484410585, lat: 34.99982314219916}
        //     const description = (
        //         `
        //     <div class="map-add-a-place-pop-up-box">
        //     <div class="map-add-a-place-pop-up-title">Is there a place that you dont see on the map?</div>
        //     <div id="map-pop-btn" class="map-add-a-place-pop-up">
        //     Add a place here 
        //     </div>
        //     <div id="map-pop1" class="map-add-a-place-pop-up-x">x</div>
        //     </div>
        //     `
        //     )

        //     let popup = new mapboxgl.Popup({
        //         closeButton: true,
        //         closeOnClick: true,
        //         closeOnMove: true,
        //         maxWidth: "auto",
        //     })

        //         .setLngLat(e.lngLat)
        //         .setHTML(description)
        //         .addTo(map.current);

        //     const pop_div = document.getElementById(`map-pop1`)
        //     pop_div.addEventListener('click', (e) => {
        //         popup.remove();
        //         props.data.closeNewPlaceIconModal()
        //     })

        //     const pop_btn_div = document.getElementById(`map-pop-btn`)
        //     pop_btn_div.addEventListener('click', (e) => {
        //         const lat = popup._lngLat.lat
        //         const lng = popup._lngLat.lng
        //         props.data.openAddPlaceModal(lat, lng)
        //     })
        // });


        map.current.on('dragend', () => {
            const map_center = map.current.getCenter()
            const lat = map_center.lat
            const lng = map_center.lng
            const zoom = map.current.getZoom()
            props.data.handleCenterChanged(lat, lng, zoom)
            // props.getMapPlaces("show_all", map_center.lat, map_center.lng, 19)
        });

        // only want to work with the map after it has fully loaded
        // if you try to add sources and layers before the map has loaded
        // things will not work properly


        // cleanup function to remove map on unmount
        return () => map.current.remove();
    }, []);

    useEffect(() => {

        if (props.data.new_hub_created) {
            if (props.data.new_prospect_id.length > 0) {
                props.getNewCreatedHub(props.data.new_prospect_id[0].id)

                if (props.data.new_created_hub.length > 0) {

                    const single_plce_pbject = props.data.new_created_hub[0][0]

                    props.createdHubMarker()
                    if (!document.getElementById(`marker-${single_plce_pbject.id}`)) {
                        let new_places_array = []
                        let new_places = {}
                        const place = {
                            "type": "Feature",
                            "properties": {
                                "name": single_plce_pbject.name,
                                "active_subscription": single_plce_pbject.active_subscription,
                                "address": single_plce_pbject.address,
                                "another_app_appointments": single_plce_pbject.another_app_appointments,
                                "another_app_reservations": single_plce_pbject.another_app_reservations,
                                "appointment_acceptance": single_plce_pbject.appointment_acceptance,
                                "appointment_app_url": single_plce_pbject.appointment_app_url,
                                "category": single_plce_pbject.category,
                                "city": single_plce_pbject.city,
                                "country": single_plce_pbject.country,
                                "cover_photo": single_plce_pbject.cover_photo,
                                "cover_photo_props": single_plce_pbject.cover_photo_props,
                                "distance": single_plce_pbject.distance,
                                "facebook_url": single_plce_pbject.facebook_url,
                                "has_facebook": single_plce_pbject.has_facebook,
                                "has_instagram": single_plce_pbject.has_instagram,
                                "has_menu": single_plce_pbject.has_menu,
                                "has_online_shop": single_plce_pbject.has_online_shop,
                                "has_sales": single_plce_pbject.has_sales,
                                "has_telephone": single_plce_pbject.has_telephone,
                                "has_website": single_plce_pbject.has_website,
                                "hubst3r_appointments": single_plce_pbject.hubst3r_appointments,
                                "hubst3r_reservations": single_plce_pbject.hubst3r_reservations,
                                "id": single_plce_pbject.id,
                                "instagram_url": single_plce_pbject.instagram_url,
                                "is_claimed": single_plce_pbject.is_claimed,
                                "is_open_label": single_plce_pbject.is_open_label,
                                "is_open_now": single_plce_pbject.is_open_now,
                                "is_overnight": single_plce_pbject.is_overnight,
                                "lat": single_plce_pbject.lat,
                                "lng": single_plce_pbject.lng,
                                "logo": single_plce_pbject.logo,
                                "map_icon": single_plce_pbject.map_icon,
                                "place_type": single_plce_pbject.place_type,
                                "reservation_acceptance": single_plce_pbject.reservation_acceptance,
                                "reservation_app_url": single_plce_pbject.reservation_app_url,
                                "sales_text": single_plce_pbject.sales_text,
                                "shop_url": single_plce_pbject.shop_url,
                                "telephone": single_plce_pbject.telephone,
                                "telephone_appointments": single_plce_pbject.telephone_appointments,
                                "telephone_reservations": single_plce_pbject.telephone_reservations,
                                "traffic": single_plce_pbject.traffic,
                                "website": single_plce_pbject.website,
                                "wifi_code": single_plce_pbject.wifi_code,
                                "users_with_access": single_plce_pbject.users_with_access,
                            },
                            "geometry": {
                                "coordinates": [
                                    single_plce_pbject.lng,
                                    single_plce_pbject.lat
                                ],
                                "type": "Point"
                            },
                            "id": single_plce_pbject.id
                        }

                        new_places_array.push(place)
                        new_places = {
                            "features": new_places_array,
                            "type": "FeatureCollection"
                        }

                        for (const marker of new_places.features) {

                            const el = document.createElement('div');
                            el.id = `marker-${marker.properties.id}`;

                            if (marker.properties.map_icon === "restaurant") {
                                el.className = "marker-restaurant-red"
                            } else if (marker.properties.map_icon === "coffee shop") {
                                el.className = "marker-cafe-red"
                            } else if (marker.properties.map_icon === "bar") {
                                el.className = "marker-bar-red"
                            } else if (marker.properties.map_icon === "night club") {
                                el.className = "marker-club-red"
                            } else if (marker.properties.map_icon === "food") {
                                el.className = "marker-food-red"
                            } else if (marker.properties.map_icon === "shopping mall") {
                                el.className = "marker-mall-red"
                            } else if (marker.properties.map_icon === "clothing store") {
                                el.className = "marker-clothestore-red"
                            } else if (marker.properties.map_icon === "shoe store") {
                                el.className = "marker-shoestore-red"
                            } else if (marker.properties.map_icon === "electronics store") {
                                el.className = "marker-electronicsstore-red"
                            } else if (marker.properties.map_icon === "supermarket") {
                                el.className = "marker-supermarket-red"
                            } else if (marker.properties.map_icon === "beauty salon") {
                                el.className = "marker-beautysalon-red"
                            } else if (marker.properties.map_icon === "hair care") {
                                el.className = "marker-hairsalon-red"
                            } else if (marker.properties.map_icon === "cinema") {
                                el.className = "marker-cinema-red"
                            } else if (marker.properties.map_icon === "lodging") {
                                el.className = "marker-lodging-red"
                            } else if (marker.properties.map_icon === "minifootball") {
                                el.className = "marker-minifootball-red"
                            } else if (marker.properties.map_icon === "spa") {
                                el.className = "marker-spa-red"
                            } else if (marker.properties.map_icon === "doctor") {
                                el.className = "marker-doctor-red"
                            } else if (marker.properties.map_icon === "dentist") {
                                el.className = "marker-dentist-red"
                            } else if (marker.properties.map_icon === "lawyer") {
                                el.className = "marker-lawyer-red"
                            } else if (marker.properties.map_icon === "sports") {
                                el.className = "marker-sports-red"
                            } else if (marker.properties.map_icon === "bowling alley") {
                                el.className = "marker-bowling-red"
                            } else if (marker.properties.map_icon === "art gallery") {
                                el.className = "marker-artgallery-red"
                            } else if (marker.properties.map_icon === "museum") {
                                el.className = "marker-museum-red"
                            } else if (marker.properties.map_icon === "aquarium") {
                                el.className = "marker-aquarium-red"
                            } else if (marker.properties.map_icon === "amusement park") {
                                el.className = "marker-amusementpark-red"
                            } else if (marker.properties.map_icon === "zoo") {
                                el.className = "marker-zoo-red"
                            } else if (marker.properties.map_icon === "campground") {
                                el.className = "marker-campground-red"
                            } else if (marker.properties.map_icon === "library") {
                                el.className = "marker-library-red"
                            } else if (marker.properties.map_icon === "rv park") {
                                el.className = "marker-rvpark-red"
                            } else if (marker.properties.map_icon === "university") {
                                el.className = "marker-university-red"
                            } else if (marker.properties.map_icon === "jewelry store") {
                                el.className = "marker-jewelry-red"
                            } else if (marker.properties.map_icon === "bakery") {
                                el.className = "marker-bakery-red"
                            } else if (marker.properties.map_icon === "liquor store") {
                                el.className = "marker-liquorstore-red"
                            } else if (marker.properties.map_icon === "pet store") {
                                el.className = "marker-petstore-red"
                            } else if (marker.properties.map_icon === "convenience store") {
                                el.className = "marker-conviniencestore-red"
                            } else if (marker.properties.map_icon === "gas station") {
                                el.className = "marker-gasstation-red"
                            } else if (marker.properties.map_icon === "home goods store") {
                                el.className = "marker-homegoodsstore-red"
                            } else if (marker.properties.map_icon === "hardware store") {
                                el.className = "marker-hardwarestore-red"
                            } else if (marker.properties.map_icon === "pharmacy") {
                                el.className = "marker-pharmacy-red"
                            } else if (marker.properties.map_icon === "pharmacy overnight") {
                                el.className = "marker-pharmacy-red"
                            } else if (marker.properties.map_icon === "florist") {
                                el.className = "marker-florist-red"
                            } else if (marker.properties.map_icon === "furniture store") {
                                el.className = "marker-furniturestore-red"
                            } else if (marker.properties.map_icon === "bicycle store") {
                                el.className = "marker-bicyclestore-red"
                            } else if (marker.properties.map_icon === "book store") {
                                el.className = "marker-bookshop-red"
                            } else if (marker.properties.map_icon === "car dealer") {
                                el.className = "marker-cardealer-red"
                            } else if (marker.properties.map_icon === "electrician") {
                                el.className = "marker-electrician-red"
                            } else if (marker.properties.map_icon === "accounting") {
                                el.className = "marker-accountant-red"
                            } else if (marker.properties.map_icon === "bank") {
                                el.className = "marker-bank-red"
                            } else if (marker.properties.map_icon === "gym") {
                                el.className = "marker-gym-red"
                            } else if (marker.properties.map_icon === "veterinary care") {
                                el.className = "marker-veterany-red"
                            } else if (marker.properties.map_icon === "insurance agency") {
                                el.className = "marker-insurance-red"
                            } else if (marker.properties.map_icon === "travel agency") {
                                el.className = "marker-travel-red"
                            } else if (marker.properties.map_icon === "parking") {
                                el.className = "marker-parking-red"
                            } else if (marker.properties.map_icon === "painter") {
                                el.className = "marker-painter-red"
                            } else if (marker.properties.map_icon === "car rental") {
                                el.className = "marker-rentcar-red"
                            } else if (marker.properties.map_icon === "physiotherapist") {
                                el.className = "marker-physiotherapist-red"
                            } else if (marker.properties.map_icon === "real estate agency") {
                                el.className = "marker-realestateagent-red"
                            } else if (marker.properties.map_icon === "plumber") {
                                el.className = "marker-plumber-red"
                            } else if (marker.properties.map_icon === "car repair") {
                                el.className = "marker-carrepair-red"
                            } else if (marker.properties.map_icon === "car wash") {
                                el.className = "marker-carwash-red"
                            } else if (marker.properties.map_icon === "laundry") {
                                el.className = "marker-laundry-red"
                            } else if (marker.properties.map_icon === "locksmith") {
                                el.className = "marker-locksmith-red"
                            } else if (marker.properties.map_icon === "casino") {
                                el.className = "marker-casino-red"
                            } else if (marker.properties.map_icon === "meal takeaway") {
                                el.className = "marker-takeaway-red"
                            } else {
                                el.className = "marker-place-red"
                            }

                            el.addEventListener('click', (e) => {

                                const activeItem = document.getElementsByClassName('active');
                                e.stopPropagation();
                                if (activeItem[0]) {
                                    activeItem[0].classList.remove('active');
                                }
                                const listing = document.getElementById(`listing-${marker.properties.id}`);
                            });
                            new mapboxgl.Marker(el, { offset: [0, 0] })
                                .setLngLat(marker.geometry.coordinates)
                                .addTo(map.current);


                            const el_name = document.createElement('div');
                            el_name.id = `profile-marker-name-${marker.properties.id}`;
                            let map_style = "mapbox://styles/mapbox/dark-v10"

                            if (JSON.parse(localStorage.getItem('map_layout'))) {
                                map_style = JSON.parse(localStorage.getItem('map_layout'))
                            }
                            if (props.data.my_profile[0].map_layout) {
                                map_style = props.data.my_profile[0].map_layout
                            }
                            if (map_style === "mapbox://styles/mapbox/dark-v10") {
                                el_name.className = "find-place-marker-map-name";
                            } else {
                                el_name.className = "map-search-marker-map-name-blabk";
                            }
                            el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                            el_name.addEventListener('click', (e) => {
                                const activeItem = document.getElementsByClassName('active');
                                e.stopPropagation();
                                if (activeItem[0]) {
                                    activeItem[0].classList.remove('active');
                                }
                                props.data.openPlaceModal(marker.id)
                            });
                            new mapboxgl.Marker(el_name, { offset: [0, 0] })
                                .setLngLat(marker.geometry.coordinates)
                                .addTo(map.current);
                        }
                    }

                }
            }
        }

        if (props.data.new_hubs_fetched) {
            props.createdFetchedHubsMarkers()
            setTimeout(() => {
                for (const single_plce_pbject of props.data.new_fetched_hubs) {
                    if (!document.getElementById(`marker-${single_plce_pbject.id}`)) {
                        let new_places_array = []
                        let new_places = {}
                        const place = {
                            "type": "Feature",
                            "properties": {
                                "name": single_plce_pbject.name,
                                "active_subscription": single_plce_pbject.active_subscription,
                                "address": single_plce_pbject.address,
                                "another_app_appointments": single_plce_pbject.another_app_appointments,
                                "another_app_reservations": single_plce_pbject.another_app_reservations,
                                "appointment_acceptance": single_plce_pbject.appointment_acceptance,
                                "appointment_app_url": single_plce_pbject.appointment_app_url,
                                "category": single_plce_pbject.category,
                                "city": single_plce_pbject.city,
                                "country": single_plce_pbject.country,
                                "cover_photo": single_plce_pbject.cover_photo,
                                "cover_photo_props": single_plce_pbject.cover_photo_props,
                                "distance": single_plce_pbject.distance,
                                "facebook_url": single_plce_pbject.facebook_url,
                                "has_facebook": single_plce_pbject.has_facebook,
                                "has_instagram": single_plce_pbject.has_instagram,
                                "has_menu": single_plce_pbject.has_menu,
                                "has_online_shop": single_plce_pbject.has_online_shop,
                                "has_sales": single_plce_pbject.has_sales,
                                "has_telephone": single_plce_pbject.has_telephone,
                                "has_website": single_plce_pbject.has_website,
                                "hubst3r_appointments": single_plce_pbject.hubst3r_appointments,
                                "hubst3r_reservations": single_plce_pbject.hubst3r_reservations,
                                "id": single_plce_pbject.id,
                                "instagram_url": single_plce_pbject.instagram_url,
                                "is_claimed": single_plce_pbject.is_claimed,
                                "is_open_label": single_plce_pbject.is_open_label,
                                "is_open_now": single_plce_pbject.is_open_now,
                                "is_overnight": single_plce_pbject.is_overnight,
                                "lat": single_plce_pbject.lat,
                                "lng": single_plce_pbject.lng,
                                "logo": single_plce_pbject.logo,
                                "map_icon": single_plce_pbject.map_icon,
                                "place_type": single_plce_pbject.place_type,
                                "reservation_acceptance": single_plce_pbject.reservation_acceptance,
                                "reservation_app_url": single_plce_pbject.reservation_app_url,
                                "sales_text": single_plce_pbject.sales_text,
                                "shop_url": single_plce_pbject.shop_url,
                                "telephone": single_plce_pbject.telephone,
                                "telephone_appointments": single_plce_pbject.telephone_appointments,
                                "telephone_reservations": single_plce_pbject.telephone_reservations,
                                "traffic": single_plce_pbject.traffic,
                                "website": single_plce_pbject.website,
                                "wifi_code": single_plce_pbject.wifi_code,
                                "users_with_access": single_plce_pbject.users_with_access,
                            },
                            "geometry": {
                                "coordinates": [
                                    single_plce_pbject.lng,
                                    single_plce_pbject.lat
                                ],
                                "type": "Point"
                            },
                            "id": single_plce_pbject.id
                        }

                        new_places_array.push(place)
                        new_places = {
                            "features": new_places_array,
                            "type": "FeatureCollection"
                        }

                        for (const marker of new_places.features) {

                            const el = document.createElement('div');
                            el.id = `marker-${marker.properties.id}`;

                            if (marker.properties.map_icon === "restaurant") {
                                el.className = "marker-restaurant-red"
                            } else if (marker.properties.map_icon === "coffee shop") {
                                el.className = "marker-cafe-red"
                            } else if (marker.properties.map_icon === "bar") {
                                el.className = "marker-bar-red"
                            } else if (marker.properties.map_icon === "night club") {
                                el.className = "marker-club-red"
                            } else if (marker.properties.map_icon === "food") {
                                el.className = "marker-food-red"
                            } else if (marker.properties.map_icon === "shopping mall") {
                                el.className = "marker-mall-red"
                            } else if (marker.properties.map_icon === "clothing store") {
                                el.className = "marker-clothestore-red"
                            } else if (marker.properties.map_icon === "shoe store") {
                                el.className = "marker-shoestore-red"
                            } else if (marker.properties.map_icon === "electronics store") {
                                el.className = "marker-electronicsstore-red"
                            } else if (marker.properties.map_icon === "supermarket") {
                                el.className = "marker-supermarket-red"
                            } else if (marker.properties.map_icon === "beauty salon") {
                                el.className = "marker-beautysalon-red"
                            } else if (marker.properties.map_icon === "hair care") {
                                el.className = "marker-hairsalon-red"
                            } else if (marker.properties.map_icon === "cinema") {
                                el.className = "marker-cinema-red"
                            } else if (marker.properties.map_icon === "lodging") {
                                el.className = "marker-lodging-red"
                            } else if (marker.properties.map_icon === "minifootball") {
                                el.className = "marker-minifootball-red"
                            } else if (marker.properties.map_icon === "spa") {
                                el.className = "marker-spa-red"
                            } else if (marker.properties.map_icon === "doctor") {
                                el.className = "marker-doctor-red"
                            } else if (marker.properties.map_icon === "dentist") {
                                el.className = "marker-dentist-red"
                            } else if (marker.properties.map_icon === "lawyer") {
                                el.className = "marker-lawyer-red"
                            } else if (marker.properties.map_icon === "sports") {
                                el.className = "marker-sports-red"
                            } else if (marker.properties.map_icon === "bowling alley") {
                                el.className = "marker-bowling-red"
                            } else if (marker.properties.map_icon === "art gallery") {
                                el.className = "marker-artgallery-red"
                            } else if (marker.properties.map_icon === "museum") {
                                el.className = "marker-museum-red"
                            } else if (marker.properties.map_icon === "aquarium") {
                                el.className = "marker-aquarium-red"
                            } else if (marker.properties.map_icon === "amusement park") {
                                el.className = "marker-amusementpark-red"
                            } else if (marker.properties.map_icon === "zoo") {
                                el.className = "marker-zoo-red"
                            } else if (marker.properties.map_icon === "campground") {
                                el.className = "marker-campground-red"
                            } else if (marker.properties.map_icon === "library") {
                                el.className = "marker-library-red"
                            } else if (marker.properties.map_icon === "rv park") {
                                el.className = "marker-rvpark-red"
                            } else if (marker.properties.map_icon === "university") {
                                el.className = "marker-university-red"
                            } else if (marker.properties.map_icon === "jewelry store") {
                                el.className = "marker-jewelry-red"
                            } else if (marker.properties.map_icon === "bakery") {
                                el.className = "marker-bakery-red"
                            } else if (marker.properties.map_icon === "liquor store") {
                                el.className = "marker-liquorstore-red"
                            } else if (marker.properties.map_icon === "pet store") {
                                el.className = "marker-petstore-red"
                            } else if (marker.properties.map_icon === "convenience store") {
                                el.className = "marker-conviniencestore-red"
                            } else if (marker.properties.map_icon === "gas station") {
                                el.className = "marker-gasstation-red"
                            } else if (marker.properties.map_icon === "home goods store") {
                                el.className = "marker-homegoodsstore-red"
                            } else if (marker.properties.map_icon === "hardware store") {
                                el.className = "marker-hardwarestore-red"
                            } else if (marker.properties.map_icon === "pharmacy") {
                                el.className = "marker-pharmacy-red"
                            } else if (marker.properties.map_icon === "pharmacy overnight") {
                                el.className = "marker-pharmacy-red"
                            } else if (marker.properties.map_icon === "florist") {
                                el.className = "marker-florist-red"
                            } else if (marker.properties.map_icon === "furniture store") {
                                el.className = "marker-furniturestore-red"
                            } else if (marker.properties.map_icon === "bicycle store") {
                                el.className = "marker-bicyclestore-red"
                            } else if (marker.properties.map_icon === "book store") {
                                el.className = "marker-bookshop-red"
                            } else if (marker.properties.map_icon === "car dealer") {
                                el.className = "marker-cardealer-red"
                            } else if (marker.properties.map_icon === "electrician") {
                                el.className = "marker-electrician-red"
                            } else if (marker.properties.map_icon === "accounting") {
                                el.className = "marker-accountant-red"
                            } else if (marker.properties.map_icon === "bank") {
                                el.className = "marker-bank-red"
                            } else if (marker.properties.map_icon === "gym") {
                                el.className = "marker-gym-red"
                            } else if (marker.properties.map_icon === "veterinary care") {
                                el.className = "marker-veterany-red"
                            } else if (marker.properties.map_icon === "insurance agency") {
                                el.className = "marker-insurance-red"
                            } else if (marker.properties.map_icon === "travel agency") {
                                el.className = "marker-travel-red"
                            } else if (marker.properties.map_icon === "parking") {
                                el.className = "marker-parking-red"
                            } else if (marker.properties.map_icon === "painter") {
                                el.className = "marker-painter-red"
                            } else if (marker.properties.map_icon === "car rental") {
                                el.className = "marker-rentcar-red"
                            } else if (marker.properties.map_icon === "physiotherapist") {
                                el.className = "marker-physiotherapist-red"
                            } else if (marker.properties.map_icon === "real estate agency") {
                                el.className = "marker-realestateagent-red"
                            } else if (marker.properties.map_icon === "plumber") {
                                el.className = "marker-plumber-red"
                            } else if (marker.properties.map_icon === "car repair") {
                                el.className = "marker-carrepair-red"
                            } else if (marker.properties.map_icon === "car wash") {
                                el.className = "marker-carwash-red"
                            } else if (marker.properties.map_icon === "laundry") {
                                el.className = "marker-laundry-red"
                            } else if (marker.properties.map_icon === "locksmith") {
                                el.className = "marker-locksmith-red"
                            } else if (marker.properties.map_icon === "casino") {
                                el.className = "marker-casino-red"
                            } else if (marker.properties.map_icon === "meal takeaway") {
                                el.className = "marker-takeaway-red"
                            } else {
                                el.className = "marker-place-red"
                            }

                            el.addEventListener('click', (e) => {
                                const activeItem = document.getElementsByClassName('active');
                                e.stopPropagation();
                                if (activeItem[0]) {
                                    activeItem[0].classList.remove('active');
                                }
                                const listing = document.getElementById(`listing-${marker.properties.id}`);

                                props.data.openPlaceModal(marker.id)


                            });
                            new mapboxgl.Marker(el, { offset: [0, 0] })
                                .setLngLat(marker.geometry.coordinates)
                                .addTo(map.current);

                            const el_name = document.createElement('div');
                            el_name.id = `profile-marker-name-${marker.properties.id}`;
                            let map_style = "mapbox://styles/mapbox/dark-v10"

                            if (JSON.parse(localStorage.getItem('map_layout'))) {
                                map_style = JSON.parse(localStorage.getItem('map_layout'))
                            }
                            if (props.data.my_profile[0].map_layout) {
                                map_style = props.data.my_profile[0].map_layout
                            }

                            if (map_style === "mapbox://styles/mapbox/dark-v10") {
                                el_name.className = "find-place-marker-map-name";
                            } else {
                                el_name.className = "map-search-marker-map-name-blabk";
                            }
                            el_name.innerHTML += `<p>${marker.properties.name}</p>`;
                            el_name.addEventListener('click', (e) => {
                                const activeItem = document.getElementsByClassName('active');
                                e.stopPropagation();
                                if (activeItem[0]) {
                                    activeItem[0].classList.remove('active');
                                }
                                props.data.openPlaceModal(marker.id)
                            });
                            new mapboxgl.Marker(el_name, { offset: [0, 0] })
                                .setLngLat(marker.geometry.coordinates)
                                .addTo(map.current);
                        }
                    }
                }
            }, 500);



        }
    }
    );


    return <div ref={mapContainer}
        className="map-box-search-map-map"
        style={{ width: "100vw", height: "100vh" }}
    />;
};
{/* <div class="map-box-search-map-map"> */ }
export default PlaceMap;
