import React, { Component, Fragment } from 'react';
// memories carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/memories/memory-tags-modal.css"
import { Link } from 'react-router-dom';


export class MemoryTagsModal extends Component {
    state = {
        addTagMode: false,
        newTaggedBuddies: [],
        user_search: '',
        removeTagMode: false,
        btnAppear: true,
    }

    onBuddiesChange = e => {
        if (e.target.name === "user_search") {
            if (e.target.value === "") {
                this.setState({ [e.target.name]: e.target.value })
                this.props.getBuddies(JSON.parse(localStorage.getItem('ur')).id);
            } else {
                const query = e.target.value
                if (query.length > 2) {
                    if (query.toLowerCase() !== "ad") {
                        this.props.searchBuddy(query)
                    }
                }
                this.setState({ [e.target.name]: e.target.value })
            }
        }
        this.setState({ [e.target.name]: e.target.value })
    }


    closeModal = () => {
        this.setState({
            addTagMode: false
        })
        this.props.data.closeModal()
    }

    addTagMode = () => {
        this.setState({
            addTagMode: true
        })
    }

    closeTagMode = () => {
        this.setState({
            addTagMode: false
        })
    }


    changeRemoveTagMode = () => {
        this.setState({
            removeTagMode: !this.state.removeTagMode
        })
    }


    addTagedBuddy = (full_name) => {
        this.setState({
            btnAppear: false,
        })
        const memory_id = this.props.data.memory_id
        const tag = { memory_id, full_name }
        this.props.addTagedBuddy(memory_id, tag)

        setTimeout(() => {
            if (this.props.data.from_homepage) {
                this.props.getHomepageMemories()
            }
        }, 500);

        setTimeout(() => {
            this.setState({
                btnAppear: true,
            })
        }, 1000);
    }

    removeTagedBuddy = (full_name) => {
        const memory_id = this.props.data.memory_id
        const tag = { memory_id, full_name }
        this.props.removeTagedBuddy(memory_id, tag)
        setTimeout(() => {
            if (this.props.data.from_homepage) {
                this.props.getHomepageMemories()
            }
        }, 500);
    }

    removeMytag = (full_name) => {
        this.setState({
            removeTagMode: !this.state.removeTagMode
        })
        const memory_id = this.props.data.memory_id
        const tag = { memory_id, full_name }
        this.props.removeMytag(memory_id, tag)
        setTimeout(() => {
            if (this.props.data.from_homepage) {
                this.props.getHomepageMemories()
            }
        }, 500);
    }


    render() {
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 3,
            vertical: true,
            verticalSwiping: true,
            swipeToSlide: true,
        };

        const buddiesSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
        };


        const tags = this.props.data.tags
        const user = this.props.data.profile[0].user
        const memory_user = this.props.data.memory_user
        const buddies = this.props.data.buddies

        const tagged_users = tags.map(tagged => tagged.user);
        return (
            <Fragment>
                <div class="memory-tags-modal-position">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="memory-tags-modal-box">
                            <div class="row text-right">
                                <div class="col-xl-12 text-right">
                                    <div
                                        onClick={() => this.closeModal()}
                                        class="memory-tags-cancle-btn">
                                        <img class="memory-tags-close-icon" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="text-left memory-tags-title-box">
                                    <h4><strong class="memory-tags-title">Who was at {this.props.data.memory_hub} that day:</strong></h4>
                                </div>
                            </div>
                            <hr />
                            {this.state.addTagMode ?
                                <>
                                    <div class="memory-tags-add-slider-title">
                                        Add Buddy to the Memory
                                    </div>

                                    <div className="add-tag-search">
                                        <div className="form-inline w-100">
                                            <input
                                                className="add-tag-search form-control mr-sm-2 w-100 py-4"
                                                type="search"
                                                name="user_search"
                                                onChange={this.onBuddiesChange}
                                                placeholder="Search Buddy"
                                                value={this.state.user_search}
                                                aria-label="Search"
                                            />
                                        </div>
                                    </div>

                                    <div class="memory-tags-add-slider">
                                        <Slider {...buddiesSettings} >
                                            {buddies.map(buddy => (
                                                <div key={buddy.id}>
                                                    <div class="memory-tags-add-buddy-frame" >
                                                        <div
                                                            // onClick={this.handleHubChange(hub.id)}
                                                            class=""
                                                        >
                                                            {buddy.profile_picture ?
                                                                <img
                                                                    class=
                                                                    // {buddy_id === buddy.id ?
                                                                    //     "post-memory-hub-sellected-logo"
                                                                    //     :
                                                                    "memory-tags-add-buddy"
                                                                    // }
                                                                    src={buddy.profile_picture} alt=""
                                                                />
                                                                :
                                                                <img
                                                                    class=
                                                                    // {buddy_id === buddy.id ?
                                                                    //     "post-memory-hub-sellected-logo"
                                                                    //     :
                                                                    "memory-tags-add-buddy"
                                                                    // }
                                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="memory-tags-add-buddy-username">
                                                            {buddy.username}
                                                        </div>
                                                    </div>
                                                    {tagged_users.includes(buddy.user) ?
                                                        <div
                                                            onClick={() => this.removeTagedBuddy(buddy.user)}
                                                            class="memory-tags-added-tag-btn"
                                                        >
                                                            Added
                                                        </div>
                                                        :
                                                        <>
                                                            {this.state.btnAppear ?
                                                                <div
                                                                    onClick={() => this.addTagedBuddy(buddy.user)}
                                                                    class="memory-tags-add-tag-btn"
                                                                >
                                                                    Add
                                                                </div>
                                                                :
                                                                <div
                                                                    class="memory-tags-add-tag-btn"
                                                                >
                                                                    Add
                                                                </div>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>

                                    <div
                                        onClick={() => this.closeTagMode()}
                                        class="add-tag-back-icon">
                                        <img
                                            class="add-tag-back-img"
                                            src={require("../../../assets/layout/homepage svg/back arrow.svg")}
                                            alt=""
                                        />
                                    </div>
                                </>
                                :

                                <div class="memory-tags-slider">
                                    <Slider {...settings} >
                                        {tags.map(tag => (
                                            <div class="memory-tags-item" >
                                                <div class="row memory-tags-single-item">
                                                    <div class="memory-tags-user-info">
                                                        <Link to={{ pathname: `/user-profile/${tag.user}/profile`, state: { user: tag.user } }}>
                                                            {tag.profile_picture ?
                                                                <img class="memory-tags-user-picture" src={tag.profile_picture} alt="" />
                                                                :
                                                                <img class="memory-tags-user-picture" src={require("../../../assets/authentication/svg/account.svg")} alt="" />
                                                            }
                                                        </Link>
                                                    </div>
                                                    <div class="memory-tags-info">
                                                        <div class="memory-tags-info-layout">
                                                            <Link to={{ pathname: `/user-profile/${tag.user}/profile`, state: { user: tag.user } }}>
                                                                {tag.full_name === "null" ?
                                                                    <p class="memory-tags-text">{tag.username}</p>
                                                                    : tag.full_name === null ?
                                                                        <p class="memory-tags-text">{tag.username}</p>
                                                                        : tag.full_name === "" ?
                                                                            <p class="memory-tags-text">{tag.username}</p>
                                                                            : tag.full_name === undefined ?
                                                                                <p class="memory-tags-text">{tag.username}</p>
                                                                                : tag.full_name === "undefined" ?
                                                                                    <p class="memory-tags-text">{tag.username}</p>
                                                                                    :
                                                                                    <p class="memory-tags-text">{tag.full_name}</p>
                                                                }
                                                            </Link>


                                                            {/* <p class="memory-tags-text">Time: {tag.time}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        ))}
                                    </Slider>
                                    <>
                                        {user === memory_user ?
                                            <div
                                                onClick={() => this.addTagMode()}
                                                class="row"
                                            >
                                                <div class="memory-tags-action-icon">
                                                    <img class="memory-tags-action-img" src={require("../../../assets/memories/svg/add.svg")} alt="" />
                                                </div>
                                                <div class="memory-tags-action-text">
                                                    Add Buddy to the memory
                                                </div>
                                            </div>
                                            : tagged_users.includes(user) ?
                                                <>
                                                    {!this.state.removeTagMode ?
                                                        <div class="row">
                                                            <div class="memory-tags-action-icon">
                                                                <img class="memory-tags-action-img" src={require("../../../assets/memories/svg/remove.svg")} alt="" />
                                                            </div>
                                                            <div
                                                                onClick={() => this.changeRemoveTagMode()}
                                                                class="memory-tags-action-text">
                                                                Remove me from the memory
                                                            </div>
                                                        </div>
                                                        :
                                                        <div class="row">
                                                            <div class="memory-tags-remove-tag-text">
                                                                Are you sure ?
                                                            </div>
                                                            <div
                                                                onClick={() => this.removeMytag(user)}
                                                                class="memory-tags-remove-yes"
                                                            >
                                                                Yes
                                                            </div>
                                                            <div
                                                                onClick={() => this.changeRemoveTagMode()}
                                                                class="memory-tags-remove-no"
                                                            >
                                                                No
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                    </>
                                </div>

                            }
                        </div>
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (MemoryTagsModal);