import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import HubDesktopNavbar from '../../../components/hub/layout/hubDesktopNavbar';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import { getHubPage } from '../../../store/actions/hub'
import { getHubDaysAppointmentHours, createNewAppointment } from '../../../store/actions/appointment'
import { createAlert } from '../../../store/actions/alerts'
//datepicker
import moment from "moment-timezone";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import WheelPicker from 'react-simple-wheel-picker';
import { Link, Redirect } from 'react-router-dom';
import "../../../assets/arrange-meeting/arrange-page.css";


export class AddAppointment extends Component {
    state = {
        appointment_note: '-',
        telephone: "-",
        hub: '',
        date: new Date(),
        date_updated: false,
        redirect: false,
        show_datepicker: false,
        hour: '',
        minute: '',
        time: '',
        time_updated: false,
        hour_sellected: false,
        dateMode: true,
        timeMode: false,
        noteMode: false,
        hub_name: "",
        hub_logo: "",
        hub_id: "",
        timeModalVisible: true,
        pre_selected: false,
        appointment_name: "",
        redirect: false,
        selected_time: ""
    }

    componentDidMount() {
        this.props.getHubPage();
    }

    handleBack = () => {
        this.props.history.goBack()
    }


    selectDateMode(hub_name, hub_logo, hub_id) {
        this.setState({
            hubMode: false,
            dateMode: true,
            timeMode: false,
            noteMode: false
        })
        if (hub_name !== "") {
            this.setState({
                hub_name: hub_name,
                hub_logo: hub_logo,
                hub_id: hub_id
            })
        }
    }

    selectDateModeTop() {
        this.setState({
            hubMode: false,
            dateMode: true,
            timeMode: false,
            noteMode: false
        })
    }

    selectTimeMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            timeMode: true,
            noteMode: false,
            timeModalVisible: true,

        })
    }

    selectNoteMode() {
        this.setState({
            hubMode: false,
            dateMode: false,
            timeMode: false,
            noteMode: true
        })
    }

    updateDateField(day) {
        const hub_id = this.props.hub[0].id
        const correct_datetime = moment(day).tz("Europe/Athens").format("YYYY-MM-DD")
        const date1 = correct_datetime.replace(/[^\d.-]/g, '');
        const date2 = date1.split('.').join("");
        const date_id = date2.split('-').join("");
        this.props.getHubDaysAppointmentHours(hub_id, date_id)

        this.setState({
            show_datepicker: false,
            date: day,
            date_updated: true,
            hubMode: false,
            dateMode: false,
            timeMode: true,
            timeModalVisible: true,
            noteMode: false,
            pre_selected: true
        })
    }


    handleOnChange(time) {
        this.setState({
            selected_time: time
        })
    }

    updateReservatonHour = (options) => {
        const {
            hour,
            minute,
        } = options;
        if (this.state.hour_sellected) {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                time_updated: true,
                hubMode: false,
                dateMode: false,
                timeMode: false,
                hour_sellected: false,
                noteMode: true
            });
        } else {
            const time = hour + ':' + minute
            this.setState({
                hour: hour,
                minute: minute,
                time: time,
                hour_sellected: true,
            });
        }


    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    setDatepickerMode = () => (e) => {
        this.setState({
            show_datepicker: true
        });
    }


    selectSpecificTime() {
        this.setState({
            dateMode: false,
            timeMode: false,
            noteMode: true
        })
    }



    onSubmitAppointment = e => {
        const appointment_name = this.state.appointment_name
        const time = this.state.selected_time.value
        const telephone = this.state.telephone
        const note = this.state.appointment_note
        const date = moment(this.state.date).tz("Europe/Athens").format("YYYY-MM-DD")
        // let user = this.props.data.user_profile[0]
        // if (user) {
        //     user = user.user
        // } else {
        //     user = ""
        // }

        const appointment = { time, date, note, telephone, appointment_name };
        if (!this.state.date_updated) {
            this.props.createAlert({ noTimeSelected: "You haven't Selected a Date!" });
        } else if (this.state.appointment_name === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Selected a name for the appointment!" });
        } else if (time === "") {
            this.props.createAlert({ noTimeSelected: "You haven't Selected time!" });
        } else {
            this.props.createNewAppointment(appointment)
            setTimeout(() => {
                this.setState({
                    appointment_note: '-',
                    telephone: "-",
                    hub: '',
                    date: new Date(),
                    date_updated: false,
                    redirect: false,
                    show_datepicker: false,
                    hour: '',
                    minute: '',
                    time: '',
                    time_updated: false,
                    hour_sellected: false,
                    hubMode: true,
                    dateMode: false,
                    timeMode: false,
                    noteMode: false,
                    hub_name: "",
                    hub_logo: "",
                    hub_id: "",
                    timeModalVisible: true,
                    pre_selected: false,
                    appointment_name: "",
                    redirect: true
                });
            }, 500);
        }
    }


    render() {

        const ExampleCustomInput = ({ value, onClick }) => (
            <div
            // onClick={onClick}
            >
                {value}
            </div>
        );


        if (this.state.redirect) {
            return <Redirect to="/hub-homepage" />;
        }

        const data = this.props.appointment_hours

        const empty_appointment_hours = data.filter(hour => hour.appointment === "")

        var empty_appointment_hours_ids = empty_appointment_hours.map(function (i) {
            return i.value;
        });

        return (


            < Fragment >

                <HubDesktopNavbar data={{ sellected: "" }} />

                <div>
                    <div class="arrange-page-whole-page-layout">
                        <OfflineLabel />
                        <div class="color-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="request-reservation-mobile-navbar">
                                        <div onClick={() => this.handleBack()} class="back-button-request-reservation-box">
                                            <img
                                                class="request-reservation-back-button"
                                                src={require("../../../assets/hub/hub-page/svg/back-btn.svg")}
                                                alt=""
                                            />
                                        </div>
                                        Create Appointment
                                    </div>

                                    <div class="body-right-4 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">


                                        <div class="arrange-invite-your-buddies-text">
                                            <div class="text-top text-center blue pt-5 pb-3">
                                                <h3>
                                                    Create Appointment
                                                </h3>
                                            </div>
                                        </div>

                                        <div class="request-reservation-top">
                                            <div class="request-reservation-top-row">

                                                <div
                                                    onClick={() => this.selectDateModeTop()}
                                                    class="request-reservation-top-step">
                                                    <div class="request-reservation-top-title">
                                                        Date
                                                    </div>

                                                    <div
                                                        class={this.state.dateMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        {this.state.date_updated ?
                                                            <img
                                                                class="request-reservation-btn-hub-logo"
                                                                src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                alt=""
                                                            />
                                                            :
                                                            1
                                                        }
                                                    </div>
                                                    {this.state.date_updated ?
                                                        <div class="request-reservation-top-text">
                                                            <DatePicker
                                                                selected={this.state.date}
                                                                dateFormat="dd/MM/yyyy"
                                                                customInput={<ExampleCustomInput />}
                                                                readOnly
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>

                                                {this.state.date_updated ?
                                                    <div
                                                        onClick={() => this.selectTimeMode()}
                                                        class="request-reservation-top-step">
                                                        <div class="request-reservation-top-title"  >
                                                            Time
                                                        </div>
                                                        <div
                                                            class={this.state.timeMode ?
                                                                "request-reservation-btn-circle-sellected"
                                                                :
                                                                "request-reservation-btn-circle"
                                                            }
                                                        >
                                                            {this.state.time === "" ?
                                                                2
                                                                :
                                                                <img
                                                                    class="request-reservation-btn-hub-logo"
                                                                    src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="request-reservation-top-text">{this.state.time}</div>
                                                    </div>
                                                    :
                                                    <div
                                                        class="request-reservation-top-step">
                                                        <div class="request-reservation-top-title"  >
                                                            Time
                                                        </div>
                                                        <div
                                                            class={this.state.timeMode ?
                                                                "request-reservation-btn-circle-sellected"
                                                                :
                                                                "request-reservation-btn-circle"
                                                            }
                                                        >
                                                            {this.state.time === "" ?
                                                                2
                                                                :
                                                                <img
                                                                    class="request-reservation-btn-hub-logo"
                                                                    src={require("../../../assets/layout/homepage svg/scan success.svg")}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div class="request-reservation-top-text">{this.state.time}</div>
                                                    </div>
                                                }



                                                <div
                                                    onClick={() => this.selectNoteMode()}
                                                    class="request-reservation-top-step"
                                                >
                                                    <div
                                                        class="request-reservation-top-title">
                                                        Details
                                                    </div>
                                                    <div
                                                        class={this.state.noteMode ?
                                                            "request-reservation-btn-circle-sellected"
                                                            :
                                                            "request-reservation-btn-circle"
                                                        }
                                                    >
                                                        3
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />



                                        <div>

                                            {this.state.dateMode ?
                                                <>
                                                    <div class="request-reservation-section-title">
                                                        Select the Date
                                                    </div>

                                                    <DatePicker
                                                        selected={this.state.date}
                                                        todayButton="Press for Today"
                                                        onChange={this.updateDateField.bind(this)}
                                                        minDate={new Date()}
                                                        name="date"
                                                        dateFormat="dd/MM/yyyy"
                                                        // customInput={<ExampleCustomInput />}
                                                        // withPortal
                                                        inline
                                                    />
                                                </>
                                                :
                                                null
                                            }

                                            {this.state.timeMode ?
                                                <>
                                                    {data.length !== 0 ?
                                                        <>
                                                            <div class="request-reservation-timewheel-box">
                                                                {data.length !== 0 ?

                                                                    <>
                                                                        <div class="request-reservation-timewheel-arrow-left-box">
                                                                            <img
                                                                                class="request-reservation-timewheel-arrow-img"
                                                                                src={require("../../../assets/hub/hub-homepage/svg/arrow-left.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="request-reservation-timewheel-arrow-right-box">
                                                                            <img
                                                                                class="request-reservation-timewheel-arrow-img"
                                                                                src={require("../../../assets/hub/hub-homepage/svg/arrow-right.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <WheelPicker
                                                                            data={data}
                                                                            onChange={this.handleOnChange.bind(this)}
                                                                            height={350}
                                                                            width={180}
                                                                            itemHeight={60}
                                                                            selectedID={data[5].id}
                                                                            color="#000"
                                                                            activeColor="white"
                                                                            backgroundColor="grey"
                                                                        />

                                                                        {this.props.appointment_hours.map(hour => (
                                                                            <>
                                                                                {hour.value === this.state.selected_time.value ?
                                                                                    <div class="request-reservation-timewheel-text">
                                                                                        Appointments at <b>{hour.value}</b>:<br />
                                                                                        <b>{hour.appointment}</b>
                                                                                    </div>
                                                                                    :

                                                                                    null
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                            </div>

                                                            {empty_appointment_hours_ids.includes(this.state.selected_time.value) ?
                                                                <div onTouchStart>
                                                                    <div
                                                                        onClick={() => this.selectSpecificTime()}
                                                                        class="request-reservation-request-button"
                                                                    >
                                                                        Select {this.state.selected_time.value}
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </>
                                                        :
                                                        <div class="request-reservation-no-reservation-hours">
                                                            You have sellected to be Closed<br />on this day of the week
                                                        </div>
                                                    }
                                                </>
                                                :
                                                null
                                            }


                                            {this.state.noteMode ?
                                                <>

                                                    <div class="request-reservation-notes">
                                                        Insert the Name of the Appointment
                                                    </div>
                                                    <div class="form-group mt-4">
                                                        <input
                                                            maxlength="22"
                                                            className="form-control"
                                                            type="text"
                                                            name="appointment_name"
                                                            onChange={this.onChange}
                                                            placeholder={this.state.appointment_name === "" ? "Appointment name..." : this.state.appointment_name}
                                                        />

                                                    </div>

                                                    <div class="request-reservation-notes">
                                                        Insert Mobile Phone Number
                                                    </div>
                                                    <div class="form-group mt-4">
                                                        <input
                                                            maxlength="18"
                                                            className="form-control"
                                                            type="number"
                                                            name="telephone"
                                                            onChange={this.onChange}
                                                            placeholder={this.state.telephone === "-" ? "Mobile Phone Number..." : this.state.telephone}
                                                        />

                                                    </div>


                                                    <div class="request-reservation-notes">
                                                        Write a note for the appointment
                                                    </div>
                                                    <div class="form-group mt-4">
                                                        <textarea
                                                            class="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            placeholder={this.state.appointment_note === "-" ? "Note..." : this.state.appointment_note}
                                                            rows="3"
                                                            name="appointment_note"
                                                            onChange={this.onChange}
                                                        ></textarea>
                                                    </div>

                                                    <div onTouchStart>
                                                        <div

                                                            onClick={() => this.onSubmitAppointment()}
                                                            class="request-reservation-request-button"
                                                        >
                                                            Create Appointment
                                                        </div>
                                                    </div>
                                                    <br /><br /><br /><br /><br />
                                                    <br /><br /><br /><br /><br />
                                                    {/* </div> */}
                                                </>
                                                :
                                                null
                                            }



                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment >

        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    hub: state.hub.hub,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,
    appointment_hours: state.appointment.appointment_hours,
});

export default connect(mapStateToProps, {
    getHubPage,
    createNewAppointment,
    getHubDaysAppointmentHours,
    createAlert,
})(AddAppointment);