import React, { Component, Fragment } from 'react';
import "../../../assets/layout/are-you-sure.css"



export class TablesModal extends Component {


    closeModal = () => {
        this.props.data.closeModal()
    }

    state = {
        sellected: "",
        sellection_changed: false
    }

    sellectNo() {
        this.setState({
            sellected: "no",
        })
        setTimeout(() => {
            this.props.data.closeModal()
            this.setState({
                sellected: "",
                sellection_changed: false
            })
        }, 1000);
    }

    sellectYes() {
        this.setState({
            sellected: "yes",
        })
        setTimeout(() => {
            this.props.data.closeModal()
            this.props.data.functionToRun()
            this.setState({
                sellected: "",
                sellection_changed: false
            })
        }, 1000);
    }

    render() {
        return (
            <Fragment>
                <div class="are-you-sure-box">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                        <div class="are-you-sure-box-6">
                            <div
                                onClick={() => this.closeModal()}
                                class="are-you-sure-times-icon">
                                <img class="" src={require("../../../assets/memories/svg/Cancel.svg")} alt="" />
                            </div>
                            <div class="are-you-sure-title-size">
                                {this.props.data.sellected === "cancel-meeting" ?
                                    <h4 class="are-you-sure-title">
                                        Are you Sure that you want to cancel the Meeting??
                                    </h4>
                                    : this.props.data.sellected === "decline-invitation" ?
                                        <h4 class="are-you-sure-title">
                                            Are you Sure you want to be the FunBreaker??
                                        </h4>
                                        : this.props.data.sellected === "reservation-not-attended" ?
                                            <h4 class="are-you-sure-title">
                                                Are you Sure you don't want to give them another 10 minutes to be sure??
                                            </h4>
                                            : this.props.data.sellected === "decline-reservation" ?
                                                <h4 class="are-you-sure-title">
                                                    Are you Sure you want to Decline the Reservation??
                                                </h4>
                                                : this.props.data.sellected === "cancel-reservation" ?
                                                    <h4 class="are-you-sure-title">
                                                        Are you Sure you want to Cancel the Reservation??
                                                    </h4>
                                                    :
                                                    null
                                }
                            </div>
                            <div>
                                <div class="row">
                                    {this.state.sellected === "no" ?
                                        <img
                                            class="are-you-sure-sellected-no"
                                            // onClick={() => this.sellectNo()}
                                            src={require("../../../assets/layout/mika/mika-are-you-sure-sellected-no.svg")}
                                            alt=""
                                        />
                                        :
                                        <img
                                            class="are-you-sure-mika-decline"
                                            onClick={() => this.sellectNo()}
                                            src={require("../../../assets/layout/mika/mika-are-you-sure-no.svg")}
                                            alt=""
                                        />
                                    }
                                    <img
                                        class=
                                        {this.state.sellected === "yes" ?
                                            "are-you-sure-sellected-yes"
                                            :
                                            "are-you-sure-mika-approve"}
                                        onClick={() => this.sellectYes()}
                                        src={require("../../../assets/layout/mika/mika-are-you-sure-yes.svg")}
                                        alt=""
                                    />
                                </div>

                                <div class="row">
                                    <h4
                                        onClick={() => this.sellectNo()}
                                        class="are-you-sure-choises-no text-center"
                                    >
                                        <strong>No leave it as it is</strong>
                                    </h4>
                                    <h4
                                        onClick={() => this.sellectYes()}
                                        class="are-you-sure-choises-yes text-center">
                                        <strong>Yes I am Sure</strong>

                                    </h4>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-1 col-sm-2 col-2">
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default (TablesModal);