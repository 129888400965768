import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { createAlert } from '../../../store/actions/alerts'
import {
    getPendingMeetings,
    getCancelledMeetings,
    getReservationMeetings,
    getMeetingInvitations,
    cancelMeeting,
    updateMeeting,
    createInvitation,
    handleInvitation,
    cancelReservationMeeting,
} from '../../../store/actions/meeting';
import { getHubActivities, getHubForReservationRequest } from '../../../store/actions/hub';
import {
    getUserAppointments,
    userCancelAppointment
} from '../../../store/actions/appointment';

import { requestReservation, getUserAllCancelledHubReservations, userCancelHubReservation, getUserHubReservations, getHubReservtionShares, shareHubReservation } from '../../../store/actions/reservation';
import { getBuddies, searchBuddy } from '../../../store/actions/buddies'
import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import GdprLinks from '../../../components/user/layout/gdprLinks';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import { getMyProfile } from '../../../store/actions/profile';
import { getNotificationsStatus } from '../../../store/actions/notification';
import "../../../assets/meeting/user-meetings.css"
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import ShareReservationModal from '../../../components/user/meeting/ShareReservationModal';
import AreYouSure from '../../../components/user/layout/AreYouSure';
import Modal from 'react-awesome-modal';

export class Meetings extends Component {

    state = {
        pendingTab: true,
        cancelledTab: false,
        reservationsTab: false,
        shareReservationModal: false,
        cancelledHubReservations: [],
        hub_reservation_id: "",
        hub_reservation_user: "",
        hub_reservation_creator_username: "",
        hub_reservation_creator_profile_pic: "",
        loading_time: true,
        selected_appointment_id: "",
        selected_appointment_hub: "",
        selected_appointment_time: "",
        selected_appointment_date: "",
    }


    componentDidMount() {
        // this.props.getPendingMeetings()
        // this.props.getCancelledMeetings()
        // this.props.getReservationMeetings()
        this.props.getMyProfile();
        this.props.getUserHubReservations()
        this.props.getUserAppointments()
        setTimeout(() => {
            this.setState({
                loading_time: false,
            })
        }, 2000);

        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }
        // this.props.getBuddies(profile.user);
    }

    closeModal() {
        this.setState({
            wifiModalVisible: false,
            shareReservationModal: false,
            reservationAreYouSureModal: false,
            appointmentAreYouSureModal: false,
            appointmentAreYouSureModal: false,
            meetingAreYouSureModal: false,
            qrCodeModalVisible: false,
            hub_name: "",
            wifi_code: "",
            qr_code: "",
            hub_reservation_id: "",
            selected_appointment_id: "",
            hub_reservation_user: "",
            hub_reservation_creator_username: "",
            hub_reservation_creator_profile_pic: "",
            selected_appointment_hub: "",
            selected_appointment_time: "",
            selected_appointment_date: "",
        });
    }

    userCancelHubReservation = (hub_reservation_id, user) => (e) => {
        const status = "Cancelled by User"
        const answer = { status, user, hub_reservation_id };
        this.props.userCancelHubReservation(answer, hub_reservation_id);
        this.updateCancelledHubReservations(hub_reservation_id)
    }

    updateCancelledHubReservations(id) {
        const cancellations_tab = [...this.state.cancelledHubReservations]
        cancellations_tab.push(id)
        this.setState({ cancelledHubReservations: cancellations_tab })
    }

    openReservationAreYouSureModal(hub_reservation_id) {
        this.setState({
            reservationAreYouSureModal: true,
            hub_reservation_id: hub_reservation_id,
            // hub_reservation_user: hub_reservation_user,
        });
    }

    openAppointmentAreYouSureModal(appointment_id, appointment_hub, appointment_date, appointment_time) {
        this.setState({
            appointmentAreYouSureModal: true,
            selected_appointment_id: appointment_id,
            selected_appointment_hub: appointment_hub,
            selected_appointment_date: appointment_date,
            selected_appointment_time: appointment_time,
        });
    }


    cancelAppointment(appointment_id) {
        this.setState({
            appointmentAreYouSureModal: false,
            selected_appointment_id: "",
            selected_appointment_hub: "",
            selected_appointment_time: "",
            selected_appointment_date: "",
        });
        const id = appointment_id
        const status = "Cancelled by User"
        const appointment = { id, status };
        this.props.userCancelAppointment(appointment);

    }

    cancelReservation = (meeting, id) => (e) => {
        const reservation_id = id
        const status = "Cancelled by User"
        const answer = { status, meeting };
        this.props.handleReservation(answer, reservation_id);
    }

    openShareReservationModal(hub_reservation) {
        let profile = JSON.parse(localStorage.getItem('urpfl'))[0]
        if (profile === []) {
            profile = this.props.data.profile[0]
        }
        this.props.getBuddies(profile.user);
        this.setState({
            shareReservationModal: true,
            hub_reservation_id: hub_reservation.id,
            hub_reservation_user: hub_reservation.user,
            hub_reservation_creator_username: hub_reservation.creator,
            hub_reservation_creator_profile_pic: hub_reservation.reservation_user_photo,
        });
        this.props.getHubReservtionShares(hub_reservation.id)
    }

    render() {
        const auth = this.props.auth
        let user = []
        if (JSON.parse(localStorage.getItem('ur'))) {
            user = JSON.parse(localStorage.getItem('ur')).id
        }
        const reservation_shares = [...this.props.hub_reservation_shares]

        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "meetings-lottie-appear" : "meetings-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                <DesktopNavbar data={{ profile: this.props.profile, sellected: "meetings", notifications_status: this.props.notifications_status }} />

                {this.state.appointmentAreYouSureModal ?
                    <Modal visible={this.state.appointmentAreYouSureModal} width="100%" height="30%" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                        <div class="approved-appointments-modal-box">
                            {!this.state.selected_appointment_date || !this.state.selected_appointment_time ?
                                <div class="approved-appointments-cancel-modal-title">
                                    Are you sure you want to cancel the request for appointment at <b>{this.state.selected_appointment_hub}</b>?
                                </div>
                                :
                                <div class="approved-appointments-cancel-modal-title">
                                    Are you sure you want to cancel the appointment at <b>{this.state.selected_appointment_hub}</b> at <b>{this.state.selected_appointment_date}</b> at <b>{this.state.selected_appointment_time}</b> o'clock?
                                </div>
                            }

                            <img
                                onClick={() => this.closeModal()}
                                class="approved-appointments-cancel-modal-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />

                            <div onClick={() => this.closeModal()} class="approved-appointments-cancel-modal-no">
                                No
                            </div>
                            <div
                                onClick={() => this.cancelAppointment(this.state.selected_appointment_id)}
                                class="approved-appointments-cancel-modal-yes">
                                Yes
                            </div>
                        </div>
                    </Modal>
                    :
                    null
                }

                {this.state.shareReservationModal ?
                    <Modal visible={this.state.shareReservationModal} effect="fadeInDown" width="100px" height="100px" onClickAway={() => this.closeModal()}>
                        <ShareReservationModal
                            searchBuddy={this.props.searchBuddy}
                            getBuddies={this.props.getBuddies}
                            shareHubReservation={this.props.shareHubReservation}
                            getHubReservtionShares={this.props.getHubReservtionShares}
                            getUserHubReservations={this.props.getUserHubReservations}

                            data={{
                                hub_reservation_id: this.state.hub_reservation_id,
                                hub_reservation_user: this.state.hub_reservation_user,
                                hub_reservation_creator_username: this.state.hub_reservation_creator_username,
                                hub_reservation_creator_profile_pic: this.state.hub_reservation_creator_profile_pic,
                                buddies: this.props.buddies,
                                hub_reservation_shares: reservation_shares,
                                profile: this.props.profile,
                                closeModal: this.closeModal.bind(this)
                            }}
                        />
                    </Modal>
                    :
                    null
                }



                {/* <div class={this.props.isLoading ? "meetings-section-disappear" : null}> */}
                <div>
                    <div id="page-scroll-up" class="meetings-total-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="hub-color">
                                    {/* <div class="container-fluid">
                                        <div class="row meeting-row">
                                            <div class="text-center">
                                                <h2 class="meeting">Reservations</h2>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="">
                                        <div class="col-xl-10 col-lg-12 meetings-page-body-right pb-5">
                                            <div id="page-scroll-up" class="meetings-page-mobile-navbar">
                                                Requests
                                            </div>


                                            <div class="col-xl-12 text-center meeting-hide-h3">
                                                <h3 class="text-blue-empty-meetings"><strong> Requests</strong></h3>
                                            </div>

                                            <br /><br /><br /><br />
                                            <div class="all-meetings-center-layout">
                                                <center>
                                                    {this.props.hub_reservations.length === 0 && this.props.user_appointments.length === 0 && !this.state.loading_time ?
                                                        <>
                                                            <div class="new-meeting-icon-position">
                                                                <Link to="/hub/request-reservation">


                                                                    {/* <div class="new-meeting-round-buttons">
                                                                        <img
                                                                            class="new-meeting-add-icon"
                                                                            src={require("../../../assets/meeting/svg/new-reservation.svg")}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div class="new-meeting-text-box">

                                                                        <h5 class="new-meeting-text text-center">
                                                                            Request a Reservation
                                                                        </h5>
                                                                    </div> */}
                                                                </Link>
                                                            </div>

                                                            <div>
                                                                <div class="hub-reservation-no-image">
                                                                    <img
                                                                        class="hub-reservation-no-created"
                                                                        src={require("../../../assets/meeting/svg/plain-reservation-icon.svg")}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div class="hub-reservation-no-text-title">
                                                                    No Requests
                                                                </div>
                                                                <p class="hub-reservation-no-text">
                                                                    When you request or make a Reservation or an Appointment through Hubst3r you'll see it here.
                                                                </p>
                                                            </div>
                                                        </>
                                                        :
                                                        <>

                                                            {this.props.hub_reservations.map(hub_reservation => (

                                                                <>
                                                                    <div class="reservation-pc-display">
                                                                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 item-col">


                                                                            <div class="item-of-table ">
                                                                                <div class="row">
                                                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w text-center">
                                                                                        <Link to={{ pathname: '/hub', state: { hub_id: hub_reservation.hub_id } }}>
                                                                                            {hub_reservation.hub_logo ?
                                                                                                <img
                                                                                                    class="m-t"
                                                                                                    src={hub_reservation.hub_logo}
                                                                                                    alt=""
                                                                                                />
                                                                                                :
                                                                                                <img
                                                                                                    class="m-t"
                                                                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                        </Link>
                                                                                        <br />
                                                                                        <strong class="d-b creator-name-color">{hub_reservation.hub_name}</strong>
                                                                                    </div>

                                                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w texttext-left">
                                                                                        <div class="hub_reservation-meeting-info">
                                                                                            <div>
                                                                                                Requested by:
                                                                                            </div>
                                                                                            <div>
                                                                                                Reservation day:
                                                                                            </div>
                                                                                            <div>
                                                                                                Reservation date:
                                                                                            </div>
                                                                                            <div>
                                                                                                At:
                                                                                            </div>
                                                                                            <div>
                                                                                                Persons:
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="hub_reservation-meeting-data">
                                                                                            <div>
                                                                                                <b>{hub_reservation.creator}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{hub_reservation.day}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{hub_reservation.date}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{hub_reservation.time}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{hub_reservation.persons}</b>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                    {hub_reservation.user === user && hub_reservation.status === "Pending" ?
                                                                                        <div
                                                                                            onTouchStart
                                                                                            onClick={() => this.openReservationAreYouSureModal(hub_reservation.id, hub_reservation.user)}
                                                                                            class="hub_reservation-cancel-reservation"
                                                                                        >
                                                                                            Cancel Reservation
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }



                                                                                    <Modal visible={this.state.reservationAreYouSureModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                                                                                        <AreYouSure
                                                                                            data={{
                                                                                                sellected: "cancel-reservation",
                                                                                                functionToRun: this.userCancelHubReservation(hub_reservation.id).bind(this),
                                                                                                closeModal: this.closeModal.bind(this)

                                                                                            }}
                                                                                        />
                                                                                    </Modal>



                                                                                    <div class="hub_reservation-meeting-status">
                                                                                        {hub_reservation.status === "Requested" ?
                                                                                            <div class="hub_reservation-meeting-requested">
                                                                                                Reservetion Requested
                                                                                            </div>
                                                                                            : hub_reservation.status === "Pending" ?
                                                                                                <div class="hub_reservation-meeting-approved">
                                                                                                    Reservation Approved
                                                                                                </div>

                                                                                                : hub_reservation.status === "Cancelled by User" ?
                                                                                                    <div class="hub_reservation-meeting-cancelled">
                                                                                                        Cancelled by {hub_reservation.creator}
                                                                                                    </div>
                                                                                                    : hub_reservation.status === "Cancelled by Hub" ?
                                                                                                        <div class="hub_reservation-meeting-cancelled">
                                                                                                            Cancelled by the Hub
                                                                                                        </div>
                                                                                                        : hub_reservation.status === "Declined by the Hub" ?
                                                                                                            <div class="hub_reservation-meeting-cancelled">
                                                                                                                Declined by the Hub
                                                                                                            </div>
                                                                                                            : hub_reservation.status === "Waiting List" ?
                                                                                                                <div class="hub_reservation-meeting-waiting">
                                                                                                                    On Waiting List
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                        }
                                                                                    </div>


                                                                                    <div
                                                                                        onClick={() => this.openShareReservationModal(hub_reservation)}
                                                                                        class="hub_reservation-meeting-shared"
                                                                                    >
                                                                                        <div class="hub_reservation-meeting-text">
                                                                                            Shared with:
                                                                                        </div>

                                                                                        {hub_reservation.user === user ?
                                                                                            <div class="hub_reservation-meeting-share-box">
                                                                                                <img
                                                                                                    class="hub_reservation-meeting-share-icon"
                                                                                                    src={require("../../../assets/meeting/svg/share.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        }

                                                                                        {hub_reservation.shared_with_persons === 0 ?
                                                                                            <div class="hub_reservation-meeting-text-persons">
                                                                                                Nobody yet
                                                                                            </div>
                                                                                            : hub_reservation.shared_with_persons === 1 ?
                                                                                                <>
                                                                                                    {/* <div class="hub_reservation-meeting-share-img-position">
                                                                                        <img
                                                                                            class="hub_reservation-meeting-share-img"
                                                                                            src={hub_reservation.last_person_shared_profile_pic}
                                                                                            alt=""
                                                                                        />
                                                                                    </div> */}
                                                                                                    <div class="hub_reservation-meeting-text-persons">
                                                                                                        {hub_reservation.last_person_shared}
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <div class="hub_reservation-meeting-text-persons">

                                                                                                    {hub_reservation.last_person_shared}
                                                                                                    <br />
                                                                                                    & {hub_reservation.shared_with_persons - 1} others
                                                                                                </div>

                                                                                        }
                                                                                    </div>


                                                                                    {/* 
                                                                    <div class=
                                                                        {hub_reservation.is_shared ?
                                                                            "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w text shared-hub-reservation-box text-left"
                                                                            :
                                                                            "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w text pre-shared-hub-reservation-box text-left"
                                                                        }>
                                                                        <strong class="day-layout">
                                                                            {hub_reservation.day}
                                                                        </strong>
                                                                        <span class="time-layout">
                                                                            {hub_reservation.time}
                                                                        </span>
                                                                        <p class="date-layout">
                                                                            {hub_reservation.date}
                                                                        </p>
                                                                    </div> */}


                                                                                    {/* <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w img-text">
                                                                        <img class="number-img" src={require("../../../assets/meeting/svg/Attending reservation.svg")} alt="" />
                                                                        <div class="numbers">
                                                                            <span>{hub_reservation.persons}</span>
                                                                        </div>
                                                                        <strong class="attending-position meetings-invitations-status-color">Persons</strong>
                                                                    </div> */}


                                                                                    {/* {hub_reservation.qr_code !== "" ?
                                                                        <div
                                                                            onClick={() => this.openReservationQrModal(hub_reservation.qr_code, hub_reservation.hub_name)}
                                                                            class="reservation-meetings-qr-position"
                                                                        >
                                                                            <div class="reservation-meetings-qr-text">
                                                                                Reservation's Qr Code
                                                                            </div>
                                                                            <div class="reservation-meetings-qr-button">
                                                                                <img
                                                                                    class="reservation-meetings-qr-img"
                                                                                    src={require("../../../assets/meeting/svg/Qr-code.svg")}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                        :
                                                                        null
                                                                    } */}
                                                                                    <br />
                                                                                    <br /><br />

                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <br />
                                                                        </div>
                                                                    </div>

                                                                    <div class="reservation-mobile-display">

                                                                        <div class="mobile-reservations-box">
                                                                            <div class="mobile-reservations-hub-name-box">
                                                                                {hub_reservation.hub_name}
                                                                            </div>

                                                                            <div class="mobile-reservations-hub-logo-box">
                                                                                <Link to={{ pathname: '/hub', state: { hub_id: hub_reservation.hub_id } }}>
                                                                                    {hub_reservation.hub_logo ?
                                                                                        <img
                                                                                            class="mobile-reservations-hub-logo"
                                                                                            src={hub_reservation.hub_logo}
                                                                                            alt=""
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            class="mobile-reservations-hub-logo"
                                                                                            src={require("../../../assets/authentication/svg/account.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    }
                                                                                </Link>
                                                                            </div>


                                                                            <div class="mobile-reservations-data-box">
                                                                                <div class="hub_reservation-meeting-info">
                                                                                    <div>
                                                                                        Requested by:
                                                                                    </div>
                                                                                    <div>
                                                                                        Day:
                                                                                    </div>
                                                                                    <div>
                                                                                        Date:
                                                                                    </div>
                                                                                    <div>
                                                                                        At:
                                                                                    </div>
                                                                                    <div>
                                                                                        Persons:
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="mobile-reservations-data-info-box">
                                                                                <div class="hub_reservation-meeting-data">
                                                                                    <div>
                                                                                        <b>{hub_reservation.creator}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{hub_reservation.day}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{hub_reservation.date}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{hub_reservation.time}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{hub_reservation.persons}</b>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="mobile-reservations-status">
                                                                                {hub_reservation.status === "Requested" ?
                                                                                    <div class="hub_reservation-meeting-requested">
                                                                                        Reservetion Requested
                                                                                    </div>
                                                                                    : hub_reservation.status === "Pending" ?
                                                                                        <div class="hub_reservation-meeting-approved">
                                                                                            Reservation Approved
                                                                                        </div>

                                                                                        : hub_reservation.status === "Cancelled by User" ?
                                                                                            <div class="hub_reservation-meeting-cancelled">
                                                                                                Cancelled by {hub_reservation.creator}
                                                                                            </div>
                                                                                            : hub_reservation.status === "Cancelled by Hub" ?
                                                                                                <div class="hub_reservation-meeting-cancelled">
                                                                                                    Cancelled by the Hub
                                                                                                </div>
                                                                                                : hub_reservation.status === "Declined by the Hub" ?
                                                                                                    <div class="hub_reservation-meeting-cancelled">
                                                                                                        Declined by the Hub
                                                                                                    </div>
                                                                                                    : hub_reservation.status === "Waiting List" ?
                                                                                                        <div class="hub_reservation-meeting-waiting">
                                                                                                            On Waiting List
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                }
                                                                            </div>


                                                                            <div
                                                                                onClick={() => this.openShareReservationModal(hub_reservation)}
                                                                                class="mobile-reservations-shared-with-box"
                                                                            >
                                                                                Shared with:

                                                                                {hub_reservation.shared_with_persons === 0 ?
                                                                                    <>
                                                                                        <div class="mobile-reservations-shared-persons">

                                                                                            Nobody yet
                                                                                        </div>
                                                                                    </>
                                                                                    : hub_reservation.shared_with_persons === 1 ?
                                                                                        <>
                                                                                            <div class="mobile-reservations-shared-profile-pic">
                                                                                                {hub_reservation.last_person_shared_profile_pic ?
                                                                                                    <img
                                                                                                        class="mobile-reservations-shared-profile-pic-img"
                                                                                                        src={hub_reservation.last_person_shared_profile_pic}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    :
                                                                                                    <img
                                                                                                        class="mobile-reservations-shared-profile-pic-img"

                                                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                }
                                                                                            </div>
                                                                                            <div class="mobile-reservations-shared-persons">
                                                                                                {hub_reservation.last_person_shared}
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div class="mobile-reservations-shared-profile-pic">

                                                                                                {hub_reservation.last_person_shared_profile_pic ?
                                                                                                    <img
                                                                                                        class="mobile-reservations-shared-profile-pic-img"
                                                                                                        src={hub_reservation.last_person_shared_profile_pic}
                                                                                                        alt=""
                                                                                                    />
                                                                                                    :
                                                                                                    <img
                                                                                                        class="mobile-reservations-shared-profile-pic-img"

                                                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                                                        alt=""
                                                                                                    />
                                                                                                }
                                                                                            </div>
                                                                                            <div class="mobile-reservations-shared-persons">
                                                                                                {hub_reservation.last_person_shared} & {hub_reservation.shared_with_persons - 1} others
                                                                                                {/* Nobody yet */}
                                                                                            </div>

                                                                                        </>

                                                                                }
                                                                            </div>

                                                                            <div class="mobile-reservations-btns">
                                                                                {hub_reservation.user === user ?
                                                                                    <>
                                                                                        {hub_reservation.status === "Pending" || hub_reservation.status === "Requested" ?
                                                                                            <>
                                                                                                <div
                                                                                                    onTouchStart
                                                                                                    onClick={() => this.openReservationAreYouSureModal(hub_reservation.id, hub_reservation.user)}
                                                                                                    class="mobile-reservations-cancel-reservation"
                                                                                                >
                                                                                                    Cancel Reservation
                                                                                                </div>
                                                                                                <Modal visible={this.state.reservationAreYouSureModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                                                                                                    <AreYouSure
                                                                                                        data={{
                                                                                                            sellected: "cancel-reservation",
                                                                                                            functionToRun: this.userCancelHubReservation(hub_reservation.id).bind(this),
                                                                                                            closeModal: this.closeModal.bind(this)

                                                                                                        }}
                                                                                                    />
                                                                                                </Modal>

                                                                                                <div
                                                                                                    onTouchStart
                                                                                                    onClick={() => this.openShareReservationModal(hub_reservation)}
                                                                                                    class="mobile-reservations-share-reservation"
                                                                                                >
                                                                                                    Share Reservation
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <div
                                                                                        onTouchStart
                                                                                        onClick={() => this.openShareReservationModal(hub_reservation)}
                                                                                        class="mobile-reservations-share-reservation-center"
                                                                                    >
                                                                                        Share Reservation
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>



                                                                    </div>


                                                                </>
                                                            ))}

                                                            {this.props.user_appointments.map(appointment => (

                                                                <>
                                                                    <div class="reservation-pc-display">
                                                                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 item-col">


                                                                            <div class="item-of-table ">
                                                                                <div class="row">
                                                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 full-w text-center">
                                                                                        <Link to={{ pathname: '/hub', state: { hub_id: appointment.hub_id } }}>
                                                                                            {appointment.hub_logo ?
                                                                                                <img
                                                                                                    class="m-t"
                                                                                                    src={appointment.hub_logo}
                                                                                                    alt=""
                                                                                                />
                                                                                                :
                                                                                                <img
                                                                                                    class="m-t"
                                                                                                    src={require("../../../assets/authentication/svg/account.svg")}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                        </Link>
                                                                                        <br />
                                                                                        <strong class="d-b creator-name-color">{appointment.hub_name}</strong>
                                                                                    </div>

                                                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 full-w texttext-left">
                                                                                        <div class="hub_reservation-meeting-info">
                                                                                            <div>
                                                                                                Requested by:
                                                                                            </div>
                                                                                            <div>
                                                                                                Reservation day:
                                                                                            </div>
                                                                                            <div>
                                                                                                Reservation date:
                                                                                            </div>
                                                                                            <div>
                                                                                                At:
                                                                                            </div>

                                                                                        </div>

                                                                                        <div class="hub_reservation-meeting-data">
                                                                                            <div>
                                                                                                <b>{appointment.creator}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{appointment.day}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{appointment.date}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{appointment.time}</b>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                    {appointment.user === user && appointment.status === "Pending" ?
                                                                                        <div
                                                                                            onTouchStart
                                                                                            onClick={() => this.openAppointmentAreYouSureModal(appointment.id, appointment.hub_name, appointment.date, appointment.time)}
                                                                                            class="hub_reservation-cancel-reservation"
                                                                                        >
                                                                                            Cancel Appointment
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }



                                                                                    <Modal visible={this.state.appointmentAreYouSureModal} width="100px" height="100px" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                                                                                        <div class="approved-appointments-modal-box">
                                                                                            {!this.state.selected_appointment_date || !this.state.selected_appointment_time ?
                                                                                                <div class="approved-appointments-cancel-modal-title">
                                                                                                    Are you sure you want to cancel the request for appointment at <b>{this.state.selected_appointment_hub}</b>?
                                                                                                </div>
                                                                                                :
                                                                                                <div class="approved-appointments-cancel-modal-title">
                                                                                                    Are you sure you want to cancel the appointment at <b>{this.state.selected_appointment_hub}</b> at <b>{this.state.selected_appointment_date}</b> at <b>{this.state.selected_appointment_time}</b> o'clock?
                                                                                                </div>
                                                                                            }

                                                                                            <img
                                                                                                onClick={() => this.closeModal()}
                                                                                                class="approved-appointments-cancel-modal-times"
                                                                                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                                                                                alt=""
                                                                                            />

                                                                                            <div onClick={() => this.closeModal()} class="approved-appointments-cancel-modal-no">
                                                                                                No
                                                                                            </div>
                                                                                            <div
                                                                                                onClick={() => this.cancelAppointment(this.state.selected_appointment_id)}
                                                                                                class="approved-appointments-cancel-modal-yes">
                                                                                                Yes
                                                                                            </div>
                                                                                        </div>
                                                                                    </Modal>



                                                                                    <div class="hub_reservation-meeting-status">
                                                                                        {appointment.status === "Requested" ?
                                                                                            <div class="hub_reservation-meeting-requested">
                                                                                                Appointment Requested
                                                                                            </div>
                                                                                            : appointment.status === "Pending" ?
                                                                                                <div class="hub_reservation-meeting-approved">
                                                                                                    Reservation Approved
                                                                                                </div>
                                                                                                : appointment.status === "Cancelled by User" ?
                                                                                                    <div class="hub_reservation-meeting-cancelled">
                                                                                                        Cancelled by {appointment.creator}
                                                                                                    </div>
                                                                                                    : appointment.status === "Cancelled by Hub" ?
                                                                                                        <div class="hub_reservation-meeting-cancelled">
                                                                                                            Cancelled by the Hub
                                                                                                        </div>
                                                                                                        : appointment.status === "Declined by the Hub" ?
                                                                                                            <div class="hub_reservation-meeting-cancelled">
                                                                                                                Declined by the Hub
                                                                                                            </div>
                                                                                                            : appointment.status === "Waiting List" ?
                                                                                                                <div class="hub_reservation-meeting-waiting">
                                                                                                                    On Waiting List
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                        }
                                                                                    </div>


                                                                                    <br />
                                                                                    <br /><br />

                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <br />
                                                                        </div>
                                                                    </div>

                                                                    <div class="reservation-mobile-display">

                                                                        <div class="mobile-reservations-box">
                                                                            <div class="mobile-reservations-hub-name-box">
                                                                                {appointment.hub_name}
                                                                            </div>

                                                                            <div class="mobile-reservations-hub-logo-box">
                                                                                <Link to={{ pathname: '/hub', state: { hub_id: appointment.hub_id } }}>
                                                                                    {appointment.hub_logo ?
                                                                                        <img
                                                                                            class="mobile-reservations-hub-logo"
                                                                                            src={appointment.hub_logo}
                                                                                            alt=""
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            class="mobile-reservations-hub-logo"
                                                                                            src={require("../../../assets/authentication/svg/account.svg")}
                                                                                            alt=""
                                                                                        />
                                                                                    }
                                                                                </Link>
                                                                            </div>


                                                                            <div class="mobile-reservations-data-box">
                                                                                <div class="hub_reservation-meeting-info">
                                                                                    <div>
                                                                                        Requested by:
                                                                                    </div>
                                                                                    <div>
                                                                                        Day:
                                                                                    </div>
                                                                                    <div>
                                                                                        Date:
                                                                                    </div>
                                                                                    <div>
                                                                                        At:
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="mobile-reservations-data-info-box">
                                                                                <div class="hub_reservation-meeting-data">
                                                                                    <div>
                                                                                        <b>{appointment.creator}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{appointment.day}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{appointment.date}</b>
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{appointment.time}</b>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="mobile-reservations-status">
                                                                                {appointment.status === "Requested" ?
                                                                                    <div class="hub_reservation-meeting-requested">
                                                                                        Appointment Requested
                                                                                    </div>
                                                                                    : appointment.status === "Pending" ?
                                                                                        <div class="hub_reservation-meeting-approved">
                                                                                            Appointment Approved
                                                                                        </div>

                                                                                        : appointment.status === "Cancelled by User" ?
                                                                                            <div class="hub_reservation-meeting-cancelled">
                                                                                                Cancelled by {appointment.creator}
                                                                                            </div>
                                                                                            : appointment.status === "Cancelled by Hub" ?
                                                                                                <div class="hub_reservation-meeting-cancelled">
                                                                                                    Cancelled by the Hub
                                                                                                </div>
                                                                                                : appointment.status === "Declined by the Hub" ?
                                                                                                    <div class="hub_reservation-meeting-cancelled">
                                                                                                        Declined by the Hub
                                                                                                    </div>
                                                                                                    : appointment.status === "Waiting List" ?
                                                                                                        <div class="hub_reservation-meeting-waiting">
                                                                                                            On Waiting List
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                }
                                                                            </div>

                                                                            <div class="mobile-reservations-btns">
                                                                                <>
                                                                                    {appointment.status === "Pending" || appointment.status === "Requested" ?
                                                                                        <>
                                                                                            <div
                                                                                                onTouchStart
                                                                                                onClick={() => this.openAppointmentAreYouSureModal(appointment.id, appointment.hub_name, appointment.date, appointment.time)}
                                                                                                class="mobile-reservations-cancel-reservation"
                                                                                            >
                                                                                                Cancel Appointment
                                                                                            </div>

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </>

                                                                            </div>

                                                                        </div>



                                                                    </div>


                                                                </>
                                                            ))}
                                                        </>
                                                    }
                                                </center>
                                            </div>


                                            <br /><br /><br /><br /><br /><br />
                                            <GdprLinks />


                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    <BottomNavbar data={{ sellected: "meetings", notifications_status: this.props.notifications_status }} />
                </ScrollLink>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in meetings.js
    auth: state.auth,
    profile: state.profile.profile,
    buddies: state.buddies.buddies,
    meetings: state.meeting.meetings,
    cancellations: state.meeting.cancellations,
    reservations: state.meeting.reservations,
    hub_reservations: state.reservation.hub_reservations,
    user_appointments: state.appointment.user_appointments,
    hub_reservation_shares: state.reservation.hub_reservation_shares,
    invitations: state.meeting.invitations,
    activities: state.hub.activities,
    hub: state.hub.hub,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet,

});

export default connect(mapStateToProps, {
    getPendingMeetings,
    getUserAllCancelledHubReservations,
    getBuddies,
    searchBuddy,
    getMyProfile,
    getCancelledMeetings,
    getReservationMeetings,
    getMeetingInvitations,
    getUserHubReservations,
    getHubReservtionShares,
    userCancelHubReservation,
    handleInvitation,
    cancelMeeting,
    updateMeeting,
    requestReservation,
    shareHubReservation,
    getHubActivities,
    getHubForReservationRequest,
    getNotificationsStatus,
    createAlert,
    cancelReservationMeeting,
    createInvitation,
    getUserAppointments,
    userCancelAppointment
})(Meetings);