import {
    GET_SEARCH_USERS,
    SEARCH_USERS_LOCALLY_LOADED,
    GET_BUDDIES,
    BUDDIES_LOCALLY_LOADED,
    GET_GAGS,
    GET_GAG_MEMBERS,
    DELETE_GAG,
    GAGS_LOCALLY_LOADED,
    CREATE_BUDDY_REQUEST,
    ANSWER_BUDDY_REQUEST,
    CANCEL_BUDDY_REQUEST,
    GET_SEARCHED_USERS_QUERY,
    GET_SEARCHED_BUDDY_QUERY,
    CLEAR_BUDDIES,
    REMOVE_BUDDY,
    CLEAR_SEARCH_USERS,
} from '../actions/actionTypes';
// import { updateObject } from "../utility";

const initialState = {
    buddies: [],
    users: [],
    gags: [],
    members: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_BUDDIES:
            return {
                ...state,
                buddies: [],
                users: [],
                gags: [],
                members: []
            };
        case GET_SEARCH_USERS:
            localStorage.setItem('shus', JSON.stringify(action.payload));
            return {
                ...state,
                users: action.payload
            };
        case SEARCH_USERS_LOCALLY_LOADED:
            return {
                ...state,
                users: JSON.parse(localStorage.getItem('shus'))
            };
        case GET_SEARCHED_USERS_QUERY:
            return {
                ...state,
                users: action.payload
            };
        case GET_BUDDIES:
            localStorage.setItem('aub', JSON.stringify(action.payload));
            return {
                ...state,
                buddies: action.payload
            };
        case BUDDIES_LOCALLY_LOADED:
            return {
                ...state,
                buddies: JSON.parse(localStorage.getItem('aub'))
            };
        case GET_SEARCHED_BUDDY_QUERY:
            return {
                ...state,
                buddies: action.payload
            };
        case GET_GAGS:
            localStorage.setItem('ug', JSON.stringify(action.payload));
            return {
                ...state,
                gags: action.payload
            };
        case GAGS_LOCALLY_LOADED:
            return {
                ...state,
                gags: JSON.parse(localStorage.getItem('ug'))
            };
        case GET_GAG_MEMBERS:
            return {
                ...state,
                members: action.payload
            };
        case DELETE_GAG:
            return {
                ...state,
                gags: state.gags.filter(gag => gag.id !== action.payload)
            };
        case CREATE_BUDDY_REQUEST:
            return {
                ...state,
                ...action.payload
            };
        case ANSWER_BUDDY_REQUEST:
            return {
                ...state,
                ...action.payload
            };
        case CANCEL_BUDDY_REQUEST:
            return {
                ...state,
                ...action.payload
            };

        case CLEAR_SEARCH_USERS:
            return {
                ...state,
                users: []
            };

        default:
            return state;
    }
}