import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { getNotifications, getNotificationsStatus, deleteNotification } from '../../../store/actions/notification'
import { answerBuddyRequest } from '../../../store/actions/buddies'
import { handleInvitation } from '../../../store/actions/meeting'
import {
    acceptMemoryToProfile,
    declineMemoryToProfile,
} from '../../../store/actions/memory'

import DesktopNavbar from '../../../components/user/layout/desktopNavbar';
import BottomNavbar from '../../../components/user/layout/bottomNavbar';
import Hubst3rLottie from '../../../components/user/layout/Hubst3rLottie';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
// import notification components
import BuddyAccepted from '../../../components/user/notifications/BuddyAccepted';
import BuddyInvitationAccepted from '../../../components/user/notifications/BuddyInvitationAccepted';
import BuddyInvitationDeclined from '../../../components/user/notifications/BuddyInvitationDeclined';
import BuddyRequest from '../../../components/user/notifications/BuddyRequest';
import MeetingCancelled from '../../../components/user/notifications/MeetingCancelled';
import MeetingInvitation from '../../../components/user/notifications/MeetingInvitation';
import MyInvitationAccepted from '../../../components/user/notifications/MyInvitationAccepted';
import MyInvitationDeclined from '../../../components/user/notifications/MyInvitationDeclined';
import ReservationAcceptance from '../../../components/user/notifications/ReservationAcceptance';
import ReservationDeclined from '../../../components/user/notifications/ReservationDeclined';
import TopMemory from '../../../components/user/notifications/TopMemory';
import HubReservationMade from '../../../components/user/notifications/HubReservationMade';
import ShareReservation from '../../../components/user/notifications/ShareReservation';
import HubReservationCancelled from '../../../components/user/notifications/HubReservationCancelled';
import AddedToGang from '../../../components/user/notifications/AddedToGang';
import BecameBuddies from '../../../components/user/notifications/BecameBuddies';
import MeetingUpdated from '../../../components/user/notifications/MeetingUpdated';
import InvitedAnother from '../../../components/user/notifications/InvitetedAnother';
import MemoryImpression from '../../../components/user/notifications/MemoryImpression';
import HubReservationRequested from '../../../components/user/notifications/HubReservationRequested';
import HubReservationCancelledByHub from '../../../components/user/notifications/HubReservationCancelledByHub';
import ReservationNotificationForHub from '../../../components/user/notifications/ReservationNotificationForHub';

import AppointmentAccepted from '../../../components/user/notifications/AppointmentAccepted';
import AppointmentDeclined from '../../../components/user/notifications/AppointmentDeclined';
import AppointmentRequested from '../../../components/user/notifications/AppointmentRequested';
import AppointmentNotificationForHub from '../../../components/user/notifications/AppointmentNotificationForHub';
import AppointmentCancelled from '../../../components/user/notifications/AppointmentCancelled';
import AppointmentCancelledByUser from '../../../components/user/notifications/AppointmentCancelledByUser';
import AppointmentCancelledByYou from '../../../components/user/notifications/AppointmentCancelledByYou';



// css
import "../../../assets/notifications/user-notifications.css"
import { MemoryTag } from '../../../components/user/notifications/MemoryTag';




export class Notifications extends Component {

    state = {
        loading_time: true,
    }

    componentDidMount() {
        this.props.getNotifications();
        setTimeout(() => {
            this.setState({
                loading_time: false,
            })
        }, 2000);
    }


    render() {
        const auth = this.props.auth
        return (
            <Fragment>
                {/* <div class={this.props.isLoading ? "notifications-lottie-appear" : "notifications-lottie-dissappear"}>
                    <Hubst3rLottie />
                </div> */}
                {/* <div class={this.props.isLoading ? "notifications-section-disappear" : null}> */}
                <div>
                    <div id="page-scroll-up" class="notification-whole-page-layout">
                        <OfflineLabel />
                        <div class="container-fluid">
                            <div class="row color-9">
                                <DesktopNavbar data={{ profile: this.props.profile, sellected: "notification", notifications_status: this.props.notifications_status }} />


                                <div class="container-fluid small-header-container-9">
                                    <div class="small-header-9">

                                        <div class="title-font-layout">
                                            {/* Notifications */}
                                        </div>
                                    </div>
                                </div>

                                <div id="page-scroll-up" class="notifications-mobile-navbar">
                                    Notifications
                                </div>


                                <div class="col-xl-10 col-lg-12 body-right-9 pb-5">
                                    <div class="row">
                                        <div class="col-xl-12 text-center notifications-hide-h3">
                                            <h3 class="text-blue-9 my-3"><strong>Notifications</strong></h3>
                                        </div>
                                    </div>

                                    {this.props.notifications.length === 0 && !this.state.loading_time ?
                                        <div class="notification-no-notification">
                                            <div class="notification-no-notification-icon-box">
                                                <img
                                                    class="notification-no-notification-icon"
                                                    // src={require("../../../assets/notifications/svg/no-notification.svg")}
                                                    src={require("../../../assets/notifications/svg/notification-red.svg")}
                                                    alt=""
                                                />
                                            </div>
                                            <div class="notification-no-notification-text">
                                                <b>No Notifications</b><br />
                                                You will be notified<br />about everything here!
                                            </div>
                                        </div>
                                        :
                                        <div class="all-notifications-layout">
                                            <div class="notification-row-position">
                                                {this.props.notifications.map(notification => (
                                                    <div class="notifications-box-size">

                                                        {notification.subject === "Buddy Request" ?
                                                            <BuddyRequest data={{ notification: notification }} answerBuddyRequest={this.props.answerBuddyRequest} deleteNotification={this.props.deleteNotification} />
                                                            : notification.subject === "Reservation Declined" ?
                                                                <ReservationDeclined data={{ notification: notification }} />
                                                                : notification.subject === "Invited to Meeting" ?
                                                                    <MeetingInvitation data={{ notification: notification }} handleInvitation={this.props.handleInvitation} deleteNotification={this.props.deleteNotification} />
                                                                    : notification.subject === "Reservation Accepted" ?
                                                                        <ReservationAcceptance data={{ notification: notification }} />
                                                                        : notification.subject === "Accepted my Invitation" ?
                                                                            <MyInvitationAccepted data={{ notification: notification }} />
                                                                            : notification.subject === "Accepted Buddy Invitation" ?
                                                                                <BuddyInvitationAccepted data={{ notification: notification }} />
                                                                                : notification.subject === "Declined my Invitation" ?
                                                                                    <MyInvitationDeclined data={{ notification: notification }} />
                                                                                    : notification.subject === "Declined Buddy Invitation" ?
                                                                                        <BuddyInvitationDeclined data={{ notification: notification }} />
                                                                                        : notification.subject === "Top Memory" ?
                                                                                            <TopMemory data={{ notification: notification }} />
                                                                                            : notification.subject === "Buddy Acceptance" ?
                                                                                                <BuddyAccepted data={{ notification: notification }} />
                                                                                                : notification.subject === "Meeting Cancelled" ?
                                                                                                    <MeetingCancelled data={{ notification: notification }} />
                                                                                                    : notification.subject === "Hub Reservation Made" ?
                                                                                                        <HubReservationMade data={{ notification: notification }} />
                                                                                                        : notification.subject === "Share Reservation" ?
                                                                                                            <ShareReservation data={{ notification: notification }} />
                                                                                                            : notification.subject === "Hub Reservation Cancelled" ?
                                                                                                                <HubReservationCancelled />
                                                                                                                : notification.subject === "Added to Gang" ?
                                                                                                                    <AddedToGang data={{ notification: notification }} />
                                                                                                                    : notification.subject === "Became Buddies" ?
                                                                                                                        <BecameBuddies data={{ notification: notification }} />
                                                                                                                        : notification.subject === "Meeting Updated" ?
                                                                                                                            <MeetingUpdated data={{ notification: notification }} />
                                                                                                                            : notification.subject === "Invited another to Meeting" ?
                                                                                                                                <InvitedAnother data={{ notification: notification }} />
                                                                                                                                : notification.subject === "Memory Impression" ?
                                                                                                                                    <MemoryImpression data={{ notification: notification }} />
                                                                                                                                    : notification.subject === "Memory Tag" ?
                                                                                                                                        <MemoryTag
                                                                                                                                            data={{ notification: notification }}
                                                                                                                                            acceptMemoryToProfile={this.props.acceptMemoryToProfile}
                                                                                                                                            declineMemoryToProfile={this.props.declineMemoryToProfile}
                                                                                                                                        />
                                                                                                                                        : notification.subject === "Hub Reservation Requested" ?
                                                                                                                                            <HubReservationRequested data={{ notification: notification }} />
                                                                                                                                            : notification.subject === "Reservation Notification for Hub" ?
                                                                                                                                                <ReservationNotificationForHub data={{ notification: notification }} />
                                                                                                                                                : notification.subject === "Hub Reservation Cancelled by Hub" ?
                                                                                                                                                    <HubReservationCancelledByHub data={{ notification: notification }} />
                                                                                                                                                    : notification.subject === "Appointment Accepted" ?
                                                                                                                                                        <AppointmentAccepted data={{ notification: notification }} />
                                                                                                                                                        : notification.subject === "Appointment Declined" ?
                                                                                                                                                            <AppointmentDeclined data={{ notification: notification }} />
                                                                                                                                                            : notification.subject === "Appointment Requested" ?
                                                                                                                                                                <AppointmentRequested data={{ notification: notification }} />
                                                                                                                                                                : notification.subject === "Appointment Notification for Hub" ?
                                                                                                                                                                    <AppointmentNotificationForHub data={{ notification: notification }} />
                                                                                                                                                                    : notification.subject === "Appointment Cancelled" ?
                                                                                                                                                                        <AppointmentCancelled data={{ notification: notification }} />
                                                                                                                                                                        : notification.subject === "Appointment Cancelled By User" ?
                                                                                                                                                                            <AppointmentCancelledByUser data={{ notification: notification }} />
                                                                                                                                                                            : notification.subject === "Appointment Cancelled By You" ?
                                                                                                                                                                                <AppointmentCancelledByYou data={{ notification: notification }} />

                                                                                                                                                                                :
                                                                                                                                                                                null
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {/* </div> */}
                                    <br /><br /><br /><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollLink activeClass="active" to="page-scroll-up" spy={true} smooth={true}>
                    <BottomNavbar data={{ sellected: "notifications", notifications_status: this.props.notifications_status }} />
                </ScrollLink>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    //the states in reducers  in index.js 'dot'  in events.js
    auth: state.auth,
    notifications: state.notification.notifications,
    notifications_status: state.notification.notifications_status,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    getNotifications,
    answerBuddyRequest,
    handleInvitation,
    getNotificationsStatus,
    deleteNotification,
    acceptMemoryToProfile,
    declineMemoryToProfile,
})(Notifications);