import React, { Fragment, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import OfflineLabel from '../../../components/user/layout/offlineLabel';
import {arrayItems} from '../../../components/scarlett/ScarlettPage/AIOptions';
import SpeechToText from '../../../components/user/chat/speechToText';
import { SitePal } from 'sitepal-react';  
import "../../../assets/scarlett/scarlettPage.css"
import Modal from 'react-awesome-modal';
import {
    askScarlet,
    getScarlettSessionMessages,
    createMessageWithScarlett,
} from '../../../store/actions/scarlett';
import { getMyProfile } from '../../../store/actions/profile';

import ScarlettSitepal from '../../../components/scarlett/ScarlettPage/ScarlettSidepal';
import TextareaAutosize from 'react-textarea-autosize';
import { Link as ScrollLink } from 'react-scroll';


export class ScarletPage extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            option:"",
            result:"",
            input:"",
            lastQuestion:"",
            talkingText:"",
            instroductionModal:false,
            // talking:false,
            question:"",
        };
    }

    componentDidMount() {
        localStorage.setItem('spchttxtrcvd', JSON.stringify(false));
        this.props.getScarlettSessionMessages()
        this.props.getMyProfile()
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        const srolRef = this.myRef
        if (this.myRef !== undefined || this.myRef !== null) {
            try {
                srolRef.scrollIntoView()
            } catch (e) {
                console.error(e);
            }
        }
    };

    closeModal() {
        this.setState({
            instroductionModal: false,
        })
    }

    setSpeechToTextResults = (e) => (question) => {        
        
        if (question.toLowerCase().includes("scarlett")||question.toLowerCase().includes("scarlett")){
            this.props.askScarlet(this.state.question)
            const scarlet_speaking = false
            const text_message = this.state.question
            const messageToScarlett = {scarlet_speaking, text_message}
            this.props.createMessageWithScarlett(messageToScarlett)
        }     
    }

    setSpeechToTextFinalResults = (e) => (question) => {
        this.setState({question:question})  
    }

    receivedChatAnswer = (e) => (answer) => { 
        const scarlet_speaking = true
        const text_message = answer
        const messageToScarlett = {scarlet_speaking, text_message}
        this.props.createMessageWithScarlett(messageToScarlett) 
    }

    // clickTalkButton(){
    //     this.setState({
    //         talking:true,
    //     })
    // }

    // clickFinishTalking(){
    //     this.setState({
    //         talking:false,
    //     })
    // }

    sendTheMessageToScarlett(){
        this.props.askScarlet(this.state.question)
        const scarlet_speaking = false
        const text_message = this.state.question
        const messageToScarlett = {scarlet_speaking, text_message}
        this.props.createMessageWithScarlett(messageToScarlett)
        this.setState({
            question:""
        })
    }

    onChange = e => { this.setState({ [e.target.name]: e.target.value }) }


    renderMessages = messages => {
        const { auth } = this.props
        const my_profile = this.props.profile
        if (my_profile.length === 1) {
            return (
                <>
                    {messages.map((message, i, arr) => (
                        <li
                            key={message.id}
                            class="row scarlett-message-item-box"
                            style={{ marginBottom: arr.length - 1 === i ? "100px" : "15px" }}
                        >
                            <div class="scarlett-message-row-correction">
                                {!message.scarlet_speaking ?
                                    <div />
                                    :
                                    <div class="scarlett-conversator-profile-pic-box">
                                        <img
                                            class="scarlett-conversator-profile-pic"
                                            src={require("../../../assets/scarlett/svg/scrlett-profile-pic.jpg")}
                                            alt=""
                                        />
                                    </div>
                                }
                                <div 
                                    class= {message.scarlet_speaking ?
                                                "scarlett-conversation-text-message scarlett-message-color-blue"
                                            :
                                                "conversation-room-conversation-my-text-message conversation-room-message-color-white"
                                            }
                                >
                                    <div class="scarlett-text-braek">
                                        {message.text_message}
                                    </div>
                                </div>
                            </div>
                        </li >
                    ))}
                </>

            )
        }
    };



    render() {
        return (
            <Fragment>
                <div class="whole-shop-produc-stock-page-layout">
 
                    {this.state.instroductionModal ?
                        <Modal visible={this.state.instroductionModal} width="100%" height="55%" effect="fadeInUp" onClickAway={() => this.closeModal()} >
                            <div class="">
                                <img
                                    onClick={() => this.closeModal()}
                                    class="scarlett-introduction-modal-times"
                                    src={require("../../../assets/layout/homepage svg/times.svg")}
                                    alt=""
                                />
                                <div class="scarlett--introduction-modal-title">
                                    Meet Scarlett
                                </div>

                                <div class="scarlett-introduction-modal-text">
                                    Scarlett can help you by being your virtual assistant that can help you
                                    with tasks such as setting reminders or providing answers to 
                                    frequently asked questions. Scarlett can answer questions about 
                                    general topics such as weather, sports, news, entertainment, and more. 
                                    You can even ask her for ideas about gifts, cars, receipes, creations, messages, emails and more
                                    Can also summerize every topic you can imagine
                                    She can even write you a poem or a song if you want to!
                                </div>
                            </div>
                        </Modal>
                        :
                        null
                    }

                    <ScarlettSitepal
                        scarlett_answer={this.props.scarlett_answer}
                        receivedChatAnswer={this.receivedChatAnswer()}
                    />

                    <div class="scarlett-chatting-box-box">
                        <div class="scarlett-mobile-navbar">
                            Chat with Scarlett                
                        </div>


                        <div className="scarlett-conversation-all-message-items-box">
                            <ul id="chat-log">
                                {this.props.session_messages && this.renderMessages(this.props.session_messages)}
                                <div
                                    style={{ float: "left", clear: "both" }}
                                    ref={(ref) => this.myRef = ref}
                                />
                            </ul>
                        </div>



                        <div id="field-to-show" class="scarlett-type-message-bar">
                            <ScrollLink activeClass="active" to="field-to-show" spy={true} smooth={true}>
                                <TextareaAutosize
                                    minRows={1}
                                    maxRows={1}
                                    className="scarlet-new-message-input-field"
                                    name="question"
                                    onChange={this.onChange}
                                    placeholder="Talk to Scarlett..."
                                    value={this.state.question}
                                    autofocus
                                    id="mytext"
                                />
                            </ScrollLink>

                            {this.state.question.length === 0 ?
                                <div class="scarlett-input-send-grey" >
                                    Send
                                </div>
                                :
                                <div 
                                onClick={()=>this.sendTheMessageToScarlett()} 
                                class="scarlett-input-send" >
                                    Send
                                </div>
                            }
                        </div>    
                    </div>

                    <SpeechToText
                        setSpeechToTextResults={this.setSpeechToTextResults()}
                        setSpeechToTextFinalResults={this.setSpeechToTextFinalResults()}
                    />

                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    scarlett_answer:state.scarlett.scarlett_answer,
    session_messages:state.scarlett.session_messages,
    profile: state.profile.profile,
    isLoading: state.layout.isLoading,
    noInternet: state.layout.noInternet
});

export default connect(mapStateToProps, {
    askScarlet,
    getScarlettSessionMessages,
    getMyProfile,
    createMessageWithScarlett,
})(ScarletPage);