import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import "../../../assets/chat/send-message-modal.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class SendMessageModal extends Component {

    state = {
        buddy_search: "",
        sending_to: "",
        sent_to: []
    }

    closeModal = () => {
        this.props.data.closeModal()
        this.setState({
            sending_to: "",
            sent_to: []
        })
    }

    onChange = e => {
        if (e.target.name === "buddy_search") {
            if (e.target.value === "") {
                this.setState({ [e.target.name]: e.target.value })
                const profile = this.props.data.profile[0]
                this.props.getBuddies(profile.user)
            } else {
                const query = e.target.value
                if (query.length > 2) {
                    if (query.toLowerCase() !== "ad") {
                        this.props.searchBuddy(query)
                    }
                    this.setState({ [e.target.name]: e.target.value })
                }
            }
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    sendMessageToBuddy(buddy_user) {
        const profile = this.props.data.profile[0]
        const sending_user = profile.user
        const recieving_user = buddy_user
        const voice_message = this.props.data.audioFile
        const text_message = this.props.data.text_message_file
        const message = { sending_user, recieving_user, voice_message, text_message }
        this.props.sendVoiceMessage(message)
        this.updateSentToList(buddy_user)
    }

    updateSentToList(buddy_user) {
        // e.preventDefault();
        const sent_to_list = [...this.state.sent_to]
        sent_to_list.push(buddy_user)
        this.setState({ sent_to: sent_to_list })
    }

    render() {
        const buddiesSettings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            swipeToSlide: true,
        };

        return (
            <Fragment>
                <div class="">
                    <div class="row">
                        <div class="col-lg-3 col-md-1 col-sm-2 col-2"></div>
                        <div class="send-message-box">
                            <img
                                onClick={() => this.closeModal()}
                                class="send-message-times"
                                src={require("../../../assets/layout/homepage svg/times.svg")}
                                alt=""
                            />
                            <div class="send-message-buddy-name">
                                Who to send the Message to ?
                            </div>
                            <div class="send-message-title-hr">
                                <hr />
                            </div>


                            <div className="send-message-search-box">
                                <div class="send-message-search-icon-box">
                                    <img
                                        class="send-message-search-icon-img"
                                        src={require("../../../assets/gags/svg/Icon.svg")}
                                        alt=""
                                    />
                                </div>
                                <input
                                    className="send-message-search-field"
                                    type="search"
                                    name="buddy_search"
                                    onChange={this.onChange}
                                    placeholder="Search Buddy"
                                    value={this.state.buddy_search}
                                    aria-label="Search"
                                />
                            </div>



                            <div class="send-message-all-choises">

                                <div class="send-message-slider-layout">
                                    <Slider {...buddiesSettings} >
                                        {this.props.data.buddies.map(buddy => (
                                            <div key={buddy.id}>
                                                <div class="send-message-slider-frame" >
                                                    {this.state.sent_to.includes(buddy.user) ?
                                                        <div class="send-message-sent-box">
                                                            <div class="send-message-buddy-username">
                                                                Sent to
                                                            </div>
                                                            <div class="send-message-sent-profile-pic-box" >
                                                                {buddy.profile_picture ?
                                                                    <img
                                                                        class="send-message-profile-pic-img"
                                                                        src={buddy.profile_picture} alt=""
                                                                    />
                                                                    :
                                                                    <img
                                                                        class="send-message-profile-pic-img"
                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                        alt=""
                                                                    />
                                                                }
                                                            </div>
                                                            <div class="send-message-buddy-username">
                                                                <b>{buddy.username}</b>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div onClick={() => this.sendMessageToBuddy(buddy.user)} class="send-message-profile-pic-box" >
                                                                {buddy.profile_picture ?
                                                                    <img
                                                                        class="send-message-profile-pic-img"
                                                                        src={buddy.profile_picture} alt=""
                                                                    />
                                                                    :
                                                                    <img
                                                                        class="send-message-profile-pic-img"
                                                                        src={require("../../../assets/authentication/svg/account.svg")}
                                                                        alt=""
                                                                    />
                                                                }
                                                            </div>
                                                            <div onClick={() => this.sendMessageToBuddy(buddy.user)} class="send-message-buddy-username">
                                                                <b>{buddy.username}</b>
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                        ))}
                                    </Slider>
                                </div>


                            </div>


                        </div>
                    </div>

                </div>
            </Fragment >
        )
    }
}


export default (SendMessageModal);