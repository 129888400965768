import {
    GET_MAP_NEARBY_PLACES,
    CREATE_HUB_PROSPECT,
    GET_MAP_MEMORIES,
    GET_MAP_USER_WITH_MEMORIES,
    UPLOAD_MEMORY,
    UPLOADING_MEMORY,
    FINISH_UPLOADING_MEMORY,
    DELETE_MEMORY,
    GET_NEW_CREATED_HUB,
    HUB_FETCHED_MARKERS_CREATED
} from '../actions/actionTypes';
import { updateObject } from "../utility";


const initialState = {
    map_places: [],
    map_memories: [],
    user_with_memories: [],
    uploading_memory: false,
    new_hubs_fetched: false,
    new_fetched_hubs: [],
    old_map_places: [],
    new_hubs_ids: [],
}

export default function (state = initialState, action) {
    switch (action.type) {

        case HUB_FETCHED_MARKERS_CREATED:
            return {
                ...state,
                new_hubs_fetched: false,
            };



        // case GET_MAP_NEARBY_PLACES:

        //     const previous_appearing_hubs = [...state.map_places]
        //     const requested_places = action.payload
        //     let new_hubs_fetched = false

        //     if (state.map_places.length === 0) {
        //         new_hubs_fetched = false
        //     } else {
        //         new_hubs_fetched = true
        //     }

        //     const requested_places_ids = []
        //     for (const new_hb of requested_places) {
        //         requested_places_ids.push(new_hb.id)
        //     }

        //     return {
        //         ...state,
        //         map_places: [...requested_places, ...state.map_places],
        //         new_hubs_fetched: new_hubs_fetched,
        //         new_fetched_hubs: action.payload,
        //         old_map_places: previous_appearing_hubs,
        //         new_hubs_ids: requested_places_ids
        //     };


        case GET_MAP_NEARBY_PLACES:
            const requested_places = action.payload
            let new_hubs_fetched = false

            if (state.map_places.length === 0) {
                new_hubs_fetched = false
            } else {
                new_hubs_fetched = true
            }


            // let all_map_places = [...requested_places, ...state.map_places[:100]]
            let all_map_places = [...requested_places]
            let obj = {};

            for (let i = 0, len = all_map_places.length; i < len; i++) {
                obj[all_map_places[i]['id']] = all_map_places[i];
            }

            let only_new_fetched = []
            for (const new_hb of action.payload) {
                if (!state.map_places.includes(new_hb)) {
                    only_new_fetched.push(new_hb)
                }
            }

            all_map_places = new Array();

            for (const key in obj) {
                all_map_places.push(obj[key]);
            }
            return {
                ...state,
                map_places: all_map_places,
                new_hubs_fetched: new_hubs_fetched,
                new_fetched_hubs: only_new_fetched,
            };


        case GET_NEW_CREATED_HUB:
            const new_places = action.payload
            let new_all_map_places = [...new_places, ...state.map_places]
            let new_obj = {};

            for (let i = 0, len = new_all_map_places.length; i < len; i++) {
                new_obj[new_all_map_places[i]['id']] = new_all_map_places[i];
            }

            new_all_map_places = new Array();

            for (const key in new_obj) {
                new_all_map_places.push(new_obj[key]);
            }
            return {
                ...state,
                map_places: new_all_map_places,
            };
        case GET_MAP_MEMORIES:
            return {
                ...state,
                map_memories: action.payload,
            };

        case UPLOAD_MEMORY:
            return {
                ...state,
                map_memories: [action.payload, ...state.map_memories]
            };
        case GET_MAP_USER_WITH_MEMORIES:
            return {
                ...state,
                user_with_memories: action.payload,
            };
        case UPLOADING_MEMORY:
            return {
                ...state,
                uploading_memory: true,
            };
        case FINISH_UPLOADING_MEMORY:
            return {
                ...state,
                uploading_memory: false,
            };
        case DELETE_MEMORY:
            return {
                ...state,
                map_memories: state.map_memories.filter(memory => memory.id !== action.payload),
            };

        default:
            return state;
    }
}