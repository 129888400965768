import {
    GET_CONVERSATIONS,
    CONVERSATIONS_LOCALY_LOADED,
    GET_THE_CONVERSATION,
    GET_CONVERSATION_MESSAGES,
    UPDATE_CONVERSATION_BACKGROUND,
    UPDATE_CONVERSATION_MY_MESSAGES_COLOR,
    LISTENED_THE_MESSAGE,
    CREATE_NEW_MESSAGE,
    CLEAR_MESSAGES,
} from '../actions/actionTypes';

const initialState = {
    conversations: [],
    conversation: [],
    conversation_messages: [],
}


export default function (state = initialState, action) {
    switch (action.type) {

        case GET_CONVERSATIONS:
            localStorage.setItem('cnvrstns', JSON.stringify(action.payload));
            return {
                ...state,
                conversations: action.payload
            };
        case CONVERSATIONS_LOCALY_LOADED:
            return {
                ...state,
                conversations: JSON.parse(localStorage.getItem('cnvrstns'))
            };

        case GET_THE_CONVERSATION:
            return {
                ...state,
                conversation: action.payload
            };

        case GET_CONVERSATION_MESSAGES:
            return {
                ...state,
                conversation_messages: action.payload.reverse()
            };
        case LISTENED_THE_MESSAGE:
            const updated_message = [action.payload]
            return {
                ...state,
                conversation_messages: state.conversation_messages.map(message => updated_message.find(change => change.id === message.id) || message)
            };

        case UPDATE_CONVERSATION_BACKGROUND:
            return {
                ...state,
                conversation: [action.payload]
            };
        case UPDATE_CONVERSATION_MY_MESSAGES_COLOR:
            return {
                ...state,
                conversation: [action.payload]
            };

        case CREATE_NEW_MESSAGE:
            return {
                ...state,
                conversation_messages: [...state.conversation_messages, action.payload]
            };

        case CLEAR_MESSAGES:
            return {
                ...state,
                conversation_messages: [],
                conversation: [],
            };

        default:
            return state;
    }
}



