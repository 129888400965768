import React from 'react';
import useSpeechToText from 'react-hook-speech-to-text';
import { useState, useEffect } from "react";

function SpeechToText(props) {

    const [talking, setTalking] = useState(false);
    const [talkingText, setTalkingText] = useState("");

    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false
    });

    useEffect(() => {
        if (results.length>0){
            let lastQuestion = results[results.length - 1];
            let question = lastQuestion.transcript
            props.setSpeechToTextFinalResults(question)
        }
    }, [results])

    if (!isRecording){
        // const initialzeRecording = startSpeechToText()
    }
    
    if (error) {
        return <p>Recording is not available 🤷‍</p>
    } 

    if (results.length>0){
        let lastQuestion = results[results.length - 1];
        let question = lastQuestion.transcript
        // setTalkingText(question)
        // const scarlet_speaking = false
        // const text_message = this.state.question
        // const messageToScarlett = {scarlet_speaking, text_message}
        // props.createMessageWithScarlett(messageToScarlett)
        props.setSpeechToTextResults(question)
        // props.setSpeechToTextResults("Scarlett How can Chatgpd help me do my homework?")
    }

    const clickTalkButton = e => {
        setTalking(true)
        startSpeechToText()
    }

    const clickFinishTalking = e => {
        setTalking(false)
        stopSpeechToText()
    }

    return (
        <>
            {talking?
                <div onClick={clickFinishTalking} class="scarlett-microphone-icon-box">
                    <img
                        class="scarlett-microphone-icon"
                        src={require("../../../assets/scarlett/svg/microphone-green.svg")}
                        alt=""
                    />
                </div>
            :
                <div onClick={clickTalkButton} class="scarlett-microphone-icon-box">
                    <img
                        class="scarlett-microphone-icon"
                        src={require("../../../assets/scarlett/svg/microphone-grey.svg")}
                        alt=""
                    />
                </div>       
            }       
            {/* <div>
                <h1>Recording: {isRecording.toString()}</h1>
                <button onClick={isRecording ? stopSpeechToText : startSpeechToText}>
                    {isRecording ? 'Stop Recording' : 'Start Recording'}
                </button>
                <ul>
                    {results.map((result) => (
                        <li key={result.timestamp}>{result.transcript}</li>
                    ))}
                    {interimResult && <li>{interimResult}</li>}
                </ul>
            </div> */}
        </>
    );
}

export default SpeechToText